import React, { ComponentType, memo, useCallback, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Box,
  ButtonGroup,
  Button,
} from "@material-ui/core";

/**
 * @typedef {{
 *      open: boolean,
 *      title?: string,
 *      message: string,
 *      onConfirm: function,
 *      onAbort: function,
 *      confirmLabel: string,
 *      abortLabel: string,
 *      onExited?: function
 * }} DialogConfirmProps
 * @param {DialogConfirmProps} param0
 */
function ConfirmDialog({
  open,
  title,
  message,
  onConfirm,
  onAbort,
  confirmLabel,
  abortLabel,
  noAbort,
  onExited
}) {
  title = title || "Conferma operazione";
  confirmLabel = confirmLabel || "Conferma";
  abortLabel = abortLabel || "Annulla";
  return (
    <Dialog open={open} data-testid="confirmation-dialog" TransitionProps={{onExited}}>
      <DialogTitle data-testid="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent data-testid="confirmation-dialog-content">
        <DialogContentText data-testid="confirmation-dialog-content-text">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box justifyContent="flex-end">
          <ButtonGroup>
            <Button
              type="button"
              variant="text"
              color="primary"
              data-testid="confirmation-dialog-confirm"
              onClick={onConfirm}
            >
              {confirmLabel}
            </Button>
            {!noAbort && (
              <Button
                type="button"
                variant="text"
                color="secondary"
                data-testid="confirmation-dialog-abort"
                onClick={onAbort}
              >
                {abortLabel}
              </Button>
            )}
          </ButtonGroup>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

/**
 * @return {ComponentType<DialogConfirmProps>}
 * @param {DialogConfirmProps} DefaultDialogProps
 */
export function make(DefaultDialogProps) {
  DefaultDialogProps = DefaultDialogProps || {};
  return (props) => <ConfirmDialog {...DefaultDialogProps} {...props} />;
}

export default memo(ConfirmDialog);
