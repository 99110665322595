import React, { Fragment, memo, useCallback, useMemo, useState } from "react";
import HulkAppBar from "../HulkAppBar";
import {
  Box,
  makeStyles,
  Grid,
  Typography,
  IconButton,
  Drawer,
} from "@material-ui/core";
import clsx from "clsx";
import Close from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  menuLogo: {
    maxWidth: "48px",
    display: "block",
  },
  menuWrapper: {
    transitionProperty: "width",
    transitionDuration: "350ms",
    overflow: "hidden",
    height: "100vh",
    position: "sticky",
    top: 0,
    backgroundColor: "#272e3d",
    display: "flex",
    flexDirection: "column",
  },
  menuContentWrapper: {
    overflow: "auto",
  },
  menuHeader: {
    backgroundColor: "#272e3d",
    padding: theme.spacing(2),
  },
  drawerMenuWrapper: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#272e3d",
  },
}));

function MenuHeader({ title, onMenu, logo }) {
  const { menuHeader, menuLogo } = useStyles();
  return (
    <Box className={menuHeader}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography component="h5" variant="h5" style={{ color: "white" }}>
            {title}
          </Typography>
        </Grid>
        {logo && (
          <Grid item>
            <img className={menuLogo} src={logo} alt={title} />
          </Grid>
        )}
        {onMenu && (
          <Grid item>
            <IconButton
              onClick={onMenu}
              style={{ color: "white" }}
              size="small"
            >
              <Close color="inherit" />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

const MenuWrapper = memo(
  ({ open, onClose, inline, children, title, menuWidth, logo }) => {
    const { menuWrapper, menuContentWrapper, drawerMenuWrapper } = useStyles();
    const frag = (
      <Fragment>
        <MenuHeader title={title} onMenu={onClose} logo={logo} />
        <Box className={menuContentWrapper} flexGrow={1}>
          {children}
        </Box>
      </Fragment>
    );
    if (inline) {
      return (
        <Box
          className={clsx([menuWrapper])}
          style={{ width: open ? menuWidth : 0 }}
        >
          {frag}
        </Box>
      );
    } else {
      return (
        <Drawer anchor="left" open={open} onClose={onClose}>
          <Box width={menuWidth} className={drawerMenuWrapper}>
            {frag}
          </Box>
        </Drawer>
      );
    }
  }
);

function HulkMainView({
  menu,
  logo,
  title,
  children,
  menuWidth,
  onSearch,
  appBarRightContent,
  appBarLeftContent,
  inlineMenu,
  contentRef,
  onContentScroll,
  appBarPadding,
  defaultOpenMenu,
  menuLogo,
  toggableMenu,
}) {
  const { contentWrapper } = useStyles();
  const [openMenu, setOpenMenu] = useState(defaultOpenMenu);
  const onToggleMenu = useCallback(() => setOpenMenu((o) => !o), [setOpenMenu]);
  const onCloseMenu = useCallback(() => setOpenMenu(false), [setOpenMenu]);
  return (
    <Box display="flex">
      {menu && (
        <MenuWrapper
          onClose={toggableMenu && onCloseMenu}
          open={openMenu}
          title={title}
          menuWidth={menuWidth}
          inline={inlineMenu}
          logo={menuLogo && logo}
        >
          {menu}
        </MenuWrapper>
      )}
      <Box flexGrow={1}>
        <HulkAppBar
          position="sticky"
          onMenu={menu && toggableMenu && onToggleMenu}
          logo={!menuLogo && logo}
          onSearch={onSearch}
          rightContent={appBarRightContent}
          leftContent={appBarLeftContent}
          padding={appBarPadding}
        />
        <Box
          component="div"
          className={contentWrapper}
          ref={contentRef}
          onScroll={onContentScroll}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}

export default memo(HulkMainView);
