import { templates } from "@italwebcom/anema-shared-web-components";
import { memo } from "react";
import { titleGetter, panelDetails, panelDescription } from "./data";
import { productSelectionViewFilters } from "../../data";

const { home: HomepageTemplate } = templates;

function Homepage() {
  return (
    <HomepageTemplate
      variant="panel"
      backgroundColor="#F0F2F5"
      panelBackgroundImage="webapp/img/home.jpg"
      title={titleGetter}
      panelDescription={panelDescription}
      panelDetails={panelDetails}
      productSelectionViewSectionFilters={productSelectionViewFilters}
      productSelectionViewInitialCategoryFilter={3}
      logo="webapp/img/anema-alt.png"
      privacyPolicyLink="https://animacore.italweb.agency/file/privacy.html"
      noHeader
    />
  );
}

export default memo(Homepage);
