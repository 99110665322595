import React, { memo } from "react";
import TagPanelSelector from "../../../../tags/TagPanelSelector";
import useCategoryFiltering from "../hooks/useCategoryFiltering";

/**
 *
 * @param {FiltererProps} param0
 */
function ProductTagPanelFilterer({ filters, onFiltersSetAll }) {
  const {onSetAll, categories} = useCategoryFiltering({filters, onFiltersSetAll});
  return (
    <TagPanelSelector
      defaultSelected={categories}
      onChange={onSetAll}
      filterType="tag_type_1"
      legend="Tipo piatto"
    />
  );
}

export default memo(ProductTagPanelFilterer);
