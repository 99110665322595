import {
  Section,
  TimePicker,
  ProvidersWrapper,
  HulkTableRenderer,
  Fieldset,
  ModalEntityCard,
  CustomTabs,
  DatePicker,
  EntityAddManager,
  MegaButton,
  MissingElementRenderer,
  ModalFormEntityEditor,
  PaginatorCacheContextWrapper,
  Unauthorized,
  FullPageLoader,
} from "./components";

import templates from "./pages";

import {
  CartNotEditableMessage,
  ReserveLoginPanel,
  LoginButton,
} from "./components/_auxiliary";

import {
  formatMessageDate,
  formatMessageHour,
  today,
  isNew,
  withDefaultTheme,
} from "./functions";

import messages from "./messages";
import {
  customers,
  carts,
  deliveryZones,
  tags,
  conversations,
  notifications,
  newsItems,
  products,
} from "./components/_entities";

import { webStorage, authFacade } from "./auxiliary";

const functions = {
  formatMessageDate,
  formatMessageHour,
  today,
  isNew,
  withDefaultTheme,
};

const auxiliary = {
  CartNotEditableMessage,
  ReserveLoginPanel,
  LoginButton,
};

export {
  Section,
  TimePicker,
  ProvidersWrapper,
  HulkTableRenderer,
  Fieldset,
  ModalEntityCard,
  CustomTabs,
  DatePicker,
  EntityAddManager,
  MegaButton,
  MissingElementRenderer,
  ModalFormEntityEditor,
  PaginatorCacheContextWrapper,
  Unauthorized,
  FullPageLoader,
  functions,
  messages,
  customers,
  carts,
  tags,
  conversations,
  deliveryZones,
  notifications,
  newsItems,
  products,
  auxiliary,
  templates,
  webStorage,
  authFacade,
};
