import { useCallback } from "react";
import { useRuntimeContext } from "../../../../misc/RuntimeProvider";
import { useStorageSet } from "../../../../misc/StorageProvider";

/**
 * @returns 
 */
export default function useTempCartStorageClear() {
  const onSet = useStorageSet();
  const { cartCodeName, cartIdName } = useRuntimeContext();
  const onClear = useCallback(() => {
    const vals = {};
    vals[cartCodeName] = null;
    vals[cartIdName] = null;
    onSet(vals);
  }, [onSet, cartCodeName, cartIdName]);
  return onClear;
}
