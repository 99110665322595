import { useMemo } from "react";
import useFormData from "./useFormData";
import { InputShouldRenderFunc } from "../defines";

/**
 * 
 * @param {{field: string, shouldRender: InputShouldRenderFunc}} param0 
 * @returns 
 */
export default function useShouldRender({ shouldRender, field }) {
  const { values, shouldRender: defaultShouldRender } = useFormData();
  return useMemo(() => {
    if (shouldRender || defaultShouldRender) {
      if (shouldRender) {
        return shouldRender(values);
      } else {
        return defaultShouldRender(field, values);
      }
    }
    return true;
  }, [values, shouldRender, defaultShouldRender, field]);
}
