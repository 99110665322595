import React, { memo, useCallback, useMemo } from "react";
import DatePicker from "../../../../../DatePicker";
import { ClosingDays } from "@italwebcom/anema-react-components";
import dayjs from "dayjs";
import Box from "@material-ui/core/Box";
import InputWrapperGroup from "../InputWrapperGroup";

function val(v) {
  return { target: { value: v } };
}

function DateInput({ value, onChange, disabled }) {
  const { closingDays, onMonthChange, loading } = ClosingDays.hooks.useClosingDays();
  const onActualChange = useCallback((date) => onChange(val(date)), [onChange]);
  const actualExcludeDates = useMemo(
    () => closingDays.map((cd) => cd.date),
    [closingDays]
  );
  const isLoading = !closingDays || loading;
  return (
    <InputWrapperGroup.InputWrapper
      id="date"
      label="Data"
      value={value && dayjs(value).format("DD MMMM YYYY")}
      disabled={disabled}
    >
      <Box style={{ width: "250px", margin: "auto" }}>
        <DatePicker
          defaultValue={value}
          onChange={onActualChange}
          onMonthChange={onMonthChange}
          excludeDates={actualExcludeDates}
          loading={isLoading}
        />
      </Box>
    </InputWrapperGroup.InputWrapper>
  );
}

export default memo(DateInput);