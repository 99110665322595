import React, { memo, useCallback, useEffect, useMemo, useRef } from "react";
import { useEntityEdit } from "@italwebcom/custom-react-hooks";
import Context from "../contexts/FormContext";
import { Field } from "../defines";
import useFormValidation from "../hooks/useFormValidation";
import useProcessedFields from "../hooks/useProcessedFields";
import useFormConfiguration from "../hooks/useFormConfiguration";

/**
 * @typedef {Record<string, any>} FormElement
 * @typedef {(inputName: string, values: FormElement) => boolean} OnChangePredicate
 *
 * @typedef {{
 *      defaultValues: FormElement,
 *      onSubmit: (values: FormElement) => void,
 *      fields: Field[],
 *      onChange: (attribute: string, value: any) => void,
 *      shouldRender?: OnChangePredicate,
 *      shouldAutoSubmit?: OnChangePredicate,
 *      shouldDisable?: OnChangePredicate,
 *      valuesMapper?: (values: FormElement, attribute: string) => FormElement
 * }} ProviderProps
 */

/**
 * @returns
 * @param {ProviderProps} param0
 */
function Provider({
  onSubmit,
  defaultValues,
  children,
  fields,
  onChange,
  shouldRender,
  shouldAutoSubmit,
  shouldDisable,
  valuesMapper,
  loading
}) {
  const { FormComponent: Form } = useFormConfiguration();
  const initialValues = useMemo(() => {
    if (defaultValues && valuesMapper) {
      return valuesMapper(defaultValues);
    }
    return defaultValues || {};
  }, [defaultValues, valuesMapper]);
  const { element: values, onSet, onMap } = useEntityEdit(initialValues);

  const onActualSubmit = useCallback(
    (evt) => {
      evt.preventDefault();
      onSubmit(values);
    },
    [values, onSubmit]
  );

  const lastChangedInput = useRef();
  const processedFields = useProcessedFields(fields);
  const { valid, invalidValue } = useFormValidation({
    values,
    fields: processedFields,
  });

  console.log(invalidValue);

  useEffect(() => {
    if (
      values &&
      valid &&
      shouldAutoSubmit &&
      lastChangedInput.current &&
      shouldAutoSubmit(lastChangedInput.current, values)
    ) {
      onSubmit(values, true);
    }
  }, [values, valid, lastChangedInput]);

  const onActualSet = useCallback(
    (attribute, value) => {
      /* fires onChange callback, if provided */
      onChange && onChange(attribute, value);
      lastChangedInput.current = attribute;

      onMap((currentValues) => {
        let out = { ...currentValues };
        /* change modified value */
        out[attribute] = value;
        /* apply mapper, if provided */
        if (valuesMapper) {
          out = valuesMapper(out, attribute);
        }
        return out;
      });
    },
    [onSet, onChange, onMap, valuesMapper, onSubmit]
  );

  return (
    <Form onSubmit={onActualSubmit}>
      <Context.Provider
        value={{
          values,
          onSet: onActualSet,
          invalidValue,
          submittable: valid,
          fields: processedFields,
          shouldRender,
          shouldDisable,
          loading
        }}
      >
        {children}
      </Context.Provider>
    </Form>
  );
}

export default memo(Provider);
