import React, { memo, useMemo } from "react";
import Generic from "../Generic";
import LocalDining from "@material-ui/icons/LocalDining";
import Person from "@material-ui/icons/Person";
import { Carts } from "@italwebcom/anema-react-components";
import getShoppingIcon from "../../getShoppingIcon";

const { useCartAndProducts } = Carts.hooks;

const addData = [
  {
    id: "menuSelection",
    icon: <LocalDining fontSize="inherit" />,
    label: "Selezione menù",
    variant: "button",
  },
  {
    id: "dashboard",
    icon: <Person />,
    label: "Accesso area utente",
    user: true,
    variant: "button",
  },
];

/**
 * @param {{cart: number, color: string}} param0
 * @returns
 */
function Temp({ color, cart, smallScreen }) {
  const { cartProducts } = useCartAndProducts();
  const data = useMemo(
    () =>
      [
        {
          id: "cartOverview",
          icon: getShoppingIcon(cartProducts),
          label: "Riepilogo prenotazione",
          variant: smallScreen ? "icon" : "button",
        },
      ].concat(smallScreen ? [] : addData),
    [cartProducts, smallScreen]
  );
  return <Generic data={data} color={color} cart={cart} />;
}

export default memo(Temp);
