import React, { memo, useMemo } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
import { Carts } from "@italwebcom/anema-react-components";
import { CostChip, QuantityChip } from "../_Auxiliary";
import InputButtonGroup from "./InputButtonGroup";
import QuantitySelector from "./QuantitySelector";
import PlaceholderWrapper from "../../../../../PlaceholderWrapper";
import { getProductImage } from "../../../../../../functions";
import trim from "../../../../../../functions/trim";

const {
  useCartProductSelection,
  useCartAndProducts,
  useCartDerivedAttributes,
} = Carts.hooks;

function ItemRenderer({ element, placeholder, onClick }) {
  const {
    title: titleStyle,
    subtitle: subtitleStyle,
    cost: costStyle,
    image: imageStyle,
    actualImage,
    wrapper,
    costWrapper,
  } = useStyles();

  const { cart, cartLoading, productsLoading } = useCartAndProducts();

  const {
    selectedElement,
    onAddToCart: onActualSelect,
    onDelete: onActualDelete,
    onQuantityChange: onActualChange,
  } = useCartProductSelection(element);
  const isSelected = selectedElement && selectedElement.quantity;
  const { editable } = useCartDerivedAttributes(cart);
  const actualMax = useMemo(() => element.stock || 20, [element]);
  return (
    <Box
      className={wrapper}
      onClick={onClick}
      role={onClick ? "button" : undefined}
    >
      <Box className={imageStyle} component="picture">
        <PlaceholderWrapper active={placeholder}>
          <img
            src={getProductImage(element)}
            className={actualImage}
            alt={element.name}
          />
        </PlaceholderWrapper>
      </Box>
      <Box flexGrow={1}>
        <PlaceholderWrapper active={placeholder}>
          <Typography
            component="h4"
            variant="body1"
            gutterBottom
            className={titleStyle}
          >
            {element.name}
          </Typography>
        </PlaceholderWrapper>
        <PlaceholderWrapper active={placeholder}>
          <Typography
            component="p"
            variant="body2"
            gutterBottom
            className={subtitleStyle}
          >
            {trim(element.description, 50)}
          </Typography>
        </PlaceholderWrapper>
      </Box>

      <Box className={costWrapper}>
        {isSelected ? (
          <QuantityChip selectedElement={selectedElement} size="small" />
        ) : (
          <CostChip element={element} size="small" />
        )}
        <Box display="flex" alignItems="center">
          {selectedElement && editable && (
            <QuantitySelector
              min={1}
              max={actualMax}
              value={selectedElement.quantity}
              onChange={onActualChange}
              disabled={cartLoading || productsLoading}
            />
          )}
          <InputButtonGroup
            onRemove={editable && isSelected && onActualDelete}
            onSelect={editable && !isSelected && onActualSelect}
            disabled={cartLoading || productsLoading}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default memo(ItemRenderer);
