import { createContext } from "react";
import {
  OpeningTime,
  DeliveryZone,
  ClosingDay,
} from "../../../../../__domain/model";

/**
 * @type {{
 *      openingTimes: OpeningTime[],
 *      closingDays: ClosingDay[],
 *      deliveryZones: DeliveryZone[],
 *      deliveryZonesLoading: boolean,
 *      openingTimesLoading: boolean,
 *      closingDaysLoading: boolean
 * }}
 */
const args = {
  openingTimes: [],
  deliveryZones: [],
  closingDays: [],
  closingDaysLoading: false,
  openingTimesLoading: false,
  deliveryZonesLoading: false,
};
export default createContext(args);
