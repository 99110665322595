import React, { memo } from "react";
import { HulkButton } from "@italwebcom/misc-ui-components";

function MakeReservationButton({ onClick, label, icon, noStyle, id }) {
  return (
    <HulkButton
      label={label || "Fai una prenotazione"}
      onClick={onClick}
      variant="light"
      startIcon={icon}
      style={
        noStyle
          ? {}
          : {
              height: "3rem",
              fontSize: "1rem",
            }
      }
      fullWidth
      id={id}
    />
  );
}

export default memo(MakeReservationButton);
