import React, { ReactNode, CSSProperties, memo } from "react";
import { makeStyles } from "@material-ui/core";
import Typography, { TypographyProps } from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Warning from "@material-ui/icons/Warning";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  buttonWrapper: {
    marginTop: theme.spacing(2),
    alignSelf: "stretch",
  },
}));

const defaultTitle = "Si è verificato un errore.";

function getFontSize(size) {
  if (size) {
    if (size === "small") {
      return 48;
    } else {
      return 96;
    }
  }
}

/**
 * @returns
 * @param {{
 *      color: string,
 *      title: string,
 *      subtitle?: string,
 *      subtitleTypographyProps?: TypographyProps,
 *      titleTypographyProps?: TypographyProps,
 *      additionalContent?: ReactNode[],
 *      containerStyle?: CSSProperties,
 *      icon?: undefined | "small" | "big"
 * }} param0
 */
function ErrorPrinter({
  color,
  title,
  subtitle,
  titleTypographyProps,
  subtitleTypographyProps,
  onRefreshAll,
  containerStyle,
  icon,
}) {
  const { wrapper, buttonWrapper } = useStyles();
  return (
    <Container maxWidth="sm">
      <Box className={wrapper} style={containerStyle} component="article">
        {icon && (
          <Box style={{ color }} fontSize={getFontSize(icon)}>
            <Warning fontSize="inherit" color="inherit" />
          </Box>
        )}
        <Typography
          component="header"
          variant="h5"
          style={{ color }}
          gutterBottom={Boolean(subtitle)}
          {...(titleTypographyProps || {})}
        >
          {title || defaultTitle}
        </Typography>
        {subtitle && (
          <Typography
            component="section"
            variant="body1"
            style={{ color, textAlign: "center" }}
            {...(subtitleTypographyProps || {})}
          >
            {subtitle}
          </Typography>
        )}
        {onRefreshAll && (
          <Box className={buttonWrapper}>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              size="medium"
              onClick={onRefreshAll}
              fullWidth
            >
              Ricarica
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
}

export default memo(ErrorPrinter);
