import React, { memo, ReactNode, useState } from "react";
import { Context, CustomerProductSelectionViewVariant } from "../context";

/**
 * Provides (duh) the currently selected variant value & a corresponding setter. 
 * Should use the context's useVariantProvider hook to interact with the value.
 * 
 * @param {{initialValue: CustomerProductSelectionViewVariant, children: ReactNode[]}} param0
 * @returns
 */
function VariantProvider({ children, initialValue }) {
  const [variant, setVariant] = useState(initialValue);
  return (
    <Context.Provider value={{ variant, onSetVariant: setVariant }}>
      {children}
    </Context.Provider>
  );
}

const Memoized = memo(VariantProvider);
export default Memoized;