import React, { memo } from "react";
import ListRenderer from "../ListRenderer";
import { Customer } from "@italwebcom/anema-react-components/Customers";
import { Cart } from "@italwebcom/anema-react-components/Carts";
import SharedList from "../_SharedList";
import PlaceholderWrapper from "../../../../PlaceholderWrapper";

/**
 * @param {{customer: Customer, onRedirectToEdit: (cart: Cart) => void, onRedirectToOverview: (cart: Cart) => void}} param0
 */
function DashboardList({
  customer,
  onRedirectToEdit,
  onRedirectToOverview,
  initialItemsPerPage,
}) {
  if (typeof customer.id === "number") {
    return (
      <SharedList
        customer={customer}
        onRedirectToEdit={onRedirectToEdit}
        onRedirectToOverview={onRedirectToOverview}
        initialItemsPerPage={initialItemsPerPage}
      >
        <ListRenderer />
      </SharedList>
    );
  } else {
    return <ListRenderer placeholder />;
  }
}

export default memo(DashboardList);
