import useCartAndProducts from "../../CartAndProductsProvider/hooks/useCartAndProducts";
import useRepeatingOperation from "../../../../../hooks/useRepeatingOperation";
import { useCallback, useEffect, useMemo, useRef } from "react";
import useRuntimeContext from "../../../../../components/misc/RuntimeProvider/hooks/useRuntimeContext";

/**
 * @param {{
 *      onRedirect: (url: string) => void,
 *      timeout?: number,
 *      active: boolean,
 *      onPaid: Function
 * }} onRedirect
 */
export default function useCustomerPayer({
  onRedirect,
  timeout,
  active,
  onPaid,
}) {
  
  const { timeoutService } = useRuntimeContext();
  const { cart, onCartRefresh } = useCartAndProducts();
  const isCartUnpaid = useMemo(() => cart && cart.status !== "PAID", [cart]);
  const actualTimeout = useMemo(() => timeout || 3000, [timeout]);
  let daTimeoutRef = useRef(null);

  const isActive = useMemo(
    () => active && isCartUnpaid,
    [active, isCartUnpaid]
  );

  const onRedirectToPaymentUrl = useCallback(() => {
    cart && cart.paymentUrl && onRedirect(cart.paymentUrl);
  }, [cart, onRedirect]);

  /* calls onPaid callback after payment */
  useEffect(() => {
    !isCartUnpaid && onPaid();
  }, [isCartUnpaid, onPaid]);

  /* sets timeout to execute onRedirect callback */
  useEffect(() => {
    if (daTimeoutRef.current) {
        timeoutService.clearTimeout(daTimeoutRef.current);
    } 
    daTimeoutRef.current = timeoutService.setTimeout(
      onRedirect,
      actualTimeout
    );
    return () =>
      daTimeoutRef.current && timeoutService.clearTimeout(daTimeoutRef.current);
  }, [daTimeoutRef, onRedirect, actualTimeout]);

  useRepeatingOperation(
    onCartRefresh,
    isActive,
    actualTimeout,
    timeoutService.setInterval.bind(timeoutService),
    timeoutService.clearInterval.bind(timeoutService)
  );

  return {isActive};
}
