import { ClosingDay } from "../../model";
import { withDefaultHateoas } from "../hateoas/auxiliary/getDefaultHateoasData";
import dayjs from "dayjs";

const n = 10;

/**
 * @type {ClosingDay[]}
 */
let closingDays = [];

for (let i = 0; i < n; i++) {
  let m = dayjs().add(i, "weeks");
  closingDays.push(
    withDefaultHateoas(
      "closing-day",
      {
        id: i,
        date: m.format("YYYY-MM-DD"),
        month: m.format("YYYY-MM"),
        description: `closing day ${i}`
      },
      "id"
    )
  );
}

export default closingDays;
