import React, { memo, useCallback } from "react";
import TimePicker from "../../../../../TimePicker";
import useOpeningTimesData from "../../_Auxiliary/hooks/useOpeningTimesData";
import InputWrapperGroup from "../../_Auxiliary/InputWrapperGroup";

function ActualTimePicker({
  onChange,
  openingTimes,
  loading,
  disabled,
  value,
}) {
  const { hours, minutesGetter } = useOpeningTimesData({ openingTimes });
  const onActualChange = useCallback(
    (v) => onChange({ target: { value: v } }),
    [onChange]
  );
  
  return (
    <InputWrapperGroup.InputWrapper
      id="time"
      value={value && `ore ${value}`}
      label="Orario"
      disabled={disabled}
    >
      <TimePicker
        defaultValue={value}
        onChange={onActualChange}
        hours={hours}
        minutesGetter={minutesGetter}
        valueFormat="HH:mm"
        disabled={loading || disabled}
        selectStyle={{ marginTop: 0 }}
      />
    </InputWrapperGroup.InputWrapper>
  );
}

export default memo(ActualTimePicker);
