import React, { memo, useContext, useEffect, useRef } from "react";
import context from "../context";

function Section({ value, children }) {
  const { value: selectedValue, onScrollTo } = useContext(context);
  const el = useRef(null);
  useEffect(() => {
    if (selectedValue && value && value === selectedValue) {
      const { top, left } = el.current.getBoundingClientRect();
      console.log(`SectionSlider.Section.<useEffect callback>: calling onScrollTo with (${left}, ${top})`)
      onScrollTo(left, top, el.current);
    }
  }, [value, onScrollTo, selectedValue, el]);
  return <div ref={(r) => (el.current = r)}>{children}</div>;
}

export default memo(Section);