import { ProductFeature } from "../../model";
import wrappers from "../hateoas";

const n = 10;

/**
 * @type {ProductFeature[]}
 */
let productFeatures = [];

for (let i = 0; i < n; i++) {
  productFeatures.push(
    wrappers.productFeature({
      id: i,
      name: `feature${i}`,
      description: `This is product feature feature${i}`,
    })
  );
}

export default productFeatures;
