/* istanbul ignore file */

import { createContext } from "react";
import { DefaultComponentsContextArgs } from "../defines";

/**
 * @type {DefaultComponentsContextArgs}
 */
const defaultComponentsContextArgs = {
    Loader: null,
    ListCollectionRenderer: null,
    fetchArgsProcessor: null,
    defaultCountGetter: null
};

export default createContext(defaultComponentsContextArgs);