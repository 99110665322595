import React, { memo } from "react";
import { Notifications } from "@italwebcom/anema-react-components";
import CollectionRenderer from "./CollectionRenderer";

function CustomerNotificationList({ customer, initialItemsPerPage }) {
  if (typeof customer.id === "number") {
    return (
      <Notifications.Components.Provider
        customer={customer}
        initialItemsPerPage={initialItemsPerPage}
      >
        <CollectionRenderer />
      </Notifications.Components.Provider>
    );
  } else {
    return <CollectionRenderer placeholder />;
  }
}

export default memo(CustomerNotificationList);
