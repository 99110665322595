import React, { memo } from "react";
import { ErrorProps, GenericForm } from "@italwebcom/react-generic-form";

/**
 * @param {ErrorProps} param0
 */
function DaErra({ field, Component, ComponentProps }) {
  return (
    <GenericForm.Error
      Component={Component}
      field={field}
      ComponentProps={ComponentProps}
    />
  );
}

export default memo(DaErra);
