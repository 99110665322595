import { useMemo } from "react";
import useFormData from "./useFormData";

/**
 * @param {string} field 
 * @returns 
 */
export default function useError(field) {
  const { invalidValue } = useFormData();
  return useMemo(() => {
    if (invalidValue && field) {
      const isField = invalidValue.id === field;
      return {
        error: isField,
        message: isField ? invalidValue.invalidMessage : null,
      };
    }
    return { error: false, message: "" };
  }, [invalidValue, field]);
}
