import React from "react";
import {
  CollectionRendererProps,
  defaultKeyExtractor,
  GridCollectionRoles,
} from "../defines";
import { Grid, makeStyles } from "@material-ui/core";
import CompositeRenderer from "../Auxiliary/CompositeRenderer";
import AltElementRenderer from "./renderers/AltElementRenderer";
import useCollectionCheckboxes from "../../hooks/useCollectionCheckboxes";
import BatchOperationsMenu from "../Auxiliary/BatchOperationsMenu";
import useSortingAttributes from "../Auxiliary/hooks/useSortingAttributes";

const defaultConfig = { sortable: true, selectOnClick: false };
const useStyles = makeStyles((theme) => ({
  batchContainer: {
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

/**
 * @template Entity
 * @param {CollectionRendererProps<GridCollectionRoles, Entity>} param0
 */
export default function GridCollectionRenderer({
  elements,
  sortAttribute,
  sortDirection,
  onSortChange,
  attributes,
  onSearch,
  keyExtractor,
  onSelect,
  actions,
  config,
}) {
  keyExtractor = keyExtractor || defaultKeyExtractor;
  config = config || defaultConfig;
  const { batchContainer } = useStyles();
  const {
    collection: batchElements,
    checkboxes,
    allCheckbox,
  } = useCollectionCheckboxes({
    elements,
    config: {
      testIDGetter: (e) => `element-${keyExtractor(e)}`,
      allCheckboxTestID: "all-checkbox",
      selectable: true,
    },
  });
  const batchOperations = actions ? actions.filter((a) => a.batch) : [];
  const hasBatch = Boolean(batchOperations.length);
  const sortableAttributes = useSortingAttributes(attributes);
  return (
    <CompositeRenderer
      onSearch={onSearch}
      sortAttribute={sortAttribute}
      sortDirection={sortDirection}
      onSortChange={onSortChange}
      attributes={sortableAttributes}
      container
      spacing={2}
    >
      {hasBatch && (
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          className={batchContainer}
        >
          <Grid item>
            <BatchOperationsMenu
              elements={batchElements}
              operations={batchOperations}
            />
          </Grid>
          <Grid item>{allCheckbox}</Grid>
        </Grid>
      )}
      {elements && elements.length &&
        elements.map((element, index) => (
          <AltElementRenderer
            key={keyExtractor ? keyExtractor(element) : index}
            onSelect={config.selectable && onSelect}
            element={element}
            attributes={attributes}
            keyExtractor={keyExtractor}
            actions={actions}
            checkbox={hasBatch && checkboxes[index]}
            selectOnClick={config.selectOnClick}
          />
        ))}
    </CompositeRenderer>
  );
}
