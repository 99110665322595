import React, { memo } from "react";
import {makeStyles} from "@material-ui/core"
import Box from "@material-ui/core/Box";
import FormLabel from "@material-ui/core/FormLabel";

const useStyles = makeStyles(theme => ({
    legendWrapper: {
        marginBottom: theme.spacing(0.5)
    }
}));

/**
 * @param {{legend: string}} param0 
 * @returns 
 */
function Legend({ legend }) {
  const { legendWrapper } = useStyles();
  if (legend) {
    return (
      <Box className={legendWrapper}>
        <FormLabel component="legend">{legend}</FormLabel>
      </Box>
    );
  }
  return null;
}

export default memo(Legend);