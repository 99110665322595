import { useCallback } from "react";
import { useRuntimeContext } from "../../components/misc/RuntimeProvider";
import setHeaders from "../_auxiliary/setHeaders";

/**
 * @returns
 * @param {{
 *      headers: Record<string, any> || () => Record<string, any>,
 *      operator: (args: any...) => Promise<any>,
 *      decoratorName?: string
 * }} param0
 */
function useCustomHeadersRequest({
  headers,
  operator,
  decoratorName,
}) {
  decoratorName = decoratorName || "decorator";
  const { fetcher } = useRuntimeContext();
  return useCallback(
    (...args) => {
      fetcher.addDefaultDecorator({
        name: decoratorName,
        process: (a) => setHeaders(a, headers),
      });
      let p = operator(...args);
      return p;
    },
    [decoratorName, fetcher, headers, operator]
  );
}

export default useCustomHeadersRequest;