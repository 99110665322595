import { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

function undef(v) {
  return typeof v === "undefined";
}

function processValue(v) {
  return v < 10 ? `0${v}` : v;
}

function getRange(l, u) {
  let out = [];
  for (let i = l; i <= u; i++) {
    out.push(i);
  }
  return out;
}

/**
 * @returns
 * @param {string} value
 * @param {string} format
 * @param {"in" | "out"} direction
 */
function formatValue(value, format, direction) {
  const inFormat = direction === "in" ? format : defaultFormat;
  const outFormat = direction === "in" ? defaultFormat : format;
  return dayjs(value, inFormat).format(outFormat);
}

/**
 * @param {string} value
 * @param {string} format
 * @returns
 */
function getState(value, format) {
  if (value) {
    const formatted = formatValue(value, format, "in");
    return {
      hours: formatted.split(":")[0],
      minutes: formatted.split(":")[1],
    };
  } else {
    return {
      hours: undefined,
      minutes: undefined,
    };
  }
}

function equals(current, target) {
  return current.hours === target.hours && current.minutes === target.minutes;
}

const defaultHours = getRange(0, 23);
const defaultFormat = "HH:mm";

/**
 * @returns
 * @param {{
 *     defaultValue: string,
 *     valueFormat?: string,
 *     onChange: (value: string) => void,
 *     minutesGetter: (hour: number) => number[],
 *     hours?: number[]
 * }} param0
 */
function useTimePickerFunctions({
  hours,
  minutesGetter,
  defaultValue,
  valueFormat,
  onChange,
}) {
  
  hours = hours || defaultHours;
  valueFormat = valueFormat || defaultFormat;

  const [state, setState] = useState({});
  const [availableMinutes, setAvailableMinutes] = useState([]);

  useEffect(() => {
    if (valueFormat && setState) {
      if (defaultValue) {
        setState((s) => {
          const proc = getState(defaultValue, valueFormat);
          if (!equals(proc, s)) {
            return proc;
          } else {
            return s;
          }
        });
      } else {
        setState({});
      }
    }
  }, [defaultValue, valueFormat, setState]);

  const onHourSet = useCallback(
    (v) => setState(() => ({ hours: v, minutes: undefined })),
    [setState]
  );
  const onMinutesSet = useCallback(
    (v) => setState((s) => ({ ...s, minutes: v })),
    [setState]
  );

  useEffect(() => {
    const { hours } = state;
    setAvailableMinutes(minutesGetter(parseInt(hours)));
  }, [minutesGetter, state]);

  useEffect(() => {
    const { hours: currentHours, minutes: currentMinutes } = state;
    if (!undef(currentHours) && !undef(currentMinutes)) {
      onChange(
        formatValue(
          `${processValue(currentHours)}:${processValue(currentMinutes)}`,
          valueFormat,
          "out"
        )
      );
    }
  }, [state, valueFormat, onChange]);

  return { onHourSet, onMinutesSet, availableMinutes, selected: state };
}

export default useTimePickerFunctions;
