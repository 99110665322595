import { withDefaultHateoas } from "../../hateoas/auxiliary/getDefaultHateoasData";
import links from "../../links";

export default function conversationWrapper(tagData) {
  let c = withDefaultHateoas(links.conversation.base, tagData);
  c._links.messages = {
    href: links.conversation.messages(tagData.id),
    templated: true,
  };
  c._templates.postMessage = {
    method: "POST",
    properties: [],
    target: links.conversation.postMessage(tagData.id),
  };
  return c;
}
