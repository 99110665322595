import useCartProductSelection from "../useCartProductSelection";
import { useValue } from "@italwebcom/custom-react-hooks";
import { useEffect, useMemo, useRef } from "react";

function useCachedCartProductSelection(product, selectedElement) {
  const {
    onAddToCart,
    onDelete,
    onQuantityChange,
    selectedElement: actualSelected,
  } = useCartProductSelection(product, selectedElement);

  const { increase, decrease, value, set } = useValue({
    initial: actualSelected && actualSelected.quantity,
    resetOnInitialChange: true,
  });

  const valueRef = useRef();

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  const { onIncrease, onDecrease, onConfirm } = useMemo(
    () => ({
      onIncrease: () => increase(1),
      onDecrease: () => decrease(1),
      onConfirm: () => {
        if (actualSelected) {
          return onQuantityChange(valueRef.current);
        } else {
          return onAddToCart(valueRef.current);
        }
      },
    }),
    [
      increase,
      decrease,
      actualSelected,
      onQuantityChange,
      onAddToCart,
      valueRef,
    ]
  );

  return {
    selected: Boolean(actualSelected),
    selectedElement: actualSelected,
    quantity: value,
    onDelete,
    onIncrease,
    onDecrease,
    onConfirm,
    onSet: set,
  };
}

export default useCachedCartProductSelection;
