import { templates } from "@italwebcom/anema-shared-web-components";
import { memo } from "react";

const Template = templates.user.reservations;

function UserReservationsPage() {
  return <Template noHeader />;
}

export default memo(UserReservationsPage);
