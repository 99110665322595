import { HateoasData } from "@italwebcom/crud-operations-wrapper";
import links from "../../links";

/**
 * @return {HateoasData}
 * @param {string} entityName 
 */
export function getElementDefaultHateoasData(entityName, id) {
    let selfLink = links.self(entityName, id);
    return ({
        _links: {
            self: {
                href: selfLink
            },
            read: {
                href: selfLink
            },
            fetch: {
                href: selfLink
            }
        },
        _templates: {
            delete: {
                method: "DELETE",
                properties: []
            },
            update: {
                method: "PUT",
                properties: []
            }
        }
    });
}

/**
 * @template {HateoasData} T
 * @param {string} entityName 
 * @param {T} element 
 * @returns 
 */
export function withDefaultHateoas(entityName, element, idAttribute) {
    idAttribute = idAttribute || "id";
    return {
        ...element,
        ...getElementDefaultHateoasData(entityName, element[idAttribute])
    };
}