import React, { memo, useCallback, useMemo } from "react";
import useStyles from "../styles";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import Avatar from "@material-ui/core/Avatar";
import PlaceholderWrapper from "../../../../../../PlaceholderWrapper";
import clsx from "clsx";

function ListItemRenderer({
  element,
  onChange,
  selected,
  variant,
  placeholder,
}) {
  const { wrapper, opaqueElement } = useStyles();
  const isSelected = useMemo(
    () =>
      Boolean(selected && selected.length) &&
      selected[0].data.id === element.id,
    [element, selected]
  );
  const onActualClick = useCallback(
    (evt) => onChange(evt, element.id),
    [element, onChange]
  );
  return (
    <ListItem
      key={element.id}
      role="button"
      className={clsx([
        isSelected && "selected",
        wrapper,
        variant || "primary",
        element.unavailable && opaqueElement
      ])}
      onClick={onActualClick}
    >
      <PlaceholderWrapper active={placeholder}>
        <ListItemAvatar>
          <Avatar
            src={(element.image && element.image.url) || element.mainImageUrl}
          />
        </ListItemAvatar>
      </PlaceholderWrapper>
      <PlaceholderWrapper active={placeholder}>
        <ListItemText
          primary={element.name}
          secondary={element.description || element.name}
        />
      </PlaceholderWrapper>
    </ListItem>
  );
}

export default memo(ListItemRenderer);
