function eq(a, b) {
  return Object.getOwnPropertyNames(a)
    .map((n) => a[n] === b[n])
    .reduce((a, b) => a && b, true);
}

export function getVals(token) {
  return {
    accessToken: token.accessToken,
    refreshToken: token.refreshToken,
    issuedAt: token.issuedAt,
    expiresIn: token.expiresIn,
  };
}

export function setStorageToken({ onStorageSet, currentToken, oldTokenValues }) {
  let currentTokenVals = getVals(currentToken);
  if (!eq(currentTokenVals, oldTokenValues)) {
    onStorageSet("token", currentTokenVals);
  }
}
