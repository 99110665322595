import {useMemo} from "react";

/**
 * @typedef {"sum"} ReducerType
 */
const f = parseFloat;
const defaultReducers = {
    sum: (a, b) => f(a) + f(b),
    prod: (a, b) => f(a) * f(b),
    max: (a, b) => Math.max(a, b),
    min: (a, b) => Math.min(a, b)
}

/**
 * @template S
 * @template T
 * @param {S} state 
 * @param {{reducer?: ReducerType | (a: any, b: any) => T, props?: string[]}} config 
 */
export default function useReducer(state, config) {
    let {reducer, props} = config;
    if(typeof(reducer) === "string") {
        reducer = defaultReducers[reducer] || defaultReducers.sum;
    }
    return useMemo(() => {
        props = props || Object.getOwnPropertyNames(state);
        let vals = [];
        for(let p of props) {
            vals.push(state[p]);
        }
        return vals.reduce(reducer);
    }, [state, reducer]);
}