import React, { memo } from "react";
import GenericInput from "../GenericInput";
import { cartType } from "../../../../../../__domain/model";
import { fieldNames } from "../../fields";

/**
 * @returns
 */
function AddressInput({ InputComponent }) {
  return (
    <GenericInput
      field={fieldNames.address}
      InputComponent={InputComponent}
      shouldRender={(v) => v[fieldNames.type] === cartType.delivery}
      InputProps={{ "data-testid": "address" }}
    />
  );
}

export default memo(AddressInput);
