import { useContext, useMemo } from "react";
import MenuContext from "../context";

/**
 * @param {string} label 
 * @returns 
 */
export default function useApplicable(label) {
  const { selected, isApplicable } = useContext(MenuContext);
  return useMemo(() => isApplicable(label, selected), [label, selected, isApplicable]);
}