import { useMemo } from "react";
import useRequestAuthenticationSetter from "../../../../authentication/hooks/useRequestAuthenticationSetter";
import useTempCartAuthenticationSetter from "../../hooks/useTempCartAuthenticationSetter";

/**
 * @returns
 */
function useCrudOperations({ cProd, cartProductWrapper, onRefresh }) {
  const authSetter = useRequestAuthenticationSetter();
  const tempAuthSetter = useTempCartAuthenticationSetter();

  return useMemo(
    () => ({
      onFetch: () => {
        if (cProd.current) {
          console.log(
            "SelectedProductFeaturesProvider.useCrudOperations: cartProduct present, fetching selections."
          );
          let r = cartProductWrapper
            .wrap(cProd.current)
            .execute("featureSelections", {});
          console.log(
            "SelectedProductFeaturesProvider.useCrudOperations: cartProduct present, setting authentication."
          );
          const req = r.request();
          tempAuthSetter(req);
          authSetter(req);
          return r.json();
        } else {
          console.log(
            "SelectedProductFeaturesProvider.useCrudOperations: cartProduct missing, returning empty array."
          );
          return Promise.resolve({ data: [] });
        }
      },
      onSave: async (el) => {
        console.log(
          "SelectedProductFeaturesProvider.useCrudOperations: executing update."
        );
        let r = cartProductWrapper.wrap(el).execute("default", el, true);
        const req = r.request();
        console.log(
          "SelectedProductFeaturesProvider.useCrudOperations: setting auth stuff for update."
        );
        tempAuthSetter(req);
        authSetter(req);
        await r.response();
        if(el.cost) {
          onRefresh && onRefresh();
        }
      },
    }),
    [cartProductWrapper, cProd, onRefresh]
  );
}

export default useCrudOperations;
