import {useEffect, useMemo, useRef, useState} from "react";

/**
 * @template V
 * @return {{promise: Promise<V>, resolve: (value: V) => void, reject: (value: V) => void}}
 * 
 * This is some convulted shit. Don't even know if it makes sense to do it this way.
 */
export default function usePromise() {
    const promise = useRef(null);
    const [funcs, setFuncs] = useState(null);

    useEffect(() => {
        if(!promise.current) {
            promise.current = new Promise((res, rej) => {
                setFuncs({resolve: res, reject: rej});
            });
        }
    }, [promise.current]);

    return useMemo(() => ({
        promise: promise.current,
        resolve: v => {
            if(funcs) {
                funcs.resolve(v);
                promise.current = null;
            }
        },
        reject: v => {
            if(funcs) {
                funcs.reject(v);
                promise.current = null;
            }
        }
    }), [funcs, promise.current]);
}