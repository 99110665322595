import { useCallback, useMemo } from "react";
import { Filter } from "@italwebcom/custom-react-hooks";

/**
 * @returns
 * @param {{
 *      initialItemsPerPage: number,
 *      count: number,
 *      onPageChange: (page: number) => void,
 *      onFilterReplace: (target: Filter<any>, filter: Filter<any>) => void,
 *      searchAttribute: string
 * }} param0
 */
export default function useAdditionalFunctions({
  initialItemsPerPage,
  count,
  onPageChange,
  onFilterReplace,
  searchAttribute,
}) {
  return {
    pages: useMemo(
      () => Math.ceil(count / initialItemsPerPage),
      [initialItemsPerPage, count]
    ),
    pageChangeCallback: useCallback(
      (e, p) => {
        onPageChange(p - 1);
      },
      [onPageChange]
    ),
    onSearch: useCallback(
      (value) =>
        onFilterReplace(
          { attribute: searchAttribute },
          { attribute: searchAttribute, value }
        ),
      [searchAttribute, onFilterReplace]
    ),
  };
}
