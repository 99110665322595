import React, { memo, ReactNode } from "react";
import MuiAppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography, { TypographyProps } from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  logoStyle: {
    display: "block",
    maxWidth: theme.spacing(8),
  },
  wrapper: {
    backgroundSize: "cover",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

/**
 * @returns
 * @param {{
 *      logo: string,
 *      title?: string,
 *      titleTypographyProps?: TypographyProps,
 *      children: ReactNode[],
 *      logoAlt: string,
 *      logoMaxWidth?: number,
 *      justifyContent: "center" | "flex-start" | "flex-end" | "space-around" | "space-between",
 *      backgroundColor?: string,
 *      backgroundImage?: string
 * }} param0
 */
function AppBar({
  logo,
  title,
  children,
  justifyContent,
  logoAlt,
  titleTypographyProps,
  backgroundColor,
  backgroundImage,
  logoMaxWidth,
}) {
  const { logoStyle, wrapper } = useStyles();
  return (
    <MuiAppBar
      position="sticky"
      style={{
        backgroundColor: backgroundImage ? undefined : backgroundColor,
        backgroundImage,
      }}
      className={wrapper}
    >
      <Container maxWidth={false}>
        <Grid container alignItems="center" justifyContent="center" spacing={2}>
          <Grid item>
            <picture>
              <img
                className={logoStyle}
                alt={logoAlt}
                src={logo}
                style={logoMaxWidth ? { maxWidth: `${logoMaxWidth}px` } : {}}
              />
            </picture>
          </Grid>
          {title && (
            <Grid item>
              <Typography
                component="h6"
                variant="h6"
                {...(titleTypographyProps || {})}
              >
                {title}
              </Typography>
            </Grid>
          )}
          {children && (
            <Grid item xs>
              <Box
                display="flex"
                justifyContent={justifyContent}
                alignItems="center"
              >
                {children}
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
    </MuiAppBar>
  );
}

export default memo(AppBar);
