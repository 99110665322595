import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useStyles from "../styles";
import { Notification } from "../../../../model";
import { formatMessageDate, formatMessageHour } from "../../../../functions";
import PlaceholderWrapper from "../../../PlaceholderWrapper";

/**
 * @returns
 * @param {{element: Notification}} param0
 */
export default function NotificationRenderer({ element, placeholder }) {
  const { notificationPaper, dateText, notificationTitle, wrapper } =
    useStyles();
  return (
    <Box className={wrapper}>
      <PlaceholderWrapper active={placeholder}>
        <Box
          className={notificationPaper}
          style={{ backgroundColor: "#2084E8" }}
        >
          {element.title && (
            <Typography
              component="p"
              variant="body1"
              className={notificationTitle}
              gutterBottom
            >
              {element.title}
            </Typography>
          )}
          <Typography component="p" variant="body2">
            {element.content || element.message}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-start">
          <Typography component="p" variant="caption" className={dateText}>
            Ricevuta il {formatMessageDate(element.date)} alle{" "}
            {formatMessageHour(element.date)}
          </Typography>
        </Box>
      </PlaceholderWrapper>
    </Box>
  );
}
