import React, { memo } from "react";
import { GenericForm } from "@italwebcom/react-generic-form";

/**
 * 
 * 
 * @param {{
 *    label: string, 
 *    disabled?: boolean, 
 *    loading?: boolean
 * }} param0
 * 
 * @returns
 */
function Submit({ label, disabled }) {
  return (
    <GenericForm.Submit
      label={label}
      disabled={disabled}
    />
  );
}

export default memo(Submit);