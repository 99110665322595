import { InnerCartProductProvider } from "../Main";
import { InnerCartProductProvider as TempInnerCartProductProvider } from "../Temp";
import { DynamicCartProvider } from "../../../CartProvider";
import React, { memo, useMemo } from "react";
import { useCustomer } from "../../../../Customers/Provider";
import undef from "../../../../../../misc/undef";

/**
 * @returns
 * @param {{
 *    id?: number,
 *    children: ReactChildren,
 * }} param0
 */
function DynamicCartAndProductsProvider({ children, id, redirectOnSave }) {
  const { customer } = useCustomer();
  const isTemp = useMemo(() => !customer || undef(customer.id), [customer]);
  return (
    <DynamicCartProvider id={id} redirectOnSave={redirectOnSave}>
      {!isTemp ? (
        <InnerCartProductProvider>{children}</InnerCartProductProvider>
      ) : (
        <TempInnerCartProductProvider>{children}</TempInnerCartProductProvider>
      )}
    </DynamicCartProvider>
  );
}

export default memo(DynamicCartAndProductsProvider);
