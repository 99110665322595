import { createContext } from "react";
import { Cart } from "../../../../../__domain/model";

/**
 * @type {{cart: Cart, onSave: (cart: Cart) => Promise<any>, onRefreshAll: Function, loading: boolean}}
 */
const contextArgs = {
  cart: null,
  onSave: () => null,
  onRefreshAll: () => null,
  loading: false,
};

export default createContext(contextArgs);
