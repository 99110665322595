import React, { useCallback, useState } from "react";
import Context from "../context";
import ProductDetailView from "../../../../ProductDetailView";
import { useRuntimeContext } from "../../../../../../../contexts";

function renderDetailView({
  variant,
  onClearSelected,
  selected,
  contentPanelHeader,
}) {
  if (variant === "drawer") {
    return (
      <ProductDetailView.Drawer
        entity={selected.element}
        onResetSelectedEntity={onClearSelected}
        headerProps={contentPanelHeader}
      />
    );
  } else {
    return (
      <ProductDetailView.Popover
        entity={selected.element}
        onResetSelectedEntity={onClearSelected}
        anchorEl={selected.anchorEl}
        headerProps={contentPanelHeader}
      />
    );
  }
}

/**
 * @param {{variant: "drawer" | "popover"}} param0
 * @returns
 */
function Component({ children, variant }) {
  const [selected, setSelected] = useState({ element: null, anchorEl: null });
  const { stylings } = useRuntimeContext();
  const onSetSelected = useCallback(
    (e, a) => setSelected({ element: e, anchorEl: a }),
    [setSelected]
  );
  const onClearSelected = useCallback(
    () => setSelected((s) => ({ element: null, anchorEl: null })),
    [setSelected]
  );
  return (
    <Context.Provider
      value={{
        element: selected.element,
        anchorEl: selected.anchorEl,
        onClearSelected,
        onSetSelected,
      }}
    >
      {renderDetailView({
        variant,
        onClearSelected,
        selected,
        //contentPanelHeader: stylings && stylings.general.contentPanelHeader,
      })}
      {children}
    </Context.Provider>
  );
}

export default Component;
