import React, {
  memo,
  useCallback,
  useContext,
  useMemo,
  CSSProperties,
} from "react";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import MenuContext from "../context";
import useApplicable from "../hooks/useApplicable";

const useStyles = makeStyles((theme) => ({
  darkBg: {
    backgroundColor: "#19222b",
  },
  lightBg: {
    backgroundColor: "#272e3d",
  },
  primary: {
    color: "white",
    //fontWeight: "bold",
  },
  secondary: {
    color: "#969fa4",
    overflowX: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  action: {
    marginLeft: "auto",
  },
  wrapper: {
    maxWidth: "100%",
    cursor: "pointer",
    position: "relative",
    "&:hover .overlay": {
      opacity: 0.25,
    },
    "&.small": {
      /*paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),*/
      padding: theme.spacing(1)
    },
    "&.normal": {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
  },
  iconWrapper: {
    color: "white",
    fontSize: "1.25em",
    display: "flex",
    alignItems: "center",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 2,
    backgroundColor: "white",
    opacity: 0,
    transitionProperty: "opacity",
    transitionDuration: "150ms",
  },
  contentWrapper: {
    zIndex: 1,
    paddingBottom: theme.spacing(0.5),
  },
}));

const defaultConfig = { overlayOnHover: true };

/**
 * @typedef {"light" | "dark"} ItemVariant
 * @typedef {"normal" | "small"} ItemSize
 * @typedef {{overlayOnHover?: boolean}} ItemConfig
 *
 * @returns
 * @param {{
 *    primary: string,
 *    secondary?: string,
 *    icon?: ReactNode,
 *    variant: ItemVariant | (isSelected: boolean) => ItemVariant,
 *    action?: ReactNode,
 *    config?: ItemConfig,
 *    isFor?: string,
 *    onClick?: Function,
 *    size?: ItemSize,
 *    contentStyle?: CSSProperties
 * }} param0
 */

function Item({
  primary,
  secondary,
  icon,
  variant,
  action,
  children,
  config,
  isFor,
  onClick,
  size,
  contentStyle
}) {
  config = config || defaultConfig;
  size = size || "normal";
  const {
    primary: primaryStyle,
    secondary: secondaryStyle,
    action: actionStyle,
    lightBg,
    darkBg,
    wrapper,
    iconWrapper,
    overlay,
    contentWrapper,
  } = useStyles();
  const { setSelected, variant: contextVariant, itemStyle } = useContext(MenuContext);
  const applicable = useApplicable(isFor);
  const onActualClick = useCallback(
    () => (!isFor || onClick ? onClick() : setSelected(isFor)),
    [isFor, setSelected, onClick]
  );
  const actualVariant = useMemo(
    () => {
      if(variant) {
        return (typeof(variant) === "function") ? variant(applicable) : variant;
      }
      return contextVariant;
    },
    [contextVariant, variant, applicable]
  );
  return (
    <Box>
      <Box
        component="article"
        className={clsx([
          actualVariant === "light" ? lightBg : darkBg,
          wrapper,
          size,
        ])}
        role="button"
        onClick={onActualClick}
        style={contentStyle || itemStyle}
      >
        {config.overlayOnHover && (
          <Box className={clsx([overlay, "overlay"])} />
        )}
        <Grid
          container
          component="header"
          spacing={1}
          alignItems="center"
          className={contentWrapper}
        >
          <Grid item className={iconWrapper}>
            {icon}
          </Grid>
          <Grid item>
            <Typography component="h6" variant="body1" className={primaryStyle}>
              {primary}
            </Typography>
          </Grid>
          {action && (
            <Grid item className={clsx([actionStyle, iconWrapper])}>
              {action}
            </Grid>
          )}
        </Grid>
        <Box>
          <Typography component="p" variant="body2" className={secondaryStyle}>
            {secondary}
          </Typography>
        </Box>
      </Box>
      {children}
    </Box>
  );
}

export default memo(Item);
