import React, { memo, useCallback, useState, useRef, ReactNode } from "react";
import { Grid, makeStyles, Card, CardContent, CardHeader, Divider, Typography, Avatar, Box } from "@material-ui/core";
import { getAttributeByRole, GridCollectionRoles, CollectionAction, renderAttribute, roles } from "../../defines";
import SimpleMenu from "../../../Menus/SimpleMenu";
import useProcessedActions from "../../Auxiliary/hooks/useProcessedActions";
import CollectionElementButtonCouple from "../../Auxiliary/CollectionElementButtonCouple";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    avatar: {
        margin: "auto",
        height: "96px",
        width: "96px"
    },
    item: {
        height: "100%"
    },
    title: {
        textAlign: "center",
        marginBottom: theme.spacing(2)
    },
    subtitle: {
        textAlign: "center"
    }
}));

export default memo(
    /**
     * @template Entity
     * @param {{
     *      element: Entity, 
     *      attributes: CollectionAttributeRenderer<GridCollectionRoles, Entity>[],
     *      keyExtractor: KeyExtractor<Entity>,
     *      onSelect: (element: Entity) => void,
     *      actions: CollectionAction<Entity>[],
     *      checkbox: ReactNode,
     *      selectOnClick?: boolean
     * }} param0 
     */
    ({element, attributes, keyExtractor, onSelect, actions, checkbox, selectOnClick}) => {

        const [action, setAction] = useState(null);
        const {avatar, item, title, subtitle, card} = useStyles();
        const avatarAttribute = getAttributeByRole(attributes, roles.avatar);
        const titleAttribute = getAttributeByRole(attributes, roles.title);
        const subtitleAttribute = getAttributeByRole(attributes, roles.subtitle);
        
        const onElementSelect = useCallback(() => onSelect && onSelect(element), [onSelect, element]);
        const processedOptions = useProcessedActions(actions, element, setAction);

        const actionButtonRef = useRef(null);

        return <Grid 
            item
            key={keyExtractor(element)}
            xs={6} 
            sm={4}
            md={3}
            role={selectOnClick && onSelect && "button"}
            onClick={selectOnClick ? onElementSelect : undefined}
        >   
            {processedOptions && Boolean(processedOptions.length) && <SimpleMenu 
                anchorEl={action && actionButtonRef.current} 
                onClose={() => setAction(false)} 
                options={processedOptions}
                label={renderAttribute(element, titleAttribute)}
            />}
            <Box className={item}>
                <Card className={clsx([card])}>
                    <CardContent>
                        <Grid container alignItems="center" spacing={3}>
                            {checkbox && <Grid item container xs={12} justifyContent="flex-end">
                                <Grid item>{checkbox}</Grid>
                            </Grid>}
                            <Grid item xs={12}>
                                <Avatar 
                                    alt={renderAttribute(element, titleAttribute)}
                                    src={renderAttribute(element, avatarAttribute)}
                                    className={avatar}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" component="header" gutterBottom className={title}>
                                    {renderAttribute(element, titleAttribute)}
                                </Typography>
                                {subtitleAttribute && <Typography variant="body1" component="section" className={subtitle}>
                                    {renderAttribute(element, subtitleAttribute)}
                                </Typography>}
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <CardHeader 
                        title={<CollectionElementButtonCouple 
                            onSelect={onSelect && onElementSelect} 
                            onAction={actions && (() => setAction(true))} 
                            actionButtonRef={ref => actionButtonRef.current = ref}
                        />}
                        disableTypography
                    />
                </Card>
            </Box>
        </Grid>;
    }
);