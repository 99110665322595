import React from "react";
import {
  buildFetcher,
  buildOpenIDTokenManager,
  ActualFetcher,
  OpenIDConnectTokenManager,
  FetcherRequestBuilder,
  headerManager,
} from "@italwebcom/augmented-fetch";
import { Wrappers } from "@italwebcom/anema-react-components";
import { serviceBaseUrl, oauthConfig } from "../../data";
import { AuthorizationServerFacade, client } from "@italwebcom/oauth2-client-web";
import {
  windowProcessor,
  onFetch,
  onError,
  onRedirect,
  pkceGenerator,
  dateGetter,
} from "./auxiliary";
import { FullPageLoader } from "@italwebcom/anema-shared-web-components";
import { useTheme } from "@material-ui/core";
import routes from "../../routes";
import Wrapper from "../../auxiliary/Wrapper";
import { Storage } from "@italwebcom/anema-react-components/Misc";
import { createRoutesFromElements, RouterProvider } from "react-router";
import { createHashRouter } from "react-router-dom";

const { useWrappers } = Wrappers.hooks;

/**
 * @param {{
 *      fetcher: ActualFetcher,
 *      manager: OpenIDConnectTokenManager,
 *      storage: Storage<any, any> | "local" | "cookie",
 *      request: FetcherRequestBuilder
 * }} param0
 * @returns
 */
function WrappersLoader({ fetcher, request, manager, storage, router }) {
  const wrappers = useWrappers(request, serviceBaseUrl);
  const theme = useTheme();
  if (wrappers) {
    return (
      <Wrapper
        fetcher={fetcher}
        manager={manager}
        wrappers={wrappers}
        storage={storage}
      >
        <RouterProvider router={router} />
      </Wrapper>
    );
  } else {
    return (
      <FullPageLoader
        backgroundColor="white"
        progressColor={theme.palette.grey["400"]}
        logoSrc="webapp/img/anema.png"
        active
      />
    );
  }
}

/**
 * @returns
 * @param {{storage: "local" | "cookie"}} param0
 */
export default function realApp({ storage }) {
  /* 
    one-time builders 
  */

  /* 1) builds the aug fetcher & corresp. request builder */
  const { request, fetcher } = buildFetcher({ onFetch, onError, onRedirect });

  fetcher.addDefaultDecorator({
    name: "content-type-setter",
    process: (init) => {
      let h = headerManager(init);
      let b = init && init.body;
      if (
        init &&
        init.method &&
        (init.method === "POST" ||
          init.method === "PUT" ||
          init.method === "PATCH")
      ) {
        h.set("content-type", "application/json");
        if (b && typeof b === "object") {
          b = JSON.stringify(b);
        }
      }
      let out = h.get();
      if(b) {
        out.body = b;
      }
      return out;
    },
  });

  /* 2) builds the token manager */

  // builds the OAuth2Client instance & facade

  const oAuth2Client = client(
    onFetch,
    windowProcessor,
    window.btoa,
    pkceGenerator
  );
  const facade = new AuthorizationServerFacade(oAuth2Client, dateGetter);

  // builds the actual token manager
  const tokenManager = buildOpenIDTokenManager(oauthConfig, facade);
  const router = createHashRouter(createRoutesFromElements(routes()));

  return (
    <WrappersLoader
      manager={tokenManager}
      fetcher={fetcher}
      request={request}
      storage={storage}
      router={router}
    />
  );
}
