import { createContext } from "react";
import { Tag } from "../../../../../__domain/model";

/**
 * @type {{
 *      selected: Tag[],
 *      onAdd: (tag: Tag) => void,
 *      onRemove: (tag: Tag) => void,
 *      onReplace: (target: Tag, element: Tag) => void
 * }}
 */
const args = {};

export default createContext(args);