import { useCallback, useMemo } from "react";
import useTagSelectorFunctions from "../../../hooks/useTagSelectorFunctions";

export default function ({ elements, onAdd, onReplace }) {
  const {
    selected,
    tags,
    onActualChange: onChange,
  } = useTagSelectorFunctions({
    elements,
    onAdd,
    onReplace,
  });
  const onActualChange = useCallback(
    (evt, v) => onChange(parseInt(v)),
    [onChange]
  );
  const availableTags = useMemo(
    () => tags.filter((e) => !e.unavailable),
    [tags]
  );
  return { onActualChange, availableTags, selected, tags };
}
