import {makeStyles} from "@material-ui/core"
import Box from "@material-ui/core/Box";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import Add from "@material-ui/icons/Add";
import clsx from "clsx";
import React, { memo } from "react";

const s = makeStyles((theme) => ({
  fab: {
    "&:hover:not(.disabled)": {
      opacity: 0.75,
    },
    "&.disabled": {
      opacity: 0.5
    },
    backgroundColor: theme.palette.primary.main
  },
}));

function ActiveCartFab({ onClick, disabled, smallScreen, size, noLabel }) {
  const hasLabel = !smallScreen && !noLabel;
  const { fab } = s();
  return (
    <Fab
      onClick={onClick}
      variant={hasLabel ? "extended" : "rounded"}
      size={size || "medium"}
      disabled={disabled}
      className={clsx([fab, disabled && "disabled"])}
      color="primary"
    >
      <Box
        color="white"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Add color="inherit" />
        {hasLabel && (
          <Typography
            component="p"
            variant="subtitle2"
            color="inherit"
            style={{ lineHeight: "inherit", marginLeft: "0.5rem" }}
          >
            Nuova prenotazione
          </Typography>
        )}
      </Box>
    </Fab>
  );
}

export default memo(ActiveCartFab);
