import {components as AuthComponents, hooks as AuthHooks} from "./components/authentication";
import {components as MiscComponents, hooks as MiscHooks} from "./components/misc";
import {components as ClosingDaysComponents, hooks as ClosingDaysHooks, contexts as ClosingDaysContexts} from "./components/entities/ClosingDays";
import {components as OpeningTimesComponents, hooks as OpeningTimesHooks, contexts as OpeningTimeContexts} from "./components/entities/OpeningTimes";
import {components as GlobalSearchComponents, hooks as GlobalSearchHooks} from "./components/entities/GlobalSearch";
import {components as NotificationComponents, hooks as NotificationHooks} from "./components/entities/Notifications";
import {components as DeliveryZonesComponents, hooks as DeliveryZonesHooks} from "./components/entities/DeliveryZones";
import {components as CustomerComponents, hooks as CustomerHooks, contexts as CustomerContexts} from "./components/entities/Customers";
import {components as TagsComponents} from "./components/entities/Tags";
import {components as NewsItemComponents, hooks as NewsItemHooks} from "./components/entities/NewsItems";
import {components as ProductComponents, hooks as ProductHooks} from "./components/entities/Products";
import {components as CartComponents, hooks as CartHooks, contexts as CartContexts, functions as CartFunctions} from "./components/entities/Carts";
import {components as ConversationComponents, hooks as ConversationHooks, contexts as ConversationContexts} from "./components/entities/Conversations";
import { TestCallbacksContext, useTestCallback, callbacks } from "./__test-auxiliary/TestCallbacks";
import authFacade from "./__test-auxiliary/AuthFacade";

import {
  useCustomerHeadersRequest,
  useTimePickerFunctions,
  useAttributeMatcherWrapper,
  useCartProductWrapper,
  useCartWrapper,
  useCategoryWrapper,
  useClosingDayWrapper,
  useConversationWrapper,
  useCostListWrapper,
  useCustomerWrapper,
  useDeliveryCostWrapper,
  useDeliveryZoneWrapper,
  useFeatureValueWrapper,
  useGlobalSearchWrapper,
  useImageWrapper,
  useNewsItemWrapper,
  useNotificationWrapper,
  useOpeningTimeWrapper,
  useProductCategoryWrapper,
  useProductCostWrapper,
  useProductFeatureWrapper,
  useProductImageWrapper,
  useProductWrapper,
  useStatWrapper,
  useTagWrapper,
  useUserWrapper,
  useValidityDiscriminatorWrapper,
  useWrappers,
  useGenericFetch,
  useInitialOperations,
  usePaymentApproval,
  usePrivacyPolicy
} from "./hooks";

import makeWrappers from "./__domain/__mock/wrappers";
import collections from "./__domain/__mock/collections";
import {data} from "./__domain";

const AuthExports = {
  hooks: AuthHooks,
  Components: AuthComponents
}

const {useAlertFunctions, useDefaultComponents, useRuntimeContext, useStorage, useStorageValue} = MiscHooks;

const MiscExports = {
  Components: MiscComponents,
  hooks: {
    useAlertFunctions, 
    useDefaultComponents, 
    useRuntimeContext, 
    useStorage, 
    useStorageValue,
    useGenericFetch,
    useCustomerHeadersRequest,
    useTimePickerFunctions,
    useInitialOperations,
    usePaymentApproval,
    usePrivacyPolicy
  }
}

const ClosingDaysExports = {
  Components: ClosingDaysComponents,
  hooks: ClosingDaysHooks,
  contexts: ClosingDaysContexts
}

const OpeningTimesExports = {
  Components: OpeningTimesComponents,
  hooks: OpeningTimesHooks,
  contexts: OpeningTimeContexts
}

const GlobalSearchExports = {
  Components: GlobalSearchComponents,
  hooks: GlobalSearchHooks
}

const NotificationsExports = {
  Components: NotificationComponents,
  hooks: NotificationHooks
}

const CustomerExports = {
  Components: CustomerComponents,
  hooks: CustomerHooks,
  contexts: CustomerContexts
}

const ConversationExports = {
  Components: ConversationComponents,
  hooks: ConversationHooks,
  contexts: ConversationContexts
}

const DeliveryZonesExports = {
  Components: DeliveryZonesComponents,
  hooks: DeliveryZonesHooks
}

const TagsExports = {
  Components: TagsComponents
}

const CartExports = {
  Components: CartComponents,
  hooks: CartHooks,
  contexts: CartContexts,
  functions: CartFunctions
}

const ProductExports = {
  Components: ProductComponents,
  hooks: ProductHooks
}

const NewsItemExports = {
  Components: NewsItemComponents,
  hooks: NewsItemHooks
}

const TestExports = {
  Contexts: {
    TestCallbacksContext
  },
  hooks: {
    useTestCallback
  },
  misc: {
    authFacade
  },
  callbacks
}

const WrappersExports = {
  hooks: {
    useAttributeMatcherWrapper,
    useCartProductWrapper,
    useCartWrapper,
    useCategoryWrapper,
    useClosingDayWrapper,
    useConversationWrapper,
    useCostListWrapper,
    useCustomerWrapper,
    useDeliveryCostWrapper,
    useDeliveryZoneWrapper,
    useFeatureValueWrapper,
    useGlobalSearchWrapper,
    useImageWrapper,
    useNewsItemWrapper,
    useNotificationWrapper,
    useOpeningTimeWrapper,
    useProductCategoryWrapper,
    useProductCostWrapper,
    useProductFeatureWrapper,
    useProductImageWrapper,
    useProductWrapper,
    useStatWrapper,
    useTagWrapper,
    useUserWrapper,
    useValidityDiscriminatorWrapper,
    useWrappers,
  },
  functions: {
    makeWrappers
  },
  data: {
    collections
  }
}
/*
const MockExports = {
  data
};
*/
export {
  AuthExports as Authentication,
  MiscExports as Misc,
  ClosingDaysExports as ClosingDays,
  OpeningTimesExports as OpeningTimes,
  GlobalSearchExports as GlobalSearch,
  NotificationsExports as Notifications,
  CustomerExports as Customers,
  DeliveryZonesExports as DeliveryZones,
  TagsExports as Tags,
  CartExports as Carts,
  ProductExports as Products,
  WrappersExports as Wrappers,
  ConversationExports as Conversations,
  //MockExports as Mocks,
  NewsItemExports as NewsItems,
  TestExports as Test
};
