import React, { createContext, useCallback, useMemo } from "react";
import UserAreaGate from "../__auxiliary__/UserAreaGate";
import { Customers } from "@italwebcom/anema-react-components";
import { memo } from "react";
import CustomerDashboard from "../../components/_entities/customers/CustomerDashboard";
import { useContext } from "react";
import useRuntimeContext from "../../contexts/Runtime/useRuntimeContext";
import CustomerCredentialsSetter from "../../components/CustomerCredentialsSetter";
import Box from "@material-ui/core/Box";
import { useAuthorized } from "@italwebcom/react-auth-provider";
import CheckCircle from "@material-ui/icons/CheckCircle";

const { useCustomer } = Customers.hooks;

const C = createContext({ tempCart: false });

const ActualSetter = memo(({ setCredentials, onError }) => {
  const { tempCart } = useContext(C);
  return (
    <CustomerCredentialsSetter
      setCredentials={setCredentials}
      onError={onError}
      title={tempCart && "Grazie per la prenotazione!"}
      icon={
        tempCart && (
          <CheckCircle
            style={{ color: "green", verticalAlign: "middle" }}
            fontSize="inherit"
          />
        )
      }
    />
  );
});

/**
 * @param {{title: string, tempCart?: boolean}} param0
 * @returns
 */
function UserAreaDashboard({ title, tempCart, noHeader }) {
  const { customer } = useCustomer();
  const { Navigation, viewNames, stylings, authentication } =
    useRuntimeContext();

  const {
    onActualRedirectToEdit,
    onActualRedirectToChat,
    onActualRedirectToCustomerEdit,
    onActualRedirectToReservations
  } = useMemo(
    () => ({
      onActualRedirectToEdit: (c) => {
        Navigation.navigate(viewNames.cartOverview, { id: c.id, cart: c.id });
      },
      onActualRedirectToCustomerEdit: (c) => {
        Navigation.navigate(viewNames.user.edit);
      },
      onActualRedirectToReservations: (c) => {
        Navigation.navigate(viewNames.user.carts);
      },
      onActualRedirectToChat: (c) => {
        Navigation.navigate(viewNames.user.chat);
      },
    }),
    [Navigation, viewNames]
  );

  const auth = useAuthorized(authentication.authenticatedRole);
  const actualTempCart = useMemo(() => !auth && tempCart, [tempCart]);

  return (
    <C.Provider value={{ tempCart: actualTempCart }}>
      <UserAreaGate
        Setter={ActualSetter}
        title={title || "Area utente"}
        viewName="dashboard"
        PageWrapperProps={{
          noHeader
        }}
      >
        <Box paddingBottom="3rem">
          <CustomerDashboard
            customer={customer}
            sectionHeaderProps={stylings.general.contentPanelHeader}
            onRedirectToEdit={onActualRedirectToEdit}
            onRedirectToCustomerEdit={onActualRedirectToCustomerEdit}
            onRedirectToReservations={onActualRedirectToReservations}
            onRedirectToChat={onActualRedirectToChat}
            readOnlyChat
            initialItemsNumber={5}
          />
        </Box>
      </UserAreaGate>
    </C.Provider>
  );
}

export default memo(UserAreaDashboard);
