import React, { useCallback, useMemo, useState } from "react";
import { TriggerActivateProviderProps } from "../defines";
import Context from "../context";

function neq(arrA, arrB) {
  return JSON.stringify(arrA) !== JSON.stringify(arrB);

}
/**
 * @returns
 * @param {TriggerActivateProviderProps} param0
 */
export default function Provider({
  children,
  triggers,
  DefaultWrapperComponent,
}) {
  const [activeTriggers, setActiveTriggers] = useState([]);
  const triggerNames = useMemo(() => triggers.map((t) => t.name), [triggers]);
  const onEvaluateTriggers = useCallback(
    (t) => setActiveTriggers(current => {
        const newActiveTriggers = triggers.filter(trig => trig.isActive(t)).map(t => t.name);
        /*
          avoids unnecessary frequent updates if triggers are unchanged
        */
        if(neq(current, newActiveTriggers)) {
          return newActiveTriggers;
        } else {
          return current;
        }
    }),
    [triggers, setActiveTriggers]
  );
  console.log(`TriggerActivate.Provider: activeTriggers = ${JSON.stringify(activeTriggers)}`);
  return (
    <Context.Provider
      value={{
        activeTriggers,
        triggers: triggerNames,
        onEvaluateTriggers,
        DefaultWrapperComponent,
      }}
    >
      {children}
    </Context.Provider>
  );
}
