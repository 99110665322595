import { templates } from "@italwebcom/anema-shared-web-components";
import React, { memo } from "react";
import { useParams } from "react-router";

const { cartOverview: CartOverviewPageTemplate } = templates;

const data = {
  title: "Prenotazione",
  backgroundColor: "#F0F2F5",
};

function CartOverviewPage() {
  const { id } = useParams();
  return (
    <CartOverviewPageTemplate
      cart={id}
      backgroundColor={data.backgroundColor}
      title={data.title}
      noHeader
      alertOnTypeChange
    />
  );
}

export default memo(CartOverviewPage);
