import { useCollectionState } from "@italwebcom/custom-react-hooks";
import { useCallback, useEffect } from "react";
import { Tag } from "../../../../../__domain/model";

/**
 * @param {{defaultSelected: Tag[], onChange: (tags: Tag[]) => void}} param0
 * @returns
 */
export default function useManagementFunctions({ defaultSelected, onChange }) {
  const {
    collection: selected,
    onAdd,
    onRemove,
    onReplace,
    onSetAll,
  } = useCollectionState({
    initial: defaultSelected,
    config: {
      matcher: (a, b) => a && b && a.id === b.id,
    },
  });

  useEffect(() => {
    if (selected) {
      onChange(selected);
    }
  }, [selected, onChange]);

  const onReset = useCallback(() => onSetAll([]), [onSetAll]);
  return { onReset, onAdd, onRemove, onReplace, selected };
}
