import React, { useCallback, useMemo } from "react";
import { useEntityEdit } from "@italwebcom/custom-react-hooks";
import Context from "../context";
import { Field } from "../defines";
import useFormValidation from "../hooks/useFormValidation";
import useProcessedFields from "../hooks/useProcessedFields";

/**
 * @typedef {{
 *      defaultValues: Record<string, any>,
 *      onSubmit: (values: Record<string, any>) => void,
 *      fields: Field[]
 * }} ProviderProps
 */

/**
 * @returns
 * @param {ProviderProps} param0
 */
export default function Provider({
  onSubmit,
  defaultValues,
  children,
  fields,
}) {
  const initialValues = useMemo(() => defaultValues || [], [defaultValues]);
  const { element: values, onSet } = useEntityEdit(initialValues);
  const onActualSubmit = useCallback(
    (evt) => {
      evt.preventDefault();
      onSubmit(values);
    },
    [values, onSubmit]
  );
  const processedFields = useProcessedFields(fields);
  console.log(processedFields);
  const { valid, invalidValue } = useFormValidation({
    values,
    fields: processedFields,
  });
  return (
    <form onSubmit={onActualSubmit}>
      <Context.Provider
        value={{
          values,
          onSet,
          invalidValue,
          submittable: valid,
          fields: processedFields,
        }}
      >
        {children}
      </Context.Provider>
    </form>
  );
}
