import React, { memo, useMemo } from "react";
import { EntityManager, useEntityManagerValues } from "@italwebcom/react-entity-manager";
import Context from "../context";
import useOpeningTimeWrapper from "../../../../hooks/wrappers/useOpeningTimeWrapper";
import { useDefaultComponents } from "../../../misc/DefaultComponentsProvider";
import useGenericFetch from "../../../../hooks/generic/useGenericFetch";
import useProviderData from "../hooks/useProviderData";

const ContextProvider = memo(
  ({ children, processOpeningTimes, filterAvailable }) => {
    const { loading } = useEntityManagerValues();
    const { actualOpeningTimes, onDateChange, rawOpeningTimes } =
      useProviderData({filterAvailable});
    return (
      <Context.Provider
        value={{
          loading,
          openingTimes: processOpeningTimes
            ? actualOpeningTimes
            : rawOpeningTimes,
          onDateChange,
        }}
      >
        {children}
      </Context.Provider>
    );
  }
);

/**
 * Manages fetching operations with an EntityManager for the OpeningTime entity &
 * instantiates a corresponding context provider with:
 *
 * - the fetched opening times
 * - a loading boolean flag
 * - the onDateChange(date: string): void callback used to change the week day filter
 *
 * Values may be obtained with the useOpeningTimes hook.
 *
 * Requires an initial value for the week day filter.
 *
 * @param {{
 *    children: ReactNode[],
 *    initialWeekDay: number,
 *    processOpeningTimes?: boolean,
 *    filterAvailable?: boolean
 * }} param0
 * @returns
 */
function OpeningTimeProvider({
  children,
  initialWeekday,
  processOpeningTimes,
  filterAvailable,
}) {
  const { fetchArgsProcessor, defaultCountGetter } = useDefaultComponents();
  const wrapper = useOpeningTimeWrapper();
  const onActualFetch = useGenericFetch(wrapper);
  const initialFilters = useMemo(
    () => [{ attribute: "weekday", value: initialWeekday }],
    [initialWeekday]
  );
  if (wrapper) {
    return (
      <EntityManager.ReadOnly
        fetchArgsProcessor={fetchArgsProcessor}
        countGetter={defaultCountGetter}
        onFetch={onActualFetch}
        initialItemsPerPage={10}
        initialFilters={initialFilters}
        testID="OpeningTimesProvider"
      >
        <ContextProvider
          processOpeningTimes={processOpeningTimes}
          filterAvailable={filterAvailable}
        >
          {children}
        </ContextProvider>
      </EntityManager.ReadOnly>
    );
  } else {
    return children;
  }
}

export default memo(OpeningTimeProvider);
