import React, { useState } from "react";
import { SingleEntityManager } from "@italwebcom/misc-ui-components";
import MissingElementRenderer from "../MissingElementRenderer";
import Check from "@material-ui/icons/Check";

/**
 * @template T
 * @param {{
 *      href: Href,
 *      wrapper: CrudOperationsWrapper<T>,
 *      linkGetter: (entity: T) => string,
 *      Editor: SingleEntityRenderer,
 *      addOperation?: string
 * }} param0
 * @returns
 */
export default function EntityAddManager({
  href,
  wrapper,
  linkGetter,
  Editor,
  addOperation,
  mapper,
}) {
  mapper = mapper || ((e) => e);
  addOperation = addOperation || "create";
  const [added, setAdded] = useState(null);
  if (added) {
    return (
      <MissingElementRenderer
        title="Elemento aggiunto"
        content="Puoi adesso visualizzare l'elemento aggiunto"
        onRedirect={() =>
          href(
            linkGetter(
              added.data ? (added.data.id ? added.data : added.data[0]) : added
            )
          )
        }
        redirectLabel="Vai all'elemento"
        Icon={Check}
      />
    );
  }
  return (
    <SingleEntityManager
      onFetch={() => Promise.resolve({ data: [{}], page: { count: 1 } })}
      onUpdate={(e) =>
        wrapper.execute(addOperation, mapper(e), true).json().then(setAdded)
      }
      Renderer={Editor}
    />
  );
}
