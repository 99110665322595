import React, {
  memo,
  useCallback,
  useState,
  Fragment,
  useMemo,
  useContext,
} from "react";
import {
  CollectionAttributeRenderer,
  CollectionRendererProps,
  defaultKeyExtractor,
  KeyExtractor,
  ListCollectionRoles,
  getAttributeByRole,
  roles,
  renderAttribute,
  CollectionAction,
} from "../defines";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Avatar,
  makeStyles,
  Grid,
  Box,
} from "@material-ui/core";
import CompositeRenderer from "../Auxiliary/CompositeRenderer";
import clsx from "clsx";
import CollectionElementButtonCouple from "../Auxiliary/CollectionElementButtonCouple";
import SimpleMenu from "../../Menus/SimpleMenu";
import useProcessedActions from "../Auxiliary/hooks/useProcessedActions";
import useCollectionCheckboxes from "../../hooks/useCollectionCheckboxes";
import BatchOperationsMenu from "../Auxiliary/BatchOperationsMenu";
import useSortingAttributes from "../Auxiliary/hooks/useSortingAttributes";

const defaultConfig = {
  sortable: true,
};
const useStyles = makeStyles((theme) => ({
  listItem: {
    "&:hover": {
      backgroundColor: "rgb(245, 245, 245)",
      cursor: "pointer",
    },
    "&:hover .MuiAvatar-root": {
      backgroundColor: "white",
    },
    transitionProperty: "background-color",
    transitionDuration: "350ms",
  },
  primary: {
    color: theme.palette.grey["700"],
    fontWeight: "bold",
  },
  secondary: {
    color: theme.palette.grey["500"],
  },
  batchContainer: {
    paddingLeft: "1em",
    paddingRight: "1em",
  },
  highlightedStyle: {
    backgroundColor: "rgb(240, 240, 240)",
  },
}));

const ListElementRenderer = memo(
  /**
   * @template Entity
   * @param {{
   *      element: Entity,
   *      attributes: CollectionAttributeRenderer<ListCollectionRoles, Entity>[],
   *      keyExtractor: KeyExtractor<Entity>,
   *      onSelect: (element: Entity) => void,
   *      actions?: CollectionAction<Entity>[],
   *      checkbox: ReactNode
   * }} param0
   */
  ({
    element,
    attributes,
    keyExtractor,
    onSelect,
    actions,
    checkbox,
    inlineSelect,
  }) => {
    const [actionRef, setActionRef] = useState(null);

    const {
      listItem,
      primary: primaryStyle,
      secondary: secondaryStyle,
    } = useStyles();
    const avatarAttribute = getAttributeByRole(attributes, roles.avatar);
    const titleAttribute = getAttributeByRole(attributes, roles.title);
    const subtitleAttribute = getAttributeByRole(attributes, roles.subtitle);
    const onElementSelect = useCallback(
      () => onSelect && onSelect(element),
      [onSelect, element]
    );
    const processedOptions = useProcessedActions(
      actions,
      element,
      setActionRef
    );
    const onSetActionRef = useCallback(
      (evt) => setActionRef(evt.target),
      [setActionRef]
    );

    return (
      <Fragment>
        <ListItem
          key={keyExtractor(element)}
          className={clsx([
            Boolean(onSelect) && listItem,
            /*highlighted &&
              equal(highlighted, element, keyExtractor) &&
              highlightedStyle,*/
          ])}
          role={Boolean(onSelect) && "button"}
          onClick={onElementSelect}
        >
          {avatarAttribute && (
            <ListItemAvatar>
              <Avatar
                variant="rounded"
                src={renderAttribute(element, avatarAttribute)}
              />
            </ListItemAvatar>
          )}
          <ListItemText
            primary={renderAttribute(element, titleAttribute)}
            primaryTypographyProps={{ className: primaryStyle }}
            secondary={
              subtitleAttribute
                ? renderAttribute(element, subtitleAttribute)
                : "Premi per selezionare"
            }
            secondaryTypographyProps={{ className: secondaryStyle }}
          />
          <ListItemSecondaryAction>
            <Grid container spacing={1} alignItems="center">
              {actions && actions.length ? (
                <Grid item>
                  <CollectionElementButtonCouple
                    onSelect={inlineSelect && onElementSelect}
                    onAction={onSetActionRef}
                  />
                </Grid>
              ) : undefined}
              <Grid item>{checkbox}</Grid>
            </Grid>
          </ListItemSecondaryAction>
        </ListItem>
        <SimpleMenu
          options={processedOptions}
          anchorEl={actionRef}
          onClose={() => setActionRef(null)}
        />
      </Fragment>
    );
  }
);

/**
 * @template Entity
 * @param {CollectionRendererProps<ListCollectionRoles, Entity>} param0
 */
function ListCollectionRenderer({
  elements,
  sortAttribute,
  sortDirection,
  onSortChange,
  attributes,
  onSearch,
  keyExtractor,
  onSelect,
  actions,
  config,
  elementStyleGetter,
}) {
  keyExtractor = keyExtractor || defaultKeyExtractor;
  config = config || defaultConfig;
  const { batchContainer } = useStyles();
  const {
    collection: batchElements,
    checkboxes,
    allCheckbox,
  } = useCollectionCheckboxes({
    elements,
    config: {
      testIDGetter: (e) => `element-${keyExtractor(e)}`,
      allCheckboxTestID: "all-checkbox",
    },
  });
  const batchOperations = actions ? actions.filter((a) => a.batch) : [];
  const hasBatch = Boolean(batchOperations.length);
  const sortableAttributes = useSortingAttributes(attributes);

  const rendered = (
    <Fragment>
      {hasBatch && (
        <Grid
          className={batchContainer}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <BatchOperationsMenu
              elements={batchElements}
              operations={batchOperations}
            />
          </Grid>
          <Grid item>{allCheckbox}</Grid>
        </Grid>
      )}
      <List>
        {Boolean(elements && elements.length) &&
          elements.map((element, index) => (
            <Box style={elementStyleGetter && elementStyleGetter(element)}>
              <ListElementRenderer
                onSelect={onSelect}
                element={element}
                attributes={attributes}
                keyExtractor={keyExtractor}
                actions={actions}
                checkbox={hasBatch && checkboxes[index]}
              />
            </Box>
          ))}
      </List>
    </Fragment>
  );

  return config.noWrapper ? (
    rendered
  ) : (
    <CompositeRenderer
      onSearch={onSearch}
      sortAttribute={sortAttribute}
      sortDirection={sortDirection}
      onSortChange={config.sortable && onSortChange}
      attributes={sortableAttributes}
    >
      {rendered}
    </CompositeRenderer>
  );
}

export default memo(ListCollectionRenderer);
