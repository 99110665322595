import React from "react";
import { HulkButton } from "@italwebcom/misc-ui-components";
import {makeStyles} from "@material-ui/core"
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Warning from "@material-ui/icons/Warning";

const useStyles = makeStyles((theme) => ({
  iconBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(3),
    fontSize: "48px",
  },
  title: {
    textAlign: "center",
    color: theme.palette.grey["600"],
    fontWeight: "bold",
  },
  avatar: {
    fontSize: theme.spacing(12),
    height: theme.spacing(24),
    width: theme.spacing(24),
  },
  message: {
    textAlign: "center",
    color: theme.palette.grey["500"],
  },
}));

/**
 * @typedef {{message: string, onAuth: Function, title?: string, buttonMessage?: string}} UnauthorizedProps
 */

/**
 * @param {UnauthorizedProps} param0
 */
export default function Unauthorized({
  title,
  message,
  onAuth,
  buttonMessage,
}) {
  const {
    iconBox,
    title: titleStyle,
    message: messageStyle,
    avatar,
  } = useStyles();
  buttonMessage = buttonMessage || "Effettua l'autenticazione";
  title = title || "Non sei autorizzato a visualizzare questo contenuto.";
  return (
    <Box component="article">
      <Grid container spacing={3} justifyContent="center">
        <Box className={iconBox}>
          <Avatar className={avatar}>
            <Warning fontSize="inherit" />
          </Avatar>
        </Box>
        <Grid item xs={12} component="header">
          <Typography component="h4" variant="h4" className={titleStyle}>
            {title}
          </Typography>
        </Grid>
        {message && (
          <Grid item xs={12} component="header">
            <Typography
              component="p"
              variant="body1"
              className={messageStyle}
            >
              {message}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} component="section">
          <HulkButton
            fullWidth
            type="button"
            variant="light"
            onClick={onAuth}
            label={buttonMessage || "Effettua l'autenticazione"}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
