import {makeStyles} from "@material-ui/core"

const styles = makeStyles((theme) => ({
  panelWrapper: {
    "&:not(:last-child)": {
      paddingBottom: theme.spacing(2)
    }
  },
  productSelectionViewContent: {
    paddingTop: theme.spacing(2),
  },
  sticky: {
    position: "sticky",
  },
}));

export default styles;
