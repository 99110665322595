import React, { memo, useMemo } from "react";
import ImageButton from "../../ImageButton";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import LocalDining from "@material-ui/icons/LocalDining";
import LocalShipping from "@material-ui/icons/LocalShipping";
import Person from "@material-ui/icons/Person";
import Restaurant from "@material-ui/icons/Restaurant";
import AutoLoadingButton from "../../AutoLoadingButton";

const useStyles = makeStyles((theme) => ({
  userAreaButtonWrapper: {
    [theme.breakpoints.up("xs")]: {
      "&:not(.image)": {
        width: "50%",
      },
    },
    [theme.breakpoints.down("xs")]: {
      "&:not(.image)": {
        marginTop: theme.spacing(4),
        width: "100%",
      },
    },
  },
  buttonWrapper: {
    [theme.breakpoints.down("xs")]: {
      "&:not(:nth-of-type(n+3)).image": {
        marginBottom: theme.spacing(4),
      },
    },
  },
  lightButton: {
    opacity: 0.25,
  },
  outlinedButton: {
    color: "white",
    borderColor: "white",
  },
}));

function ButtonRenderer({
  variant,
  buttonVariant,
  title,
  onClick,
  image,
  typographyProps,
  imageSize,
  icon,
  disabled,
  loading,
}) {
  const { lightButton, outlinedButton } = useStyles();
  if (variant === "image") {
    return (
      <ImageButton
        title={title}
        onClick={onClick}
        src={image}
        alt={title}
        titleTypographyProps={typographyProps}
        size={imageSize || 64}
        disabled={disabled}
        loading={loading}
      />
    );
  } else {
    const isOutlined = variant === "outlined";
    return (
      <AutoLoadingButton
        ButtonProps={{
          variant: !isOutlined ? "contained" : "outlined",
          color: !isOutlined ? "primary" : "default",
          fullWidth: true,
          className: clsx([
            buttonVariant === "light" && lightButton,
            isOutlined && outlinedButton,
          ]),
          startIcon: icon,
          style: { color: "white", borderColor: "white" },
        }}
        onClick={onClick}
        disabled={disabled}
      >
        {title}
      </AutoLoadingButton>
    );
  }
}

/**
 * @typedef {"default" | "in_place" | "delivery"} ReservationType
 * @param {{
 *    variant: "image" | "hulk" | "outlined",
 *    onUserArea: Function,
 *    onReservation: (type: ReservationType) => void,
 *    image?: string,
 *    imageSize?: number,
 *    loading?: boolean
 * }}
 */
function HomepageButtonGroup({
  variant,
  onUserArea,
  onReservation,
  image,
  typographyProps,
  imageSize,
  disabled,
  smallScreen,
  loading,
  noDelivery,
}) {
  const { userAreaButtonWrapper, buttonWrapper } = useStyles();
  const { onDefault, onDelivery, onInPlace } = useMemo(
    () => ({
      onDefault: () => onReservation("default"),
      onDelivery: () => onReservation("delivery"),
      onInPlace: () => onReservation("in_place"),
    }),
    [onReservation]
  );
  const bWrap = clsx([buttonWrapper, variant]);
  const isImage = variant === "image";
  const xs = isImage ? 6 : 12;
  return (
    <Grid container spacing={isImage ? undefined : 2} alignItems="center">
      <Grid item xs={xs} sm className={bWrap}>
        <ButtonRenderer
          variant={variant}
          title="Asporto"
          onClick={onDefault}
          image={image}
          imageSize={imageSize}
          typographyProps={typographyProps}
          icon={<LocalShipping />}
          disabled={disabled}
          loading={loading}
        />
      </Grid>
      <Grid item xs={xs} sm className={bWrap}>
        <ButtonRenderer
          variant={variant}
          title="Tavolo"
          onClick={onInPlace}
          image={image}
          imageSize={imageSize}
          typographyProps={typographyProps}
          icon={<Restaurant />}
          disabled={disabled}
          loading={loading}
        />
      </Grid>
      <Grid item xs={xs} sm className={bWrap}>
        <ButtonRenderer
          variant={variant}
          title="Consegna"
          onClick={onDelivery}
          image={image}
          imageSize={imageSize}
          typographyProps={typographyProps}
          icon={<LocalDining />}
          disabled={disabled || noDelivery}
          loading={loading}
        />
      </Grid>
      <Grid item xs={xs} sm className={bWrap}>
        <ButtonRenderer
          buttonVariant="light"
          variant={variant}
          title="Menù"
          //onClick={onUserArea}
          image={image}
          imageSize={imageSize}
          typographyProps={typographyProps}
          icon={<Person />}
          //disabled={disabled}
          disabled
          loading={loading}
        />
      </Grid>
      {/*!smallScreen && (
        <Grid item xs={xs} sm={isImage ? true : 4} className={bWrap}>
          <Box
            display="flex"
            justifyContent={isImage ? "center" : "flex-end"}
            alignItems="center"
          >
            <Box className={clsx([userAreaButtonWrapper, variant])}>
              <ButtonRenderer
                buttonVariant="light"
                variant={variant}
                title="Menù"
                //onClick={onUserArea}
                image={image}
                imageSize={imageSize}
                typographyProps={typographyProps}
                icon={<Person />}
                //disabled={disabled}
                disabled
                loading={loading}
              />
            </Box>
          </Box>
        </Grid>
      )*/}
    </Grid>
  );
}

export default memo(HomepageButtonGroup);
