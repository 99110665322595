import React, { memo } from "react";
import GenericInput from "../GenericInput";
import { cartType } from "../../../../../../__domain/model";
import { fieldNames } from "../../fields";

/**
 * @returns
 */
function GuestsInput({InputComponent}) {
  return (
    <GenericInput
      field={fieldNames.guests}
      shouldRender={(v) => v.type === cartType.in_place}
      InputProps={{ "data-testid": "guests" }}
      controlled
      InputComponent={InputComponent}
    />
  );
}

export default memo(GuestsInput);