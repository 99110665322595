import { createContext } from "react";
import { InvalidValue, Field } from "../defines";

/**
 * @type {{
 *      values: Record<string, any>,
 *      onSet: (name: string, value: any) => void,
 *      invalidValue?: InvalidValue,
 *      submittable: boolean,
 *      fields: Field[]
 * }}
 */
const args = {
  values: {},
  onSet: () => null,
  invalidValue: null,
  submittable: false,
  fields: []
};

export default createContext(args);
