import React, { memo, useContext } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { getStatusColor, printStatus, printType } from "../../attributes";
import clsx from "clsx";
import { Carts } from "@italwebcom/anema-react-components";
import useStyles from "../styles";
import { formatMessageDate } from "../../../../../functions";
import Context from "../context";
import useCartSelect from "../hooks";
import PlaceholderWrapper from "../../../../PlaceholderWrapper";

const { useCartDerivedAttributes } = Carts.hooks;

function ItemRenderer({ cart, onSelect, placeholder }) {
  const {
    statusText,
    dateTimeText,
    wrapper,
    pastReservation,
    awaitingPaymentText,
    title
  } = useStyles();
  const { past, awaitingPayment } = useCartDerivedAttributes(cart);
  let content = (
    <Grid
      container
      alignItems="center"
      spacing={2}
      className={clsx([wrapper, past && pastReservation])}
    >
      <Grid item xs component="header">
        <Typography component="h6" variant="body1" className={title}>
          {printType(cart.type, cart)}
        </Typography>
        <Typography component="p" variant="subtitle2" className={dateTimeText}>
          {formatMessageDate(cart.date)} per le {cart.time}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          component="p"
          variant="body1"
          className={clsx([statusText, awaitingPayment && awaitingPaymentText])}
          style={{color: getStatusColor(cart.status, cart)}}
        >
          {printStatus(cart.status, false, cart)}
        </Typography>
        <Typography
          component="p"
          variant="h6"
          style={{ fontWeight: "bold", textAlign: "right" }}
        >
          {cart.cost || cart.totalCost} &euro;
        </Typography>
      </Grid>
    </Grid>
  );
  if (onSelect) {
    return (
      <Box role="button" onClick={onSelect}>
        {content}
      </Box>
    );
  } else {
    return (
      <PlaceholderWrapper active={placeholder}>{content}</PlaceholderWrapper>
    );
  }
}

function AuxItemRenderer({ element, placeholder }) {
  const { onRedirectToEdit, onRedirectToOverview } = useContext(Context);
  const onActualSelect = useCartSelect(
    element,
    onRedirectToEdit,
    onRedirectToOverview
  );
  return (
    <ItemRenderer
      cart={element}
      onSelect={!placeholder && onActualSelect}
      placeholder={placeholder}
    />
  );
}

export default memo(AuxItemRenderer);
