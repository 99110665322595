import React, { memo, useCallback } from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { DeliveryZones } from "@italwebcom/anema-react-components";
import { DeliveryZone } from "@italwebcom/anema-react-components/DeliveryZones";

const { Provider } = DeliveryZones.Components;
const { useDeliveryZones } = DeliveryZones.hooks;

const Renderer = memo(
  /**
   * @returns
   * @param {{onSelect: (dzone: DeliveryZone) => void}} param0
   */
  ({ onSelect, defaultValue }) => {
    const { deliveryZones: elements } = useDeliveryZones();
    const onActualChange = useCallback(
      (evt) => {
        onSelect(
          elements.filter((e) => e.id === parseInt(evt.target.value))[0]
        );
      },
      [elements, onSelect]
    );
    return (
      elements && <TextField
        fullWidth
        variant="outlined"
        select
        onChange={onActualChange}
        defaultValue={defaultValue}
      >
        {elements.map((e) => (
          <MenuItem value={e.id} label={e.name} key={e.id}>
            {e.name}
          </MenuItem>
        ))}
      </TextField>
    );
  }
);

/**
 *
 * @param {{onSelect: (dzone: DeliveryZone) => void, defaultValue: DeliveryZone}} param0
 * @returns
 */
function DeliveryZoneSelector({ onSelect, defaultValue }) {
  return (
    <Provider>
      <Renderer onSelect={onSelect} defaultValue={defaultValue} />
    </Provider>
  );
}

export default memo(DeliveryZoneSelector);
