import { createContext, useContext } from "react";

/**
 * @typedef {"list" | "grid"} CustomerProductSelectionViewVariant
 * @type {{variant: "CustomerProductSelectionViewVariant, onSetVariant: (v: CustomerProductSelectionViewVariant) => void }}
 */
const args = {variant: "grid", onSetVariant: () => null};

const Context = createContext(args);
function useVariantProvider() {
    return useContext(Context);
}

export {useVariantProvider, Context};