import { useEffect, useRef, useMemo } from "react";

/**
 * @param {{
 *    onExecute: Function,
 *    active: boolean,
 *    timeout: number,
 *    onSetInterval?: (callback: Function, ms?: number) => number,
 *    onClearInterval?: (handle: number) => void
 * }}
 */
export default function useRepeatingOperation(
  onExecute,
  active,
  timeout,
  onSetInterval,
  onClearInterval,
) {
  const { onActualSetInterval, onActualClearInterval } = useMemo(
    () => ({
      onActualSetInterval: onSetInterval || setInterval,
      onActualClearInterval: onClearInterval || clearInterval,
    }),
    [onSetInterval, onClearInterval]
  );
  const timeoutRef = useRef(null);
  useEffect(() => {
    if (active) {
      timeoutRef.current = onActualSetInterval(onExecute, timeout);
    } else {
      timeoutRef.current && clearInterval(timeoutRef.current);
    }
    return () =>
      timeoutRef.current && onActualClearInterval(timeoutRef.current);
  }, [active, onExecute, timeout, onActualSetInterval, onActualClearInterval]);
}
