import React, { memo } from "react";
import { FiltererProps } from "@italwebcom/misc-ui-components/v2";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";

/**
 * @param {FiltererProps<"categories">} param0
 */
function TagChipCollection({ filters, onFilterRemove }) {
  return (
    <Grid container spacing={2}>
      {filters.map((filter) => (
        <Grid item>
          <Chip
            variant="default"
            color="default"
            label={`${filter.attribute}: ${filter.value}`}
            onDelete={() =>
              onFilterRemove(filter)
            }
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default memo(TagChipCollection);