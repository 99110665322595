import React, { memo } from "react";
import { NewsItems } from "@italwebcom/anema-react-components";
import CarouselCollectionRenderer from "./CarouselCollectionRenderer";
import Context from "./context";
import { colors } from "../../../../themes/anema";

const { Provider } = NewsItems.Components;

function CustomerNewsItemRenderer({
  vertical,
  carouselDotColor,
  carouselDotSize,
  carouselDetailTitleTypographyProps,
  carouselDetailContentTypographyProps,
  initialItemsPerPage
}) {
  return (
    <Context.Provider
      value={{
        carouselDetailContentTypographyProps:
          carouselDetailContentTypographyProps || {
            variant: "body2",
            style: { color: colors.grey.secondary },
          },
        carouselDetailTitleTypographyProps:
          carouselDetailTitleTypographyProps || {
            variant: "h6",
            style: { color: colors.grey.primary, fontWeight: "bold" },
          },
        carouselDotColor,
        carouselDotSize,
      }}
    >
      <Provider initialItemsPerPage={initialItemsPerPage}>
        <CarouselCollectionRenderer vertical={vertical} />
      </Provider>
    </Context.Provider>
  );
}

export default memo(CustomerNewsItemRenderer);
