import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { EntityManager } from "@italwebcom/react-entity-manager";
import useTagWrapper from "../../../../hooks/wrappers/useTagWrapper";
import { useDefaultComponents } from "../../../misc/DefaultComponentsProvider";
import useCartProvider from "../../Carts/CartProvider/hooks/useCartProvider";

const initialSorter = { attribute: "priority", direction: "desc" };

function useTagProviderFetch({wrapper, cart, addCart}) {
  const cartRef = useRef();
  useEffect(() => {
    cartRef.current = cart;
  }, [cart]);
  return useCallback(args => {
    if(cartRef.current || !addCart) {
      return wrapper.fetch(args).json();
    } else {
      return Promise.resolve({data: []});
    }
  }, [cartRef, addCart]);
}

/**
 * @param {{filterType?: string, addCart?: boolean}} param0
 * @returns
 */
function TagProvider(
  { filterType, children, addCart, initialItemsPerPage, lazyCountRecompute },
  ref
) {
  const tagWrapper = useTagWrapper();
  const entityManagerRef = useRef();
  const { cart } = useCartProvider();

  const { fetchArgsProcessor, defaultCountGetter } = useDefaultComponents();
  const onActualFetch = useTagProviderFetch({wrapper: tagWrapper, cart, addCart});

  useEffect(() => {
    if (addCart && cart && entityManagerRef) {
      entityManagerRef.current.replaceFilter(
        { attribute: "cart" },
        { attribute: "cart", value: cart.id }
      );
    }
  }, [cart, addCart, entityManagerRef]);

  useImperativeHandle(
    ref,
    () => ({
      refresh: () =>
        entityManagerRef.current && entityManagerRef.current.refresh(),
    }),
    [entityManagerRef]
  );

  const initialFilters = useMemo(() => {
    let out = [{ attribute: "filterType", value: filterType }];
    if (cart) {
      out.push({ attribute: "cart", value: cart.id });
    }
    return out;
  }, [cart, addCart, filterType]);

  return (
    <EntityManager.ReadOnly
      fetchArgsProcessor={fetchArgsProcessor}
      countGetter={defaultCountGetter}
      onFetch={onActualFetch}
      initialFilters={initialFilters}
      initialItemsPerPage={initialItemsPerPage || 10}
      initialSorter={initialSorter}
      attributes={[]}
      ref={entityManagerRef}
      lazyCountRecompute={lazyCountRecompute}
    >
      {children}
    </EntityManager.ReadOnly>
  );
}

export default memo(forwardRef(TagProvider));
