import TagSelector from "./TagSelector";
import TagProvider from "./TagProvider";
import TagGroupView from "./TagGroupView";

const components = {
    Selector: TagSelector,
    GroupView: TagGroupView,
    Provider: TagProvider
}

export {components};