import { useEffect, useMemo, useState } from "react";

function getInitial(initial) {
  return initial || 0;
}

/**
 * @returns
 * @param {{initial?: number, resetOnInitialChange?: boolean}} initial
 */
function useValue(args) {
  /* istanbul ignore next */
  args = args || {};

  const { initial, resetOnInitialChange } = args;
  const [value, setValue] = useState(getInitial(initial));

  useEffect(() => {
    if (typeof initial !== "undefined" && resetOnInitialChange) {
      setValue((v) => {
        if (v !== initial) {
          return initial;
        } else {
          return v;
        }
      });
    }
  }, [initial, resetOnInitialChange, setValue]);

  const { increase, decrease, reset } = useMemo(
    () => ({
      increase: (o) => setValue((v) => v + o),
      decrease: (o) => setValue((v) => v - o),
      reset: () => setValue(getInitial(initial)),
    }),
    [setValue, initial]
  );

  return {
    increase,
    decrease,
    value,
    reset,
    set: setValue,
  };
}

export default useValue;
