import TextField from "@material-ui/core/TextField";
import React, { memo } from "react";

function Input(props) {
  const InnerProps = { ...props };
  const label = props.label;
  delete InnerProps.label;
  const {error, helperText} = props;
  return (
    <TextField
      {...InnerProps}
      inputProps={{
        style: !props.multiline ? { padding: "14px 12px" } : {},
        ...(props.inputProps || {}),
      }}
      variant="outlined"
      fullWidth
      error={error}
      helperText={helperText}
      type={props.type}
    />
  );
}

export default memo(Input);
