import { templates } from "@italwebcom/anema-shared-web-components";
import { memo } from "react";

const Template = templates.user.dashboard;

function UserDashboardPage() {
  let sParams = new URLSearchParams(
    window.location.href.replace(/^.*[\\?]/, "")
  );
  return <Template tempCart={sParams.get("tempCart")} noHeader />;
}

export default memo(UserDashboardPage);
