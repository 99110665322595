import dayjs from "dayjs";

export function formatMessageDate(e, format) {
    return dayjs(e).format(format || "DD MMM YYYY");
}

export function formatMessageHour(e) {
    return dayjs(e).format("HH:mm");
}

export function today() {
    return dayjs().format("YYYY-MM-DD");
}