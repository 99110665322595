import React, { memo, useContext } from "react";
import useFormData from "../hooks/useFormData";
import Button from "@material-ui/core/Button";

/**
 * @param {{label: string, fullWidth?: boolean}} param0
 */
function Submit({ label, fullWidth, disabled, startIcon }) {
  const { submittable } = useFormData();
  return (
    <Button
      type="submit"
      variant="outlined"
      color="primary"
      disabled={!submittable || disabled}
      fullWidth={fullWidth}
      startIcon={startIcon}
    >
      {label}
    </Button>
  );
}

export default memo(Submit);
