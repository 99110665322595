import React, { memo, useCallback, useMemo } from "react";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/Add";

/**
 * @param {{selectedElement: CartProduct, onChange: (quantity: number) => void}, stock?: number} param0
 * @returns
 */
function PlusButton({ selectedElement, onChange, stock, disabled }) {
  const onActualChange = useCallback(
    () => onChange(selectedElement.quantity + 1),
    [onChange, selectedElement]
  );
  const actuallyDisabled = useMemo(
    () => disabled || (stock && selectedElement.quantity === stock),
    [selectedElement, stock, disabled]
  );
  return (
    <IconButton
      disabled={actuallyDisabled}
      size="small"
      onClick={onActualChange}
    >
      <Add />
    </IconButton>
  );
}

export default memo(PlusButton);
