import React, { ChangeEvent, ComponentType, useCallback, InputHTMLAttributes } from "react";
import useFormData from "../hooks/useFormData";

function getField(fields, field) {
    const f = fields.filter(f => f.id === field);
    if(f.length) {
        return f[0];
    } else {
        throw Error(`GenericForm.Input.<getField>: expected matching field for input value ${field}, but got null.`);
    }
}

/**
 * @typedef {{
 *      onChange: (evt: ChangeEvent<HTMLInputElement>) => void,
 *      defaultValue?: any,
 *      value?: any
 * }} InputComponentProps
 * @typedef {ComponentType<InputComponentProps>} InputComponent
 * @typedef {{
 *      Component: InputComponent, 
 *      field: string, 
 *      controlled?: boolean,
 *      InputProps?: any
 * }} InputProps
 */

/**
 * @returns
 * @param {InputProps} param0
 */
export default function Input({ Component, field, controlled, InputProps, disabled }) {
  const { onSet, values, fields } = useFormData();
  const daVal = values[field];
  const daField = getField(fields, field);
  const onChange = useCallback(
    (evt) => {
      onSet(field, evt.target.value);
    },
    [onSet, field]
  );
  return (
    <Component
      value={controlled ? daVal : undefined}
      defaultValue={controlled ? null : daVal}
      onChange={onChange}
      type={daField.type}
      disabled={disabled}
      {...(InputProps || {})}
    />
  );
}
