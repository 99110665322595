import { OnAfterSaveCallback } from "@italwebcom/in-memory-collection";
import { CrudOperationsWrapper } from "@italwebcom/crud-operations-wrapper";

/**
 * @template T
 * @param {Function} hateoasWrapper
 * @return {OnAfterSaveCallback<T>}
 */
export const makeOnAfterSave = (hateoasWrapper) => (element, collection) => {
    element.id = collection.count();
    let out = hateoasWrapper(element);
    element._links = out._links;
    element._templates = out._templates;
};
