const out = {
  Conversations: {
    postMessage: "Conversation.postMessage",
  },
  Authentication: {
    setTempCustomerCredentials: "Authentication.setTempCustomerCredentials",
  },
  Carts: {
    cartProductSave: "Carts.cartProductSave",
    cartProductDelete: "Carts.cartProductDelete",
    cartSave: "Carts.cartSave"
  }
};

export default out;
