import { createContext } from "react";

/**
 * @type {{
 *      count: number,
 *      page: number,
 *      itemsPerPage: number,
 *      onDataChange: (count: number, page: number, itemsPerPage: number) => void
 * }}
 */
const contextArgs = {
    count: null,
    page: null,
    itemsPerPage: null,
    onDataChange: (c, p, i) => null
};

export default createContext(contextArgs);