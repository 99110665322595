import { useState, useEffect, useCallback } from "react";

const defaultConfig = {
  setter: (e, a, value) => {
    e[a] = value;
  },
  clearer: (e, a) => {
    delete e[a];
  },
  toggler: (e, a) => {
    e[a] = !e[a];
  },
  adder: (e, a, m) => {
    let v = e[a];
    if (v instanceof Array) {
      v.push(m);
    } else {
      e[a] = parseFloat(v) + m;
    }
  },
  remover: (e, a, m) => {
    let v = e[a];
    if (v instanceof Array) {
      let i = v.indexOf(m);
      if (i !== -1) {
        v.splice(i, 1);
      }
    } else {
      e[a] = parseFloat(v) - m;
    }
  },
  validator: () => true,
};
/**
 * @template T
 * @template {string} Attributes
 * @typedef {{
 *      element: T,
 *      valid: boolean,
 *      onSet: (attribute: Attributes, value: any) => void,
 *      onClear: (attribute: Attributes) => void,
 *      onOperation: (operation: (value: T) => void) => void,
 *      onToggle: (attribute: Attributes) => void,
 *      onAdd: (a: Attributes, v: any) => void,
 *      onRemove: (a: Attributes, v: any) => void
 * }} HookOutValues
 */

/**
 * @template T
 * @template {string} Attributes
 * @typedef {{
 *      setter?: (element: T, a: Attributes, v: any) => void,
 *      clearer?: (element: T, a: Attributes) => void,
 *      toggler?: (element: T, a: Attributes) => void,
 *      adder?: (element: T, a: Attributes, v: any) => void,
 *      remover?: (element: T, a: Attributes, v: any) => void,
 *      validator?: (element: T) => boolean
 * }} HookConfig
 */

/**
 * @template T
 * @template {string} Attributes
 * @param {T} initialValue
 * @param {HookConfig} config
 * @return {HookOutValues<T, Attributes>}
 */
export function useEntityEdit(initialValue, config) {
  config = config || defaultConfig;

  const { setter, clearer, toggler, adder, remover, validator } = config;
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue((v) => {
      if (v !== initialValue) {
        return initialValue;
      } else {
        return v;
      }
    });
  }, [initialValue]);

  const makeValueOperation = useCallback(
    (op) =>
      setValue((currentValue) => {
        let newValue = { ...currentValue };
        op(newValue);
        return newValue;
      }),
    [setValue]
  );

  const onMap = useCallback(
    (mapper) =>
      setValue((cVal) => {
        const newVal = { ...cVal };
        const out = mapper(newVal);
        return out;
      }),
    [setValue]
  );

  return {
    valid: validator(value),
    element: value,
    onSet: useCallback(
      (a, v) => makeValueOperation((val) => setter(val, a, v)),
      [setter, makeValueOperation]
    ),
    onClear: (a) => makeValueOperation((val) => clearer(val, a)),
    onToggle: (a) => makeValueOperation((val) => toggler(val, a)),
    onAdd: (a, v) => makeValueOperation((val) => adder(val, a, v)),
    onRemove: (a, v) => makeValueOperation((val) => remover(val, a, v)),
    onSetAll: setValue,
    onOperation: makeValueOperation,
    onMap
  };
}

/**
 * @param {HookConfig} config
 */
export default function makeHook(config) {
  config = {
    ...defaultConfig,
    ...config,
  };
  return (v) => useEntityEdit(v, config);
}
