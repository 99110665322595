import React, { memo } from "react";
import Grid from "@material-ui/core/Grid";
import { CartTypeSelector, DateInput } from "./_Auxiliary";
import { Carts } from "@italwebcom/anema-react-components";
import {
  ActualTimePicker,
  ActualDeliveryZoneSelector,
  ActualAddressInput,
  ActualGuestsInput,
  ActualNotesInput,
} from "./_Inputs";
import InputWrapperGroup from "./_Auxiliary/InputWrapperGroup";

const { CartCustomerEditor } = Carts.Components;

function AuxCartCustomerEditor({ cart, onSave, editable, loading, noSubmit }) {
  const { onSet } = Carts.hooks.useCartTypeSet();
  return (
    <InputWrapperGroup.Provider readOnly={loading || !editable}>
      <CartCustomerEditor.Provider
        onSubmit={onSave}
        defaultValues={cart}
        onTypeChange={onSet}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CartCustomerEditor.Inputs.Type InputComponent={CartTypeSelector} />
          </Grid>
          <Grid item xs={12}>
            <CartCustomerEditor.Inputs.Date InputComponent={DateInput} />
          </Grid>
          <Grid item xs={12}>
            <CartCustomerEditor.Inputs.Time
              InputComponent={ActualTimePicker}
              controlled
            />
          </Grid>
          <CartCustomerEditor.Inputs.DeliveryZone
            InputComponent={ActualDeliveryZoneSelector}
          />
          <CartCustomerEditor.Inputs.Address
            InputComponent={ActualAddressInput}
          />
          <CartCustomerEditor.Inputs.Guests
            InputComponent={ActualGuestsInput}
          />
          <Grid item xs={12}>
            <CartCustomerEditor.Inputs.Notes
              InputComponent={ActualNotesInput}
            />
          </Grid>
          {!noSubmit && (
            <Grid item xs={12}>
              <CartCustomerEditor.Submit
                label="Conferma e prosegui"
                fullWidth
              />
            </Grid>
          )}
        </Grid>
      </CartCustomerEditor.Provider>
    </InputWrapperGroup.Provider>
  );
}

export default memo(AuxCartCustomerEditor);
