import { TokenResponse, DiscoveryDocument, UserInfo } from "@italwebcom/augmented-fetch";

/**
 * @typedef {"resolve" | "reject"} PromiseOutcome
 * @param {{data: any, delay?: number, outcome?: PromiseOutcome}} param0
 * @returns
 */
function promiseWithDelay({ data, delay, outcome }) {
  const reject = outcome === "reject";
  if (delay) {
    return new Promise((res, rej) => {
      setTimeout(() => (reject ? rej(data) : res(data)), delay);
    });
  } else {
    return reject ? Promise.reject(data) : Promise.resolve(data);
  }
}

/**
 * @typedef {{
 *      accessToken: string,
 *      discoveryDocument?: DiscoveryDocument,
 *      delay?: number,
 *      userInfo: UserInfo
 * }} AuthFacadeInputs
 */

class AuthFacade {
  /**
   * @type {UserInfo}
   */
  _userInfo;

  /**
   * @type {string}
   */
  _accessToken;

  /**
   * @type {DiscoveryDocument}
   */
  _discoveryDocument;

  /**
   * @type {boolean}
   */
  _fail;

  /**
   * @type {number}
   */
  _delay;

  /**
   * @param {AuthFacadeInputs} param0
   */
  constructor({ accessToken, discoveryDocument, userInfo, delay, fail }) {
    this._accessToken = accessToken;
    this._discoveryDocument = discoveryDocument;
    this._userInfo = userInfo;
    this._fail = fail;
    this._delay = delay;
  }

  loadAsync() {
    return Promise.resolve(this);
  }

  fetchUserInfoAsync() {
    return this.p(this._userInfo);
  }

  fetchDiscoveryAsync() {
    return this.p(this._discoveryDocument);
  }

  /**
   * @return {TokenResponse}
   */
  exchangeCodeAsync() {
    return this.p({
      accessToken: this._accessToken,
      refreshToken: this._accessToken,
      expiresAt: 10000,
      issuedAt: 0,
    });
  }

  promptAsync() {
    return this.p({
      type: "success",
      params: {
        code: "c",
      },
    });
  }

  isTokenFresh() {
    return true;
  }

  /**
   * @param {any} data
   * @returns
   */
  p(data) {
    return promiseWithDelay({
      data,
      delay: this._delay,
      outcome: this._fail ? "reject" : "resolve",
    });
  }

  /**
   * @param {boolean} f
   * @returns
   */
  fail(f) {
    this._fail = f;
    return this;
  }

  /**
   * @param {number} d
   * @returns
   */
  delay(d) {
    this._delay = d;
    return this;
  }

  request() {
    return this;
  }

  makeToken(d) {
    return d;
  }
}

/**
 * @param {AuthFacadeInputs} param0
 * @returns
 */
export default function authFacade({
  accessToken,
  discoveryDocument,
  userInfo,
  delay,
}) {
  return new AuthFacade({ accessToken, discoveryDocument, userInfo, delay });
}
