import CartEditorPanel from "./CartEditorPanel";
import { Carts } from "@italwebcom/anema-react-components";
import React, { memo, useEffect } from "react";
import { useRuntimeContext } from "../../../../contexts";
import { useAuthorized } from "@italwebcom/react-auth-provider";

const { useCartAndProducts, useCartDerivedAttributes } = Carts.hooks;

/*
function useRedirectToAuth({ cart }) {
  const { awaitingPayment, editable } = useCartDerivedAttributes(cart);
  const { Navigation, viewNames, authentication } = useRuntimeContext();
  const isAuth = useAuthorized(authentication.authenticatedRole);
  
  useEffect(() => {
    !isAuth &&
      typeof editable === "boolean" &&
      typeof awaitingPayment === "boolean" &&
      !editable &&
      !awaitingPayment &&
      Navigation.navigate(viewNames.user.dashboard, { tempCart: true });
  }, [awaitingPayment, editable, Navigation, isAuth]);
}
*/

function CartOverviewEditor({ onCartProductAdd, headerProps, smallScreen, alertOnTypeChange }) {
  const { cart, onCartSave, cartLoading, onCartRefresh } = useCartAndProducts();
  //useRedirectToAuth({ cart });
  return (
    <CartEditorPanel
      cart={cart}
      onSave={onCartSave}
      onCartProductAdd={onCartProductAdd}
      headerProps={headerProps}
      smallScreen={smallScreen}
      cartLoading={cartLoading}
      alertOnTypeChange={alertOnTypeChange}
      onCartRefresh={onCartRefresh}
    />
  );
}

export default memo(CartOverviewEditor);
