import { useMemo } from "react";
import { useRuntimeContext } from "../../../../misc/RuntimeProvider";
import { useStorageValue } from "../../../../misc/StorageProvider";

/**
 * @returns 
 */
export default function useTempCartId() {
    const {cartIdName} = useRuntimeContext();
    const actualCartIdName = useMemo(() => cartIdName || "x-cart-id", [cartIdName]);
    const id = useStorageValue(actualCartIdName);
    const actualId = useMemo(() => id && parseInt(id), [id]);
    return actualId;
}