import {ProductCost} from "../../model";
import products from "./products";
import costLists from "./costLists";
import wrappers from "../hateoas";

/**
 * @type {ProductCost[]}
 */
const productCosts = [];

for(let i=0; i<costLists.length; i++) {
    for(let j=0; j<products.length; j++) {
        const p = products[j];
        let pCost = wrappers.productCost({
            product: p,
            costList: costLists[i],
            productId: p.id,
            cost: 10.0 + i*0.1 + j*0.1,
            active: true
        });
        productCosts.push(pCost);
    }
}

export default productCosts;