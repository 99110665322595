import React, { memo, useCallback, useState } from "react";
import { Carts } from "@italwebcom/anema-react-components";
import AutoLoadingButton from "../../../../../AutoLoadingButton";
import {
  CostChip,
  QuantityChip,
} from "../../../CustomerProductSelectionView/ItemRenderers/_Auxiliary";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Grid from "@material-ui/core/Grid";
import CustomDropdownMenu from "../../../../../CustomDropdownMenu";
import DeleteForever from "@material-ui/icons/DeleteForever";
import Edit from "@material-ui/icons/Edit";

const {
  useCartAndProducts,
  useCachedCartProductSelection,
  useCartDerivedAttributes,
} = Carts.hooks;

const s = makeStyles((theme) => ({
  btnGroup: {
    marginBottom: theme.spacing(1),
  },
}));

//fucking sucks, but hey
function c(q, p) {
  return q * p.cost;
}

function opts(n) {
  let out = [];
  for (let i = 1; i < n; i++) {
    out.push({ label: i.toString(), value: i });
  }
  return out;
}

function renderQuantitySelect({ options, onSet, quantity, rendered }) {
  if (rendered) {
    return (
      <CustomDropdownMenu
        options={options}
        value={quantity || 1}
        onChange={onSet}
      />
    );
  }
  return null;
}

function renderChip({ product, selectedElement, selected, quantity, loading }) {
  if (selected || selectedElement) {
    /* renders selection cost, if present */
    return (
      <QuantityChip
        selectedElement={
          selected ? { quantity, cost: c(quantity, product) } : selectedElement
        }
        loading={loading}
      />
    );
  }

  /* renders product cost otherwise */
  return <CostChip element={product} />;
}

function renderMainButton({
  cart,
  onCartRefresh,
  loading,
  editing,
  selected,
  productUnavailable,
  onConfirmSelection,
  onEdit,
  onDelete,
  onAdd,
  onSelectIngredients,
  editable,
}) {
  const { btnGroup } = s();
  if (!cart) {
    /* if cart is missing, it renders the refresh button */
    return (
      <AutoLoadingButton loading={loading} onClick={onCartRefresh}>
        Ricarica prenotazione
      </AutoLoadingButton>
    );
  }

  if (editable) {
    if (editing) {
      /* if cart is editable & user is currently editing the quantity -> render the conf button */
      return (
        <AutoLoadingButton
          onClick={onConfirmSelection}
          loading={loading}
          key="confirm_button"
        >
          Conferma selezione
        </AutoLoadingButton>
      );
    } else {
      if (!selected) {
        /* if not selected -> render add button (disabled if product is unavailable) */
        return (
          <AutoLoadingButton
            disabled={productUnavailable}
            loading={loading}
            onClick={onAdd}
          >
            {productUnavailable ? "Non disponibile" : "Seleziona"}
          </AutoLoadingButton>
        );
      } else {
        /* otherwise, render the edit button + delete button */
        return (
          <Box>
            <ButtonGroup fullWidth className={btnGroup}>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={onEdit}
                startIcon={<Edit />}
                disabled={loading}
              >
                Selezione
              </Button>
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={onDelete}
                startIcon={<DeleteForever />}
                disabled={loading}
              >
                Rimuovi
              </Button>
            </ButtonGroup>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              onClick={onSelectIngredients}
              disabled={loading}
            >
              Modifica ingredienti ed extra
            </Button>
          </Box>
        );
      }
    }
  }
}

/**
 * Renders the footer for product quantity selection.
 *
 * Initially it renders:
 * - a disabled button if the current selection isn't editable, according to the cart status
 * - a button to refresh the cart, if missing due to loading errors,
 * - a button to add the product to the cart, if the current selection is missing
 * - a button to edit the current selection, if present
 *
 * If the selection is edited/product added to the cart:
 * - a select dropdown menu with confirmation button is rendered
 * - a removal icon button is rendered, assuming the quantity for the current selection is > 0
 * - a button for ingredient edit is edited, assuming the above condition holds
 *
 * To display the current quantity selection & related cost:
 * - the selectedElement.cost property is used, unless selection is being edited
 * - if selection is being edited, the cached selected quantity is used to compute the cost with the product.cost property
 */
function Footer({ product, onSelectIngredients }) {
  const [editing, setEditing] = useState(false);

  const onEdit = useCallback(() => setEditing(true), [setEditing]);

  //current cart, loading flash & refresh callback
  const { cart, cartLoading, productsLoading, onCartRefresh } = useCartAndProducts();

  //editable status for the cart
  const { editable } = useCartDerivedAttributes(cart);

  console.log(cart);
  console.log(editable);

  //cached quantity management & selected product
  const { quantity, onSet, onDelete, selectedElement, selected, onConfirm } = useCachedCartProductSelection(product);

  const onActualConfirm = useCallback(
    (c) => {
      onConfirm(c);
      setEditing(false);
    },
    [onConfirm, setEditing]
  );

  const options = opts(10);
  const loading = cartLoading || productsLoading;

  const chip = renderChip({
    product,
    selected: editing && Boolean(quantity),
    quantity,
    selectedElement,
    loading,
  });

  const isEditable = editable;

  const button = renderMainButton({
    cart,
    onCartRefresh,
    loading,
    editing,
    selected,
    productUnavailable: product && product.unavailable,
    onConfirmSelection: onActualConfirm,
    onEdit,
    onDelete,
    onAdd: onEdit,
    onSelectIngredients,
    editable: isEditable,
  });

  const select = renderQuantitySelect({
    options,
    quantity,
    onSet,
    rendered: editing && isEditable,
  });

  return (
    <Box component="footer">
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
      >
        <Grid item>{chip}</Grid>
        <Grid item>{select}</Grid>
        <Grid item xs={12}>
          {button}
        </Grid>
      </Grid>
    </Box>
  );
}

export default memo(Footer);
