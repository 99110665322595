import { GenericForm, R } from "@italwebcom/react-generic-form";
import fields, { fieldNames } from "../fields";
import useOpeningTimes from "../../../OpeningTimes/hooks/useOpeningTimes";
import useDeliveryZones from "../../../DeliveryZones/hooks/useDeliveryZones";
import useClosingDays from "../../../ClosingDays/hooks/useClosingDays";
import Context from "../context";
import React, {
  useCallback,
  ReactNode,
  useRef,
  useMemo,
  useEffect,
  memo,
} from "react";
import DeliveryZoneCostProvider from "../../../DeliveryZones/DeliveryZoneCostProvider/Component";
import { cartType } from "../../../../../__domain/model";
import undef from "../../../../../misc/undef";

function valuesMapper(values, attribute) {
  let out = values;
  /* resets time selection after date change */
  if (attribute === fieldNames.date) {
    out = { ...values, time: "" };
  }
  if (
    attribute === fieldNames.type &&
    values[fieldNames.type] !== cartType.delivery
  ) {
    out = { ...out, deliveryZone: null };
  }
  if (values[fieldNames.type] === cartType.in_place) {
    out[fieldNames.paymentType] = "direct";
  }
  console.log(`valuesMapper: out values ${JSON.stringify(out)}`);
  return out;
}

/* disables input selection when appropriate */
function shouldDisable(input, values) {
  console.log(
    `shouldDisable: input ${input}, values ${JSON.stringify(values)}`
  );
  
  if (input === fieldNames.type) {
    return false;
  }

  const hasType = Boolean(values[fieldNames.type]);
  const hasDate = Boolean(values[fieldNames.date]);
  const hasDzone = Boolean(values[fieldNames.deliveryZone]);

  /* disables inputs for everything but type unless a value is selected */
  if (!hasType) {
    return input !== fieldNames.type;
  }

  /* disables everything else unless date is selected */
  if (!hasDate) {
    return (
      input !== fieldNames.type &&
      input !== fieldNames.date
    );
  }

  /* disables address input unless delivery zone is selected */
  if (!hasDzone) {
    return input === fieldNames.address;
  }
}

/**
 * @param {{
 *      defaultValues?: R,
 *      onSubmit: (values: R) => void,
 *      children: ReactNode[],
 *      onChange?: (attribute: string, value: any) => void,
 *      onTypeChange: (type: string) => void,
 *      onDeliveryZoneChange: (deliveryZone: any) => void
 * }} param0
 * @returns
 */
function Provider({
  defaultValues,
  onSubmit,
  children,
  onChange,
  onTypeChange,
  loading,
}) {
  const {
    openingTimes,
    onDateChange,
    loading: openingTimesLoading,
  } = useOpeningTimes();
  const { loading: deliveryZonesLoading, deliveryZones } = useDeliveryZones();
  const { closingDays, loading: closingDaysLoading } = useClosingDays();

  const dCostRef = useRef();
  const { onDeliveryCostFetch, onDeliveryCostReset } = useMemo(
    () => ({
      onDeliveryCostFetch: (deliveryZone) => {
        dCostRef.current &&
          dCostRef.current.fetch({ cart: defaultValues, deliveryZone });
      },
      onDeliveryCostReset: () => dCostRef.current && dCostRef.current.reset(),
    }),
    [dCostRef, defaultValues]
  );

  useEffect(() => {
    console.log(defaultValues);
    if (defaultValues && onDateChange && defaultValues[fieldNames.date]) {
      onDateChange(defaultValues[fieldNames.date]);
    }
  }, [defaultValues, onDateChange]);

  const onActualChange = useCallback(
    (a, v) => {
      console.log(
        `onActualChange: attribute ${a}, value ${
          typeof v === "object" ? JSON.stringify(v) : v
        }`
      );
      if (a === fieldNames.date) {
        onDateChange(v);
      }

      if (onChange) {
        onChange(a, v);
      }

      if (onTypeChange && a === fieldNames.type) {
        onTypeChange(v);
      }

      if (a === fieldNames.deliveryZone) {
        onDeliveryCostFetch(v.id ? v.id : v);
      } else {
        if (a === fieldNames.type && v !== cartType.delivery) {
          onDeliveryCostReset();
        }
      }
    },
    [
      onDateChange,
      onChange,
      onTypeChange,
      onDeliveryCostFetch,
      onDeliveryCostReset,
    ]
  );

  const onActualSubmit = useCallback(
    (data) => {
      let out = { ...data, status: "CLOSED" };
      if (!undef(data.deliveryZone)) {
        let v = undef(data.deliveryZone.id)
          ? parseInt(data.deliveryZone)
          : data.deliveryZone.id;
        out.deliveryZone = v;
        out.deliveryZoneId = v;
      }
      if (!undef(defaultValues.id)) {
        out = { ...out, id: defaultValues.id };
      }
      delete out.totalCost;
      onSubmit(out);
    },
    [onSubmit, defaultValues]
  );

  return (
    <GenericForm.Provider
      fields={fields}
      defaultValues={defaultValues}
      onSubmit={onActualSubmit}
      onChange={onActualChange}
      valuesMapper={valuesMapper}
      shouldDisable={shouldDisable}
      loading={loading}
    >
      <Context.Provider
        value={{
          openingTimes,
          openingTimesLoading,
          deliveryZones,
          deliveryZonesLoading,
          closingDays,
          closingDaysLoading,
        }}
      >
        <DeliveryZoneCostProvider ref={dCostRef}>
          {children}
        </DeliveryZoneCostProvider>
      </Context.Provider>
    </GenericForm.Provider>
  );
}

export default memo(Provider);
