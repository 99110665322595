import React, { ReactNode } from "react";
import { SingleEntityRenderer } from "../defines";
import useEntityManagementWithFeedback from "../../hooks/useEntityManagementWithFeedback";
import { Box } from "@material-ui/core";
import BlockingLoader from "../../Misc/BlockingLoader";

/**
 * @template T
 * @typedef {{
 *      onFetch: Function,
 *      onDelete: Function,
 *      Renderer: SingleEntityRenderer<T>,
 *      onUpdate?: function,
 *      renderOnMissingElement?: () => ReactNode
 * }} SingleEntityManagerProps
 */

/**
 * @template T
 * @param {SingleEntityManagerProps<T>} param0
 */
export default function SingleEntityManager({
  onFetch,
  onDelete,
  onUpdate,
  Renderer,
  renderOnMissingElement,
}) {
  const {
    onDelete: onActualDelete,
    onSave: onActualSave,
    elements,
    loading,
    onRefreshAll,
    dialog
  } = useEntityManagementWithFeedback({
    countGetter: () => 1,
    initialItemsPerPage: 1,
    initialCount: 1,
    onFetch,
    onDelete,
    onSave: onUpdate,
    defaultMessage: "Operazione effettuata.",
    errorHandler: (e) => `Si è verificato un errore: ${e.message}`,
  });

  let r = null;

  if (elements) {
    if (elements.length) {
      r = (
        <Renderer
          loading={loading}
          entity={elements[0]}
          onDelete={onActualDelete}
          onUpdate={onActualSave}
          onRefresh={onRefreshAll}
        />
      );
    } else {
      if (renderOnMissingElement && !loading) {
        r = renderOnMissingElement();
      }
    }
  }

  return (
    <Box style={{ position: "relative" }}>
      {dialog}
      <BlockingLoader in={loading} />
      {r}
    </Box>
  );
}
