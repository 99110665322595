import React, { memo, useCallback } from "react";
import { Carts } from "@italwebcom/anema-react-components";
import { Cart } from "@italwebcom/anema-react-components/Carts";
import ActiveCartFab from "./ActiveCartFab";
import useRuntimeContext from "../../../../contexts/Runtime/useRuntimeContext";

const { ActiveCartProvider } = Carts.Components;
const { useCartProvider } = Carts.hooks;

const Inner = memo(({ onSelect, fabSize, noLabel }) => {
  const { smallScreen } = useRuntimeContext();
  const { cart, loading } = useCartProvider();
  const onActualClick = useCallback(
    () => cart && onSelect(cart),
    [onSelect, cart]
  );
  return (
    <ActiveCartFab
      disabled={loading || !cart}
      onClick={onActualClick}
      smallScreen={smallScreen}
      size={fabSize}
      noLabel={noLabel}
    />
  );
});

/**
 * @param {{
 *    onSelect: (cart: Cart) => void,
 *    fabSize: "large" | "medium" | "small"
 * }} param0
 * @returns
 */
function ActiveCartRenderer({ onSelect, fabSize, noLabel }) {
  return (
    <ActiveCartProvider>
      <Inner onSelect={onSelect} fabSize={fabSize} noLabel={noLabel} />
    </ActiveCartProvider>
  );
}

export default memo(ActiveCartRenderer);
