import React from "react";
import {
  FormEditEntityRenderer,
  MainContentCard,
  FormEditEntityRendererAttribute,
} from "@italwebcom/misc-ui-components";
import { EditorProps } from "@italwebcom/misc-ui-components/v2";
import {makeStyles} from "@material-ui/core"
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import Container from "@material-ui/core/Container";
import Close from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  externalWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

/**
 * @template Entity
 * @typedef {{
 *      subtitleGetter: (entity: Entity) => string,
 *      attributes: FormEditEntityRendererAttribute<any>[]
 * }} ModalFormEntityEditorAdditionalProps
 */

/**
 * @template Entity
 * @param {EditorProps<Entity> & ModalFormEntityEditorAdditionalProps<Entity>} param0
 */
export default function ModalFormEntityEditor({
  entity,
  title,
  loading,
  onSubmit,
  onResetSelectedEntity,
  subtitleGetter,
  attributes,
  composer
}) {
  const { externalWrapper } = useStyles();
  return (
    <Modal open={Boolean(entity)} onClose={onResetSelectedEntity}>
      <Box className={externalWrapper}>
        <Container maxWidth="sm">
          <MainContentCard
            title={title || `Modifica elemento`}
            subtitle={entity && subtitleGetter(entity)}
            buttons={[
              {
                id: "close",
                onPress: onResetSelectedEntity,
                label: "Annulla",
                color: "secondary",
                icon: <Close />,
              },
            ]}
          >
            <FormEditEntityRenderer
              onSubmit={onSubmit}
              loading={loading}
              entity={entity}
              attributes={attributes}
              composer={composer}
            />
          </MainContentCard>
        </Container>
      </Box>
    </Modal>
  );
}

/**
 * @template Entity
 * @param {ModalFormEntityEditorAdditionalProps<Entity>} param0
 * @return {EditorProps<Entity>}
 */
export function makeEditor({ subtitleGetter, attributes, composer }) {
  return (props) => (
    <ModalFormEntityEditor
      {...props}
      subtitleGetter={subtitleGetter}
      attributes={attributes}
      composer={composer}
    />
  );
}
