import { withDefaultHateoas } from "../../hateoas/auxiliary/getDefaultHateoasData";
import links from "../../links";
import {CostList} from "../../../model";

/**
 * @param {CostList} data 
 * @returns 
 */
export default function costListHateoasWrapper(data) {
  let e = withDefaultHateoas(links.costList.base, data);
  e._links.discriminators = {
    href: links.costList.discriminators(e.id),
    templated: true,
  };
  e._templates.addDiscriminator = {
    method: "POST",
    properties: [],
    target: links.costList.discriminator(e.id),
  };
  return e;
}
