import {makeStyles} from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  title: {
    color: "rgb(75, 75, 75)",
    fontWeight: "bold"
  },
  subtitle: {
    color: "rgb(111, 111, 111)"
  },
  cost: {
    color: "rgb(25, 25, 25)",
    fontWeight: "bold",
  },
  image: {
    height: "50px",
    width: "50px",
    marginRight: "1rem",
  },
  wrapper: {
    display: "flex",
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  costWrapper: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  actualImage: {
    maxWidth: "100%"
  }
}));

export default useStyles;
