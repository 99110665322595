import { useCallback, useState } from "react";
import { Customer } from "../../../../__domain/model";
import useWrapper from "../../../../hooks/useWrapper";
import useRequestAuthenticationSetter from "../../../authentication/hooks/useRequestAuthenticationSetter";

/**
 * @template T
 * @typedef {(customer: Customer) => Promise<T>} TokenGetter
 */

/**
 * @template T
 * @param {{tokenGetter: TokenGetter<T>}} param0
 */
export default function useCustomerSetToken({
  tokenGetter,
  tokenProcessor,
  onError,
}) {
  const s = useRequestAuthenticationSetter();
  const customerWrapper = useWrapper("customer");
  const [loading, setLoading] = useState(true);

  const cback = useCallback(
    /**
     * @param {Customer} c
     * @returns
     */
    async (c) => {
      try {
        setLoading(true);
        let t = await tokenGetter(c);
        if (tokenProcessor) {
          t = tokenProcessor(t);
        }
        let out = customerWrapper
          .wrap(c)
          .execute("setNotificationToken", t, true);
        s(out.request());
        out = await out.json();
        setLoading(false);
        return out;
      } catch (exc) {
        if (onError) {
          onError(exc);
        }
        setLoading(false);
      }
    },
    [customerWrapper, setLoading, onError, s]
  );

  return { setToken: cback, loading };
}
