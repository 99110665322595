import React, { memo, useMemo } from "react";
import useImageHeight from "../hooks/useImageHeight";
import useImageWidth from "../hooks/useImageWidth";
import useStyles from "../styles";
import { ListImageProps } from "../defines";

/**
 * @param {ListImageProps} param0
 * @returns
 */
function ListImage({ src, elementSpan, float, alt, rowSpan, renderImage }) {
  const { listElement: listElementStyle, listImage } = useStyles();

  /* sets height for image wrapper as multiple of list element height */
  const height = useImageHeight(elementSpan);

  /* sets width of image wrapper as percentage of containing block according to number of element "rows" */
  const width = useImageWidth(rowSpan);

  /* positions image on line */
  const actualFloat = useMemo(() => float || "left", [float]);

  const img = <img src={src} alt={alt} className={listImage} />;

  return (
    <li
      className={listElementStyle}
      style={{ height, width, float: actualFloat }}
    >
      {renderImage ? renderImage(img, {height, width}) : img}
    </li>
  );
}

export default memo(ListImage);
