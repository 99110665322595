import React from "react";
import { memo } from "react";
import CustomerConversationManager from "../../components/_entities/conversations/CustomerConversationManager";
import UserAreaGate from "../__auxiliary__/UserAreaGate";
import { Customers } from "@italwebcom/anema-react-components";
import MainContentPanel from "../../components/MainContentPanel";
import CustomerCredentialsSetter from "../../components/CustomerCredentialsSetter";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";
import { useRuntimeContext } from "../../contexts";

const { useCustomer } = Customers.hooks;

const s = makeStyles((theme) => ({
  wrapper: {
    paddingBottom: theme.spacing(8),
  },
}));

/**
 * @param {{title: string}} param0
 * @returns
 */
function UserAreaChat({ title, noHeader }) {
  const { customer } = useCustomer();
  const { wrapper } = s();
  const { smallScreen } = useRuntimeContext();
  return (
    <UserAreaGate
      Setter={CustomerCredentialsSetter}
      title={title || "Chat"}
      viewName="chat"
      PageWrapperProps={{
        noContentGutters: smallScreen,
        noTopPadding: smallScreen,
        noHeader
      }}
    >
      <MainContentPanel header={<span />}>
        <CustomerConversationManager
          id={
            customer.conversation ||
            (customer.mainConversation && customer.mainConversation.id)
          }
        />
      </MainContentPanel>
    </UserAreaGate>
  );
}

export default memo(UserAreaChat);
