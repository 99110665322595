import React, { Fragment, ReactNode } from "react";
import {
  Grid,
  Box,
  makeStyles,
  Container,
  Typography,
  Paper,
  Divider,
} from "@material-ui/core";
import clsx from "clsx";
import HulkButton from "../../Inputs/HulkButton";
import useStyles from "./styles";
import SectionTitle from "./auxiliary/SectionTitle";
import TextCouple from "./auxiliary/TextCouple";

/**
 * @param {{totalCost: number, additionalCost: number, itemCost: number}} param0
 * @returns
 */
function CostPrinter({ totalCost, additionalCost, itemCost }) {
  const { textCoupleWrapper } = useStyles();
  return (
    <Fragment>
      {itemCost && <TextCouple
        label="Costo prodotti:"
        value={itemCost}
        className={textCoupleWrapper}
      />}
      {additionalCost && (
        <TextCouple
          label={additionalCost.description}
          value={additionalCost.amount}
          className={textCoupleWrapper}
        />
      )}
      {(itemCost || additionalCost) &&<Box marginBottom="1rem">
        <Divider />
      </Box>}
      <TextCouple
        label="Totale"
        value={totalCost}
        className={textCoupleWrapper}
        bold
        vertical
        valueVariant="h4"
        valueStyle={{ color: "green" }}
      />
    </Fragment>
  );
}

function Section({ title, subtitle, action, children, margin }) {
  const { titleWrapper } = useStyles();
  return (
    <Box component="article" marginBottom={margin ? "2em" : ""}>
      <SectionTitle
        title={title}
        subtitle={subtitle}
        action={action}
        className={titleWrapper}
      />
      <Box component="section">{children}</Box>
    </Box>
  );
}

function AdditionalDataRenderer({ data }) {
  const { textCoupleWrapper, additionalDataWrapper } = useStyles();
  return (
    <Box className={additionalDataWrapper}>
      {data.map(({ label, value }) => (
        <TextCouple label={label} value={value} className={textCoupleWrapper} />
      ))}
    </Box>
  );
}

/**
 * @typedef {{
 *      description: string,
 *      amount: number
 * }} AdditionalCost
 *
 * @typedef {{
 *      label: string,
 *      value: string
 * }} AdditionalCartData
 *
 * @typedef {{
 *      children?: ReactNode,
 *      onCheckout?: Function,
 *      onReset?: Function,
 *      onEdit?: Function,
 *      totalCost?: number,
 *      additionalCost?: AdditionalCost,
 *      itemCost?: number,
 *      itemCount?: number,
 *      additionalOverviewText?: string,
 *      additionalCartData?: AdditionalCartData[]
 * }} HulkCartOverviewProps
 */

/**
 * @param {HulkCartOverviewProps} param0
 */
export default function HulkCartOverview({
  children,
  totalCost,
  additionalCost,
  itemCost,
  itemCount,
  onCheckout,
  onProductsEdit,
  onDataEdit,
  additionalOverviewText,
  additionalCartData,
  additionalOverviewContent,
}) {
  const { itemsWrapper, overviewWrapper, overviewWrapperContent } = useStyles();
  return (
    <Paper>
      <Grid container>
        <Grid item xs={12} sm={8}>
          <Container maxWidth={false} className={itemsWrapper}>
            {additionalCartData && (
              <Section
                margin
                title="Dati"
                action={
                  onDataEdit && (
                    <HulkButton
                      label="Modifica dati"
                      onClick={onDataEdit}
                      variant="light"
                    />
                  )
                }
              >
                <AdditionalDataRenderer data={additionalCartData} />
              </Section>
            )}
            <Section
              title="Prodotti"
              subtitle={`(${itemCount} prodotti)`}
              action={
                onProductsEdit && (
                  <HulkButton
                    label="Modifica prodotti"
                    onClick={onProductsEdit}
                    variant="light"
                  />
                )
              }
            >
              {children}
            </Section>
          </Container>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Container maxWidth={false} className={overviewWrapper}>
            <Box className={overviewWrapperContent}>
              <Box marginBottom="2em">
                <CostPrinter
                  totalCost={totalCost}
                  additionalCost={additionalCost}
                  itemCost={itemCost}
                />
              </Box>
              {additionalOverviewText && (
                <Box marginBottom="2em">
                  <Typography component="p" variant="body2">
                    {additionalOverviewText}
                  </Typography>
                </Box>
              )}
              {onCheckout && (
                <HulkButton
                  fullWidth
                  label="Procedi al pagamento"
                  onClick={onCheckout}
                  variant="light"
                />
              )}
              {additionalOverviewContent}
            </Box>
          </Container>
        </Grid>
      </Grid>
    </Paper>
  );
}
