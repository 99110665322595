import { useCallback } from "react";
import { CrudOperationsWrapper, CrudOperation } from "@italwebcom/crud-operations-wrapper";

/**
 * @template {CrudOperation} U
 * @template T
 * 
 * @param {CrudOperationsWrapper<T, U>} wrapper 
 * @returns 
 */
export default function useGenericFetch(wrapper) {
    return useCallback(args => {
        return wrapper && wrapper.fetch(args).json();
    }, [wrapper]);
}