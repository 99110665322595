import React, { memo, useCallback, ReactNode, useRef, useEffect } from "react";
import MainCartProvider from "../Main";
import { useCustomer } from "../../../../Customers/Provider";
import useCustomerWrapper from "../../../../../../hooks/wrappers/useCustomerWrapper";
import { useNavigation } from "../../../../../misc/NavigationProvider";
import undef from "../../../../../../misc/undef";
import useRequestAuthenticationSetter from "../../../../../authentication/hooks/useRequestAuthenticationSetter";

/**
 * Provides the (only) cart with status "ACTIVE" for the customer provided by the nearest
 * CustomerProvider. Does not render anything if the customer's missing.
 *
 * !REQUIRES!: a CustomerProvider ancestor.
 *
 * @param {{children: ReactNode[]}} param0
 * @returns
 */
function ActiveCartProvider({
  children,
  activeCartOperationName,
  redirectOnSave,
}) {
  const customerWrapper = useCustomerWrapper();
  const navRef = useRef();

  const { customer } = useCustomer();
  const { navigate } = useNavigation();
  const authSetter = useRequestAuthenticationSetter();

  useEffect(() => {
    if (navRef) {
      navRef.current = navigate;
    }
  }, [navigate, navRef]);

  const onAfterSave = useCallback(
    (c) => {
      navRef.current &&
        redirectOnSave &&
        navRef.current({ to: "cart", args: { id: c.id } });
    },
    [navRef, redirectOnSave]
  );

  const onActualFetch = useCallback(() => {
    let e = customerWrapper
      .wrap(customer)
      .execute(activeCartOperationName || "activeCart");
    authSetter(e.request());
    return e.json();
  }, [customer, customerWrapper, activeCartOperationName, authSetter]);

  if (customer && !undef(customer.id)) {
    return (
      <MainCartProvider
        asCustomer
        onActualFetch={onActualFetch}
        onAfterSave={onAfterSave}
      >
        {children}
      </MainCartProvider>
    );
  }
  return null;
}

export default memo(ActiveCartProvider);
