import { Tags } from "@italwebcom/anema-react-components";
import React, { memo } from "react";
import DefaultErrorPrinter from "../../../_auxiliary/DefaultErrorPrinter";
import GroupRenderer from "./GroupRenderer";

const { Selector } = Tags.Components;

function TagTabsSelector({ filterType, defaultSelected, onChange }) {
  return (
    <Selector.Provider onChange={onChange} defaultSelected={defaultSelected}>
      <Selector.Group
        filterType={filterType}
        Renderer={GroupRenderer}
        lazyCountRecompute
        initialItemsPerPage={9999}
      />
      <DefaultErrorPrinter />
    </Selector.Provider>
  );
}

export default memo(TagTabsSelector);
