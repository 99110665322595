import { withDefaultHateoas } from "../auxiliary/getDefaultHateoasData";
import links from "../../links";
import { ProductFeature, ProductFeatureAttributes } from "../../../model";

/**
 * @param {ProductFeatureAttributes} data 
 * @return {ProductFeature}
 */
export default function productFeatureWrapper(data) {
  let e = withDefaultHateoas(links.productFeature.base, data);
  e._links.values = {
    href: links.productFeature.values(e.id),
    templated: true,
  };
  e._templates.addValue = {
    method: "POST",
    properties: [],
    target: links.productFeature.addValue(e.id),
  };
  return e;
}
