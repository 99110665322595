import React from "react";
import { InputComponent, GenericForm } from "@italwebcom/react-generic-form";

/**
 * @param {{Component?: InputComponent}} props
 * @returns
 */
export default function Email({ Component, testID, loading }) {
  return (
    <GenericForm.Input
      field="email"
      Component={Component}
      InputProps={{ "data-testid": testID, loading, disabled: true }}
    />
  );
}
