/**
 * @type {{
 *      email: RegExp
 *      name: RegExp,
 *      phone: RegExp,
 *      alphanumeric: RegExp,
 *      extendedAlphanumeric: RegExp
 * }}
 */
 const validators = {
    email: /^[a-zA-Z\.0-9 ]+[@][a-zA-Z0-9 ]+[.][a-zA-Z0-9 ]+$/,
    name: /^[a-zA-Z0-9.\-_,:° ]+$/,
    phone: /^(\+39){0,1}( ){0,1}[0-9]{3,4}( ){0,1}[0-9]+$/,
    alphanumeric: /^[a-zA-Z0-9ùàèò °\/]+$/,
    extendedAlphanumeric: /^[a-zA-Z0-9_\-.,;:ùàòè'"()&%!? °+\n\/\\]+$/
};

export default validators;