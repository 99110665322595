import React, { memo, useMemo } from "react";
import { HulkButton } from "@italwebcom/misc-ui-components";
import { Conversations } from "@italwebcom/anema-react-components";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from "../styles";
import Button from "@material-ui/core/Button";

const { useConversationAndMessages } = Conversations.hooks;

function MessageSubmitButton({ disabled, label }) {
  const { messageSubmitButton } = useStyles();
  const { messagesLoading, conversationLoading } = useConversationAndMessages();
  const { isLoading, isDisabled } = useMemo(
    () => ({
      isLoading: messagesLoading || conversationLoading,
      isDisabled: messagesLoading || conversationLoading || disabled,
    }),
    [disabled]
  );
  return (
    <Box className={messageSubmitButton}>
      <Button
        type="submit"
        color="primary"
        title={label}
        variant="outlined"
        fullWidth
        disabled={isDisabled}
        startIcon={isLoading && <CircularProgress size={12} color="white" />}
      >
        {label}
      </Button>
    </Box>
  );
}

export default memo(MessageSubmitButton);
