import { HateoasLinksManager, wrap } from "@italwebcom/crud-operations-wrapper";
import { useEffect, useState } from "react";
import { FetcherRequestBuilder } from "@italwebcom/augmented-fetch";

function fullUrl(url, base) {
  return `${base}${url}`;
}

function makeWrapper(data, mgr, r) {
  return wrap(
    data,
    {
      linksGetter: mgr._getter,
    },
    r
  );
}

/**
 * Creates initial entity wrappers (after fetching hateoas metadata for general operations from well-known endpoints).
 * 
 * @param {FetcherRequestBuilder} request
 * @param {string} baseUrl
 * 
 * @returns
 */
function useWrappers(request, baseUrl) {
  const [wrappers, setWrappers] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      const ps = [
        request(fullUrl("/.well-known", baseUrl)).json(),
        request(fullUrl("/.well-known/cart", baseUrl)).json(),
        /*request(fullUrl("/.well-known/image", baseUrl)).json(),
        request(fullUrl("/.well-known/product", baseUrl)).json(),
        request(fullUrl("/.well-known/cart", baseUrl)).json(),
        request(fullUrl("/.well-known/customer", baseUrl)).json(),
        request(fullUrl("/.well-known/conversation", baseUrl)).json(),
        request(fullUrl("/.well-known/news-item", baseUrl)).json(),
        request(fullUrl("/.well-known/variant-attribute", baseUrl)).json(),
        request(fullUrl("/.well-known/delivery-zone", baseUrl)).json(),
        request(fullUrl("/.well-known/user", baseUrl)).json(),
        request(fullUrl("/.well-known/stats", baseUrl)).json(),
        request(fullUrl("/.well-known/search", baseUrl)).json(),
        request(fullUrl("/.well-known/opening-time", baseUrl)).json(),
        request(fullUrl("/.well-known/closing-day", baseUrl)).json(),*/
      ];
      const mgr = new HateoasLinksManager("fetch");
      Promise.all(ps).then(
        ([
          data1,
          data2
        ]) => {
          setWrappers({
            tag: makeWrapper(data1.categories, mgr, request),
            category: makeWrapper(data1.categories, mgr, request),
            image: makeWrapper({}, mgr, request),
            product: makeWrapper(data1.products, mgr, request),
            productCategory: makeWrapper({}, mgr, request),
            productImage: makeWrapper({}, mgr, request),
            productCost: makeWrapper({}, mgr, request),
            deliveryCost: makeWrapper({}, mgr, request),
            validityDiscriminator: makeWrapper({}, mgr, request),
            cartProduct: makeWrapper({}, mgr, request),
            cart: makeWrapper(data2, mgr, request),
            customer: makeWrapper(data1.customers, mgr, request),
            conversation: makeWrapper(data1.conversations, mgr, request),
            newsItem: makeWrapper(data1.newsItems, mgr, request),
            productFeature: makeWrapper({}, mgr, request),
            deliveryZone: makeWrapper(data1.deliveryZones, mgr, request),
            user: makeWrapper(data1.users, mgr, request),
            stat: makeWrapper({}, mgr, request),
            globalSearchResult: makeWrapper({}, mgr, request),
            openingTime: makeWrapper(data1.openingTimes, mgr, request),
            closingDay: makeWrapper(data1.closingDays, mgr, request),
          });
        }
      );
    }, 2);
  }, [request]);

  return wrappers;
}

export default useWrappers;