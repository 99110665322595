import React, { memo, useState } from "react";
import Context from "../context";
import {BaseProps} from "../defines";

/**
 * @param {BaseProps} param0 
 * @returns 
 */
function Base({ children, onScrollTo }) {
  const [v, setV] = useState(null);
  return (
    <Context.Provider value={{ value: v, onChangeValue: setV, onScrollTo }}>
      {children}
    </Context.Provider>
  );
}

export default memo(Base);
