import React, { Component, memo } from "react";
import {
  CollectionAttributeRenderer,
  KeyExtractor,
} from "../../CollectionRenderers/defines";
import {
  useEntityManagement,
  FetcherInputs,
  FetchResult,
} from "@italwebcom/custom-react-hooks";
import { Grid, Box, makeStyles } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { BaseEntityManagerProps } from "../defines";
import BlockingLoader from "../../Misc/BlockingLoader";
import useAdditionalFunctions from "../../hooks/useAdditionalFunctions";

const useStyles = makeStyles(() => ({
  wrapper: {
    position: "relative",
  },
}));

/**
 * @template Entity
 * @template Indices
 * @template Sorters
 * @typedef {{
 *      onFetch: (args: FetcherInputs<Indices, Sorters>) => Promise<FetchResult<Entity>>,
 *      searchDelayMs?: number
 * }} AdditionalReadOnlyEntityManagerProps
 */

/**
 * @template Entity
 * @template Indices
 * @template Sorters
 * @typedef {BaseEntityManagerProps<Entity, Indices, Sorters> & AdditionalReadOnlyEntityManagerProps<Entity, Indices, Sorters>} ReadOnlyEntityManagerProps
 */

/**
 * @template Entity
 * @template Indices
 * @template Sorters
 * @param {ReadOnlyEntityManagerProps<Entity, Indices, Sorters>} param0
 */
function ReadOnlyEntityManager({
  onFetch,
  onSelect,
  Renderer,
  initialFilters,
  initialSorter,
  searchAttribute,
  attributes,
  keyExtractor,
  countGetter,
  initialItemsPerPage,
  onError,
  RendererProps,
  searchDelayMs
}) {
  RendererProps = RendererProps || {};
  const { wrapper } = useStyles();
  const {
    elements,
    count,
    loading,
    sorter,
    onPageChange,
    onFilterReplace,
    onFilterAdd,
    onFilterRemove,
    onSorterChange,
  } = useEntityManagement({
    onFetch,
    onError,
    initialCount: 0,
    countGetter: countGetter || ((r) => r.count),
    initialItemsPerPage,
    initialFilters,
    initialSorter,
    config: {
      filterMatcher: (a, b) => a.attribute === b.attribute,
    },
  });

  const { onSearch, pageChangeCallback, pages } = useAdditionalFunctions({
    initialItemsPerPage,
    count,
    onPageChange,
    onFilterReplace,
    searchAttribute,
    searchDelayMs
  });

  return (
    <Box className={wrapper}>
      <BlockingLoader in={loading} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Renderer
            {...RendererProps}
            elements={elements}
            onSearch={onSearch}
            onFilterAdd={onFilterAdd}
            onFilterRemove={onFilterRemove}
            onFilterReplace={onFilterReplace}
            onSortChange={(attribute, direction) =>
              onSorterChange({ attribute, direction })
            }
            sortAttribute={sorter && sorter.attribute}
            sortDirection={sorter && sorter.direction}
            attributes={attributes}
            onSelect={onSelect}
            keyExtractor={keyExtractor}
          />
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Grid item>
            <Pagination
              count={pages}
              size="large"
              onChange={pageChangeCallback}
              showLastButton
              showFirstButton
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

/**
 * @template Entity
 * @template Indices
 * @template Sorters
 * @param {Component<ReadOnlyEntityManagerProps<Entity, Indices, Sorters>>} Manager
 * @param {{
 *      keyExtractor?: KeyExtractor<Entity>,
 *      initialItemsPerPage?: number,
 *      attributes: CollectionAttributeRenderer<any, Entity>[],
 *      searchAttribute?: string,
 *
 * }} config
 * @return {Component<ReadOnlyEntityManagerProps<Entity, Indices, Sorters>>}
 */
export default function manage(Manager, config) {
  return (props) => <Manager {...config} {...props} />;
}

const MemoedReadOnlyEntityManager = memo(ReadOnlyEntityManager);
export { MemoedReadOnlyEntityManager as ReadOnlyEntityManager };
