import React, { memo, useMemo } from "react";
import {TagGroupProps} from "../defines";
import useFilteredTags from "../hooks/useFilteredTags";
import useTagGroup from "../hooks/useTagGroup";
import {requireProp, validators} from "../../../../../misc/propUtils";

function checkProps({tags}) {
    requireProp(tags, {propName: "tags", validator: validators.isArray});
}

/**
 * @returns
 * @param {TagGroupProps} props
 */
function TagGroup(props) {
    checkProps(props);
    const {tags: targetNames, Renderer} = props;
    const filteredTags = useFilteredTags(targetNames);
    const {GroupRenderer} = useTagGroup();
    const ActualRenderer = useMemo(() => Renderer || GroupRenderer, [Renderer, GroupRenderer]);
    return <ActualRenderer tags={filteredTags} />;
}

export default memo(TagGroup);