import { memo, useContext } from "react";
import { Paginator, EntityManagerWrapperProps } from "../defines";
import EntityManagerContext from "../context";
import { ifNotUndef } from "../auxiliary";

/**
 * @param {EntityManagerWrapperProps<Paginator>} param0
 */
function PaginatorWrapper({ Component }) {
  const { onPageChange, page, pages } = useContext(EntityManagerContext);
  return ifNotUndef(Component, { onPageChange, page, pages });
}

export default memo(PaginatorWrapper);