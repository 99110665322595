import { DeliveryZone } from "../../model";
import wrappers from "../hateoas";

const n = 10;

/**
 * @type {DeliveryZone[]}
 */
let deliveryZones = [];

for (let i = 0; i < n; i++) {
  deliveryZones.push(
    wrappers.deliveryZone({
      id: i,
      name: `zone${i}`,
    })
  );
}

export default deliveryZones;
