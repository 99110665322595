import carts from "./__mock/data/carts";
import cartProducts from "./__mock/data/cartProducts";
import categories from "./__mock/data/categories";
import closingDays from "./__mock/data/closingDays";
import conversations from "./__mock/data/conversations";
import costLists from "./__mock/data/costLists";
import customers from "./__mock/data/customers";
import deliveryCosts from "./__mock/data/deliveryCosts";
import deliveryZones from "./__mock/data/deliveryZones";
import featureValues from "./__mock/data/featureValues";
import globalSearchResults from "./__mock/data/globalSearchResults";
import images from "./__mock/data/images";
import messages from "./__mock/data/messages";
import newsItems from "./__mock/data/newsItems";
import notifications from "./__mock/data/notifications";
import openingTimes from "./__mock/data/openingTimes";
import products from "./__mock/data/products";
import productCategories from "./__mock/data/productCategories";
import productCosts from "./__mock/data/productCosts";
import productFeatures from "./__mock/data/productFeatures";
import productImages from "./__mock/data/productImages";
import stats from "./__mock/data/stats";
import tags from "./__mock/data/tags";
import validityDiscriminators from "./__mock/data/validityDiscriminators";

const data = {
  carts,
  cartProducts,
  categories,
  closingDays,
  conversations,
  costLists,
  customers,
  deliveryCosts,
  deliveryZones,
  featureValues,
  globalSearchResults,
  images,
  messages,
  newsItems,
  notifications,
  openingTimes,
  products,
  productCategories,
  productCosts,
  productFeatures,
  productImages,
  stats,
  tags,
  validityDiscriminators
}

export {data};
