import React, { memo } from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

function MissingProducts({ loading }) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {loading ? (
        <CircularProgress size={16} />
      ) : (
        <Typography component="p" variant="body1">
          Nessun prodotto presente per i filtri selezionati.
        </Typography>
      )}
    </Box>
  );
}

export default memo(MissingProducts);
