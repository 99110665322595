import { createContext } from "react";

/**
 * @typedef {(selected: string, label: string) => boolean} ApplicabilityFunction
 * @type {{selected: string, isApplicable: ApplicabilityFunction, setSelected: (label: string) => void, itemStyle?: CSSProperties}}
 */
const args = {
    selected: null,
    isApplicable: () => false,
    setSelected: v => null,
    variant: null,
    itemStyle: null
};

export default createContext(args);