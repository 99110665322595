import { PaginatorCacheContext } from "@italwebcom/custom-react-hooks";
import { useMemo } from "react";

function c(l) {
  return l.replace(/[?].*/, "");
}

function pars() {
  let u = new URLSearchParams(
    window.location.hash.substring(window.location.hash.lastIndexOf("?"))
  );
  return {
    count: parseInt(u.get("count")),
    page: parseInt(u.get("page")),
    itemsPerPage: parseInt(u.get("itemsPerPage")),
  };
}

function onDataChange(count, page, itemsPerPage) {
  window.location.replace(
    c(window.location.href) +
      `?count=${count}&page=${page}&itemsPerPage=${itemsPerPage}`
  );
}

export default function PaginatorCacheContextWrapper({ children, disabled }) {
  const data = useMemo(() => pars(), []);
  if (disabled) {
    return children;
  } else {
    return (
      <PaginatorCacheContext.Provider value={{ ...data, onDataChange }}>
        {children}
      </PaginatorCacheContext.Provider>
    );
  }
}
