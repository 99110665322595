import React from "react";
import { InputComponent, GenericForm } from "@italwebcom/react-generic-form";

/**
 * @param {{Component?: InputComponent}} props
 * @returns
 */
export default function Phone({ Component, testID, loading }) {
  return (
    <GenericForm.Input
      field="phone"
      Component={Component}
      InputProps={{ "data-testid": testID, loading }}
    />
  );
}
