import React, {
  forwardRef,
  memo,
  ReactNode,
  useContext,
  useImperativeHandle,
  useRef,
} from "react";
import {
  EntityManager,
  CollectionRendererProps,
  useEntityManagerValues,
} from "@italwebcom/react-entity-manager";
import useDefaultComponents from "../../../../components/misc/DefaultComponentsProvider/hooks/useDefaultComponents";
import useMessageProviderFunctions from "../hooks/useMessageProviderFunctions";
import { Conversation, Message } from "../../../../__domain/model";
import Context from "../context";

/**
 * @param {CollectionRendererProps<Message>} param0
 * @returns
 */
function CollectionRenderer({ elements, children, onSave }) {
  const {
    conversation,
    conversationLoading,
    conversationErrored,
    onRefreshConversation,
  } = useContext(Context);
  const { loading } = useEntityManagerValues();
  return (
    <Context.Provider
      value={{
        conversation,
        conversationLoading,
        conversationErrored,
        onRefreshConversation,
        messagesLoading: loading,
        messages: elements,
        onPostMessage: onSave,
      }}
    >
      {children}
    </Context.Provider>
  );
}

/**
 * @returns
 * @param {{conversation: Conversation children: ReactNode[]}} param0
 */
function MessagesProvider(
  { conversation, children, initialItemsPerPage },
  ref
) {
  const { fetchArgsProcessor, defaultCountGetter } = useDefaultComponents();
  const { onFetchMessages, onPostMessage, onSuccess, onError } =
    useMessageProviderFunctions(conversation);

  const r = useRef();

  useImperativeHandle(
    ref,
    () => ({
      refresh: () => r.current && r.current.refresh(),
    }),
    [r]
  );

  return (
    <EntityManager.Crud
      countGetter={defaultCountGetter}
      fetchArgsProcessor={fetchArgsProcessor}
      onFetch={onFetchMessages}
      onSave={onPostMessage}
      initialItemsPerPage={initialItemsPerPage || 9999}
      initialSorter={{ attribute: "date", direction: "asc" }}
      onSuccess={onSuccess}
      onError={onError}
      ref={r}
    >
      <EntityManager.Components.CollectionRenderer
        Component={CollectionRenderer}
        ComponentProps={{ children }}
        renderOnError
      />
    </EntityManager.Crud>
  );
}

export default memo(forwardRef(MessagesProvider));
