import React, {
  Fragment,
  memo,
  useContext,
  createContext,
  ReactNode,
} from "react";
import { Typography, Divider, Grid, Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  subtitle: {
    color: theme.palette.grey["400"],
  },
  sectionTitle: {
    color: theme.palette.grey["600"],
  },
  elementLabel: {
    color: theme.palette.grey["500"],
  },
  headerContent: {
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
}));

const DaContext = createContext({ entity: null, defaultElementsWidth: null });

/**
 * @template T
 *
 * @typedef {{
 *      label: string,
 *      attribute?: string,
 *      renderer?: (entity: T) => string,
 *      disableTypography?: boolean
 * }} SimpleTextEntityRendererElement
 *
 * @typedef {{
 *      title: string,
 *      divider?: boolean,
 *      elements: SimpleTextEntityRendererElement<T>[],
 *      elementsWidth?: number,
 *      additionalTitleContent?: ReactNode
 * }} SimpleTextEntityRendererSection
 *
 * @typedef {{
 *      title?: string,
 *      subtitle?: string,
 *      headerContent?: string,
 *      sections: SimpleTextEntityRendererSection<T>[],
 *      sectionDivider?: boolean,
 *      entity: T
 * }} SimpleTextEntityRendererProps
 *
 */

const SimpleTextEntityRendererElement = memo(
  /**
   * @template T
   * @param {SimpleTextEntityRendererElement<T>} param0
   * @returns
   */
  ({ label, attribute, renderer, disableTypography }) => {
    const { entity } = useContext(DaContext);
    const { elementLabel } = useStyles();
    const value = renderer ? renderer(entity) : entity[attribute];
    return (
      <Box>
        {label && (
          <Typography
            component="h6"
            variant="h6"
            className={elementLabel}
            gutterBottom
          >
            {label}
          </Typography>
        )}
        {disableTypography ? (
          value
        ) : (
          <Typography component="p" variant="body2">
            {value}
          </Typography>
        )}
      </Box>
    );
  }
);

const SimpleTextEntityRendererSection = memo(
  /**
   * @template T
   * @param {SimpleTextEntityRendererSection<T>} param0
   * @returns
   */
  ({ title, divider, elements, elementsWidth, additionalTitleContent }) => {
    const { sectionTitle } = useStyles();
    const { defaultElementsWidth } = useContext(DaContext);
    return (
      <Grid container component="article" spacing={2}>
        <Grid
          item
          container
          xs={12}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h5" component="h5" className={sectionTitle}>
              {title}
            </Typography>
          </Grid>
          {additionalTitleContent && <Grid item>{additionalTitleContent}</Grid>}
        </Grid>
        {divider && (
          <Grid item xs={12}>
            <Divider />
          </Grid>
        )}
        <Grid item xs={12} container spacing={2}>
          {elements.map((element) => (
            <Grid item sm={elementsWidth || defaultElementsWidth} xs={12}>
              <SimpleTextEntityRendererElement {...element} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  }
);

/**
 * @template T
 * @param {SimpleTextEntityRendererProps<T>} param0
 */
export default function SimpleTextEntityRenderer({
  title,
  subtitle,
  sections,
  entity,
  headerContent,
  sectionDivider,
}) {
  const { subtitle: subtitleStyle, headerContent: headerContentStyle } =
    useStyles();
  return (
    <DaContext.Provider value={{ entity, defaultElementsWidth: 6 }}>
      <Grid container spacing={3}>
        {(title || subtitle) && (
          <Grid item xs={12} component="header">
            {title && (
              <Typography component="h4" variant="h4" gutterBottom>
                {title}
              </Typography>
            )}
            {subtitle && (
              <Typography component="h5" variant="h5" className={subtitleStyle}>
                {subtitle}
              </Typography>
            )}
            {headerContent && (
              <Box className={headerContentStyle}>{headerContent}</Box>
            )}
          </Grid>
        )}
        {sections.map((section) => (
          <Fragment>
            {sectionDivider && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
            <Grid item xs={12}>
              <SimpleTextEntityRendererSection {...section} />
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </DaContext.Provider>
  );
}
