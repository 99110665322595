import React, {useMemo, useRef, useState} from "react";
import DialogConfirm, {DialogConfirmProps} from "../Misc/ConfirmDialog";

/**
 * @typedef {"confirmed" | "aborted"} DialogConfirmStatus
 * @typedef {() => Promise<DialogConfirmStatus>} DialogConfirmTrigger
 * @param {DialogConfirmProps} DialogConfirmProps 
 * @return {{rendered: ReactNode, onTrigger: DialogConfirmTrigger}}
 */
export default function useDialogConfirm(DialogConfirmProps) {
    const [open, setOpen] = useState(false);
    const promiseValues = useRef({res: null, rej: null, message: ""});
    const {onConfirm, onAbort} = useMemo(() => ({
        onConfirm: () => promiseValues.current.res && promiseValues.current.res("confirmed"),
        onAbort: () => promiseValues.current.res && promiseValues.current.res("aborted")
    }), [promiseValues.current]);
    const rendered = <DialogConfirm 
        message={promiseValues.current.message}
        {...DialogConfirmProps} 
        open={open} 
        onConfirm={onConfirm}
        onAbort={onAbort}
    />;
    const onTrigger = (mex) => {
        const p = new Promise((res, rej) => {
            setOpen(true);
            promiseValues.current = {res, rej, message: mex};
        });
        p.finally(() => setOpen(false));
        return p;
    }
    return { rendered, onTrigger };
}