import React, {ReactNode} from "react";
import { Button } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";

/**
 * @param {{label: string, onClick: Function, icon?: ReactNode}} param0 
 */
export default function CustomButton({label, onClick, icon, id, color, className, disabled}) {
    return <Button 
        className={className}
        type="button" 
        variant="outlined" 
        color={color || "primary" }
        id={id}
        disabled={disabled}
        onClick={onClick}
        endIcon={icon || <Edit fontSize="small" />}
    >
        {label}
    </Button>
}