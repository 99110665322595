import Grid from "@material-ui/core/Grid";
import React, { memo, useCallback } from "react";
import { DeliveryZoneSelector } from "../../../../deliveryzones";
import InputWrapperGroup from "../../_Auxiliary/InputWrapperGroup";

function val(v) {
  if (v) {
    return typeof v.id !== "undefined" ? v.name : v;
  }
  return null;
}
function ActualDeliveryZoneSelector(props) {
  const { onChange, defaultValue } = props;
  const onActualSelect = useCallback(
    (dz) => onChange({ target: { value: dz } }),
    [onChange]
  );
  return (
    <Grid item xs={12}>
      <InputWrapperGroup.InputWrapper
        id="delivery-zone"
        label="Zona di consegna"
        value={val(defaultValue)}
        disabled={props.disabled}
      >
        <DeliveryZoneSelector {...props} onSelect={onActualSelect} />
      </InputWrapperGroup.InputWrapper>
    </Grid>
  );
}

export default memo(ActualDeliveryZoneSelector);
