import React, {
  memo,
  useContext,
  ReactChildren,
  useMemo,
} from "react";
import SwitchContext from "./context";
import Context from "../../context";
import {useCollectionState} from "@italwebcom/custom-react-hooks";

/**
 * @param {{children: ReactChildren}} param0
 * @returns
 */
function Switch({ children }) {

  const {collection: roles, onAdd} = useCollectionState([]);
  const {credentials, isAuthorized} = useContext(Context);

  const selectedRole = useMemo(() => {
    let l = children.length || 1;
    if(roles.length === l) {
      let applicable = roles.filter(r => isAuthorized(credentials, r.role));
      applicable = applicable.sort((a, b) => a.priority - b.priority);
      return applicable[0];
    }
    return null;
  }, [roles, credentials, isAuthorized, children]);

  //console.log(`selectedRole = ${JSON.stringify(selectedRole)}, roles = ${JSON.stringify(roles)}`);

  return (
    <SwitchContext.Provider
      value={{
        onAddRole: onAdd,
        selectedRole
      }}
    >
        {children}
    </SwitchContext.Provider>
  );
}

export default memo(Switch);
