import React, {
  ComponentType,
  memo,
  ReactChildren,
  ReactNode,
  useContext,
  useMemo,
} from "react";
import { Grid, Box, Container, Drawer } from "@material-ui/core";
import clsx from "clsx";
import useStyles from "../styles";
import { useSwitches } from "@italwebcom/custom-react-hooks";
import { AltAppBar as AppBar } from "../../../Misc/AppBar";
import CustomIconButton from "../../../Inputs/CustomIconButton";
import MoreVert from "@material-ui/icons/MoreVert";
import Person from "@material-ui/icons/Person";
import MiscUiContext from "../../../contexts/styles";

/**
 * @typedef {{onClick: Function}} BarTriggerProps
 * @typedef {ComponentType<AltMainViewButtonProps>} BarTrigger
 * @typedef {{trigger: BarTrigger, content: ReactNode}} MainViewBar
 * @typedef {{
 *    leftBar?: MainViewBar,
 *    rightBar? MainViewBar,
 *    logo?: string,
 *    title?: string,
 *    additionalContent?: ReactNode,
 *    children: ReactChildren,
 *    subtitle?: string
 * }} AltMainViewProps
 */

function DaBarWrapper({ shown, children, onClose, anchor }) {
  const { listMenuWrapper, fullHeight, stickyBar } = useStyles();
  const { MainView } = useContext(MiscUiContext);
  return (
    <Grid item className={clsx([stickyBar, fullHeight])}>
      {MainView.inlineBars ? (
        <Box className={clsx([shown && "shown", listMenuWrapper])}>
          {children}
        </Box>
      ) : (
        <Drawer anchor={anchor} open={shown} onClose={onClose}>
          {children}
        </Drawer>
      )}
    </Grid>
  );
}

const BarWrapper = memo(DaBarWrapper);

/**
 *
 * @param {AltMainViewProps} param0
 * @returns
 */
export default function AltMainView({
  children,
  leftBar,
  rightBar,
  additionalContent,
  title,
  subtitle,
  logo,
}) {
  const { mainContainer, appBarWrapper, mainContainerBg } = useStyles();
  const { MainView: MainViewStyle } = useContext(MiscUiContext);
  const { switches, onSwitch } = useSwitches(["left", "right"]);
  const { onToggleLeft, onToggleRight } = useMemo(
    () => ({
      onToggleLeft: () => onSwitch("left"),
      onToggleRight: () => onSwitch("right"),
    }),
    [onSwitch]
  );

  return (
    <Box className={appBarWrapper}>
      <Container maxWidth={false}>
        <AppBar
          title={title}
          subtitle={subtitle}
          logo={logo}
          height="70px"
          additionalContent={additionalContent}
          leftContent={
            <CustomIconButton onPress={onToggleLeft} Icon={MoreVert} />
          }
          rightContent={
            <CustomIconButton onPress={onToggleRight} Icon={Person} />
          }
        />
        <Grid container>
          {leftBar && (
            <BarWrapper
              shown={switches["left"]}
              onClose={onToggleLeft}
              anchor="left"
            >
              {leftBar}
            </BarWrapper>
          )}
          <Grid
            item
            xs
            className={clsx([
              mainContainer,
              !MainViewStyle.backgroundColor && mainContainerBg,
            ])}
            style={
              MainViewStyle.backgroundColor
                ? { backgroundColor: MainViewStyle.backgroundColor }
                : {}
            }
          >
            {children}
          </Grid>
          {rightBar && (
            <BarWrapper
              shown={switches["right"]}
              onClose={onToggleRight}
              anchor="right"
            >
              {rightBar}
            </BarWrapper>
          )}
        </Grid>
      </Container>
    </Box>
  );
}
