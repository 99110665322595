import React, { memo } from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

function Loader({ loading }) {
  if (loading || typeof loading === "undefined") {
    return (
      <Box
        position="fixed"
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress size={24} />
      </Box>
    );
  }
  return null;
}

export default memo(Loader);