import Provider from "./Provider";
import Input from "./Input";
import Submit from "./Submit";
import validators from "./validators";

const GenericForm = {
    Provider,
    Input,
    Submit,
    validators
};

export default GenericForm;