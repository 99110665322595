import React from "react";
import { Customers, Authentication } from "@italwebcom/anema-react-components";
import { memo } from "react";
import {
  CredentialsSetterComponent,
  useCredentials,
} from "@italwebcom/react-auth-provider";
import PageWrapper from "../PageWrapper";
import { useRuntimeContext } from "../../../contexts";

const { CompositeGate } = Authentication.Components;

/**
 * @param {{Setter: CredentialsSetterComponent}} param0
 * @returns
 */
function UserAreaGate({
  Setter,
  children,
  title,
  maxWidth,
  minHeight,
  viewName,
  PageWrapperProps,
}) {
  const { authentication } = useRuntimeContext();
  return (
    <PageWrapper
      title={title}
      maxWidth={maxWidth}
      minHeight={minHeight}
      userArea
      viewName={viewName}
      {...(PageWrapperProps || {})}
    >
      <CompositeGate
        requiredRole={authentication.authenticatedRole}
        defaultRole={authentication.defaultRole}
        Setter={Setter}
      >
        {children}
      </CompositeGate>
    </PageWrapper>
  );
}

export default memo(UserAreaGate);
