import React, { memo } from "react";
import {Box, makeStyles} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    image: {
      display: "block",
      maxWidth: "100%",
    },
    imageWrapper: {
      cursor: "pointer",
    },
    selectedImage: {
      borderColor: theme.palette.error.light,
      borderWidth: "1px",
      borderStyle: "solid"
    }
}));

/**
* @param {{src: string, alt?: string, onSelect?: Function, selected?: boolean}} param0
* @returns
*/
function Image({ src, alt, selected, onSelect }) {
 const { image, imageWrapper, selectedImage } = useStyles();
 return (
   <Box
     role="button"
     onClick={onSelect}
     className={clsx([imageWrapper, selected && selectedImage])}
   >
     <img className={image} alt={alt} src={src} />
   </Box>
 );
}

export default memo(Image);