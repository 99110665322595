import Provider from "./Provider";
import Input from "./Input";
import Submit from "./Submit";
import Error from "./Error";
import ConfigurationContext from "./contexts/FormConfigurationContext";
import validators from "./validators";
import useError from "./hooks/useError";
import useValues from "./hooks/useValues";

const GenericForm = {
    Provider,
    Input,
    Submit,
    Error,
    validators,
    ConfigurationContext,
    useError,
    useValues
};

export {GenericForm};