import React, {
  memo,
  ReactNode,
  useMemo,
} from "react";
import useCartProviderOperations from "../../hooks/useCartProviderOperations";
import useTempCartAuthenticationSetter from "../../../hooks/useTempCartAuthenticationSetter";
import useTempCartId from "../../../hooks/useTempCartId";
import Crud from "../Crud";

/**
 * @param {{children: ReactNode[]}} param0
 * @returns
 */
function TempCartProvider({ children }) {
  const id = useTempCartId();
  const authSetter = useTempCartAuthenticationSetter();
  const actualFilters = useMemo(() => [{ attribute: "id", value: id }], [id]);
  const { onSuccess, onError, onSave, onFetch } = useCartProviderOperations({
    asCustomer: false,
    requestProcessor: authSetter
  });

  if (id) {
    return (
      <Crud
        onFetch={onFetch}
        onSave={onSave}
        onSuccess={onSuccess}
        onError={onError}
        filters={actualFilters}
      >
        {children}
      </Crud>
    );
  } else {
    return children;
  }
}

export default memo(TempCartProvider);
