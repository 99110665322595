import { OpeningTime } from "../../model";
import { withDefaultHateoas } from "../hateoas/auxiliary/getDefaultHateoasData";

/**
 * @type {OpeningTime[]}
 */
let openingTimes = [];

for(let i=0; i<7; i++) {
    openingTimes.push(
        withDefaultHateoas(
            "opening-time",
            {
                id: i,
                weekday: i.toString(),
                start: `${10+i}:${(i%2)?"10":"00"}:00`,
                end: `${12+i}:00:00`
            }
        )
    )
}

export default openingTimes;