import React, { memo, useMemo } from "react";
import { EntityManager } from "@italwebcom/react-entity-manager";
import useClosingDayWrapper from "../../../../hooks/wrappers/useClosingDayWrapper";
import { useDefaultComponents } from "../../../misc/DefaultComponentsProvider";
import { ClosingDay } from "../../../../__domain/model";
import Context from "../context";
import useProviderData from "../hooks/useProviderData";
import useGenericFetch from "../../../../hooks/generic/useGenericFetch";

const initialSorter = { attribute: "date", direction: "asc" };

/**
 * @param {CollectionRendererProps<ClosingDay>} param0
 */
function ContextProvider({ children }) {
  const { elements, loading, onActualMonthChange } = useProviderData();
  return (
    <Context.Provider
      value={{
        closingDays: elements,
        onMonthChange: onActualMonthChange,
        loading,
      }}
    >
      {children}
    </Context.Provider>
  );
}

/**
 * Manages fetching operations with an EntityManager for the ClosingDay entity &
 * instantiates a corresponding context provider with:
 *
 * - the fetched closing days
 * - a loading boolean flag
 * - the onMonthChange(month: string): void callback used to change the month filter
 *
 * Values may be obtained with the useClosingDays hook.
 *
 * Requires an initial value for the month filter.
 *
 * @param {{
 *    children: ReactNode[],
 *    initialMonth: string
 * }} param0
 *
 * @returns
 */
function ClosingDayReadOnlyProvider({ children, initialMonth }) {
  const { fetchArgsProcessor, defaultCountGetter } = useDefaultComponents();
  const wrapper = useClosingDayWrapper();
  const onActualFetch = useGenericFetch(wrapper);
  const initialFilters = useMemo(
    () => [{ attribute: "month", value: initialMonth }],
    [initialMonth]
  );
  if (wrapper) {
    return (
      <EntityManager.ReadOnly
        fetchArgsProcessor={fetchArgsProcessor}
        countGetter={defaultCountGetter}
        initialItemsPerPage={10}
        initialFilters={initialFilters}
        initialSorter={initialSorter}
        onFetch={onActualFetch}
        testID="ClosingDaysProvider"
      >
        <ContextProvider>{children}</ContextProvider>
      </EntityManager.ReadOnly>
    );
  } else {
    return children;
  }
}

export default memo(ClosingDayReadOnlyProvider);
