import React, { memo, useCallback } from "react";
import ListRenderer from "../ListRenderer";
import { Carts } from "@italwebcom/anema-react-components";
import { Customer } from "@italwebcom/anema-react-components/Customers";
import { Cart } from "@italwebcom/anema-react-components/Carts";
import SharedList from "../_SharedList";
import MainContentPanel from "../../../../MainContentPanel";
import CustomDropdownMenu from "../../../../CustomDropdownMenu";
import {makeStyles} from "@material-ui/core"
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

const filterOptions = [
  {
    value: "unpaid",
    label: "In attesa di pagamento",
  },
  {
    value: "open",
    label: "Aperte",
  },
  {
    value: "all",
    label: "Tutte",
  },
];

const sortingOptions = [
  {
    value: "date_latest",
    label: "Ordina da più recenti",
  },
  {
    value: "date_earliest",
    label: "Ordina da più vecchie",
  },
];

const s = makeStyles((theme) => ({
  headerWrapper: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    position: "sticky",
    top: "64px",
    backgroundColor: "white",
    opacity: 1,
    zIndex: 2,
  },
}));

const Header = memo(() => {
  const args = Carts.hooks.useCarts();
  const { headerWrapper } = s();
  const { onFilterOpen, onFilterUnpaid, onFiltersSetAll, onSortByDate } = args;

  const onFilterValueChange = useCallback(
    (v) => {
      switch (v) {
        case "open":
          onFilterOpen();
          break;
        case "unpaid":
          onFilterUnpaid();
          break;
        default:
          onFiltersSetAll([]);
      }
    },
    [onFilterOpen, onFilterUnpaid]
  );

  const onSortValueChange = useCallback(
    (v) => {
      switch (v) {
        case "date_latest":
          onSortByDate("latest");
          break;
        default:
          onSortByDate("earliest");
      }
    },
    [onSortByDate]
  );

  return (
    <Box className={headerWrapper}>
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item xs sm={3}>
            <CustomDropdownMenu
              label="Filtraggio"
              options={filterOptions}
              onChange={onFilterValueChange}
              defaultValue="all"
            />
          </Grid>
          <Grid item xs sm={3}>
            <CustomDropdownMenu
              label="Ordinamento"
              options={sortingOptions}
              onChange={onSortValueChange}
              defaultValue="date_latest"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
});

/**
 * @param {{customer: Customer, onRedirectToEdit: (cart: Cart) => void, onRedirectToOverview: (cart: Cart) => void}} param0
 */
function DetailedList({ customer, onRedirectToEdit, onRedirectToOverview }) {
  return (
    <SharedList
      customer={customer}
      onRedirectToEdit={onRedirectToEdit}
      onRedirectToOverview={onRedirectToOverview}
    >
      <MainContentPanel header={<Header />}>
        <ListRenderer />
      </MainContentPanel>
    </SharedList>
  );
}

export default memo(DetailedList);
