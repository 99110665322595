import React, { memo, useCallback, useEffect, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText, {
  DialogContentTextProps,
} from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";
import Notifications from "@material-ui/icons/Notifications";

const defaults = {
  dindlingDurationMs: 3000,
  alarmTimingMs: 1500,
};

const s = makeStyles((theme) => ({
  iconWrapper: {
    color: theme.palette.secondary.light,
    fontSize: theme.spacing(8),
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },
}));

function clearIntervalRef(i) {
  if (i && i.current) {
    clearInterval(i.current);
  }
}

function useAlarm({ alarmTimingMs, alarmAudioURL, active }) {
  const audioRef = useRef();
  const intervalRef = useRef();

  useEffect(() => {
    audioRef.current = new Audio(alarmAudioURL);
  }, [alarmAudioURL]);

  useEffect(() => {
    if (active) {
      intervalRef.current = setInterval(
        () => audioRef.current.play(),
        alarmTimingMs
      );
    } else {
      clearIntervalRef(intervalRef);
    }
    return () => clearIntervalRef(intervalRef);
  }, [active, intervalRef, audioRef, alarmTimingMs]);
}

/**
 * @typedef {{
 *      title: string,
 *      content: string,
 *      active: boolean,
 *      onDismiss: Function,
 *      alarmTimingMs?: number,
 *      alarmAudioURL: string,
 *      contentTextProps?: DialogContentTextProps,
 *      dindlingDurationMs?: number
 * }} AlarmDialogAlertProps
 * @param {AlarmDialogAlertProps} param0
 * @returns
 */
function AlarmDialogAlert({
  title,
  content,
  active,
  alarmTimingMs,
  onDismiss,
  alarmAudioURL,
  contentTextProps,
  dindlingDurationMs,
  autoDismissalTimeMs,
}) {
  const { iconWrapper } = s();
  const tRef = useRef();
  useAlarm({
    active,
    alarmAudioURL,
    alarmTimingMs: alarmTimingMs || defaults.alarmTimingMs,
  });

  const onActualClose = useCallback(
    (evt, reason) => {
      if (reason === "escapeKeyDown") {
        onDismiss();
      }
    },
    [onDismiss]
  );

  useEffect(() => {
    if (autoDismissalTimeMs && active) {
      if (tRef.current) {
        clearTimeout(tRef.current);
      }
      tRef.current = setTimeout(onDismiss, autoDismissalTimeMs);
      return () => tRef.current && clearTimeout(tRef.current);
    }
  }, [active, autoDismissalTimeMs, onDismiss]);

  return (
    <Dialog onClose={onActualClose} open={active}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box>
          <Box className={iconWrapper}>
            <Notifications
              fontSize="inherit"
              color="inherit"
              className="dindling"
              style={{
                animationDuration: `${
                  dindlingDurationMs || defaults.dindlingDurationMs
                }ms`,
              }}
            />
          </Box>
          <DialogContentText {...(contentTextProps || {})}>
            {content}
          </DialogContentText>
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button variant="text" color="secondary" onClick={onDismiss}>
              Chiudi
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default memo(AlarmDialogAlert);
