import {makeStyles} from "@material-ui/core"

export default makeStyles((theme) => ({
  header: {
    "&:hover": {
      fontWeight: "bold",
    },
    color: "rgba(0, 0, 0, 0.54)",
  },
  arrow: {
    transitionProperty: "opacity",
    transitionDuration: "500ms",
    opacity: 0,
    "&:hover": {
      opacity: 0.75,
    },
    color: "rgb(111, 111, 111)",
  },
  selectedArrow: {
    opacity: 1,
  },
  row: {
    cursor: "pointer",
    "& td": {
      transitionProperty: "background-color",
      transitionDuration: "350ms",
      backgroundColor: "initial",
    },
    "&.selected td": {
      backgroundColor: "rgb(243, 247, 250)",
    },
  },
  lastCell: {
    position: "relative",
  },
  selectedElementArrow: {
    position: "absolute",
    right: "3rem",
    transitionProperty: "right",
    transitionDuration: "500ms",
    fontSize: "1.5rem",
    color: "rgb(77, 125, 242)",
    top: "12px",
    opacity: 0,
    "&.visible": {
      right: "1rem",
      opacity: 1,
    },
  },
}));
