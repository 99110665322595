import { useMemo } from "react";
import {
  useTestCallback,
  callbacks,
} from "../../../../../__test-auxiliary/TestCallbacks";

function useCartProductFunctions({
  wrapper,
  cartRef,
  onMessageAdd,
  requestProcessor,
}) {
  const cProdSaveTestCallback = useTestCallback(
    callbacks.Carts.cartProductSave
  );
  const cProdDeleteTestCallback = useTestCallback(
    callbacks.Carts.cartProductDelete
  );
  return useMemo(() => {
    return {
      onActualSave: (e) => {
        const data = { ...e, product: e.product && e.product.id };
        cProdSaveTestCallback && cProdSaveTestCallback(data);
        let o;
        if (e._links) {
          o = wrapper.wrap({ ...data, cart: null }).update();
        } else {
          o = wrapper.wrap(cartRef.current).execute("addProduct", data, true);
        }
        if (requestProcessor) {
          requestProcessor(o.request());
        }
        return o.json();
      },
      onActualSuccess: (o) => {
        if (o === "save") {
          onMessageAdd({ type: "success", content: "Prodotto modificato." });
        }
        if (o === "delete") {
          onMessageAdd({ type: "success", content: "Prodotto rimosso." });
        }
      },
      onActualError: (err) =>
        onMessageAdd({
          type: "error",
          content: err.message || "Si è verificato un errore.",
        }),
      onFetch: (args) => {
        if (cartRef.current) {
          console.log(
            "useCartProductFunctions: onFetch callback - cart is present, fetching elements."
          );
          let r = wrapper.wrap(cartRef.current).execute("products", args);
          if (requestProcessor) {
            requestProcessor(r.request());
          }
          return r.json();
        } else {
          console.log(
            "useCartProductFunctions: onFetch callback - missing cart, returning empty array."
          );
          return Promise.resolve({ data: [] });
        }
      },
      onDelete: (e) => {
        cProdDeleteTestCallback && cProdDeleteTestCallback(e);
        let r = wrapper.wrap(e).delete();
        if (requestProcessor) {
          requestProcessor(r.request());
        }
        return r.json();
      },
    };
  }, [
    wrapper,
    cartRef,
    onMessageAdd,
    cProdSaveTestCallback,
    cProdDeleteTestCallback,
    requestProcessor,
  ]);
}

export default useCartProductFunctions;