import { useMemo } from "react";
import { useNavigation } from "../../../misc/NavigationProvider";
import useGlobalSearchWrapper from "../../../../hooks/wrappers/useGlobalSearchWrapper";

export default function useManagerFunctions() {
  const wrapper = useGlobalSearchWrapper();
  const { navigate } = useNavigation();
  return useMemo(
    () => ({
      onActualSelect: (e) => {
        if (e.resultType.toLowerCase() === "product") {
          navigate({ to: "Products", args: { id: e.id } });
        } else {
          navigate({ to: "Tags", args: { id: e.id } });
        }
      },
      onFetch: (s) =>
        s.query ? wrapper.fetch(s).json() : Promise.resolve({ data: [] }),
    }),
    [navigate, wrapper]
  );
}
