import { createContext } from "react";

/**
 * @type {{
 *      MainView: {
 *          inlineBars: boolean,
 *          backgroundColor: string
 *      },
 *      SearchBar: {
 *          backgroundColor: string
 *      },
 *      AppBar: {
 *          onlyLogo: boolean
 *      }
 * }}
 */
const contextArgs = {
    MainView: {
        inlineBars: true,
        backgroundColor: ""
    },
    SearchBar: {
        backgroundColor: ""
    },
    AppBar: {
        onlyLogo: false
    }
};

export default createContext(contextArgs);