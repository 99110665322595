import React, { memo } from "react";
import TagProvider from "../../TagProvider";
import { TagGroupProviderProps } from "../defines";
import Context from "../context";
import { requireProp } from "../../../../../misc/propUtils";

function checkProps({ GroupRenderer, filterType, tagGroupMatcher }) {
  requireProp(filterType, {propName: "filterType", requiredType: "string"});
  requireProp(tagGroupMatcher, {propName: "tagGroupMatcher", requiredType: "function"});
  requireProp(GroupRenderer, {propName: "GroupRenderer", validator: (e, t) => (t === "object") || (t === "function")});
}

/**
 * @returns
 * @param {TagGroupProviderProps} props
 */
function TagGroupProvider(props) {
  checkProps(props);
  const { GroupRenderer, filterType, children, tagGroupMatcher } = props;
  return (
    <Context.Provider value={{ GroupRenderer, tagGroupMatcher }}>
      <TagProvider filterType={filterType}>{children}</TagProvider>
    </Context.Provider>
  );
}

export default memo(TagGroupProvider);
