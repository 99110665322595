import React, { memo } from "react";
import Generic from "../Generic";
import Home from "@material-ui/icons/Home";
import { useMemo } from "react";
import { Carts } from "@italwebcom/anema-react-components";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import LocalDining from "@material-ui/icons/LocalDining";
import Person from "@material-ui/icons/Person";

const { useCartAndProducts } = Carts.hooks;

/**
 * @param {{cart: number, colors: any}} param0
 * @returns
 */
function Temp({ cart, colors, selected, backgroundColor }) {
  const { cartProducts } = useCartAndProducts();
  const elements = useMemo(
    () => [
      {
        id: "home",
        icon: <Home />,
        label: "Home",
      },
      {
        id: "menuSelection",
        icon: <LocalDining />,
        label: "Menù",
      },
      {
        id: "cartOverview",
        icon: <ShoppingCart />,
        label: "Prenotazione",
      },
      {
        id: "dashboard",
        icon: <Person />,
        label: "Area utente",
        user: true
      },
    ],
    [cartProducts]
  );
  return (
    <Generic
      elements={elements}
      colors={colors}
      cart={cart}
      selected={selected}
      backgroundColor={backgroundColor}
    />
  );
}

export default memo(Temp);
