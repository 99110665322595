import React, { memo, useMemo } from "react";
import useStyles from "../styles";
import { useVariantProvider } from "../context";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import IconButton from "@material-ui/core/IconButton";
import ViewList from "@material-ui/icons/ViewList";
import ViewModule from "@material-ui/icons/ViewModule";
import clsx from "clsx";

/**
 * *Requires a {@link module:src/index~newsItems} ancestor*.
 * 
 * Renders a button group (two buttons) to switch between "grid" and "list" variants.
 */
const VariantButtonGroup = memo(
  /**
   * @returns
   */
  () => {
    const { notSelected } = useStyles();
    const { onSetVariant, variant } = useVariantProvider();

    const { onSetList, onSetGrid } = useMemo(
      () => ({
        onSetGrid: () => onSetVariant("grid"),
        onSetList: () => onSetVariant("list"),
      }),
      [onSetVariant]
    );

    return (
      <ButtonGroup>
        <IconButton
          onClick={onSetList}
          className={clsx([variant === "grid" && notSelected])}
        >
          <ViewList />
        </IconButton>
        <IconButton
          onClick={onSetGrid}
          className={clsx([variant === "list" && notSelected])}
        >
          <ViewModule />
        </IconButton>
      </ButtonGroup>
    );
  }
);

export default VariantButtonGroup;
