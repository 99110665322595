import links from "../../links";
import { ProductCategoryAttributes, ProductCategory } from "../../../model";

/**
 * @param {ProductCategoryAttributes} data 
 * @return {ProductCategory}
 */
export default function productCategoryWrapper(data) {
  let i = data.product;
  let c = data.category;
  return {
    ...data,
    _links: {
      self: {
        href: links.product.related.category(i, c)
      },
      fetch: {
        href: links.product.categories(i),
        templated: true
      }
    },
    _templates: {
      delete: {
        method: "DELETE",
        target: links.product.related.category(i, c),
        properties: []
      },
    },
  };
}
