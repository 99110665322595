import { useCallback } from "react";
import { CustomerWrapper } from "../../../../__domain/__mock/wrappers";
import useRequestAuthenticationSetter from "../../../authentication/hooks/useRequestAuthenticationSetter";

function g(a) {
  if (/^(http).*/.test(a)) {
    return undefined;
  }
  return a;
}

function dec(r, a) {
  a(r.request());
  return r.json();
}

/**
 * @returns
 * @param {{wrapper: CustomerWrapper}} param0
 */
function useCustomerManagement({ wrapper, id }) {

  const authSetter = useRequestAuthenticationSetter();

  const onFetch = useCallback(
    (args) => {
      let r;
      if (typeof id !== "undefined") {
        r = wrapper.execute("read", { id });
      } else {
        if (args.username) {
          r = wrapper.execute("fetch", args);
        } else {
          return Promise.resolve({ data: [{}] });
        }
      }
      return dec(r, authSetter);
    },
    [wrapper, id, authSetter]
  );

  const onDelete = useCallback(
    (e) => dec(
      wrapper.wrap(e).delete(), 
      authSetter
    ),
    [wrapper, authSetter]
  );

  const onSave = useCallback(
    (e) =>
      dec(
        wrapper.wrap({ ...e, avatar: g(e.avatar) }).update(), 
        authSetter
      ),
    [wrapper, authSetter]
  );

  return {
    onFetch,
    onDelete,
    onSave,
  };
}

export default useCustomerManagement;