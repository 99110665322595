import React, { ReactNode } from "react";
import {makeStyles} from "@material-ui/core"
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";

const s = makeStyles((theme) => ({
  dividerStyles: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }
}));

/**
 *
 * @param {{title: string, subtitle?: string, divider?: boolean, content: ReactNode, disableTypography?: boolean}} param0
 */
export default function Section({
  title,
  subtitle,
  divider,
  content,
  disableTypography,
  style
}) {
  const { dividerStyles } = s();
  return (
    <Box component="section" style={style}>
      <Typography component="h5" variant="h5" gutterBottom>
        {title}
      </Typography>
      {subtitle && <Typography component="h6" variant="body1">
        {subtitle}
      </Typography>}
      {divider && (
        <Box className={dividerStyles}>
          <Divider />
        </Box>
      )}
      {disableTypography ? (
        content
      ) : (
        <Typography component="p" variant="body1">
          {content}
        </Typography>
      )}
    </Box>
  );
}
