import { Field, GenericForm } from "@italwebcom/react-generic-form";
import dayjs from "dayjs";
import { cartType } from "../../../../__domain/model";

export const fieldNames = {
  deliveryZone: "deliveryZone",
  address: "address",
  date: "date",
  time: "time",
  guests: "guests",
  notes: "notes",
  type: "type",
  paymentType: "cartPaymentType",
  totalCost: "totalCost"
};

function undef(v) {
  return typeof v === "undefined";
}

function positiveNum(v) {
  v = (v && v.id) || v;
  return !undef(v) && parseInt(v) > 0;
}

function dateValidator(d) {
  let v = dayjs();
  return dayjs(d).isSame(v, "day") || dayjs(d).isAfter(v, "day");
}

/**
 * @type {Field[]}
 */
const fields = [
  {
    id: fieldNames.type,
    validationRules: [],
  },
  {
    id: fieldNames.totalCost,
    validationRules: [
      {
        priority: 0,
        invalidMessage: "Devi selezionare almeno un piatto.",
        validator: (v, values) => values.type === "in_place" || (v && !undef(v) && (parseFloat(v) > 0))
      }
    ]
  },
  {
    id: fieldNames.paymentType,
    validationRules: [
      {
        priority: 0,
        invalidMessage: "Tipo di pagamento non valido.",
        validator: (v) => v === "direct" || v === "paypal",
      },
    ],
  },
  {
    id: fieldNames.deliveryZone,
    validationRules: [
      {
        priority: 0,
        invalidMessage: "Zona di consegna non valida.",
        validator: (v, values) =>
          values.type !== cartType.delivery || positiveNum(v),
      },
    ],
  },
  {
    id: fieldNames.address,
    validationRules: [
      {
        priority: 0,
        invalidMessage: "Indirizzo non valido.",
        validator: (v, values) =>
          values.type !== cartType.delivery ||
          (v && GenericForm.validators.extendedAlphanumeric.test(v)),
      },
    ],
  },
  {
    id: fieldNames.notes,
    validationRules: [
      {
        priority: 0,
        invalidMessage: "Note non valide.",
        validator: GenericForm.validators.extendedAlphanumeric,
      },
    ],
  },
  {
    id: fieldNames.guests,
    type: "number",
    validationRules: [
      {
        priority: 0,
        invalidMessage: "Numero ospiti non valido.",
        validator: (v, values) =>
          (values.type && values.type !== cartType.in_place) || positiveNum(v),
      },
    ],
  },
  {
    id: fieldNames.time,
    type: "time",
    validationRules: [
      {
        priority: 0,
        invalidMessage: "Orario non valido.",
        validator: /^[0-9]{2}[:][0-9]{2}$/,
      },
    ],
  },
  {
    id: fieldNames.date,
    type: "date",
    validationRules: [
      {
        priority: 0,
        invalidMessage: "Data non valida.",
        validator: dateValidator,
      },
    ],
  },
];

export default fields;
