import React, { useCallback, useEffect, useState, memo } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { Carts, Misc } from "@italwebcom/anema-react-components";
import { useAlertContainerFunctions } from "@italwebcom/misc-ui-components";
import PlaceholderWrapper from "../../../PlaceholderWrapper";
import Button from "@material-ui/core/Button";

const { useCustomerPayer, useCartAndProducts } = Carts.hooks;
const { usePaymentApproval } = Misc.hooks;

function onRedirect(v) {
  console.log(v);
  window.open(v);
}

/**
 * @returns
 */
function CartCustomerPayer({ active, onPaid, timeout, onAbort }) {
  const { onAdd } = useAlertContainerFunctions();
  const onError = useCallback(
    (err) => onAdd({ type: "error", content: "Si è verificato un errore." }),
    [onAdd]
  );
  const { cart } = useCartAndProducts();
  const {
    url,
    loading: approvalLoading,
    onExecute,
  } = usePaymentApproval({ onError });
  const [f, setF] = useState(false);

  const onActualRedirect = useCallback(() => {
    if (url) {
      onRedirect(url);
    }
  }, [url]);

  useEffect(() => {
    if (onExecute && active && !f) {
      setF(true);
      onExecute(cart);
    } else {
      if (!active) {
        setF(false);
      }
    }
  }, [active, onExecute, f, setF, cart]);

  const { isActive } = useCustomerPayer({
    active: active && !approvalLoading,
    onPaid,
    timeout,
    onRedirect: onActualRedirect,
  });

  return (
    isActive && (
      <PlaceholderWrapper active={approvalLoading}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ flexDirection: "column" }}
          component="article"
        >
          {url && (
            <Grid item component="header">
              <Typography variant="body1" component="h5">
                A breve sarai reindirizzato sul sito di PayPal. In alternativa,
                puoi{" "}
                <Link href={cart.paymentUrl} target="_blank">
                  usare questo link
                </Link>
                .
              </Typography>
            </Grid>
          )}
          <Grid item component="section">
            <Typography variant="subtitle2" component="p">
              Ti invitiamo a non chiudere il pannello prima della conferma del
              pagamento.
            </Typography>
          </Grid>
          <Grid item>
            <CircularProgress size="2rem" />
          </Grid>
          <Grid item component="footer" container justifyContent="flex-end">
            <Grid item>
              <Button variant="text" color="secondary" onClick={onAbort}>
                Annulla
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </PlaceholderWrapper>
    )
  );
}

export default memo(CartCustomerPayer);
