import { useCallback } from "react";
import useStorage from "./useStorage";

/**
 * @returns
 */
export default function useStorageSet() {
  const { onSet } = useStorage();
  return useCallback(
    /**
     * @param {Record<string, any>} values
     */
    (values) => {
      let names = Object.getOwnPropertyNames(values);
      names.forEach((name) => {
        onSet(name, values[name]);
      });
    },
    [onSet]
  );
}
