import { useEffect, useMemo, useState } from "react";

/**
 * @typedef {{count: number, callback: (count: number) => void}} CounterCallback
 *
 * @returns
 * @param {{initialValue?: number, callbacks?: CounterCallback[]}} param0
 */
export default function useCounter({ initialValue, callbacks }) {
  const [count, setCount] = useState(
    /* istanbul ignore next */ initialValue || 0
  );
  const { increase, decrease, reset } = useMemo(
    () => ({
      increase: () => setCount((c) => c + 1),
      decrease: () => setCount((c) => c - 1),
      reset: () => setCount(/* istanbul ignore next */ initialValue || 0),
    }),
    [setCount, initialValue]
  );

  useEffect(() => {
    if (callbacks) {
      const matchingCallbacks = callbacks.filter((cb) => cb.count === count);
      for (let mc of matchingCallbacks) {
        mc.callback(count);
      }
    }
  }, [count, callbacks]);

  return { increase, decrease, reset, count };
}
