import React, { memo } from "react";
import Add from "@material-ui/icons/AddCircleOutlined";
import Remove from "@material-ui/icons/RemoveCircleOutlined";
import DeleteForever from "@material-ui/icons/DeleteForever";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core"
import clsx from "clsx";

const s = makeStyles((theme) => ({
  wrapper: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#3C89E4",
    display: "inline-block",
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(1),
  },
  icon: {
    verticalAlign: "baseline",
    "&.small": {
      fontSize: theme.spacing(2.5),
    },
    "&:not(.small)": {
      fontSize: theme.spacing(3),
    },
  },
  mainIconColor: {
    color: theme.palette.primary.main,
  },
  secondaryIconColor: {
    color: theme.palette.error.main,
  },
  valueStyle: {
    fontWeight: "bold",
    marginRight: theme.spacing(0.5),
  },
  costStyle: {
    color: theme.palette.success.main,
    marginLeft: theme.spacing(0.5),
    fontWeight: "bold",
  },
  textStyle: {
    display: "inline-block",
    margin: 0,
  },
  textWrapper: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: "inline-flex",
    verticalAlign: "top",
    height: "28px",
    alignItems: "center",
  },
  timesStyle: {
    verticalAlign: "bottom",
    position: "relative",
    bottom: "3px",
  },
}));

/**
 * @returns
 * @param {{
 *      value: number,
 *      cost: number,
 *      onAdd: Function,
 *      onRemove: Function,
 *      onDelete: Function,
 *      size: "small" | "big",
 *      disabled?: boolean,
 *      addDisabled?: boolean,
 *      removeDisabled?: boolean,
 *      deleteDisabled?: boolean,
 *      loading?: boolean
 * }} param0
 */
function QuantityEditor({
  value,
  cost,
  onAdd,
  onRemove,
  onDelete,
  size,
  disabled,
  addDisabled,
  removeDisabled,
  deleteDisabled,
  loading,
}) {
  const {
    icon,
    mainIconColor,
    secondaryIconColor,
    wrapper,
    costStyle,
    valueStyle,
    textStyle,
    timesStyle,
    textWrapper,
  } = s();
  const isSmall = size === "small";
  return (
    <Box className={wrapper}>
      <IconButton
        size="small"
        className={clsx([icon, mainIconColor, isSmall && "small"])}
        onClick={onRemove}
        disabled={disabled || removeDisabled}
      >
        <Remove fontSize="inherit" color="inherit" />
      </IconButton>
      <Box className={clsx([textWrapper, loading && "loading"])}>
        {loading ? (
          <CircularProgress size={isSmall ? 12 : 24} />
        ) : (
          <Box component="p" className={textStyle}>
            <Typography
              component="span"
              variant={isSmall ? "body1" : "h6"}
              className={valueStyle}
            >
              {value}
            </Typography>
            <Typography
              component="span"
              variant="subtitle"
              className={timesStyle}
            >
              &times;
            </Typography>
            <Typography
              component="span"
              variant={isSmall ? "body2" : "body1"}
              className={costStyle}
            >
              {cost} &euro;
            </Typography>
          </Box>
        )}
      </Box>
      <IconButton
        size="small"
        className={clsx([icon, mainIconColor, isSmall && "small"])}
        onClick={onAdd}
        disabled={disabled || addDisabled}
      >
        <Add fontSize="inherit" color="inherit" />
      </IconButton>
      <IconButton
        size="small"
        className={clsx([icon, secondaryIconColor, isSmall && "small"])}
        onClick={onDelete}
        disabled={disabled || deleteDisabled}
      >
        <DeleteForever fontSize="inherit" color="inherit" />
      </IconButton>
    </Box>
  );
}

export default memo(QuantityEditor);
