import {useContext} from "react";
import Context from "../context";

/**
 * @returns
 * @param {string} role 
 */
export default function useAuthorized(role) {
    const {isAuthorized, credentials} = useContext(Context);
    return credentials && isAuthorized(credentials, role);
}