import React, { memo } from "react";
import {makeStyles} from "@material-ui/core"
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  image: {
    display: "block",
    margin: "auto",
    transitionProperty: "width",
    transitionDuration: "750ms",
  },
  imageWrapper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  label: {
    textAlign: "center",
  },
  contentWrapper: {
    margin: "auto",
    display: "block",
    width: "fit-content"
  },
  externalWrapper: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(223, 232, 253, 0.75)",
      borderRadius: "10px",
      "& .MuiTypography-root": {
        fontWeight: "bold"
      },
      "& .image": {
        width: "100%",
      },
    },
    "&:not(:hover)": {
      "& .image": {
        width: "85%",
      },
    },
  },
}));

/**
 * @param {{label: string, image?: string, content?: string, onClick: Function}} param0
 */
function MegaButton({ label, image, content, onClick }) {
  const {
    image: imageStyle,
    imageWrapper,
    externalWrapper,
    label: labelStyle,
    contentWrapper
  } = useStyles();
  return (
    <Box className={externalWrapper} role="button" onClick={onClick}>
      <Box className={imageWrapper}>
        {content ? (
          <Box className={contentWrapper}>
          {content}
          </Box>
        ) : (
          <img
            src={image}
            className={clsx([imageStyle, "image"])}
            alt={label}
          />
        )}
      </Box>
      {label && (
        <Typography component="h6" variant="h6" className={labelStyle}>
          {label}
        </Typography>
      )}
    </Box>
  );
}

export default memo(MegaButton);
