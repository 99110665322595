/* istanbul ignore file */

function name(n) {
    let out = "prop";
    if(n) {
        out += ` ${n}`;
    }
    return out;
}

/**
 * @typedef {(prop: any, propType: string) => boolean} PropValidator
 */

/**
 * @returns
 * @param {any} prop
 * @param {{validator?: PropValidator, propName?: string, requiredType?: string}} additionalArgs
 */
export function requireProp(prop, additionalArgs) {
    additionalArgs = additionalArgs || {};
    const {validator, propName, requiredType} = additionalArgs;
    let t = typeof prop;
    if(typeof(prop) === "undefined") {
        throw Error(`prop ${name(propName)} is required but missing`);
    }
    if(requiredType && (t !== requiredType)) {
        throw TypeError(`prop ${name(propName)}'s type is ${t}, should be ${requiredType}`)
    }
    if(validator && !validator(prop, t)) {
        throw RangeError(`prop ${name(propName)} isn't valid according to provided validator`)
    }
}

export const validators = {
    isArray: e => e instanceof Array
}