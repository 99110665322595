import Inputs from "./Inputs";
import Provider from "./Provider";
import Submit from "./Submit";
import Error from "./Error";
import DeliveryZoneCostPrinter from "./DeliveryZoneCostPrinter";

const CartCustomerEditor = {
  Provider,
  Inputs,
  Submit,
  Error,
  DeliveryZoneCostPrinter,
};
export default CartCustomerEditor;
