/* istanbul ignore file */

import { NewsItem } from "../../../__domain/model";
import { formatMessageDate, formatMessageHour } from "../../../misc/dateUtils"

/**
 * @type {CollectionAttributeRenderer<NewsItem>[]}
 */
const attributes = [
    {
        id: "title",
        label: "Titolo",
        type: "title",
        sortable: true
    },
    {
        id: "date",
        label: "Data",
        type: "subtitle",
        renderer: e => `pubblicata il ${formatMessageDate(e.date, "DD MMM YYYY")} alle ${formatMessageHour(e.date)}`,
        sortable: true
    },
    {
        id: "image",
        label: "Immagine",
        type: "avatar",
        renderer: e => e.image && e.image.url
    }
];

export default attributes;