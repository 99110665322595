import React, { memo, useMemo } from "react";
import { TagSelectorGroupRendererProps } from "@italwebcom/anema-react-components/Tags";
import List from "@material-ui/core/List";
import Box from "@material-ui/core/Box";
import { RadioLegend } from "../_Auxiliary";
import useTagGroupFunctions from "../hooks/useTagGroupFunctions";
import ListItemRenderer from "./ListItemRenderer";
import DefaultErrorPrinter from "../../../../../_auxiliary/DefaultErrorPrinter";
import { Divider, makeStyles, Typography } from "@material-ui/core";

const s = makeStyles(theme => ({
  panelTitle: {
    color: theme.palette.grey["700"],
    fontWeight: "bold"
  },
  panelTitleWrapper: {
    padding: "12px",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

const MemoListItemRenderer = memo(ListItemRenderer);
function placeholders(n) {
  let out = [];
  for (let i = 0; i < n; i++) {
    out.push({
      id: i,
      name: `placeholder${i}`,
      description: `placeholder${i}`,
    });
  }
  return out;
}

/**
 * @returns
 * @param {TagSelectorGroupRendererProps} param0
 */
function GroupRenderer({ elements, onAdd, onReplace, loading }) {
  const {
    availableTags,
    onActualChange: onChange,
    selected
  } = useTagGroupFunctions({
    elements,
    onAdd,
    onReplace,
  });
  const { actualElements, placeholder } = useMemo(() => {
    const placeholder = (!elements || !elements.length);
    let actualElements;
    if (placeholder) {
      actualElements = placeholders(5);
    } else {
      actualElements = availableTags;
    }
    return { actualElements, placeholder };
  }, [elements, loading, availableTags]);
  const {panelTitle, panelTitleWrapper} = s();
  return (
    <Box>
      <Box className={panelTitleWrapper}>
        <Typography className={panelTitle} component="h6" variant="h6">
          Tipi di piatto
        </Typography>
      </Box>
      <Divider />
      <DefaultErrorPrinter iconSize="small" subtitle=" " />
      <List>
        {actualElements.map(
          (tag) =>
            (
              <MemoListItemRenderer
                element={tag}
                key={tag.id}
                onChange={onChange}
                selected={selected}
                placeholder={placeholder}
              />
            )
        )}
      </List>
    </Box>
  );
}

const Out = memo(GroupRenderer);
export default Out;
