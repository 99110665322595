import React, { memo, useContext, ReactNode } from "react";
import { GenericForm, SubmitButton } from "@italwebcom/react-generic-form";

/**
 * @param {{Button: SubmitButton, children: ReactNode[]}} param0
 * @returns
 */
function CustomFormButtonProvider({ Button, children }) {
  const { FormComponent } = useContext(GenericForm.ConfigurationContext);
  return (
    <GenericForm.ConfigurationContext.Provider
      value={{ FormComponent, SubmitButton: Button }}
    >
      {children}
    </GenericForm.ConfigurationContext.Provider>
  );
}

export default memo(CustomFormButtonProvider);
