import { memo, useContext } from "react";
import EntityManagerContext from "../context";
import { Loader, EntityManagerWrapperProps } from "../defines";
import { ifNotUndef } from "../auxiliary";

/**
 * @param {EntityManagerWrapperProps<Loader>} param0
 */
function LoaderWrapper({ Component }) {
  const { loading } = useContext(EntityManagerContext);
  return ifNotUndef(Component, { loading });
}

export default memo(LoaderWrapper);
