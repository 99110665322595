import {makeStyles} from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  notificationPaper: {
    padding: theme.spacing(2),
    borderRadius: "5px",
    "& p": {
      color: "white",
    },
    marginBottom: theme.spacing(0.5),
  },
  wrapper: {
    maxWidth: "350px",
  },
  dateText: {
    color: "#777474",
  },
  notificationTitle: {
    fontWeight: "bold",
  },
}));

export default useStyles;
