import { templates } from "@italwebcom/anema-shared-web-components";
import { memo } from "react";

const Template = templates.user.chat;

function UserChatPage() {
  return <Template noHeader />;
}

export default memo(UserChatPage);
