import {makeStyles} from "@material-ui/core"

export default makeStyles(theme => ({
    cursor: "pointer",
    wrapper: {
        "&:not(.selected):hover": {
            backgroundColor: "rgb(224 239 255)"
        },
        "&.selected": {
            "&.primary": {
                backgroundColor: "rgb(239 247 255)",
                "& .MuiListItemText-primary": {
                    color: "rgb(32, 132, 232)",
                    fontWeight: "bold"
                },
                "& .MuiListItemText-secondary": {
                    color: theme.palette.primary["light"]
                },
                "& .arrow-wrapper": {
                    color: "rgb(32, 132, 232)"
                }
            },
            "&.secondary": {
                backgroundColor: "#F9E2E2",
                "& .MuiListItemText-primary": {
                    color: "red",
                    fontWeight: "bold"
                },
                "& .MuiListItemText-secondary": {
                    color: theme.palette.secondary["light"]
                },
                "& .arrow-wrapper": {
                    color: "red"
                }
            }
        },
        cursor: "pointer"
    },
    arrowWrapper: {
        display: "flex",
        alignItems: "center",
        fontSize: "2rem"
    },
    opaqueElement: {
        opacity: 0.35
    }
}));