import React, { memo, useCallback } from "react";
import NativeSelect from "@material-ui/core/NativeSelect";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Misc } from "@italwebcom/anema-react-components";

function processValue(v) {
  return v < 10 ? `0${v}` : v;
}

function undef(v) {
  return typeof v === "undefined";
}

/**
 * @returns
 * @param {TimePickerProps}
 */
function TimePicker({
  defaultValue,
  onChange,
  hours,
  minutesGetter,
  valueFormat,
  selectStyle,
  disabled,
}) {

  const { selected, onMinutesSet, onHourSet, availableMinutes } =
    Misc.hooks.useTimePickerFunctions({
      onChange,
      defaultValue,
      minutesGetter,
      hours,
      valueFormat,
    });

  const onActualHourSet = useCallback(evt => onHourSet(evt.target.value), [onHourSet]);
  const onActualMinutesSet = useCallback(evt => onMinutesSet(evt.target.value), [onMinutesSet]);

  return (
    <Box display="flex" alignItems="center">
      <Box flexGrow={1}>
        <NativeSelect
          fullWidth
          disableUnderline
          placeholder="Ore"
          value={selected.hours}
          onChange={onActualHourSet}
          disabled={disabled}
          inputProps={{
            style: selectStyle,
          }}
          variant="outlined"
        >
          <option disabled selected={undef(selected.hours)}>
            Ore
          </option>
          {hours.map((hour) => (
            <option key={hour} value={hour}>
              {processValue(hour)}
            </option>
          ))}
        </NativeSelect>
      </Box>
      <Box marginLeft="0.5rem" marginRight="0.5rem">
        <Typography component="p" variant="body1">
          :
        </Typography>
      </Box>
      <Box flexGrow={1}>
        <NativeSelect
          fullWidth
          disableUnderline
          placeholder="Minuti"
          value={selected.minutes}
          onChange={onActualMinutesSet}
          disabled={disabled || !availableMinutes.length}
          inputProps={{
            style: selectStyle,
          }}
          variant="outlined"
        >
          <option disabled selected={undef(selected.minutes)}>
            Minuti
          </option>
          {availableMinutes &&
            availableMinutes.map((mins) => (
              <option key={mins} value={mins}>
                {processValue(mins)}
              </option>
            ))}
        </NativeSelect>
      </Box>
    </Box>
  );
}

export default memo(TimePicker);
