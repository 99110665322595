import React from "react";

export function defaultEntityComparer(a, b) {
    return a.id === b.id;
}

export function defaultCountGetter(data) {
    return data.count;
}

export function defaultFilterMatcher(f1, f2) {
    return f1.attribute === f2.attribute;
}

export function undef(value) {
    return typeof(value) === "undefined";
}

export function ifNotUndef(Component, Props) {
    if(!undef(Component)) {
        return <Component {...Props} />;
    }
    return null;
}

export function auxProps(a, b) {
    return ({...a, ...(b || {})});
}

export function shouldRender(error, renderOnError) {
    return !error || renderOnError;
}