import React, { memo, ReactNode } from "react";
import {makeStyles} from "@material-ui/core"
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Picture from "../Picture";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  externalWrapper: {
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  logoWrapper: {
    marginBottom: theme.spacing(2),
  },
  logo: {
    "&.active": {
      opacity: 1,
    },
    opacity: 0,
    transitionProperty: "opacity",
    transitionDuration: "750ms",
  },
}));

/**
 * @returns
 * @param {{
 *    logoSrc: string,
 *    progressColor: string,
 *    backgroundColor: string,
 *    active?: boolean,
 *    children?: ReactNode[]
 * }} param0
 */
function FullPageLoader({
  logoSrc,
  progressColor,
  backgroundColor,
  active,
  children,
}) {
  const { logoWrapper, externalWrapper, logo: logoStyle } = useStyles();
  return (
    <Box className={externalWrapper} style={{ backgroundColor }}>
      <Box className={logoWrapper}>
        <Picture
          defaultSrc={logoSrc}
          alt="Logo"
          imgStyle={{ maxWidth: "250px" }}
        />
      </Box>
      <CircularProgress
        size={32}
        style={{ color: progressColor }}
        className={clsx([active && "active", logoStyle])}
      />
      {children}
    </Box>
  );
}

export default memo(FullPageLoader);
