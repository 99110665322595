import React, { useContext, useMemo } from "react";
import Context from "../context";
import { TriggerActivateContentProps } from "../defines";

/**
 * @returns
 * @param {TriggerActivateContentProps} param0
 */
export default function Content({ children, WrapperComponent, trigger }) {
  const { DefaultWrapperComponent, activeTriggers } = useContext(Context);
  let ActualWrapperComponent = useMemo(
    () => WrapperComponent || DefaultWrapperComponent,
    [DefaultWrapperComponent, WrapperComponent]
  );
  const isActive = useMemo(
    () =>
      activeTriggers &&
      activeTriggers.length &&
      activeTriggers.indexOf(trigger) !== -1,
    [activeTriggers, trigger]
  );
  console.log(`TriggerActivate.Content: trigger = ${trigger}, isActive = ${isActive}`);
  return (
    <ActualWrapperComponent in={isActive}>{children}</ActualWrapperComponent>
  );
}
