import React, {
  memo,
  useMemo,
  ReactNode,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import useCustomerWrapper from "../../../../../hooks/wrappers/useCustomerWrapper";
import useCartWrapper from "../../../../../hooks/wrappers/useCartWrapper";
import { EntityManager, useEntityManagerValues } from "@italwebcom/react-entity-manager";
import useAlertFunctions from "../../../../misc/AlertProvider/hooks/useAlertFunctions";
import useRequestAuthenticationSetter from "../../../../authentication/hooks/useRequestAuthenticationSetter";
import useDefaultComponents from "../../../../misc/DefaultComponentsProvider/hooks/useDefaultComponents";
import Context from "../context";
import { CartStatus } from "../../../../../__domain/model";

function d(r, s) {
  s(r.request());
  return r.json();
}

function useCartsManagementFuncs(customer) {
  const { onAdd } = useAlertFunctions();
  const customerWrapper = useCustomerWrapper();
  const cartWrapper = useCartWrapper();
  const s = useRequestAuthenticationSetter();

  return useMemo(
    () => ({
      onError: () =>
        onAdd({ type: "error", content: "Si è verificato un errore." }),
      onFetch: (args) =>
        d(customerWrapper.wrap(customer).execute("carts", args), s),
      onDelete: (c) => d(cartWrapper.wrap(c).delete(), s),
      onSave: (c) => d(cartWrapper.wrap(c).update(), s),
    }),
    [onAdd, customerWrapper, customer, s]
  );
}

const AuxCollectionRenderer = memo(({ children }) => {
  const {
    loading,
    elements,
    onDelete,
    onSave,
    error,
    onRefreshAll,
    onSorterChange,
    onFiltersSetAll,
    onFiltersClear,
    onItemsPerPageIncrease,
  } = useEntityManagerValues();

  const { onSortByDate, onFilterByStatus, onFilterUnpaid, onFilterOpen } =
    useMemo(
      () => ({
        onSortByDate: (from) => {
          onSorterChange({
            attribute: "date",
            direction: from === "latest" ? "desc" : "asc",
          });
        },
        onFilterByStatus: (status) => {
          onFiltersSetAll([{ attribute: "status", value: status }]);
        },
        onFilterUnpaid: () => {
          onFiltersSetAll([{ attribute: "status", value: "CLOSED" }]);
        },
        onFilterOpen: () => {
          onFiltersSetAll([{ attribute: "status", value: "OPEN" }]);
        },
      }),
      [onSorterChange, onFiltersSetAll]
    );

  return (
    <Context.Provider
      value={{
        loading,
        elements,
        onDelete,
        onSave,
        error,
        onRefreshAll,
        onSorterChange,
        onFiltersSetAll,
        onFiltersClear,
        onSortByDate,
        onFilterByStatus,
        onFilterUnpaid,
        onFilterOpen,
        onItemsPerPageIncrease,
      }}
    >
      {children}
    </Context.Provider>
  );
});

/**
 *
 * @param {{customer: Customer, children: ReactNode[], status?: CartStatus}} param0
 * @returns
 */
function CartsCustomerManager(
  { customer, children, status, initialItemsPerPage },
  ref
) {
  const { onError, onFetch, onDelete, onSave } =
    useCartsManagementFuncs(customer);
  const { fetchArgsProcessor, defaultCountGetter } = useDefaultComponents();

  const innerRef = useRef();

  useImperativeHandle(
    ref,
    () => ({
      refresh: () => innerRef.current && innerRef.current.refresh(),
    }),
    [innerRef]
  );

  return (
    <EntityManager.Crud
      fetchArgsProcessor={fetchArgsProcessor}
      countGetter={defaultCountGetter}
      onFetch={onFetch}
      onSave={onSave}
      onDelete={onDelete}
      onError={onError}
      initialFilters={status ? [{ attribute: "status", value: status }] : []}
      initialItemsPerPage={initialItemsPerPage || 10}
      initialSorter={{ attribute: "date", direction: "desc" }}
      ref={innerRef}
    >
      <EntityManager.Components.CollectionRenderer
        Component={AuxCollectionRenderer}
        ComponentProps={{ children }}
        renderOnError
      />
    </EntityManager.Crud>
  );
}

export default memo(forwardRef(CartsCustomerManager));
