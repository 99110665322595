import React, { memo, ComponentType, useContext, useMemo } from "react";
import ErrorContext from "../context";
import {
  EntityManagementError,
  EntityManagementErrorType,
} from "@italwebcom/custom-react-hooks";

/**
 * @typedef {(error: EntityManagementError) => boolean} ApplicabilityChecker
 *
 * @typedef {{
 *      error: EntityManagementError,
 *      onRefreshAll: Function
 * }} ErrorHandlerProps
 *
 * @typedef {{
 *      Component: ComponentType<ErrorHandlerProps>,
 *      ComponentProps?: any,
 *      errorType?: EntityManagementErrorType,
 *      isApplicable?: ApplicabilityChecker
 * }} ErrorWrapperProps
 *
 */

/**
 * @param {EntityManagementErrorType} t
 * @return {ApplicabilityChecker}
 */
function getDefaultIsApplicable(t) {
  return (e) => e.type === t;
}

/**
 * @param {ErrorWrapperProps} param0
 */
function ErrorWrapper({ Component, ComponentProps, errorType, isApplicable }) {
  const { error, onRefreshAll } = useContext(ErrorContext);
  const applicabilityChecker = useMemo(() => {
    if (errorType) {
      return getDefaultIsApplicable(errorType);
    } else {
      return isApplicable;
    }
  }, [isApplicable, errorType]);
  const isActuallyApplicable = useMemo(
    () => error && applicabilityChecker && applicabilityChecker(error),
    [applicabilityChecker, error]
  );
  if (isActuallyApplicable) {
    return (
      <Component
        {...(ComponentProps || {})}
        error={error}
        onRefreshAll={onRefreshAll}
      />
    );
  }
  return null;
}

export default memo(ErrorWrapper);
