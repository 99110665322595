function is(c, r) {
  return c.realm_access.roles.indexOf(r) !== -1;
}

/**
 *
 * @param {UserCredentials} c
 * @param {string} r
 * @param {string} defaultRole
 * @returns
 */
export default function isAuthorized(c, r, defaultRole) {
  if (c && c.realm_access && c.realm_access.roles) {
    if (r === "admin") {
      return is(c, "admin");
    }
    if (r === "standard") {
      return is(c, "admin") || is(c, "standard");
    }
    if (r === defaultRole) {
      return is(c, defaultRole);
    }
  }
  return false;
}
