import React, { memo, useCallback } from "react";
import Fieldset from "../../../Fieldset";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useRuntimeContext } from "../../../../contexts";

function AltPaymentMethodSetter({ value, onChange, disabled }) {
  const { onlyDefaultPayment } = useRuntimeContext();
  const onActualChange = useCallback(
    (evt, v) => onChange({ target: { value: v } }),
    [onChange]
  );
  return (
    <Fieldset label="Pagamento con" horizontal>
      <RadioGroup
        value={value}
        onChange={onActualChange}
        row
        disabled={onlyDefaultPayment || disabled}
      >
        <FormControlLabel
          label="Contanti"
          value="direct"
          control={<Radio size="small" color="primary" />}
          disabled={disabled}
        />
        <FormControlLabel
          label="Paypal"
          value="paypal"
          control={<Radio size="small" color="primary" />}
          style={{ marginRight: 0 }}
          disabled={onlyDefaultPayment || disabled}
        />
      </RadioGroup>
    </Fieldset>
  );
}
export default memo(AltPaymentMethodSetter);
