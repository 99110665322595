import { CircularProgress } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import React, { memo } from "react";

function ProductSelectionChip({ selectedElement, loading }) {
  return (
    <Box display="flex" alignItems="center">
      <Box
        fontSize="0.85rem"
        color="white"
        position="relative"
        top="2px"
        marginRight="0.25rem"
      >
        <ShoppingCart color="inherit" fontSize="inherit" />
      </Box>
      {loading ? (
        <CircularProgress color="white" size={12} />
      ) : (
        <Typography component="p" variant="subtitle2">
          {selectedElement.quantity} x {selectedElement.cost}€
        </Typography>
      )}
    </Box>
  );
}

export default memo(ProductSelectionChip);
