import React, { memo } from "react";
import { useCartProvider, CartProvider } from "../../../CartProvider";
import { CartProductProvider } from "../../../CartProductProvider";
import { Filter } from "@italwebcom/custom-react-hooks";

const InnerCartProductProvider = memo(
  /**
   * @param {{children: ReactChildren}} param0
   * @returns
   */
  ({ children }) => {
    const { cart } = useCartProvider();
    return <CartProductProvider cart={cart}>{children}</CartProductProvider>;
  }
);

/**
 * @returns
 * @param {{
 *    cart: number,
 *    children: ReactChildren,
 *    filters: Filter<any>[]
 * }} param0
 */
function CartAndProductsProvider({ cart, children, filters }) {
  return (
    <CartProvider cart={cart} asCustomer filters={filters}>
      <InnerCartProductProvider>{children}</InnerCartProductProvider>
    </CartProvider>
  );
}

export default memo(CartAndProductsProvider);
export { InnerCartProductProvider };
