import { useContext } from "react";
import Context from "../context";

/**
 * 
 * @returns 
 */
export default function useInputWrapperGroup() {
  return useContext(Context);
}
