import React, { memo } from "react";
import ElementRenderer from "../ElementRenderer";
import List from "@material-ui/core/List";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core";

const s = makeStyles((theme) => ({
  wrapper: {
    marginTop: theme.spacing(2),
  },
  title: {
    fontWeight: "bold",
    color: theme.palette.grey["800"],
    textAlign: "center"
  },
}));

function ListRenderer({
  elements,
  loading,
  selectionIndex,
  onSave,
  placeholder,
}) {
  const { title: titleStyle, wrapper } = s();
  return (
    <Box component="article" className={wrapper}>
      <Box component="header">
        <Typography component="h6" variant="h6" className={titleStyle}>
          Selezione per piatto {selectionIndex + 1}
        </Typography>
      </Box>
      <Box component="section">
        <List>
          {elements.map((e) => (
            <ElementRenderer
              element={e}
              key={e.name}
              placeholder={placeholder}
              onSave={onSave}
              loading={loading}
            />
          ))}
        </List>
      </Box>
    </Box>
  );
}

export default memo(ListRenderer);
