import React, { memo, ReactNode, useEffect, useRef } from "react";
import Crud from "../Crud";
import useCartWrapper from "../../../../../../hooks/wrappers/useCartWrapper";
import { useAlertFunctions } from "../../../../../../components/misc/AlertProvider";
import useCartProductFunctions from "../../hooks/useCartProductFunctions";
import useAuthenticatedRequest from "../../../../../authentication/hooks/useAuthenticatedRequest";
import useRequestAuthenticationSetter from "../../../../../authentication/hooks/useRequestAuthenticationSetter";

export function useMiscRefs({ cart }) {
  const childRef = useRef();
  const cartRef = useRef();
  useEffect(() => {
    cartRef.current = cart;
    childRef.current && childRef.current.refresh();
  }, [cart]);
  return { childRef, cartRef };
}

/**
 * @param {{cart: number, children: ReactNode[]}} param0
 * @returns
 */
function CartProductProvider({ cart, children }) {

  const wrapper = useCartWrapper();
  const { onAdd: onMessageAdd } = useAlertFunctions();
  const { childRef, cartRef } = useMiscRefs({ cart });
  const requestProcessor = useRequestAuthenticationSetter();

  const { 
    onActualError: onError, 
    onActualSuccess: onSuccess, 
    onActualSave: onSave, 
    onDelete, 
    onFetch 
  } = useCartProductFunctions({ 
    wrapper, 
    cartRef, 
    onMessageAdd, 
    id: "main",
    requestProcessor
  });

  return (
    <Crud
      onSave={onSave}
      onDelete={onDelete}
      onFetch={onFetch}
      onSuccess={onSuccess}
      onError={onError}
      cart={cart}
      ref={childRef}
    >
      {children}
    </Crud>
  );
}

export default memo(CartProductProvider);
