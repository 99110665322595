import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import clsx from "clsx";
import React, { memo, useCallback, useContext } from "react";
import Context from "../context";
import useStyles from "../styles";
import Box from "@material-ui/core/Box";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

function CellRenderer({ element, attribute, isLast, selected }) {
  const { lastCell, selectedElementArrow } = useStyles();
  const { id, renderer } = attribute;
  return (
    <TableCell key={id} className={clsx([isLast && lastCell])}>
      {renderer ? renderer(element) : element[id]}
      {isLast && (
        <Box className={clsx([selectedElementArrow, selected && "visible"])}>
          <ArrowForwardIos fontSize="inherit" />
        </Box>
      )}
    </TableCell>
  );
}

const RowRenderer = memo(({ element }) => {
  const { selected, onSelect, attributes } = useContext(Context);
  const { row } = useStyles();
  const isSelected = selected && element.id === selected.id;
  const onActualSelect = useCallback(
    () => onSelect(element),
    [onSelect, element]
  );
  return (
    <TableRow
      key={element.id}
      className={clsx([isSelected && "selected", row])}
      onClick={onActualSelect}
    >
      {attributes.map((a, i) => (
        <CellRenderer
          attribute={a}
          element={element}
          isLast={i === attributes.length - 1}
          selected={isSelected}
        />
      ))}
    </TableRow>
  );
});

export default RowRenderer;
