import DeliveryZoneProvider from "./DeliveryZoneProvider";
import useDeliveryZones from "./hooks/useDeliveryZones";
import CostProvider from "./DeliveryZoneCostProvider/Component";
import useDeliveryZoneCost from "./DeliveryZoneCostProvider/hooks/useDeliveryZoneCost";

const components = {
  Provider: DeliveryZoneProvider,
  CostProvider: CostProvider,
};
const hooks = { useDeliveryZones, useDeliveryZoneCost };

export { components, hooks };
