import { useMemo } from "react";
import useCartWrapper from "../../../../../hooks/wrappers/useCartWrapper";
import { useAlertFunctions } from "../../../../../components/misc/AlertProvider";
import {
  useTestCallback,
  callbacks,
} from "../../../../../__test-auxiliary/TestCallbacks";
import { useNavigation } from "../../../../misc/NavigationProvider";

//const missingRegex = /^(missing_entity_)[0-9]+$/;

export default function useCartProviderOperations({
  asCustomer,
  onAfterSave,
  requestProcessor,
}) {
  const cartWrapper = useCartWrapper();
  const { navigate } = useNavigation();
  const { onAdd: onAlertAdd } = useAlertFunctions();
  const cartSaveTestCallback = useTestCallback(callbacks.Carts.cartSave);

  return useMemo(
    () => ({
      onSuccess: (op) =>
        op !== "fetch" &&
        onAlertAdd({ type: "success", content: "Prenotazione modificata." }),
      onError: (err) => {
        if (asCustomer) {
          onAlertAdd({
            type: "error",
            content: "Si è verificato un errore nel caricamento del carrello.",
          });
        } else {
          onAlertAdd({ type: "error", content: "Si è verificato un errore." });
        }
      },
      onFetch: (args) => {
        let e = cartWrapper.execute("read", args);
        if (requestProcessor) {
          requestProcessor(e.request());
        }
        return e.json();
      },
      onSave: (e) => {
        cartSaveTestCallback && cartSaveTestCallback(e);
        let p;
        //sigh
        if (e.patch) {
          p = cartWrapper
            .wrap({ ...e })
            .execute("patch", { type: e.type }, true);
        } else {
          p = cartWrapper
            .wrap({
              ...e,
              deliveryZoneId:
                e.deliveryZoneId || (e.deliveryZone && e.deliveryZone.id),
            })
            .update();
        }
        if (requestProcessor) {
          requestProcessor(p.request());
        }
        return p.json().then(() => onAfterSave && onAfterSave(e));
      },
    }),
    [onAlertAdd, cartWrapper, asCustomer, navigate, requestProcessor]
  );
}
