import { Category } from "../../model";
import imageUrls from "../auxiliary/imageData";
import wrappers from "../hateoas";

/**
 * @type {Category[]}
 */
let categories = [];

for (let i = 1; i < 20; i += 2) {
  categories.push(
    wrappers.category({
      id: i,
      name: `category${i}`,
      type: i !== 1 && i !== 9 ? "category" : "top_category",
      parent: i !== 1 && i !== 9 ? (i < 9 ? 1 : 9) : undefined,
      hasChildren: i === 1 || i === 9,
      mainImageUrl: imageUrls[i % imageUrls.length],
      unavailable: (i > 8) && (i < 14)
    })
  );
}

export default categories;
