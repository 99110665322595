import React, { memo } from "react";
import Picture, { Source } from "../Picture";
import Grid from "@material-ui/core/Grid";
import { Box, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";

const styles = {
  img: {
    maxWidth: "100%",
  },
  figure: {
    margin: 0,
  },
};

const s = makeStyles((theme) => ({
  outer: {
    position: "relative",
  },
  absolute: {
    position: "absolute",
    width: "100%",
    height: "100%",
    boxSizing: "border-box"
  },
  backdropWrapper: {
    zIndex: 1,
  },
  titleWrapper: {
    zIndex: 2,
    padding: theme.spacing(3),
  },
  text: {
    color: "white",
  },
}));

/**
 * @typedef {{defaultSrc: string, sources?: Source[], alt: string}} InnerPictureProps
 * @typedef {{color: string, opacity: number}} PictureGroupBackdrop
 * @typedef {{spacing?: number, pictures: InnerPictureProps[], backdrop?: PictureGroupBackdrop, title?: string, subtitle?: string}} PictureGroupProps
 *
 * @param {PictureGroupProps} param0
 * @returns
 */
function PictureGroup({ spacing, pictures, backdrop, title, subtitle }) {
  const { outer, absolute, backdropWrapper, titleWrapper, text } = s();
  return (
    <Box className={outer} component="article">
      {backdrop && (
        <Box
          component="aside"
          className={clsx([backdropWrapper, absolute])}
          style={{ backgroundColor: backdrop.color, opacity: backdrop.opacity }}
        />
      )}
      {title && (
        <Box className={clsx([absolute, titleWrapper])} component="header">
          <Typography
            component="h5"
            variant="h5"
            className={text}
            gutterBottom={Boolean(subtitle)}
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography component="p" variant="body2" className={text}>
              subtitle
            </Typography>
          )}
        </Box>
      )}
      <Grid container component="section">
        {pictures.map(({ defaultSrc, sources, alt }) => (
          <Grid item xs={spacing}>
            <Picture
              defaultSrc={defaultSrc}
              sources={sources}
              alt={alt}
              imgStyle={styles.img}
              figureStyle={styles.figure}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default memo(PictureGroup);
