const imageUrls = [
    "https://billis.italweb.app/billis-app-alt/server/public/img/824711335.jpg",
    "https://billis.italweb.app/billis-app-alt/server/public/img/824711335.jpg",
    "https://billis.italweb.app/billis-app-alt/server/public/img/824711335.jpg",
    "https://billis.italweb.app/billis-app-alt/server/public/img/824711335.jpg",
    "https://billis.italweb.app/billis-app-alt/server/public/img/824711335.jpg",
    "https://billis.italweb.app/billis-app-alt/server/public/img/824711335.jpg",
    "https://billis.italweb.app/billis-app-alt/server/public/img/824711335.jpg",
    "https://billis.italweb.app/billis-app-alt/server/public/img/824711335.jpg",
  ];
  
  export default imageUrls;
  