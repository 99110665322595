import useTempCartCode from "../useTempCartCode";
import useCustomHeadersRequest from "../../../../../hooks/useCustomHeadersRequest";
import decorateRequest from "../../../../../hooks/_auxiliary/decorateRequest";
import { useCallback } from "react";

/**
 * @returns
 * @param {Function} operator
 */
function useTempCartAuthenticationSetter() {
  /* gets temp code from storage */
  const { onGet: getCode } = useTempCartCode();

  return useCallback(
    /**
     * @param {FetchRequest} r
     * @returns
     */
    (request) =>
      decorateRequest({ request, headers: getCode, decoratorName: "tempCart" }),
    [getCode]
  );
}

export default useTempCartAuthenticationSetter;
