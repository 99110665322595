import context from "../context";
import { useContext, useMemo } from "react";

/**
 * @returns
 */
export default function useProductProvider() {
  const { products, onSelect } = useContext(context);
  const singleProduct = useMemo(
    () => (products && products.length ? products[0] : null),
    [products]
  );
  return { products, singleProduct, onSelect };
}
