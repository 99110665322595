import { createContext } from "react";

export function tagFilterer(t, n) {
  return t.name === n;
}

export function actualOnScrollTo(x, y) {
  window.scrollTo({
    top: y + window.scrollY,
    left: x + window.scrollX,
    behavior: "smooth",
  });
}

const C = createContext({ variant: "simple" });
export {C};