import { withDefaultHateoas } from "../auxiliary/getDefaultHateoasData";
import links from "../../links";
import {Cart} from "../../../model";

/**
 * @param {Cart} cartData 
 * @returns 
 */
export default function cartHateoasWrapper(cartData) {
  const {id} = cartData;
  let c = withDefaultHateoas(links.cart.base, cartData);
  c._links.products = {
    href: links.cart.products(id),
    templated: true,
  };
  c._links.deliveryZoneCost = {
    href: links.cart.deliveryZoneCost(id),
    templated: true
  };
  c._templates.addProduct = {
    target: links.cart.addProduct(id),
    method: "POST",
    properties: []
  }
  c._templates.acquire = {
    target: links.cart.acquire,
    method: "POST",
    properties: []
  };
  c._templates.patch = {
    target: links.cart.self(id),
    method: "PATCH",
    properties: []
  }
  return c;
}
