import React, { ReactNode } from "react";
import { Alert } from "@material-ui/lab";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    cursor: "pointer",
    opacity: "0.75",
    "&:hover": {
      opacity: 1,
    },
  },
}));

/**
 * @typedef {"success" | "warning" | "error" | "info"} HulkAlertType
 * @typedef {{children: ReactNode, type: HulkAlertType, onDismiss: Function}} HulkAlertProps
 * @param {HulkAlertProps} param0
 */
export default function HulkAlert({ children, type, onDismiss }) {
  const { wrapper } = useStyles();
  return (
    <Box className={wrapper} onClick={onDismiss} role="button">
      <Alert variant="filled" severity={type}>
        {children}
      </Alert>
    </Box>
  );
}
