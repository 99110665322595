import React, { memo, ReactNode } from "react";
import {
  EntityManager,
  CollectionRendererProps,
  useEntityManagerValues,
} from "@italwebcom/react-entity-manager";
import useDefaultComponents from "../../../../components/misc/DefaultComponentsProvider/hooks/useDefaultComponents";
import useConversationProviderFunctions from "../hooks/useConversationProviderFunctions";
import Context from "../context";
import { Conversation } from "../../../../__domain/model";

/**
 * @returns
 * @param {CollectionRendererProps<Conversation>} param0
 */
const CollectionRenderer = memo(({ elements, children, onRefreshAll }) => {
  const { loading, error } = useEntityManagerValues();
  const e = elements && elements.length ? elements[0] : null;
  const conversationErrored = error && (error.type === "fetch_error");
  return (
    <Context.Provider
      value={{
        conversationLoading: loading,
        conversation: e,
        conversationErrored,
        onRefreshConversation: onRefreshAll,
      }}
    >
      {children}
    </Context.Provider>
  );
})

/**
 * @returns
 * @param {{id: number, children: ReactNode[]}} param0
 */
function ConversationProvider({ id, children }) {
  
  const { fetchArgsProcessor } = useDefaultComponents();
  const onFetch = useConversationProviderFunctions(id);

  return (
    <EntityManager.ReadOnly
      countGetter={() => 1}
      fetchArgsProcessor={fetchArgsProcessor}
      onFetch={onFetch}
      initialFilters={[]}
      initialItemsPerPage={1}
      initialSorter={{}}
    >
      <EntityManager.Components.CollectionRenderer
        Component={CollectionRenderer}
        ComponentProps={{ children }}
        renderOnError
      />
    </EntityManager.ReadOnly>
  );
}

export default memo(ConversationProvider);
