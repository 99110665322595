import React, { memo } from "react";
import { Carts } from "@italwebcom/anema-react-components";
import ProductQuantitySelectionGroup from "../ProductQuantitySelectionGroup";
import ProductSelectionChipContent from "../ProductSelectionChipContent";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import { HulkButton } from "@italwebcom/misc-ui-components";

const { useCartAndProducts, useCartDerivedAttributes } = Carts.hooks;

const s = makeStyles((theme) => ({
  quantityChip: {
    backgroundColor: theme.palette.success.main,
    color: "white",
  },
  costChip: {
    fontWeight: "800",
    borderColor: theme.palette.primary.light,
    color: theme.palette.primary.light,
  },
}));

export function CostChip({ element, size }) {
  const { costChip } = s();
  if (element && element.cost) {
    return (
      <Box display="flex">
        <Chip
          variant="outlined"
          label={`${element.cost}€`}
          className={costChip}
          size={size}
        />
      </Box>
    );
  }
  return null;
}

export function QuantityChip({ selectedElement, size, loading }) {
  const { quantityChip } = s();
  if (selectedElement) {
    return (
      <Chip
        label={
          <ProductSelectionChipContent
            selectedElement={selectedElement}
            loading={loading}
          />
        }
        key={selectedElement.quantity}
        className={quantityChip}
        size={size}
      />
    );
  }
  return null;
}

/**
 * @param {{
 *    selectedElement: CartProduct,
 *    element: Product,
 *    onDelete: Function,
 *    onChange: (q: number) => void,
 *    isSelected?: boolean,
 *    onSelect?: Function,
 *    costs?: boolean
 * }} param0
 * @returns
 */
function ActionContent({
  selectedElement,
  element,
  onDelete,
  onChange,
  isSelected,
  onSelect,
  costs,
}) {
  const { cart, productsLoading: loading } = useCartAndProducts();
  const { editable } = useCartDerivedAttributes(cart);

  const actuallyEditable = editable && !element.unavailable;

  if (isSelected) {
    return (
      <Box
        display="flex"
        justifyContent={costs ? "space-between" : "flex-end"}
        alignItems="center"
        height="100%"
      >
        {actuallyEditable && (
          <ProductQuantitySelectionGroup
            onChange={onChange}
            selectedElement={selectedElement}
            element={element}
            onDelete={onDelete}
          />
        )}
        {selectedElement && costs && (
          <QuantityChip selectedElement={selectedElement} />
        )}
      </Box>
    );
  } else {
    return (
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        height="100%"
      >
        {costs && <CostChip element={element} />}
        {onSelect && (
          <HulkButton
            disabled={loading || element.unavailable}
            variant="light"
            onClick={onSelect}
            startIcon={loading && <CircularProgress size={12} color="white" />}
            label={
              element.unavailable
                ? "Non disponibile"
                : loading
                ? "Attendere..."
                : "Seleziona"
            }
          />
        )}
      </Box>
    );
  }
}

export default memo(ActionContent);
