import { DeliveryCost } from "../../model";
import wrappers from "../hateoas";

const n = 20;

/**
 * @type {DeliveryCost[]}
 */
let costs = [];

for (let i = 10; i < 10 + n; i++) {
  let dz = Math.floor((i - 10) / 2);
  costs.push(
    wrappers.deliveryCost({
      id: i,
      name: `Costo numero ${i}`,
      priority: i % 2,
      cost: i * 10 + 0.5,
      deliveryZone: dz,
    })
  );
}

export default costs;
