import React, { memo, useContext } from "react";
import Context from "../Context";
import TagProvider from "../../TagProvider";
import { useEntityManagerValues } from "@italwebcom/react-entity-manager";
import { GroupProps, GroupRenderer } from "../defines";
import useGroupElements from "../hooks/useGroupElements";

const InnerRenderer = memo(
  /**
   * @param {{Renderer: GroupRenderer}} param0
   * @returns
   */
  ({ Renderer }) => {
    const { elements, loading } = useEntityManagerValues();
    const { selected, onAdd, onRemove, onReplace } = useContext(Context);
    const groupElements = useGroupElements(elements, selected);
    return (
      <Renderer
        elements={groupElements}
        onAdd={onAdd}
        onRemove={onRemove}
        onReplace={onReplace}
        loading={loading}
      />
    );
  }
);

/**
 * @param {GroupProps} param0
 * @returns
 */
function Group({
  filterType,
  Renderer,
  RendererProps,
  initialItemsPerPage,
  lazyCountRecompute,
}) {
  return (
    <TagProvider
      filterType={filterType}
      addCart
      initialItemsPerPage={initialItemsPerPage}
      lazyCountRecompute={lazyCountRecompute}
    >
      <InnerRenderer Renderer={Renderer} {...(RendererProps || {})} />
    </TagProvider>
  );
}

export default memo(Group);
