import React, { memo, CSSProperties, useMemo } from "react";
import Typography, { TypographyProps } from "@material-ui/core/Typography";

/**
 * @returns
 * @param {number} minWidth
 */
function getMediaQuery(minWidth) {
  return `(min-width: ${minWidth}px)`;
}

function getSrcSet({ href, dimensionDescriptor }) {
  return `${href} ${dimensionDescriptor}`;
}

/**
 * @typedef {{href: string, minWidth?: number, dimensionDescriptor?: string}} Source
 *
 * @returns
 * @param {{
 *      sources?: Source[],
 *      defaultSrc?: string,
 *      alt: string,
 *      pictureStyle?: CSSProperties,
 *      imgStyle?: CSSProperties,
 *      figureStyle?: CSSProperties,
 *      caption?: string,
 *      captionTypographyProps?: TypographyProps
 * }} param0
 */
function Picture({
  sources,
  defaultSrc,
  alt,
  pictureStyle,
  imgStyle,
  figureStyle,
  caption,
  captionTypographyProps,
}) {
  const sortedSources = useMemo(
    () => sources ? sources.sort((a, b) => b.minWidth - a.minWidth) : null,
    [sources]
  );
  return (
    <figure style={figureStyle}>
      <picture style={pictureStyle}>
        {sortedSources && sortedSources.map(({ minWidth, href, dimensionDescriptor }) => (
          <source
            key={minWidth}
            media={getMediaQuery(minWidth)}
            srcSet={getSrcSet({ href, dimensionDescriptor })}
          />
        ))}
        <img src={defaultSrc} alt={alt} style={imgStyle} />
      </picture>
      {caption && (
        <figcaption>
          <Typography
            component="span"
            variant="caption"
            {...(captionTypographyProps || {})}
          >
            {caption}
          </Typography>
        </figcaption>
      )}
    </figure>
  );
}

export default memo(Picture);
