import {
  AuthRequestInputs,
  AuthRequest,
  AuthRequestResponse,
  DiscoveryDocument as AugmentedFetchDiscoveryDocument,
  TokenRequestInputs,
  TokenResponse,
  TokenResponseData,
} from "@italwebcom/augmented-fetch";
import OAuth2Client, { request as makeRequest } from "../client";
import "regenerator-runtime/runtime";
import {
  DiscoveryDocument,
  OAuth2Response,
  DateGetter,
  PasswordGrantData,
  OAuth2Config,
} from "../defines";

function getAltIssuedAt() {
  return Math.floor(new Date().getTime() / 1000);
}

const refreshToken = async (request, refreshToken) => {
  const data = await request.refreshToken(refreshToken);
  const { access_token, refresh_token, expires_in, issued_at } = data;
  return new OAuth2WebClientToken(
    access_token,
    refresh_token,
    expires_in,
    issued_at || getAltIssuedAt(),
    request
  );
};

/**
 * @param {Date} d
 * @return {number}
 */
const epochSeconds = (d) => Math.round(d.getTime() / 1000);

/**
 * @param {DiscoveryDocument} discoveryDocument
 * @return {AugmentedFetchDiscoveryDocument}
 */
const adaptDiscoveryDocumentFrom = (discoveryDocument) => {
  const { authorization_endpoint, token_endpoint, userinfo_endpoint } =
    discoveryDocument;
  return {
    tokenEndpoint: token_endpoint,
    authorizationEndpoint: authorization_endpoint,
    userInfoEndpoint: userinfo_endpoint,
  };
};

/**
 * @param {AugmentedFetchDiscoveryDocument} discoveryDocument
 * @return {DiscoveryDocument}
 */
const adaptDiscoveryDocumentTo = (discoveryDocument) => {
  const { authorizationEndpoint, tokenEndpoint, userInfoEndpoint } =
    discoveryDocument;
  return {
    token_endpoint: tokenEndpoint,
    authorization_endpoint: authorizationEndpoint,
    userinfo_endpoint: userInfoEndpoint,
  };
};

/**
 * @returns {AuthRequestResponse}
 * @param {OAuth2Response} response
 */
const adaptResponse = (response) => {
  let { status, statusText, statusCode, authorization_code, code_verifier } =
    response;
  statusCode = statusCode || 400;
  return {
    error: status === "error" && statusText,
    errorCode: status === "error" && statusCode,
    type: status,
    params: {
      code: authorization_code,
      codeVerifier: code_verifier,
    },
  };
};

export class OAuth2WebClientToken {
  accessToken;
  refreshToken;
  expiresIn;
  issuedAt;

  /**
   * @type {OAuth2WebClientAuthRequest}
   */
  _request;

  constructor(accessToken, refreshToken, expires_in, issued_at, request) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    this.expiresIn = expires_in;
    this.issuedAt = issued_at;
    this._request = request;
  }

  /**
   * @returns
   */
  async refreshAsync() {
    return refreshToken(this._request, this.refreshToken);
  }
}

export class OAuth2WebClientAuthRequest {
  /**
   * @type {AuthRequestInputs}
   */
  _inputs;

  /**
   * @type {OAuth2Client}
   */
  _client;

  /**
   * @param {AuthRequestInputs} stuff
   * @param {OAuth2Client} client
   */
  constructor(stuff, client) {
    this._inputs = stuff;
    this._client = client;
  }

  /**
   * Uses the authorization code grant to fetch an auth code, calling the appropriate method of the
   * OAuth2ClientRequest instance.
   *
   * @return {Promise<AuthRequestResponse>}
   * @param {string} discoveryDocument
   * @param {boolean} pkce
   */
  async promptAsync(discoveryDocument, pkce) {
    try {
      const dDoc = adaptDiscoveryDocumentTo(discoveryDocument);
      console.log(dDoc);
      let request = makeRequest(
        this._client,
        this._inputs
      ).withDiscoveryDocument(dDoc);
      console.log("requesting auth code");
      const response = await request.requestAuthCode(pkce);
      return adaptResponse(response);
    } catch (e) {
      throw adaptResponse(e);
    }
  }
}

function makeToken(data, request) {
  const { access_token, refresh_token, expires_in, issued_at } = data;
  return new OAuth2WebClientToken(
    access_token,
    refresh_token,
    expires_in,
    issued_at || getAltIssuedAt(),
    request
  );
}

/*
    Used as adapter for the interface expected by the OpenIdConnectTokenManager class defined in the augmented-fetch lib
    See integration test for usage example:

    1) build the client: client <- new OAuth2Client(fetcher, windowHandler, btoa)
    2) build the facade: facade <- new AuthorizationServerFacade(client, dateGetter)
    3) build the manager: manager <- new OpenIDConnectTokenManager(config, facade)
*/
export default class OAuth2WebClientAuthorizationServerFacade {
  /**
   * @type {OAuth2Client}
   */
  _client;

  /**
   * @type {DateGetter}
   */
  _dateGetter;

  /**
   * @param {OAuth2Client} client
   * @param {DateGetter} dateGetter
   */
  constructor(client, dateGetter) {
    this._client = client;
    this._dateGetter = dateGetter;
  }

  /**
   * @returns
   * @param {string} issuerUri
   */
  async fetchDiscoveryAsync(issuerUri) {
    const dDoc = await this._client.discoveryDocument(issuerUri);
    return adaptDiscoveryDocumentFrom(dDoc);
  }

  /**
   * @returns {AuthRequest}
   * @param {AuthRequestInputs} inputs
   */
  request(inputs) {
    return new OAuth2WebClientAuthRequest(inputs, this._client);
  }

  /**
   * Builds an instance of the OAuth2WebClientAuthRequest helper class using
   * the provided AuthRequestInputs configuration object.
   *
   * @returns {AuthRequest}
   * @param {AuthRequestInputs} inputs
   */
  async loadAsync(inputs) {
    return this.request(inputs);
  }

  /**
   * @returns {Promise<TokenResponse>}
   * @param {TokenRequestInputs} inputs
   * @param {AugmentedFetchDiscoveryDocument} discoveryDocument
   */
  async exchangeCodeAsync(inputs, discoveryDocument) {
    try {
      let { clientId, clientSecret, redirectUri, code, extraParams } = inputs;
      extraParams = extraParams || {};
      const dDoc = adaptDiscoveryDocumentTo(discoveryDocument);
      const request = makeRequest(this._client, {
        clientId,
        clientSecret,
        redirectUri,
      }).withDiscoveryDocument(dDoc);
      /*console.log(
        `code = ${code}, verifier = ${
          extraParams.code_verifier || extraParams.codeVerifier
        }`
      );*/
      const data = await request.tokenRequest(
        code,
        extraParams.code_verifier || extraParams.codeVerifier
      );
      return makeToken(data, request);
    } catch (e) {
      throw adaptResponse(e);
    }
  }

  /**
   * @param {TokenResponse} tokenResponse
   * @param {AugmentedFetchDiscoveryDocument} discoveryDocument
   * @returns
   */
  async fetchUserInfoAsync(tokenResponse, discoveryDocument) {
    try {
      const dDoc = adaptDiscoveryDocumentTo(discoveryDocument);
      const request = makeRequest(this._client, {}).withDiscoveryDocument(dDoc);
      return request.requestUserInfo(tokenResponse.accessToken);
    } catch (e) {
      /* istanbul ignore next */
      throw adaptResponse(e);
    }
  }

  /**
   * @returns
   * @param {PasswordGrantData} data
   * @param {OAuth2Config} config
   * @param {AugmentedFetchDiscoveryDocument} discoveryDocument
   */
  async fetchTokenPasswordGrantAsync(data, config, discoveryDocument) {
    try {
      const dDoc = adaptDiscoveryDocumentTo(discoveryDocument);
      const response = await this._client.passwordGrant(data, config, dDoc);
      const request = makeRequest(this._client, config).withDiscoveryDocument(
        dDoc
      );
      return makeToken(response, request);
    } catch (e) {
      /* istanbul ignore next */
      throw adaptResponse(e);
    }
  }

  /**
   * @param {TokenResponse} tokenResponse
   * @param {number} threshold
   * @returns
   */
  isTokenFresh(tokenResponse, threshold) {
    threshold = threshold || 0;
    let d = this._dateGetter();
    const expiresAt =
      tokenResponse.issuedAt + tokenResponse.expiresIn - threshold;
    const currentSeconds = epochSeconds(d);
    return currentSeconds < expiresAt;
  }

  /**
   * @param {TokenResponseData} data
   * @param {OAuth2Config} config
   *
   * @return {OAuth2WebClientToken}
   */
  makeToken(data, config) {
    const request = makeRequest(this._client, config);
    const token = makeToken(
      {
        issued_at: data.issuedAt,
        expires_in: data.expiresIn,
        access_token: data.accessToken,
        refresh_token: data.refreshToken,
      },
      request
    );
    return token;
  }
}
