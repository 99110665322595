import { AttributeMatcher } from "../../model";
import { withDefaultHateoas } from "../hateoas/auxiliary/getDefaultHateoasData";

const n = 120;

/**
 * @type {AttributeMatcher[]}
 */
let matchers = [];

for (let i = 0; i < n; i++) {
  let vd = Math.floor(i / 2);
  matchers.push(
    withDefaultHateoas(
      `validity-discriminator/${vd}/matcher`,
      {
        validityDiscriminator: vd,
        attribute: `attribute${i % 2}`,
        order: i % 2,
        value: i % 2 ? "shit" : "1.2",
        matchType: i % 2 ? "equal" : "greater",
      },
      "order"
    )
  );
}

export default matchers;
