import {
  CartProvider,
  TempCartProvider,
  ActiveCartProvider,
  useCartProvider,
  Context as CartProviderContext,
} from "./CartProvider";
import {
  CartProductProvider,
  TempCartProductProvider,
  useCartProductProvider,
  Context as CartProductProviderContext,
} from "./CartProductProvider";
import {
  CartAndProductsProvider,
  TempCartAndProductsProvider,
  ActiveCartAndProductsProvider,
  DynamicCartAndProductsProvider,
  useCartAndProducts,
} from "./CartAndProductsProvider";
import { CartsCustomerManager, useCarts } from "./CartsCustomerManager";
import useCartTypeSet from "./hooks/useCartTypeSet";
import useCustomerPayer from "./hooks/useCustomerPayer";
import useCartProductSelection from "./hooks/useCartProductSelection";
import useCachedCartProductSelection from "./hooks/useCachedCartProductSelection";
import CartCustomerEditor from "./CartCustomerEditor";
import useCartDerivedAttributes, {
  isCartPayable,
  isCartEmptyReservation,
  isCartEditable,
} from "./hooks/useCartDerivedAttributes";
import {
  SelectedProductFeaturesProvider,
  useSelectedProductFeatures
} from "./SelectedProductFeaturesProvider";

const contexts = {
  CartProviderContext,
  CartProductProviderContext,
};

const components = {
  CartProvider,
  CartProductProvider,
  CartAndProductsProvider,
  ActiveCartAndProductsProvider,
  DynamicCartAndProductsProvider,
  CartCustomerEditor,
  TempCartProvider,
  TempCartProductProvider,
  TempCartAndProductsProvider,
  CartsCustomerManager,
  ActiveCartProvider,
  SelectedProductFeaturesProvider
};

const hooks = {
  useCartProvider,
  useCartProductProvider,
  useCartAndProducts,
  useCartProductSelection,
  useCustomerPayer,
  useCartDerivedAttributes,
  useCachedCartProductSelection,
  useCarts,
  useCartTypeSet,
  useSelectedProductFeatures
};

const functions = {
  isCartPayable,
  isCartEmptyReservation,
  isCartEditable,
};

export { components, hooks, contexts, functions };
