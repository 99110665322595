import React, { memo } from "react";
import ListCollectionRenderer from "../ListCollectionRenderer";
import GridCollectionRenderer from "../GridCollectionRenderer";
import { useVariantProvider } from "../../_Auxiliary/VariantProvider/context";
import {CollectionRendererProps} from "@italwebcom/react-entity-manager";
import {Product} from "../../../../../../model";

/**
 * @param {CollectionRendererProps<Product>} props 
 * @returns 
 */
function VariableCollectionRenderer(props) {
    const {variant} = useVariantProvider();
    if(variant === "list") {
        return <ListCollectionRenderer {...props} />;
    } else {
        return <GridCollectionRenderer {...props} />;
    }
}

export default memo(VariableCollectionRenderer);