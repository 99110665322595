import React, { Fragment, memo, useCallback, useState } from "react";
import {makeStyles} from "@material-ui/core"
import Box  from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import CartCustomerPayer from "../../CartCustomerPayer";

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    display: "flex",
  },
  paper: {
    padding: theme.spacing(2),
  },
}));

function PayerButton({ onPaid }) {
  const { contentWrapper, paper } = useStyles();
  const [active, setActive] = useState(false);
  const onSetActive = useCallback(() => setActive(true), [setActive]);
  const onClose = useCallback(() => setActive(false), [setActive]);
  const onPaidCallback = useCallback(() => {
    setActive(false);
    onPaid && onPaid();
  }, [setActive]);
  return (
    <Fragment>
      <Button
        fullWidth
        variant="outlined"
        color="primary"
        onClick={onSetActive}
        size="small"
      >
        Paga
      </Button>
      <Modal open={active} onClose={onClose}>
        <Box className={contentWrapper}>
          <Paper className={paper}>
            <CartCustomerPayer
              active={active}
              onPaid={onPaidCallback}
              timeout={2500}
              onAbort={onClose}
            />
          </Paper>
        </Box>
      </Modal>
    </Fragment>
  );
}

export default memo(PayerButton);
