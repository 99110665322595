import React, { memo } from "react";
import useStyles from "../styles";
import MessageInputRenderer from "../MessageInput";
import { Conversations } from "@italwebcom/anema-react-components";
import ConversationErrorPrinter from "../ConversationErrorPrinter";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import MessageRenderer from "../MessageRenderer";
import CustomFormButtonProvider from "../../../../_auxiliary/CustomFormButtonProvider";
import MessageSubmitButton from "../MessageSubmitButton";
import useRuntimeContext from "../../../../../contexts/Runtime/useRuntimeContext";
import MissingElementRenderer from "../../../../MissingElementRenderer";
import Message from "@material-ui/icons/Message";

const { MessageInput } = Conversations.Components;
const { useConversationAndMessages } = Conversations.hooks;

function makePlaceholders(n) {
  let out = [];
  for (let i = 0; i < n; i++) {
    out.push({
      id: 1,
      content: "placeholder",
      author: "placeholder",
      date: "2022-11-11",
    });
  }
  return out;
}

function renderForm({ readOnly, className, style }) {
  if (!readOnly) {
    return (
      <Box flex={1} className={className} style={style}>
        <MessageInput Component={MessageInputRenderer} buttonLabel="Invia" />
      </Box>
    );
  }
  return null;
}

function renderMessages({
  messages,
  loading,
  windowStyle,
  placeholder: inPlaceholder,
}) {
  const hasEls = messages && messages.length;
  const placeholder = inPlaceholder || (loading && !hasEls);
  if (placeholder || hasEls) {
    let els;
    if (placeholder) {
      els = makePlaceholders(5);
    } else {
      els = messages;
    }
    return (
      <Box className={windowStyle} flex={3}>
        {els.map((m) => (
          <MessageRenderer message={m} placeholder={placeholder} />
        ))}
      </Box>
    );
  } else {
    return (
      <Box paddingBottom="2em">
        <MissingElementRenderer
          Icon={Message}
          title="Nessun messaggio presente."
          content="Non hai ancora ricevuto o inviato messaggi."
        />
      </Box>
    );
  }
}

function renderInnerContent({
  conversationErrored,
  loading,
  readOnly,
  messages,
  placeholder,
  smallScreen,
}) {
  const { wrapperStyle, windowStyle, formWrapper } = useStyles();
  return (
    <Box className={wrapperStyle} display="flex" flexDirection="column">
      {!conversationErrored &&
        renderMessages({ messages, loading, windowStyle, placeholder })}
      {!conversationErrored &&
        renderForm({
          readOnly,
          className: formWrapper,
          style: smallScreen ? { bottom: "66px" } : {},
        })}
    </Box>
  );
}

function ConversationRenderer({ readOnly, placeholder }) {
  const {
    messages,
    messagesLoading,
    conversationLoading,
    conversationErrored,
  } = useConversationAndMessages();
  console.log(`conversationErrored = ${conversationErrored}`);
  const { smallScreen } = useRuntimeContext();
  const loading = messagesLoading || conversationLoading;
  return (
    <CustomFormButtonProvider Button={MessageSubmitButton}>
      <ConversationErrorPrinter />
      {renderInnerContent({
        conversationErrored,
        messages,
        readOnly,
        loading,
        placeholder,
        smallScreen,
      })}
    </CustomFormButtonProvider>
  );
}

export default memo(ConversationRenderer);
