import React, { memo } from "react";
import FullPage from "../FullPage";
import { PanelProps } from "../defines";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core"
import HomepageButtonGroup from "../../HomepageButtonGroup";

const s = makeStyles((theme) => ({
  borderRadius: {
    borderRadius: theme.spacing(1),
  },
  innerBorderRadius: {
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
  },
  buttonGroupWrapper: {
    padding: theme.spacing(2)
  },
}));

/**
 * @returns
 * @param {PanelProps} param0
 */
function Panel({
  onReservation,
  onUserArea,
  details,
  image,
  smallScreen,
  description,
  buttonGroupDisabled,
  logo,
  noDelivery
}) {
  const { borderRadius, innerBorderRadius, buttonGroupWrapper } = s();
  return (
    <Paper className={borderRadius}>
      <FullPage
        smallScreen
        onReservation={onReservation}
        onUserArea={onUserArea}
        details={details}
        image={image}
        buttonsVariant="outlined"
        /*noButtons={!smallScreen}*/
        sm={6}
        newsFirst={smallScreen}
        description={description}
        className={innerBorderRadius}
        logo={logo}
        noDelivery={noDelivery}
        buttonContainerMaxWidth="md"
      />
      {/*!smallScreen && (
        <Box className={buttonGroupWrapper}>
          <HomepageButtonGroup
            variant="hulk"
            onUserArea={onUserArea}
            onReservation={onReservation}
            disabled={buttonGroupDisabled}
            smallScreen={smallScreen}
            noDelivery={noDelivery}
          />
        </Box>
      )*/}
    </Paper>
  );
}

export default memo(Panel);
