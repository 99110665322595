import { Image } from "../../model";
import wrappers from "../hateoas";
import imageUrls from "../auxiliary/imageData";
import settings from "../settings";

const n = settings.nImages;

/**
 * @type {Image[]}
 */
let images = [];

for (let i = 0; i < n; i++) {
  images.push(
    wrappers.image({
      id: i,
      name: `image${i}`,
      source: imageUrls[i % imageUrls.length]
    })
  );
}

export default images;
