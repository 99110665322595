import React, {
  forwardRef,
  memo,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { Cart } from "../../../../../__domain/model";
import useWrapper from "../../../../../hooks/useWrapper";
import Context from "../context";

/**
 * @typedef {{fetch: ({cart: Cart, deliveryZone: number}) => void}} DeliveryZoneCostProviderRef
 * @param {{ref: DeliveryZoneCostProviderRef}} param0
 * @returns
 */
function DeliveryZoneCostProvider({ children }, ref) {
  const cartWrapper = useWrapper("cart");
  const [loading, setLoading] = useState(false);
  const [cost, setCost] = useState(null);
  const { onFetch, reset } = useMemo(
    () => ({
      onFetch: (args) => {
        setLoading(true);
        const { cart, deliveryZone } = args;
        cartWrapper
          .wrap(cart)
          .execute("deliveryZoneCost", {
            deliveryZone,
            delivery_zone: deliveryZone,
          })
          .json()
          .then(({ data }) => {
            console.log(data);
            setCost(data.cost);
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
          });
      },
      reset: () => setCost(0),
    }),
    [cartWrapper, setLoading, setCost]
  );
  useImperativeHandle(
    ref,
    () => ({
      fetch: onFetch,
      reset,
    }),
    [onFetch]
  );
  return (
    <Context.Provider value={{ loading, cost }}>{children}</Context.Provider>
  );
}

export default memo(forwardRef(DeliveryZoneCostProvider));
