import { useMemo } from "react";

export function makeInitialCredentials(defaultRole) {
  return {
    realm_access: {
      roles: [defaultRole],
    },
  };
}

export default function useInitialCredentials(defaultRole) {
  return useMemo(() => makeInitialCredentials(defaultRole), [defaultRole]);
}
