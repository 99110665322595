import React, { useCallback } from "react";
import { memo } from "react";
import { Grid, Button } from "@material-ui/core";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import useHandlerAndStopPropagation from "../../hooks/useHandlerAndStopPropagation";

const CollectionElementButtonCouple = memo(
  ({ onSelect, onAction, actionButtonRef }) => {
    const onActualAction = useHandlerAndStopPropagation(onAction);
    const onActualSelect = useHandlerAndStopPropagation(onSelect);
    return (
      <Grid container spacing={1}>
        {onSelect && <Grid item xs>
          <Button
            fullWidth
            variant="text"
            color="primary"
            onClick={onActualSelect}
          >
            Visualizza
          </Button>
        </Grid>}
        {onAction && (
          <Grid item xs>
            <Button
              fullWidth
              ref={actionButtonRef}
              variant="text"
              color="secondary"
              endIcon={<KeyboardArrowDown />}
              onClick={onActualAction}
            >
              Azioni
            </Button>
          </Grid>
        )}
      </Grid>
    );
  }
);

export default CollectionElementButtonCouple;
