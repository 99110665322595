import { useCallback, useState } from "react";
import useAuthContext from "../hooks/useAuthContext";
import { CredentialsSetter } from "@italwebcom/react-auth-provider";
import useStorage from "../../misc/StorageProvider/hooks/useStorage";
import useUserWrapper from "../../../hooks/wrappers/useUserWrapper";
import { headerManager } from "@italwebcom/augmented-fetch";
import { setStorageToken, getVals } from "../../../misc/setStorageToken";

/**
 * Returns a memoized callback which:
 *
 * - requests a token from the TokenManager (prompting user authorization if needed)
 * - sets the token in storage & sets the user credentials for the AuthProvider
 *
 * !Requires an AuthicationProvider ancestor!
 * !Requires a StorageProvider ancestor!
 *
 * @typedef {{
 *  setCredentials: CredentialsSetter,
 *  onError: function,
 *  data: any,
 *  onBeforeSetCredentials?: (token: string) => Promise<any>
 * }} UseSetCredentialsArgs
 * @param {UseSetCredentialsArgs} param0
 * @returns
 */
export default function useSetCredentials({
  setCredentials,
  onError,
  onBeforeSetCredentials,
  onAfterSetCredentials
}) {
  const { manager } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const { onSet } = useStorage();
  const onSetCredentials = useCallback((data) => {
    (async (d) => {
      try {
        setLoading(true);
        let out = await manager.getTokenAndUserInfo(d);
        const { userInfo, token } = out;
        if (onBeforeSetCredentials) {
          await onBeforeSetCredentials(token);
        }
        let vals = getVals(token);
        onSet("token", vals);
        if (onAfterSetCredentials) {
          await onAfterSetCredentials();
        }
        setCredentials(userInfo);
      } catch (err) {
        onError && onError(err);
      } finally {
        setLoading(false);
      }
    })(data);
  }, [
    setCredentials,
    manager,
    onError,
    setLoading,
    onSet,
    onBeforeSetCredentials,
  ]);
  return { onSetCredentials, loading };
}
