import React, { memo } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import {
  Avatar as AvatarEditor,
  TextInput as TextInputComponent,
} from "../Inputs";
import { Customers } from "@italwebcom/anema-react-components";

const { Editor: CustomerEditor } = Customers.Components;

function InputWithLabel({ label, children, htmlFor }) {
  return (
    <FormGroup>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={4}>
          <FormLabel htmlFor={htmlFor}>{label}</FormLabel>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>{children}</FormControl>
        </Grid>
      </Grid>
    </FormGroup>
  );
}

function TextInputWrapper({ label, id, children }) {
  return (
    <InputWithLabel label={label} htmlFor={id}>
      {children}
    </InputWithLabel>
  );
}

/**
 * @returns
 * @param {{customer: Customer, onSubmit: (customer: Customer) => any}} param0
 */
function CustomerEditForm({ customer, onSubmit, loading }) {
  return (
    <CustomerEditor.Provider
      customer={customer}
      onSave={onSubmit}
      loading={loading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputWithLabel label="Avatar">
            <CustomerEditor.Inputs.Avatar
              Component={AvatarEditor}
            />
          </InputWithLabel>
        </Grid>
        <Grid item xs={12}>
          <TextInputWrapper label="Email" id="email">
            <CustomerEditor.Inputs.Email
              Component={TextInputComponent}
            />
          </TextInputWrapper>
        </Grid>
        <Grid item xs={12}>
          <TextInputWrapper label="Nome e cognome" id="full_name">
            <CustomerEditor.Inputs.Fullname
              Component={TextInputComponent}
            />
          </TextInputWrapper>
        </Grid>
        <Grid item xs={12}>
          <TextInputWrapper label="Numero di telefono" id="phone">
            <CustomerEditor.Inputs.Phone
              Component={TextInputComponent}
            />
          </TextInputWrapper>
        </Grid>
        <Grid item xs={12}>
          <TextInputWrapper label="Indirizzo" id="address">
            <CustomerEditor.Inputs.Address
              Component={TextInputComponent}
            />
          </TextInputWrapper>
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={4}></Grid>
          <Grid item xs>
            <CustomerEditor.Submit
              label="Conferma"
            />
          </Grid>
        </Grid>
      </Grid>
    </CustomerEditor.Provider>
  );
}

export default memo(CustomerEditForm);