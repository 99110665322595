import React from "react";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core"
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  alertButton: {
    transitionProperty: "box-shadow",
    transitionDuration: "750ms",
    cursor: "pointer",
    "&:not(:hover)": {
      boxShadow: "0px",
    },
    "&:hover": {
      boxShadow: "1px 2px 3px 2px rgb(222, 222, 222)",
    },
  },
}));

export default function CartNotEditableMessage({ onClick }) {
  const { alertButton } = useStyles();
  return (
    <Box
      marginBottom="1em"
      role="button"
      onClick={onClick}
      className={alertButton}
    >
      <Alert severity="warning" variant="filled">
        Hai una prenotazione rimasta in sospeso. Clicca o premi su questo avviso
        per visualizzare i dettagli e confermarla.
      </Alert>
    </Box>
  );
}
