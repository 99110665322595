import React, { memo } from "react";
import { Misc } from "@italwebcom/anema-react-components";
import useRuntimeContext from "../../../contexts/Runtime/useRuntimeContext";
import MainContentPanel from "../../MainContentPanel";
import { Container, Link, makeStyles, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import { useEffect } from "react";

const s = makeStyles((theme) => ({
  butanWrapper: {
    justifyContent: "flex-end",
    display: "flex",
    marginTop: theme.spacing(2),
  },
  descriptionStyle: {
    color: theme.palette.grey["500"],
  },
  altDescriptionStyle: {
    color: theme.palette.grey["600"],
    fontWeight: "bold",
    textAlign: "right",
    marginTop: theme.spacing(2),
  },
  modalContentWrapper: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

const titleStyle = { color: "rgb(111, 111, 111)", fontWeight: "bold" };
const data = {
  title: "Accettazione privacy policy",
  description:
    "Al fine di consentire il corretto funzionamento della webapp e fornire funzionalità aggiuntive (e.g. mantenimento di un carrello aperto tra sessioni successive), memorizziamo alcuni dati nel tuo browser mediante cookies e/o Web Storage API. Per proseguire, è necessario consentirne l'utilizzo premendo il pulsante in basso.",
  privacyPolicyLinkDesc: "La privacy policy integrale è consultabile a ",
};

const { usePrivacyPolicy } = Misc.hooks;

function renderContent({ privacyPolicyLink, accept, noPaper }) {
  const { butanWrapper, descriptionStyle, altDescriptionStyle } = s();
  return (
    <MainContentPanel
      title={data.title}
      noPaper={noPaper}
      headerProps={{ titleStyle }}
    >
      <Typography component="p" variant="body1" className={descriptionStyle}>
        {data.description}
      </Typography>
      <Typography component="p" variant="body2" className={altDescriptionStyle}>
        {data.privacyPolicyLinkDesc}{" "}
        <Link href={privacyPolicyLink} target="_blank">questo link</Link>
      </Typography>
      <Box className={butanWrapper}>
        <Button variant="outlined" color="primary" onClick={accept}>
          Accetta e prosegui
        </Button>
      </Box>
    </MainContentPanel>
  );
}

function renderModal({ children, accepted }) {
  const { modalContentWrapper } = s();
  return (
    <Modal open={!accepted}>
      <Box className={modalContentWrapper}>
        <Container maxWidth="md">{children}</Container>
      </Box>
    </Modal>
  );
}

function renderDrawer({ children, accepted }) {
  return (
    <Drawer anchor="bottom" open={!accepted}>
      {children}
    </Drawer>
  );
}

/**
 * @returns
 * @param {{title: string, description: string, storageAttributeName: string}} param0
 */
function PrivacyPolicyPanel({
  privacyPolicyLink,
  storageAttributeName,
  onStatusChange,
}) {
  const { smallScreen } = useRuntimeContext();
  const { accept, accepted, status } = usePrivacyPolicy({
    storageAttributeName,
  });

  useEffect(() => {
    if (onStatusChange && status) {
      onStatusChange(status);
    }
  }, [onStatusChange, status]);

  let content = renderContent({
    privacyPolicyLink,
    accept,
    noPaper: smallScreen,
  });

  if (smallScreen) {
    return renderDrawer({
      children: content,
      accepted,
    });
  } else {
    return renderModal({
      children: content,
      accepted,
    });
  }
}

export default memo(PrivacyPolicyPanel);
