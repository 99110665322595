import React, { memo } from "react";
import useStyles from "../styles";
import { Box } from "@material-ui/core";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";

const SortArrows = memo(
  /**
   * @typedef {"asc" | "desc"} SortDirection
   * @param {{
   *      attribute: string,
   *      sortDirection: SortDirection,
   *      sortAttribute: string,
   *      onSortChange: (attribute: string, direction: SortDirection) => void
   * }} param0
   * @returns
   */
  ({ attribute, sortDirection, sortAttribute, onSortChange }) => {
    const { arrow, selectedArrow, arrowsWrapper } = useStyles();
    return (
      <Box
        display="flex"
        flexDirection="column"
        fontSize={12}
        role="button"
        className={arrowsWrapper}
        onClick={() => {
          if (sortDirection === "desc" && sortAttribute === attribute) {
            onSortChange(null, "asc");
          } else {
            if (sortDirection === "asc" && sortAttribute === attribute) {
              onSortChange(attribute, "desc");
            } else {
              onSortChange(attribute, "asc");
            }
          }
        }}
      >
        <KeyboardArrowUp
          fontSize="inherit"
          className={
            sortAttribute === attribute && sortDirection === "asc"
              ? selectedArrow
              : arrow
          }
        />
        <KeyboardArrowDown
          fontSize="inherit"
          className={
            sortAttribute === attribute && sortDirection === "desc"
              ? selectedArrow
              : arrow
          }
        />
      </Box>
    );
  }
);

export default SortArrows;
