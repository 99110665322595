import React, {
  createContext,
  memo,
  useContext,
  useEffect,
  useRef,
} from "react";
import { EntityManager, useEntityManagerValues } from "@italwebcom/react-entity-manager";
import { Customer } from "../../../../__domain/model";
import { useDefaultComponents } from "../../../misc/DefaultComponentsProvider";
import useCustomerWrapper from "../../../../hooks/wrappers/useCustomerWrapper";
import useCustomerOutcomeHandling from "../hooks/useCustomerOutcomeHandling";
import useCustomerManagement from "../hooks/useCustomerManagement";
import defaultActions from "../../../../misc/defaultActions";

/**
 * @type {{customer: Customer, onSave: (customer: Customer) => any}}
 */
const cArgs = {
  customer: null,
  onSave: () => null,
  onDelete: () => null,
  onRefresh: () => null,
};

const CustomerProviderContext = createContext(cArgs);

/**
 * @returns
 */
export function useCustomer() {
  const { customer, onSave, loading, onDelete, onRefresh, error } = useContext(
    CustomerProviderContext
  );
  return { customer, onSave, loading, onDelete, onRefresh, error };
}

/**
 * @returns
 */
const LolCollectionRenderer = memo(
  ({ elements, onSave, onDelete, children, onRefreshAll }) => {
    const { loading, error } = useEntityManagerValues();
    if (!(elements instanceof Array)) {
      elements = [elements];
    }
    return (
      <CustomerProviderContext.Provider
        value={{
          customer: elements.length ? elements[0] : null,
          onSave,
          onDelete,
          loading,
          onRefresh: onRefreshAll,
          error,
        }}
      >
        {children}
      </CustomerProviderContext.Provider>
    );
  }
);

/**
 * @param {{
 *    id: number,
 *    username?: string,
 *    asCustomer?: boolean,
 *    noAutoFetch?: boolean
 * }} param0
 * @returns
 */
function CustomerProvider({ id, username, children, asCustomer, noAutoFetch }) {
  const { fetchArgsProcessor } = useDefaultComponents();
  const wrapper = useCustomerWrapper();
  const ref = useRef();

  const { onSuccess: onActualSuccess, onError: onActualError } = useCustomerOutcomeHandling(asCustomer);
  const { onFetch, onDelete, onSave } = useCustomerManagement({
    wrapper,
    id,
    asCustomer,
  });

  useEffect(() => {
    if (ref.current) {
      console.log(`CustomerProvider.<useEffect callback>: refreshing customer (username = ${username})`);
      ref.current.setFilters([{ attribute: "username", value: username }]);
    }
  }, [username, ref]);

  return (
    <EntityManager.Crud
      countGetter={() => 1}
      fetchArgsProcessor={fetchArgsProcessor}
      onFetch={onFetch}
      onDelete={onDelete}
      onSave={onSave}
      actions={defaultActions}
      initialItemsPerPage={1}
      onSuccess={onActualSuccess}
      onError={onActualError}
      noAutoFetch={noAutoFetch}
      ref={ref}
    >
      <EntityManager.Components.CollectionRenderer
        Component={LolCollectionRenderer}
        ComponentProps={{ children }}
        renderOnError
      />
    </EntityManager.Crud>
  );
}

export default memo(CustomerProvider);

export { CustomerProviderContext };
