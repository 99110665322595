import { withDefaultHateoas } from "../auxiliary/getDefaultHateoasData";
import links from "../../links";
import imageUrls from "../../auxiliary/imageData";
import { ImageAttributes, Image} from "../../../model";

/**
 * @param {ImageAttributes} data 
 * @return {Image}
 */
export default function imageHateoasWrapper(data) {
  let w = withDefaultHateoas(links.image, data);
  w.url = imageUrls[w.id % 10];
  return w;
}
