import { useMemo } from "react";
import { Tag } from "../../../../../__domain/model";

/**
 * @param {Tag[]} elements
 * @param {Tag[]} selected
 * @returns
 */
export default function useGroupElements(elements, selected) {
  const selIds = useMemo(
    () => selected && selected.map((e) => e.id),
    [selected]
  );
  return useMemo(
    () =>
      selIds &&
      elements &&
      elements.map((e) => ({ data: e, selected: selIds.indexOf(e.id) !== -1 })),
    [selIds, elements]
  );
}
