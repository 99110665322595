import { ReactNode, CSSProperties } from "react";
import { SortDirection } from "../defines";

const defaultKeyExtractor = (e) => e.id;

const getAttributeByRole = (attributes, role) => {
  const filtered = attributes.filter((a) => (a.type || a.role) === role);
  if (filtered.length) {
    return filtered[0];
  }
  return null;
};

const renderAttribute = (element, attribute) =>
  attribute.renderer ? attribute.renderer(element) : element[attribute.id];

const roles = {
  avatar: "avatar",
  title: "title",
  subtitle: "subtitle",
};

/**
 * @template Entity
 * @typedef {{
 *      id: string,
 *      label: string,
 *      onExecute: (elements: Entity[]) => void,
 *      isApplicable: (in: Entity|Entity[]) => boolean,
 *      batch?: boolean
 * }} CollectionAction
 */

/**
 * @template Entity
 * @typedef {(element: Entity) => number | string} KeyExtractor
 */

/**
 * @typedef {{
 *      itemsPerPage: number,
 *      count: number,
 *      page: number,
 *      onPageChange: (page: number) => void
 * }} CollectionRendererPaginationProps
 */

/**
 * @template Roles
 * @template Entity
 * @typedef {{
 *      id: string,
 *      type: Roles,
 *      label?: string,
 *      renderer?: (element: Entity) => ReactNode,
 *      sortable?: boolean
 * }} CollectionAttributeRenderer<Roles, Entity>
 */

/**
 * @template Roles
 * @template Entity
 * @typedef {Object} CollectionRendererProps<Roles, Entity>
 *
 * @property {Entity[]} elements
 * @property {(query: string) => void} [onSearch]
 * @property {(attribute: string, direction: SortDirection) => void} onSortChange
 * @property {string} sortAttribute
 * @property {SortDirection} sortDirection
 * @property {CollectionAttributeRenderer<Roles, Entity>[]} attributes
 * @property {CollectionRendererPaginationProps} [pagination]
 * @property {KeyExtractor<Entity>} [keyExtractor]
 * @property {(element: Entity) => void} [onSelect]
 * @property {CollectionAction<Entity>[]} [actions]
 * @property {(element: Entity) => CSSProperties} [styleGetter]
 *
 * @property {Object} config
 * @property {boolean} [config.sortable]
 * @property {boolean} [config.selectOnClick]
 * @property {boolean} [config.noWrapper]
 **/

/**
 * @typedef {"title" | "subtitle" | "avatar"} ListCollectionRoles
 * @typedef {"title" | "subtitle" | "avatar"} GridCollectionRoles
 */
export { defaultKeyExtractor, getAttributeByRole, roles, renderAttribute };
