import { useCallback, useMemo } from "react";
import { TagSelectorGroupRendererProps } from "@italwebcom/anema-react-components/Tags";

/**
 * @returns
 * @param {TagSelectorGroupRendererProps} param0
 */
export default function useTagSelectorFunctions({
  elements,
  onAdd,
  onReplace,
}) {
  const tags = useMemo(() => {
    console.log("********************************");
    return elements.map((e) => e.data);
  }, [elements]);
  const selected = useMemo(
    () => elements.filter((e) => e.selected),
    [elements]
  );
  const onActualChange = useCallback(
    (t) => {
      if (t) {
        const match = tags.filter((tag) => tag.id === t);
        if (selected.length) {
          onReplace(selected[0].data, match[0]);
        } else {
          onAdd(match[0]);
        }
      }
    },
    [onAdd, onReplace, selected, tags]
  );
  return {tags, selected, onActualChange};
}
