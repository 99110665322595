import useTempCartCode from "../useTempCartCode";
import useCartWrapper from "../../../../../hooks/wrappers/useCartWrapper";
import useCartProvider from "../../CartProvider/hooks/useCartProvider";
import { Cart } from "../../../../../__domain/model";
import { CartWrapper } from "../../../../../__domain/__mock/wrappers";
import { useCallback } from "react";
import useCustomHeadersRequest from "../../../../../hooks/useCustomHeadersRequest";

/**
 * @returns
 * @param {(cart: Cart, wrapper: CartWrapper) => Promise<Cart>} operator
 */
export default function useTempCartOperation(operator) {
  
  /* gets wrapper and cart from provider */
  const cartWrapper = useCartWrapper();
  const { cart } = useCartProvider();

  /* gets temp code from storage */
  const { onGet: getCode } = useTempCartCode();

  /* delegates to external operator */
  const actualOperator = useCallback(() => {
    return operator(cart, cartWrapper);
  }, [cart, cartWrapper, operator]);

  /* applies operator to appropriate hook */
  return useCustomHeadersRequest({
    headers: getCode,
    operator: actualOperator,
    decoratorName: "tempCart",
  });
}
