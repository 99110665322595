import { HateoasData } from "@italwebcom/crud-operations-wrapper";

/**
 * @typedef { "Category" | "Tag" } TagType
 * @typedef { "exact" | "less" | "greater" } MatchType
 * @typedef { "customer" | "user" } CustomerType
 * @typedef { "in_place" | "delivery" | "takeaway" } CartType
 * @typedef { "OPEN" | "CLOSED" | "PAID" | "CONFIRMED" | "ABORTED" } CartStatus
 * @typedef { "cms" | "app" } CartOrigin
 * @typedef { "direct" | "paypal" } CartPaymentType
 */

/* domain model entities indices & sorters */

/**
 * @typedef { "name" | "id" } ImageIndices
 * @typedef { "name" } ImageSorters
 * 
 * @typedef { "name" | "id" } TagIndices
 * @typedef { "name" } TagSorters
 * 
 * @typedef { "name" | "parent" | "id" } CategoryIndices
 * 
 * @typedef { "name" } ConversationIndices
 * @typedef { "lastMessageDate" } ConversationSorters
 * 
 * @typedef { "author" | "conversation" } MessageIndices
 * @typedef { "date" } MessageSorters
 * 
 * @typedef { "name" } ProductFeatureIndices
 * @typedef { "name" } ProductFeatureSorters
 * 
 * @typedef { "value" } FeatureValueIndices
 * @typedef { "value" } FeatureValueSorters
 * 
 * @typedef { "name" } ValidityDiscriminatorIndices
 * @typedef { "name" } ValidityDiscriminatorSorters
 * 
 * @typedef { "name" | "id" } CostListIndices
 * @typedef { "name" | "priority" } CostListSorters
 * 
 * @typedef { "attribute" } AttributeMatcherIndices
 * @typedef { "attribute" } AttributeMatcherSorters
 * 
 * @typedef { "name" | "id" } DeliveryZoneIndices
 * @typedef { "name" } DeliveryZoneSorters
 * 
 * @typedef { "name" | "id" } DeliveryCostIndices
 * @typedef { "name" | "priority" } DeliveryCostSorters
 * 
 * @typedef { "name" | "code" } ProductIndices
 * @typedef { "name" | "code" } ProductSorters
 * 
 * @typedef { "name" | "code" } CustomerIndices
 * @typedef { "name" } CustomerSorters
 * 
 * @typedef { "title" } NotificationIndices
 * @typedef { "title" } NotificationSorters
 * 
 * @typedef { "date" } CartIndices
 * @typedef { "date" } CartSorters
 * 
 * @typedef { "date" | "name" } NewsItemIndices
 * @typedef { "date" | "name" } NewsItemSorters
 * 
 * @typedef { "date" | "name" } StatIndices
 * @typedef { "date" | "name" } StatSorters
 * 
 * @typedef { "month" } ClosingDayIndices
 * @typedef { "weekday" } OpeningTimeIndices
 */

/* domain model entities attributes */

/**
 * @typedef {{id: number}} BaseEntity
 * @typedef {{name: string, url: string}} ImageAttributes
 * @typedef {{mainImageUrl: string}} ImageEntity
 * @typedef {{name: string, dtype: TagType, filterType?: string}} TagAttributes
 * @typedef {{name: string, parent?: number}} CategoryAttributes
 * 
 * @typedef {{name: string, lastMessageDate: string, id: number}} ConversationAttributes
 * @typedef {{date: string, conversation: number, author: string, id: number, content: string}} MessageAttributes
 * 
 * @typedef {{name: string}} ProductFeatureAttributes
 * @typedef {{value: string, productFeature: number, order: number}} FeatureValueAttributes
 * 
 * @typedef {{name: string, relatedElement: number, id?: number, priority: number}} ValidityDiscriminatorAttributes
 * @typedef {{attribute: string, value: string, matchType: MatchType, validityDiscriminator: number, order?: number}} AttributeMatcherAttributes
 * 
 * @typedef {{name: string, id: number, priority: number}} CostListAttributes
 * @typedef {{name: string, id: number}} DeliveryZoneAttributes
 * @typedef {{name: string, id: number, cost: number, deliveryZone: number, priority: number}} DeliveryCostAttributes
 * @typedef {{id: number, name: string, code: string, stock: number}} ProductAttributes
 * 
 * @typedef {{category: number, product: number}} ProductCategoryAttributes
 * @typedef {{image: number, product: number}} ProductImageAttributes
 * @typedef {{costList: CostList, cost: number, product: Product, active: boolean}} ProductCostAttributes
 * 
 * @typedef {{
 *      id: number, 
 *      username?: string, 
 *      fullName: number, 
 *      phone: string, 
 *      address: string, 
 *      email?: string, 
 *      type: CustomerType, 
 *      avatar?: string, 
 *      notificationToken?: string
 * }} CustomerAttributes
 * @typedef {{id: number, title: string, content: string, date: string, customer: number}} NotificationAttributes
 * @typedef {{
 *      id: number, 
 *      date: string,
 *      time: string,
 *      deliveryZone?: DeliveryZone|number, 
 *      customer: number, 
 *      status: CartStatus, 
 *      address: string,
 *      cost: number, 
 *      guests?: number,
 *      origin: CartOrigin,
 *      notes?: string,
 *      type: CartType
 * }} CartAttributes
 * @typedef {{cart: number, product: number, quantity: number, cost: number}} CartProductAttributes
 * @typedef {{date: string, image: number, name: string, content: string}} NewsItemAttributes
 * @typedef {{name: string, label: string, value: string}} StatAttributes
 * @typedef {{type: string, query?: string}} GlobalSearchResultAttributes
 * 
 * @typedef {{description: string, date: string}} ClosingDayAttributes
 * @typedef {{weekday: number, start: string, end: string}} OpeningTimeAttributes
 * 
 * @typedef {{name: string, description: string, defaultCost: number}} FeatureAttributes
 * @typedef {{product: Product, feature: Feature, cost: number}} ProductFeatureAttributes
 * @typedef {{productFeature: ProductFeature, selectionIndex: number, selected: boolean}} SelectedProductFeatureAttributes
 */

/* domain model entities defs */

/**
 * @typedef {ConversationAttributes & HateoasData} Conversation
 * @typedef {MessageAttributes & HateoasData} Message
 * @typedef {ImageAttributes & HateoasData & BaseEntity} Image
 * @typedef {TagAttributes & BaseEntity & ImageEntity & HateoasData} Tag
 * @typedef {CategoryAttributes & BaseEntity & ImageEntity & HateoasData} Category
 * @typedef {ProductFeatureAttributes & BaseEntity & HateoasData} ProductFeature
 * @typedef {FeatureValueAttributes & HateoasData} FeatureValue
 * @typedef {ValidityDiscriminatorAttributes & HateoasData} ValidityDiscriminator
 * @typedef {AttributeMatcherAttributes & HateoasData} AttributeMatcher
 * @typedef {CostListAttributes & HateoasData} CostList
 * @typedef {DeliveryZoneAttributes & HateoasData} DeliveryZone
 * @typedef {DeliveryCostAttributes & HateoasData} DeliveryCost
 * @typedef {ProductAttributes & HateoasData} Product
 * @typedef {ProductCategoryAttributes & HateoasData} ProductCategory
 * @typedef {ProductImageAttributes & HateoasData} ProductImage
 * @typedef {CustomerAttributes & HateoasData} Customer
 * @typedef {NotificationAttributes & HateoasData} Notification
 * @typedef {CartAttributes & HateoasData} Cart
 * @typedef {NewsItemAttributes & HateoasData} NewsItem
 * @typedef {CartProductAttributes & HateoasData} CartProduct
 * @typedef {ProductCostAttributes & HateoasData} ProductCost
 * @typedef {StatAttributes & HateoasData} Stat
 * @typedef {GlobalSearchResultAttributes & HateoasData} GlobalSearchResult
 * @typedef {ClosingDayAttributes & HateoasData} ClosingDay
 * @typedef {OpeningTimeAttributes & HateoasData} OpeningTime
 * @typedef {FeatureAttributes & HateoasData} Feature
 * @typedef {ProductFeatureAttributes & HateoasData} ProductFeature
 * @typedef {SelectedProductFeatureAttributes & HateoasData} SelectedProductFeature
 */

/**
 * @type {{open: string, closed: string, confirmed: string, aborted: string, paid: string, active: string, archived: string}}
 */
const cartStatus = {
    open: "OPEN",
    closed: "CLOSED",
    confirmed: "CONFIRMED",
    aborted: "ABORTED",
    paid: "PAID",
    active: "ACTIVE",
    archived: "ARCHIVED"
}

const cartOrigins = {
    app: "app",
    cms: "cms"
}

/**
 * @type {{delivery: string, takeaway: string, in_place: string}}
 */
const cartType = {
    delivery: "delivery",
    takeaway: "default",
    in_place: "in_place"
}

export {cartStatus, cartOrigins, cartType};