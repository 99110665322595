import { createContext } from "react";
import { EntityManagerContextArgs } from "../defines";

/**
 * @template Entity
 * @template {string} Indices
 * @template {string} Sorters
 * 
 * @type {EntityManagerContextArgs<Entity, Indices, Sorters>}
 */
const args = {};

export default createContext(args);