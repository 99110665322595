import { memo, useContext, useEffect } from "react";
import SwitchContext from "../Switch/context";


/**
 * @typedef {{role: string, priority: number, children: ReactChildren}} GateProps
 */

/**
 * @param {GateProps} param0
 */
function Gate({ role, priority, children }) {
  const { selectedRole, onAddRole } = useContext(SwitchContext);

  //console.log(`selectedRole = ${JSON.stringify(selectedRole)}`);

  useEffect(() => {
    //console.log(`useEffect: selectedRole = ${JSON.stringify(selectedRole)}, role = ${role}`);
    onAddRole({role, priority});
  }, [
    onAddRole,
    role,
    priority
  ]);

  if (selectedRole && (role === selectedRole.role) && (priority === selectedRole.priority)) {
    /* selectedRole chosen and equal to Gate's role -> render children */
    return children;
  }

  /* if waiting for selectedRole or no conditions apply -> render null */
  return null;
}

export default memo(Gate);
