import { Notification } from "../../model";
import wrappers from "../hateoas";

const n = 20;
const mexPlaceholder = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";

/**
 * @type {Notification[]}
 */
const notifications = [];

for (let i = 0; i < n; i++) {
  notifications.push(
    wrappers.notification({
      id: i,
      customer: Math.floor(i / 2),
      title: `Notification ${i}`,
      content: mexPlaceholder,
      date: `2022-03-${(17 + i) % 30}`
    })
  );
}

export default notifications;
