import React, {
  useCallback,
  useState,
  useRef,
  Fragment,
  useEffect,
  memo,
} from "react";
import { useCollectionState } from "@italwebcom/custom-react-hooks";
import SimpleSearchBar from "../SimpleSearchBar/index.jsx";
import {
  CircularProgress,
  Grid,
  Chip,
  IconButton,
  MenuItem,
  ListItemText,
  Divider,
  Box,
  Card,
  List,
  makeStyles,
  CardHeader,
  CardContent,
  Collapse,
  CardActionArea,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";

const filterMatcher = (f1, f2) => filterId(f1) === filterId(f2);
const filterId = (f) => f.attribute + f.value;
const contains = (els, el) =>
  els.map((f) => filterMatcher(f, el)).reduce((a, b) => a || b, false);

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    zIndex: 2,
  },
  card: {
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    "& .MuiCardHeader-action": {
      marginTop: 0,
      marginLeft: 0,
    },
    "& .MuiCardHeader-root": {
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    },
    "& .MuiCardContent-root": {
      padding: theme.spacing(1)
    }
  },
  panelWrapper: {
    position: "absolute",
    width: "100%",
  },
}));

const Loader = () => (
  <Box display="flex" justifyContent="center">
    <CircularProgress size={20} color="primary" />
  </Box>
);

/**
 * @param {{filters: Filter[]}} param0
 */
const FiltersRenderer = ({ filters, onRemove }) => (
  <Grid container spacing={1}>
    {filters.map((filter) => (
      <Grid item key={filterId(filter)}>
        <Chip
          label={`${filter.attributeLabel || filter.attribute}: ${
            filter.label || filter.value
          }`}
          variant="outlined"
          onDelete={() => onRemove(filter)}
        />
      </Grid>
    ))}
  </Grid>
);

const FilterMatchesMenu = ({
  filters,
  onSelect,
  onClose,
  styles,
  loading,
  selected,
  titleTypographyProps,
}) => {
  const { panelWrapper } = useStyles();
  return (
    <Box className={panelWrapper}>
      <Collapse in={loading || filters} collapsedSize={0}>
        <Card variant="outlined" className={styles.card}>
          <CardHeader
            title="Selezione filtro"
            action={
              <IconButton size="small" onClick={onClose}>
                <Close />
              </IconButton>
            }
            titleTypographyProps={
              titleTypographyProps || {
                variant: "h5",
                component: "header",
              }
            }
          />
          <Divider />
          <CardContent>
            {loading ? (
              <Loader />
            ) : (
              <List disablePadding>
                {filters &&
                  filters
                    .filter((f) => !contains(selected, f))
                    .map((f) => (
                      <MenuItem key={filterId(f)} onClick={() => onSelect(f)}>
                        <ListItemText
                          primary={f.attributeLabel || f.attribute}
                          secondary={f.label || f.value}
                        />
                      </MenuItem>
                    ))}
              </List>
            )}
          </CardContent>
        </Card>
      </Collapse>
    </Box>
  );
};

/**
 * @typedef {{
 *      attribute: string,
 *      value: string,
 *      attributeLabel?: string,
 *      label?: string
 * }} Filter
 *
 * @typedef {{
 *      onFiltersGet: (query: string) => Promise<Filter[]>,
 *      onFiltersChange: (filters: Filter[]) => void,
 *      defaultFilters?: Filter[]
 * }} FiltersBarProps
 *
 */

/**
 * @param {FiltersBarProps} param0
 */
function FiltersBar({
  onFiltersGet,
  onFiltersChange,
  defaultFilters,
  titleTypographyProps,
}) {
  const styles = useStyles();
  const [query, setQuery] = useState(null);
  const [matches, setMatches] = useState(null);
  const obj = useCollectionState({
    initial: defaultFilters,
    config: {
      matcher: filterMatcher,
    },
  });
  const {
    collection: filters,
    onAdd: onFilterAdd,
    onRemove: onFilterRemove,
  } = obj;
  useEffect(() => onFiltersChange(filters), [filters]);
  useEffect(() => {
    setMatches(null);
    onFiltersGet(query).then(setMatches);
  }, [query]);
  return (
    <Box className={styles.wrapper}>
      <SimpleSearchBar
        icon={<FiltersRenderer filters={filters} onRemove={onFilterRemove} />}
        onChange={setQuery}
      />
      <FilterMatchesMenu
        titleTypographyProps={titleTypographyProps}
        filters={query && matches}
        loading={query && !matches}
        onClose={() => setQuery(null)}
        onSelect={onFilterAdd}
        styles={styles}
        selected={filters}
      />
    </Box>
  );
}
export default memo(FiltersBar);
