import React, { forwardRef, memo } from "react";
import CrudEntityManager from "../Crud";

function ReadOnlyEntityManager(props, ref) {
  return (
    <CrudEntityManager
      {...props}
      ref={ref}
      onSave={null}
      onDelete={null}
      actions={null}
    />
  );
}

export default memo(forwardRef(ReadOnlyEntityManager));
