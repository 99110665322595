import {useEffect, useRef, useState} from "react";

export default function makeTimedSwitchHook({onSetTimeout, onClearTimeout, onSwitch, timeout}) {
    /* istanbul ignore next */
    onSwitch = onSwitch || (s => !s);
    return function(active) {
        const timeoutId = useRef(null);
        const [value, setValue] = useState(false);
        useEffect(() => {
            //console.log(`useTimedValue.<useEffect callback>: active = ${active}`)
            if(active && !timeoutId.current) {
                timeoutId.current = onSetTimeout(() => setValue(onSwitch), timeout)
            } else {
                onClearTimeout(timeoutId.current);
            }
            return () => timeoutId.current && onClearTimeout(timeoutId.current)
        }, [active]);
        return value;
    }
}