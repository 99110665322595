import React, { Fragment, memo, ReactNode, useEffect, useMemo } from "react";
import undef from "../../../../../../misc/undef";
import { useCustomer } from "../../../../Customers/Provider";
import { Main, Active, Temp } from "../index";

/**
 * Provides either:
 * 1) a specific cart if id prop is provided
 * 2) the active cart for the current user if a customer from CustomerProvider is present AND id prop isn't provided
 * 3) the temp cart if none of the above apply
 *
 * !REQUIRES: a CustomerProvider ancestor.
 *
 * @param {{id?: number, children?: ReactNode[]}} param0
 * @returns
 */
function DynamicCartProvider({ id, children, redirectOnSave }) {
  const { customer } = useCustomer();

  useEffect(() => {
    console.log(
      `DynamicCartAndProductsProvider.<useEffect> callback: rendering DynamicCartProvider for id = ${
        id || "none"
      }, customer = ${(customer && customer.id) || "none"}`
    );
  }, [customer, id]);

  if (!undef(id)) {
    return (
      <Main cart={id} asCustomer>
        {children}
      </Main>
    );
  } else {
    if (customer && !undef(customer.id)) {
      return <Active redirectOnSave={redirectOnSave}>{children}</Active>;
    } else {
      return <Temp>{children}</Temp>;
    }
  }
}

export default memo(DynamicCartProvider);
