import Homepage from "./Homepage";
import LoadingPage from "./LoadingPage";
import CartOverview from "./CartOverview";
import ProductSelection from "./ProductSelection";
import UserAreaChat from "./UserAreaChat";
import UserAreaDashboard from "./UserAreaDashboard";
import UserAreaEdit from "./UserAreaDataEdit";
import UserAreaReservations from "./UserAreaReservations";

const userTemplates = {
  dashboard: UserAreaDashboard,
  edit: UserAreaEdit,
  chat: UserAreaChat,
  reservations: UserAreaReservations,
};

const templates = {
  user: userTemplates,
  home: Homepage,
  loading: LoadingPage,
  cartOverview: CartOverview,
  productSelection: ProductSelection,
};

export default templates;
