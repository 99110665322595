import { createTheme, responsiveFontSizes } from "@material-ui/core";

const colors = {
  primary: {
    light: "rgb(32, 132, 232)",
    main: "#143279",
    dark: "#143279",
  },
  secondary: {
    main: "rgb(255, 0, 0)",
    light: "rgb(255, 0, 0)",
  },
  success: {
    main: "#008000",
    light: "#008000",
  },
  grey: {
    primary: "#424242",
    secondary: "#757575",
  },
};

const anemaTheme = responsiveFontSizes(
  createTheme({
    typography: {},
    overrides: {
      MuiListItemText: {
        primary: {
          color: colors.grey.primary,
          fontWeight: "bold",
        },
        secondary: {
          color: colors.grey.secondary,
        },
      },
      MuiBackdrop: {
        root: {
          backgroundColor: "rgb(0, 0, 0, 0.5)",
        },
      },
      MuiTypography: {
        h5: {
          color: colors.grey.primary,
          fontWeight: "bold",
        },
        h6: {
          color: colors.grey.primary,
          fontWeight: "bold",
        },
      },
      MuiNativeSelect: {
        root: {
          marginTop: "0.25em",
        },
        select: {
          padding: "0.75em",
          border: "1px solid rgb(222, 222, 222)",
        },
      },
      MuiSelect: {
        root: {
          padding: "0.75em"
        }
      }
    },
    palette: {
      ...colors,
    },
  })
);

export { colors };
export default anemaTheme;
