import React, { memo } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import NotificationRenderer from "../../NotificationRenderer";
import { Notifications } from "@italwebcom/anema-react-components";
import makePlaceholders from "./placeholders";
import { NonWrappedDefaultErrorPrinter } from "../../../../_auxiliary/DefaultErrorPrinter";
import MissingElementRenderer from "../../../../MissingElementRenderer";
import NotificationIcon from "@material-ui/icons/Notifications";

function renderElements(notifications, placeholder) {
  return (
    <Grid container spacing={2}>
      {notifications.map((e, index) => (
        <Grid item xs={12} key={e.id}>
          <NotificationRenderer
            element={e}
            index={index}
            placeholder={placeholder}
          />
        </Grid>
      ))}
    </Grid>
  );
}

function renderNoElementsMessage() {
  return (
    <MissingElementRenderer
      Icon={NotificationIcon}
      title="Nessuna notifica presente."
      content="Non hai ancora ricevuto notifiche."
    />
  );
}

/**
 * @returns
 * @param {CollectionRendererProps<Notification>} param0
 */
function CollectionRenderer({ placeholder: inPlaceholder }) {
  const { notifications, loading, error, onRefreshAll } =
    Notifications.hooks.useNotifications();
  const hasElements = notifications && notifications.length;
  const isPlaceholder = inPlaceholder || (loading && !hasElements);
  if (error) {
    return <NonWrappedDefaultErrorPrinter onRefreshAll={onRefreshAll} />;
  }
  if (isPlaceholder || hasElements) {
    let els;
    if (isPlaceholder) {
      els = makePlaceholders(5);
    } else {
      els = notifications;
    }
    return renderElements(els, isPlaceholder);
  } else {
    return renderNoElementsMessage();
  }
}

export default memo(CollectionRenderer);
