import React, { memo } from "react";
import useStyles from "../styles";
import { ListProps } from "../defines";
import Context from "../context";

/**
 * @param {ListProps} param0
 * @returns
 */
function List({ children, elementHeight, rows }) {
  const { listRoot } = useStyles();
  return (
    <Context.Provider value={{ elementHeight, rows }}>
      <ul className={listRoot}>{children}</ul>
    </Context.Provider>
  );
}

export default memo(List);
