import React, { memo, ReactNode, useCallback } from "react";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import useOnElementSelect from "../../hooks/useOnElementSelect";
import { Button, makeStyles } from "@material-ui/core";

const s = makeStyles((theme) => ({
  btn: {
    marginLeft: theme.spacing(2),
    height: theme.spacing(4),
  },
}));

const GroupButton = memo(({ element, onSelect, color }) => {
  const { btn: btnStyle } = s();
  const onActualClick = useCallback(
    () => onSelect(element),
    [onSelect, element]
  );

  let btn;

  if (element.variant === "button") {
    btn = (
      <Button
        style={{ borderColor: color, color }}
        variant="outlined"
        endIcon={element.icon}
        className={btnStyle}
        onClick={onActualClick}
      >
        {element.label}
      </Button>
    );
  } else {
    btn = (
      <IconButton key={element.id} onClick={onActualClick}>
        <Box fontSize={24} color={color} height="24px">
          {element.icon}
        </Box>
      </IconButton>
    );
  }

  if (element.badge) {
    btn = (
      <Badge color="primary" badgeContent={element.badge.toString()}>
        {btn}
      </Badge>
    );
  }

  if (element.tooltip) {
    return <Tooltip title={element.tooltip}>{btn}</Tooltip>;
  } else {
    return btn;
  }
});

/**
 * @typedef {{
 *      id: string,
 *      tooltip: string,
 *      icon: ReactNode,
 *      badge?: string
 * }} ButtonGroupDataElement
 *
 * @param {{
 *      data: ButtonGroupDataElement[],
 *      color: string,
 *      cart: number
 * }} param0
 */
function AppBarButtonGroup({ data, color, cart, user }) {
  const onActualSelect = useOnElementSelect({ cart, user });
  return (
    <ButtonGroup>
      {data.map((e) => (
        <GroupButton
          onSelect={onActualSelect}
          color={color}
          element={e}
          key={e.id}
        />
      ))}
    </ButtonGroup>
  );
}

export default memo(AppBarButtonGroup);
