import { Email, LocationOn, Phone } from "@material-ui/icons";
import React from "react";

function titleGetter(u) {
  /*if (u) {
    return `Bentornato da Anima e Core, ${u}!`;
  } else {
    return "Benvenuto da Anima e Core!";
  }*/
  return "Anima e Core";
}

const panelDetails = [
  {
    content: "Strada Statale 69 di Val D'Arno, 52100 Arezzo",
    icon: <LocationOn />,
  },
  {
    content: "0575 383966",
    icon: <Phone />,
    href: "tel:+390575389966",
  },
  {
    content: "email@animacore.it",
    icon: <Email />,
    href: "mailto:email@animacore.it",
  },
];

const panelDescription =
  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.";

export {
  titleGetter,
  panelDetails,
  panelDescription,
};
