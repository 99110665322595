import React, { memo, ComponentType } from "react";
import useStyles from "../styles";
import { CollectionAttributeRenderer } from "../../../defines";
import {
  TableHead,
  TableRow,
  TableCell,
  Grid,
  Typography,
} from "@material-ui/core";

/**
 * @typedef {{attribute: string}} SorterProps
 */
const TableHeaderRenderer = memo(
  /**
   * @template Entity
   * @param {{
   *      attributes: CollectionAttributeRenderer<any, Entity>[],
   *      Sorter: ComponentType<SorterProps>
   * }} param0
   */
  ({ attributes, actions, Sorter }) => {
    const { head } = useStyles();
    return (
      <TableHead>
        <TableRow>
          {attributes.map(({ label, id, sortable }) => (
            <TableCell key={id}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    variant="caption"
                    component="header"
                    className={head}
                  >
                    {label || id}
                  </Typography>
                </Grid>
                {sortable && (
                  <Grid item>
                    <Sorter attribute={id} />
                  </Grid>
                )}
              </Grid>
            </TableCell>
          ))}
          {actions && <TableCell key="actions"></TableCell>}
        </TableRow>
      </TableHead>
    );
  }
);

export default TableHeaderRenderer;
