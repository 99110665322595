import React, { memo } from "react";
import {
  GenericForm,
  InputComponent,
  InputShouldRenderFunc
} from "@italwebcom/react-generic-form";

/**
 * @param {{field: string, InputProps?: any, InputComponent: InputComponent, shouldRender?: InputShouldRenderFunc}} param0
 * @returns
 */
function GenericInput({
  field,
  children,
  InputProps,
  InputComponent,
  shouldRender,
  controlled
}) {
  return (
    <GenericForm.Input
      field={field}
      InputProps={InputProps}
      Component={InputComponent}
      shouldRender={shouldRender}
      controlled={controlled}
    >
      {children}
    </GenericForm.Input>
  );
}

export default memo(GenericInput);