import { notify } from "@italwebcom/observer-utils";
import React, { Fragment, useContext, useEffect } from "react";
import { Auth } from "@italwebcom/react-auth-provider";
import AuthContext from "../context";

function Dummy() {
  const { errorObservable } = useContext(AuthContext);
  useEffect(() => {
    notify(errorObservable).of("authError").with("unauthorized");
  }, [errorObservable]);
  return null;
}

/**
 * Utility component to gate content for a given requiredRole role. If the user
 * doesn't have the required credentials, the component notifies an authError to
 * the nearest Auth.Provider ancestor, thus triggering an authentication with
 * the provided Setter component.
 * 
 * !Requires an Auth.Provider ancestor!
 * 
 * @param {{children: ReactChildren, defaultRole: string, requiredRole: string}} param0
 * @returns
 */
export default function CompositeGate({
  children,
  defaultRole,
  requiredRole,
  Setter,
}) {
  return (
    <Fragment>
      <Auth.Switch>
        <Auth.Gate role={requiredRole} priority={0}>
          {children}
        </Auth.Gate>
        <Auth.Gate role={defaultRole} priority={1}>
          <Dummy />
        </Auth.Gate>
      </Auth.Switch>
      <Auth.Setter requiredAuthError="unauthorized" Component={Setter} />
    </Fragment>
  );
}
