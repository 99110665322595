import Guests from "./Guests";
import Address from "./Address";
import Date from "./Date";
import DeliveryZone from "./DeliveryZone";
import Time from "./Time";
import Type from "./Type";
import Notes from "./Notes";
import PaymentType from "./PaymentType";

const Inputs = {Time, Type, DeliveryZone, Date, Address, Guests, Notes, PaymentType};
export default Inputs;