import { useMemo } from "react";
import { useCredentials } from "@italwebcom/react-auth-provider";

/**
 * @returns
 * @param {string | (username: string) => string} title
 */
export default function useHomepageTitle(title) {
  const creds = useCredentials();
  return useMemo(() => {
    if (typeof title === "function") {
      return title(creds && creds.username);
    } else {
      return title;
    }
  }, [title, creds]);
}
