import { CartProduct } from "../../model";
import carts from "./carts";
import products from "./products";
import wrappers from "../hateoas";

/**
 * @type {CartProduct[]}
 */
const cartProducts = [];

for (let i = 0; i < carts.length-1; i++) {
  for (let v = 0; v < 3; v++) {
    let p = v + (i % 2) * 3;
    cartProducts.push(
      wrappers.cartProduct({
        cart: i,
        product: products[p],
        quantity: v + 1,
        cost: 11 + v * 10,
        editable: (p%2)
      })
    );
  }
}

export default cartProducts;
