import React, { memo, useContext, useMemo } from "react";
import { NewsItems } from "@italwebcom/anema-react-components";
import CustomCarousel from "../../../../CustomCarousel";
import Context from "../context";
import { NonWrappedDefaultErrorPrinter } from "../../../../_auxiliary/DefaultErrorPrinter";

const { useNewsItems } = NewsItems.hooks;

function CollectionRenderer({ vertical }) {
  const { newsItems, loading, error, onRefreshAll } = useNewsItems();
  const {
    carouselDetailContentTypographyProps,
    carouselDetailTitleTypographyProps,
    carouselDotColor,
    carouselDotSize,
  } = useContext(Context);
  const actualItems = useMemo(
    () =>
      newsItems.map((nItem) => ({
        title: nItem.name || nItem.title,
        content: nItem.content,
        image:
          (nItem.image && nItem.image.url) ||
          nItem.mainImageUrl ||
          nItem.imageUrl,
      })),
    [newsItems]
  );
  if (error) {
    return <NonWrappedDefaultErrorPrinter onRefreshAll={onRefreshAll} />;
  } else {
    return (
      <CustomCarousel
        items={actualItems}
        variant={vertical ? "vertical" : "horizontal"}
        detailTitleTypographyProps={carouselDetailTitleTypographyProps}
        detailContentTypographyProps={carouselDetailContentTypographyProps}
        dotColor={carouselDotColor}
        dotSize={carouselDotSize}
        placeholder={loading && (!newsItems || !newsItems.length)}
      />
    );
  }
}

export default memo(CollectionRenderer);
