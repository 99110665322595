import cartWrapper from "./wrappers/cart";
import cartProductWrapper from "./wrappers/cartProduct";
import categoryWrapper from "./wrappers/category";
import conversationWrapper from "./wrappers/conversation";
import costListWrapper from "./wrappers/costList";
import customerWrapper from "./wrappers/customer";
import deliveryCostWrapper from "./wrappers/deliveryCost";
import deliveryZoneWrapper from "./wrappers/deliveryZone";
import imageWrapper from "./wrappers/image";
import notificationWrapper from "./wrappers/notification";
import productWrapper from "./wrappers/product";
import productCategoryWrapper from "./wrappers/productCategory";
import productCostWrapper from "./wrappers/productCost";
import productFeatureWrapper from "./wrappers/productFeature";
import productImageWrapper from "./wrappers/productImage";
import tagWrapper from "./wrappers/tag";
import validityDiscriminatorWrapper from "./wrappers/validityDiscriminator";

const wrappers = {
    cart: cartWrapper,
    cartProduct: cartProductWrapper,
    category: categoryWrapper,
    conversation: conversationWrapper,
    costList: costListWrapper,
    customer: customerWrapper,
    deliveryCost: deliveryCostWrapper,
    deliveryZone: deliveryZoneWrapper,
    image: imageWrapper,
    notification: notificationWrapper,
    product: productWrapper,
    productCategory: productCategoryWrapper,
    productCost: productCostWrapper,
    productFeature: productFeatureWrapper,
    productImage: productImageWrapper,
    tag: tagWrapper,
    validityDiscriminator: validityDiscriminatorWrapper
};

export default wrappers;