import links from "../../links";
import { CartProduct } from "../../../model";

/**
 * @param {CartProduct} data 
 * @returns 
 */
export default function wrapper(data) {
  let p = data.product;
  let c = data.cart;
  return {
    ...data,
    _links: {
      self: {
        href: links.cart.related.product(c, p)
      },
      fetch: {
        href: links.cart.products(c),
        templated: true
      }
    },
    _templates: {
      delete: {
        method: "DELETE",
        target: links.cart.related.product(c, p),
        properties: []
      },
      update: {
        method: "PUT",
        target: links.cart.related.product(c, p),
        properties: []
      }
    }
  };
}
