import { useEffect, useState, useMemo } from "react";
import useStorage from "../../components/misc/StorageProvider/hooks/useStorage";

const statuses = {
  pending: "pending",
  accepted: "accepted",
  refused: "refused",
};

/**
 * @returns
 * @param {{storageAttributeName: string}} param0
 */
function usePrivacyPolicy({ storageAttributeName }) {
    
  const [status, setStatus] = useState(statuses.pending);
  const { onSet, onGet } = useStorage();

  useEffect(() => {
    /* fetches initial status value & sets the state */
    if (storageAttributeName) {
      const v = onGet(storageAttributeName);
      if (v) {
        setStatus(v);
      }
    }
  }, [onGet, storageAttributeName, setStatus]);

  useEffect(() => {
    /* when status changes & isn't pending, it persists the value to storage */
    if (status && status !== statuses.pending) {
      onSet(storageAttributeName, status);
    }
  }, [status, onSet, storageAttributeName]);

  /* returned callbacks to accept or refuse policy */
  const { accept, refuse } = useMemo(
    () => ({
      accept: () => setStatus(statuses.accepted),
      refuse: () => setStatus(statuses.refused),
    }),
    [onSet, setStatus]
  );

  const accepted = useMemo(() => status === statuses.accepted, [status]);

  return { accept, refuse, status, accepted };
}

export default usePrivacyPolicy;
