import { EntityManager, SearchBarProps } from "@italwebcom/react-entity-manager";
import React, { useEffect } from "react";
import useGlobalSearch from "../hooks/useGlobalSearch";
import useManagerFunctions from "../hooks/useManagerFunctions";
import {useDefaultComponents} from "../../../misc/DefaultComponentsProvider";

/**
 * @param {SearchBarProps} param0
 */
function FakeSearchBar({onSearch}) {
    const {query} = useGlobalSearch();
    useEffect(() => {
        query && onSearch(query);
    }, [query, onSearch]);
    return null;
}

/**
 * @returns
 */
export default function Manager({attributes}) {
  const {onActualSelect, onFetch} = useManagerFunctions();
  const {fetchArgsProcessor, defaultCountGetter, Loader, ListCollectionRenderer} = useDefaultComponents();
  return (
    <EntityManager.ReadOnly
        countGetter={defaultCountGetter}
        fetchArgsProcessor={fetchArgsProcessor}
        initialFilters={[]}
        initialItemsPerPage={10}
        initialSorter={{}}
        onFetch={onFetch}
        onSelect={onActualSelect}
        attributes={attributes}
        searchAttribute="query"
    >
      <EntityManager.Components.Loader Component={Loader} />
      <EntityManager.Components.SearchBar Component={FakeSearchBar} />
      <EntityManager.Components.CollectionRenderer
        Component={ListCollectionRenderer}
      />
    </EntityManager.ReadOnly>
  );
}
