import { createContext } from "react";
import { Conversation, Message } from "../../../../__domain/model";
/**
 * @type {{
 *      conversationLoading: boolean, 
 *      messagesLoading: boolean, 
 *      conversation: Conversation, 
 *      messages: Message[], 
 *      onPostMessage: (message: Message) => Promise<any>
 * }}
 */
const args = {conversationLoading: false, messagesLoading: false, conversation: null, messages: null, onPostMessage: null};
export default createContext(args);