import { Box, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, ReactNode, useState, useRef, useMemo } from "react";
import "./styles.css";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    height: "100%"
  },
}));

/**
 * @typedef {{
 *      duration?: string,
 *      initialTop?: string,
 *      initialOpacity?: number,
 *      active: boolean
 * }} AnimationConfig
 */

/**
 * @typedef {{animation: AnimationConfig, children: ReactNode}} AnimatedContainerProps
 * @param {AnimatedContainerProps} param0
 */
export default function AnimatedContainer({ animation, children }) {
  const { wrapper } = useStyles();
  animation = animation || {};

  const [anim, setAnim] = useState(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      animation.active && setAnim(true);
    }, 10);
    return () => timeoutRef.current && clearTimeout(timeoutRef.current);
  }, [animation]);

  const animStyle = useMemo(
    () =>
      !animation.active
        ? {}
        : {
            opacity: animation.initialOpacity || 0.01,
            animationDuration: animation.duration || "1000ms",
            top: animation.initialTop || "100px",
          },
    [animation]
  );

  return (
    <Box
      className={clsx([anim && "slide-and-fade-animated", wrapper])}
      style={animStyle}
    >
      {children}
    </Box>
  );
}
