import { useMemo } from "react";
import useCartAndProducts from "../../CartAndProductsProvider/hooks/useCartAndProducts";
import useCartDerivedAttributes from "../useCartDerivedAttributes";

/**
 * @returns
 * @param {(type: string) => void} onAfterSet
 */
function useCartTypeSet(onAfterSet) {
  
  const args = useCartAndProducts();
  const { cart, cartLoading, onCartSave } = args;
  const { editable, awaitingPayment } = useCartDerivedAttributes(cart);

  const { onSetDefault, onSetInplace, onSetDelivery, onSet } = useMemo(() => {
    return {
      onSetDefault: () =>
        onCartSave({ ...cart, type: "default", patch: true }).then(() =>
          onAfterSet("default")
        ),
      onSetInplace: () =>
        onCartSave({ ...cart, type: "in_place", patch: true }).then(() =>
          onAfterSet("in_place")
        ),
      onSetDelivery: () =>
        onCartSave({ ...cart, type: "delivery", patch: true }).then(() =>
          onAfterSet("delivery")
        ),
      onSet: (type) =>
        onCartSave({ ...cart, type, patch: true }).then(
          () => onAfterSet && onAfterSet(type)
        ),
    };
  }, [cart, onCartSave]);

  return {
    onSetDefault,
    onSetInplace,
    onSetDelivery,
    loading: cartLoading,
    onSet,
    editable,
    awaitingPayment,
  };
}

export default useCartTypeSet;
