import React, { useMemo } from "react";
import { useCallback } from "react";
import Context from "../context";
import { Storage } from "../defines";
import { useEntityEdit } from "@italwebcom/custom-react-hooks";
import undef from "../../../../misc/undef";

/**
 * @returns
 * @param {{storage: Storage, defaultOptions?: Record<string, any>}} param0
 */
export default function StorageProvider({ children, storage, defaultOptions }) {
  
  const v = useMemo(() => ({}), []);
  const { element, onSet } = useEntityEdit(v);

  /* writes value to local cache and to storage - should probably add option to delay storage write */
  const onActualSet = useCallback(
    (n, v, o) => {
      let opts = { ...defaultOptions, ...(o || {}) };
      storage.set(n, v, opts);
      onSet(n, v);
    },
    [onSet, defaultOptions, storage]
  );

  /* reads from local cache and, if missing, from storage */
  const onGet = useCallback(n => {
    let v = element[n];
    if(undef(v)) {
      v = storage.get(n);
    }
    return v;
  }, [element, storage, onSet]);

  return (
    <Context.Provider value={{ onGet, onSet: onActualSet }}>{children}</Context.Provider>
  );
}
