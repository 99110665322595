import React, { memo, useCallback, useMemo, useState } from "react";
import { Product } from "@italwebcom/anema-react-components/Products";
import { Carts } from "@italwebcom/anema-react-components";
import Typography, { TypographyProps } from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import IconButton from "@material-ui/core/IconButton";
import Picture from "../../../../Picture";
import {
  QuantityChip,
  CostChip,
} from "../../CustomerProductSelectionView/ItemRenderers/_Auxiliary";
import { getProductImage } from "../../../../../functions";
import styles from "./styles";
import clsx from "clsx";
import DeleteForever from "@material-ui/icons/DeleteForever";
import CustomDropdownMenu from "../../../../CustomDropdownMenu";
import AutoLoadingButton from "../../../../AutoLoadingButton";
import SelectedProductFeaturesRenderer from "../../../carts/SelectedProductFeaturesRenderer";
import Footer from "./Footer";

const {
  useCartAndProducts,
  useCartDerivedAttributes,
  useCachedCartProductSelection,
  useCartProductSelection,
} = Carts.hooks;

const { SelectedProductFeaturesProvider } = Carts.Components;
const additionalStyles = {
  img: {
    maxWidth: "calc(min(100%, 300px))",
    borderRadius: "16px",
  },
  figure: { maxWidth: "calc(min(100%, 300px))", margin: "auto" },
  selProdWrapper: {
    maxHeight: "45vh",
    overflow: "auto"
  }
};

function c(q, p) {
  return q * p.cost;
}

function opts(n) {
  let out = [];
  for (let i = 1; i < n; i++) {
    out.push({ label: i.toString(), value: i });
  }
  return out;
}

///////////////

const IngredSelView = memo(({ onBack, cartProduct, onCartRefresh }) => (
  <SelectedProductFeaturesProvider
    cartProduct={cartProduct}
    onRefresh={onCartRefresh}
  >
    <Box style={additionalStyles.selProdWrapper}>
      <SelectedProductFeaturesRenderer onBack={onBack} />
    </Box>
  </SelectedProductFeaturesProvider>
));

////////////////

const AltFooter = memo(({ product, onIngreds }) => {
  const { section, footerWrapper } = styles();
  const { cart, cartLoading, productsLoading, onCartRefresh } =
    useCartAndProducts();
  const { editable } = useCartDerivedAttributes(cart);
  const { quantity, onSet, onDelete, selectedElement, selected, onConfirm } =
    useCachedCartProductSelection(product);

  let chip,
    other = null,
    ingreds = null;

  const loading = cartLoading || productsLoading;
  const onActualAddToCart = useCallback(() => onSet(1), [onSet]);

  if (selected) {
    chip = (
      <QuantityChip
        selectedElement={
          editable ? { quantity, cost: c(quantity, product) } : selectedElement
        }
      />
    );
  } else {
    chip = <CostChip element={product} />;
  }

  if (!cart) {
    other = (
      <AutoLoadingButton loading={loading} onClick={onCartRefresh}>
        Ricarica prenotazione
      </AutoLoadingButton>
    );
  } else {
    if (editable) {
      if (quantity) {
        other = (
          <Box display="flex" alignItems="center">
            <CustomDropdownMenu
              options={opts(10)}
              value={quantity}
              onChange={onSet}
            />
            <IconButton
              size="medium"
              color="secondary"
              onClick={onDelete}
              disabled={!selected}
            >
              <DeleteForever />
            </IconButton>
          </Box>
        );
      } else {
        other = (
          <AutoLoadingButton
            disabled={product.unavailable}
            loading={loading}
            onClick={onActualAddToCart}
          >
            {product.unavailable ? "Non disponibile" : "Seleziona"}
          </AutoLoadingButton>
        );
      }
      if (selectedElement) {
        ingreds = (
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={onIngreds}
          >
            Selezione ingredienti
          </Button>
        );
      }
    } else {
      other = (
        <Button variant="outlined" color="primary" disabled>
          Non modificabile
        </Button>
      );
    }
  }

  return (
    <Box component="footer">
      <Box component="section" className={clsx([footerWrapper, section])}>
        {chip}
        {other}
      </Box>
      <ButtonGroup fullWidth>
        {editable && quantity && (
          <AutoLoadingButton
            loading={loading}
            onClick={onConfirm}
            ButtonProps={{ fullWidth: true }}
          >
            Conferma selezione
          </AutoLoadingButton>
        )}
        {ingreds}
      </ButtonGroup>
    </Box>
  );
});

////////////////

/**
 * @returns
 * @param {{product: Product, descriptionTypographyProps?: TypographyProps}} param0
 */
function Renderer({ product, descriptionTypographyProps }) {
  const { descriptionStyle, section } = styles();
  const [ingreds, setIngreds] = useState(false);
  const { onCartRefresh } = useCartAndProducts();
  const { selectedElement } = useCartProductSelection(product);

  const { onBack, onIngreds } = useMemo(
    () => ({
      onIngreds: () => setIngreds(true),
      onBack: () => setIngreds(false),
    }),
    [setIngreds]
  );

  if (ingreds) {
    return (
      <IngredSelView
        onBack={onBack}
        cartProduct={selectedElement}
        onCartRefresh={onCartRefresh}
      />
    );
  } else {
    return (
      <Box component="article">
        <Box component="section" className={section}>
          <Picture
            defaultSrc={getProductImage(product)}
            alt={product.name}
            figureStyle={additionalStyles.figure}
            imgStyle={additionalStyles.img}
          />
        </Box>
        <Box component="section" className={section}>
          <Typography
            component="p"
            variant="body1"
            className={descriptionStyle}
            {...(descriptionTypographyProps || {})}
          >
            {product.description}
          </Typography>
        </Box>
        <Footer product={product} onSelectIngredients={onIngreds} />
      </Box>
    );
  }
}

export default memo(Renderer);
