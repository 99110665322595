import { Message } from "../../model";
import { withDefaultHateoas } from "../hateoas/auxiliary/getDefaultHateoasData";
import links from "../links";

const n = 30;
const z = (d) => (d < 10 ? "0" + d : d);

/**
 * @type {Message[]}
 */
let messages = [];

for (let i = 0; i < n; i++) {
  messages.push(
    withDefaultHateoas(links.message, {
      id: i,
      date: `2022-11-11T${z((13 + i) % 24)}:20:00.000Z`,
      conversation: i % 10,
      author: "Autore",
      content: `contenuto del messaggio ${i}`,
    })
  );
}

export default messages;
