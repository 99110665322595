import makeFetcher from "./fetcher";
import { DataCollections } from "./collections";
import { buildFetcher } from "@italwebcom/augmented-fetch";
import {
  wrap,
  HateoasLinksManager,
  CrudOperation,
  CrudOperationsWrapper,
} from "@italwebcom/crud-operations-wrapper";
import { getDefaultOperations } from "./auxiliary/misc";
import {
  Image,
  Category,
  Tag,
  Conversation,
  Message,
  ProductFeature,
  FeatureValue,
  CostList,
  ValidityDiscriminator,
  AttributeMatcher,
  DeliveryZone,
  DeliveryCost,
  Product,
  ProductCategory,
  ProductImage,
  Customer,
  Notification,
  Cart,
  NewsItem,
  ProductCost,
  Stat,
  GlobalSearchResult,
  ClosingDay,
  OpeningTime
} from "../model";

function makeWrapper(name, hateoasLinksManager, request, additionalTemplates) {
  return wrap(
    getDefaultOperations(name, additionalTemplates),
    {
      linksGetter: hateoasLinksManager._getter,
    },
    request
  );
}

/**
 * @typedef {CrudOperation | "messages" | "postMessage"} ConversationOperation
 * @typedef {CrudOperation | "discriminators" | "addDiscriminator"} ConditionallyValidOperation
 **/

/**
 * @template Entity
 * @typedef {CrudOperationsWrapper<Entity, ConditionallyValidOperation>} ConditionallyValidWrapper
 */

/**
 *
 * @typedef {CrudOperationsWrapper<Image, CrudOperation>} ImageWrapper
 * @typedef {CrudOperationsWrapper<Tag, CrudOperation>} TagWrapper
 * @typedef {CrudOperationsWrapper<Category, CrudOperation>} CategoryWrapper
 * @typedef {CrudOperationsWrapper<Conversation, ConversationOperation>} ConversationWrapper
 * @typedef {CrudOperationsWrapper<Message, CrudOperation>} MessageWrapper
 * @typedef {CrudOperationsWrapper<ProductFeature, CrudOperation>} ProductFeatureWrapper
 * @typedef {CrudOperationsWrapper<FeatureValue, CrudOperation>} FeatureValueWrapper
 * @typedef {ConditionallyValidWrapper<CostList>} CostListWrapper
 * @typedef {CrudOperationsWrapper<ValidityDiscriminator, CrudOperation>} ValidityDiscriminatorWrapper
 * @typedef {CrudOperationsWrapper<AttributeMatcher, CrudOperation>} AttributeMatcherWrapper
 * @typedef {CrudOperationsWrapper<DeliveryZone, CrudOperation>} DeliveryZoneWrapper
 * @typedef {CrudOperationsWrapper<DeliveryCost, CrudOperation>} DeliveryCostWrapper
 * @typedef {CrudOperationsWrapper<Product, CrudOperation>} ProductWrapper
 * @typedef {CrudOperationsWrapper<ProductCategory, CrudOperation>} ProductCategoryWrapper
 * @typedef {CrudOperationsWrapper<ProductImage, CrudOperation>} ProductImageWrapper
 * @typedef {CrudOperationsWrapper<ProductCost, CrudOperation>} ProductCostWrapper
 * @typedef {CrudOperationsWrapper<Customer, CrudOperation>} CustomerWrapper
 * @typedef {CrudOperationsWrapper<Notification, CrudOperation>} NotificationWrapper
 * @typedef {CrudOperationsWrapper<Cart, CrudOperation>} CartWrapper
 * @typedef {CrudOperationsWrapper<NewsItem, CrudOperation>} NewsItemWrapper
 * @typedef {CrudOperationsWrapper<CartProduct, CrudOperation>} CartProductWrapper
 * @typedef {CrudOperationsWrapper<any, CrudOperation>} UserWrapper
 * @typedef {CrudOperationsWrapper<Stat, CrudOperation>} StatWrapper
 * @typedef {CrudOperationsWrapper<GlobalSearchResult, CrudOperation>} GlobalSearchResultWrapper
 * @typedef {CrudOperationsWrapper<ClosingDay, CrudOperation>} ClosingDayWrapper
 * @typedef {CrudOperationsWrapper<OpeningTime, CrudOperation>} OpeningTimeWrapper
 *
 * @typedef {{
 *    image: ImageWrapper,
 *    tag: TagWrapper,
 *    category: CategoryWrapper,
 *    conversation: ConversationWrapper,
 *    message: MessageWrapper,
 *    productFeature: ProductFeatureWrapper,
 *    featureValue: FeatureValueWrapper,
 *    costList: CostListWrapper,
 *    validityDiscriminator: ValidityDiscriminatorWrapper,
 *    attributeMatcher: AttributeMatcherWrapper,
 *    deliveryZone: DeliveryZoneWrapper,
 *    deliveryCost: DeliveryCostWrapper,
 *    product: ProductWrapper,
 *    productCategory: ProductCategoryWrapper,
 *    productCost: ProductCostWrapper,
 *    productImage: ProductImageWrapper,
 *    customer: CustomerWrapper,
 *    notification: NotificationWrapper,
 *    cart: CartWrapper,
 *    cartProduct: CartProductWrapper,
 *    newsItem: NewsItemWrapper,
 *    stat: StatWrapper,
 *    user: UserWrapper,
 *    globalSearchResult: GlobalSearchResultWrapper,
 *    closingDay: ClosingDayWrapper,
 *    openingTime: OpeningTimeWrapper
 * }} OperationWrappers
 */

/**
 * @returns
 * @param {DataCollections} collections
 */
function makeWrappers(collections) {
  const mgr = new HateoasLinksManager("fetch");
  const fetcher = makeFetcher(collections);
  const { request: r, fetcher: actualFetcher } = buildFetcher({
    onFetch: fetcher,
    onError: async (e, r) => {
      //return Promise.reject(e);
      const respData = await r.json();
      throw respData.data;
    },
    onRedirect: (e) => {
    },
  });

  let ops = getDefaultOperations("categories");
  ops._links.topCategories = {
    href: `/categories/top{?sortAttribute,sortDirection,page,size,name}`,
    templated: true,
  };
  const cWrapper = wrap(
    ops,
    {
      linksGetter: mgr._getter,
    },
    r
  );

  /**
   * @type {OperationWrappers}
   */
  const wrappers = {
    image: makeWrapper("images", mgr, r),
    tag: makeWrapper("tags", mgr, r),
    category: cWrapper,
    message: makeWrapper("messages", mgr, r),
    conversation: makeWrapper("conversations", mgr, r),
    productFeature: makeWrapper("product-features", mgr, r),
    featureValue: makeWrapper("feature-values", mgr, r),
    costList: makeWrapper("cost-lists", mgr, r),
    validityDiscriminator: makeWrapper("validity-discriminators", mgr, r),
    attributeMatcher: makeWrapper("attribute-matchers", mgr, r),
    deliveryZone: makeWrapper("delivery-zones", mgr, r),
    deliveryCost: makeWrapper("delivery-costs", mgr, r),
    closingDay: makeWrapper("closing-days", mgr, r),
    openingTime: makeWrapper("opening-times", mgr, r),
    product: makeWrapper("products", mgr, r),
    productCategory: makeWrapper("product-categories", mgr, r),
    productImage: makeWrapper("product-images", mgr, r),
    productCost: makeWrapper("product-costs", mgr, r),
    customer: makeWrapper("customers", mgr, r),
    notification: makeWrapper("notifications", mgr, r),
    stat: makeWrapper("stats", mgr, r),
    globalSearchResult: makeWrapper("search", mgr, r),
    cart: makeWrapper("carts", mgr, r, {
      makeTemp: {
        method: "POST",
        target: "/temp",
        properties: []
      }
    }),
    cartProduct: makeWrapper("cart-products", mgr, r),
    newsItem: makeWrapper("news-items", mgr, r),
    user: wrap(
      {
        _templates: {
          register: {
            method: "PUT",
            target: "/user",
            properties: []
          },
          delete: {
            method: "DELETE",
            target: "/user",
            properties: []
          }
        }
      },
      {
        linksGetter: mgr._getter,
      },
      r
    )
  };

  return {wrappers, request: r, fetcher: actualFetcher};
}

export default makeWrappers;
