import React, { memo } from "react";
import { Detail } from "../../components/_auxiliary/HomepageView/defines";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useRuntimeContext } from "../../contexts";
import { Carts } from "@italwebcom/anema-react-components";
import CustomerProductSelectionView from "../../components/_entities/products/CustomerProductSelectionView/Sectioned";
import PageWrapper from "../__auxiliary__/PageWrapper";
import useHomepageTitle from "./hooks/useHomepageTitle";
import TabsFilteringProductSelectionView from "../../components/_entities/products/TabsFilteringProductSelectionView";
import MainContentPanel from "../../components/MainContentPanel";
import styles from "./styles";
import PanelRenderer from "./PanelRenderer";
import NewsItemsCustomerRenderer from "../../components/_entities/newsitems/CustomerNewsItemsRenderer";
import TempCartAlert from "../../components/_entities/carts/TempCartAlert";
import PrivacyPolicyPanel from "../../components/_auxiliary/PrivacyPolicyPanel";

const { DynamicCartAndProductsProvider } = Carts.Components;

/**
 * @typedef {{
 *      variant: "panel" | "fullPage" | "noPanel",
 *      panelBackgroundImage?: string,
 *      panelButtonImage?: string,
 *      panelDetails?: Detail[],
 *      panelDescription?: string,
 *      title: string | (username: string) => string,
 *      logo?: string,
 *      productSelectionViewSectionFilters: string[],
 *      cart?: number,
 *      productSelectionViewInitialCategoryFilter?: number
 * }} HomepageProps
 */

/**
 * @param {HomepageProps} props
 *
 * !REQUIRES a RuntimeContext.Provider ancestor.
 */
function HomepageTemplate(props) {
  /* custom styles */
  const { productSelectionViewContent, sticky, panelWrapper } = styles();

  /* stylings from runtime context */
  const { smallScreen, stylings } = useRuntimeContext();
  const { headersTypographyProps, contentPanelHeader } = stylings.general;
  const { selectionView } = stylings.home;

  /* props */
  const {
    variant,
    productSelectionViewSectionFilters,
    cart,
    title,
    logo,
    viewName,
    privacyPolicyLink,
  } = props;

  const actualTitle = useHomepageTitle(title);
  let menu;

  if (smallScreen) {
    menu = (
      <Box component="article" className={panelWrapper}>
        <MainContentPanel
          title={"I nostri piatti"}
          headerProps={contentPanelHeader}
          header={variant === "noPanel" && <span />}
        >
          <TabsFilteringProductSelectionView
            filtererStyle={{
              position: "sticky",
              top: "64px",
              maxHeight: "calc(100vh - 64px)",
              overflow: "auto",
            }}
            initialCategoryFilter={
              props.productSelectionViewInitialCategoryFilter
            }
          />
        </MainContentPanel>
      </Box>
    );
  } else {
    menu = (
      <Box component="article" className={panelWrapper}>
        {/*<Box
          component="header"
          className={sticky}
          style={{
            top: selectionView.titleOffset,
          }}
        >
          <Typography component="h5" variant="h5" {...headersTypographyProps}>
            I nostri piatti
          </Typography>
        </Box>*/}
        <Box component="section" className={productSelectionViewContent}>
          <CustomerProductSelectionView
            headerProps={contentPanelHeader}
            variant="panel"
            sectionFilters={productSelectionViewSectionFilters}
            smallScreen={smallScreen}
            filtererStyle={{
              position: "sticky",
              top: selectionView.sliderOffset,
              height: `calc(100vh - ${selectionView.sliderOffset})`,
            }}
          />
        </Box>
      </Box>
    );
  }

  let contentPanel = (
    <Box>
      <TempCartAlert />
      <PanelRenderer
        {...props}
        title={actualTitle}
        variant={variant}
        smallScreen={smallScreen}
        headersTypographyProps={headersTypographyProps}
        cart={cart}
        logo={logo}
      />
    </Box>
  );

  return (
    <DynamicCartAndProductsProvider id={cart}>
      <PageWrapper
        title={actualTitle}
        externalContent={variant === "fullPage" && contentPanel}
        viewName={viewName || "home"}
        cart={cart}
        noHeader={props.noHeader || variant === "fullPage"}
        noContentGutters={props.noContentGutters}
        noTopPadding={props.noTopPadding}
      >
        {variant === "panel" && (
          <Box className={panelWrapper}>{contentPanel}</Box>
        )}
        {menu}
        <PrivacyPolicyPanel
          privacyPolicyLink={privacyPolicyLink}
          storageAttributeName="privacy"
        />
        {/*smallScreen && variant !== "noPanel" && (
          <Box className={panelWrapper}>
            <MainContentPanel
              title="Ultime notizie"
              headerProps={contentPanelHeader}
            >
              <NewsItemsCustomerRenderer vertical carouselDotColor="grey" />
            </MainContentPanel>
          </Box>
        )*/}
      </PageWrapper>
    </DynamicCartAndProductsProvider>
  );
}

export default memo(HomepageTemplate);
