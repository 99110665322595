import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ImageCarousel from "./auxiliary/ImageCarousel";
import ImageRenderer from "./auxiliary/ImageRenderer";
import { GalleryImage } from "./defines";

/**
 * @typedef {{
 *      images: GalleryImage[],
 *      onChange: (image: GalleryImage) => void,
 *      defaultSelected?: GalleryImage
 * }} ImageGalleryProps
 */

/**
 * @returns
 * @param {ImageGalleryProps} param0
 */
export default function ImageGallery({ images, onChange, defaultSelected }) {
  const [selected, setSelected] = useState(defaultSelected);
  useEffect(() => {
    selected && onChange && onChange(selected);
  }, [selected, onChange]);
  return (
    <Grid container spacing={2}>
      {images.map((i) => (
        <ImageRenderer
          selected={selected}
          onSelect={setSelected}
          image={i}
          key={i.name}
        />
      ))}
      <Grid item xs={12}>
        <ImageCarousel
          selectedItem={selected}
          onChange={setSelected}
          images={images}
        />
      </Grid>
    </Grid>
  );
}
