import React, { memo } from "react";
import { Authentication, Carts } from "@italwebcom/anema-react-components";
import MainContentPanel from "../MainContentPanel";
import useRuntimeContext from "../../contexts/Runtime/useRuntimeContext";
import Lock from "@material-ui/icons/Lock";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MissingElementRenderer from "../MissingElementRenderer";

const { TempCartProvider } = Carts.Components;
const { useCartAndProducts } = Carts.hooks;

function renderButton({ loading, onSetCredentials }) {
  return (
    <Button
      type="button"
      variant="outlined"
      color="primary"
      onClick={onSetCredentials}
      disabled={loading}
      startIcon={loading && <CircularProgress color="white" size={12} />}
      fullWidth
    >
      Registrati o effettua l'accesso
    </Button>
  );
}

const defaults = {
  title: "Accedi al tuo account per proseguire",
  subtitle:
    "Cliccando o premendo il pulsante sarai reindirizzato alla pagina di login, che si chiuderà automaticamente al termine della procedura.",
};

const Inner = memo(
  ({ setCredentials, onError, icon, title, subtitle, wrapped }) => {
    const { onSetCredentials, loading } =
      Authentication.hooks.useTempCustomerSetCredentials({
        setCredentials,
        onError,
      });
    const { cartLoading } = useCartAndProducts();
    const { stylings } = useRuntimeContext();
    const innerContent = (
      <MissingElementRenderer
        Icon={Lock}
        title={title || defaults.title}
        content={subtitle || defaults.subtitle}
        additionalContent={renderButton({
          loading: loading || cartLoading,
          onSetCredentials,
        })}
      />
    );
    if (wrapped) {
      return (
        <Container maxWidth="sm">
          <MainContentPanel
            title="Autenticazione"
            headerProps={stylings.general.contentPanelHeader}
          >
            {innerContent}
          </MainContentPanel>
        </Container>
      );
    } else {
      return innerContent;
    }
  }
);

function CustomerCredentialsSetter({
  setCredentials,
  onError,
  title,
  icon,
  subtitle,
}) {
  return (
    <TempCartProvider>
      <Inner
        setCredentials={setCredentials}
        onError={onError}
        title={title}
        icon={icon}
        subtitle={subtitle}
        wrapped
      />
    </TempCartProvider>
  );
}

export { Inner as InnerCustomerCredentialsSetter };
export default memo(CustomerCredentialsSetter);
