import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    listElement: {
        listStyleType: "none",
        display: "inline-block",
        boxSizing: "border-box"
    },
    listImage: {
        display: "block",
        maxHeight: "100%",
        margin: "auto"
    },
    listRoot: {
        margin: 0,
        padding: 0
    }
}));

export default useStyles;