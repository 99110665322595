import { useContext, useMemo } from "react";
import context from "../context";

/**
 * @returns
 * @param {string} name 
 */
export default function useStorageValue(name) {
    const {onGet} = useContext(context);
    return onGet(name);
}