import { Product } from "../../model";
import wrappers from "../hateoas";
import settings from "../settings";
import {imgs} from "../auxiliary/productData";

const n = settings.nProducts;

/**
 * @type {Product[]}
 */
let products = [];

for (let i = 0; i < n; i++) {
  products.push(
    wrappers.product({
      id: i,
      name: `product${i}`,
      code: `product_code${i}`,
      cost: (10.0 + i),
      description: `This is product product${i}`,
      mainImage: imgs[i % 5],
      categories: (i % 2) ? [12, 10, 8] : [6, 4, 2],
      unavailable: (i > 5) && (i < 10)
    })
  );
}
/*
products.push(
  wrappers.product({
    id: 1,
    name: "Crocchette Artigianali",
    description: "Crocchette di patate artigianali ripiene di mozzarella.",
    cost: "7.5",
    mainImage: "https://billis.italweb.app/billis-app-alt/server/public/img/824711335.jpg",
    categories: [1]
  })
)

products.push(
  wrappers.product({
    id: 2,
    name: "Fritto Billissimo",
    description: "Fritto artigianale (polentina, crocchetta ripiena con mozzarella, melanzana ripiena, zeppoline, frittatina di pasta, mozzarella in carrozza)",
    cost: "8.5",
    mainImage: "https://billis.italweb.app/billis-app-alt/server/public/img/fritto.jpg",
    categories: [1]
  })
)

products.push(
  wrappers.product({
    id: 3,
    name: "Scroncy Scroncy",
    description: "Misto di frittini sfiziosi, stile americano",
    cost: "8.5",
    mainImage: "https://billis.italweb.app/billis-app-alt/server/public/img/scroncy.jpg",
    categories: [1]
  })
)

products.push(
  wrappers.product({
    id: 4,
    name: "Patata Crispers",
    description: "Patatine surgelate con buccia di primissima qualità",
    cost: "4.5",
    mainImage: "https://billis.italweb.app/billis-app-alt/server/public/img/586297294.jpg",
    categories: [1]
  })
)

products.push(
  wrappers.product({
    id: 5,
    name: "BBQ ribs baffa intera",
    description: "Costine intere di maialino cotto a bassa temperatura, massaggiate con una miscela di spezie, poi scottate su griglia, ricoperte di salsa BBQ ad ottenere la caramellatura. Servite con patate e salse.",
    cost: "17",
    mainImage: "https://billis.italweb.app/billis-app-alt/server/public/img/ribs.jpg",
    categories: [2]
  })
)

products.push(
  wrappers.product({
    id: 6,
    name: "BBQ ribs mezza baffa",
    description: "Costine intere di maialino cotto a bassa temperatura, massaggiate con una miscela di spezie, poi scottate su griglia, ricoperte di salsa BBQ ad ottenere la caramellatura. Servite con patate e salse.",
    cost: "17",
    mainImage: "https://billis.italweb.app/billis-app-alt/server/public/img/ribs.jpg",
    categories: [2]
  })
)

products.push(
  wrappers.product({
    id: 7,
    name: "Tagliata Rucola e Grana",
    description: "Tagliata di Maremma selezione Billi's servita con rucola, grana e pomodorini",
    cost: "19",
    mainImage: "https://billis.italweb.app/billis-app-alt/server/public/img/Grill_icon.png",
    categories: [2]
  })
)

products.push(
  wrappers.product({
    id: 8,
    name: "Tagliata con lardo di Colonnata",
    description: "tagliata di Maremma selezione Billi’s, servita con patate e accompagnata da salse",
    cost: "19",
    mainImage: "https://billis.italweb.app/billis-app-alt/server/public/img/Grill_icon.png",
    categories: [2]
  })
)

products.push(
  wrappers.product({
    id: 9,
    name: "California",
    description: "hamburger macina billi's, doppio cheddar, triplo bacon, anelloni di cipolla pastellati alla birram, salsa BBQ SPECIAL. Servito con patate.",
    cost: "13",
    mainImage: "https://billis.italweb.app/billis-app-alt/server/public/img/california2022.jpg",
    categories: [3]
  })
)

products.push(
  wrappers.product({
    id: 10,
    name: "Double Smashed",
    description: "Doppio hamburger macina Billi's cotto con tecnica smashed, cetrioli in agrodolce, cipolla rossa cruda, ceddar, bacon crunch, salsa special. Servito con patate.",
    cost: "15",
    mainImage: "https://billis.italweb.app/billis-app-alt/server/public/img/doublesmashed.jpg",
    categories: [3]
  })
)

products.push(
  wrappers.product({
    id: 11,
    name: "Il Beyonde",
    description: "Hamburger vegano a base di rapa rossa, patate e piselli, friarielli, pomodorini semi-dry, patate a fette al forno. Servito con patate.",
    cost: "13",
    mainImage: "https://billis.italweb.app/billis-app-alt/server/public/img/beyonde.jpg",
    categories: [3]
  })
)

products.push(
  wrappers.product({
    id: 12,
    name: "Il Big Gym",
    description: "hamburger di Maremmana e Cinta senese, provola di Agerola, patate aromatiche al forno, bacon croccante, uovo occhio di bue. Servito con patate.",
    cost: "13",
    mainImage: "https://billis.italweb.app/billis-app-alt/server/public/img/biggym.jpg",
    categories: [3]
  })
)

products.push(
  wrappers.product({
    id: 13,
    name: "Il Billarino",
    description: "hamburger di Maremmana e Cinta senese, rucola, bacon croccante, provola di Agerola, pomodori secchi e salsa Billis. Servito con patate.",
    cost: "13",
    mainImage: "https://billis.italweb.app/billis-app-alt/server/public/img/billarino.jpg",
    categories: [3]
  })
)

products.push(
  wrappers.product({
    id: 14,
    name: "Capricciosa",
    description: "pomodoro, mozzarella, prosciutto cotto, carciofi, funghi, olive, origano",
    cost: "9",
    mainImage: "https://billis.italweb.app/billis-app-alt/server/public/img/1277836480.jpg",
    categories: [4]
  })
)

products.push(
  wrappers.product({
    id: 15,
    name: "Margherita",
    description: "pomodoro, mozzarella",
    cost: "6",
    mainImage: "https://billis.italweb.app/billis-app-alt/server/public/img/169930699.jpg",
    categories: [4]
  })
)

products.push(
  wrappers.product({
    id: 16,
    name: "Marinara",
    description: "pomodoro, aglio, origano",
    cost: "5",
    mainImage: "https://billis.italweb.app/billis-app-alt/server/public/img/1201416229.jpg",
    categories: [4]
  })
)


products.push(
  wrappers.product({
    id: 13,
    name: "Il Billarino",
    description: "hamburger di Maremmana e Cinta senese, rucola, bacon croccante, provola di Agerola, pomodori secchi e salsa Billis. Servito con patate.",
    cost: "13",
    mainImage: "https://billis.italweb.app/billis-app-alt/server/public/img/billarino.jpg",
    categories: [3]
  })
)*/



products.push(
  wrappers.product({
    id: 1,
    name: "4 formaggi",
    description: "pizza bianca con mozzarella, provola, emmental, gorgonzola",
    cost: "7.5",
    mainImage: "https://animacore.italweb.agency/file/Biancolina.jpg",
    categories: [1]
  })
)

products.push(
  wrappers.product({
    id: 2,
    name: "Biancolina",
    description: "pizza bianca con mozzarella, gorgonzola, speck, radicchio, olio evo",
    cost: "8.5",
    mainImage: "https://animacore.italweb.agency/file/Biancolina.jpg",
    categories: [1]
  })
)

products.push(
  wrappers.product({
    id: 3,
    name: "Mimosa",
    description: "pizza bianca con fiordilatte, panna, prosciutto, mais",
    cost: "8",
    mainImage: "https://animacore.italweb.agency/file/Posillipo.jpg",
    categories: [1]
  })
)

products.push(
  wrappers.product({
    id: 4,
    name: "Ortolana",
    description: "pizza bianca con mozzarella, salsiccia, friarielli, evo",
    cost: "8",
    mainImage: "https://animacore.italweb.agency/file/Posillipo.jpg",
    categories: [1]
  })
)

products.push(
  wrappers.product({
    id: 5,
    name: "Posillipo",
    description: "pizza bianca con mozzarella, salsiccia, friarielli, evo",
    cost: "8",
    mainImage: "https://animacore.italweb.agency/file/Posillipo.jpg",
    categories: [1]
  })
)

products.push(
  wrappers.product({
    id: 6,
    name: "Primavera",
    description: "pizza bianca con mozzarella, salsiccia, friarielli, evo",
    cost: "8",
    mainImage: "https://animacore.italweb.agency/file/Posillipo.jpg",
    categories: [1]
  })
)

products.push(
  wrappers.product({
    id: 7,
    name: "Terra mia",
    description: "pizza bianca con mozzarella, salsiccia, friarielli, evo",
    cost: "8",
    mainImage: "https://animacore.italweb.agency/file/Posillipo.jpg",
    categories: [1]
  })
)

products.push(
  wrappers.product({
    id: 8,
    name: "Amalfi",
    description: "pizza bianca con mozzarella, salsiccia, friarielli, evo",
    cost: "8",
    mainImage: "https://animacore.italweb.agency/file/Posillipo.jpg",
    categories: [2]
  })
)

products.push(
  wrappers.product({
    id: 9,
    name: "Crocchettara",
    description: "pizza bianca con mozzarella, salsiccia, friarielli, evo",
    cost: "8",
    mainImage: "https://animacore.italweb.agency/file/Posillipo.jpg",
    categories: [2]
  })
)

products.push(
  wrappers.product({
    id: 10,
    name: "O sole mio",
    description: "pizza bianca con mozzarella, salsiccia, friarielli, evo",
    cost: "8",
    mainImage: "https://animacore.italweb.agency/file/Posillipo.jpg",
    categories: [2]
  })
)

products.push(
  wrappers.product({
    id: 11,
    name: "Pistacchiosa",
    description: "pizza bianca con mozzarella, salsiccia, friarielli, evo",
    cost: "8",
    mainImage: "https://animacore.italweb.agency/file/Posillipo.jpg",
    categories: [2]
  })
)

products.push(
  wrappers.product({
    id: 12,
    name: "Margherita",
    description: "pizza bianca con mozzarella, provola, emmental, gorgonzola",
    cost: "7.5",
    mainImage: "https://animacore.italweb.agency/file/Biancolina.jpg",
    categories: [3]
  })
)

products.push(
  wrappers.product({
    id: 13,
    name: "Marinara",
    description: "pizza bianca con mozzarella, gorgonzola, speck, radicchio, olio evo",
    cost: "8.5",
    mainImage: "https://animacore.italweb.agency/file/Biancolina.jpg",
    categories: [3]
  })
)

products.push(
  wrappers.product({
    id: 14,
    name: "Napoli",
    description: "pizza bianca con fiordilatte, panna, prosciutto, mais",
    cost: "8",
    mainImage: "https://animacore.italweb.agency/file/Posillipo.jpg",
    categories: [3]
  })
)

products.push(
  wrappers.product({
    id: 15,
    name: "Bufalina",
    description: "pizza bianca con mozzarella, salsiccia, friarielli, evo",
    cost: "8",
    mainImage: "https://animacore.italweb.agency/file/Posillipo.jpg",
    categories: [3]
  })
)

products.push(
  wrappers.product({
    id: 16,
    name: "Capricciosa",
    description: "pizza bianca con mozzarella, salsiccia, friarielli, evo",
    cost: "8",
    mainImage: "https://animacore.italweb.agency/file/Posillipo.jpg",
    categories: [3]
  })
)

products.push(
  wrappers.product({
    id: 17,
    name: "Funiculi Funiculà",
    description: "pizza bianca con mozzarella, salsiccia, friarielli, evo",
    cost: "8",
    mainImage: "https://animacore.italweb.agency/file/Posillipo.jpg",
    categories: [3]
  })
)

products.push(
  wrappers.product({
    id: 18,
    name: "Parmigiana",
    description: "pizza bianca con mozzarella, salsiccia, friarielli, evo",
    cost: "8",
    mainImage: "https://animacore.italweb.agency/file/Posillipo.jpg",
    categories: [3]
  })
)

products.push(
  wrappers.product({
    id: 19,
    name: "Peppa Pig",
    description: "pizza bianca con mozzarella, salsiccia, friarielli, evo",
    cost: "8",
    mainImage: "https://animacore.italweb.agency/file/Parmigiana.jpg",
    categories: [3]
  })
)

products.push(
  wrappers.product({
    id: 20,
    name: "Sciuè sciuè",
    description: "pizza bianca con mozzarella, salsiccia, friarielli, evo",
    cost: "8",
    mainImage: "https://animacore.italweb.agency/file/Parmigiana.jpg",
    categories: [3]
  })
)

products.push(
  wrappers.product({
    id: 21,
    name: "Siciliana",
    description: "pizza bianca con mozzarella, salsiccia, friarielli, evo",
    cost: "8",
    mainImage: "https://animacore.italweb.agency/file/Parmigiana.jpg",
    categories: [3]
  })
)


products.push(
  wrappers.product({
    id: 22,
    name: "Calzone al cotto",
    description: "pizza bianca con mozzarella, salsiccia, friarielli, evo",
    cost: "8",
    mainImage: "https://animacore.italweb.agency/file/Calzone.jpg",
    categories: [4]
  })
)

products.push(
  wrappers.product({
    id: 23,
    name: "Calzone al salame",
    description: "pizza bianca con mozzarella, salsiccia, friarielli, evo",
    cost: "8",
    mainImage: "https://animacore.italweb.agency/file/Calzone.jpg",
    categories: [4]
  })
)

products.push(
  wrappers.product({
    id: 24,
    name: "Calzone salsiccia",
    description: "pizza bianca con mozzarella, salsiccia, friarielli, evo",
    cost: "8",
    mainImage: "https://animacore.italweb.agency/file/Calzone.jpg",
    categories: [4]
  })
)

products.push(
  wrappers.product({
    id: 25,
    name: "Pizza calzone anema e core",
    description: "pizza bianca con mozzarella, salsiccia, friarielli, evo",
    cost: "8",
    mainImage: "https://animacore.italweb.agency/file/Calzone.jpg",
    categories: [4]
  })
)



products.push(
  wrappers.product({
    id: 26,
    name: "Panuozzo hamburger",
    description: "pizza bianca con mozzarella, salsiccia, friarielli, evo",
    cost: "8",
    mainImage: "https://animacore.italweb.agency/file/Calzone.jpg",
    categories: [5]
  })
)

products.push(
  wrappers.product({
    id: 27,
    name: "Panuozzo prosciutto cotto",
    description: "pizza bianca con mozzarella, salsiccia, friarielli, evo",
    cost: "8",
    mainImage: "https://animacore.italweb.agency/file/Calzone.jpg",
    categories: [5]
  })
)

products.push(
  wrappers.product({
    id: 28,
    name: "Panuozzo prosciutto crudo",
    description: "pizza bianca con mozzarella, salsiccia, friarielli, evo",
    cost: "8",
    mainImage: "https://animacore.italweb.agency/file/Calzone.jpg",
    categories: [5]
  })
)

products.push(
  wrappers.product({
    id: 29,
    name: "Panuozzo salsiccia",
    description: "pizza bianca con mozzarella, salsiccia, friarielli, evo",
    cost: "8",
    mainImage: "https://animacore.italweb.agency/file/Calzone.jpg",
    categories: [5]
  })
)



export default products;
