import React from "react";
import SimpleListMenu from "../../../Menus/SimpleListMenu";
import SimpleAppBar, { SimpleAppBarProps } from "../../../Misc/AppBar/Simple";
import { Collapse, Grid, Box, Container, makeStyles } from "@material-ui/core";
import { useCallback, useState } from "react";
import clsx from "clsx";
import useStyles from "../styles";

export default function MainView({
  SimpleAppBarProps,
  SimpleListMenuProps,
  children,
}) {
  const [menu, setMenu] = useState(false);
  const onToggleMenu = useCallback(() => setMenu((m) => !m), [setMenu]);
  const {
    mainContainer,
    fullHeight,
    listMenuWrapper,
    appBarWrapper,
    mainContainerBg,
  } = useStyles();

  return (
    <Box display="flex" flexDirection="column" className={appBarWrapper}>
      <SimpleAppBar {...SimpleAppBarProps} onToggleMenu={onToggleMenu} />
      <Container maxWidth={false} style={{ flex: 1 }}>
        <Grid container>
          <Grid item className={fullHeight}>
            <Box
              position="sticky"
              top="82px"
              className={clsx([listMenuWrapper, menu && "shown"])}
            >
              <Box marginRight="2em">
                <SimpleListMenu {...SimpleListMenuProps} />
              </Box>
            </Box>
          </Grid>
          <Grid item xs>
            <Box className={clsx([mainContainer, mainContainerBg])}>
              {children}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
