import { ValidityDiscriminator } from "../../model";
import wrappers from "../hateoas";

const n = 60;

/**
 * @type {ValidityDiscriminator[]}
 */
let elements = [];

for (let i = 0; i < n; i++) {
  let relatedElement = Math.floor(i / 2);
  let vd = wrappers.validityDiscriminator({
    id: i,
    name: `Condizione di validità ${i}`,
    relatedElement: relatedElement
  });
  elements.push(vd);
}

export default elements;
