import {Field, GenericForm} from "@italwebcom/react-generic-form";

/**
 * @type {Field[]}
 */
const fields = [
    {
        id: "content",
        validationRules: [
            {
                priority: 0,
                invalidMessage: "Contenuto del messaggio non valido.",
                validator: v => v && v.length && GenericForm.validators.extendedAlphanumeric.test(v)
            }
        ]
    }
];

export default fields;