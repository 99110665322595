import React, { ComponentType, memo, useContext } from "react";
import Context from "../../context";
import { AuthError } from "../../defines";

/**
 * @typedef {{
 *      setCredentials: (credentials: any) => void,
 *      authError: AuthError
 * }} CredentialSetterComponentProps
 * @typedef {ComponentType<CredentialSetterComponentProps>} CredentialSetterComponent
 */

/**
 * @param {{Component: CredentialSetterComponent, requiredAuthError?: AuthError}} param0
 */
function Setter({ Component, requiredAuthError }) {
  const { setCredentials, authError } = useContext(Context);
  //console.log(`authError = ${authError} vs requiredAuthError = ${requiredAuthError}`);
  if (!requiredAuthError || authError === requiredAuthError) {
    return <Component authError={authError} setCredentials={setCredentials} />;
  }
  return null;
}

export default memo(Setter);
