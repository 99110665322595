import React, { memo, useCallback, useMemo } from "react";
import NativeSelect from "@material-ui/core/NativeSelect";

function range(l, u) {
  let out = [];
  for (let i = l; i <= u; i++) {
    out.push(i);
  }
  return out;
}

/**
 * @param {{min: number, max: number, value: number, onChange: (value: number) => void}} param0
 * @returns
 */
function QuantitySelector({ min, max, value, onChange, disabled }) {
  const actualVals = useMemo(() => range(min, max), [min, max]);
  const onActualChange = useCallback(
    (evt) => onChange(parseInt(evt.target.value)),
    [onChange]
  );
  return (
    <NativeSelect
      onChange={onActualChange}
      value={value}
      disabled={disabled}
      disableUnderline
    >
      <option disabled value={undefined} label=""></option>
      {actualVals.map((v) => (
        <option value={v} label={v} key={v}>
          {v}
        </option>
      ))}
    </NativeSelect>
  );
}

export default memo(QuantitySelector);
