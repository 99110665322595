/* istanbul ignore file */

import { createContext } from "react";
import { Cart, CartSorters, CartIndices } from "../../../../../__domain/model";
import { Filter } from "@italwebcom/custom-react-hooks";

/**
 * @type {{
 *    elements: Cart[], 
 *    onDelete: (cart: Cart) => any, 
 *    onSave: (cart: Cart) => any, 
 *    loading: boolean,
 *    onSorterChange: ({attribute: CartSorters, direction: "asc" | "desc"}),
 *    onFiltersSetAll: (filters: Filter<CartIndices>[]) => void,
 *    onFiltersClear: Function
 * }}
 */
const args = {
  elements: [],
  loading: false,
  onDelete: () => null,
  onSave: () => null,
  onRefreshAll: () => null,
  onSorterChange: () => null,
  onFiltersSetAll: () => null,
  onFiltersClear: () => null,
  onFilterUnpaid: () => null,
  onFilterOpen: () => null,
};

export default createContext(args);