import { useCallback, useMemo } from "react";
import { Conversation } from "../../../../__domain/model";
import useConversationWrapper from "../../../../hooks/wrappers/useConversationWrapper";
import useRequestAuthenticationSetter from "../../../authentication/hooks/useRequestAuthenticationSetter";
import useAlertFunctions from "../../../misc/AlertProvider/hooks/useAlertFunctions";
import {
  useTestCallback,
  callbacks,
} from "../../../../__test-auxiliary/TestCallbacks";

/**
 * @returns
 * @param {Conversation} conversation
 */
export default function useMessageProviderFunctions(conversation) {
  const authSetter = useRequestAuthenticationSetter();
  const postMessageCallback = useTestCallback(
    callbacks.Conversations.postMessage
  );
  const wrapper = useConversationWrapper();
  const { onAdd } = useAlertFunctions();

  const onFetchMessages = useCallback(
    (args) => {
      if (conversation) {
        let r = wrapper.wrap(conversation).execute("messages", args);
        authSetter(r.request());
        return r.json();
      } else {
        return Promise.resolve({ data: [] });
      }
    },
    [wrapper, conversation, authSetter]
  );

  const onPostMessage = useCallback(
    (e) => {
      postMessageCallback && postMessageCallback(e);
      let r = wrapper.wrap(conversation).execute("postMessage", e, true);
      authSetter(r.request());
      return r.json();
    },
    [wrapper, conversation, postMessageCallback, authSetter]
  );

  const { onSuccess, onError } = useMemo(
    () => ({
      onSuccess: (op) =>
        op !== "fetch" &&
        onAdd({ type: "success", content: "Messaggio inviato." }),
      onError: ({ type }) => {
        if (type === "save_error") {
          onAdd({ type: "error", content: "Si è verificato un errore." });
        }
      },
    }),
    [onAdd]
  );

  return {
    onFetchMessages,
    onPostMessage,
    onSuccess,
    onError
  };
}
