import React, { memo, useCallback, useMemo } from "react";
import useFormData from "../hooks/useFormData";
import { InputComponent, InputShouldRenderFunc } from "../defines";
import useFormConfiguration from "../hooks/useFormConfiguration";
import useShouldRender from "../hooks/useShouldRender";
import useShouldDisable from "../hooks/useShouldDisable";

function getField(fields, field) {
  const f = fields.filter((f) => f.id === field);
  if (f.length) {
    return f[0];
  } else {
    throw Error(
      `GenericForm.Input.<getField>: expected matching field for input value ${field}, but got null.`
    );
  }
}

function useActualComponent(Component) {
  const { InputComponent } = useFormConfiguration();
  return useMemo(
    () => Component || InputComponent,
    [Component, InputComponent]
  );
}

/**
 * @typedef {{
 *      Component: InputComponent,
 *      field: string,
 *      controlled?: boolean,
 *      InputProps?: any,
 *      shouldRender: InputShouldRenderFunc
 * }} InputProps
 */

/**
 * @returns
 * @param {InputProps} param0
 */
function Input({
  Component,
  field,
  controlled,
  InputProps,
  disabled,
  shouldRender,
}) {
  const ActualComponent = useActualComponent(Component);
  const { onSet, values, fields, loading } = useFormData();

  const daVal = values[field];
  const daField = getField(fields, field);
  const onChange = useCallback(
    (evt) => onSet(field, evt.target.value),
    [onSet, field]
  );

  const itShouldRender = useShouldRender({ shouldRender, field });
  const itShouldDisable = useShouldDisable({ field });

  /* istanbul ignore next */
  const ActualInputProps = InputProps || {};

  if (itShouldRender) {
    return (
      <ActualComponent
        value={/* istanbul ignore next */ controlled ? daVal : undefined}
        defaultValue={/* istanbul ignore next */ controlled ? null : daVal}
        onChange={onChange}
        type={daField.type}
        disabled={disabled || itShouldDisable || loading}
        loading={loading}
        {...ActualInputProps}
      />
    );
  }
  return null;
}

export default memo(Input);
