import React from "react";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import ShoppingCart from "@material-ui/icons/ShoppingCart";

export default function getShoppingIcon(cProds) {
  let icon = (
      <ShoppingCart fontSize="inherit" />
  );
  if (cProds && cProds.length) {
    return (
      <Badge color="secondary" badgeContent={cProds.length}>
        {icon}
      </Badge>
    );
  } else {
    return icon;
  }
}
