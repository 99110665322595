import React, { useCallback } from "react";
import { ButtonGroup as VariantButtonGroup } from "../_Auxiliary/VariantProvider";
import { SearchBarProps } from "@italwebcom/misc-ui-components";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

/**
 * @param {SearchBarProps} param0
 */
export default function SearchBar({ onSearch }) {
  const onActualSearch = useCallback(
    (evt) => onSearch(evt.target.value),
    [onSearch]
  );
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <VariantButtonGroup />
      </Grid>
      <Grid item xs style={{ backgroundColor: "white" }}>
        <Box height="100%" display="flex" alignItems="center">
          <TextField
            fullWidth
            placeholder="Ricerca per nome..."
            type="search"
            variant="outlined"
            onChange={onActualSearch}
            size="small"
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
