import React, { memo } from "react";
import List from "@material-ui/core/List";
import { Product } from "../../../../../../model";
import { useEntityManagerValues } from "@italwebcom/react-entity-manager";
import { ListItemRenderer } from "../../ItemRenderers";
import MissingProducts from "../auxiliary/MissingProducts";
import { plc } from "../AlternateListCollectionRenderer";
import PlaceholderWrapper from "../../../../../PlaceholderWrapper";

/**
 * @param {{elements: Product[]}} param0
 */
function ListCollectionRenderer({
  elements,
  actionLabel,
  onSelect,
  sendOnSelect,
}) {
  const { loading } = useEntityManagerValues();

  console.log(elements);

  const isPlaceholder = !elements || !elements.length || loading;
  const actualElements = isPlaceholder ? plc(10) : elements;

  /*if (!hasElements && !isPlaceholder) {
    return <MissingProducts />;
  } else {
    return (
      <List>
        {actualElements.map(
          (e) =>
            (
              <ListItemRenderer
                key={e.id}
                element={e}
                actionLabel={actionLabel}
                onSelect={sendOnSelect && onSelect}
                placeholder={isPlaceholder}
              />
            )
        )}
      </List>
    );
  }*/
  return (
    <List>
      {actualElements.map((e) => (
        <ListItemRenderer
          key={e.id}
          element={e}
          actionLabel={actionLabel}
          onSelect={sendOnSelect && onSelect}
          placeholder={isPlaceholder}
        />
      ))}
    </List>
  );
}

export default memo(ListCollectionRenderer);
