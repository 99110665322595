import { useCallback, useEffect, useMemo, useRef } from "react";
import { useEntityManagerValues } from "@italwebcom/react-entity-manager";
import { ProcessedOpeningTime } from "../context";
import { OpeningTime } from "../../../../__domain/model";
import dayjs from "dayjs";
import "dayjs/locale/it";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);
dayjs.locale("it");

/**
 * @return {ProcessedOpeningTime[]}
 * @param {OpeningTime[]} oTimes
 */
function getActualOpeningTimes(oTimes) {
  let out = [];
  let ind = 0;
  oTimes.forEach((oTime) => {
    let s = dayjs(oTime.start, "HH:mm");
    let e = dayjs(oTime.end, "HH:mm");
    do {
      out.push({ hour: s.hour(), minutes: s.minute(), index: ind });
      s = s.add(10, "minutes");
      ind++;
    } while (s.isBefore(e));
  });
  return out;
}

/**
 * @param {OpeningTime[]} els
 * @returns
 */
function filter(els) {
  return els.filter((e) => {
    const actual = dayjs(e.end, "HH:mm");
    const target = dayjs();
    return actual.isAfter(target);
  });
}

export default function useProviderData({filterAvailable}) {
  const { elements, onFilterReplace } = useEntityManagerValues();

  const { actualOpeningTimes, rawOpeningTimes } = useMemo(() => {
    if (elements) {
      let filtered = elements;
      if(filterAvailable) {
        filtered = filter(elements);
      }
      return {
        actualOpeningTimes: getActualOpeningTimes(filtered),
        rawOpeningTimes: filtered,
      };
    } else {
      return {
        actualOpeningTimes: [],
        rawOpeningTimes: [],
      };
    }
  }, [elements]);

  const onDateChange = useCallback(
    (d) =>
      onFilterReplace(
        { attribute: "weekday" },
        { attribute: "weekday", value: dayjs(d).day() }
      ),
    [onFilterReplace]
  );

  return { onDateChange, actualOpeningTimes, rawOpeningTimes };
}
