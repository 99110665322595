import React, { memo, ReactNode, CSSProperties } from "react";
import { makeStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    backgroundSize: "cover",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    /*borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",*/
    /*position: "relative",
    top: "-4px",*/
  },
  centeredTitle: {
    textAlign: "center",
  },
  titleStyle: {
    color: "white",
  },
  sideImageStyle: {
    display: "block",
    maxWidth: "50px",
  },
  contentWrapper: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
}));

/**
 * @typedef {{
 *      backgroundImage?: string,
 *      backgroundColor?: string,
 *      centeredTitle?: boolean,
 *      sideImage?: string,
 *      sideContent?: ReactNode,
 *      titleStyle?: CSSProperties
 * }} MainContentPanelHeaderProps
 *
 * @typedef {{
 *      content: ReactNode,
 *      title?: string,
 *      headerProps?: MainContentPanelHeaderProps
 * }} MainContentPanelProps
 */

const MainContentPanelHeader = memo(({ title, configuration }) => {
  const { headerWrapper, centeredTitle, titleStyle, sideImageStyle } =
    useStyles();
  configuration = configuration || {};
  return (
    <Box
      className={headerWrapper}
      style={{
        backgroundImage: configuration.backgroundImage
          ? `url(${configuration.backgroundImage})`
          : undefined,
        backgroundColor: !configuration.backgroundImage
          ? configuration.backgroundColor
          : undefined,
      }}
      component="header"
    >
      <Container maxWidth={false}>
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography
              component="h6"
              variant="h6"
              className={clsx([
                configuration.centeredTitle && centeredTitle,
                titleStyle,
              ])}
              style={configuration.titleStyle}
            >
              {title}
            </Typography>
          </Grid>
          {(configuration.sideImage || configuration.sideContent) && (
            <Grid item>
              {configuration.sideImage ? (
                <picture className={sideImageStyle}>
                  <img
                    src={configuration.sideImage}
                    alt="side image"
                    className={sideImageStyle}
                  />
                </picture>
              ) : (
                configuration.sideContent
              )}
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
});

/**
 *
 * @param {MainContentPanelProps} param0
 */
function MainContentPanel({ children, title, headerProps, header, noPaper }) {
  const { contentWrapper } = useStyles();
  const innerContent = (
    <Box
      flexDirection="column"
      display="flex"
      component={noPaper ? "article" : "div"}
    >
      {header || (
        <MainContentPanelHeader title={title} configuration={headerProps} />
      )}
      <Divider />
      <Box flex={1} component="section" padding="1rem">
        {children}
      </Box>
    </Box>
  );
  if (noPaper) {
    return innerContent;
  } else {
    return (
      <Paper component="article" className={contentWrapper}>
        {innerContent}
      </Paper>
    );
  }
}

export default memo(MainContentPanel);
