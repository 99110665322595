import { useStorage } from "../../../../misc/StorageProvider";
import { useRuntimeContext } from "../../../../misc/RuntimeProvider";
import { useCallback } from "react";

/**
 * @returns
 */
export default function useTempCartCode() {
  const { cartCodeName } = useRuntimeContext();
  const { onGet, onSet } = useStorage();
  const onActualGet = useCallback(() => {
    let v = onGet(cartCodeName);
    let out = {};
    out[cartCodeName] = v;
    return out;
  }, [onGet, cartCodeName]);
  const onActualSet = useCallback(
    (v) => onSet(cartCodeName, v, {}),
    [onSet, cartCodeName]
  );
  return { onSet: onActualSet, onGet: onActualGet };
}
