import React, { memo } from "react";
import useStyles from "../styles";
import { formatMessageDate, formatMessageHour } from "../../../../../functions";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Person from "@material-ui/icons/Person";
import { useCredentials } from "@italwebcom/react-auth-provider";
import { Message } from "@italwebcom/anema-react-components/Conversations";
import clsx from "clsx";
import PlaceholderWrapper from "../../../../PlaceholderWrapper";

/**
 * @param {{message: Message}} param0
 */
function MessageRenderer({ message, placeholder }) {
  const { messageWrapper, messageContainer, messageTitleWrapper, messageDate } =
    useStyles();
  const creds = useCredentials();
  const { content, date, author, authorName, authorAvatar } = message;
  const actualAuthor = author || authorName;
  const isAuthor = creds && creds.username && creds.username === actualAuthor;
  return (
    <Box
      className={clsx([messageWrapper, isAuthor ? "authored" : "not-authored"])}
    >
      <PlaceholderWrapper active={placeholder}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box className={clsx(["avatar-container"])}>
            <Avatar variant="circular" src={authorAvatar}>
              {!authorAvatar && <Person />}
            </Avatar>
          </Box>
          <Box>
            <Paper
              className={clsx([messageContainer, "message-container"])}
              component="article"
            >
              <Box component="header" className={messageTitleWrapper}>
                <Typography
                  className="message-subtitle"
                  component="p"
                  variant="caption"
                >
                  da {actualAuthor}
                </Typography>
              </Box>
              <Typography
                component="section"
                variant="body1"
                className="message-body"
              >
                {content}
              </Typography>
            </Paper>
            <Typography className={messageDate} component="p" variant="caption">
              {formatMessageDate(date)}, {formatMessageHour(date)}
            </Typography>
          </Box>
        </Box>
      </PlaceholderWrapper>
    </Box>
  );
}

export default memo(MessageRenderer);
