import { useCallback, useEffect, useState, useRef } from "react";

/**
 * @template T
 * @typedef {{
 *      onGet: () => Promise<T>,
 *      stoppingCondition: (elements: T) => boolean,
 *      timeout: number
 * }} AsyncPollingHookInputs
 * @typedef {{
 *      onSetInterval: (handler: Function, interval: number) => number,
 *      onClearInterval: (handle: number) => void
 * }} AsyncPollingHookConfig
 */

/**
 * @template T
 * @param {AsyncPollingHookInputs<T>} param0
 * @param {AsyncPollingHookConfig} param1
 * @return {T}
 */
export function useAsyncPolling({ onGet, stoppingCondition, timeout }, config) {
  const [elements, setElements] = useState(null);
  const { onSetInterval, onClearInterval } = config;
  const handleRef = useRef(null);

  const pollingFunc = useCallback(() => {
    onGet().then((stuff) => {
      setElements(stuff);
      if (stoppingCondition(stuff)) {
        onClearInterval(handleRef.current);
        handleRef.current = null;
      }
    });
  }, [onGet, stoppingCondition]);

  useEffect(() => {
    handleRef.current = onSetInterval(pollingFunc, timeout);
    return () => {
      if (handleRef.current !== null) {
        onClearInterval(handleRef.current);
      }
    };
  }, [pollingFunc, timeout]);

  return elements;
}

/**
 * @template T
 * @param {AsyncPollingHookConfig} config
 * @return {(vals: AsyncPollingHookInputs<T>) => T}
 */
export default function makeHook(config) {
  return (vals) => useAsyncPolling(vals, config);
}
