import React, { Fragment, memo, useCallback, useMemo } from "react";
import {
  CartTypeSelector,
  DateInput,
} from "../../CartCustomerEditor/_Auxiliary";
import {
  ActualTimePicker,
  ActualDeliveryZoneSelector,
  ActualAddressInput,
  ActualGuestsInput,
  ActualNotesInput,
} from "../../CartCustomerEditor/_Inputs";
import MainContentPanel, {
  MainContentPanelHeaderProps,
} from "../../../../MainContentPanel";
import { Cart } from "@italwebcom/anema-react-components/Carts";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { Carts, DeliveryZones } from "@italwebcom/anema-react-components";
import CartProductEditorPanel from "../CartProductEditorPanel";
import CartPaymentMethodSetter from "../../CartPaymentMethodSetter";
import { getStatusColor, printStatus } from "../../attributes";
import PayerButton from "../PayerButton";
import InputWrapperGroup from "../../CartCustomerEditor/_Auxiliary/InputWrapperGroup";
import CartErrorPrinter from "../CartErrorPrinter";
import PlaceholderWrapper from "../../../../PlaceholderWrapper";
import { useDialogConfirm } from "@italwebcom/misc-ui-components";
import { colors } from "../../../../../themes/anema";
import { Authentication } from "@italwebcom/anema-react-components";
import { InnerCustomerCredentialsSetter as CustomerCredentialsSetter } from "../../../../CustomerCredentialsSetter";
import { useRuntimeContext } from "../../../../../contexts";

const { CartCustomerEditor } = Carts.Components;
const { useCartDerivedAttributes } = Carts.hooks;
const { CompositeGate } = Authentication.Components;

const s = makeStyles((theme) => ({
  costTitle: {
    color: theme.palette.grey["700"],
    fontWeight: "bold",
  },
  costContent: {
    color: "green",
    fontWeight: "bold",
  },
}));

const ActualSetter = memo(({ setCredentials, onError }) => {
  return (
    <CustomerCredentialsSetter
      setCredentials={setCredentials}
      onError={onError}
      title="Autenticazione necessaria"
      subtitle="Per completare e confermare la prenotazione è necessario autenticarsi"
    />
  );
});

function CostPrinter({ cost, editable }) {
  const args = DeliveryZones.hooks.useDeliveryZoneCost();
  const { cost: deliveryCost, loading } = args;
  const { costTitle, costContent } = s();
  return (
    <Box>
      {Boolean(deliveryCost) && editable && (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography component="h5" variant="body1" className={costTitle}>
            Costo consegna
          </Typography>
          <Typography component="p" variant="h6" className={costContent}>
            {deliveryCost || "---"} &euro;
          </Typography>
        </Box>
      )}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography component="h5" variant="h6" className={costTitle}>
          Costo totale
        </Typography>
        <Typography component="p" variant="h5" className={costContent}>
          {(deliveryCost && editable ? cost + deliveryCost : cost) || "---"} &euro;
        </Typography>
      </Box>
    </Box>
  );
}

function renderPaymentGroup({ editable, placeholder }) {
  return (
    <Fragment>
      {(editable || placeholder) && (
        <Grid item xs={12}>
          <PlaceholderWrapper active={placeholder}>
            <CartCustomerEditor.Inputs.PaymentType
              InputComponent={CartPaymentMethodSetter}
            />
          </PlaceholderWrapper>
        </Grid>
      )}
      {editable && (
        <Grid item xs={12}>
          <CartCustomerEditor.Submit label="Conferma e paga" fullWidth />
        </Grid>
      )}
    </Fragment>
  );
}

function renderInputGroup({
  cart,
  editable,
  awaitingPayment,
  onPaid,
  cartLoading,
}) {
  cart = cart || {};
  const { authentication } = useRuntimeContext();
  return (
    <CompositeGate
      Setter={ActualSetter}
      defaultRole={authentication.defaultRole}
      requiredRole={authentication.authenticatedRole}
    >
      <InputWrapperGroup.Provider
        cart={cart}
        readOnly={!editable}
        placeholder={cartLoading}
      >
        <Grid container spacing={3}>
          {!editable && cart.status && (
            <Grid item xs={12}>
              <Typography variant="h6" style={{ color: colors.grey.primary }}>
                Stato
              </Typography>
              <Typography
                variant="h5"
                style={{
                  fontWeight: "bold",
                  color: getStatusColor(cart.status || "", cart),
                }}
                gutterBottom
              >
                {printStatus(cart.status, false, cart)}
              </Typography>
              {awaitingPayment && <PayerButton onPaid={onPaid} />}
            </Grid>
          )}
          <Grid item xs={12}>
            <CartCustomerEditor.Inputs.Type InputComponent={CartTypeSelector} />
          </Grid>
          <Grid item xs={12}>
            <CartCustomerEditor.Inputs.Date InputComponent={DateInput} />
          </Grid>
          <Grid item xs={12}>
            <CartCustomerEditor.Inputs.Time
              InputComponent={ActualTimePicker}
              controlled
            />
          </Grid>
          <CartCustomerEditor.Inputs.DeliveryZone
            InputComponent={ActualDeliveryZoneSelector}
          />
          <CartCustomerEditor.Inputs.Address
            InputComponent={ActualAddressInput}
          />
          <CartCustomerEditor.Inputs.Guests
            InputComponent={ActualGuestsInput}
          />
          <Grid item xs={12}>
            <CartCustomerEditor.Inputs.Notes
              InputComponent={ActualNotesInput}
            />
          </Grid>
          <Grid item xs={12}>
            <Box marginLeft="-16px" marginRight="-16px">
              <Divider />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <CostPrinter cost={cart.cost || cart.totalCost} editable={editable} />
          </Grid>
          {renderPaymentGroup({
            editable,
            payable: awaitingPayment,
            onPaid,
            placeholder: cartLoading,
          })}
        </Grid>
      </InputWrapperGroup.Provider>
    </CompositeGate>
  );
}

/**
 * @param {{
 *    headerProps: MainContentPanelHeaderProps,
 *    onSave: (cart: Cart) => void,
 *    cart: Cart,
 *    onCartProductAdd: Function,
 *    smallScreen: boolean,
 *    onPaid: Function
 * }} param0
 * @returns
 */
function CartEditorPanel({
  cart,
  onSave,
  headerProps,
  onCartProductAdd,
  smallScreen,
  onPaid,
  cartLoading,
  alertOnTypeChange,
  onCartRefresh
}) {

  const { editable, awaitingPayment } = useCartDerivedAttributes(cart);
  const { onlyDefaultPayment } = useRuntimeContext();

  const { onTrigger, rendered } = useDialogConfirm({
    confirmLabel: "Conferma",
    abortLabel: "Annulla",
    title: "Conferma modifica",
    message:
      "Alcuni piatti potrebbero non essere più disponibili modificando il tipo della prenotazione. In tal caso, verranno automaticamente rimossi. Procedere?",
  });

  const { onSet } = Carts.hooks.useCartTypeSet();
  const onTypeChange = useCallback(
    async (v) => {
      if (v) {
        const outcome = await onTrigger();
        if (outcome === "confirmed") {
          onSet(v);
        } else {
          onCartRefresh();
        }
      }
    },
    [onSet, onCartRefresh]
  );


  const onActualSave = useCallback(
    (lol) => {
      const out = { ...lol };
      if (out.deliveryZone && typeof out.deliveryZone.id === "number") {
        out.deliveryZoneId = out.deliveryZone.id;
      }
      onSave(out);
    },
    [onSave]
  );

  const defaultVals = useMemo(() => {
    if(onlyDefaultPayment) {
      return {...cart, cartPaymentType: "direct"};
    }
    return cart;
  }, [cart, onlyDefaultPayment]);

  return (
    <CartCustomerEditor.Provider
      onSubmit={onActualSave}
      defaultValues={defaultVals}
      onTypeChange={alertOnTypeChange && onTypeChange}
    >
      {alertOnTypeChange && rendered}
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={5}
          //style={{ order: smallScreen && editable ? 2 : 1 }}
        >
          <Box position="sticky" top="0px">
            <MainContentPanel
              title="Dettagli prenotazione"
              headerProps={headerProps}
            >
              <CartErrorPrinter />
              {renderInputGroup({
                cart,
                editable,
                awaitingPayment,
                onPaid,
                cartLoading,
              })}
            </MainContentPanel>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
          //style={{ order: smallScreen && editable ? 1 : 2 }}
        >
          <CartProductEditorPanel
            fab
            headerProps={headerProps}
            onCartProductAdd={onCartProductAdd}
            showProductsCost={smallScreen}
            cart={cart}
          />
        </Grid>
      </Grid>
    </CartCustomerEditor.Provider>
  );
}

export default memo(CartEditorPanel);
