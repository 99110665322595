import {
  Box,
  Collapse,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useState, ReactNode } from "react";
import { Image } from "../ImageGallery/defines";
import ImageGallery from "../ImageGallery";
import HulkButton from "../../Inputs/HulkButton";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  boldText: {
    fontWeight: "bold",
  },
  contentBoxWrapper: {
    marginBottom: theme.spacing(2),
  },
  propertyWrapper: {
    marginBottom: theme.spacing(1),
  },
}));

function Property({ name, value }) {
  const { boldText, propertyWrapper } = useStyles();
  return (
    <Box component="p" className={propertyWrapper}>
      <Typography component="span" variant="body1" className={boldText}>
        {name}:&nbsp;
      </Typography>
      <Typography component="span" variant="body1">
        {value}
      </Typography>
    </Box>
  );
}

function AdditionalData({ name, content, collapsible }) {
  const [collapsed, setCollapsed] = useState(true);
  const { propertyWrapper, boldText } = useStyles();
  const onToggleCollapsed = useCallback(
    () => setCollapsed((s) => !s),
    [setCollapsed]
  );
  return (
    <Box className={propertyWrapper}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        component="header"
      >
        <Typography
          component="span"
          variant="body1"
          gutterBottom
          className={boldText}
        >
          {name}
        </Typography>
        {collapsible && (
          <IconButton onClick={onToggleCollapsed}>
            {collapsed ? <ExpandMore /> : <ExpandLess />}
          </IconButton>
        )}
      </Box>
      {collapsible ? (
        <Collapse in={!collapsed} collapsedSize={0}>
          {content}
        </Collapse>
      ) : (
        content
      )}
    </Box>
  );
}

function Title({ name, mainCategory, cost }) {
  const { boldText } = useStyles();
  return (
    <Box component="header">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography component="span" variant="body1">
          {mainCategory}
        </Typography>
        <Typography component="span" variant="h6" className={boldText}>
          {cost}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Typography component="span" variant="h5" className={boldText}>
          {name}
        </Typography>
      </Box>
    </Box>
  );
}

/**
 * @typedef {{name: string, value: string|number}} Property
 * @typedef {{label: string, content?: ReactNode, collapsible?: boolean}} AdditionalData
 *
 * @typedef {{
 *      name: string,
 *      mainCategory?: string,
 *      cost?: string,
 *      description: string,
 *      properties?: Property[],
 *      additionalData?: AdditionalData[],
 *      imageContent?: ReactNode,
 *      images?: Image[],
 *      onCart: Function,
 *      onAdditional?: Function,
 *      additionalLabel?: string,
 *      cartLabel?: string,
 *      additionalContent?: ReactNode
 * }} HulkProductRendererProps
 */

/**
 * @param {HulkProductRendererProps} param0
 * @returns
 */
export default function HulkProductRenderer({
  name,
  mainCategory,
  cost,
  images,
  imageContent,
  description,
  properties,
  additionalData,
  onCart,
  onAdditional,
  additionalLabel,
  cartLabel,
  additionalContent
}) {
  const { boldText, contentBoxWrapper } = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        {images ? (
          <ImageGallery images={images} defaultSelected={images[0]} />
        ) : (
          imageContent
        )}
      </Grid>
      <Grid item xs={12} sm={6} component="section">
        <Box className={contentBoxWrapper}>
          <Title name={name} mainCategory={mainCategory} cost={cost} />
        </Box>
        <Box className={contentBoxWrapper}>
          <HulkButton
            variant="light"
            label={cartLabel || "Aggiungi al carrello"}
            onClick={onCart}
          />
        </Box>
        {onAdditional && additionalLabel && (
          <Box className={contentBoxWrapper}>
            <HulkButton
              variant="outlined"
              label={additionalLabel}
              onClick={onAdditional}
            />
          </Box>
        )}
        <Box className={contentBoxWrapper}>
          <Typography component="p" variant="body1">
            {description}
          </Typography>
        </Box>
        {properties && (
          <Box className={contentBoxWrapper}>
            {properties.map((args) => (
              <Property {...args} />
            ))}
          </Box>
        )}
        {additionalData &&
          additionalData.map((args) => (
            <Box component="section">
              <AdditionalData {...args} />
            </Box>
          ))}
      </Grid>
      {additionalContent && (
        <Grid item xs={12}>
          {additionalContent}
        </Grid>
      )}
    </Grid>
  );
}
