import React, { memo } from "react";
import GenericInput from "../GenericInput";
import Grid from "@material-ui/core/Grid";
import InputWrapperGroup from "../../_Auxiliary/InputWrapperGroup";
import { GenericForm } from "@italwebcom/react-generic-form";

const { useError } = GenericForm;

const ActualAddressInput = memo((props) => {
  return (
    <Grid item xs={12}>
      <InputWrapperGroup.InputWrapper
        id="address"
        label="Indirizzo"
        value={props.defaultValue}
        disabled={props.disabled}
      >
        <GenericInput {...props} />
      </InputWrapperGroup.InputWrapper>
    </Grid>
  );
});

const ActualGuestsInput = memo((props) => {
  return (
    <Grid item xs={12}>
      <InputWrapperGroup.InputWrapper
        id="guests"
        label="Ospiti"
        value={props.value && `${props.value} persone`}
        disabled={props.disabled}
      >
        <GenericInput
          {...props}
          value={Boolean(props.value) && props.value}
          inputProps={{ min: 1, step: 1 }}
          type="number"
        />
      </InputWrapperGroup.InputWrapper>
    </Grid>
  );
});

const ActualNotesInput = memo((props) => {
  const { error, message } = useError("notes");
  return (
    <InputWrapperGroup.InputWrapper
      id="notes"
      label="Note aggiuntive"
      value={props.defaultValue}
      disabled={props.disabled}
    >
      <GenericInput
        {...props}
        multiline
        minRows={5}
        error={error}
        helperText={message}
      />
    </InputWrapperGroup.InputWrapper>
  );
});

export { ActualAddressInput, ActualGuestsInput, ActualNotesInput };
