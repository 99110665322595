import { useMemo, useCallback } from "react";
import {
  useStorage,
  useStorageSet,
} from "../../components/misc/StorageProvider";
import { useRuntimeContext } from "../../components/misc/RuntimeProvider";
import { useSetCredentials } from "@italwebcom/react-auth-provider";
import useAuthContext from "../../components/authentication/hooks/useAuthContext";
import { CartWrapper } from "../../__domain/__mock/wrappers";
import { setStorageToken } from "../../misc/setStorageToken";
import { headerManager } from "@italwebcom/augmented-fetch";

function makeVals(data, a, b) {
  let out = {};
  out[a] = data[a];
  out[b] = data[b];
  return out;
}

function readVals(names, onGet) {
  let out = {};
  names.forEach((n) => {
    out[n] = onGet(n);
  });
  return out;
}

/**
 * Executes initial operations to init temp cart data and/or user credentials before navigating to home view.
 *
 * !Requires a StorageProvider ancestor!
 * !Requires a RuntimeContextProvider ancestor!
 * !Requires an AuthenticationProvider ancestor!
 *
 * @param {{cartWrapper: CartWrapper}} param0
 * @returns
 */
function useInitialOperations({ cartWrapper }) {
  const { cartCodeName, cartIdName } = useRuntimeContext();
  const { onSet, onGet } = useStorage();
  const { manager } = useAuthContext();
  const setCredentials = useSetCredentials();
  const names = useMemo(
    () => ["token", cartCodeName, cartIdName],
    [cartCodeName, cartIdName]
  );
  const onStorageSet = useStorageSet();

  return useCallback(
    /**
     *
     * @returns {Promise<number>}
     */
    async () => {
      const values = readVals(names, onGet);

      /* preloads discovery document to avoid Safari popup blocking */
      await manager.loadDiscovery();

      if (values.token) {
        console.log(
          "useInitialOperations: found token in cache, validating & refreshing."
        );
        try {
          /* 
            if a token is present -> set as cached for manager, fetch user info and set credentials (no user prompt)
          */
          manager.setCachedToken(values.token);
          if (manager.isCachedTokenFresh()) {
            console.log(
              "useInitialOperations: cached token is fresh, proceeding."
            );
            let out = await manager.getTokenAndUserInfo(undefined, true);

            if (out.token && out.userInfo && !out.userInfo.error) {
              setStorageToken({
                onStorageSet: onSet,
                currentToken: out.token,
                oldTokenValues: values.token,
              });
              setCredentials(out.userInfo);
              return;
            } else {
              console.log(
                "useInitialOperations: error during userInfo fetch, aborting."
              );
              onSet("token", null);
            }
          } else {
            console.log(
              "useInitialOperations: cached token has expired, aborting."
            );
            onSet("token", null);
          }
        } catch (err) {
          console.log(
            "useInitialOperations: error occured while getting token."
          );
          /* assuming an error is thrown (e.g. if user closed auth window), reset manager cache and continue */
          manager.reset();
        }
      }
      /*
        if either the token or the userInfo is missing (i.e. user isn't authenticated or token/refresh token isn't valid):
      
        1) temp cart values are in storage -> nothing more to do, invoke onDone
        2) temp cart values not in storage, then:
      
        - make temp cart
        - set storage values
      */
      if (values[cartCodeName] && values[cartIdName]) {
        console.log("useInitialOperations: temp cart values found.");
        try {
          /* verify cart validity by making a read request */
          let r = cartWrapper
            .execute("read", { id: values[cartIdName] })
            .request();
          r.withDecorator({
            name: "code-setter",
            process: (init) => headerManager(init).set("x-cart-code", values[cartCodeName]).get(),
          });
          await r.json();
          return values[cartIdName];
        } catch (err) {
          //in case of error, proceed with makeTemp request
        }
      }

      console.log(
        "useInitialOperations: temp cart values missing, making new cart."
      );
      let out = await cartWrapper.execute("makeTemp", {}, false).json();
      const { data } = out;
      onStorageSet(makeVals(data, cartCodeName, cartIdName));
      return data[cartIdName];
    },
    [cartCodeName, cartIdName, cartWrapper, onSet, onGet, names, onStorageSet]
  );
}

export default useInitialOperations;
