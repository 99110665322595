import React, { memo } from "react";
import useFormData from "../hooks/useFormData";
import useFormConfiguration from "../hooks/useFormConfiguration";

/**
 * @param {{label: string, disabled?: boolean, ButtonProps?: any}} param0
 */
function Submit({ label, disabled, ButtonProps }) {
  const { submittable, loading } = useFormData();
  const { SubmitButton } = useFormConfiguration();
  return (
    <SubmitButton
      label={label}
      disabled={!submittable || disabled || loading}
      loading={loading}
      {...(ButtonProps || {})}
    />
  );
}

export default memo(Submit);