import { Cart, cartType } from "../../../../model";
import {formatMessageDate,} from "../../../../functions";
//import CartProductCustomerList from "../../_Related/CartProducts/CartProductCustomerList";

function printType(v) {
  switch (v) {
    case "delivery":
      return "Consegna";
    case "default":
      return "Asporto";
    default:
      return "Tavolo";
  }
}

/**
 * @typedef {(cart: Cart) => string} CartDataRenderer
 * @type {{
 *      guests: CartDataRenderer,
 *      deliveryCost: CartDataRenderer,
 *      deliveryZone: CartDataRenderer,
 *      dateAndHour: CartDataRenderer,
 *      cartProducts: CartDataRenderer,
 *      type: CartDataRenderer,
 *      cost: CartDataRenderer
 * }}
 */
const renderers = {
  guests: (cart) => {
    if (cart.type === cartType.in_place) {
      return ` per ${cart.guests || "?"} persone`;
    }
    return "";
  },
  deliveryCost: (cart) => {
    if (cart.deliveryCost) {
      return cart.deliveryCost.cost || cart.deliveryCost;
    }
    return "";
  },
  cartPaymentType: (cart) => {
    if(cart.cartPaymentType) {
      return cart.cartPaymentType === "paypal" ? "Paypal" : "Contanti"
    }
    return "---";
  },
  deliveryZone: (cart) =>
    `Consegna a ${cart.deliveryZone.name} presso ${cart.address}`,
  dateAndHour: (cart) =>
    `${formatMessageDate(cart.date, "DD MMM YYYY")} alle ${cart.time}`,
  cartProducts: (cart, editable) => /*<CartProductCustomerList cart={cart} readOnly={!editable} />*/ null,
  type: (cart) => `${printType(cart.type)}${renderers.guests(cart)}`,
  cost: (cart) =>
    `${cart.totalCost || cart.cost} €${renderers.deliveryCost(cart)}`,
};

export default renderers;
