import { createContext } from "react";
import { WrapperComponent } from "./defines";

/**
 * @template T
 * @type {{
 *      activeTriggers: string[],
 *      triggers: string[],
 *      onEvaluateTriggers: (t: T) => void,
 *      DefaultWrapperComponent: WrapperComponent
 * }}
 */
const args = {
  activeTriggers: [],
  triggers: [],
  onEvaluateTriggers: () => null,
  DefaultWrapperComponent: () => null
};
export default createContext(args);
