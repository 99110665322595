import CryptoJS from "crypto-js";

const defaultAlphabet =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";

function base64URL(string) {
  return string
    .toString(CryptoJS.enc.Base64)
    .replace(/=/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_");
}

function randString(len, alphabet) {
  alphabet = alphabet || defaultAlphabet;
  let out = "";
  for (let i = 0; i < len; i++) {
    let randInd = Math.floor(Math.random() * alphabet.length);
    out += alphabet.charAt(randInd);
  }
  return out;
}

export default function pkceGen() {
  const codeVerifier = randString(128);
  const codeChallenge = base64URL(CryptoJS.SHA256(codeVerifier));
  return { codeChallenge, codeVerifier };
}
