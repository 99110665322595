function makePlaceholders(n) {
  let out = [];
  for (let i = 0; i < n; i++) {
    out.push({
      id: i,
      title: "placeholder",
      content: "placeholder",
      date: "2022-11-11T11:20:00.000Z",
    });
  }
  return out;
}

export default makePlaceholders;