import React, { ComponentType, memo } from "react";
import GenericInput from "../GenericInput";
import { CartType } from "../../../../../../__domain/model";
import {fieldNames} from "../../fields";

/**
 * @typedef {{value: CartType, onChange: (value: CartType) => void}} TypeInputComponentProps
 * @typedef {ComponentType<TypeInputComponentProps>} TypeInputComponent
 */

/**
 * @returns
 * @param {{InputComponent: TypeInputComponent, testID?: string}} param0
 */
function TypeInput({ InputComponent, testID }) {
  return (
    <GenericInput
      field={fieldNames.type}
      InputComponent={InputComponent}
      InputProps={{ "data-testid": testID }}
      controlled
    />
  );
}

export default memo(TypeInput);