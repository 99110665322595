import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactDatePicker from "react-datepicker";
import dayjs from "dayjs";
import it from "date-fns/locale/it";
import { makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  externalWrapper: {
    position: "relative",
    display: "inline-block",
  },
  loaderWrapper: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

/**
 * @typedef {{
 *    defaultValue: string,
 *    onChange: (value: string) => void,
 *    minDate?: string,
 *    maxDate?: string,
 *    onMonthChange: (value: string) => void,
 *    excludeDates?: string[],
 *    loading?: boolean
 * }} DatePickerProps
 */

function toDate(v, f) {
  return new Date(dayjs(v).format(f || "YYYY-MM-DD"));
}

function initDateState(v, f) {
  if (v) {
    return toDate(v, f);
  }
}

function fromDate(d, f) {
  return new dayjs(d).format(f || "YYYY-MM-DD");
}

/**
 * @returns
 * @param {DatePickerProps} param0
 */
function DatePicker({
  onChange,
  defaultValue,
  minDate,
  maxDate,
  onMonthChange,
  excludeDates,
  loading,
}) {
  const { externalWrapper, loaderWrapper } = useStyles();
  const [date, setDate] = useState(initDateState(defaultValue));

  const inited = useRef(false);
  const daMinDate = useMemo(() => toDate(minDate), [minDate]);
  const daMaxDate = useMemo(() => {
    if (maxDate) {
      return toDate(maxDate);
    }
    return null;
  }, [maxDate]);

  const actualExcludeDates = useMemo(
    () => (excludeDates ? excludeDates.map((el) => toDate(el)) : []),
    [excludeDates]
  );

  const onActualChange = useCallback(
    (d) => {
      setDate(d);
      onChange(fromDate(d));
    },
    [setDate, onChange]
  );

  const onActualMonthChange = useCallback(
    (d) => {
      if (onMonthChange) {
        onMonthChange(fromDate(d, "YYYY-MM"));
      }
    },
    [onMonthChange]
  );

  useEffect(() => {
    if (!inited.current) {
      inited.current = true;
      let d;
      if (date) {
        d = fromDate(date, "YYYY-MM");
      } else {
        d = fromDate(undefined, "YYYY-MM");
      }
      onActualMonthChange(d);
    }
  }, [onActualMonthChange, date, inited]);

  return (
    <Box className={externalWrapper}>
      <Box className={loaderWrapper} zIndex={loading ? 2 : 0}>
        {loading && <CircularProgress size={20} />}
      </Box>
      <Box zIndex={1} style={{ opacity: loading ? 0.5 : 1 }}>
        <ReactDatePicker
          selected={date}
          onChange={onActualChange}
          minDate={daMinDate}
          maxDate={daMaxDate}
          onMonthChange={onActualMonthChange}
          locale={it}
          inline
          disabledKeyboardNavigation
          excludeDates={actualExcludeDates}
        />
      </Box>
    </Box>
  );
}

export default memo(DatePicker);
