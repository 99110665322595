import React, { memo, useMemo, ReactNode } from "react";
import useFilteredTag from "../hooks/useFilteredTag";
import { ProductProvider } from "../../ProductProvider";
import SectionContext from "../contexts/section";

/**
 * @param {{filterValue: string, children: ReactNode[]}} param0
 * @returns
 */
function SectionProvider({ filterValue, children }) {
  const { tag, loading } = useFilteredTag(filterValue);
  const actualFilters = useMemo(
    () => (tag ? [{ attribute: "categories", value: tag.id }] : []),
    [tag]
  );
  return (
    <SectionContext.Provider value={{ tag, loading }}>
      {tag ? (
        <ProductProvider filters={actualFilters} lazyCountRecompute addCart>
          {children}
        </ProductProvider>
      ) : (
        children
      )}
    </SectionContext.Provider>
  );
}

export default memo(SectionProvider);
