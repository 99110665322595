import {
  DiscoveryDocument,
  OAuth2Response,
  PasswordGrantData,
  OAuth2Config,
  OnFetch,
  WindowProcessor,
  Btoa,
  PKCEGenerator,
} from "../defines";
import "regenerator-runtime/runtime";
import { urlencode } from "../utils";
import generatePkce from "../pkce/generatePkce";

const errors = {
  genericError: "error",
  abortedUserClosedWindow: "user_closed_window",
  missingParameters: "missing_parameters",
};

/**
 * @param {PasswordGrantData} data
 * @param {OAuth2Config} config
 */
function makePasswordGrantBody(data, config) {
  const { username, password } = data;
  const { clientId } = config;
  return urlencode({
    grant_type: "password",
    client_id: clientId,
    username,
    password,
  });
}

/**
 * @param {PasswordGrantData} data
 * @param {OAuth2Config} config
 */
function makeAccessGrantBody(data, config) {
  const { code, code_verifier } = data;
  const { clientId, redirectUri } = config;
  return urlencode({
    grant_type: "authorization_code",
    client_id: clientId,
    redirect_uri: redirectUri,
    code,
    code_verifier,
  });
}

/**
 * @param {string} refreshToken
 * @param {OAuth2Config} config
 */
function makeRefreshTokenBody(refreshToken, config) {
  const { clientId, redirectUri } = config;
  return urlencode({
    grant_type: "refresh_token",
    client_id: clientId,
    redirect_uri: redirectUri,
    refresh_token: refreshToken,
  });
}

/**
 * @param {string} authUrl
 * @param {OAuth2Config} config
 * @param {string} codeChallenge
 */
function makeAuthorizationUrl(authUrl, config, codeChallenge) {
  const { clientId, redirectUri } = config;
  let out = `${authUrl}?client_id=${clientId}&response_type=code&grant_type=authorization_code&redirect_uri=${redirectUri}`;
  if (codeChallenge) {
    out += `&code_challenge=${codeChallenge}&code_challenge_method=S256`;
  }
  return out;
}

/**
 * @return {string}
 * @param {OAuth2Config} config
 */
function makeBasicAuth(config, btoa) {
  const { clientId, clientSecret } = config;
  const enc = btoa(`${clientId}:${clientSecret}`);
  return `Basic ${enc}`;
}

/**
 * @returns
 * @param {string} token
 */
function makeBearerAuth(token) {
  return `Bearer ${token}`;
}

/**
 * Handles response from requests.
 *
 * @returns {Promise<OAuth2Response>}
 * @param {Response} response
 */
function handleResponse(response) {
  /*console.log(
    `OAuth2Client.handleResponse: got response with {status = ${response.status}, statusText = ${response.statusText}}`
  );*/
  if (response.status === 200) {
    return response.json();
  } else {
    throw {
      status: "error",
      statusText: response.statusText,
      statusCode: response.status,
    };
  }
}

/**
 * Helper class for OAuth2Client. Stores the DiscoveryDocument instance after
 * the first retrieval and provides helper function for the various use
 * cases of the OAuth2Client.
 *
 */
export class OAuth2ClientRequest {
  /**
   * @type {OAuth2Client}
   */
  _oauth2Client;

  /**
   * @type {string}
   */
  _clientId;

  /**
   * @type {string}
   */
  _clientSecret;

  /**
   * @type {DiscoveryDocument}
   */
  _discoveryDocument;

  /**
   * @type {string}
   */
  _baseUrl;

  /**
   * @param {OAuth2Client} oauth2Client
   */
  constructor(oauth2Client) {
    this._oauth2Client = oauth2Client;
  }

  /**
   * @returns
   * @param {string} c
   */
  withClientId(c) {
    this._clientId = c;
    return this;
  }

  /**
   * @returns
   * @param {string} c
   */
  withClientSecret(c) {
    this._clientSecret = c;
    return this;
  }

  /**
   * @param {string} c
   * @returns
   */
  withBaseUrl(c) {
    this._baseUrl = c;
    return this;
  }

  /**
   * @param {string} c
   * @returns
   */
  withRedirectUri(c) {
    this._redirectUri = c;
    return this;
  }

  /**
   * @param {DiscoveryDocument} dDoc
   * @returns
   */
  withDiscoveryDocument(dDoc) {
    this._discoveryDocument = dDoc;
    return this;
  }

  /**
   * @returns
   */
  getConfig() {
    return {
      clientId: this._clientId,
      clientSecret: this._clientSecret,
      redirectUri: this._redirectUri,
    };
  }

  /**
   * @returns
   */
  async discoveryDocument() {
    if (!this._discoveryDocument) {
      const dDoc = await this._oauth2Client.discoveryDocument(this._baseUrl);
      this._discoveryDocument = dDoc;
    }
    return this._discoveryDocument;
  }

  /**
   * @param {PasswordGrantData} data
   * @returns
   */
  async passwordGrant(data) {
    const dDoc = await this.discoveryDocument();
    return this._oauth2Client.passwordGrant(data, this.getConfig(), dDoc);
  }

  /**
   * @param {boolean} pkce
   * @returns
   */
  async authorizationGrant(pkce) {
    const dDoc = await this.discoveryDocument();
    return this._oauth2Client.authorizationGrant(this.getConfig(), dDoc, pkce);
  }

  /**
   * @param {boolean} pkce
   * @returns
   */
  async requestAuthCode(pkce) {
    const dDoc = await this.discoveryDocument();
    return this._oauth2Client.requestAuthCode(this.getConfig(), dDoc, pkce);
  }

  /**
   * @returns
   * @param {string} token
   */
  async requestUserInfo(token) {
    const dDoc = await this.discoveryDocument();
    return this._oauth2Client.requestUserInfo(dDoc, token);
  }

  /**
   * @param {string} authorizationCode
   * @param {string} codeVerifier
   * @returns
   */
  async tokenRequest(authorizationCode, codeVerifier) {
    const reqBody = makeAccessGrantBody(
      {
        code: authorizationCode,
        code_verifier: codeVerifier,
      },
      this.getConfig()
    );
    const dDoc = await this.discoveryDocument();
    return this._oauth2Client.tokenRequest(dDoc, reqBody, this.getConfig());
  }

  /**
   * @param {string} refreshToken
   * @returns
   */
  async refreshToken(refreshToken) {
    const reqBody = makeRefreshTokenBody(refreshToken, this.getConfig());
    const dDoc = await this.discoveryDocument();
    return this._oauth2Client.refreshToken(dDoc, reqBody, this.getConfig());
  }
}

/**
 * Class used to execute login related to:
 * - discovery document fetch from the OAuth2 auth. server
 * - access token retrieval via password or authorization code grant
 * - access token refresh via corresponding grant type
 */
export default class OAuth2Client {
  /**
   * @type {OnFetch}
   */
  _onFetch;

  /**
   * @type {WindowProcessor}
   */
  _windowProcessor;

  /**
   * @type {Btoa}
   */
  _btoa;

  /**
   * @type {PKCEGenerator}
   */
  _pkceGenerator;

  /**
   * @param {OnFetch} onFetch
   * @param {WindowProcessor} windowProcessor
   * @param {Btoa} btoa
   * @param {PKCEGenerator} pkceGenerator
   */
  constructor(onFetch, windowProcessor, btoa, pkceGenerator) {
    this._onFetch = onFetch;
    this._windowProcessor = windowProcessor;
    this._btoa = btoa;
    this._pkceGenerator = pkceGenerator;
  }

  /**
   * @param {string} uri
   * @param {string} body
   * @param {OAuth2Config} config
   * @returns
   */
  async postRequest(uri, body, config) {
    const { clientId, clientSecret } = config;
    /*console.log(
      `OAuth2Client.postRequest: uri = ${uri}, clientId = ${clientId}, clientSecret = ${clientSecret}`
    );*/
    if (clientId && clientSecret && uri) {
      const response = await this._onFetch(uri, {
        method: "POST",
        body,
        headers: {
          Authorization: makeBasicAuth(config, this._btoa),
          "content-type": "application/x-www-form-urlencoded",
        },
      });
      return handleResponse(response);
    }
    throw {
      status: "error",
      statusText: errors.missingParameters,
      statusCode: 400,
    };
  }

  /**
   * @param {DiscoveryDocument} discoveryDocument
   * @param {string} body
   * @param {OAuth2Config} config
   * @returns
   */
  async tokenRequest(discoveryDocument, body, config) {
    return this.postRequest(discoveryDocument.token_endpoint, body, config);
  }

  /**
   * @param {DiscoveryDocument} discoveryDocument
   * @param {string} body
   * @param {OAuth2Config} config
   * @returns
   */
  async refreshToken(discoveryDocument, body, config) {
    return this.postRequest(
      discoveryDocument.refresh_endpoint || discoveryDocument.token_endpoint,
      body,
      config
    );
  }

  /**
   * Requests user info from corresponding endpoint using previously retrieved access token.
   *
   * @param {DiscoveryDocument} discoveryDocument
   * @param {string} token
   * @return {Promise<OAuth2Response | Record<string, any>>}
   */
  async requestUserInfo(discoveryDocument, token) {
    const response = await this._onFetch(discoveryDocument.userinfo_endpoint, {
      headers: {
        Authorization: makeBearerAuth(token),
      },
    });
    return handleResponse(response);
  }

  /**
   * Fetches discoveryDocument from base endpoint.
   *
   * @param {OAuth2Config} config
   * @returns {Promise<DiscoveryDocument>}
   */
  async discoveryDocument(baseUrl) {
    //console.log(`OAuth2Client.discoveryDocument: fetching discovery document from URL ${baseUrl}`);
    const response = await this._onFetch(baseUrl);
    return handleResponse(response);
  }

  /**
   * Requests token to corresponding endpoint with password grant type.
   *
   * @param {PasswordGrantData} data
   * @param {OAuth2Config} config
   * @param {DiscoveryDocument} discoveryDocument
   *
   * @returns {Promise<OAuth2Response>}
   */
  async passwordGrant(data, config, discoveryDocument) {
    return this.tokenRequest(
      discoveryDocument,
      makePasswordGrantBody(data, config),
      config
    );
  }

  /**
   * Requests authorization code by making request to auth endpoint and
   * waiting for code (via message) by corresponding window.
   *
   * @return {Promise<OAuth2Response>}
   * @param {OAuth2Config} config
   * @param {DiscoveryDocument} discoveryDocument
   * @param {boolean} pkce
   */
  async requestAuthCode(config, discoveryDocument, pkce) {
    const { clientId, clientSecret, noWindowUnloadListener } = config;

    if (clientId && clientSecret && discoveryDocument) {
      const { codeChallenge, codeVerifier } = this._pkceGenerator();
      const wProc = this._windowProcessor;

      /* auth url with redirect uri - optionally includes pkce codeChallenge */
      const authUrl = makeAuthorizationUrl(
        discoveryDocument.authorization_endpoint,
        config,
        pkce ? codeChallenge : null
      );

      //console.log(`opening window to ${authUrl}`);
      
      /* opens window to auth endpoint */
      let w = wProc.open(authUrl);

      let p = new Promise((res, rej) => {

        let t;
        function onUnload() {
          if(w.closed) {
            console.log("aborting code request");
            t && clearInterval(t);
            res({
              status: "aborted",
            });
          }
        }

        if(!noWindowUnloadListener) {
          /*console.log("adding onUnload listener (1)");
          w.addEventListener("load", () => {
            console.log("adding onUnload listener (2)");
            w.addEventListener("unload", onUnload);
          })*/

          /* amazingly shitty workaround, it's so bad it screams */
          //wProc.addEventListener("focus", onUnload);
          t = setInterval(onUnload, 750);
        }

        /* listens for message sending auth code after redirect to given redirectUri */
        wProc.addEventListener("message", (evt) => {
          let { data } = evt;
          data = JSON.parse(data);
          console.log(`got message with data ${JSON.stringify(evt.data)}`);
          if(!noWindowUnloadListener) {
            console.log("removing onUnload listener");
            //w.removeEventListener("unload", onUnload);
            //wProc.removeEventListener("focus", onUnload);
            t && clearInterval(t);
          }
          w.close();
          if (data && data.code) {
            res({
              status: "success",
              authorization_code: data.code,
              code_verifier: pkce ? codeVerifier : undefined,
            });
          } else {
            res({
              status: "error",
              statusText: errors.genericError,
              statusCode: 400,
            });
          }
        });
      });
      return p;
    }

    /* if params are missing -> error */
    throw {
      status: "error",
      statusText: errors.missingParameters,
      statusCode: 400,
    };
  }

  /**
   * Full authorization code grant flow, with:
   * - authorization code request via requestAuthCode
   * - token request with code parameter via tokenRequest
   *
   * @return {Promise<OAuth2Response>}
   * @param {OAuth2Config} config
   * @param {DiscoveryDocument} discoveryDocument
   * @param {boolean} pkce
   */
  async authorizationGrant(config, discoveryDocument, pkce) {
    const data = await this.requestAuthCode(config, discoveryDocument, pkce);
    const { status, authorization_code, code_verifier } = data;
    if (status === "success") {
      const reqBody = makeAccessGrantBody(
        {
          code: authorization_code,
          code_verifier: pkce ? code_verifier : undefined,
        },
        config
      );
      return this.tokenRequest(discoveryDocument, reqBody, config);
    } else {
      return data;
    }
  }
}

/**
 * Builder function for the OAuth2Client class.
 *
 * @param {OnFetch} onFetch
 * @param {WindowProcessor} windowProcessor
 * @param {Btoa} btoa
 * @param {PKCEGenerator} pkceGenerator
 * @returns
 */
export function client(onFetch, windowProcessor, btoa, pkceGenerator) {
  pkceGenerator = pkceGenerator || generatePkce;
  return new OAuth2Client(onFetch, windowProcessor, btoa, pkceGenerator);
}

/**
 * Builder function for the OAuth2ClientRequest class.
 *
 * @param {OAuth2Client} client
 * @param {OAuth2Config} config
 * @returns {OAuth2ClientRequest}
 */
export function request(client, config) {
  const { baseUrl, clientId, clientSecret, redirectUri } = config;
  return new OAuth2ClientRequest(client)
    .withBaseUrl(baseUrl)
    .withClientId(clientId)
    .withRedirectUri(redirectUri)
    .withClientSecret(clientSecret);
}
