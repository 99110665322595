import React, { memo, useEffect, useRef } from "react";
import { EntityManager } from "@italwebcom/react-entity-manager";
import { useDefaultComponents } from "../../../../../misc/DefaultComponentsProvider";
import CollectionRenderer from "../../CollectionRenderer";

function CommonProvider({ filters, children, onFetch, onSave, onSuccess, onError }) {
  const { fetchArgsProcessor, defaultCountGetter } = useDefaultComponents();

  const r = useRef();
  useEffect(() => {
    filters && r.current && r.current.setFilters(filters);
  }, [filters, r]);

  return (
    <EntityManager.Crud
      fetchArgsProcessor={fetchArgsProcessor}
      countGetter={defaultCountGetter}
      onFetch={onFetch}
      onSave={onSave}
      initialFilters={filters}
      initialItemsPerPage={1}
      initialSorter={{}}
      onSuccess={onSuccess}
      onError={onError}
      ref={r}
    >
      <EntityManager.Components.CollectionRenderer
        Component={CollectionRenderer}
        ComponentProps={{ children }}
        renderOnError
      />
    </EntityManager.Crud>
  );
}

export default memo(CommonProvider);
