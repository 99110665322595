import React, { memo } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

/**
 * @param {InputComponentProps} param0
 */
function TextInputComponent({ onChange, defaultValue, disabled, loading }) {
  return (
    <TextField
      defaultValue={defaultValue}
      type="text"
      placeholder={defaultValue}
      onChange={onChange}
      fullWidth
      variant="outlined"
      hiddenLabel
      InputProps={{
        startAdornment: loading && <InputAdornment>
          <CircularProgress size={14} />
        </InputAdornment>
      }}
      disabled={disabled || loading}
    />
  );
}

export default memo(TextInputComponent);
