import { ProductCategory } from "../../model";
import categories from "./categories";
import products from "./products";
import wrappers from "../hateoas";

/**
 * @type {ProductCategory[]}
 */
const productCategories = [];

for (let i = 0; i < products.length; i++) {
  for(let v = 0; v < 3; v++) {
    let c = v + (i % 2) * 3;
    productCategories.push(
      wrappers.productCategory({
        category: c,
        product: i,
        ...categories[c],
      })
    );
  }
}

export default productCategories;
