import React, { memo, useCallback, useMemo } from "react";
import Renderer from "../Renderer";
import { makeStyles } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import Box from "@material-ui/core/Box";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import { EditorProps } from "@italwebcom/react-entity-manager";
import { Product } from "@italwebcom/anema-react-components/Products";
import clsx from "clsx";
import MainContentPanel from "../../../../MainContentPanel";
import useRuntimeContext from "../../../../../contexts/Runtime/useRuntimeContext";

const s = makeStyles((t) => ({
  contentWrapper: {
    width: "400px",
  },
  popover: {
    "&.open": {
      backgroundColor: "rgb(0, 0, 0, 0.5)",
    },
    backgroundColor: "transparent",
    transitionDuration: "500ms",
    transitionProperty: "background-color",
  },
}));

function shouldAnchorBottom(anchorEl) {
  let y = anchorEl.getBoundingClientRect().top;
  return y < window.innerHeight * 0.5;
}

/**
 * @typedef {{anchorEl: HTMLElement}} AdditionalProps
 *
 * @returns
 * @param {EditorProps<Product> & AdditionalProps} param0
 */
function PopoverRenderer({ entity, onResetSelectedEntity, anchorEl }) {
  const { contentWrapper, popover } = s();
  const { stylings } = useRuntimeContext();
  const isOpen = Boolean(anchorEl);
  const closeButton = (
    <Button variant="text" color="secondary" onClick={onResetSelectedEntity}>
      Chiudi
    </Button>
  );
  const onActualPopoverClose = useCallback(
    (evt, reason) => reason === "escapeKeyDown" && onResetSelectedEntity(),
    [onResetSelectedEntity]
  );
  const anchorBottom = useMemo(
    () => anchorEl && shouldAnchorBottom(anchorEl),
    [anchorEl]
  );
  return (
    <Popover
      anchorEl={anchorEl}
      anchorReference="anchorEl"
      open={isOpen}
      onClose={onActualPopoverClose}
      anchorOrigin={{
        vertical: anchorBottom ? "bottom" : "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: anchorBottom ? "top" : "bottom",
        horizontal: "center",
      }}
      TransitionComponent={Fade}
      className={clsx([isOpen && "open", popover])}
    >
      {entity && (
        <Box className={contentWrapper}>
          <MainContentPanel
            title={entity.name}
            headerProps={{
              ...stylings.home.productDetailPanelHeaderProps,
              sideContent: closeButton,
            }}
          >
            <Renderer product={entity} />
          </MainContentPanel>
        </Box>
      )}
    </Popover>
  );
}

export default memo(PopoverRenderer);
