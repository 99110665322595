import { Filter, Sorter, FetchResult, EntityMatcher } from "../defines";

/**
 * @template T
 * @template Sorters
 * @template Indices
 */
export default class Repository {
  /**
   * @type {T[]}
   */
  _elements;

  /**
   * @type {EntityMatcher<T>}
   */
  _matcher;

  /**
   * @type {boolean}
   */
  _error;

  /**
   * @param {T[]} elements
   * @param {EntityMatcher<T>} matcher
   */
  constructor(elements, matcher) {
    this._elements = elements;
    this._matcher = matcher;
  }

  /**
   * @return {number}
   * @param {T} element
   */
  indexOf(element) {
    let match = this._elements.filter((e) => this._matcher(element, e));
    if (match.length) {
      return this._elements.indexOf(match[0]);
    }
    return -1;
  }

  /**
   * @return {Promise<any>}
   * @param {T} element
   */
  save(element) {
    if (this._error) {
      return Promise.reject({ message: "save_error" });
    }
    let ind = this.indexOf(element);
    if (ind !== -1) {
      this._elements[ind] = element;
    } else {
      this._elements.push(element);
    }
    return Promise.resolve();
  }

  /**
   * @return {Promise<any>}
   * @param {T} element
   */
  delete(element) {
    if (this._error) {
      return Promise.reject({ message: "delete_error" });
    }
    let ind = this.indexOf(element);
    if (ind !== -1) {
      this._elements.splice(ind, 1);
    }
    return Promise.resolve();
  }

  /**
   * @param {{
   *      filters: Filter<Indices>[],
   *      sorter?: Sorter<Sorters>,
   *      start?: number,
   *      end?: number
   * }} param0
   * @return {Promise<FetchResult<T>>}
   */
  fetch({ filters, sorter, start, end }) {
    let out;
    let filtered = [...this._elements];
    if (this._error) {
      return Promise.reject({ message: "fetch_error" });
    } else {
      for (let filter of filters) {
        filtered = filtered.filter((e) => e[filter.attribute] === filter.value);
      }
      out = filtered;
      if (sorter) {
        out = out.sort((e1, e2) => {
          let { attribute: a, direction: d } = sorter;
          d = d || "asc";
          if (e1[a] < e2[a]) {
            return d === "desc" ? 1 : -1;
          } else {
            return d === "desc" ? -1 : 1;
          }
        });
      }
      if (start !== undefined && end !== undefined) {
        out = out.slice(start, end + 1);
      }
      return Promise.resolve({
        data: out,
        count: filtered.length,
      });
    }
  }
}
