import { withDefaultHateoas } from "../auxiliary/getDefaultHateoasData"
import imageUrls from "../../auxiliary/imageData";
import links from "../../links";
import { Tag, TagAttributes } from "../../../model";

/**
 * @param {TagAttributes} tagData 
 * @return {Tag}
 */
export default function tagHateoasWrapper(tagData) {
    let w = withDefaultHateoas(links.tag, tagData);
    w.mainImageUrl = imageUrls[w.id%(imageUrls.length)];
    return w;
}