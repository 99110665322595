import React, {ReactNode} from "react";
import { Box, Typography } from "@material-ui/core";
import useStyles from "../../styles";

/**
 * @param {{title: string, subtitle: string, action: ReactNode}} param0 
 * @returns 
 */
export default function SectionTitle({ title, subtitle, action, className }) {
  const { bold } = useStyles();
  return (
    <Box
      component="header"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      className={className}
    >
      <Box component="section">
        <Typography component="h4" variant="h5" className={bold}>
          {title}
        </Typography>
        <Typography component="h6" variant="caption">
          {subtitle}
        </Typography>
      </Box>
      <Box component="aside">{action}</Box>
    </Box>
  );
}
