import { Conversations } from "@italwebcom/anema-react-components";
import React, { memo } from "react";
import { NonWrappedDefaultErrorPrinter } from "../../../../_auxiliary/DefaultErrorPrinter";

const { useConversationAndMessages } = Conversations.hooks;

function ConversationErrorPrinter() {
  const { conversationErrored, onRefreshConversation } =
    useConversationAndMessages();
  if (conversationErrored) {
    return (
      <NonWrappedDefaultErrorPrinter onRefreshAll={onRefreshConversation} />
    );
  }
  return null;
}

export default memo(ConversationErrorPrinter);
