import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  image: {
    display: "block",
    maxWidth: "100%",
    maxHeight: theme.spacing(6),
  },
  externalWrapper: {
    paddingTop: theme.spacing(1),
    //paddingBottom: theme.spacing(1)
  },
  logo: {
    marginLeft: theme.spacing(1),
  },
  bar: {
    borderWidth: 0,
    backgroundColor: "white",
  },
  title: {
    fontWeight: "bold",
    color: theme.palette.primary["dark"],
  },
  subtitle: {
    color: theme.palette.primary["main"],
  },
  logoOnSmall: {
    margin: "auto"
  }
}));

export default useStyles;
