import React, { memo, useEffect, useMemo, useState } from "react";
import { TextField, TextFieldProps } from "@material-ui/core";
import { useValidator, ValidationResult, ValidationRule } from "@italwebcom/custom-react-hooks";

/**
 * @typedef {"value"} ValidatableTextFieldDummyAttribute
 * @typedef {{
 *      validator: RegExp | Function,
 *      label?: string,
 *      invalidMessage: string,
 *      priority?: number
 * }} ValidatableTextFieldValidationRule
 * @typedef {{
 *      validationRules: ValidatableTextFieldValidationRule[],
 *      onValueChange: (value: string, validationResult: ValidationResult) => void,
 *      testID?: string,
 *      printError?: boolean,
 *      onlyPrintErrorWithValue?: boolean
 * }} ValidatableTextFieldAdditionalProps
 * @typedef {TextFieldProps & ValidatableTextFieldAdditionalProps} ValidatableTextFieldProps
 **/


/**
 * @returns {ValidationRule<ValidatableTextFieldDummyAttribute>}
 * @param {ValidatableTextFieldValidationRule} param0
 */
function makeInnerValidationRule({priority, label, invalidMessage, validator}) {
    return (
        {
            priority: priority || 0,
            label,
            invalidMessage,
            validator,
            attribute: "value",
            type: "attribute"
        }
    );
}

function is(v) {
    return typeof(v) !== undefined && (!(typeof(v) === "string") || v.length);
}

function getTextFieldProps(props) {
    const outProps = {...props};
    delete outProps.validationRules;
    delete outProps.onValueChange;
    delete outProps.testID;
    delete outProps.onlyPrintErrorWithValue;
    delete outProps.printError;
    delete outProps.defaultValue;
    return outProps;
}

/**
 * @param {ValidatableTextFieldProps} props 
 */
function ValidatableTextField(props) {
    const {
        onValueChange, 
        validationRules, 
        defaultValue, 
        testID, 
        printError,
        onlyPrintErrorWithValue
    } = props;

    const [theValue, setValue] = useState(defaultValue);
    const [focused, setFocused] = useState(false);
    
    const mappedValue = useMemo(() => ({value: theValue}), [theValue]);
    console.log(`theValue = ${theValue}, mappedValue = ${JSON.stringify(mappedValue)}`);
    const innerValidationRules = useMemo(() => validationRules && validationRules.length && validationRules.map(makeInnerValidationRule), [validationRules]);
    const validationResult = useValidator(mappedValue, innerValidationRules);

    useEffect(() => validationResult && onValueChange(theValue, validationResult), [theValue, validationResult]);
    useEffect(() => {
        console.log(`defaultValue = ${defaultValue}, focused = ${focused}`);
        setValue(v => {
            if(!focused && (defaultValue !== v)) {
                return defaultValue;
            }
            return v;
        });
    }, [defaultValue, focused]);

    const TextFieldProps = getTextFieldProps(props);
    const errorPrintable = printError && validationResult && (!onlyPrintErrorWithValue || is(theValue));

    return <TextField
        {...TextFieldProps}
        value={theValue || ""}
        onChange={evt => setValue(evt.target.value)}
        error={errorPrintable ? !validationResult.valid : false}
        helperText={errorPrintable ? validationResult.invalidMessage : undefined}
        FormHelperTextProps={{
            "data-testid": `${testID}-helper`
        }}
        inputProps={{
            ...(TextFieldProps.inputProps || {}),
            "data-testid": `${testID}-input`
        }}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
    />;
}

export default ValidatableTextField;