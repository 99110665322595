import { WebRuntimeContextArgs } from "@italwebcom/anema-shared-web-components";

function u(i) {
  return typeof i === "undefined";
}

const Navigation = {
  navigate: (url, args) => {
    console.log(url);
    console.log(args);
    if (url === "user/dash") {
      window.location.href =
        "#/user/dash" + (args && args.tempCart ? `?tempCart=true` : "");
    } else {
      if (url === "menu") {
        window.location.href = `#/menu${
          args && (args.cart || args.id)
            ? "?reservation=" + (args.id || args.cart)
            : ""
        }`;
      } else {
        window.location.href = `#/${url}${
          !u(args && args.id) ? "/" + args.id : ""
        }`;
      }
    }
  },
};

/**
 * @type {WebRuntimeContextArgs}
 */
const args = {
  Navigation,
  viewNames: {
    home: "home",
    contacts: "contacts",
    cartOverview: "cart",
    menuSelection: "menu",
    user: {
      dashboard: "user/dash",
      carts: "user/carts",
      edit: "user/edit",
      chat: "user/chat",
    },
  },
  authentication: {
    authenticatedRole: "standard",
    defaultRole: "unregistered",
  },
  smallScreen: window.innerWidth <= 512,
  onlyDefaultPayment: true,
  deliveryDisabled: true,
  disabledDelivery: true,
  stylings: {
    general: {
      appBar: {
        logo: "webapp/img/anema-logo.png",
        backgroundColor: "rgb(85, 48, 66)",
        buttonsColor: "white",
        titleTypographyProps: {
          style: {
            color: "white",
            textAlign: "center",
            fontFamily: "Anema"
          }
        }
      },
      bottomBar: {
        backgroundColor: "rgb(85, 48, 66)",
        colors: {
          selected: "rgb(248, 152, 128)",
          default: "white",
        },
      },
      contentPanelHeader: {
        backgroundColor: "rgb(248, 152, 128)",
        centeredTitle: false,
        sideImage: "webapp/img/anema.png",
        titleStyle: {
          fontFamily: "Anema"
        }
      },
      headersTypographyProps: {
        style: {
          fontFamily: "Anema",
        },
      },
      pageHeader: {
        backgroundColor: "#143279",
        maxWidth: "lg",
        titleTypographyProps: {
          style: {
            fontFamily: "Anema",
            color: "white",
            textAlign: "center",
          },
        },
      },
      backgroundColor: "#F0F2F5",
    },
    home: {
      selectionView: {
        titleOffset: "64px",
        sliderOffset: "52px",
      },
      productDetailPanelHeaderProps: {
        titleStyle: {
          fontWeight: "bold",
          color: "grey",
        },
      },
    },
  },
};

export default args;
