import React, { ReactNode, memo } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import PageHeader from "../../../components/PageHeader";
import AppBar from "../../../components/AppBar";
import AppBarButtonGroup from "./AppBarButtonGroup";
import BottomTabBar from "./BottomTabBar";
import { useRuntimeContext } from "../../../contexts";
import clsx from "clsx";

const s = makeStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing(2),
  },
  tabsWrapper: {
    position: "fixed",
    bottom: 0,
    zIndex: 5,
    width: "100%",
  },
  bottomPadding: {
    paddingBottom: theme.spacing(8),
  },
}));

function renderAppBarButtons({ smallScreen, userArea, color, cart }) {
  if (userArea) {
    if(smallScreen) {
      return null;
    }
    return (
      <AppBarButtonGroup.User
        color={color}
        cart={cart}
        smallScreen={smallScreen}
      />
    );
  } else {
    return (
      <AppBarButtonGroup.Temp
        color={color}
        cart={cart}
        smallScreen={smallScreen}
      />
    );
  }
}

function renderTabs({
  smallScreen,
  userArea,
  colors,
  viewName,
  backgroundColor,
}) {
  if (smallScreen) {
    if (userArea) {
      return (
        <BottomTabBar.User
          colors={colors}
          selected={viewName}
          backgroundColor={backgroundColor}
        />
      );
    } else {
      return (
        <BottomTabBar.Temp
          colors={colors}
          selected={viewName}
          backgroundColor={backgroundColor}
        />
      );
    }
  }
  return null;
}

/**
 * @param {{
 *    title: string,
 *    maxWidth?: string,
 *    minHeight?: string,
 *    children: ReactNode[],
 *    externalContent?: ReactNode[],
 *    noHeader?: boolean,
 *    cart?: number,
 *    userArea?: boolean,
 *    viewName?: string
 * }} param0
 * @returns
 */
function PageWrapper({
  children,
  title,
  maxWidth,
  minHeight,
  noHeader,
  externalContent,
  cart,
  userArea,
  viewName,
  noContentGutters,
  noTopPadding,
  noPadding,
}) {
  const { wrapper, tabsWrapper, bottomPadding } = s();
  const { stylings, smallScreen } = useRuntimeContext();
  const { appBar, pageHeader, backgroundColor, bottomBar } = stylings.general;
  return (
    <Box>
      <AppBar {...appBar} justifyContent="flex-end" title={noHeader && title}>
        {renderAppBarButtons({
          color: appBar.buttonsColor,
          userArea,
          smallScreen,
          cart,
        })}
      </AppBar>
      {!noHeader && <PageHeader {...pageHeader} title={title} />}
      {externalContent}
      <Box
        className={clsx([
          !noTopPadding && !noPadding && wrapper,
          smallScreen && !noPadding && bottomPadding,
        ])}
        style={{ backgroundColor, minHeight }}
      >
        <Container
          maxWidth={maxWidth || "lg"}
          disableGutters={noContentGutters}
        >
          {children}
        </Container>
      </Box>
      <Paper className={tabsWrapper}>
        {renderTabs({
          colors: bottomBar.colors,
          userArea,
          smallScreen,
          viewName,
          backgroundColor: bottomBar.backgroundColor,
        })}
      </Paper>
    </Box>
  );
}

export default memo(PageWrapper);
