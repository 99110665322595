import React, { memo, useMemo } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography, { TypographyProps } from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundSize: "cover",
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    borderRadius: 0,
  },
  titleStyle: {
    fontWeight: "bold",
    color: "white",
  },
}));

/**
 * @typedef {{
 *      maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false,
 *      backgroundImage?: string,
 *      backgroundColor?: string,
 *      rightContent?: ReactNode,
 *      title: string,
 *      titleTypographyProps?: TypographyProps,
 *      component?: string
 * }} PageHeaderProps
 *
 * @param {PageHeaderProps} param0
 * @returns
 */
function PageHeader({
  maxWidth,
  backgroundImage,
  backgroundColor,
  rightContent,
  title,
  titleTypographyProps,
  component,
}) {
  const { wrapper, titleStyle } = useStyles();
  const style = useMemo(
    () => ({
      backgroundColor,
      backgroundImage: backgroundColor ? undefined : `url(${backgroundImage})`,
    }),
    [backgroundColor, backgroundImage]
  );
  return (
    <Paper className={wrapper} style={style} component={component || "header"}>
      <Container maxWidth={maxWidth || "md"}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              variant="h5"
              component="h5"
              className={titleStyle}
              {...titleTypographyProps}
            >
              {title}
            </Typography>
          </Grid>
          {rightContent && <Grid item>{rightContent}</Grid>}
        </Grid>
      </Container>
    </Paper>
  );
}

export default memo(PageHeader);
