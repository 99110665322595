import React, { memo, useCallback } from "react";
import CustomTabs from "../../../../../components/CustomTabs";
import useOnElementSelect from "../../hooks/useOnElementSelect";

/**
 * @typedef {{
 *      id: string,
 *      icon: ReactNode,
 *      label: string
 * }} AppBarTabElement
 *
 * @param {{
 *      elements: AppBarTabElement[],
 *      colors: any,
 *      cart: number,
 *      selected: id
 * }} param0
 */
function Generic({ elements, colors, cart, selected, backgroundColor }) {
  const onSelect = useOnElementSelect({ cart });
  const onActualChange = useCallback(
    (v) => {
      let e = elements.filter((e) => e.id === v)[0];
      onSelect(e);
    },
    [onSelect, elements]
  );
  return (
    <CustomTabs.Base
      defaultValue={selected}
      onChange={onActualChange}
      styling={{
        colors,
        backgroundColor,
      }}
    >
      {elements.map((e) => (
        <CustomTabs.Item
          value={e.id}
          label={e.label}
          icon={e.icon}
          flex
          vertical
        />
      ))}
    </CustomTabs.Base>
  );
}

export default memo(Generic);
