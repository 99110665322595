import React from "react";
import { ProviderProps } from "../defines";
import useManagementFunctions from "../hooks/useManagementFunctions";
import Context from "../Context";

/**
 * @returns
 * @param {ProviderProps} param0
 */
export default function Provider({ defaultSelected, onChange, children }) {
  const { onAdd, onRemove, onReplace, onReset, selected } =
    useManagementFunctions({ defaultSelected, onChange });
  return (
    <Context.Provider value={{ onAdd, onRemove, onReplace, selected }}>
      {children}
    </Context.Provider>
  );
}
