import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import {
  useEntityManagement,
  UseEntityManagementArgs,
  UseEntityManagementReturn,
  EntityManagementError
} from "@italwebcom/custom-react-hooks";
import useDialogConfirm from "../useDialogConfirm";

const defaultErrorHandler = (err) => {
  console.log(err);
  return `Si è verificato un errore${err.message && (": " + err.message)}.`;
};
const p = (h, onResolve) => {
  if(h instanceof Promise) {
    return h.then(onResolve);
  }
  return h;
}

/**
 * @typedef {{defaultMessage: string, errorHandler?: (error: EntityManagementError) => string}} AdditionalArgs
 * @typedef {{dialog: ReactNode}} AdditionalReturnValues
 */

/**
 * @template T
 * @template {string} Indices
 * @template {string} Sorters
 * @typedef {UseEntityManagementArgs<T, Indices, Sorters> | AdditionalArgs} UseEntityManagementWithFeedbackArgs
 */

/**
 * @template T
 * @template {string} Indices
 * @template {string} Sorters
 * @typedef {UseEntityManagementReturn<T, Indices, Sorters> | AdditionalReturnValues} UseEntityManagementWithFeedbackReturn
 */

/**
 * @template T
 * @template {string} Indices
 * @template {string} Sorters
 * @param {UseEntityManagementWithFeedbackArgs<T, Indices, Sorters>} param0
 */
function useEntityManagementWithFeedback({
  onFetch,
  onSave,
  onDelete,
  countGetter,
  initialCount,
  initialElements,
  initialFilters,
  initialItemsPerPage,
  initialSorter,
  config,
  errorHandler,
  defaultMessage,
  lazyCountRecompute,
  onCountChange: inputOnCountChange
}) {
  errorHandler = errorHandler || defaultErrorHandler;
  const [error, setError] = useState(null);

  const { dialogMessage, dialogTitle } = useMemo(
    () => ({
      dialogMessage: error ? errorHandler(error) : defaultMessage,
      dialogTitle: error ? "Errore" : "Successo",
    }),
    [error, errorHandler, defaultMessage]
  );

  const { rendered: dialog, onTrigger } = useDialogConfirm({
    message: dialogMessage,
    noAbort: true,
    confirmLabel: "OK",
    title: dialogTitle,
    onExited: () => setError(false)
  });

  /* trigger confirm dialog on operation success; error is taken care of by the setError below */
  const onActualSave = useCallback((e) => p(onSave(e), onTrigger), [onSave]);
  const onActualDelete = useCallback(
    (e) => p(onDelete(e), onTrigger),
    [onDelete]
  );

  useEffect(() => {
    //error && onTrigger().then(() => setError(null));
    error && onTrigger();
  }, [error]);

  const out = useEntityManagement({
    onFetch,
    onSave: onActualSave,
    onDelete: onActualDelete,
    /* whenever an error happens, set the state & call the confirmation dialog via useEffect callback above */
    onError: setError,
    countGetter,
    initialCount,
    initialElements,
    initialFilters,
    initialItemsPerPage,
    initialSorter,
    config,
    onCountChange: inputOnCountChange,
    lazyCountRecompute
  });

  return {
    ...out,
    dialog
  };
}

export default useEntityManagementWithFeedback;
