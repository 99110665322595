import React, { memo } from "react";
import { Carts } from "@italwebcom/anema-react-components";
import { Customer } from "@italwebcom/anema-react-components/Customers";
import Context from "../context";

const { CartsCustomerManager } = Carts.Components;

/**
 * @param {{customer: Customer}} param0
 */
function SharedList({
  customer,
  children,
  onRedirectToEdit,
  onRedirectToOverview,
  initialItemsPerPage
}) {
  return (
    <Context.Provider value={{ onRedirectToEdit, onRedirectToOverview }}>
      <CartsCustomerManager customer={customer} initialItemsPerPage={initialItemsPerPage}>
        {children}
      </CartsCustomerManager>
    </Context.Provider>
  );
}

export default memo(SharedList);
