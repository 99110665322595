import { memo, useContext } from "react";
import EntityManagerContext from "../context";
import { CollectionRenderer, EntityManagerWrapperProps } from "../defines";
import { ifNotUndef, auxProps as props } from "../auxiliary";

/**
 * @param {EntityManagerWrapperProps<CollectionRenderer>} param0
 */
function CollectionRendererWrapper({
  Component,
  ComponentProps,
  renderOnError,
}) {
  const {
    elements,
    attributes,
    actions,
    onSelect,
    onSave,
    onDelete,
    onRefreshAll,
    onSorterChange,
    sorter,
    selectedEntity,
    error,
  } = useContext(EntityManagerContext);
  if (error && !renderOnError) {
    return null;
  } else {
    return ifNotUndef(
      Component,
      props(
        {
          elements,
          attributes,
          actions,
          onSelect,
          onSave,
          onDelete,
          onRefreshAll,
          onSorterChange,
          sorter,
          selectedEntity,
          errored: Boolean(error),
        },
        ComponentProps
      )
    );
  }
}

export default memo(CollectionRendererWrapper);
