import React, { useEffect } from "react";
import { NativeSelect, Grid, FormControl, InputLabel } from "@material-ui/core";
import { SortDirection } from "../../defines";
import { useStateWithDefault } from "@italwebcom/custom-react-hooks";

/**
 * @typedef {{
 *      id: string,
 *      label?: string,
 *      value?: string
 * }} SortAttribute
 */

/**
 * @typedef {{
 *      attributes: SortAttribute[],
 *      defaultSortAttribute: string,
 *      defaultSortDirection?: SortDirection
 *      fixedDirection?: boolean,
 *      onChange: (attribute: string, direction: SortDirection) => void,
 *      baseId: string
 * }} SimpleSorterProps
 */

/**
 * @param {SimpleSorterProps} param0 
 */
export default function SimpleSorter({
    attributes, 
    defaultSortAttribute, 
    defaultSortDirection, 
    fixedDirection, 
    onChange, 
    baseId
}) {
    defaultSortDirection = defaultSortDirection || "asc";
    const [direction, setDirection] = useStateWithDefault({defaultValue: defaultSortDirection});
    const [attribute, setAttribute] = useStateWithDefault({defaultValue: defaultSortAttribute});
    useEffect(() => onChange(attribute, direction), [direction, attribute]);
    return <Grid container spacing={2} component="article" alignItems="center">
        <Grid item xs component="section">
            <FormControl fullWidth>
                <InputLabel htmlFor={`${baseId}-attribute-select`}>
                    Ordina per
                </InputLabel>
                <NativeSelect
                    disableUnderline
                    variant="outlined"
                    value={attribute} 
                    inputProps={{
                        id: `${baseId}-attribute-select`
                    }}
                    onChange={evt => setAttribute(evt.target.value)}
                >
                    {attributes && attributes.filter(a => a.sortable).map(({label, id, value}) => <option key={id} value={value || id}>
                        {label || id}
                    </option>)}
                </NativeSelect>
            </FormControl>
        </Grid>
        {!fixedDirection && <Grid item xs component="section">
            <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor={`${baseId}-direction-select`}>
                </InputLabel>
                <NativeSelect 
                    disableUnderline
                    inputProps={{
                        id: `${baseId}-direction-select`
                    }}
                    variant="outlined" 
                    fullWidth 
                    value={direction} 
                    onChange={evt => setDirection(evt.target.value)}
                >
                    <option key="asc" value="asc">Crescente</option>
                    <option key="desc" value="desc">Decrescente</option>
                </NativeSelect>
            </FormControl>
        </Grid>}
    </Grid>
}