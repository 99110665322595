import React, { createContext, Context, useContext } from "react";
import useEntityManagement, {
  Filter,
  Sorter,
} from "../hooks/use-entity-management/use-entity-management.hook";
/**
 * @template T
 * @template {string} Indices
 * @template {string} Sorters
 * @typedef {{
 *      elements: T[],
 *      filtering: {
 *          filters: Filter<Indices>[],
 *          onFilterAdd: (filter: Filter<Indices>) => void,
 *          onFilterRemove: (filter: Filter<Indices>) => void,
 *          onFilterReplace: (trg: Filter<Indices>, filter: Filter<Indices>) => void
 *      },
 *      management: {
 *          onSave: (element: T) => Promise<any>,
 *          onDelete: (element: T) => Promise<any>
 *      },
 *      sorting: {
 *          sorter: Sorter<Sorters>,
 *          onSorterChange: (sorter: Sorter<Sorters>) => void,
 *          onSorterClear: Function
 *      },
 *      pagination: {
 *          count: number,
 *          page: number,
 *          onCountChange: (count: number) => void,
 *          itemsPerPage: number,
 *          onNextPage: Function,
 *          onPrevPage: Function,
 *          onItemsPerPageChange: (itemsPerPage: number) => void,
 *          onPageChange: (page: number) => void
 *      }
 * }} EntityManagementContextArgs
 * @typedef {Context<EntityManagementContextArgs<T, Indices, Sorters>>} EntityManagementContext
 */

/**
 * @template T
 * @template {string} Indices
 * @template {string} Sorters
 * @return {EntityManagementContext<T, Indices, Sorters>}
 */
export function createEntityManagementContext() {
  return createContext({
    filtering: {},
    management: {},
    elements: [],
    sorting: {},
    pagination: {},
  });
}

function EntityManagementProvider({
  onFetch,
  onSave,
  onDelete,
  countGetter,
  initialFilters,
  initialSorter,
  initialElements,
  initialItemsPerPage,
  onError,
  ComponentsContext,
  children,
}) {
  /**
   * @type {{EntityManagementContext: EntityManagementContext<T, Indices, Sorters>}}
   */
  const { EntityManagementContext } = useContext(ComponentsContext);
  initialElements = initialElements || [];

  const {
    elements,
    filters,
    sorter,
    onCountChange,
    onDelete: onElementDelete,
    onFilterAdd,
    onFilterRemove,
    onFilterReplace,
    onItemsPerPageChange,
    onNextPage,
    onPageChange,
    onPrevPage,
    onSave: onElementSave,
    onSorterChange,
    onSorterClear,
    count,
    page,
    itemsPerPage,
  } = useEntityManagement({
    initialCount: initialElements.length,
    initialElements,
    initialFilters: initialFilters || [],
    initialItemsPerPage: initialItemsPerPage,
    initialSorter: initialSorter || {},
    countGetter: countGetter,
    onFetch: onFetch,
    onSave: onSave,
    onDelete: onDelete,
    onError,
  });

  return (
    <EntityManagementContext.Provider
      value={{
        elements,
        filtering: {
          filters,
          onFilterAdd,
          onFilterRemove,
          onFilterReplace,
        },
        pagination: {
          count,
          page,
          itemsPerPage,
          onCountChange,
          onItemsPerPageChange,
          onNextPage,
          onPageChange,
          onPrevPage,
        },
        management: {
          onSave: onElementSave,
          onDelete: onElementDelete,
        },
        sorting: {
          sorter,
          onSorterChange,
          onSorterClear,
        },
      }}
    >
      {children}
    </EntityManagementContext.Provider>
  );
}

export function createEntityManagementProvider({ ComponentsContext }) {
  const AuxContext = createContext({ EntityManagementContext: ComponentsContext });
  return (args) => (
    <EntityManagementProvider ComponentsContext={AuxContext} {...args} />
  );
}
