import React, { ReactNode, useState } from "react";
import Context from "../context";

/**
 * @returns
 * @param {{children: ReactNode | ReactNode[]}} param0
 */
export default function GlobalSearchQueryProvider({ children }) {
  const [query, setQuery] = useState("");
  return (
    <Context.Provider value={{ query, setQuery }}>{children}</Context.Provider>
  );
}
