import React, {
  forwardRef,
  memo,
  ReactNode,
  useImperativeHandle,
  useRef,
} from "react";
import ConversationProvider from "../ConversationProvider";
import useConversationAndMessages from "../hooks/useConversationAndMessages";
import MessagesProvider from "../MessagesProvider";

const InnerRenderer = memo(({ children, initialMessagesNumber, mexRef }) => {
  const { conversation } = useConversationAndMessages();
  return (
    <MessagesProvider
      key={conversation ? conversation.id : undefined}
      conversation={conversation}
      initialItemsPerPage={initialMessagesNumber}
      ref={mexRef}
    >
      {children}
    </MessagesProvider>
  );
});

/**
 * @param {{id: number, children: ReactNode[]}} param0
 * @returns
 */
function Provider({ children, id, initialMessagesNumber }, ref) {
  const mexRef = useRef();

  useImperativeHandle(
    ref,
    () => ({
      refreshMessages: () => mexRef.current && mexRef.current.refresh(),
    }),
    [mexRef]
  );

  return (
    <ConversationProvider id={id}>
      <InnerRenderer
        initialMessagesNumber={initialMessagesNumber}
        mexRef={mexRef}
      >
        {children}
      </InnerRenderer>
    </ConversationProvider>
  );
}

export default memo(forwardRef(Provider));
