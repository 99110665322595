import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import useAlertContainerFunctions from "../hooks/useAlertContainerFunctions";
import HulkAlert from "../../HulkAlert";
import { Box, Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  containerWrapper: {
    overflow: "hidden",
    position: "fixed",
    zIndex: 99999,
    "&.top": {
      top: "1em",
    },
    "&.bottom": {
      bottom: "1em",
    },
    "&.left": {
      left: "1em"
    },
    "&.right": {
      right: "1em"
    }
  },
  elementWrapper: {
    position: "relative",
  },
  autoDismissWrapper: {
    position: "relative",
    transitionDuration: "350ms",
    transitionProperty: "left",
    "&.right": {
      left: "calc(100% + 2px)",
      "&.shown": {
        left: 0,
      },
    },
    "&.left": {
      left: "calc(-100% - 2px)",
      "&.shown": {
        left: 0,
      },
    },
  },
}));

const AutoDismissAlert = memo(({ message, onDismiss, from, dismissalTime }) => {
  const { autoDismissWrapper } = useStyles();
  const [state, setState] = useState(false);
  const timeoutRef = useRef(null);

  const onSetDismiss = useCallback(() => setState("dismiss"), [setState]);
  const onTransitionEnd = useCallback(
    (e) => {
      /*
      when the disappearing transition is over, calls the dismissal callback
    */
      if (e.target.classList.contains("dismiss")) {
        //console.log(`AutoDismissAlert.<onTransitionEnd callback>: calling onDismiss with message ${JSON.stringify(message)}`);
        onDismiss(message);
      }
    },
    [onDismiss, message]
  );

  useEffect(() => {
    /* 
      - sets state to 'shown' when mounted and triggers appearing transition
      - after dismissalTime is elapsed, triggers the disappearing transition by setting the state to 'dismiss'
      - if necessary, clears the timeout when unmounting
    */

    setState("shown");
    timeoutRef.current = setTimeout(() => setState("dismiss"), dismissalTime);
    return () => timeoutRef.current && clearTimeout(timeoutRef.current);
  }, [dismissalTime, setState]);

  return (
    <Box
      className={clsx([autoDismissWrapper, state, from])}
      onTransitionEnd={onTransitionEnd}
    >
      <HulkAlert type={message.type} onDismiss={onSetDismiss}>
        {message.content}
      </HulkAlert>
    </Box>
  );
});

/**
 * @typedef {{vertical: "top" | "bottom", horizontal: "left" | "right"}} ContainerPosition
 * @param {{position: ContainerPosition, dismissalTime: number}} param0
 * @returns
 */
export default function Container({ position, dismissalTime, width }) {
  const { messages, onRemove } = useAlertContainerFunctions();
  const { elementWrapper, containerWrapper } = useStyles();
  return (
      <Box
        style={{width}}
        className={clsx([
          containerWrapper,
          position.vertical,
          position.horizontal,
        ])}
      >
        <Grid container spacing={2}>
          {messages.map((m) => (
            <Grid item xs={12} className={elementWrapper} key={m.key}>
              <AutoDismissAlert
                message={m}
                onDismiss={onRemove}
                dismissalTime={dismissalTime}
                from={position.horizontal}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
  );
}
