import { useMemo } from "react";
import { useSetCredentials } from "@italwebcom/react-auth-provider";
import { useRuntimeContext } from "../../../misc/RuntimeProvider";
import { useNavigation } from "../../../misc/NavigationProvider";
import { useAlertFunctions } from "../../../../components/misc/AlertProvider";

/**
 * @returns
 * @param {boolean} asCustomer
 */
export default function useErrorHandler(asCustomer) {
  const { defaultRole } = useRuntimeContext();
  const { navigate } = useNavigation();
  const setCredentials = useSetCredentials();
  const { onAdd } = useAlertFunctions();

  return useMemo(
    () => ({
      onError: (err) => {
        if (err.message === "missing_customer") {
          console.log("missing_customer");
          if (asCustomer) {
            /* invalid access (by customer) with non existing entity -> redirect to home & set temp user */
            setCredentials(makeInitialCredentials(defaultRole));
          }
          onAdd({
            type: "error",
            content:
              (!asCustomer && err.message) ||
              "Si è verificato un errore nell'accesso all'area utente.",
          });
        } else {
          onAdd({
            type: "error",
            content: err.message || "Si è verificato un errore.",
          });
        }
      },
      onSuccess: (op) => {
        op === "save" &&
          onAdd({ type: "success", content: "Dati modificati." });
      },
    }),
    [onAdd, asCustomer, setCredentials, navigate, defaultRole]
  );
}
