export default function getProductImage(p) {
  if (p.image) {
    if (typeof p.image === "string") {
      return p.image;
    } else {
      return p.image.url;
    }
  }
  return p.mainImage;
}
