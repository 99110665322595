import React, { memo, useMemo } from "react";
import { Product } from "../../../../../../model";
import MissingProducts from "../auxiliary/MissingProducts";
import SimpleRenderer from "./SimpleRenderer";
import PanelRenderer from "./PanelRenderer";
import { useSelectedElement } from "../../_Auxiliary/SelectedElementHandler";
import { useEntityManagerValues } from "@italwebcom/react-entity-manager";

export function plc(n) {
  let out = [];
  for (let i = 0; i < n; i++) {
    out.push({
      key: i,
      name: `a`,
      description: `a`,
    });
  }
  return out;
}

const ActualCollectionRenderer = memo(
  ({ elements, placeholder, image, variant }) => {
    const { onSetSelected } = useSelectedElement();
    if (variant === "panel") {
      return (
        <PanelRenderer
          elements={elements}
          image={image}
          placeholder={placeholder}
          onSelect={onSetSelected}
        />
      );
    } else {
      return (
        <SimpleRenderer
          elements={elements}
          placeholder={placeholder}
          onSelect={onSetSelected}
        />
      );
    }
  }
);

/**
 * @param {{elements: Product[]}} param0
 */
function AuxCollectionRenderer({
  elements,
  image,
  variant,
  placeholder: extPlaceholder,
}) {
  const { loading } = useEntityManagerValues();
  const { placeholder, missingElements, actualElements } = useMemo(
    () => ({
      placeholder:
        extPlaceholder || (loading && (!elements || !elements.length)),
      missingElements: !loading && (!elements || !elements.length),
      actualElements: !elements || !elements.length ? plc(10) : elements,
    }),
    [loading, elements, extPlaceholder]
  );
  if (missingElements) {
    return <MissingProducts />;
  } else {
    return (
      <ActualCollectionRenderer
        elements={actualElements}
        image={image}
        variant={variant}
        placeholder={placeholder}
      />
    );
  }
}

export default memo(AuxCollectionRenderer);
