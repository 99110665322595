import fields from "./fields";
import { GenericForm } from "@italwebcom/react-generic-form";
import useConversationAndMessages from "../hooks/useConversationAndMessages";
import React, { ComponentType, memo, useCallback } from "react";

/**
 * @typedef {{value: string, onChange: (value: string) => void}} ComponentProps
 */
/**
 *
 * @param {{Component: ComponentType<ComponentProps>, buttonLabel: string}} param0
 * @returns
 */
function MessageInput({ Component, buttonLabel }) {
  const { onPostMessage, messagesLoading, conversationLoading } =
    useConversationAndMessages();
  const onActualPostMessage = useCallback(
    (stuff) => {
      onPostMessage(stuff);
    },
    [onPostMessage]
  );
  return (
    <GenericForm.Provider
      fields={fields}
      onSubmit={onActualPostMessage}
      loading={messagesLoading || conversationLoading}
    >
      <GenericForm.Input field="content" Component={Component} />
      <GenericForm.Submit
        label={buttonLabel}
        disabled={messagesLoading || conversationLoading}
      />
    </GenericForm.Provider>
  );
}

export default memo(MessageInput);
