import React, { ReactNode, memo, useEffect } from "react";
import {
  EntityManager,
  CollectionRendererProps,
  useEntityManagerValues,
} from "@italwebcom/react-entity-manager";
import useNewsItemWrapper from "../../../../hooks/wrappers/useNewsItemWrapper";
import { useDefaultComponents } from "../../../misc/DefaultComponentsProvider";
import attributes from "../attributes";
import { NewsItem } from "../../../../__domain/model";
import Context from "../context";
import useGenericFetch from "../../../../hooks/generic/useGenericFetch";

const initialSorter = { attribute: "date", direction: "desc" };
const initialFilters = [];

/**
 * @returns
 * @param {CollectionRendererProps<NewsItem>} param0
 */
function ProviderCollectionRenderer({ elements, children }) {
  const { loading, error, onRefreshAll } = useEntityManagerValues();
  return (
    <Context.Provider
      value={{ newsItems: elements, loading, error, onRefreshAll }}
    >
      {children}
    </Context.Provider>
  );
}

/**
 * Manages fetching operations with an EntityManager for the NewsItem entity &
 * instantiates a corresponding context provider with:
 *
 * - the fetched news items
 * - a loading boolean flag
 *
 * Values may be obtained with the useNewsItems hook.
 *
 * @returns
 * @param {{children: ReactNode[], initialItemsPerPage?: number}} param0
 */
function NewsItemProvider({ children, initialItemsPerPage }) {
  const wrapper = useNewsItemWrapper();
  const { fetchArgsProcessor, defaultCountGetter } = useDefaultComponents();
  const onFetch = useGenericFetch(wrapper);
  if (wrapper) {
    return (
      <EntityManager.ReadOnly
        fetchArgsProcessor={fetchArgsProcessor}
        countGetter={defaultCountGetter}
        initialItemsPerPage={initialItemsPerPage || 5}
        initialFilters={initialFilters}
        initialSorter={initialSorter}
        onFetch={onFetch}
        attributes={attributes}
        testID="NewsItemsProvider"
      >
        <EntityManager.Components.CollectionRenderer
          Component={ProviderCollectionRenderer}
          ComponentProps={{ children }}
          renderOnError
        />
      </EntityManager.ReadOnly>
    );
  } else {
    return children;
  }
}

export default memo(NewsItemProvider);
