import { useMemo } from "react";
import useCartProductProvider from "../../CartProductProvider/hooks/useCartProductProvider";
import useCartProvider from "../../CartProvider/hooks/useCartProvider";

/**
 * @returns
 */
export default function useCartAndProducts() {
  const {
    onRefreshAll: onCartRefresh,
    onSave: onCartSave,
    cart,
    loading: cartLoading,
    errored: cartErrored
  } = useCartProvider();

  const {
    cartProducts,
    onDelete: onCartProductDelete,
    onSave: onCartProductSave,
    loading: productsLoading,
    onSelect: onCartProductSelect,
  } = useCartProductProvider();

  const { onActualCartProductDelete, onActualCartProductSave } = useMemo(
    () => ({
      onActualCartProductDelete: (cp) => {
        let p = onCartProductDelete(cp);
        if (p instanceof Promise) {
          //console.log("useCartAndProducts.<onActualCartProductDelete>: refreshing cart.")
          p.then(onCartRefresh);
        }
      },
      onActualCartProductSave: (cp) => {
        let p = onCartProductSave(cp);
        if (p instanceof Promise) {
          //console.log("useCartAndProducts.<onActualCartProductSave>: refreshing cart.")
          p.then(onCartRefresh);
        }
        return p;
      },
    }),
    [onCartProductDelete, onCartRefresh, onCartProductSave]
  );

  return {
    onCartProductDelete: onActualCartProductDelete,
    onCartProductSave: onActualCartProductSave,
    onCartRefresh,
    onCartSave,
    cart,
    cartErrored,
    cartProducts,
    cartLoading,
    productsLoading,
    onCartProductSelect,
  };
}
