import { Field, GenericForm } from "@italwebcom/react-generic-form";

/**
 * @type {Field[]}
 */
const fields = [
  {
    id: "avatar",
    validationRules: [],
  },
  {
    id: "email",
    type: "email",
    validationRules: [
      {
        priority: 0,
        invalidMessage: "Email non valida.",
        validator: GenericForm.validators.email
      }
    ],
  },
  {
    id: "full_name",
    validationRules: [
      {
        priority: 0,
        invalidMessage: "Nome e cognome non validi.",
        validator: GenericForm.validators.extendedAlphanumeric
      }
    ],
  },
  {
    id: "address",
    validationRules: [
      {
        priority: 0,
        invalidMessage: "Indirizzo non valido.",
        validator: GenericForm.validators.extendedAlphanumeric
      }
    ],
  },
  {
    id: "phone",
    type: "tel",
    validationRules: [
      {
        priority: 0,
        invalidMessage: "Numero di telefono non valido.",
        validator: GenericForm.validators.phone
      }
    ],
  },
];

export default fields;
