import React, { memo, useCallback } from "react";
import AlertContext from "../context";
import { AlertContextArgs, Alert } from "../defines";

/**
 * @typedef {(alert: Alert) => Alert} AlertMapper
 * @typedef {{mapper?: AlertMapper}} AdditionalArgs
 *
 * @param {AlertContextArgs & AdditionalArgs} param0
 * @returns
 */
function AlertProvider({ onAdd, mapper, children }) {
  const onActualAdd = useCallback(
    (a) => {
      if (mapper) {
        a = mapper(a);
      }
      onAdd(a);
    },
    [mapper, onAdd]
  );
  return (
    <AlertContext.Provider value={{ onAdd: onActualAdd }}>
      {children}
    </AlertContext.Provider>
  );
}

export default memo(AlertProvider);
