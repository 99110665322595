import { useCallback } from "react";
import { useRuntimeContext } from "../../../misc/RuntimeProvider";
import useTokenDecorator from "../useTokenDecorator";

/**
 * "Decorates" the given operator by:
 *
 * - getting the current token from storage
 * - using the TokenManager to verify the token's freshness (and triggering refresh and/or prompt user if needed).
 * - setting a default decorator for the fetcher which adds the token to the current request
 *
 * Problems:
 *
 * - direct comparison between token value and new token obtained by manager most likely won't work
 * - should probably set a non-default decorator for the request
 * - the decorator isn't removed from fetcher after the request (finally method of Promise doesn't work for native code)
 *
 * @returns
 * @param {{
 *    tokenStorageName: string,
 *    operator: (args: any...) => Promise<any>,
 *    name?: string
 * }} param0
 */
export default function useAuthenticatedRequest({
  tokenStorageName,
  operator
}) {
  
  tokenStorageName = tokenStorageName || "token";
  const { fetcher } = useRuntimeContext();

  const onSetToken = useTokenDecorator();

  return useCallback(
    (...args) => {
      fetcher.addDefaultDecorator({
        name: "cached_token_setter",
        process: onSetToken,
      });
      let p = operator(...args);
      return p;
    },
    [fetcher, operator, onSetToken]
  );
}
