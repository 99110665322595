import React, { memo, useCallback, useMemo, useState } from "react";
import CarouselDots from "./CarouselDots";
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PlaceholderWrapper from "../../components/PlaceholderWrapper";

const detailPlaceholder = {
  title: "title",
  content:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
};

function Image({ element }) {
  return (
    <img src={element.image} alt={element.title} style={{ maxWidth: "calc(min(100%, 300px))" }} />
  );
}

function ImagePlaceholder() {
  return (
    <PlaceholderWrapper active>
      <Box style={{ maxWidth: "100%", height: "150px" }} />
    </PlaceholderWrapper>
  );
}

function DetailRenderer({
  element,
  titleTypographyProps,
  contentTypographyProps,
  placeholder,
}) {
  return (
    <PlaceholderWrapper active={placeholder}>
      <Box component="article">
        <Typography
          component="h5"
          variant="body1"
          gutterBottom
          {...(titleTypographyProps || {})}
        >
          {element.title}
        </Typography>
        <Typography
          component="p"
          variant="caption"
          gutterBottom
          {...(contentTypographyProps || {})}
        >
          {element.content}
        </Typography>
      </Box>
    </PlaceholderWrapper>
  );
}

/**
 * @typedef {{title: string, content: string, image: string}} CarouselItem
 * @typedef {"horizontal" | "vertical"} CarouselVariant
 *
 * @param {{
 *    items: CarouselItem[],
 *    variant: CarouselVariant,
 *    defaultSelected?: number,
 *    dotColor?: string,
 *    detailTitleTypographyProps?: TypographyProps,
 *    detailContentTypographyProps?: TypographyProps,
 *    dotSize?: number,
 *    alignItems?: "flex-start" | "flex-end" | "space-around" | "space-between" | "center"
 * }} param0
 */
function Carousel({
  items,
  variant,
  defaultSelected,
  dotColor,
  detailTitleTypographyProps,
  detailContentTypographyProps,
  dotSize,
  alignItems,
  placeholder,
}) {
  const [selected, setSelected] = useState(defaultSelected || 0);
  const selectedItem = useMemo(() => items[selected], [selected, items]);
  const onActualChange = useCallback(
    ( index, item ) => setSelected(index),
    [setSelected]
  );
  const xs = variant === "vertical" ? 12 : 6;
  return (
    <Grid container spacing={3} justifyContent="center" alignItems={alignItems}>
      <Grid item xs={xs}>
        {!placeholder ? (
          <ResponsiveCarousel
            selectedItem={selected}
            showArrows={false}
            showIndicators={false}
            showStatus={false}
            showThumbs={false}
            onChange={onActualChange}
          >
            {items.map((item) => (
              <Image element={item} key={item.title} />
            ))}
          </ResponsiveCarousel>
        ) : (
          <ImagePlaceholder />
        )}
      </Grid>
      <Grid item xs={xs}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          {(selectedItem || placeholder) && (
            <DetailRenderer
              placeholder={placeholder}
              element={placeholder ? detailPlaceholder : selectedItem}
              titleTypographyProps={detailTitleTypographyProps}
              contentTypographyProps={detailContentTypographyProps}
            />
          )}
          <PlaceholderWrapper active={placeholder}>
            <Box
              display="flex"
              justifyContent="center"
              overflow="auto"
              maxWidth="100%"
              paddingBottom="0.25rem"
              marginTop="1rem"
            >
              <CarouselDots
                number={placeholder ? 4 : items.length}
                onChange={setSelected}
                dotSize={dotSize || 12}
                selected={selected}
                color={dotColor}
              />
            </Box>
          </PlaceholderWrapper>
        </Box>
      </Grid>
    </Grid>
  );
}

export default memo(Carousel);
