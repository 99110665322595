import clsx from "clsx";
import React, { useMemo } from "react";
import {makeStyles} from "@material-ui/core"
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PlaceholderWrapper from "../PlaceholderWrapper";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(1),
    backgroundColor: "white",
  },
  image: {
    display: "block",
    borderRadius: "50%",
    maxWidth: "100%",
  },
  primary: {
    fontWeight: "bold",
    color: theme.palette.grey["800"],
  },
  butan: {
    transitionProperty: "background-color",
    transitionDuration: "500ms",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: theme.palette.grey["200"],
    },
    cursor: "pointer",
  },
  secondaryStyle: {
    color: theme.palette.grey["600"],
  },
}));

/**
 * @param {{
 *      image?: string,
 *      primary: string,
 *      secondary?: string,
 *      footerContent?: ReactNode[],
 *      additionalContent?: ReactNode[],
 *      imageWidth?: number,
 *      component?: string,
 *      style?: CSSProperties,
 *      className?: string,
 *      onClick?: Function,
 *      placeholder?: boolean
 * }} param0
 * @returns
 */
export default function AlternateListRenderer({
  image,
  primary,
  secondary,
  footerContent,
  additionalContent,
  imageWidth,
  component,
  style,
  className,
  onClick,
  placeholder,
}) {
  const {
    wrapper,
    image: imageStyle,
    primary: primaryStyle,
    butan,
    secondaryStyle,
  } = useStyles();
  const actualImageWidth = useMemo(() => `${imageWidth || 50}px`, [imageWidth]);
  return (
    <Box
      className={clsx([wrapper, className, onClick && butan])}
      component={component || "li"}
      style={style}
      role={onClick ? "button" : undefined}
      onClick={onClick}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <PlaceholderWrapper
            dimensions={{ width: actualImageWidth, height: actualImageWidth }}
            active={placeholder}
          >
            <img
              className={imageStyle}
              alt={primary}
              src={image}
              width={actualImageWidth}
              height={actualImageWidth}
              loading="lazy"
            />
          </PlaceholderWrapper>
        </Grid>
        <Grid item xs style={{ alignSelf: "stretch" }}>
          <PlaceholderWrapper active={placeholder}>
            <Typography component="h6" variant="h6" className={primaryStyle}>
              {primary}
            </Typography>
          </PlaceholderWrapper>
          <PlaceholderWrapper active={placeholder}>
            <Typography
              component="p"
              variant="body1"
              className={secondaryStyle}
            >
              {secondary}
            </Typography>
          </PlaceholderWrapper>
        </Grid>
        {additionalContent && (
          <Grid item>
            <PlaceholderWrapper active={placeholder}>
              {additionalContent}
            </PlaceholderWrapper>
          </Grid>
        )}
        {footerContent && (
          <Grid item xs={12}>
            <PlaceholderWrapper active={placeholder}>
              {footerContent}
            </PlaceholderWrapper>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
