import React, { memo } from "react";
import { SubmitProps } from "@italwebcom/react-generic-form";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { useButtonAutoLoading } from "@italwebcom/custom-react-hooks";

/**
 * @param {SubmitProps} param0
 * @returns
 */
function Submit({ label, disabled, loading, fullWidth }) {
  const { load, onActualClick } = useButtonAutoLoading({ loading, disabled });
  return (
    <Button
      variant="outlined"
      color="primary"
      disabled={disabled}
      startIcon={load && <CircularProgress size={12} color="white" />}
      type="submit"
      fullWidth={fullWidth}
      onClick={onActualClick}
    >
      {label}
    </Button>
  );
}

export default memo(Submit);
