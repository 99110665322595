import { useCallback, useState } from "react";
import useRequestAuthenticationSetter from "../../../authentication/hooks/useRequestAuthenticationSetter";
import useWrapper from "../../../../hooks/useWrapper";
import { UserWrapper } from "../../../../__domain/__mock/wrappers";
import { useAlertFunctions } from "../../../misc/AlertProvider";
import { useSetCredentials } from "@italwebcom/react-auth-provider";
import { makeInitialCredentials } from "../../../authentication/hooks/useInitialCredentials";
import { useRuntimeContext } from "../../../misc/RuntimeProvider";
import { useStorageSet } from "../../../misc/StorageProvider";
import { headerManager } from "@italwebcom/augmented-fetch";

function makeVals(data, a, b) {
  let out = {};
  out[a] = data[a];
  out[b] = data[b];
  out.token = null;
  return out;
}

/**
 * @returns
 * @param {Function} onAfterDeletion
 */
function useCustomerDeletion(onAfterDeletion) {
  /**
   * @type {UserWrapper}
   */
  const userWrapper = useWrapper("user");
  const cartWrapper = useWrapper("cart");

  const [loading, setLoading] = useState(false);
  const { onAdd: onAlertAdd } = useAlertFunctions();
  const setCredentials = useSetCredentials();
  const { defaultRole, cartCodeName, cartIdName } = useRuntimeContext();
  const onStorageSet = useStorageSet();
  const authSetter = useRequestAuthenticationSetter();

  const onUserDelete = useCallback(() => {
    setLoading(true);
    let r = userWrapper.execute("delete");
    authSetter(r.request());
    r.response()
      .then(() => {
        let r = cartWrapper.execute("makeTemp", {}, false);
        r.request().withDecorator({
          name: "lel",
          process: (init) =>
            headerManager(init)
              .set("Authorization", undefined)
              .set("x-cart-code", undefined)
              .get(),
        });
        return r.json();
      })
      .then(({ data }) => {
        onStorageSet(makeVals(data, cartCodeName, cartIdName));
      })
      .then(() => {
        const initialCredentials = makeInitialCredentials(defaultRole);
        setCredentials(initialCredentials);
        onAfterDeletion();
      })
      .then(() => {
        onAlertAdd({ type: "success", content: "Account cancellato." });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        onAlertAdd({ type: "error", content: "Si è verificato un errore." });
        setLoading(false);
      });
  }, [
    setLoading,
    userWrapper,
    onAlertAdd,
    setCredentials,
    defaultRole,
    authSetter,
    cartWrapper,
    onStorageSet,
    cartCodeName,
    cartIdName,
  ]);

  return { loading, onDelete: onUserDelete };
}

export default useCustomerDeletion;
