import React, { memo, useCallback, useMemo } from "react";
import { Carousel } from "react-responsive-carousel";
import Image from "../Image";

function ImageCarousel({ images, selectedItem, onChange }) {
  const selectedIndex = useMemo(
    () => images.indexOf(selectedItem),
    [images, selectedItem]
  );
  const onActualChange = useCallback(
    (v) => onChange(images[v]),
    [onChange, images]
  );
  return (
    <Carousel
      selectedItem={selectedIndex}
      onChange={onActualChange}
      showStatus={false}
      showThumbs={false}
    >
      {images.map(({ src, name }) => (
        <Image src={src} alt={name} key={name} />
      ))}
    </Carousel>
  );
}

export default memo(ImageCarousel);
