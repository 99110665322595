import React, { ComponentType, memo, useContext } from "react";
import GenericInput from "../GenericInput";
import { fieldNames } from "../../fields";
import context from "../../context";
import {OpeningTime} from "../../../../../../__domain/model";

/**
 * @typedef {{
 *    openingTimes: OpeningTime[],
 *    onChange: (time: string) => void,
 *    value: string
 * }} TimeInputComponentProps
 */

/**
 * @returns
 * @param {{InputComponent: ComponentType<TimeInputComponentProps>, testID?: string}} param0
 */
function TimeInput({ InputComponent, testID, controlled }) {
  const {openingTimes, openingTimesLoading: loading} = useContext(context);
  return (
    <GenericInput
      field={fieldNames.time}
      InputComponent={InputComponent}
      InputProps={{loading, openingTimes, "data-testid": testID}}
      controlled={controlled}
    />
  );
}

export default memo(TimeInput);