import React, { memo } from "react";
import { Customer } from "@italwebcom/anema-react-components/Customers";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import PlaceholderWrapper from "../../../../PlaceholderWrapper";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderRadius: "16px",
  },
  avatarStyle: {
    height: "64px",
    width: "64px",
    borderRadius: "32px",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(2),
  },
  innerContentWrapper: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(1)
  },
  username: {
    fontWeight: "bolder",
  },
  userType: {
    color: theme.palette.grey["600"],
  },
  dataWrapperTitle: {
    color: theme.palette.grey["700"],
    fontWeight: "bolder",
  },
  dataTitle: {
    color: theme.palette.grey["500"],
  },
  clipped: {
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
}));

function getDataPairs(customer) {
  return [
    { title: "Nome e cognome", content: customer.fullName },
    { title: "Email", content: customer.email },
    { title: "Numero di telefono", content: customer.phone },
    { title: "Indirizzo", content: customer.address },
  ];
}

function DataPair({ title, content, placeholder, clipContent }) {
  const { dataTitle, dataContent, clipped } = useStyles();
  return (
    <Box component="article">
      <PlaceholderWrapper active={placeholder}>
        <Typography
          component="header"
          variant="body2"
          className={dataTitle}
          gutterBottom
        >
          {title}
        </Typography>
        <Typography
          component="header"
          variant="body2"
          className={clsx([dataContent, clipContent && clipped])}
        >
          {content || "-"}
        </Typography>
      </PlaceholderWrapper>
    </Box>
  );
}

function renderAvatar({ avatar, placeholder }) {
  const { avatarStyle, contentWrapper, innerContentWrapper } = useStyles();
  return (
    <PlaceholderWrapper active={placeholder}>
      <Box className={innerContentWrapper} alignItems="center">
        <Avatar src={avatar} className={avatarStyle} />
      </Box>
    </PlaceholderWrapper>
  );
}

function renderUsername({ username, placeholder, variant }) {
  const { contentWrapper, username: usernameStyle } = useStyles();
  return (
    <PlaceholderWrapper active={placeholder}>
      <Box className={contentWrapper} alignItems="center">
        <Typography
          component="p"
          variant={variant || "h6"}
          className={usernameStyle}
        >
          {username}
        </Typography>
      </Box>
    </PlaceholderWrapper>
  );
}

/**
 * @returns
 * @param {{customer: Customer, onDataEdit?: Function, avatarInData?: boolean, usernameInData?: boolean, dataLabel?: string}} param0
 */
function CustomerDataRenderer({
  customer,
  onDataEdit,
  avatarInData,
  usernameInData,
  smallScreen,
}) {
  const { wrapper, contentWrapper, buttonWrapper } = useStyles();
  const isPlaceholder = !customer || typeof customer.id !== "number";
  return (
    <Paper className={wrapper}>
      <Container maxWidth={false}>
        {!avatarInData &&
          renderAvatar({ placeholder: isPlaceholder, avatar: customer.avatar })}
        {!usernameInData &&
          renderUsername({
            username: customer.username || customer.name,
            placeholder: isPlaceholder,
          })}
        <Box>
          {avatarInData && (
            <DataPair
              key="avatar"
              title="Avatar"
              content={renderAvatar({ avatar: customer.avatar })}
              placeholder={isPlaceholder}
            />
          )}
          {usernameInData && (
            <DataPair
              key="username"
              title="Username"
              content={renderUsername({
                username: customer.username,
                placeholder: "",
                variant: "body2",
              })}
              placeholder={isPlaceholder}
            />
          )}
          <Box className={contentWrapper}>
            <Grid container spacing={2}>
              {getDataPairs(customer).map(({ title, content }) => (
                <Grid item xs={6} sm={12}>
                  <DataPair
                    key={title}
                    title={title}
                    content={content}
                    clipContent
                    placeholder={isPlaceholder}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
        {onDataEdit && (
          <Box
            className={clsx([contentWrapper, !smallScreen && buttonWrapper])}
          >
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={onDataEdit}
              disabled={isPlaceholder}
            >
              Modifica dati
            </Button>
          </Box>
        )}
      </Container>
    </Paper>
  );
}

export default memo(CustomerDataRenderer);
