import React from "react";
import {
  Avatar,
  IconButton,
  Grid,
  makeStyles,
  Typography,
  Badge,
} from "@material-ui/core";
import SettingsOutlined from "@material-ui/icons/SettingsOutlined";
import { UserDetails } from "../../defines";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.primary.light,
    "&:not(:hover)": {
      color: theme.palette.primary.main,
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
    borderRadius: theme.spacing(3),
  },
  avatarElement: {
    height: "32px",
    width: "32px",
  },
  iconWrapper: {
    position: "relative",
    top: "2px",
  },
}));

/**
 * @param {{userDetails: UserDetails}} param0
 */
export default function UserManagementButton({ userDetails, onEdit }) {
  const { wrapper, avatarElement, iconWrapper } = useStyles();
  const { avatar, logged } = userDetails;
  const avatarContent = <Avatar src={avatar} className={avatarElement} />;
  return (
    <IconButton onPress={onEdit} className={wrapper}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          {logged ? (
            <Badge color="secondary" badgeContent="">{avatarContent}</Badge>
          ) : (
            avatarContent
          )}
        </Grid>
        <Grid item className={iconWrapper}>
          <SettingsOutlined />
        </Grid>
      </Grid>
    </IconButton>
  );
}
