import { Cart, cartType } from "../../model";
import settings from "../settings";
import deliveryZones from "./deliveryZones";
import wrappers from "../hateoas";
import customers from "./customers";

const n = settings.nCustomers;

function getStatus(i) {
  if (i !== 0) {
    if (i % 5 === 0) {
      return "ABORTED";
    }
    if (i % 4 === 0) {
      return "CONFIRMED";
    }
    if (i % 3 === 0) {
      return "PAID";
    }
    if (i % 2 === 0) {
      return "CLOSED";
    }
    return "OPEN";
  }
  return "ACTIVE";
}

function getType(v) {
  return cartType[Object.getOwnPropertyNames(cartType)[v]];
}

/**
 * @type {Cart[]}
 */
const carts = [];

for (let i = 0; i < n; i++) {
  carts.push(
    wrappers.cart({
      id: i,
      code: i.toString(),
      customer: customers[i].id,
      date: `2022-03-${((17 + i) % 31) + 1}`,
      time: "18:30",
      status: getStatus(i),
      cost: 11 + 5 * i,
      origin: i >= n / 2 ? "app" : "cms",
      type: getType(i % 3),
      guests: 2,
      deliveryCost: { cost: 5 },
      deliveryZone: deliveryZones[i % 3],
      address: "delivery address",
      cartPaymentType: i % 2 ? "direct" : "paypal",
      productCount: 3,
      productsNum: 3,
      notes:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    })
  );
}

carts.push(
  wrappers.cart({
    id: n,
    code: n.toString(),
    customer: 0,
    date: `2022-03-02 + 1}`,
    time: "18:30",
    status: "OPEN"
  })
)

export default carts;
