import { FetchRequest } from "@italwebcom/augmented-fetch";
import setHeaders from "./setHeaders";

/**
 * @typedef {Record<string, any>} R
 * @param {{request: FetchRequest, headers: R | () => R, decoratorName?: string}} param0
 * @returns
 */
function decorateRequest({ request, headers, decoratorName }) {
    request.withDecorator({
        name: decoratorName || "headers_adder",
        process: init => setHeaders(init, headers)
    })
    return request;
}

export default decorateRequest;
