import React, { memo, useCallback, useMemo, useState } from "react";
import {
  AppBar,
  Container,
  Grid,
  Grow,
  IconButton,
  makeStyles,
  TextField,
} from "@material-ui/core";
import ClearSharp from "@material-ui/icons/ClearSharp";
import MenuSharp from "@material-ui/icons/MenuSharp";
import SearchSharp from "@material-ui/icons/SearchSharp";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  logo: {
    display: "block",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "64px"
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "48px"
    }
  },
  rightContentStyle: {
    marginLeft: "auto",
  },
  hidden: {
    display: "none",
  },
  wrapper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

function Logo({ logo, alt }) {
  const { logo: logoStyle } = useStyles();
  return <img src={logo} alt={alt} className={logoStyle} />;
}

function AppButton({ Icon, color, onClick }) {
  return (
    <IconButton onClick={onClick} style={{ color }}>
      <Icon color="inherit" />
    </IconButton>
  );
}

function SearchInput({ onSearch }) {
  const onActualSearch = useCallback(
    (e) => onSearch(e.target.value, e.target),
    [onSearch]
  );
  return (
    <TextField
      autoFocus
      fullWidth
      type="text"
      placeholder="Inserisci un termine per la ricerca..."
      onChange={onActualSearch}
      variant="standard"
      InputProps={{
        disableUnderline: true,
      }}
    />
  );
}

/**
 * @typedef {{
 *      searchContent?: ReactNode,
 *      rightContent?: ReactNode,
 *      leftContent?: ReactNode,
 *      onMenu?: Function,
 *      logo?: string,
 *      onSearch: (query: string, target: HTMLInputElement) => void
 * }} HulkAppBarProps
 */

/**
 * @param {HulkAppBarProps} param0
 */
function HulkAppBar({
  logo,
  searchContent,
  onSearch,
  rightContent,
  leftContent,
  onMenu,
  position,
  padding
}) {
  const { rightContentStyle, hidden, wrapper } = useStyles();
  const [search, setSearch] = useState(false);
  const toggleSearch = useCallback(() => setSearch((s) => !s), [setSearch]);
  const isAutoSearch = Boolean(onSearch);
  const hasSearch = useMemo(
    () => onSearch || searchContent,
    [onSearch, searchContent]
  );
  return (
    <AppBar variant="elevation" color="inherit" position={position || "fixed"}>
      <Container maxWidth={false} className={clsx([padding && wrapper])}>
        {!search && (
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            {logo && (
              <Grid item>
                <Logo logo={logo} alt="logo" />
              </Grid>
            )}
            {onMenu && <Grid item>
              <AppButton Icon={MenuSharp} onClick={onMenu} />
            </Grid>}
            {hasSearch && (
              <Grid item>
                <AppButton Icon={SearchSharp} onClick={toggleSearch} />
              </Grid>
            )}
            {leftContent && <Grid item xs>{leftContent}</Grid>}
            {rightContent && <Grid item className={rightContentStyle}>
              {rightContent}
            </Grid>}
          </Grid>
        )}
        {hasSearch && <Grow
          in={search}
          className={clsx([!search && hidden])}
          mountOnEnter={isAutoSearch}
          unmountOnExit={isAutoSearch}
        >
          <Grid container alignItems="center">
            <Grid item xs>
              {isAutoSearch ? (
                <SearchInput onSearch={onSearch} />
              ) : (
                searchContent
              )}
            </Grid>
            <Grid item>
              <AppButton Icon={ClearSharp} onClick={toggleSearch} />
            </Grid>
          </Grid>
        </Grow>}
      </Container>
    </AppBar>
  );
}

export default memo(HulkAppBar);