import { withDefaultHateoas } from "../auxiliary/getDefaultHateoasData";
import links from "../../links";
import { Tag } from "../../../model";

/**
 * @param {Tag} tagData 
 * @returns 
 */
export default function tagHateoasWrapper(tagData) {
  let i = tagData.id;
  let c = withDefaultHateoas(links.category.base, tagData);
  c._links.children = {
    href: links.category.childrenElements(i),
    templated: true,
  };
  return c;
}
