import React, { memo } from "react";
import { FormComponentProps } from "@italwebcom/react-generic-form";

/**
 * @param {FormComponentProps} param0
 * @returns
 */
function Form({ onSubmit, children }) {
  return <form onSubmit={onSubmit}>{children}</form>;
}

export default memo(Form);