import React, { memo, useCallback, useMemo } from "react";
import { ItemProps } from "../defines";
import useTabs from "../hooks/useTabs";
import {makeStyles} from "@material-ui/core"
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import Image from "../Image";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    cursor: "pointer",
  },
  contentWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  verticalStyle: {
    flexDirection: "column",
  },
  underline: {
    height: "2px",
    transitionProperty: "width",
    transitionDuration: "500ms",
    width: "0px",
    "&.selected": {
      width: "100%",
    },
  },
  underlineWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  image: {
    height: "25px",
    width: "25px",
    display: "block",
    borderRadius: "50%",
  },
  imageWrapper: {
    marginRight: theme.spacing(1),
  },
  label: {
    whiteSpace: "nowrap",
  },
  flexStyle: {
    flex: 1,
  },
}));

const IconImageRenderer = memo(({ icon, image, alt, vertical }) => {
  const { image: imageStyle, imageWrapper } = useStyles();
  if (icon || image) {
    return (
      <Box className={clsx([!vertical && imageWrapper])}>
        {icon || <Image className={imageStyle} src={image} alt={alt} />}
      </Box>
    );
  }
  return null;
});

/**
 * @returns
 * @param {ItemProps} param0
 */
function Item({ label, value, icon, image, flex, vertical }) {
  const { selected, onChange, styling } = useTabs();
  const {
    wrapper,
    contentWrapper,
    underline,
    underlineWrapper,
    label: labelStyle,
    flexStyle,
    verticalStyle,
  } = useStyles();
  const isSelected = useMemo(() => selected === value, [selected, value]);
  const onActualChange = useCallback(() => onChange(value), [value, onChange]);
  return (
    <Box
      component="article"
      className={clsx([wrapper, flex && flexStyle])}
      role="button"
      onClick={onActualChange}
      style={{
        color: isSelected ? styling.colors.selected : styling.colors.default,
      }}
    >
      <Box
        component="section"
        className={clsx([
          contentWrapper,
          isSelected && "selected",
          vertical && verticalStyle,
        ])}
      >
        <IconImageRenderer
          icon={icon}
          image={image}
          alt={label}
          vertical={vertical}
        />
        <Typography component="p" variant="body2" className={labelStyle}>
          {label}
        </Typography>
      </Box>
      <Box component="footer" className={underlineWrapper}>
        <Box
          className={clsx([underline, isSelected && "selected"])}
          style={isSelected ? { backgroundColor: styling.colors.selected } : {}}
        />
      </Box>
    </Box>
  );
}

export default memo(Item);
