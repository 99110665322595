import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import React from "react";

export default function OtherwiseSeparator() {
  return (
    <Box display="flex" alignItems="center">
      <Box
        flexGrow={1}
        style={{ height: "1px", backgroundColor: "rgb(175, 175, 175)" }}
      />
      <Typography
        component="p"
        variant="subtitle2"
        style={{
          marginLeft: "1rem",
          marginRight: "1rem",
          color: "rgb(175, 175, 175)",
        }}
      >
        oppure
      </Typography>
      <Box
        flexGrow={1}
        style={{ height: "1px", backgroundColor: "rgb(175, 175, 175)" }}
      />
    </Box>
  );
}
