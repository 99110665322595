import links from "../../links";
import {ProductCostAttributes, ProductCost} from "../../../model";

/**
 * @param {ProductCostAttributes} data 
 * @return {ProductCost}
 */
export default function productCostWrapper(data) {
  const i = data.product.id;
  const c = data.costList.id;
  return {
    ...data,
    _links: {
      self: {
        href: links.product.related.cost(i, c)
      },
      fetch: {
        href: links.product.costs(i),
        templated: true
      }
    },
    _templates: {
      delete: {
        method: "DELETE",
        target: links.product.related.cost(i, c),
        properties: []
      },
      update: {
        method: "PUT",
        target: links.product.related.cost(i, c),
        properties: []
      },
    },
  };
}
