//const redirectUri = "http://localhost:3000/webapp/redirect";
const redirectUri = "https://animacore.italweb.agency/webapp/redirect";

const config = {
  clientId: "base-client",
  clientSecret: "a",
  issuerUri: "https://auth.italweb.agency/auth/realms/AnimaCore/.well-known/openid-configuration",
  redirectUri: redirectUri,
};

export default config;