import React, { memo, ReactNode } from "react";
import Crud from "../Crud";
import useCartWrapper from "../../../../../../hooks/wrappers/useCartWrapper";
import { useAlertFunctions } from "../../../../../../components/misc/AlertProvider";
import useCartProductFunctions from "../../hooks/useCartProductFunctions";
import useTempCartAuthenticationSetter from "../../../hooks/useTempCartAuthenticationSetter";
import { useMiscRefs } from "../Main";

/**
 * @param {{cart: number, children: ReactNode[]}} param0
 * @returns
 */
function CartProductTempProvider({ cart, children }) {

  const wrapper = useCartWrapper();

  const { cartRef, childRef } = useMiscRefs({ cart });
  const { onAdd: onMessageAdd } = useAlertFunctions();
  const requestProcessor = useTempCartAuthenticationSetter();

  const { 
    onActualError: onError, 
    onActualSuccess: onSuccess, 
    onActualSave: onSave, 
    onDelete, 
    onFetch 
  } = useCartProductFunctions({ 
    wrapper, 
    cartRef, 
    onMessageAdd, 
    id: "temp",
    requestProcessor
  });

  return (
    <Crud
      onSave={onSave}
      onDelete={onDelete}
      onFetch={onFetch}
      onSuccess={onSuccess}
      onError={onError}
      cart={cart}
      ref={childRef}
    >
      {children}
    </Crud>
  );
}

export default memo(CartProductTempProvider);
