import { createContext } from "react";
import { HulkAlertContainerMessage, HulkAlertContainerInput } from "../defines";

/**
 * @type {{
 *      messages: HulkAlertContainerMessage[], 
 *      onAdd: (message: HulkAlertContainerInput) => void, 
 *      onRemove: (mex: HulkAlertContainerMessage) => void
 * }}
 */
const args = {};

export default createContext(args);
