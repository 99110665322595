import Gate from "./components/Gate";
import Provider from "./components/Provider";
import Switch from "./components/Switch";
import Setter from "./components/Setter";
import useCredentials from "./hooks/useCredentials";
import useAuthorized from "./hooks/useAuthorized";
import useSetCredentials from "./hooks/useSetCredentials";

const Auth = {
  Setter,
  Gate,
  Provider,
  Switch,
  useCredentials,
  useAuthorized,
  useSetCredentials,
};

export {
  Auth,
  Gate,
  Provider,
  Switch,
  Setter,
  useCredentials,
  useAuthorized,
  useSetCredentials,
};

