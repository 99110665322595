import { Storage } from "@italwebcom/anema-react-components/Misc";

/**
 * @returns {Record<string, any>}
 * @param {string} c
 */
function proc(c) {
  let s = c.split("; ");
  let out = {};
  for (let entry of s) {
    let sIn = entry.split("=");
    out[sIn[0]] = sIn[1];
  }
  return out;
}

function isFalsy(v) {
  return v === null || typeof v === "undefined" || v === false;
}

const defaultCookieAccessors = {
  get: () => document.cookie,
  set: (k, v) => {
    document.cookie[k] = v;
  },
};

/**
 * @typedef {{
 *      get: () => string,
 *      set: (k: string, v: any) => void
 * }} CookieAccessors
 */
export class CookieStorage {
  _accessors;

  /**
   * @param {CookieAccessors} accessors
   */
  constructor(accessors) {
    this._accessors = accessors || defaultCookieAccessors;
  }

  /**
   * @param {CookieAccessors} accessors
   */
  accessors(accessors) {
    this._cookie = accessors;
  }

  /**
   * @param {string} k
   * @returns
   */
  get(k) {
    let c = this._accessors.get();
    if (c) {
      let entries = proc(c);
      let v = entries[k];
      /* try to parse serialized JSON object */
      try {
        v = JSON.parse(v);
      } catch (e) {}
      return v;
    }
    return null;
  }

  /**
   * @param {string} k
   * @param {any} v
   * @param {any} options
   * @returns
   */
  set(k, v, options) {
    if (typeof v === "object") {
      v = JSON.stringify(v);
    }
    this._accessors.set(k, v);
    return this;
  }
}

class LocalStorage {
  _session;

  /**
   * @param {boolean} session
   */
  constructor(session) {
    this._session = session;
  }

  get(k) {
    let v;
    if (this._session) {
      v = window.sessionStorage.getItem(k);
    } else {
      v = window.localStorage.getItem(k);
    }
    if(typeof(v) === "string") {
      try {
        v = JSON.parse(v);
      } catch (e) {}
    }
    return v;
  }

  set(k, v) {
    let s;
    if (this._session) {
      s = window.sessionStorage;
    } else {
      s = window.localStorage;
    }
    if (!isFalsy(v)) {
      if (typeof v === "object") {
        v = JSON.stringify(v);
      }
      s.setItem(k, v);
    } else {
      s.removeItem(k);
    }
  }
}

/**
 * @typedef {"cookie" | "local"} StorageType
 *
 * @returns
 * @param {{type: "cookie", accessors: CookieAccessors} | {type: "local", session: boolean}} args
 */
export function webStorage(args) {
  switch (args.type) {
    case "local":
      return new LocalStorage(args.session);
    default:
      return new CookieStorage(args.accessors);
  }
}
