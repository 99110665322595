import {createTheme, responsiveFontSizes} from "@material-ui/core";

export default responsiveFontSizes(
    createTheme({
        palette: {
            primary: {
                light: "rgb(227, 242, 253)",
                main: "rgb(30, 136, 229)",
                dark: "rgb(94, 53, 177)",
                contrastText: "#fff"
            },
            secondary: {
                main: "rgb(247, 53, 53)",
                light: "rgb(240, 122, 122)",
                dark: "rgb(242, 155, 155)",
                contrastText: "rgb(240, 240, 240)"
            },
            background: {
                default: "#fff"
            }
        },
        typography: {
            body1: {
                fontFamily: "Roboto, sans-serif"
            },
            h1: {
                fontFamily: "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
                fontSize: "2rem",
                fontWeight: "bold"
            },
            h2: {
                fontSize: "1.5rem",
                fontFamily: "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'"
            },
            h3: {
                fontSize: "1.25rem",
                fontFamily: "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
                fontWeight: "bold"
            },
            h4: {
                fontSize: "1rem",
                fontFamily: "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
                marginBottom: "0.25em"
            },
            h5: {
                fontSize: "0.5rem",
                fontFamily: "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
                marginBottom: "0.25em"
            }
        },
        overrides: {
            MuiNativeSelect: {
                root: {
                    marginTop: "0.25em"
                },
                select: {
                    padding: "0.75em",
                    border: "1px solid rgb(222, 222, 222)"
                }
            },
            MuiCard: {
                root: {
                    boxShadow: "none",
                    border: "1px solid rgb(240, 240, 240)",
                    borderRadius: "8px",
                    "&:hover": {
                        boxShadow: "1px 2px 3px 2px rgb(245, 245, 245)"
                    }
                }
            },
            MuiMenu: {
                list: {
                    padding: 0
                }
            },
            MuiChip: {
                root: {
                    padding: 0,
                    height: "24px"
                }
            },
            MuiFormControlLabel: {
                label: {
                    color: "rgb(75, 75, 75)"
                }
            }
        }
    })
);