import { useEntityManagerValues } from "@italwebcom/react-entity-manager";
import { useMemo } from "react";
import useClosingDayWrapper from "../../../../hooks/wrappers/useClosingDayWrapper";

/**
 * @returns
 */
export default function useProviderData() {
  const { elements, onFilterReplace, loading } = useEntityManagerValues();
  const wrapper = useClosingDayWrapper();
  return useMemo(
    () => ({
      elements,
      loading,
      onActualFetch: (args) => wrapper.fetch(args).json(),
      onActualMonthChange: (m) =>
        onFilterReplace(
          { attribute: "month" },
          { attribute: "month", value: m }
        ),
    }),
    [wrapper, onFilterReplace, elements, loading]
  );
}
