import React, { memo, useContext } from "react";
import { SliderProps } from "../defines";
import context from "../context";

/**
 *
 * @param {SliderProps} param0
 * @returns
 */
function Slider({ Renderer, RendererProps }) {
  const { onChangeValue } = useContext(context);
  return <Renderer {...(RendererProps || {})} onChange={onChangeValue} />;
}

export default memo(Slider);
