import { makeStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import WarningOutlined from "@material-ui/icons/WarningOutlined";
import clsx from "clsx";
import React, { memo } from "react";

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: theme.spacing(15),
    width: theme.spacing(15),
    borderRadius: "50%",
    margin: "auto",
  },
  iconWrapper: {
    color: theme.palette.grey["400"],
    fontSize: theme.spacing(6),
    display: "flex",
    justifyContent: "center",
  },
  title: {
    color: theme.palette.grey["700"],
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  content: {
    color: theme.palette.grey["500"],
    textAlign: "center",
  },
  text: {
    textAlign: "center",
  },
}));

/**
 * @param {{ title: string, content: string, onRedirect?: function, redirectLabel?: string }} param0
 */
function MissingElementRenderer({
  title,
  content,
  onRedirect,
  redirectLabel,
  Icon,
  additionalContent,
}) {
  const {
    title: titleStyle,
    content: contentStyle,
    iconWrapper,
    text,
  } = useStyles();
  Icon = Icon || WarningOutlined;
  return (
    <Container maxWidth="md">
      <Grid container spacing={3} component="article">
        <Grid item xs={12}>
          <Box className={iconWrapper}>
            <Icon fontSize="inherit" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box component="header">
            <Typography
              component="h5"
              variant="h5"
              gutterBottom
              className={clsx([text, titleStyle])}
            >
              {title}
            </Typography>
          </Box>
          <Typography
            component="section"
            variant="body1"
            className={clsx([text, contentStyle])}
          >
            {content}
          </Typography>
        </Grid>
        {onRedirect && redirectLabel && (
          <Grid item xs={12}>
            <Button
              fullWidth
              type="button"
              variant="outlined"
              color="primary"
              onClick={onRedirect}
            >
              {redirectLabel}
            </Button>
          </Grid>
        )}
        {additionalContent && (
          <Grid item xs={12}>
            {additionalContent}
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default memo(MissingElementRenderer);
