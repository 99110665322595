import { createContext } from "react";
import { SelectedProductFeature } from "../../../../../__domain/model";

/**
 * @typedef {{selectionIndex: number, features: SelectedProductFeature[]}} SelectedProductFeatureGroup
 * @type {{
 *      loading: boolean, 
 *      onSave: (feature: SelectedProductFeature, selected: boolean) => Promise<any>,
 *      elements: SelectedProductFeature[],
 *      groupedElements: SelectedProductFeatureGroup[],
 *      onRefresh: Function
 * }}
 */
const args = {};
const context = createContext(args);

export default context;