import { useMemo } from "react";
import { useRuntimeContext } from "../components/misc/RuntimeProvider";

/**
 * @param {string} name
 * @returns
 */
export default function useWrapper(name) {
    const args = useRuntimeContext();
    const {wrappers} = args;
    return useMemo(() => wrappers && wrappers[name], [wrappers, name]);
}