import { FeatureValue } from "../../model";
import { withDefaultHateoas } from "../hateoas/auxiliary/getDefaultHateoasData";
import links from "../links";

const n = 30;

/**
 * @type {FeatureValue[]}
 */
let featureValues = [];

for (let i = 0; i < n; i++) {
  let pf = Math.floor(i / 3);
  let fv = withDefaultHateoas(
    links.productFeature.addValue(pf).substring(1),
    {
      value: `value${i}`,
      productFeature: pf,
      order: i,
    },
    "order"
  );
  featureValues.push(fv);
}

export default featureValues;
