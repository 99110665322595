import { cartType } from "../../../../model";
import dataRenderers from "./dataRenderers";

export function getDeliveryZone(cart) {
    if (cart.type === cartType.delivery) {
      return [
        {
          label: "Zona consegna",
          attribute: "delivery_zone",
          renderer: dataRenderers.deliveryZone,
          value: dataRenderers.deliveryZone(cart),
        },
      ];
    }
    return [];
  }
  
  export  function getPaymentType(cart) {
    return [
      {
        label: "Metodo di pagamento",
        attribute: "cartPaymentType",
        renderer: dataRenderers.cartPaymentType,
        value: dataRenderers.cartPaymentType(cart)
      }
    ];
  }