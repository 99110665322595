import React, { useMemo } from "react";
import { Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";

function capitalize(s) {
  return s.substring(0, 1).toUpperCase() + s.substring(1);
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    transitionProperty: "background-color",
    transitionDuration: "500ms",
    textTransform: "unset",
    fontWeight: "bold",
    "&.secondary": {
      color: "#db2222",
      backgroundColor: "#f7c0bf",
      border: "1px solid #f7c0bf",
      borderRadius: "5px",
    },
    "&.secondary:hover": {
      backgroundColor: "#db2222",
      color: "white",
    },
    "&.dark": {
      color: "white",
      backgroundColor: "#4d7df2",
      border: "1px solid white",
      borderRadius: "5px",
    },
    "&.dark:hover": {
      backgroundColor: "#335ec7"
    },
    "&.light": {
      backgroundColor: "rgb(223, 232, 253)",
      color: "#4d7df2",
    },
    "&.light:hover": {
      backgroundColor: "#4d7df2",
      color: "white",
    },
    "&.outlined": {
      color: "#4d7df2",
      border: "1px solid rgba(77, 125, 242, 0.5)",
    },
    "&.outlined:hover": {
      backgroundColor: "rgb(223, 232, 253, 0.5)",
      border: "1px solid rgba(77, 125, 242)",
    },
    "&.lightOutlined": {
      color: "white",
      border: "1px solid white",
    },
    "&.lightOutlined:hover": {
      fontWeight: "bold",
      border: "3px solid white",
    },
  },
  opaque: {
    opacity: 0.5,
  },
}));

/**
 * @typedef {"light" | "dark" | "secondary" | "outlined" | "lightOutlined"} HulkButtonVariant
 */

/**
 * @returns
 * @param {{
 *      variant: HulkButtonVariant,
 *      label?: string,
 *      onClick?: Function,
 *      noCapitalize?: boolean,
 *      className?: string
 * }} param0
 */
export default function HulkButton({
  variant,
  label,
  onClick,
  noCapitalize,
  className,
  fullWidth,
  type,
  disabled,
  style,
  startIcon,
  endIcon,
  id
}) {
  variant = variant || "light";
  const { wrapper, opaque } = useStyles();
  const capLabel = useMemo(
    () => (noCapitalize ? label : label.split(" ").map(capitalize).join(" ")),
    [label, noCapitalize]
  );
  return (
    <Button
      fullWidth={fullWidth}
      type={type || "button"}
      variant="text"
      color="inherit"
      className={clsx([wrapper, variant, className, disabled && opaque])}
      onClick={onClick}
      disabled={disabled}
      style={style}
      startIcon={startIcon}
      endIcon={endIcon}
      id={id}
    >
      {capLabel}
    </Button>
  );
}
