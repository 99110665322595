import React, {
  ReactChildren,
  useState,
  memo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import Context from "../../context";
import { AuthorizationVerifier, AuthError } from "../../defines";
import { observe, Observable, detach } from "@italwebcom/observer-utils";

/**
 * @typedef {{
 *      initialCredentials: any,
 *      isAuthorized: AuthorizationVerifier,
 *      errorObservable: ErrorObservable,
 *      children: ReactChildren
 * }} ContextProviderProps
 */

/**
 * @param {ContextProviderProps} param0 
 * @returns 
 */
function ContextProvider({
  initialCredentials,
  isAuthorized,
  errorObservable,
  children,
}) {
  const [state, setState] = useState({
    credentials: initialCredentials,
    authError: null,
  });
  const onSetCredentials = useCallback(
    (c) => setState({ credentials: c, authError: null }),
    [setState]
  );
  const onSetAuthError = useCallback(
    (a) => {
      //console.log(`setting authError to ${a}`);
      setState({ credentials: null, authError: a });
    },
    [setState]
  );
  const observer = useRef();
  useEffect(() => {
    observer.current = observe(errorObservable)
      .on("authError")
      .with(onSetAuthError)
      .build();
    return () =>
      observer.current && detach(observer.current).from(errorObservable);
  }, [onSetAuthError, errorObservable]);
  const { credentials, authError } = state;
  return (
    <Context.Provider
      value={{
        credentials,
        authError,
        isAuthorized,
        setCredentials: onSetCredentials,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default memo(ContextProvider);
/*
export default class ContextProvider extends PureComponent {

  state;

  constructor(props) {
    super(props);
    this._setCredentials = this.setCredentials.bind(this);
    this.state = {credentials: null, authError: null};
  }

  componentDidCatch(error) {
    const {getAuthError} = this.getProps();
    const authError = getAuthError(error);
    if(authError) {
        this.setState({credentials: null, authError});
    }
  }

  getProps() {
    return this.props;
  }

  setCredentials(credentials) {
    this.setState({ credentials, authError: null });
  }

  componentDidMount() {
    const { initialCredentials } = this.getProps();
    if (initialCredentials != null) {
      this.setCredentials(initialCredentials);
    }
  }

  render() {
    const { isAuthorized, children } = this.getProps();
    const { credentials, authError } = this.state;
    return (
      <Context.Provider
        value={{
          credentials,
          isAuthorized,
          setCredentials: this._setCredentials,
          authError
        }}
      >
        {children}
      </Context.Provider>
    );
  }
}*/
