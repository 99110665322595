import React, { memo, useCallback } from "react";
import { useEntityEdit } from "@italwebcom/custom-react-hooks";
import {makeStyles} from "@material-ui/core"
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import PaymentMethodSetter from "../../CartPaymentMethodSetter";
import { HulkButton, HulkCartOverview } from "@italwebcom/misc-ui-components";
import { useAuthorized } from "@italwebcom/react-auth-provider";
import dataRenderers from "../dataRenderers";
import { cartType } from "../../../../../model";
import { printStatus } from "../../attributes";
import { getDeliveryZone, getPaymentType } from "../auxiliary";
import { Carts } from "@italwebcom/anema-react-components";

const { useCartDerivedAttributes } = Carts.hooks;

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    marginBottom: theme.spacing(2),
  },
}));

function CartCustomerRenderer({
  cart,
  onConfirm,
  onEditProducts,
  onEditCart,
  additionalContent,
  loading,
  onRedirectToUserArea,
}) {
  const authorized = useAuthorized("standard");
  const { onSet, element } = useEntityEdit(cart);
  const { formWrapper } = useStyles();
  const onActualSubmit = useCallback(
    (e) => {
      e.preventDefault();
      onConfirm(element);
    },
    [element, onConfirm]
  );

  const {
    editable,
    payable,
    cashPayment,
    readOnly,
    emptyReservation,
    paymentSelectable,
  } = useCartDerivedAttributes(cart);
  const dCost = dataRenderers.deliveryCost(cart);

  return (
    <form onSubmit={onActualSubmit}>
      <Box className={formWrapper}>
        {/*<Box marginBottom="1rem">
          <CartAlert cart={cart} />
        </Box>*/}
        <HulkCartOverview
          onDataEdit={editable && onEditCart}
          onProductsEdit={editable && onEditProducts}
          additionalCost={
            dCost &&
            cart.type === cartType.delivery && {
              description: "Costo consegna",
              amount: `${dCost} €`,
            }
          }
          itemCount={cart.productsNum || "?"}
          totalCost={`${cart.cost || cart.totalCost || "---"} €`}
          additionalOverviewText={`Ti ricordiamo che la tua prenotazione, a seguito del pagamento, rimarrà in sospeso fino alla nostra conferma. ${
            !authorized
              ? "Puoi controllare lo stato in ogni momento accedendo all'area utente."
              : ""
          }`}
          additionalCartData={[
            {
              label: "Tipo",
              value: dataRenderers.type(cart),
            },
            {
              label: "Stato prenotazione",
              value: printStatus(cart.status),
            },
            {
              label: "Data ed ora",
              value: dataRenderers.dateAndHour(cart),
            },
            {
              label: "Note",
              value: cart.notes || "---",
            },
          ]
            .concat(getDeliveryZone(cart))
            .concat(getPaymentType(cart))}
          additionalOverviewContent={
            <Box>
              {paymentSelectable && (
                <Box marginBottom="1em">
                  <Typography
                    component="p"
                    variant="body1"
                    style={{ fontWeight: "bold" }}
                    gutterBottom
                  >
                    Metodo di pagamento
                  </Typography>
                  <PaymentMethodSetter
                    element={element}
                    onSet={onSet}
                    editable={payable}
                  />
                </Box>
              )}
              {readOnly || (!editable && !cart.code && cashPayment) ? (
                <HulkButton
                  fullWidth
                  variant="light"
                  label="Torna all'area utente"
                  noCapitalize
                  onClick={onRedirectToUserArea}
                  type="button"
                />
              ) : (
                <HulkButton
                  fullWidth
                  disabled={loading || !payable}
                  label={
                    (!editable && cashPayment) || emptyReservation
                      ? "Conferma prenotazione"
                      : "Paga"
                  }
                  noCapitalize
                  variant="dark"
                  startIcon={
                    loading && <CircularProgress size={12} color="white" />
                  }
                  onClick={payable ? undefined : onActualSubmit}
                  type={payable ? "submit" : "button"}
                />
              )}
              {additionalContent}
            </Box>
          }
        >
          <Box padding="1em" style={{ backgroundColor: "white" }}>
            {dataRenderers.cartProducts(cart, editable)}
          </Box>
        </HulkCartOverview>
      </Box>
    </form>
  );
}

export default memo(CartCustomerRenderer);
