import { headerManager } from "@italwebcom/augmented-fetch";

function setHeaders(a, headers) {
  let h = headerManager(a);
  let actualHeaders;
  if (typeof headers === "function") {
    actualHeaders = headers();
  } else {
    actualHeaders = headers;
  }
  if (actualHeaders) {
    for (let v in actualHeaders) {
      h.set(v, actualHeaders[v]);
    }
  }
  return h.get();
}

export default setHeaders;