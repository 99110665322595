export function urlencode(stuff) {
  let names = Object.getOwnPropertyNames(stuff);
  return names
    .filter((n) => stuff[n])
    .map((n) => `${n}=${stuff[n]}`)
    .reduce((a, b) => `${a}&${b}`);
}

export function urldecode(encoded) {
  let stuff = encoded.split("&");
  let out = {};
  for (let el of stuff) {
    let aux = el.split("=");
    out[aux[0]] = aux[1];
  }
  return out;
}
