import AuthenticationProvider from "./Provider";
import CompositeGate from "./CompositeGate";
import useSetCredentials from "./hooks/useSetCredentials";
import useTempCustomerSetCredentials from "./hooks/useTempCustomerSetCredentials";
import useAuthContext from "./hooks/useAuthContext";
import useAuthenticatedRequest from "./hooks/useAuthenticatedRequest";
import AuthenticationContext from "./context";

const components = {
    AuthenticationProvider,
    CompositeGate,
    AuthenticationContext
};

const hooks = {
    useSetCredentials,
    useTempCustomerSetCredentials,
    useAuthContext,
    useAuthenticatedRequest
};

export {components, hooks};