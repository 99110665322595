import React from "react";
import CustomerEditorForm from "./Form";
import Grid from "@material-ui/core/Grid";
import CustomerDataRenderer from "../CustomerDashboard/CustomerDataRenderer";
import { Customers } from "@italwebcom/anema-react-components";
import MainContentPanel from "../../../MainContentPanel";
import { useRuntimeContext } from "../../../../contexts";

const { useCustomer } = Customers.hooks;

function CustomerEditor({ headerProps }) {
  const { customer, onSave, loading, onDelete } = useCustomer();
  const { smallScreen } = useRuntimeContext();
  let editPanel = (
    <MainContentPanel
      //title="Inserimento nuovi dati"
      //headerProps={headerProps}
      header={<span />}
    >
      <CustomerEditorForm
        customer={customer}
        onSubmit={onSave}
        loading={loading}
      />
    </MainContentPanel>
  );
  if (smallScreen) {
    return editPanel;
  } else {
    return (
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3}>
          <CustomerDataRenderer
            customer={customer}
            avatarInData
            dataLabel="Dati attuali"
          />
        </Grid>
        <Grid item xs>
          {editPanel}
        </Grid>
      </Grid>
    );
  }
}

export default CustomerEditor;
