import { useEffect, useRef } from "react";
import { Observable, observe, detach } from "@italwebcom/observer-utils";

/**
 * @template {string} Events
 * @typedef {{
 *      event: Events,
 *      handler: (args: any) => void
 * }} EventHandler
 */

/**
 * @template {string} Events
 * @param {Observable<Events>} observable
 * @param {EventHandler<Events>[]} handlers
 */
export default function useObservers(observable, handlers) {
  const observers = useRef({});
  useEffect(() => {
    handlers.forEach(({ event, handler }) => {
      observers.current[event] = observe(observable)
        .on(event)
        .with(handler)
        .build();
    });
    return () => {
      handlers.forEach(({ event }) => {
        const currentObserver = observers.current[event];
        detach(currentObserver).from(observable);
      });
    };
  }, [observable, handlers]);
}
