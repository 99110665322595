import React, { memo, useCallback, useContext } from "react";
import useStyles from "../styles";
import Context from "../context";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";

const HeadCell = memo(({ id, label, sortable }) => {
  const { header, arrow, selectedArrow } = useStyles();
  const { sorter, onSorterChange } = useContext(Context);
  const isSelected = sorter && sorter.attribute === id;
  const onActualSorterChange = useCallback(() => {
    let d = sorter.direction === "asc" ? "desc" : "asc";
    onSorterChange({ attribute: id, direction: d });
  }, [sorter, onSorterChange, id]);
  return (
    <TableCell key={id} className={header}>
      <Typography component="span" variant="body1">
        {label}
      </Typography>
      {sortable && (
        <Box
          className={clsx([arrow, isSelected && selectedArrow])}
          display="inline-block"
        >
          <IconButton
            color="inherit"
            size="small"
            onClick={onActualSorterChange}
            style={{ fontSize: "1rem", marginLeft: "0.5rem" }}
          >
            {sorter.direction === "asc" ? (
              <ArrowUpward color="inherit" fontSize="inherit" />
            ) : (
              <ArrowDownward color="inherit" fontSize="inherit" />
            )}
          </IconButton>
        </Box>
      )}
    </TableCell>
  );
});

export default HeadCell;
