import React, { ComponentType, memo, useContext } from "react";
import GenericInput from "../GenericInput";
import { fieldNames } from "../../fields";
import context from "../../context";
import {ClosingDay} from "../../../../../../__domain/model";

/**
 * @typedef {{
 *    closingDays: ClosingDay[],
 *    onChange: (date: string) => void,
 *    value: string
 * }} DateInputComponentProps
 */

/**
 * @returns
 * @param {{InputComponent: ComponentType<DateInputComponentProps>, testID?: string}} param0
 */
function DateInput({ InputComponent, testID }) {
  const {closingDays, closingDaysLoading: loading} = useContext(context);
  return (
    <GenericInput
      field={fieldNames.date}
      InputComponent={InputComponent}
      InputProps={{loading, closingDays, "data-testid": testID}}
      controlled
    />
  );
}

export default memo(DateInput);