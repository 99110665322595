import React from "react";
import {Typography, Divider, List, ListItem, ListItemText, ListItemIcon, Box, makeStyles, Grid, Collapse} from "@material-ui/core";
import {ReactNode, useState, createContext, useCallback, useEffect} from "react";
import clsx from "clsx";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

const SimpleListMenuContext = createContext({selected: null, setSelected: null, icons: true});
const useStyles = makeStyles(theme => ({
    sectionHeader: {
        fontWeight: "bold"
    },
    listItem: {
        borderRadius: theme.spacing(2),
        cursor: "pointer",
        marginBottom: theme.spacing(1),
        "&:hover": {
            backgroundColor: theme.palette.primary.light,
            "& svg": {
                color: theme.palette.primary.main
            },
            "& span": {
                color: theme.palette.primary.main
            }
        },
        "&.selected": {
            backgroundColor: theme.palette.primary.light,
            "& svg": {
                color: theme.palette.primary.main,
                fontWeight: "bold"
            },
            "& span": {
                color: theme.palette.primary.main,
                fontWeight: "bold"
            }
        },
        "& span": {
            position: "relative",
            top: "1px"
        }
    },
    innerContentWrapper: {
        marginLeft: theme.spacing(2)
    }
}));

/**
 * @typedef {{
 *      id: string,
 *      label?: string,
 *      icon: ReactNode,
 *      innerContent?: ReactNode,
 *      innerMenuSections? ListMenuProps,
 *      onSelect?: Function
 * }} ListMenuItem
 * 
 * @typedef {{
 *      title?: string,
 *      items: ListMenuItem[]
 * }} ListMenuSection
 * 
 * @typedef {{
 *      sections: ListMenuSection[],
 *      config: {
 *          dividers?: boolean,
 *          spacing?: number
 *      }
 * }} ListMenuProps
 */

/**
 * 
 * @param {{item: ListMenuItem}} param0 
 */
function ListMenuSectionItemRenderer({item}) {
    const {label, icon, id, onSelect, innerContent, innerMenuSections} = item;
    const {listItem, innerContentWrapper} = useStyles();
    return <SimpleListMenuContext.Consumer>
        {({selected, setSelected, icons}) => <Box>
            <ListItem 
                key={item.label} 
                className={clsx([listItem, (id === selected) && "selected"])}
                onClick={evt => setSelected({id, onSelect})}
            >
                <ListItemIcon>{icons ? icon : <FiberManualRecord style={{fontSize: "12px"}} />}</ListItemIcon>
                <ListItemText primary={label || id} />
            </ListItem>
            <Collapse in={(innerContent || innerMenuSections) && (id === selected)} collapsedSize={0}>
                <Box className={innerContentWrapper}>
                    {innerMenuSections ? <SimpleListMenu 
                        noSelect={(id !== selected)} 
                        sections={innerMenuSections} 
                        config={{dividers: false, spacing: 0}} 
                    /> : innerContent}
                </Box>
            </Collapse>
        </Box>}
    </SimpleListMenuContext.Consumer>
}

/**
 * @param {{section: ListMenuSection}} param0 
 */
function ListMenuSectionRenderer({section, config}) {
    const {title, items} = section;
    const {sectionHeader} = useStyles();
    return <Box component="article">
        {title && <Typography variant="body1" component="header" className={sectionHeader} gutterBottom>
            {title}
        </Typography>}
        <List>
            {items.map(item => <ListMenuSectionItemRenderer item={item} />)}
        </List>
        {config.dividers && <Divider />}
    </Box>
}

/**
 * @param {ListMenuProps} param0 
 */
export default function SimpleListMenu({sections, config, noSelect}) {
    config = config || {dividers: true, spacing: 2, icons: true};
    const [selected, setSelected] = useState(null);
    const onSetSelected = useCallback(({id, onSelect}) => {
        onSelect && onSelect(id);
        setSelected(id);
    }, [setSelected]);
    useEffect(() => {
        noSelect && setSelected(null);
    }, [noSelect]);
    return <SimpleListMenuContext.Provider value={{selected, setSelected: onSetSelected, icons: config.icons}}>
        <Grid container spacing={config.spacing}>
            {sections.map(section => <Grid item xs={12}>
                <ListMenuSectionRenderer key={section.title} section={section} config={config} />
            </Grid>)}
        </Grid>
    </SimpleListMenuContext.Provider>;
}