import {makeStyles} from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  filtererWrapper: { 
    alignSelf: "start", 
    position: "sticky", 
    top: "50px"
  },
  searchBarWrapper: { 
    position: "sticky", 
    top: "0px", 
    zIndex: 3
  },
  panelWrapper: {
    position: "sticky",
    left: 0,
    zIndex: 2,
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
    borderRadius: 0,
  },
  panelInnerWrapper: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
}));

export default useStyles;
