import React, { Fragment, memo } from "react";
import {
  Paper,
  Grid,
  Typography,
  makeStyles,
  Container,
  Divider,
  Box,
  ButtonGroup,
} from "@material-ui/core";
import HulkButton, { HulkButtonVariant } from "../../Inputs/HulkButton";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingBottom: theme.spacing(2),
  },
  title: {
    fontWeight: "bold",
  },
  subtitle: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  image: {
    marginTop: -theme.spacing(1),
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    display: "block",
    maxWidth: "100%",
  },
  imageWrapper: {
    position: "relative",
  },
  titleWrapper: {
    cursor: "pointer",
  },
  topRightPlacement: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  topLeftPlacement: {
    position: "absolute",
    left: theme.spacing(1),
    top: theme.spacing(1),
  },
  buttonGroup: {
    "& .button-group-secondary + .button-group-primary": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& .button-group-primary + .button-group-secondary": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
}));

/**
 * @typedef {"topRight" | "topLeft"} AdditionalContentPlacement
 * @typedef {{content: ReactNode, placement: AdditionalContentPlacement}} HulkCardAdditionalContent
 */

/**
 * @param {{
 *    avatar: string,
 *    title: string,
 *    subtitle: string,
 *    onSelect?: Function,
 *    onAction?: Function,
 *    actionLabel?: string,
 *    actionRightContent?: string,
 *    additionalContent?: HulkCardAdditionalContent,
 *    actionVariant: HulkButtonVariant,
 *    longSubtitle?: boolean
 * }} param0
 */
function HulkCard({
  avatar,
  title,
  subtitle,
  onSelect,
  onAction,
  actionLabel,
  actionRightContent,
  additionalContent,
  actionVariant,
  avatarStyle,
  longSubtitle,
  wrapperStyle,
  avatarHeight,
  avatarWidth,
  titleTypographyProps,
  subtitleTypographyProps,
  actionDivider
}) {
  const {
    image,
    imageWrapper,
    container,
    title: titleStyle,
    subtitle: subtitleStyle,
    wrapper,
    topLeftPlacement,
    topRightPlacement,
    buttonGroup,
    titleWrapper,
  } = useStyles();

  return (
    <Paper className={wrapper} style={wrapperStyle}>
      <Grid container className={container} spacing={2}>
        <Grid item xs={12}>
          <Box className={imageWrapper}>
            <img
              className={image}
              src={avatar}
              alt={title}
              style={avatarStyle}
              height={avatarHeight}
              width={avatarWidth}
            />
            {additionalContent && (
              <Box
                className={
                  additionalContent.placement === "topLeft"
                    ? topLeftPlacement
                    : topRightPlacement
                }
              >
                {additionalContent.content}
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Container maxWidth={false}>
            <Box role="button" onClick={onSelect} className={titleWrapper}>
              <Typography
                component="h6"
                variant="h6"
                className={titleStyle}
                gutterBottom={Boolean(subtitle)}
                {...(titleTypographyProps || {})}
              >
                {title}
              </Typography>
            </Box>
            {subtitle && (
              <Typography
                component="p"
                variant="body1"
                className={clsx([longSubtitle && subtitleStyle])}
                {...(subtitleTypographyProps || {})}
              >
                {subtitle}
              </Typography>
            )}
          </Container>
        </Grid>
        {(onAction || actionRightContent) && (
          <Fragment>
            {actionDivider && <Grid item xs={12}>
              <Divider />
            </Grid>}
            <Grid item xs={12}>
              <Container maxWidth={false}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {onAction && (
                    <Grid item>
                      <ButtonGroup className={buttonGroup}>
                        <HulkButton
                          variant={actionVariant || "light"}
                          label={actionLabel || "Seleziona"}
                          onClick={onAction}
                          className="button-group-primary"
                        />
                      </ButtonGroup>
                    </Grid>
                  )}
                  {actionRightContent && (
                    <Grid item xs>
                      {actionRightContent}
                    </Grid>
                  )}
                </Grid>
              </Container>
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Paper>
  );
}

export default memo(HulkCard);
