import runtimeContextArgs from "./runtimeContextArgs";
import oauthConfig from "./oauthConfig";
import pageStylingContextArgs from "./pageStylingContextArgs";

const productSelectionViewFilters = [
  "Stuzzicheria",
  "Antipasti",
  "Primi piatti",
  "Secondi piatti",
  "Pizze bianche",
  "Pizze rosse",
  "Panuozzi e calzoni",
  "Insalate",
  "Bibite in bottiglia"
];
const serviceBaseUrl = "https://animacore.italweb.agency/v2";

export {
  runtimeContextArgs,
  oauthConfig,
  productSelectionViewFilters,
  pageStylingContextArgs,
  serviceBaseUrl,
};
