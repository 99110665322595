import Box from "@material-ui/core/Box";
import { HulkButton } from "@italwebcom/misc-ui-components";
import React, { createContext, memo, useContext, useEffect } from "react";
import { Auth } from "@italwebcom/react-auth-provider";
import { Authentication, Misc } from "@italwebcom/anema-react-components";
import Person from "@material-ui/icons/Person";

const C = createContext({ id: null });
const {useSetCredentials} = Authentication.hooks;
const {useAlertFunctions} = Misc.hooks;

function DaSetter({ setCredentials }) {
  const { onAdd } = useAlertFunctions();
  const { id } = useContext(C);
  const { onSetCredentials } = useSetCredentials({
    setCredentials,
    onError: (e) =>
      onAdd({ type: "error", content: "Si è verificato un errore." }),
  });
  return (
    <HulkButton
      label="Accedi all'area utente"
      startIcon={<Person />}
      onClick={onSetCredentials}
      variant="dark"
      fullWidth
      noCapitalize
      id={id}
    />
  );
}

function Redirect({ onRedirect }) {
  useEffect(() => {
    onRedirect();
  }, [onRedirect]);
  return null;
}

function LoginButton({ onRedirect, id }) {
  return (
    <Box>
      <Auth.Switch>
        <Auth.Gate role="standard" priority={0}>
          <Redirect onRedirect={onRedirect} />
        </Auth.Gate>
      </Auth.Switch>
      <C.Provider value={{ id }}>
        <Auth.Setter Component={DaSetter} />
      </C.Provider>
    </Box>
  );
}

export default memo(LoginButton);