import React, { memo, ReactNode } from "react";
import {
  EntityManager,
  CollectionRendererProps,
  useEntityManagerValues,
} from "@italwebcom/react-entity-manager";
import useDeliveryZoneWrapper from "../../../../hooks/wrappers/useDeliveryZoneWrapper";
import { useDefaultComponents } from "../../../misc/DefaultComponentsProvider";
import attributes from "../attributes";
import { DeliveryZone } from "../../../../__domain/model";
import Context from "../context";
import useGenericFetch from "../../../../hooks/generic/useGenericFetch";

const initialFilters = [];
const initialSorter = { attribute: "name", direction: "asc" };

/**
 * @returns
 * @param {CollectionRendererProps<DeliveryZone>} param0
 */
function ProviderCollectionRenderer({ elements, children }) {
  const { loading } = useEntityManagerValues();
  return (
    <Context.Provider value={{ deliveryZones: elements, loading }}>
      {children}
    </Context.Provider>
  );
}

/**
 * Manages fetching operations with an EntityManager for the DeliveryZone entity &
 * instantiates a corresponding context provider with:
 *
 * - the fetched delivery zones
 * - a loading boolean flag
 *
 * Values may be obtained with the useDeliveryZones hook.
 *
 * @param {{children: ReactNode[]}} param0
 *
 * @returns
 */
function DeliveryZoneProvider({ children, initialItemsPerPage }) {
  const wrapper = useDeliveryZoneWrapper();
  const { fetchArgsProcessor, defaultCountGetter } = useDefaultComponents();
  const onFetch = useGenericFetch(wrapper);
  if (wrapper) {
    return (
      <EntityManager.ReadOnly
        fetchArgsProcessor={fetchArgsProcessor}
        countGetter={defaultCountGetter}
        initialItemsPerPage={initialItemsPerPage || 9999}
        initialFilters={initialFilters}
        initialSorter={initialSorter}
        onFetch={onFetch}
        attributes={attributes}
      >
        <EntityManager.Components.CollectionRenderer
          Component={ProviderCollectionRenderer}
          ComponentProps={{ children }}
        />
      </EntityManager.ReadOnly>
    );
  } else {
    return children;
  }
}

export default memo(DeliveryZoneProvider);
