import React from "react";
import {Box, TextField, InputAdornment, ButtonGroup, Button, Grid, Container, makeStyles} from "@material-ui/core";
import SearchOutlined from "@material-ui/icons/SearchOutlined";
import {CSSProperties, useState} from "react";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    wrapper: {
        borderRadius: theme.spacing(1.5),
        //backgroundColor: "rgb(250, 250, 250)",
        borderWidth: "2px",
        borderStyle: "solid",
        "&:hover": {
            borderColor: "rgb(155, 155, 155)"
        },
        "&:not(:hover)": {
            borderColor: "rgb(235, 235, 235)"
        },
        "&.focused": {
            borderColor: theme.palette.primary.main
        }

    },
    iconWrapper: {
        marginRight: theme.spacing(2),
        alignItems: "center",
        display: "flex"
    },
    iconStyle: {
        color: "rgb(180, 180, 180)",
        fontSize: "20px"
    },
    textField: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        border: 0
    }
}));

/**
 * @typedef {{
 *      placeholder: string,
 *      onChange: (text: string) => void,
 *      onClear: Function,
 *      style: CSSProperties,
 *      icon?: ReactNode,
 *      buttons: boolean
 * }} SimpleSearchBarProps
 */

export default function SimpleSearchBar({icon, onChange, onClear, placeholder, style, buttons}) {
    const {wrapper, iconStyle, iconWrapper, textField} = useStyles();
    const [focused, setFocused] = useState(false);
    return <Box className={clsx([wrapper, focused && "focused"])} style={style}>
        <Container maxWidth={false}>
            <Grid container alignItems="center">
                <Grid item className={iconWrapper}>
                    {icon || <SearchOutlined className={iconStyle} />}
                </Grid>
                <Grid item xs>
                    <TextField
                        fullWidth
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        variant="standard"
                        placeholder={placeholder}
                        onChange={evt => onChange(evt.target.value)}
                        inputProps={{
                            className: textField
                        }}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: buttons && <InputAdornment>
                                <ButtonGroup>
                                    <Button variant="text" color="primary">
                                        Cerca
                                    </Button>
                                    <Button variant="text" color="secondary">
                                        Annulla
                                    </Button>
                                </ButtonGroup>
                            </InputAdornment>
                        }}
                    />
                </Grid>
            </Grid>
        </Container>
    </Box>;
}