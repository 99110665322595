import React, { memo } from "react";
import Button, {ButtonProps} from "@material-ui/core/Button";
import CircularProgress, {CircularProgressProps} from "@material-ui/core/CircularProgress";
import useButtonAutoLoading from "./useButtonAutoLoading";

/**
 * @typedef {{
 *      loading?: boolean,
 *      disabled?: boolean,
 *      onClick: (evt: MouseEvent) => void,
 *      CircularProgress?: CircularProgressProps,
 *      ButtonProps?: ButtonProps
 * }} AutoLoadingButtonProps
 *
 * @param {AutoLoadingButtonProps} param0
 * @returns
 */
function AutoLoadingButton({
  onClick,
  CircularProgressProps,
  children,
  loading,
  ButtonProps,
  disabled,
}) {
  const { load, onActualClick } = useButtonAutoLoading({ loading, onClick });
  return (
    <Button
      fullWidth
      type="button"
      variant="outlined"
      color="primary"
      onClick={onActualClick}
      disabled={load || disabled}
      {...(ButtonProps || {})}
      startIcon={
        load && (
          <CircularProgress
            size={12}
            color="white"
            {...(CircularProgressProps || {})}
          />
        )
      }
    >
      {children}
    </Button>
  );
}

export default memo(AutoLoadingButton);
