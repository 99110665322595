import React from "react";
import { RuntimeContextArgs } from "../defines";
import RuntimeContext from "../context";
import { Provider as NavigationProvider } from "../../NavigationProvider";

/**
 * @returns
 * @param {RuntimeContextArgs} param0
 */
export default function RuntimeProvider({
  children,
  wrappers,
  navigate,
  storage,
  smallScreen,
  defaultRole,
  fetcher,
  cartCodeName,
  cartIdName,
  timeoutService,
}) {
  return (
    <NavigationProvider navigate={navigate}>
      <RuntimeContext.Provider
        value={{
          wrappers,
          storage,
          smallScreen,
          defaultRole,
          fetcher,
          cartCodeName,
          cartIdName,
          timeoutService,
        }}
      >
        {children}
      </RuntimeContext.Provider>
    </NavigationProvider>
  );
}
