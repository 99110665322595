import { NewsItem } from "../../model";
import { withDefaultHateoas } from "../hateoas/auxiliary/getDefaultHateoasData";
import settings from "../settings";
import images from "./images";

const n = settings.nNewsItems;
const mexPlaceholder =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";

/**
 * @type {NewsItem[]}
 */
const newsItems = [];

for (let i = 0; i < n; i++) {
  newsItems.push(
    withDefaultHateoas("news-item", {
      id: i,
      image: i,
      imageUrl: images[i].url,
      title: `Notizia ${i}`,
      content: mexPlaceholder,
      date: `2022-03-${((17 + i) % 31) + 1}`,
    })
  );
}

export default newsItems;
