import { useState, useCallback, useMemo } from "react";

/**
 * @template T
 * @typedef {(a: T, b: T) => boolean} ElementMatcher
 */

/**
 * @template T
 * @typedef {{
 *      matcher?: ElementMatcher<T>
 * }} UseCollectionStateConfig
 **/

/**
 * @template T
 * @typedef {{
 *      initial?: T[],
 *      config?: UseCollectionStateConfig<T>
 * }} UseCollectionStateArgs
 */

/**
 * @template T
 * @type {UseCollectionStateConfig<T>}
 */
const defaultConfig = {
  matcher: (a, b) => a === b,
};

/**
 * @template T
 * @param {T[]} collection
 * @param {T} element
 * @param {ElementMatcher<T>} matcher
 */
const doOpByIndex = (collection, element, matcher, op) => {
  const match = collection.filter((a) => matcher(a, element));
  if (match.length !== 0) {
    let ind = collection.indexOf(match[0]);
    if (op === "remove") {
      collection.splice(ind, 1);
    } else {
      collection[ind] = element;
    }
  } else {
    if (op === "replace") {
      collection.push(element);
    }
  }
};

/**
 * @template T
 * @param {UseCollectionStateArgs<T>} param0
 * @return {{
 *      collection: T[],
 *      onAdd: (element: T) => void,
 *      onRemove: (element: T) => void,
 *      onReplace: (target: T, element: T) => void,
 *      onSetAll: (elements: T[]) => void
 * }}
 */
function useCollectionState({ initial, config }) {
  initial = initial || [];
  config = config || defaultConfig;

  const [collection, setCollection] = useState(initial);
  const { matcher } = config;

  const makeCollectionOp = useCallback(
    (op) => {
      setCollection((collection) => {
        const newCollection = [...collection];
        op(newCollection);
        return newCollection;
      });
    },
    [setCollection]
  );

  const { onAdd, onRemove, onReplace } = useMemo(
    () => ({
      onAdd: (v) => makeCollectionOp((c) => c.push(v)),
      onRemove: (v) =>
        makeCollectionOp((c) => doOpByIndex(c, v, matcher, "remove")),
      onReplace: (trg, v) =>
        makeCollectionOp((c) =>
          doOpByIndex(c, v, (a) => matcher(a, trg), "replace")
        ),
    }),
    [makeCollectionOp]
  );

  return {
    collection,
    onSetAll: setCollection,
    onAdd,
    onRemove,
    onReplace,
  };
}

export default useCollectionState;
