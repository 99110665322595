import { withDefaultHateoas } from "../auxiliary/getDefaultHateoasData";
import links from "../../links";
import { NotificationAttributes, Notification } from "../../../model";

/**
 * @param {NotificationAttributes} customer 
 * @return {Notification}
 */
export default function notificationHateoasWrapper(notification) {
  const {customer} = notification;
  let c = withDefaultHateoas(
      links.customer.notification.base, 
      notification
    );
  c._links.customer = {
    href: links.customer.self(customer),
    templated: true,
  };
  return c;
}
