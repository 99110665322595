import { createContext } from "react";
import { ErrorObservable } from "@italwebcom/react-auth-provider";
import { OpenIDConnectTokenManager } from "@italwebcom/augmented-fetch";

/**
 * @type {{manager: OpenIDConnectTokenManager, errorObservable: ErrorObservable}}
 */
const contextArgs = { manager: null, errorObservable: null };
const AuxContext = createContext(contextArgs);

export default AuxContext;
