import React, { memo, CSSProperties } from "react";

/**
 * @returns
 * @param {{alt?: string, src: string, style?: CSSProperties, className?: string}} param0
 */
function Image({ alt, src, style, className }) {
  return (
    <picture style={style} className={className}>
      <img alt={alt} src={src} style={{width: "100%", height: "100%", display: "block", borderRadius: "inherit"}} />
    </picture>
  );
}

export default memo(Image);
