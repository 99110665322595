import {makeStyles} from "@material-ui/core"
import { colors } from "../../../../../themes/anema";

const s = makeStyles((theme) => ({
  costStyle: {
    color: "white",
    fontWeight: "bold",
  },
  addToCartIcon: {
    fontSize: 24,
    color: "white",
    display: "flex",
    alignItems: "center",
  },
  addToCartFab: {
    backgroundColor: "green",
  },
  descriptionStyle: {
    color: colors.grey.secondary,
  },
  section: {
    marginBottom: theme.spacing(2)
  },
  wrapper: {
    padding: theme.spacing(2)
  },
  footerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

export default s;
