import { useCallback, useMemo, useRef } from "react";
import { Filter } from "@italwebcom/custom-react-hooks";

/**
 * @returns
 * @param {{
 *      initialItemsPerPage: number,
 *      count: number,
 *      onPageChange: (page: number) => void,
 *      onFilterReplace: (target: Filter<any>, filter: Filter<any>) => void,
 *      searchAttribute: string,
 *      searchDelayMs?: number
 * }} param0
 */
export default function useAdditionalFunctions({
  initialItemsPerPage,
  count,
  onPageChange,
  onFilterReplace,
  searchAttribute,
  searchDelayMs,
}) {
  const timeoutRef = useRef();
  return {
    pages: useMemo(
      () => Math.ceil(count / initialItemsPerPage),
      [initialItemsPerPage, count]
    ),
    pageChangeCallback: useCallback(
      (e, p) => onPageChange(p - 1),
      [onPageChange]
    ),
    onSearch: useCallback(
      (value) => {
        if (searchDelayMs) {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
          timeoutRef.current = setTimeout(() => {
            onFilterReplace(
              { attribute: searchAttribute },
              { attribute: searchAttribute, value }
            );
          }, searchDelayMs);
        } else {
          onFilterReplace(
            { attribute: searchAttribute },
            { attribute: searchAttribute, value }
          );
        }
        return () => {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
        };
      },
      [searchAttribute, onFilterReplace, searchDelayMs]
    ),
  };
}
