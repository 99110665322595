import React, {
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import useAdditionalFunctions from "../hooks/useAdditionalFunctions";
import {
  defaultCountGetter,
  defaultFilterMatcher,
  defaultEntityComparer,
} from "../auxiliary";
import EntityManagerContext from "../context";
import { CrudEntityManagerProps } from "../defines";
import { useEntityManagement } from "@italwebcom/custom-react-hooks";

/**
 * @template Entity
 * @template {string} Indices
 * @template {string} Sorters
 * @param {CrudEntityManagerProps<Entity, Indices, Sorters>} param0
 */
function CrudEntityManager(
  {
    onFetch,
    onSave,
    onDelete,
    onSelect,
    onSuccess,
    initialFilters,
    initialSorter,
    searchAttribute,
    countGetter,
    initialItemsPerPage,
    onError,
    children,
    attributes,
    defaultFeedbackMessage,
    errorHandler,
    actions,
    fetchArgsProcessor,
    manageEntitySelection,
    noAutoRefetch,
    noAutoFetch,
    lazyCountRecompute,
    testID,
    entityComparer,
    onCountChange: onInputCountChange
  },
  ref
) {
  entityComparer = entityComparer || defaultEntityComparer;
  defaultFeedbackMessage =
    defaultFeedbackMessage || "Operazione effettuata con successo.";

  const [selectedEntity, setSelectedEntity] = useState(null);

  const onActualFetch = useCallback(
    (args) => {
      if (fetchArgsProcessor) {
        args = fetchArgsProcessor(args);
      }
      return onFetch(args);
    },
    [fetchArgsProcessor, onFetch]
  );

  const onActualSelect = useCallback(
    (e) => {
      if (manageEntitySelection) {
        setSelectedEntity(e);
      } else {
        onSelect(e);
      }
    },
    [onSelect, manageEntitySelection]
  );

  const onResetSelectedEntity = useCallback(
    () => setSelectedEntity(null),
    [setSelectedEntity]
  );

  const entityManagementArgs = {
    onFetch: onActualFetch,
    onError,
    onDelete,
    onSave,
    initialCount: lazyCountRecompute ? initialItemsPerPage : 0,
    countGetter: countGetter || defaultCountGetter,
    initialItemsPerPage,
    initialFilters,
    initialSorter,
    config: {
      filterMatcher: defaultFilterMatcher,
      noAutoRefetch,
      noAutoFetch,
      lazyCountRecompute,
    },
    defaultMessage: defaultFeedbackMessage,
    errorHandler,
    onCountChange: onInputCountChange
  };

  const {
    elements,
    count,
    loading,
    sorter,
    page,
    filters,
    onPageChange,
    onFilterReplace,
    onFilterAdd,
    onFilterRemove,
    onSorterChange,
    onFiltersClear,
    onFiltersSetAll,
    onSorterClear,
    onDelete: onActualDelete,
    onSave: onActualSave,
    onRefreshAll,
    error,
    onItemsPerPageChange,
    onItemsPerPageIncrease,
    itemsPerPage,
  } = useEntityManagement({ ...entityManagementArgs, onSuccess, id: testID });
  
  console.log(elements);
  
  useImperativeHandle(
    ref,
    () => ({
      refresh: onRefreshAll,
      setFilters: onFiltersSetAll,
      replaceFilter: onFilterReplace,
    }),
    [onRefreshAll, onFiltersSetAll, onFilterReplace]
  );

  useEffect(() => {
    console.log(elements);
    console.log(setSelectedEntity);
    if (elements && setSelectedEntity) {
      setSelectedEntity((e) => {
        /* changes selected entity on refresh */
        if (e) {
          let matches = elements.filter((el) => entityComparer(e, el));
          console.log("SELECTED ENTITY REFRESH");
          console.log(matches);
          if (matches.length) {
            return matches[0];
          } else {
            return e;
          }
        }
        return null;
      });
    }
  }, [elements, setSelectedEntity]);

  const { onSearch, pageChangeCallback, pages } = useAdditionalFunctions({
    initialItemsPerPage,
    count,
    onPageChange,
    onFilterReplace,
    searchAttribute,
  });

  return (
    <EntityManagerContext.Provider
      value={{
        elements,
        filters,
        onFilterAdd,
        onFilterRemove,
        onFilterReplace,
        onFiltersClear,
        onFiltersSetAll,
        onSorterChange,
        onSorterClear,
        onSelect: onActualSelect,
        selectedEntity: manageEntitySelection && selectedEntity,
        manageEntitySelection,
        onResetSelectedEntity,
        onPageChange: pageChangeCallback,
        onItemsPerPageChange,
        onItemsPerPageIncrease,
        itemsPerPage,
        page,
        pages,
        sorter,
        loading,
        onSearch,
        attributes,
        onDelete: onDelete && onActualDelete,
        onSave: onSave && onActualSave,
        onRefreshAll,
        error,
      }}
    >
      {children}
    </EntityManagerContext.Provider>
  );
}

export default memo(React.forwardRef(CrudEntityManager));
