import React, { memo } from "react";
import ListRenderer from "./ListRenderer";
import { Carts } from "@italwebcom/anema-react-components";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { makeStyles } from "@material-ui/core";

const { useSelectedProductFeatures } = Carts.hooks;
const placeholderEls = [
  { selectionIndex: 0, elements: [] },
  { selectionIndex: 1, elements: [] },
];

function SelectedProductFeaturesRenderer({ onBack }) {
  const { loading, onSave, onRefresh, groupedElements } =
    useSelectedProductFeatures();
  const placeholder = loading && (!groupedElements || !groupedElements.length);
  return (
    <Box component="article">
      <Box component="aside">
        <Button
          variant="text"
          color="primary"
          startIcon={<KeyboardArrowLeft />}
          onClick={onBack}
        >
          Indietro
        </Button>
      </Box>
      <Box component="section">
        {(groupedElements && groupedElements.length) || placeholder ? (
          groupedElements.map(({ selectionIndex, features }) => (
            <ListRenderer
              selectionIndex={selectionIndex}
              elements={features}
              placeholder={placeholder}
              onSave={onSave}
              loading={loading}
            />
          ))
        ) : (
          <Typography variant="body1">
            Per questo piatto non vi sono ingredienti o extra selezionabili
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default memo(SelectedProductFeaturesRenderer);
