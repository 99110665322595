import { useCallback } from "react";
import useConversationWrapper from "../../../../hooks/wrappers/useConversationWrapper";
import useRequestAuthenticationSetter from "../../../authentication/hooks/useRequestAuthenticationSetter";

export default function useConversationProviderFunctions(id) {
  const wrapper = useConversationWrapper();
  const authSetter = useRequestAuthenticationSetter();
  const operator = useCallback(
    () => {
      let r = wrapper.execute("read", { id });
      authSetter(r.request());
      return r.json();
    },
    [wrapper, id, authSetter]
  );
  /*return useAuthenticatedRequest({
    operator,
    name: "ConversationProvider fetch",
  });*/
  return operator;
}
