import React, { memo, ReactNode, useCallback } from "react";
import { GenericForm } from "@italwebcom/react-generic-form";
import fields from "../fields";
import { Customer } from "../../../../../__domain/model";

/**
 * Provides a GenericForm.Provider for the Customer editor, with appropriate
 * fields. Should be used along with the corresponding input components as
 * descendants (not necessarily children!).
 *
 * Requires an instance of the Customer entity whose values are meant to
 * be edited.
 *
 * @param {{
 *    children: ReactNode[],
 *    customer: Customer,
 *    onSave: (customer: Customer) => void
 * }} param0
 * @returns
 */
function Provider({ children, customer, onSave, loading }) {
  if (customer) {
    return (
      <GenericForm.Provider
        fields={fields}
        onSubmit={onSave}
        defaultValues={customer}
        loading={loading}
      >
        {children}
      </GenericForm.Provider>
    );
  }
  return null;
}

export default memo(Provider);
