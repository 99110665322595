import { createContext } from "react";
import { InvalidValue, Field, ShouldRenderFunc } from "../defines";

/**
 * @type {{
 *      values: Record<string, any>,
 *      onSet: (name: string, value: any) => void,
 *      invalidValue?: InvalidValue,
 *      submittable: boolean,
 *      fields: Field[],
 *      shouldRender: ShouldRenderFunc
 * }}
 */
const args = {
  values: {},
  onSet: null,
  invalidValue: null,
  submittable: false,
  fields: [],
  shouldRender: null
};

export default createContext(args);
