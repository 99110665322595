import React, { memo } from "react";
import CartProductList from "./List";
import MainContentPanel, {
  MainContentPanelHeaderProps,
} from "../../../../MainContentPanel";
import { Carts } from "@italwebcom/anema-react-components";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import Add from "@material-ui/icons/Add";
import { HulkButton } from "@italwebcom/misc-ui-components";
import { Fragment } from "react";
import DefaultErrorPrinter from "../../../../_auxiliary/DefaultErrorPrinter";
import CartErrorPrinter from "../CartErrorPrinter";

const { useCartAndProducts, useCartDerivedAttributes } = Carts.hooks;

const s = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
  },
  textWrapper: {
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  costTitle: {
    color: "#777474",
    fontWeight: "bold",
  },
  costContent: {
    color: "green",
    fontWeight: "bold",
  },
  fab: {
    color: "white",
  },
  costWrapper: {
    position: "sticky",
    bottom: "62px",
    backgroundColor: "white",
  },
}));

function FooterRenderer({ cost, onAdd, fab, editable }) {
  const {
    divider,
    textWrapper,
    costTitle,
    costContent,
    fab: fabStyle,
    costWrapper,
  } = s();
  if (!editable) {
    return null;
  }
  let butan = fab ? (
    <Fab onClick={onAdd} className={fabStyle} size="medium" color="primary">
      <Add />
    </Fab>
  ) : (
    <HulkButton
      variant="light"
      onClick={onAdd}
      title="Aggiungi prodotto"
      label="Aggiungi prodotto"
      noCapitalize
    />
  );
  return (
    <Box className={costWrapper}>
      <Box className={divider}>
        <Divider />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className={textWrapper}
      >
        <Box>
          {editable && (
            <Fragment>
              <Typography
                component="p"
                variant="body1"
                className={costTitle}
                gutterBottom
              >
                Subtotale piatti
              </Typography>
              <Typography component="p" variant="h5" className={costContent}>
                {cost} &euro;
              </Typography>
            </Fragment>
          )}
        </Box>
        {butan}
      </Box>
    </Box>
  );
}

/**
 * @param {{headerProps: MainContentPanelHeaderProps, onCartProductAdd: Function}} param0
 * @returns
 */
function CartProductEditorPanel({ headerProps, onCartProductAdd, fab, cart }) {
  const { editable } = useCartDerivedAttributes(cart);
  return (
    <MainContentPanel title="Prodotti" headerProps={headerProps}>
      <CartErrorPrinter />
      <CartProductList />
      <FooterRenderer
        cost={cart && cart.totalCost}
        onAdd={onCartProductAdd}
        fab={fab}
        editable={editable}
      />
    </MainContentPanel>
  );
}

export default memo(CartProductEditorPanel);
