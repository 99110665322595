import { useEffect, useState } from "react";
import { Wrappers, Misc } from "@italwebcom/anema-react-components";
import useRuntimeContext from "../../../contexts/Runtime/useRuntimeContext";

const { useCartWrapper } = Wrappers.hooks;
const { useInitialOperations } = Misc.hooks;

function useLoadingInit(navigateOnDone) {
  const [error, setError] = useState(null);
  const [inited, setInited] = useState(false);
  const [done, setDone] = useState(false);
  const cartWrapper = useCartWrapper();
  const { Navigation, viewNames } = useRuntimeContext();
  const onInitialOps = useInitialOperations({ cartWrapper });

  useEffect(async () => {
    try {
      if (!inited && cartWrapper && Navigation && onInitialOps) {
        setInited(true);
        await onInitialOps();
        setDone(true);
        if(navigateOnDone) {
          Navigation.navigate(viewNames.home);
        }
      }
    } catch (e) {
      setError(e);
    }
  }, [
    cartWrapper,
    setInited,
    Navigation,
    viewNames,
    onInitialOps,
    navigateOnDone,
    inited,
    setDone
  ]);

  return { done, error };
}

export default useLoadingInit;
