import { TagSelectorGroupRendererProps } from "@italwebcom/anema-react-components/Tags";
import React, { memo, useMemo } from "react";
import CustomTabs from "../../../../CustomTabs";
import useTagSelectorFunctions from "../../hooks/useTagSelectorFunctions";

/**
 * @returns
 * @param {TagSelectorGroupRendererProps} param0
 */
function GroupRenderer({ elements, onAdd, onReplace }) {
  const { tags, selected, onActualChange } = useTagSelectorFunctions({
    elements,
    onAdd,
    onReplace,
  });
  const v = useMemo(
    () => selected && selected.length && selected[0].data.id,
    [selected]
  );
  return (
    tags && <CustomTabs.Base onChange={onActualChange} defaultValue={v}>
      {tags.map(
        (t) =>
          !t.unavailable && (
            <CustomTabs.Item
              value={t.id}
              label={t.name}
              key={t.id}
              image={t.mainImageUrl}
            />
          )
      )}
    </CustomTabs.Base>
  );
}

export default memo(GroupRenderer);
