import React, { memo, useCallback, useEffect, useRef } from "react";
import PlaceholderWrapper from "../../../../PlaceholderWrapper";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CostChip } from "../../../products/CustomerProductSelectionView/ItemRenderers/_Auxiliary";
import clsx from "clsx";

const s = makeStyles((theme) => ({
  primary: {
    color: theme.palette.grey["600"],
  },
  secondary: {
    color: theme.palette.grey["400"]
  },
  wrapper: {
    "&:hover": {
      backgroundColor: "rgb(245, 245, 245)",
    },
    transitionDuration: "450ms",
    transitionProperty: "background-color",
    backgroundColor: "white",
    "&:not(.loading)": {
      cursor: "pointer",
    },
    "&.loading": {
      opacity: 0.5,
    },
  },
}));

const CheckboxListItem = memo(
  ({ checked, onChange, title, subtitle, cost, loading }) => {
    const checkedRef = useRef();
    const { primary: primaryStyle, secondary: secondaryStyle, wrapper } = s();
    useEffect(() => {
      if (checkedRef) {
        checkedRef.current = checked;
      }
    }, [checked, checkedRef]);
    const onActualClick = useCallback(
      () => onChange(!checkedRef.current),
      [onChange, checkedRef]
    );
    return (
      <ListItem
        onClick={onActualClick}
        role="button"
        className={clsx([wrapper, loading && "loading"])}
      >
        <ListItemText
          primary={title}
          primaryTypographyProps={{ variant: "body1", className: primaryStyle }}
          secondary={subtitle}
          secondaryTypographyProps={{
            variant: "body2",
            className: secondaryStyle,
          }}
        />
        <ListItemSecondaryAction>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <CostChip element={{ cost }} />
            </Grid>
            <Grid item>
              {loading ? (
                <CircularProgress size={16} color="primary" />
              ) : (
                <Checkbox
                  checked={checked}
                  onClick={onActualClick}
                  size="medium"
                  color="primary"
                />
              )}
            </Grid>
          </Grid>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
);

function ElementRenderer({ onSave, element, placeholder, loading }) {
  const onActualSave = useCallback(
    (selected) => onSave(element, selected),
    [element, onSave]
  );

  return (
    <PlaceholderWrapper active={placeholder}>
      <CheckboxListItem
        checked={element.selected}
        onChange={onActualSave}
        title={element.name}
        subtitle={element.description}
        cost={element.cost}
        loading={loading}
      />
    </PlaceholderWrapper>
  );
}

export default memo(ElementRenderer);
