import { RequestDecorator } from "@italwebcom/augmented-fetch";

function isModifyingMethod(m) {
  return m === "POST" || m === "PUT";
}

/**
 * @type {RequestDecorator}
 */
const jsonifier = {
  name: "jsonifier",
  process: (init) => {
    const { body } = init;
    const out = { ...init };
    /* istanbul ignore next */
    if (isModifyingMethod(init.method) && body && typeof body === "object") {
      out.body = JSON.stringify(out.body);
    }
    return out;
  },
};

export default jsonifier;
