import {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
  useContext,
} from "react";
import PaginatorCacheContext from "./PaginatorCacheContext";

/**
 * count = c, page = p, start = s, end = e, itemsPerPage = i, max page = mp
 *
 * - s = p*i, e=(p+1)*i-1
 * - mp = ceil(c/i)
 *
 * 1) s = p*i, e=(p+1)*i-1
 * 2) on next page, p = min(p+1, mp)
 * 3) on prev page, p = max(p-1, 0)
 * 4) on set page, set if 0 <= p <= mp
 * 5) on count change c -> c', if e > c' then p = p - ceil((c' - c)/i)
 * 6) on itemsPerPage change i -> i', if p > mp' then p = mp'
 *
 * @typedef {{
 *      count: number,
 *      itemsPerPage: number,
 *      lazyCountRecompute?: boolean,
 *      onCountChange?: (oldCount: number, newCount: number) => void
 * }} UsePaginatorArgs
 *
 * @typedef {{
 *      onItemsPerPageChange: (i: number) => void,
 *      onCountChange: (i: number) => void,
 *      onNextPage: () => void,
 *      onPrevPage: () => void,
 *      onPageChange: (p: number) => void,
 *      page: number,
 *      start: number,
 *      end: number
 * }} UsePaginatorReturn
 */

const getMaxPage = (count, itemsPerPage) =>
  Math.max(0, Math.ceil(count / itemsPerPage) - 1);
const getStart = (page, itemsPerPage) => page * itemsPerPage;
const getEnd = (page, itemsPerPage) =>
  Math.max(0, (page + 1) * itemsPerPage - 1);

/**
 * @param {UsePaginatorArgs} param0
 * @return {UsePaginatorReturn}
 */
export default function usePaginator({
  count: initialCount,
  itemsPerPage: initialItemsPerPage,
  page: initialPage,
  lazyCountRecompute,
  onCountChange: inputOnCountChange,
}) {
  const {
    count: cachedCount,
    page: cachedPage,
    itemsPerPage: cachedItemsPerPage,
    onDataChange,
  } = useContext(PaginatorCacheContext);

  const [count, setCount] = useState(cachedCount || initialCount);
  const [itemsPerPage, setItemsPerPage] = useState(
    cachedItemsPerPage || initialItemsPerPage
  );
  const [page, setPage] = useState(cachedPage || initialPage || 0);

  //const countRef = useRef(initialCount);

  useEffect(() => {
    onDataChange && onDataChange(count, page, itemsPerPage);
  }, [page, itemsPerPage, count, onDataChange]);

  useEffect(() => {
    setPage((page) => {
      const mp = getMaxPage(count, itemsPerPage);
      //const end = getEnd(page, itemsPerPage);
      /*console.log(
        `usePaginator.<page recompute useEffect>: mp=${mp}, end=${end}, count=${count}, previous count=${countRef.current}, page=${page}, itemsPerPage=${itemsPerPage}`
      );*/
      if (page > mp) {
        page = mp;
      }
      /*if (end > count) {
        page = Math.max(
          0,
          page - Math.ceil(Math.abs(count - countRef.current) / itemsPerPage)
        );
      }*/
      /*console.log(
        `usePaginator.<page recompute useEffect>: new page = ${page}`
      );*/
      return page;
    });
  }, [itemsPerPage, count]);

  const start = useMemo(
    () => getStart(page, itemsPerPage),
    [page, itemsPerPage]
  );

  const end = useMemo(
    () => Math.max(0, Math.min(getEnd(page, itemsPerPage), count - 1)),
    [page, itemsPerPage, count]
  );

  const onCountChange = useCallback(
    (v) =>
      setCount((c) => {
        console.log(`onCountChange: new count ${v} vs old count ${c}`);
        console.log(inputOnCountChange);
        if(inputOnCountChange) {
          console.log("using inputOnCountChange");
          inputOnCountChange(c, v);
        }
        if (lazyCountRecompute && c >= v) {
          console.log("using the old value");
          return c;
        } else {
          console.log("setting the new value");
          return v;
        }
      }),
    [setCount, lazyCountRecompute, inputOnCountChange]
  );

  const onNextPage = useCallback(() => {
    setPage((p) => {
      let newPage = Math.min(p + 1, getMaxPage(count, itemsPerPage));
      //console.log(`usePaginator.<onNextPage>: setting new page = ${newPage}`);
      return newPage;
    });
  }, [itemsPerPage, count]);

  const onPrevPage = useCallback(() => {
    setPage((p) => {
      let newPage = Math.max(p - 1, 0);
      //console.log(`usePaginator.<onPrevPage>: setting new page = ${newPage}`);
      return newPage;
    });
  });

  const onPageChange = useCallback(
    (np) =>
      setPage((p) => {
        if (np >= 0 && np <= getMaxPage(count, itemsPerPage)) {
          return np;
        } else {
          return p;
        }
      }),
    [count, itemsPerPage]
  );

  return {
    count,
    page,
    start,
    end,
    onNextPage,
    onPrevPage,
    onPageChange,
    onItemsPerPageChange: setItemsPerPage,
    onCountChange,
    itemsPerPage,
  };
}
