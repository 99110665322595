import { withDefaultHateoas } from "../../hateoas/auxiliary/getDefaultHateoasData";
import links from "../../links";
import { CustomerAttributes } from "../../../model";

/**
 * @param {CustomerAttributes} customer
 * @return {Customer}
 */
export default function customerHateoasWrapper(customer) {
  const { id } = customer;
  let c = withDefaultHateoas(links.customer.base, customer);
  c._links.carts = {
    href: links.customer.carts(id),
    templated: true,
  };
  c._links.notifications = {
    href: links.customer.notifications(id),
    templated: true,
  };
  c._links.token = {
    href: links.customer.token(id)
  };
  c._templates.addNotification = {
    method: "POST",
    target: links.customer.addNotification(id),
    properties: [],
  };
  c._templates.setNotificationToken = {
    method: "PUT",
    target: links.customer.token(id),
    properties: [],
  };
  c._links.activeCart = {
    href: links.customer.activeCart(id),
    templated: true,
  };
  return c;
}
