/**
 * @typedef {"ok" | "not_found" | "created" | "bad_request" | "unauthorized" | "forbidden" | "confict" | "precondition_failed" | "found"} ResultStatus
 * @typedef {(response: Response, info: RequestInfo) => Promise<any>} ResponseHandlerFunc
 * @typedef {{
 *      status: ResultStatus,
 *      handler: ResponseHandlerFunc,
 *      retryOnError?: boolean
 * }} ResponseHandler
 * @typedef {{
 *      name?: string,
 *      process: (init: RequestInit, request?: FetchRequest) => Promise<RequestInit>
 * }} RequestDecorator
 */

export const ResultStatusCodes = {
  bad_request: 400,
  unauthorized: 401,
  forbidden: 403,
  not_found: 404,
  conflict: 409,
  precondition_failed: 412,
  internal_server_error: 500,
  found: 304,
};

/**
 *
 * @param {RequestDecorator[]} rd
 * @param {string} name
 */
function removeDecorator(rd, name) {
  let m = rd.filter((d) => d.name === name);
  if (m.length) {
    rd.splice(rd.indexOf(m[0]), 1);
  }
}

/**
 * @typedef {(req: RequestInfo, init: RequestInit) => Promise<Response>} FetcherFunc
 * @typedef {(req: RequestInfo, init: RequestInit) => FetchRequest} Fetcher
 * @typedef {{
 *      onFetch: FetcherFunc,
 *      onError: ResponseHandler,
 *      onRedirect: ResponseHandler
 * }} AugmentedFetcherConfig
 */

/**
 * @param {AugmentedFetcherConfig} param0
 * @returns
 */
export default function build({ onFetch, onError, onRedirect }) {
  let fetcher = new ActualFetcher(onFetch, onError, onRedirect);
  return {
    /**
     * @param {RequestInfo} info
     * @param {RequestInit} init
     * @return {FetchRequest}
     */
    request: function (info, init) {
      return new FetchRequest(fetcher, info, init);
    },
    fetcher,
  };
}

/**
 * @param {RequestInit} init
 * @param {RequestDecorator[]} decorators
 * @return {Promise<RequestInit>}
 */
const processDecorators = (init, decorators) => {
  let p = Promise.resolve(init);
  for (let decorator of decorators) {
    p = p.then(decorator.process.bind(decorator));
  }
  return p;
};

export class ActualFetcher {
  /**
   * @type {FetcherFunc}
   */
  _onFetch;

  /**
   * @type {ResponseHandler}
   */
  _onError;

  /**
   * @type {ResponseHandler}
   */
  _onRedirect;

  /**
   * @type {FetchRequest}
   */
  _defaultRequest;

  constructor(onFetch, onError, onRedirect) {
    this._onFetch = onFetch;
    this._onError = onError;
    this._onRedirect = onRedirect;
    this._defaultRequest = {
      _requestDecorators: [],
      _resultHandlers: [],
    };
  }

  /**
   * @returns
   * @param {FetchRequest} req
   */
  withDefaultRequest(req) {
    this._defaultRequest = req;
    return this;
  }

  /**
   * @returns
   * @param {RequestDecorator} decorator
   */
  addDefaultDecorator(decorator) {
    if (decorator) {
      let decNames = this._defaultRequest._requestDecorators.map(
        (dec) => dec.name
      );
      if (decNames.indexOf(decorator.name) === -1) {
        this._defaultRequest._requestDecorators.push(decorator);
      }
    }
    return this;
  }

  /**
   * @returns
   * @param {string} name
   */
  removeDefaultDecorator(name) {
    removeDecorator(this._defaultRequest._requestDecorators, name);
    return this;
  }

  /**
   * @returns
   * @param {ResponseHandler} handler
   */
  addDefaultResponseHandler(handler) {
    this._defaultRequest._resultHandlers.push(handler);
    return this;
  }

  /**
   * @return {FetchResponse}
   * @param {FetchRequest} request
   */
  process(request, retried) {
    let { _init: init, _info: info } = request;

    /* istanbul ignore next */
    let decorators = this._defaultRequest._requestDecorators.concat(
      request._requestDecorators || []
    );
    /* istanbul ignore next */
    let reqHandlers = request._resultHandlers || [];

    /**
     * @type {ResponseHandler[]}
     */
    let handlers = [
      ...(this._defaultRequest._resultHandlers || []),
      ...reqHandlers,
    ];

    let p = processDecorators(init, decorators)
      .then((i) => this._onFetch(info, i))
      .then((response) => {
        const { status } = response;
        if (status >= 300) {
          let matchingHandler = handlers.filter((h) => h.status === status);
          if (matchingHandler.length !== 0) {
            const { handler, retryOnError } = matchingHandler[0];
            let r = handler(response, info);
            if (retryOnError && !retried) {
              return r.then(() => this.process(request, true));
            } else {
              return r;
            }
          } else {
            if (status === ResultStatusCodes.found) {
              return this._onRedirect(response);
            } else {
              return this._onError(status, response);
            }
          }
        } else {
          return Promise.resolve(response);
        }
      });

    return new FetchResponse(p);
  }
}

class FetchResponse {
  /**
   * @type {Promise<Response>}
   */
  _pResponse;

  constructor(pResponse) {
    this._pResponse = pResponse;
  }

  /**
   * @return {Promise<Object>}
   */
  json() {
    return this._pResponse.then((r) => r && r.json && r.json());
  }

  /**
   * @return {Promise<Response>}
   */
  response() {
    return this._pResponse;
  }
}

export class FetchRequest {
  /**
   * @type {ActualFetcher}
   */
  _fetcher;

  /**
   * @type {RequestInfo}
   */
  _info;

  /**
   * @type {RequestInit}
   */
  _init;

  /**
   * @type {RequestDecorator[]}
   */
  _requestDecorators;

  /**
   * @type {ResponseHandler[]}
   */
  _resultHandlers;

  /**
   * @param {ActualFetcher} fetcher
   * @param {RequestInfo} info
   * @param {RequestInit} init
   */
  constructor(fetcher, info, init) {
    this._info = info;
    this._init = init;
    this._fetcher = fetcher;
    this._requestDecorators = [];
    this._resultHandlers = [];
  }

  /**
   * @param {ResultStatus} status
   * @param {ResponseHandler} handler
   * @return {FetchRequest}
   */
  on(status, handler) {
    this._resultHandlers.push({ status: ResultStatusCodes[status], handler });
    return this;
  }

  /**
   * @param {ResponseHandler} h
   * @return {FetchRequest}
   */
  withHandler(h) {
    this._resultHandlers.push(h);
    return this;
  }

  /**
   * @param {RequestDecorator} d
   * @return {FetchRequest}
   */
  withDecorator(d) {
    this._requestDecorators.push(d);
    return this;
  }

  /**
   * @param {RequestDecorator} d
   * @return {FetchRequest}
   */
  decorate(d, name) {
    name = name || "decorator_" + this._requestDecorators.length;
    return this.withDecorator({ name, process: d });
  }

  /**
   * @returns
   * @param {string} name
   */
  removeDecorator(name) {
    removeDecorator(this._requestDecorators, name);
    return this;
  }

  /**
   * @returns {FetchResponse}
   */
  fetch() {
    return this._fetcher.process(this);
  }

  /**
   * @returns {Promise<any>}
   */
  json() {
    return this.fetch().json();
  }
}
