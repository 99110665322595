import React, { memo } from "react";
import { FullPageProps } from "../defines";
import CustomerNewsCarousel from "../../../_entities/newsitems/CustomerNewsItemsRenderer";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import HomepageButtonGroup from "../../HomepageButtonGroup";
import DetailRenderer from "../DetailRenderer";
import clsx from "clsx";
import Picture from "../../../Picture";

const styles = makeStyles((theme) => ({
  logoWrapper: {
    [theme.breakpoints.down("md")]: {
      maxWidth: "250px",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    margin: "auto",
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(4),
      maxWidth: "300px",
    },
  },
  wrapper: {
    backgroundSize: "cover",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
      //paddingBottom: theme.spacing(6),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
  },
  titleDescWrapper: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(4),
    },
  },
  titleStyle: {
    color: "white",
    marginBottom: theme.spacing(4),
    fontWeight: "bold",
  },
  descriptionStyle: {
    color: "white",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  newsWrapper: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  titleDescFlexWrapper: {
    [theme.breakpoints.down("xs")]: {
      order: 1,
    },
  },
  buttWrapper: {
    [theme.breakpoints.down("xs")]: {
      order: 2,
      maxWidth: "300px",
      margin: "auto",
    },
    display: "flex",
    justifyContent: "center",
  },
}));

/**
 * @returns
 * @param {FullPageProps} param0
 */
function FullPage({
  image,
  details,
  onReservation,
  onUserArea,
  title,
  description,
  titleTypographyProps,
  descriptionTypographyProps,
  buttonGroupImage,
  buttonGroupTypographyProps,
  buttonGroupImageSize,
  buttonGroupDisabled,
  buttonGroupLoading,
  noButtons,
  smallScreen,
  className,
  newsFirst,
  sm,
  logo,
  buttonsVariant,
  carouselDotColor,
  noDelivery,
  buttonContainerMaxWidth,
}) {
  const {
    wrapper,
    titleDescWrapper,
    titleStyle,
    descriptionStyle,
    newsWrapper,
    titleDescFlexWrapper,
    buttWrapper,
    logoWrapper,
  } = styles();
  return (
    <Box
      className={clsx([wrapper, className])}
      style={{ backgroundImage: `url(${image})` }}
    >
      <Container maxWidth="lg">
        <Grid container justifyContent="space-between" spacing={2}>
          {details && (
            <Grid item xs={12}>
              <Container maxWidth="md" disableGutters>
                <DetailRenderer details={details} color="white" iconSize={20} />
              </Container>
            </Grid>
          )}
          {logo && (
            <Grid item xs={12}>
              <Box className={logoWrapper}>
                <Picture
                  defaultSrc={logo}
                  alt="Logo"
                  figureStyle={{ maxWidth: "100%", margin: 0 }}
                  imgStyle={{ maxWidth: "100%" }}
                />
              </Box>
            </Grid>
          )}

          {!noButtons && (
            <Grid item xs={12} className={buttWrapper}>
              <Container
                maxWidth={buttonContainerMaxWidth || "sm"}
                disableGutters
              >
                <HomepageButtonGroup
                  variant={buttonsVariant || "image"}
                  onUserArea={onUserArea}
                  onReservation={onReservation}
                  image={buttonGroupImage}
                  typographyProps={buttonGroupTypographyProps}
                  imageSize={buttonGroupImageSize}
                  disabled={buttonGroupDisabled}
                  loading={buttonGroupLoading}
                  noDelivery={noDelivery}
                />
              </Container>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
}

export default memo(FullPage);
