import { CollectionAttributeRenderer } from "@italwebcom/react-entity-manager";
import { Notification } from "../../../__domain/model";
import { formatMessageDate, formatMessageHour } from "../../../misc/dateUtils";

/**
 * @type {CollectionAttributeRenderer<Notification>[]}
 */
const attributes = [
    {
        id: "title",
        label: "Titolo",
        type: "title"
    },
    {
        id: "date",
        label: "Data",
        type: "subtitle",
        renderer: e => `Inviata il ${formatMessageDate(e.date, "DD MMM YYYY")} alle ${formatMessageHour(e.date)}`
    }
];

export default attributes;