import React, { memo } from "react";
import { Provider as VariantProvider } from "./_Auxiliary/VariantProvider";
import { VariableCollectionRenderer } from "./CollectionRenderers";
import { DefaultComposer, SmallScreenComposer } from "./Composers";
import SearchBar from "./SearchBar";
import {Products} from "@italwebcom/anema-react-components";

const {Provider: ProductsProvider} = Products.Components;

const InnerRenderer = memo(({ smallScreen }) => {
  if (smallScreen) {
    return (
      <SmallScreenComposer CollectionRenderer={VariableCollectionRenderer} />
    );
  } else {
    return (
      <DefaultComposer
        CollectionRenderer={VariableCollectionRenderer}
        SearchBar={SearchBar}
      />
    );
  }
});

/**
 * @param {{defaultView: "list" | "grid", smallScreen: boolean}} param0 
 * @returns 
 */
function CustomerProductSelection({ defaultView, smallScreen }) {
  return (
    <VariantProvider initialValue={defaultView}>
      <ProductsProvider>
        <InnerRenderer smallScreen={smallScreen} />
      </ProductsProvider>
    </VariantProvider>
  );
}

export default memo(CustomerProductSelection);