import { InMemoryCollection } from "@italwebcom/in-memory-collection";
import { makeOnAfterSave } from "./auxiliary/collectionCallbacks";

/* fake data */
import images from "./data/images";
import tags from "./data/tags";
import categories from "./data/categories";
import cartProducts from "./data/cartProducts";
import conversations from "./data/conversations";
import messages from "./data/messages";
import productFeatures from "./data/productFeatures";
import featureValues from "./data/featureValues";
import validityDiscriminators from "./data/validityDiscriminators";
import attributeMatchers from "./data/attributeMatchers";
import costLists from "./data/costLists";
import deliveryZones from "./data/deliveryZones";
import deliveryCosts from "./data/deliveryCosts";
import customers from "./data/customers";
import stats from "./data/stats";
import notifications from "./data/notifications";
import products from "./data/products";
import carts from "./data/carts";
import newsItems from "./data/newsItems";
import productCategories from "./data/productCategories";
import productImages from "./data/productImages";
import productCosts from "./data/productCosts";
import productFilterer from "./auxiliary/productFilterer";
import globalSearchResults from "./data/globalSearchResults";
import openingTimes from "./data/openingTimes";
import closingDays from "./data/closingDays";

/* hateoas wrappers */
import wrappers from "./hateoas";
import { withDefaultHateoas } from "./hateoas/auxiliary/getDefaultHateoasData";

const {
    tag: tagHateoasWrapper,
    image: imageHateoasWrapper,
    deliveryZone: deliveryZoneHateoasWrapper,
    productFeature: productFeaturesHateoasWrapper,
    costList: costListHateoasWrapper,
    validityDiscriminator: validityDiscriminatorHateoasWrapper,
    category: categoryHateoasWrapper,
    conversation: conversationHateoasWrapper,
    deliveryCost: deliveryCostHateoasWrapper,
    customer: customerHateoasWrapper,
    notification: notificationHateoasWrapper,
    product: productHateoasWrapper,
    cart: cartHateoasWrapper
} = wrappers;

import {
  Image,
  ImageIndices,
  ImageSorters,
  Category,
  Tag,
  TagIndices,
  TagSorters,
  CategoryIndices,
  Conversation,
  ConversationAttributes,
  ConversationIndices,
  Message,
  MessageAttributes,
  MessageIndices,
  ConversationSorters,
  MessageSorters,
  ProductFeature,
  ProductFeatureIndices,
  ProductFeatureSorters,
  FeatureValue,
  FeatureValueIndices,
  FeatureValueSorters,
  ValidityDiscriminator,
  ValidityDiscriminatorIndices,
  ValidityDiscriminatorSorters,
  CostList,
  CostListIndices,
  CostListSorters,
  AttributeMatcher,
  AttributeMatcherIndices,
  AttributeMatcherSorters,
  DeliveryZone,
  DeliveryZoneIndices,
  DeliveryZoneSorters,
  DeliveryCost,
  DeliveryCostIndices,
  DeliveryCostSorters,
  Product,
  ProductIndices,
  ProductSorters,
  ProductCategory,
  ProductImage,
  Customer,
  CustomerIndices,
  CustomerSorters,
  Notification,
  NotificationIndices,
  NotificationSorters,
  Cart,
  CartIndices,
  CartSorters,
  CartProduct,
  NewsItem,
  NewsItemIndices,
  NewsItemSorters,
  Stat,
  GlobalSearchResult,
  GlobalSearchResultAttributes,
  OpeningTime,
  ClosingDay,
  OpeningTimeIndices,
  ClosingDayIndices
} from "../model";

/**
 * @typedef {{
 *    images: InMemoryCollection<Image, ImageIndices, ImageSorters>,
 *    tags: InMemoryCollection<Tag, TagIndices, TagSorters>,
 *    categories: InMemoryCollection<Category, CategoryIndices, TagSorters>,
 *    conversations: InMemoryCollection<Conversation, ConversationIndices, ConversationSorters>,
 *    messages: InMemoryCollection<Message, MessageIndices, MessageSorters>,
 *    features: InMemoryCollection<ProductFeature, ProductFeatureIndices, ProductFeatureSorters>,
 *    featureValues: InMemoryCollection<FeatureValue, FeatureValueIndices, FeatureValueSorters>,
 *    costLists: InMemoryCollection<CostList, CostListIndices, CostListSorters>,
 *    validityDiscriminators: InMemoryCollection<ValidityDiscriminator, ValidityDiscriminatorIndices, ValidityDiscriminatorSorters>,
 *    attributeMatchers: InMemoryCollection<AttributeMatcher, AttributeMatcherIndices, AttributeMatcherSorters>,
 *    deliveryZones: InMemoryCollection<DeliveryZone, DeliveryZoneIndices, DeliveryZoneSorters>,
 *    deliveryCosts: InMemoryCollection<DeliveryCost, DeliveryCostIndices, DeliveryCostSorters>,
 *    products: InMemoryCollection<Product, ProductIndices, ProductSorters>,
 *    productCategories: InMemoryCollection<ProductCategory, any, any>,
 *    productImages: InMemoryCollection<ProductImage, any, any>,
 *    customers: InMemoryCollection<Customer, CustomerIndices, CustomerSorters>,
 *    notifications: InMemoryCollection<Notification, NotificationIndices, NotificationSorters>,
 *    carts: InMemoryCollection<Cart, CartIndices, CartSorters>,
 *    cartProducts: InMemoryCollection<CartProduct, any, any>,
 *    stats: InMemoryCollection<Stat, any, any>,
 *    newsItems: InMemoryCollection<NewsItem, NewsItemIndices, NewsItemSorters>,
 *    globalSearchResults: InMemoryCollection<GlobalSearchResult, any, any>,
 *    openingTimes: InMemoryCollection<OpeningTime, OpeningTimeIndices, any>,
 *    closingDays: InMemoryCollection<ClosingDay, ClosingDayIndices, any>
 * }} DataCollections
 */

/**
 * @type {DataCollections}
 */
const collections = {
  images: new InMemoryCollection(images, {
    onAfterSave: makeOnAfterSave(imageHateoasWrapper),
  }),
  tags: new InMemoryCollection(tags, {
    onAfterSave: makeOnAfterSave(tagHateoasWrapper),
  }),
  categories: new InMemoryCollection(categories, {
    onAfterSave: makeOnAfterSave(categoryHateoasWrapper),
  }),
  conversations: new InMemoryCollection(conversations, {
    onAfterSave: makeOnAfterSave(conversationHateoasWrapper),
  }),
  messages: new InMemoryCollection(messages),
  features: new InMemoryCollection(productFeatures, {
    onAfterSave: makeOnAfterSave(productFeaturesHateoasWrapper),
  }),
  featureValues: new InMemoryCollection(featureValues),
  costLists: new InMemoryCollection(costLists, {
    onAfterSave: makeOnAfterSave(costListHateoasWrapper),
  }),
  validityDiscriminators: new InMemoryCollection(validityDiscriminators, {
    onAfterSave: makeOnAfterSave(validityDiscriminatorHateoasWrapper),
  }),
  attributeMatchers: new InMemoryCollection(attributeMatchers),
  deliveryZones: new InMemoryCollection(deliveryZones, {
    onAfterSave: makeOnAfterSave(deliveryZoneHateoasWrapper),
  }),
  deliveryCosts: new InMemoryCollection(deliveryCosts, {
    onAfterSave: makeOnAfterSave(deliveryCostHateoasWrapper),
  }),
  products: new InMemoryCollection(
    products,
    { onAfterSave: makeOnAfterSave(productHateoasWrapper) },
    productFilterer
  ),
  productCategories: new InMemoryCollection(productCategories),
  productImages: new InMemoryCollection(productImages),
  productCosts: new InMemoryCollection(productCosts),
  customers: new InMemoryCollection(customers, {
    onAfterSave: makeOnAfterSave(customerHateoasWrapper),
  }),
  notifications: new InMemoryCollection(notifications, {
    onAfterSave: makeOnAfterSave(notificationHateoasWrapper),
  }),
  carts: new InMemoryCollection(carts, {
    onAfterSave: makeOnAfterSave(cartHateoasWrapper),
  }),
  cartProducts: new InMemoryCollection(cartProducts),
  stats: new InMemoryCollection(stats),
  globalSearchResults: new InMemoryCollection(globalSearchResults),
  openingTimes: new InMemoryCollection(openingTimes),
  closingDays: new InMemoryCollection(closingDays),
  newsItems: new InMemoryCollection(newsItems, {
    onAfterSave: makeOnAfterSave(e => withDefaultHateoas("news-item", e)),
  }),
};

export default collections;
