import {
  FetcherRequestBuilder,
  FetchRequest,
} from "@italwebcom/augmented-fetch";
import { WrapperMeta, CrudOperation } from "../defines";
import jsonifier from "./auxiliary/jsonifier-decorator";

/**
 * @param {string} url
 * @param {OperationParameter[]} parameters
 */
function makeUrl(url, parameters, args) {
  let query = "";
  let outUrl = url;
  if(parameters) {
    for (let par of parameters) {
      if (args[par.name] !== undefined) {
        if (par.scope === "path") {
          outUrl = outUrl.replace("{" + par.name + "}", args[par.name]);
        }
        if (par.scope === "query") {
          query += `&${par.name}=${args[par.name]}`;
        }
      }
    }
  }
  if (query.length) {
    outUrl = `${outUrl}?${query.replace("&", "")}`;
  }
  return outUrl;
}

/**
 * @template T
 */
class CrudOperationResponse {
  /**
   * @type {FetchRequest}
   */
  _request;

  /**
   * @type {WrapperMeta<T>}
   */
  _meta;

  /**
   * @type {FetcherRequestBuilder}
   */
  _requestBuilder;

  /**
   * @param {FetchRequest} request
   * @param {WrapperMeta<T>} meta
   * @param {FetcherRequestBuilder} requestBuilder
   */
  constructor(request, meta, requestBuilder) {
    this._request = request;
    this._meta = meta;
    this._requestBuilder = requestBuilder;
  }

  /**
   * @returns
   */
  response() {
    return this._request.fetch().response();
  }

  /**
   * @returns
   */
  request() {
    return this._request;
  }

  /**
   * @returns
   */
  json() {
    return this._request.fetch().json();
  }

  /**
   * @returns
   */
  async wrapped() {
    const data = await this.json();
    return wrap(data, this._meta, this._requestBuilder);
  }
}

/**
 * @template {CrudOperation} U
 * @template T
 *
 * @param {U} id
 * @param {T} data
 * @param {WrapperMeta<T>} wrapperMeta
 * @param {FetcherRequestBuilder} request
 * @param {Record<string, any>} args
 * @return {CrudOperationResponse}
 */
function makeRequest(id, data, wrapperMeta, request, args, meta) {
  const { linksGetter, bodyGetter } = wrapperMeta;
  const operationsMeta = linksGetter(meta);
  console.log(meta);
  console.log(operationsMeta);
  const matchingOperation = operationsMeta.filter((op) => op.id === id)[0];
  console.log(matchingOperation);
  if (matchingOperation) {
    console.log(
      `url = ${matchingOperation.url}, method = ${
        matchingOperation.method
      }, pars = ${
        matchingOperation.parameters
          ? matchingOperation.parameters.length
          : "none"
      }`
    );
    let { method, url, parameters } = matchingOperation;
    if (method === "GET" || method === "DELETE") {
      url = makeUrl(url, parameters, args);
      return new CrudOperationResponse(
        request(url, { method }),
        wrapperMeta,
        request
      );
    } else {
      let body = bodyGetter ? bodyGetter(data, matchingOperation) : data;
      return new CrudOperationResponse(
        request(url, { body, method }).withDecorator(jsonifier),
        wrapperMeta,
        request
      );
    }
  }
  throw { status: "error", statusText: "missing_operation_handler" };
}

/**
 * @template {CrudOperation} U
 * @template T
 */
export default class CrudOperationsWrapper {
  /**
   * @type {T}
   */
  _data;

  /**
   * @type {WrapperMeta<T>}
   */
  _meta;

  /**
   * @type {FetcherRequestBuilder}
   */
  _requestBuilder;

  /**
   * @param {T} data
   * @param {WrapperMeta<T>} meta
   * @param {FetcherRequestBuilder} requestBuilder
   */
  constructor(data, meta, requestBuilder) {
    this._data = data;
    this._meta = meta;
    this._requestBuilder = requestBuilder;
  }

  /**
   * @return {CrudOperationResponse}
   */
  delete(op) {
    return this.execute(op || "delete");
  }

  /**
   * @return {CrudOperationResponse}
   */
  update() {
    return this.execute("update");
  }

  /**
   * @return {CrudOperationResponse}
   */
  create() {
    return this.execute("create");
  }

  /**
   * @return {CrudOperationResponse}
   */
  read(args) {
    return this.execute("read", args);
  }

  /**
   * @returns
   * @param {Record<string, any>} pars
   */
  fetch(pars) {
    /* istanbul ignore next */
    pars = pars || {};
    return this.execute("fetch", pars);
  }

  /**
   * @param {U} name
   * @param {boolean} withBody
   * @return {CrudOperationResponse}
   */
  execute(name, pars, withBody) {
    return makeRequest(
      name,
      withBody ? pars : this._data,
      this._meta,
      this._requestBuilder,
      !withBody ? pars : {},
      this._data
    );
  }

  /**
   * @param {string} attribute
   * @param {any} value
   * @returns
   */
  set(attribute, value) {
    const newData = { ...this._data };
    newData[attribute] = value;
    return this.wrap(newData);
  }

  /**
   * @return {CrudOperationsWrapper<U, T>}
   * @param {T} data
   */
  wrap(data) {
    const { linksSetter, linksGetter } = this._meta;
    let newData = { ...data };
    if (linksSetter) {
      let links = linksGetter(this._data);
      newData = linksSetter(newData, links);
    }
    return new CrudOperationsWrapper(newData, this._meta, this._requestBuilder);
  }

  /**
   * @return {CrudOperationsWrapper<U, T>}
   * @param {T} data
   */
  decorate(data) {
    return this.wrap({ ...data, ...this._data });
  }

  /**
   * @returns
   */
  data() {
    return this._data;
  }
}

/**
 * @template T
 * @param {T} data
 * @param {WrapperMeta<T>} meta
 * @param {FetcherRequestBuilder} request
 * @returns
 */
export function wrap(data, meta, request) {
  return new CrudOperationsWrapper(data, meta, request);
}
