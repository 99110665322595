import React from "react";
import useStyles from "../styles";
import { TableRow, TableCell, Typography, Button } from "@material-ui/core";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";

/**
 * @template Entity
 * @param {{
 *      attributes: CollectionAttributeRenderer<any, Entity>[],
 *      element: Entity,
 *      keyExtractor: (e: Entity) => number|string,
 *      actions: any,
 *      onAction: Function
 * }} param0
 */
const TableRowRenderer = ({
  attributes,
  element,
  keyExtractor,
  actions,
  onAction,
}) => {
  const { bodyCell } = useStyles();
  return (
    <TableRow key={keyExtractor(element)}>
      {attributes.map(({ id, renderer }) => (
        <TableCell key={id}>
          <Typography variant="body1" component="p" className={bodyCell}>
            {renderer ? renderer(element) : element[id]}
          </Typography>
        </TableCell>
      ))}
      {actions && actions.length && (
        <TableCell key="actions">
          <Button
            type="button"
            variant="text"
            color="secondary"
            endIcon={<KeyboardArrowDown />}
            onClick={(evt) => onAction({ element, actionRef: evt.target })}
          >
            Azioni
          </Button>
        </TableCell>
      )}
    </TableRow>
  );
};

export default TableRowRenderer;