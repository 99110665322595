import React, { memo, useCallback, useState } from "react";
import DatePicker, { DatePickerProps } from "..";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Divider from "@material-ui/core/Divider";
import "./style.css";

/**
 * @typedef {{onClose: Function, onChange: (date: string) => void, open: boolean, title?: string}} AdditionalDialogPickerProps
 */

/**
 * @returns
 * @param {DatePickerProps & AdditionalDialogPickerProps} props
 */
function DialogDatePicker(props) {
  const { open, onChange, onClose, title } = props;
  const [date, setDate] = useState(null);
  const onActualConfirm = useCallback(() => onChange(date), [onChange, date]);
  return (
    <Dialog open={open} disableAutoFocus>
      <DialogTitle>{title || "Selezione data"}</DialogTitle>
      <Divider />
      <DialogContent>
        <DatePicker {...props} onChange={setDate} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box display="flex" justifyContent="flex-end">
          <ButtonGroup>
            <Button
              type="button"
              variant="text"
              color="primary"
              disabled={!date}
              onClick={onActualConfirm}
            >
              Conferma
            </Button>
            <Button
              type="button"
              variant="text"
              color="secondary"
              onClick={onClose}
            >
              Annulla
            </Button>
          </ButtonGroup>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default memo(DialogDatePicker);
