import React, { memo } from "react";
import { Customer } from "@italwebcom/anema-react-components/Customers";
import NotificationList from "../../notifications/CustomerNotificationList";
import { DashboardList } from "../../carts/CartCustomerList";
import CustomerConversationManager from "../../conversations/CustomerConversationManager";
import NewsItemsCustomerRenderer from "../../newsitems/CustomerNewsItemsRenderer";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CustomerDataRenderer from "./CustomerDataRenderer";
import MainContentPanel, {
  MainContentPanelHeaderProps,
} from "../../../MainContentPanel";
import { useCallback } from "react";
import ActiveCartFab from "../../carts/CustomerActiveCartRenderer";
import { useRuntimeContext } from "../../../../contexts";

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    marginBottom: theme.spacing(4),
  },
  panelFooter: {
    display: "flex",
    justifyContent: "space-between",
  },
  footerDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
  },
  topSticky: {
    position: "sticky",
    top: "80px",
  },
  notificationListWrapper: {
    [theme.breakpoints.up("md")]: {
      maxHeight: "calc(100vh - 80px - 48px)",
      overflow: "auto",
    },
  },
}));

function renderReservationsPanel({
  customer,
  onRedirectToEdit,
  sectionHeaderProps,
  onRedirectToReservations,
  classes,
  initialItemsNumber,
}) {
  const { contentWrapper, panelFooter, footerDivider } = classes;
  return (
    <Box className={contentWrapper}>
      <MainContentPanel
        title="Ultime prenotazioni"
        headerProps={sectionHeaderProps}
      >
        <DashboardList
          customer={customer}
          onRedirectToEdit={onRedirectToEdit}
          onRedirectToOverview={onRedirectToEdit}
          initialItemsPerPage={initialItemsNumber || 5}
        />
        <Box className={footerDivider}>
          <Divider />
        </Box>
        <Box className={panelFooter}>
          <Button
            variant="text"
            color="primary"
            onClick={onRedirectToReservations}
            size="small"
          >
            Visualizza tutte
          </Button>
          <ActiveCartFab onSelect={onRedirectToEdit} fabSize="small" noLabel />
        </Box>
      </MainContentPanel>
    </Box>
  );
}

function renderChatPanel({
  customer,
  readOnlyChat,
  sectionHeaderProps,
  onRedirectToChat,
  classes,
  initialItemsNumber,
}) {
  const { panelFooter, footerDivider } = classes;
  return (
    <MainContentPanel title="Chat" headerProps={sectionHeaderProps}>
      <CustomerConversationManager
        id={
          customer.conversation ||
          (customer.mainConversation && customer.mainConversation.id)
        }
        readOnly={readOnlyChat}
        initialMessagesPerPage={initialItemsNumber || 5}
      />
      <Box className={footerDivider}>
        <Divider />
      </Box>
      <Box className={panelFooter}>
        <Button
          variant="text"
          color="primary"
          onClick={onRedirectToChat}
          size="small"
        >
          Vai alla chat
        </Button>
      </Box>
    </MainContentPanel>
  );
}

/**
 * @returns
 * @param {{
 *    customer: Customer,
 *    sectionHeaderProps: MainContentPanelHeaderProps,
 *    onRedirectToEdit: (cart: Cart) => void,
 *    onRedirectToCustomerEdit: (customer: Customer) => void,
 *    onRedirectToReservations: Function,
 *    onRedirectToChat: Function,
 *    readOnlyChat?: boolean
 * }} param0
 */
function CustomerDashboard({
  customer,
  sectionHeaderProps,
  onRedirectToEdit,
  onRedirectToCustomerEdit,
  onRedirectToReservations,
  onRedirectToChat,
  readOnlyChat,
  initialItemsNumber,
}) {
  const {
    contentWrapper,
    panelFooter,
    footerDivider,
    topSticky,
    notificationListWrapper,
  } = useStyles();
  const onActualRedirectToCustomerEdit = useCallback(
    () => onRedirectToCustomerEdit(customer),
    [onRedirectToCustomerEdit, customer]
  );
  const { smallScreen } = useRuntimeContext();
  return (
    customer && (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3}>
          <Box className={topSticky}>
            <CustomerDataRenderer
              customer={customer}
              onDataEdit={onActualRedirectToCustomerEdit}
              smallScreen={smallScreen}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={5}>
          {renderReservationsPanel({
            customer,
            onRedirectToEdit,
            sectionHeaderProps,
            contentWrapper,
            onRedirectToReservations,
            classes: {
              panelFooter,
              footerDivider,
              contentWrapper,
            },
            initialItemsNumber,
          })}
          {/*<Box className={contentWrapper}>
            <MainContentPanel
              title="Ultime notizie"
              headerProps={sectionHeaderProps}
            >
              <NewsItemsCustomerRenderer
                vertical
                carouselDotColor="grey"
                initialItemsPerPage={initialItemsNumber}
              />
            </MainContentPanel>
          </Box>*/}
          {renderChatPanel({
            sectionHeaderProps,
            customer,
            readOnlyChat,
            onRedirectToChat,
            classes: {
              panelFooter,
              footerDivider,
            },
            initialItemsNumber,
          })}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box className={topSticky}>
            <MainContentPanel
              title="Notifiche"
              headerProps={sectionHeaderProps}
            >
              <Box className={notificationListWrapper}>
                <NotificationList
                  customer={customer}
                  initialItemsPerPage={initialItemsNumber || 5}
                />
              </Box>
            </MainContentPanel>
          </Box>
        </Grid>
      </Grid>
    )
  );
}

export default memo(CustomerDashboard);
