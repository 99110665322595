import { useMemo } from "react";

function getPosition(e, offset) {
  if(e.scrollLeft < offset) {
    return "left";
  }
  if(e.scrollLeft >= (e.scrollWidth - offset)) {
    return "right";
  }
  return "middle";
}

/**
 * @param {{current: HTMLElement}} ref
 * @param {number} offset
 * @returns
 */
export default function useContainerScrolling(ref, offset) {
  return useMemo(
    () => ({
      scrollLeft: () =>
        ref.current &&
        ref.current.scroll({
          left: ref.current.scrollLeft - offset,
          behavior: "smooth",
        }),
      scrollRight: () =>
        ref.current &&
        ref.current.scroll({
          left: ref.current.scrollLeft + offset,
          behavior: "smooth",
        }),
    }),
    [ref, offset]
  );
}
