import React from "react";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core"
import Box from "@material-ui/core/Box";
import FormLabel from "@material-ui/core/FormLabel";

const useStyles = makeStyles((theme) => ({
  label: {
    marginBottom: theme.spacing(1),
  },
  fieldset: {
    border: 0,
    margin: 0,
    padding: 0,
  },
  horizontalWrapper: {
    display: "flex",
    alignItems: "center",
  },
  contentWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    flexGrow: 1
  },
}));

export default function Fieldset({ children, label, horizontal }) {
  const {
    label: labelStyle,
    fieldset,
    horizontalWrapper,
    contentWrapper,
  } = useStyles();
  return (
    <Box component="fieldset" className={clsx(["top-fieldset", fieldset])}>
      <Box className={clsx([horizontal && horizontalWrapper])}>
        <Box className={clsx([!horizontal && labelStyle])}>
          <FormLabel component="legend">{label}</FormLabel>
        </Box>
        <Box className={contentWrapper}>{children}</Box>
      </Box>
    </Box>
  );
}
