import { useEffect, useMemo, useRef } from "react";
import useCartAndProducts from "../../CartAndProductsProvider/hooks/useCartAndProducts";

/**
 * @returns
 * @param {number} product
 */
export default function useCartProductSelection(product, selectedElement) {
  const { cartProducts, onCartProductDelete, onCartProductSave, cart } =
    useCartAndProducts();

  const cartRef = useRef();
  const selRef = useRef();

  useEffect(() => {
    if(cart && cartRef) {
      cartRef.current = cart;
    }
  }, [cart, cartRef]);

  const actualSelected = useMemo(() => {
    console.log("useCartProductSelection: recomputing selected element.");
    if (selectedElement) {
      return selectedElement;
    }
    if (cartProducts && cartProducts.length) {
      const cp = cartProducts.filter(
        (t) => t.product && t.product.id === product.id
      );
      if (cp.length) {
        return cp[0];
      }
    }
    return null;
  }, [product, cartProducts, selectedElement]);

  useEffect(() => {
    selRef.current = actualSelected;
  }, [actualSelected, selRef]);

  const { onQuantityChange, onDelete, onAddToCart } = useMemo(
    () => ({
      onQuantityChange: (q) => {
        if (selRef.current) {
          onCartProductSave({ ...selRef.current, quantity: q });
        }
      },
      onDelete: () => {
        if (selRef.current) {
          onCartProductDelete(selRef.current);
        }
      },
      onAddToCart: (q) =>
        onCartProductSave({
          product,
          quantity: q || 1,
          cart: cartRef.current.id,
        }),
    }),
    [selRef, onCartProductDelete, onCartProductSave, cartRef, product]
  );

  return {
    selectedElement: actualSelected,
    onQuantityChange,
    onAddToCart,
    onDelete,
  };
}
