import React, { createContext, memo, useContext, useState } from "react";
import { Table, TableBody, TablePagination } from "@material-ui/core";
import { CollectionRendererProps, defaultKeyExtractor } from "../defines";
import SimpleMenu from "../../Menus/SimpleMenu";
import useProcessedActions from "../Auxiliary/hooks/useProcessedActions";
import ActualSortArrows from "./auxiliary/SortArrows";
import HeaderRenderer from "./auxiliary/HeaderRenderer";
import RowRenderer from "./auxiliary/RowRenderer";

const TableCollectionRendererContext = createContext({
  sortAttribute: null,
  sortDirection: null,
  onSortChange: null,
  setMenuArgs: null,
});

const SortArrows = memo(({ attribute }) => {
  const args = useContext(TableCollectionRendererContext);
  return <ActualSortArrows attribute={attribute} {...args} />;
});

const TableRowRenderer = (props) => {
  const { setMenuArgs } = useContext(TableCollectionRendererContext);
  return <RowRenderer {...props} onAction={setMenuArgs} />;
};

/**
 * @template Entity
 * @param {CollectionRendererProps<any, Entity>} param0
 */
export default function TableCollectionRenderer({
  elements,
  attributes,
  sortAttribute,
  sortDirection,
  onSortChange,
  pagination,
  keyExtractor,
  actions,
}) {
  const [menuArgs, setMenuArgs] = useState({ actionRef: null, element: null });
  const processedOptions = useProcessedActions(actions, menuArgs.element, () =>
    setMenuArgs({ actionRef: null, element: null })
  );
  keyExtractor = keyExtractor || defaultKeyExtractor;
  return (
    <TableCollectionRendererContext.Provider
      value={{ sortAttribute, sortDirection, onSortChange, setMenuArgs }}
    >
      <Table>
        <HeaderRenderer
          attributes={attributes}
          actions={Boolean(actions && actions.length)}
          Sorter={SortArrows}
        />
        <TableBody>
          {elements.map((e) => (
            <TableRowRenderer
              attributes={attributes}
              element={e}
              key={e.id}
              keyExtractor={keyExtractor}
              actions={actions}
            />
          ))}
        </TableBody>
        {pagination && (
          <TablePagination
            count={pagination.count}
            rowsPerPage={pagination.itemsPerPage}
            page={pagination.page}
            onPageChange={(evt, page) => pagination.onPageChange(page)}
            labelDisplayedRows={({ from, to, count, page }) =>
              `${from}-${to} di ${count} totali`
            }
            labelRowsPerPage="Elementi per pagina:"
          />
        )}
      </Table>
      <SimpleMenu
        options={processedOptions}
        anchorEl={menuArgs.actionRef}
        onClose={() => setMenuArgs((args) => ({ ...args, anchorEl: null }))}
      />
    </TableCollectionRendererContext.Provider>
  );
}
