import CustomerProvider, {
  useCustomer,
  CustomerProviderContext,
} from "./Provider";
import CustomerEditor from "./Editor";
import AuthProvider from "./AuthProvider";
import useCustomerDeletion from "./hooks/useCustomerDeletion";
import useCustomerSetToken from "./hooks/useCustomerSetToken";

const components = {
  Provider: CustomerProvider,
  Editor: CustomerEditor,
  AuthProvider,
};
const hooks = { useCustomer, useCustomerDeletion, useCustomerSetToken };
const contexts = { CustomerProviderContext };

export { components, hooks, contexts };
