import MuiThemeProvider from "@material-ui/styles/ThemeProvider";
import theme from "../themes/mui";
import React, { ReactNode } from "react";

/**
 * @param {ReactNode} content
 * @returns
 */
export default function withDefaultTheme(content) {
  return <MuiThemeProvider theme={theme}>{content}</MuiThemeProvider>;
}
