import { withDefaultHateoas } from "../auxiliary/getDefaultHateoasData";
import links from "../../links";
import { DeliveryZoneAttributes, DeliveryZone } from "../../../model";

/**
 * @param {DeliveryZoneAttributes} data 
 * @returns {DeliveryZone}
 */
export default function deliveryZoneHateoasWrapper(data) {
  let dz = withDefaultHateoas(links.deliveryZone.base, data);
  dz._links.costs = {
    href: links.deliveryZone.costs(dz.id),
    templated: true,
  };
  dz._templates.addCost = {
    method: "POST",
    properties: [],
    target: links.deliveryZone.addCost(dz.id),
  };
  return dz;
}
