import { memo, useContext } from "react";
import EntityManagerContext from "../context";
import { Filterer, EntityManagerWrapperProps } from "../defines";
import { ifNotUndef } from "../auxiliary";
import { shouldRender } from "../auxiliary";

/**
 * @param {EntityManagerWrapperProps<Filterer>} param0
 */
function FiltererWrapper({ Component, renderOnError }) {
  const {
    filters,
    onFilterReplace,
    onFilterAdd,
    onFilterRemove,
    onFiltersSetAll,
    onFiltersClear,
    onSearch,
    error
  } = useContext(EntityManagerContext);
  if(shouldRender(error, renderOnError)) {
    return ifNotUndef(Component, {
      filters,
      onFilterReplace,
      onFilterAdd,
      onFilterRemove,
      onFiltersSetAll,
      onFiltersClear,
      onQuery: onSearch,
    });
  }
  return null;
}

export default memo(FiltererWrapper);

