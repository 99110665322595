import { createContext } from "react";
import { MainContentPanelHeaderProps } from "../../components/MainContentPanel";
import { TypographyProps } from "@material-ui/core/Typography";

/**
 * @typedef {{
 *    navigate: (to: string, args: Record<string, any>) => void
 * }} Navigation
 *
 * @typedef {{
 *    dashboard: string,
 *    chat: string,
 *    carts: string,
 *    edit: string
 * }} UserViewNames
 *
 * @typedef {{
 *    home: string,
 *    cartOverview: string,
 *    menuSelection: string,
 *    contacts: string,
 *    user: UserViewNames
 * }} ViewNames
 *
 * @typedef {{
 *    defaultRole: string,
 *    authenticatedRole: string
 * }} AuthNames
 *
 **/

/**
 * @typedef {{
 *    appBar: {
 *      backgroundColor?: string,
 *      logoAlt?: string,
 *      logo?: string,
 *      buttonsColor: string
 *   },
 *   bottomBar: {
 *      backgroundColor: string,
 *      colors: {
 *        selected: string,
 *        default: string
 *      }
 *   },
 *   pageHeader: {
 *        backgroundColor?: string,
 *        titleTypographyProps?: TypographyProps,
 *        backgroundImage?: string,
 *        maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
 *   },
 *   backgroundColor: string,
 *   contentPanelHeader: MainContentPanelHeaderProps,
 *   headersTypographyProps?: TypographyProps,
 * }} GeneralPageStylings
 *
 * @typedef {{
 *   selectionView: {
 *      sliderOffset: string,
 *      titleOffset: string
 *   },
 *   productDetailPanelHeaderProps: {
 *      fontFamily: string,
 *      titleColor?: string
 *   }
 * }} HomePageStylings
 *
 * @typedef {{
 *
 * } & HomePageStylings} ProductSelectionPageStylings
 *
 * @typedef {{
 *    general: GeneralPageStylings,
 *    home: HomePageStylings
 * }} PageStylings
 */

/**
 * @typedef {{
 *    Navigation: Navigation
 *    viewNames: ViewNames,
 *    authentication: AuthNames,
 *    smallScreen?: boolean,
 *    stylings: PageStylings
 * }} RuntimeContextArgs
 */

/**
 * @type {RuntimeContextArgs}
 */
const args = {
  Navigation: {
    navigate: () => {},
  },
  authentication: {
    defaultRole: "unregistered",
    authenticatedRole: "standard",
  },
  viewNames: {
    home: "Home",
    cartOverview: "CartOverview",
    menuSelection: "MenuSelection",
    contacts: "Contacts",
    user: {
      dashboard: "UserDashboard",
      carts: "UserCarts",
      chat: "UserChat",
      edit: "UserEdit",
    },
  },
  smallScreen: false,
  deliveryDisabled: false,
  onlyDefaultPayment: false,
  stylings: {
    home: {},
    general: {},
  },
};

export default createContext(args);
