import React, { useContext, useMemo, ReactChild, ReactNode } from "react";
import Context from "../context";
import { Box, Typography } from "@material-ui/core";
import Content from "../Content";
import styles from "../styles";

function undef(v) {
  return typeof(v) === "undefined";
}

function checkChildren(elements) {
  if (!(elements instanceof Array)) {
    elements = [elements];
  }
  if (
    !elements.map((e) => e.type && e.type === Content).reduce((a, b) => a && b)
  ) {
    throw Error(
      "HulkContent.Section: at least one of the children isn't a Content element!"
    );
  }
}

/**
 * @param {{
 *    title: string,
 *    subtitle?: string,
 *    margin?: string,
 *    marginTop?: string,
 *    marginBottom?: string,
 *    action?: ReactNode,
 *    children: ReactChild[]
 * }} param0
 * @returns
 */
export default function Section({
  title,
  subtitle,
  action,
  children,
  margin,
  marginTop,
  marginBottom,
}) {
  const { sectionTitle, sectionSubtitle } = styles();
  const { sectionMargin: defaultMargin } = useContext(Context);
  const { marginTop: actualMarginTop, marginBottom: actualMarginBottom } = useMemo(
    () => ({
      marginTop: undef(marginTop) ? (margin || defaultMargin) : marginTop,
      marginBottom: undef(marginBottom) ? (margin || defaultMargin) : marginBottom
    }),
    [defaultMargin, margin, marginTop, marginBottom]
  );
  checkChildren(children);
  return (
    <Box
      marginTop={actualMarginTop}
      marginBottom={actualMarginBottom}
      component="section"
    >
      <Box
        component="header"
        marginBottom="0.75rem"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Typography component="h5" variant="h5" className={sectionTitle}>
            {title}
          </Typography>
          {subtitle && (
            <Typography
              component="p"
              variant="subtitle1"
              className={sectionSubtitle}
            >
              {subtitle}
            </Typography>
          )}
        </Box>
        {action}
      </Box>
      {children}
    </Box>
  );
}
