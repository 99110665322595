import { createContext } from "react";
import { CartProduct } from "../../../../../__domain/model";
import { Cart } from "../../../../../__domain/model";

/**
 * @type {{
 *      cartProducts: CartProduct[],
 *      onDelete: (cp: CartProduct) => Promise<any>,
 *      onSave: (cp: CartProduct) => Promise<any>,
 *      loading: boolean,
 *      cart: Cart
 * }}
 */
const args = {
  cartProducts: null,
  onDelete: null,
  onSave: null,
};

export default createContext(args);
