import {HulkAlertType} from "../HulkAlert"

/**
 * @typedef {{content: string, type: HulkAlertType}} HulkAlertContainerInput
 * @typedef {HulkAlertContainerInput & {key: number | string}} HulkAlertContainerMessage
 * @typedef {{vertical: "top" | "bottom", horizontal: "left" | "right"}} HulkAlertContainerPosition
 * @typedef {(stuff: HulkAlertContainerInput) => number | string} HulkAlertContainerKeyGetter
 */

const dummy = {};
export default dummy;