import { withDefaultHateoas } from "../../hateoas/auxiliary/getDefaultHateoasData";
import links from "../../links";
import { DeliveryCostAttributes } from "../../../model";

/**
 * @param {DeliveryCostAttributes} data 
 * @returns 
 */
export default function deliveryZoneHateoasWrapper(data) {
  let dc = withDefaultHateoas(links.deliveryCost.base, data);
  dc._links.discriminators = {
    href: links.deliveryCost.discriminators(data.id),
    templated: true
  }
  dc._templates.addDiscriminator = {
    method: "POST",
    properties: [],
    target: links.deliveryCost.discriminator(data.id)
  }
  return dc;
}
