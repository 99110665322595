import React, { ReactChildren, useCallback } from "react";
import { OpenIDConnectTokenManager } from "@italwebcom/augmented-fetch";
import { Auth, ErrorObservable } from "@italwebcom/react-auth-provider";
import AuthContext from "../context";
import {makeInitialCredentials} from "../hooks/useInitialCredentials";
import isAuthorized from "../functions/isAuthorized";

/**
 * @param {{
 *      children: ReactChildren, 
 *      errorObservable: ErrorObservable,
 *      manager: OpenIDConnectTokenManager
 * }} param0
 * @returns
 */
export default function AuthenticationProvider({
  children,
  errorObservable,
  manager,
  defaultRole,
  initialCredentials
}) {
  const auxInitialCredentials = initialCredentials || makeInitialCredentials(defaultRole);
  const actualIsAuthorized = useCallback((a, b) => isAuthorized(a, b, defaultRole), [defaultRole]);
  return (
    <AuthContext.Provider value={{errorObservable, manager}}>
      <Auth.Provider
        isAuthorized={actualIsAuthorized}
        initialCredentials={auxInitialCredentials}
        errorObservable={errorObservable}
      >
        {children}
      </Auth.Provider>
    </AuthContext.Provider>
  );
}
