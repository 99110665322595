import { useCallback } from "react";

export default function useHandlerAndStopPropagation(handler) {
  return useCallback(
    (evt) => {
      evt.stopPropagation();
      handler(evt);
    },
    [handler]
  );
}
