import {useCallback, useMemo, useState} from "react";

/**
 * @returns
 * @param {string[]} names 
 */
function initSwitches(names) {
    return names.map(n => ({switch: false, name: n}));
}

/**
 * @returns
 * @param {{names: string[]}} param0 
 */
export default function useSwitches(names) {
    const [switches, setSwitches] = useState(initSwitches(names));
    const onSwitch = useCallback(/** @param {string} name */ name => {
        setSwitches(switches => {
            const out = [...switches];
            const match = out.filter(e => e.name === name);
            if(match.length) {
                const i = out.indexOf(match[0]);
                out[i] = {...out[i], switch: !out[i].switch};
            }
            return out;
        })
    }, [switches]);
    const outSwitches = useMemo(() => {
        let out = {};
        for(let sw of switches) {
            out[sw.name] = sw.switch;
        }
        return out;
    }, [switches]);
    return {switches: outSwitches, onSwitch}
}