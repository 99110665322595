import React, { memo, ComponentType, useMemo } from "react";
import useFormData from "../hooks/useFormData";

/**
 * If the field prop is provided, it gets rendered when the corresponding input value
 * is errored according to its field definition. Otherwise, the Component is rendered
 * whenever a given input is errored.
 *
 * @typedef {{field: string, message: string}} PrinterProps
 * @typedef {ComponentType<PrinterProps>} Printer
 *
 * @param {{Component: Printer, field?: string}} param0
 */
function ErrorPrinter({ Component, field, ComponentProps }) {
  const { invalidValue } = useFormData();
  const shouldRender = useMemo(() => {
    if (field && invalidValue) {
      return invalidValue.id === field;
    }
    return Boolean(invalidValue);
  }, [field, invalidValue]);
  if (shouldRender) {
    const { id, invalidMessage } = invalidValue;
    return (
      <Component
        field={field}
        message={invalidMessage}
        {...(ComponentProps || {})}
      />
    );
  }
  return null;
}

export default memo(ErrorPrinter);
