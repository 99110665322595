import { Tag } from "../../model";
import categories from "./categories";
import wrappers from "../hateoas";
import {tagNames, getFilterType} from "../auxiliary/tagData";

/**
 * @type {(Tag|Category)[]}
 */
let tags = [...categories];

for (let i = 0; i < 20; i += 2) {
  tags.push(
    wrappers.tag({
      id: i,
      name: tagNames[i/2],
      type: "tag",
      filterType: getFilterType(i)
    })
  );
}
/*
tags.push(
  wrappers.tag({
    id: 1,
    name: "Fritti",
    filterType: "tag_type_1",
    mainImageUrl: "https://billis.italweb.app/billis-app-alt/server/public/img/824711335.jpg"
  })
)

tags.push(
  wrappers.tag({
    id: 2,
    name: "Grill",
    filterType: "tag_type_1",
    mainImageUrl: "https://billis.italweb.app/billis-app-alt/server/public/img/ribs.jpg"
  })
)

tags.push(
  wrappers.tag({
    id: 3,
    name: "Panini",
    filterType: "tag_type_1",
    mainImageUrl: "https://billis.italweb.app/billis-app-alt/server/public/img/biggym.jpg"
  })
)

tags.push(
  wrappers.tag({
    id: 4,
    name: "Pizze",
    filterType: "tag_type_1",
    mainImageUrl: "https://billis.italweb.app/billis-app-alt/server/public/img/Pizza_icon.png"
  })
)*/


tags.push(
  wrappers.tag({
    id: 1,
    name: "Pizze bianche",
    filterType: "tag_type_1",
    mainImageUrl: "https://animacore.italweb.agency/file/Posillipo.jpg"
  })
)

tags.push(
  wrappers.tag({
    id: 2,
    name: "Pizze bianche speciali",
    filterType: "tag_type_1",
    mainImageUrl: "https://animacore.italweb.agency/file/Biancolina.jpg"
  })
)

tags.push(
  wrappers.tag({
    id: 3,
    name: "Pizze rosse",
    filterType: "tag_type_1",
    mainImageUrl: "https://animacore.italweb.agency/file/Bufalina.jpg"
  })
)

tags.push(
  wrappers.tag({
    id: 4,
    name: "Calzoni",
    filterType: "tag_type_1",
    mainImageUrl: "https://animacore.italweb.agency/file/Calzone.jpg"
  })
)

tags.push(
  wrappers.tag({
    id: 5,
    name: "Panuozzo",
    filterType: "tag_type_1",
    mainImageUrl: "https://animacore.italweb.agency/file/Panuozzo.jpg"
  })
)

export default tags;
