import { templates } from "@italwebcom/anema-shared-web-components";
import { memo } from "react";
import { productSelectionViewFilters } from "../../data";
import { useMemo } from "react";

const { productSelection: ProductSelectionPageTemplate } = templates;

function useReservationId() {
  return useMemo(() => {
    let sParams = new URLSearchParams(
      window.location.href.replace(/^.*[\\?]/, "")
    );
    if (sParams.has("reservation")) {
      return parseInt(sParams.get("reservation"));
    }
  }, []);
}

const data = {
  backgroundColor: "#F0F2F5",
  title: "Menù",
};

function ProductSelectionPage() {
  const id = useReservationId();
  return (
    <ProductSelectionPageTemplate
      cart={id}
      productSelectionViewSectionFilters={productSelectionViewFilters}
      backgroundColor={data.backgroundColor}
      title={data.title}
      productSelectionViewInitialCategoryFilter={3}
      noHeader
    />
  );
}

export default memo(ProductSelectionPage);
