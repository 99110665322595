import React, { memo } from "react";
import ItemRenderer from "../../../ItemRenderers/ListItemRenderer";
import { ImagedList } from "@italwebcom/misc-ui-components";
import PlaceholderWrapper from "../../../../../../PlaceholderWrapper";

function PanelRenderer({ elements, placeholder, image, onSelect }) {
  return (
    <ImagedList.List rows={2} elementHeight={68}>
      <ImagedList.ListImage
        src={image}
        alt="Immagine"
        elementSpan={3}
        rowSpan={1}
        float="right"
        renderImage={(img, { height, width }) => (
          <PlaceholderWrapper
            active={placeholder}
            dimensions={{ height, width: "100%" }}
            containerStyle={{ height, width, margin: "auto" }}
          >
            {img}
          </PlaceholderWrapper>
        )}
      />
      {elements.map(
        (e) =>
          (
            <ImagedList.ListElement>
              <ItemRenderer
                element={e}
                placeholder={placeholder}
                style={{ padding: "12px 32px 12px 8px" }}
                onSelect={onSelect}
              />
            </ImagedList.ListElement>
          )
      )}
    </ImagedList.List>
  );
}

export default memo(PanelRenderer);
