import React, { memo, useCallback, useMemo } from "react";
import IconButton from "@material-ui/core/IconButton";
import Remove from "@material-ui/icons/Remove";
import { CartProduct } from "@italwebcom/anema-react-components/Carts";

/**
 * @param {{selectedElement: CartProduct, onChange: (quantity: number) => void}} param0
 * @returns
 */
function MinusButton({ selectedElement, onChange, disabled }) {
  const onActualChange = useCallback(
    () => onChange(selectedElement.quantity - 1),
    [onChange, selectedElement]
  );
  const actuallyDisabled = useMemo(
    () => disabled || selectedElement.quantity <= 1,
    [disabled, selectedElement]
  );
  return (
    <IconButton
      disabled={actuallyDisabled}
      size="small"
      onClick={onActualChange}
    >
      <Remove />
    </IconButton>
  );
}

export default memo(MinusButton);
