import React, {
  ComponentType,
  useCallback,
  useState,
  InputHTMLAttributes,
  useContext,
  createContext,
  memo,
  useMemo,
} from "react";
import ValidatableTextField, {
  ValidatableTextFieldValidationRule,
} from "../../Inputs/ValidatableTextField";
import { useEntityEdit } from "@italwebcom/custom-react-hooks";
import {
  Grid,
  Box,
  Button,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import DeleteOutline from "@material-ui/icons/DeleteOutline";

function toObj(attrs) {
  let out = {};
  for (let a of attrs) {
    out[a.attribute] = a.rendered;
  }
  return out;
}

const defaultComposer = (obj, button) => (
  <Grid container spacing={2}>
    {Object.getOwnPropertyNames(obj).map((o) => (
      <Grid item xs={6} key={o}>
        {obj[o]}
      </Grid>
    ))}
    <Grid item xs={12} key="button">
      {button}
    </Grid>
  </Grid>
);

const DaContext = createContext({
  onSet: null,
  setValidation: null,
  element: null,
});

const undef = (a) => typeof a === undefined;

const checkNonOptionalAttributes = (e, attrs) => {
  let valid = true;
  for (let a of attrs) {
    if (!a.optional && undef(e[a.attribute])) {
      valid = false;
    }
  }
  return valid;
};
const SingleInputRenderer = memo(
  ({
    attribute,
    label,
    validationRules,
    type,
    value,
    InputProps,
    inputProps,
    noClear,
    testID,
    Renderer,
  }) => {
    const { onSet, setValidation, onClear } = useContext(DaContext);
    const shit = useMemo(() => validationRules || [], [validationRules]);
    if (Renderer) {
      return (
        <Renderer
          attribute={attribute}
          value={value}
          onSet={onSet}
          label={label}
        />
      );
    } else {
      return (
        <ValidatableTextField
          inputProps={inputProps}
          testID={testID}
          fullWidth
          variant="outlined"
          validationRules={shit}
          defaultValue={value}
          onValueChange={(v, validationResult) => {
            onSet(attribute, v);
            setValidation(validationResult);
          }}
          InputProps={{
            endAdornment: !noClear && (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => onClear(attribute)}
                  data-testid={`${testID}-clear`}
                >
                  <DeleteOutline />
                </IconButton>
              </InputAdornment>
            ),
            ...(InputProps || {}),
          }}
          printError
          onlyPrintErrorWithValue
          label={label || attribute}
          type={type}
        />
      );
    }
  },
  (p, n) => p.value === n.value
);

/**
 * @typedef {{value?: any, onSet: (attribute: string, value: any) => void}} InputRendererProps
 * @typedef {ComponentType<InputRendererProps>} InputRenderer
 */

/**
 * @template {string} Attributes
 * @typedef {{
 *      Renderer?: InputRenderer,
 *      attribute: Attributes,
 *      label?: string,
 *      type?: InputHTMLAttributes<unknown>['type'] | "onoff",
 *      validationRules?: ValidatableTextFieldValidationRule[],
 *      optional?: boolean,
 *      InputProps?: any,
 *      inputProps?: any,
 *      noClear?: boolean
 * }} FormEditEntityRendererAttribute
 */

/**
 * @template {string} Attributes
 * @template T
 * @typedef {{
 *      entity: T,
 *      attributes: FormEditEntityRendererAttribute<Attributes>[],
 *      onSubmit: (entity: T) => void,
 *      testID?: string,
 *      composer: (elements: Record<Attributes, any>, button: ReactNode) => ReactNode,
 *      loading?: boolean
 * }} FormEditEntityRendererProps
 */

/**
 * @template Attributes
 * @template T
 * @param {FormEditEntityRendererProps<Attributes, T>} param0
 */
function FormEditEntityRenderer({
  entity,
  attributes,
  onSubmit,
  testID,
  composer,
  loading,
}) {
  testID = testID || "test-form";
  composer = composer || defaultComposer;

  const [validation, setValidation] = useState(null);
  const { element, onSet, onClear } = useEntityEdit(entity);
  const onFormSubmit = useCallback(
    (evt) => {
      evt.preventDefault();
      onSubmit(element);
    },
    [onSubmit, element]
  );
  const isButtonDisabled =
    loading ||
    (validation && !validation.valid) ||
    !checkNonOptionalAttributes(element, attributes);

  const button = (
    <Button
      fullWidth
      type="submit"
      variant="outlined"
      color="primary"
      data-testid={`${testID}-submit-button`}
      disabled={isButtonDisabled}
    >
      {!loading ? "Conferma" : <CircularProgress size={12} />}
    </Button>
  );

  const renderedAttrs = attributes.map((a) => ({
    rendered: (
      <SingleInputRenderer
        {...a}
        value={element && element[a.attribute]}
        testID={`${testID}-input-${a.attribute}`}
      />
    ),
    attribute: a.attribute,
  }));

  return (
    <DaContext.Provider value={{ onSet, setValidation, onClear }}>
      <Box
        component="form"
        onSubmit={onFormSubmit}
        data-testid={`${testID}-form`}
      >
        {composer(toObj(renderedAttrs), button, element, isButtonDisabled)}
      </Box>
    </DaContext.Provider>
  );
}

export default memo(FormEditEntityRenderer);
