import React from "react";
import Context from "../context";
import { DefaultComponentsContextArgs } from "../defines";

/**
 * @param {DefaultComponentsContextArgs} param0
 * @returns
 */
export default function DefaultComponentsProvider({
  children,
  fetchArgsProcessor,
  defaultCountGetter,
  ListCollectionRenderer,
  Loader,
}) {
  return (
    <Context.Provider
      value={{
        Loader,
        ListCollectionRenderer,
        fetchArgsProcessor,
        defaultCountGetter,
      }}
    >
      {children}
    </Context.Provider>
  );
}
