import SectionContext from "../contexts/section";
import React, { ComponentType, memo, useContext } from "react";

/**
 * @typedef {{tag: Tag, loading: boolean}} SectionRendererComponentProps
 * @typedef {{Renderer: ComponentType<SectionRendererComponentProps>}} SectionRendererProps
 */

/**
 * @returns
 * @param {SectionRendererProps} param0
 */
function SectionRenderer({ Component }) {
  const { tag, loading } = useContext(SectionContext);
  return <Component tag={tag} loading={loading} />;
}

const Out = memo(SectionRenderer);
export default Out;
