import {
  CollectionAttributeRenderer,
  ListCollectionRoles,
} from "@italwebcom/misc-ui-components";
import { Cart } from "../../../model";
import { formatMessageDate } from "../../../functions";
import { Carts } from "@italwebcom/anema-react-components";

const { isCartPayable } = Carts.functions;

export function printStatus(status, short, cart) {
  if (status) {
    const toPrint = status.toLowerCase();
    switch (toPrint) {
      case "confirmed":
        return "Confermata";
      case "closed":
        return cart && isCartPayable(cart)
          ? "In attesa di pagamento"
          : "In attesa di conferma";
      case "paid":
        return short ? "Pagata" : "Pagata in attesa di conferma";
      case "aborted":
        return "Annullata";
      case "open":
        return "Aperta";
      default:
        return "Attiva";
    }
  }
  return "";
}

export function getStatusColor(status, cart) {
  const toPrint = status.toLowerCase();
  switch (toPrint) {
    case "confirmed":
      return "green";
    case "closed":
      return cart && isCartPayable(cart) ? "red" : "blue";
    case "paid":
      return "rgb(32, 132, 232)";
    default:
      return "grey";
  }
}

export function printType(type, cart) {
  switch (type) {
    case "delivery":
      return "Consegna";
    case "default":
      return "Asporto";
    default:
      return `Tavolo per ${(cart && cart.guests) || "?"}`;
  }
}

export function printDate(e) {
  return formatMessageDate(e.date, "DD MMM YYYY");
}

/**
 * @type {CollectionAttributeRenderer<ListCollectionRoles, Cart>[]}
 */
const attributes = [
  {
    id: "date",
    sortable: true,
    label: "Data",
    type: "subtitle",
    renderer: (e) => e && `del ${printDate(e)}, ${e.origin}`,
  },
  {
    id: "status",
    label: "Stato",
    sortable: true,
    type: "title",
    renderer: (e) => e && `${printStatus(e.status, true, e)}`,
  },
];

export const tableAttributes = [
  {
    id: "date",
    label: "Data",
    sortable: true,
    renderer: (e) => e && `${printDate(e)}`,
  },
  {
    id: "type",
    label: "Tipo",
    sortable: true,
    renderer: (e) => printType(e.type, e),
  },
  {
    id: "totalCost",
    label: "Costo",
    sortable: true,
    renderer: (e) => `${e.totalCost}`,
  },
  {
    id: "status",
    label: "Stato",
    sortable: true,
    renderer: (e) => e && `${printStatus(e.status, true, e)}`,
  },
  {
    id: "customer",
    label: "Cliente",
    sortable: false,
    renderer: (e) => e.customer && (e.customer.name || e.customer.fullName),
  },
];

export default attributes;
