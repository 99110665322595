import React, { memo } from "react";
import { Grid, Box, makeStyles } from "@material-ui/core";
import SimpleSorter from "../../Inputs/SimpleSorter";
import SimpleSearchBar from "../../Inputs/SimpleSearchBar";

const useStyles = makeStyles((theme) => ({
  bar: {
    marginBottom: theme.spacing(1),
  },
}));

function CompositeRenderer({
  children,
  attributes,
  sortAttribute,
  sortDirection,
  onSortChange,
  onSearch,
  container,
  spacing,
}) {
  const { bar } = useStyles();
  const hasSorters = Boolean(onSortChange && attributes && attributes.length);
  return (
    <Box>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className={bar}
      >
        {hasSorters && (
          <Grid item xs={4}>
            <SimpleSorter
              attributes={attributes}
              defaultSortAttribute={sortAttribute}
              defaultSortDirection={sortDirection}
              onChange={onSortChange}
            />
          </Grid>
        )}
        {onSearch && (
          <Grid item xs={hasSorters ? 4 : 12}>
            <SimpleSearchBar onChange={onSearch} />
          </Grid>
        )}
      </Grid>
      <Grid container={container} spacing={spacing}>
        {children}
      </Grid>
    </Box>
  );
}

export default memo(CompositeRenderer);