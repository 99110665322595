import React, { memo } from "react";
import { Carts } from "@italwebcom/anema-react-components";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ItemRenderer from "../ItemRenderer";
import { NonWrappedDefaultErrorPrinter } from "../../../../_auxiliary/DefaultErrorPrinter";
import MissingElementRenderer from "../../../../MissingElementRenderer";
import LocalDining from "@material-ui/icons/LocalDining";

const { useCarts } = Carts.hooks;

function makePlaceholders(n) {
  let out = [];
  for (let i = 0; i < n; i++) {
    out.push({ id: i, date: "2022-11-11", time: "11:20", status: "OPEN" });
  }
  return out;
}

function renderElements(elements, placeholder, loading) {
  let els = elements;
  if (placeholder) {
    els = makePlaceholders(3);
  }
  return (
    <Grid container spacing={3}>
      {els.map((e) => (
        <Grid item xs={12} key={e.id}>
          <ItemRenderer element={e} placeholder={placeholder} />
        </Grid>
      ))}
    </Grid>
  );
}

function renderNoElementsMessage() {
  return (
    <MissingElementRenderer
      Icon={LocalDining}
      title="Nessuna prenotazione presente."
      content="Non hai ancora effettuato prenotazioni."
    />
  );
}

function ListRenderer({ placeholder: inPlaceholder }) {
  const { elements, loading, error, onRefreshAll } = useCarts();
  const hasEls = elements && elements.length;
  const isPlaceholder = inPlaceholder || (!hasEls && loading);
  if (error) {
    return <NonWrappedDefaultErrorPrinter onRefreshAll={onRefreshAll} />;
  } else {
    if (isPlaceholder || hasEls) {
      return renderElements(elements, isPlaceholder, loading);
    } else {
      return renderNoElementsMessage();
    }
  }
}

export default memo(ListRenderer);
