/* istanbul ignore file */

import { createContext, Context } from "react";

/**
 * @typedef {{role: string, priority: number}} Role
 * @type {Context<{onAddRole: (role: Role) => void, selectedRole: Role}>}
 */
export default createContext({
    onAddRole: () => null,
    selectedRole: null
})