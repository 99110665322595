import React from "react";
import {IconButton, makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(1),
        borderRadius: theme.spacing(1),
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: "white"
        },
        "&:not(:hover)": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.dark
        }
    }
}));

/**
 * @param {{
 *      Icon: string,
 *      onPress: Function
 * }} param0 
 */
export default function CustomIconButton({Icon, onPress}) {
    const styles = useStyles();
    return <IconButton onClick={onPress} size="small" className={styles.wrapper}>
        <Icon />
    </IconButton>;
}