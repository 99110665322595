import React, { memo } from "react";
import Delete from "@material-ui/icons/Delete";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Reply from "@material-ui/icons/Reply";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core"
import ButtonGroup from "@material-ui/core/ButtonGroup";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  addToCartIcon: {
    transform: "scale(-1, 1)",
  },
  iconButton: {
    minWidth: "30px",
  },
}));

function InputButtonGroup({ onRemove, onSelect, disabled }) {
  const { addToCartIcon, iconButton } = useStyles();
  return (
    <ButtonGroup>
      {onSelect && (
        <IconButton
          onClick={onSelect}
          size="small"
          disabled={disabled}
          className={iconButton}
        >
          <Reply fontSize="inherit" className={clsx([addToCartIcon])} />
          <ShoppingCart fontSize="inherit" />
        </IconButton>
      )}
      {onRemove && (
        <IconButton
          onClick={onRemove}
          size="small"
          disabled={disabled}
          className={iconButton}
        >
          <Delete fontSize="inherit" />
        </IconButton>
      )}
    </ButtonGroup>
  );
}

export default memo(InputButtonGroup);
