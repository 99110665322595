import React, { memo } from "react";
import CartProviderContext from "../context";
import {
  CollectionRendererProps,
  useEntityManagerValues,
} from "@italwebcom/react-entity-manager";
import { useMemo } from "react";

/**
 * @param {CollectionRendererProps<Cart>} param0
 */
function CollectionRenderer({ elements, onSave, onRefreshAll, children }) {
  const { loading, error } = useEntityManagerValues();
  const cart = useMemo(
    () => {
      let els;
      if (elements && !(elements instanceof Array)) {
        els = [elements];
      } else {
        els = elements;
      }
      return (els && els.length ? els[0] : null)
    },
    [elements]
  );
  return (
    <CartProviderContext.Provider
      value={{
        cart,
        onSave,
        onRefreshAll,
        loading,
        errored: Boolean(error)
      }}
    >
      {children}
    </CartProviderContext.Provider>
  );
}

export default memo(CollectionRenderer);
