import React, { Fragment } from "react";
import { Divider, Menu, MenuItem, Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import useHandlerAndStopPropagation from "../../hooks/useHandlerAndStopPropagation";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: "bold",
  },
  menu: {
    minWidth: "160px",
  },
  menuItem: {
    paddingTop: "0.5em",
    paddingBottom: "0.5em",
  },
}));

function OptionRenderer(props) {
  const { menu, menuItem } = useStyles();
  const { id, label, onSelect, disabled } = props;
  const onActualSelect = useHandlerAndStopPropagation(onSelect);
  return (
    <MenuItem
      key={id}
      onClick={onActualSelect}
      className={clsx([menu, menuItem])}
      disabled={disabled}
    >
      {label || id}
    </MenuItem>
  );
}

/**
 * @typedef {{
 *      id: string,
 *      label?: string,
 *      onSelect: Function,
 *      disabled?: boolean
 * }} SimpleMenuOption
 * @typedef {{
 *      anchorEl: any,
 *      options: SimpleMenuOption[],
 *      onClose: Function,
 *      label?: string
 * }} SimpleMenuProps
 * @param {SimpleMenuProps} param0
 */
function SimpleMenu({ anchorEl, options, onClose, label }) {
  const { menuItem, header } = useStyles();
  return options ? (
    <Menu
      TransitionComponent={Fade}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      getContentAnchorEl={null}
    >
      {label && [
        <MenuItem disabled key="label" className={clsx([menuItem, header])}>
          {label}
        </MenuItem>,
        <Divider />,
      ]}
      {options.map(OptionRenderer)}
    </Menu>
  ) : null;
}

export default SimpleMenu;
