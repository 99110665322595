import React, { useMemo, ReactNode } from "react";
import { Checkbox } from "@material-ui/core";
import { useCollectionState } from "@italwebcom/custom-react-hooks";

/**
 * @template Entity
 * @typedef {{
 *      testIDGetter: (element: Entity) => string,
 *      allCheckboxTestID: string
 * }} UseCollectionCheckboxesHookConfig
 */

/**
 * @template Entity
 * @param {{
 *      elements: Entity[],
 *      config: UseCollectionCheckboxesHookConfig<Entity>
 * }} param0
 * @return {{
 *      checkboxes: ReactNode[],
 *      collection: Entity[],
 *      allCheckbox: ReactNode
 * }}
 */
export default function useCollectionCheckboxes({ elements, config }) {
  const { testIDGetter, allCheckboxTestID } = config;
  const { collection, onAdd, onSetAll, onRemove } = useCollectionState({});
  const checkboxes = useMemo(
    () =>
      elements && elements.length
        ? elements.map((element) => {
            const checked = collection.indexOf(element) !== -1;
            return (
              <Checkbox
                onChange={(evt, checked) =>
                  checked ? onAdd(element) : onRemove(element)
                }
                checked={checked}
                inputProps={{
                  "data-testid": testIDGetter(element),
                  "data-checked": checked,
                }}
                key={testIDGetter(element)}
              />
            );
          })
        : [],
    [elements, collection]
  );
  const allCheckbox = useMemo(() => {
    const checked = elements && collection.length === elements.length;
    return (
      <Checkbox
        key={allCheckboxTestID}
        inputProps={{
          "data-testid": allCheckboxTestID,
          "data-checked": checked,
        }}
        checked={checked}
        onChange={(e, checked) => onSetAll(checked ? elements : [])}
      />
    );
  }, [elements, collection]);
  return { collection, checkboxes, allCheckbox };
}
