import React from "react";
import {
  Box,
  Grid,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  titleBar: {
    backgroundColor: "#4d7df2",
    color: "#fff",
  },
  titleBarInnerWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  contentContainer: {
    backgroundColor: "#f3f7fa",
  },
  minHeight: {
    minHeight: "75px",
  },
  externalWrapper: {
    position: "relative",
    height: "100%",
  },
  centered: {
    textAlign: "center",
  },
}));

/**
 * @param {{
 *      children: ReactChildren,
 *      title?: string,
 *      subtitle?: string,
 *      leftBarContent?: ReactNode,
 *      rightBarContent?: ReactNode,
 *      barMaxWidth?: string,
 *      smallScreen?: boolean
 * }} param0
 *
 * @returns
 */
export default function HulkContentView({
  children,
  title,
  subtitle,
  leftBarContent,
  rightBarContent,
  barMaxWidth,
  smallScreen,
  alwaysShowRightContent
}) {
  const {
    titleBar,
    contentContainer,
    titleBarInnerWrapper,
    minHeight,
    externalWrapper,
    centered,
  } = useStyles();

  return (
    <Box className={externalWrapper}>
      <Box display="flex" flexDirection="column" height="100%">
        <Box className={titleBar}>
          <Container
            maxWidth={barMaxWidth || (smallScreen ? false : "lg")}
            disableGutters={smallScreen}
          >
            <Grid
              container
              className={clsx([titleBarInnerWrapper, !smallScreen && minHeight])}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid
                item
                container
                spacing={2}
                component="article"
                xs={12}
                sm={5}
              >
                <Grid item xs={12}>
                  <Typography
                    component="h2"
                    variant="h4"
                    className={clsx([smallScreen && centered])}
                  >
                    {title}
                  </Typography>
                </Grid>
                {subtitle && (
                  <Grid item xs={12}>
                    <Typography
                      component="p"
                      variant="body1"
                      className={clsx([smallScreen && centered])}
                    >
                      {subtitle}
                    </Typography>
                  </Grid>
                )}
                {leftBarContent && <Grid item xs={smallScreen && 12}>{leftBarContent}</Grid>}
              </Grid>
              {(!smallScreen || alwaysShowRightContent) && rightBarContent && (
                <Grid item xs={3}>
                  {rightBarContent}
                </Grid>
              )}
            </Grid>
          </Container>
        </Box>
        <Box flexGrow={1} className={contentContainer}>
          <Container maxWidth={false} disableGutters={smallScreen}>
            {children}
          </Container>
        </Box>
      </Box>
    </Box>
  );
}
