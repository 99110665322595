import React from "react";
import {
  createHashRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import routes from "../routes";
import { Wrappers } from "@italwebcom/anema-react-components";
import { authFacade, Wrapper } from "../auxiliary";
import { OpenIDConnectTokenManager } from "@italwebcom/augmented-fetch";
import { webStorage } from "../auxiliary";

const { collections } = Wrappers.data;
const { makeWrappers } = Wrappers.functions;

export default function mockApp() {

  const facade = authFacade({
    accessToken: "token",
    discoveryDocument: { authorizationEndpoint: "a", tokenEndpoint: "t" },
    userInfo: {
      username: "customer0",
      realm_access: {
        roles: ["standard"],
      },
    },
  });

  const manager = new OpenIDConnectTokenManager(
    { clientId: "client", clientSecret: "secret", redirectUri: "redirect" },
    facade
  );

  const cookieAccessors = {
    get: () => document.cookie,
    set: (k, v) => (document.cookie = `${k}=${v}`),
  };
  const { wrappers, fetcher } = makeWrappers(collections);
  const storage = webStorage({ type: "cookie", accessors: cookieAccessors });

  const router = createHashRouter(createRoutesFromElements(routes()));

  return (
    <Wrapper
      fetcher={fetcher}
      manager={manager}
      storage={storage}
      wrappers={wrappers}
    >
      <RouterProvider router={router} fallbackElement={<span>SHIT!</span>} />
    </Wrapper>
  );
}
