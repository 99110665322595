import React from "react";
import {
  Box,
  makeStyles,
  Typography,
  TypographyVariant,
} from "@material-ui/core";
import clsx from "clsx";
import useStyles from "../../styles";

/**
 * @param {{label: string, value: string, bold?: boolean, variant?: TypographyVariant}} param0
 * @returns
 */
export default function TextCouple({
  label,
  value,
  bold,
  variant,
  className,
  vertical,
  disableValueTypography,
  valueVariant,
  valueStyle: propValueStyle
}) {
  const {
    bold: boldStyle,
    innerPar: innerPStyle,
    value: valueStyle,
    centered,
  } = useStyles();
  return (
    <Box
      display={vertical ? "block" : "flex"}
      justifyContent="space-between"
      component={vertical ? "div" : "p"}
      className={clsx([bold && boldStyle, className])}
    >
      <Typography
        component={vertical ? "p" : "span"}
        variant={variant || "body1"}
        className={innerPStyle}
      >
        {label}
      </Typography>
      {disableValueTypography ? (
        value
      ) : (
        <Typography
          component={vertical ? "p" : "span"}
          variant={valueVariant || variant || "body1"}
          className={clsx([
            innerPStyle,
            !vertical && valueStyle,
            vertical && centered,
          ])}
          style={propValueStyle}
        >
          {value}
        </Typography>
      )}
    </Box>
  );
}
