import React, { memo } from "react";
import { Detail } from "../defines";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography, {TypographyProps} from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core"

const s = makeStyles((theme) => ({
  detailWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    "& a": {
      textDecoration: "underline"
    }
  },
  iconWrapper: {
    marginRight: theme.spacing(2),
    "& svg": {
      fontSize: "inherit",
      verticalAlign: "middle",
      position: "relative",
      bottom: "2px",
    },
  },
}));

function renderTypo({ color, content, typographyProps }) {
  return (
    <Typography
      component="p"
      variant="body2"
      style={{ color }}
      {...(typographyProps || {})}
    >
      {content}
    </Typography>
  );
}

/**
 * @param {{details: Detail[], iconSize?: number, typographyProps: TypographyProps, color?: string}} param0
 * @returns
 */
function DetailRenderer({ details, iconSize, typographyProps, color }) {
  const { detailWrapper, iconWrapper } = s();
  return (
    <Box>
      {details.map(({ content, icon, href }) => {
        const typo = renderTypo({ color, content, typographyProps });
        return <Box className={detailWrapper} key={content}>
          <Box className={iconWrapper} style={{ fontSize: iconSize, color }}>
            {icon}
          </Box>
          {href ? <Link href={href}>{typo}</Link> : typo}
        </Box>;
      })}
    </Box>
  );
}

export default memo(DetailRenderer);
