import Provider from "./Provider";
import Submit from "./Submit";
import Error from "./Error";
import { Email, Address, Phone, Fullname, Avatar } from "./Inputs";

const Inputs = { Email, Address, Phone, Fullname, Avatar };

/*
 * <CustomerEditor.Provider customer={customer} ...>
 *      <CustomerEditor.Inputs.Email Component={EmailComponent} />
 *      ...
 *      <CustomerEditor.Submit label="Submit button" />
 * </CustomerEditor.Provider>
 */
const CustomerEditor = { Provider, Submit, Inputs, Error };

export default CustomerEditor;
