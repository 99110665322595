import React, { Fragment, memo } from "react";
import Grid from "@material-ui/core/Grid";
import { EntityManager } from "@italwebcom/react-entity-manager";
import useStyles from "../styles";
import ProductTagPanelFilterer from "../../Filterers/ProductTagPanelFilterer";

function DefaultComposer({
  CollectionRenderer,
  CollectionRendererProps,
  SearchBar,
}) {
  const { filtererWrapper, searchBarWrapper } = useStyles();
  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} className={searchBarWrapper}>
          <EntityManager.Components.SearchBar Component={SearchBar} />
        </Grid>
        <Grid
          item
          xs={3}
          className={filtererWrapper}
        >
          <EntityManager.Components.Filterer
            Component={ProductTagPanelFilterer}
          />
        </Grid>
        <Grid item xs>
          <EntityManager.Components.CollectionRenderer
            Component={CollectionRenderer}
            ComponentProps={CollectionRendererProps}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default memo(DefaultComposer);
