import React, { memo } from "react";
import { TagSelectorGroupRendererProps } from "@italwebcom/anema-react-components/Tags";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Box from "@material-ui/core/Box";
import { RadioLegend } from "../_Auxiliary";
import useTagGroupFunctions from "../hooks/useTagGroupFunctions";

/**
 * @returns
 * @param {TagSelectorGroupRendererProps} param0
 */
function GroupRenderer({ elements, onAdd, onReplace, legend }) {
  const {
    availableTags,
    onActualChange: onReallyActualChange,
    selected,
  } = useTagGroupFunctions({
    elements,
    onAdd,
    onReplace,
  });
  return (
    <Box>
      <RadioLegend legend={legend} />
      <RadioGroup
        value={selected && selected.length ? selected[0].data.id : null}
        onChange={onReallyActualChange}
        name="tag-panel-selector-radio-group"
      >
        {availableTags.map((element) => (
          <FormControlLabel
            label={element.name}
            key={element.id}
            value={element.id}
            control={<Radio />}
            labelPlacement="end"
          />
        ))}
      </RadioGroup>
    </Box>
  );
}

const Out = memo(GroupRenderer);
export default Out;
