import React, { Fragment, useMemo, useState, useRef, useEffect } from "react";
import SimpleMenu from "../../Menus/SimpleMenu";
import { Button } from "@material-ui/core";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
/**
 * @template Entity
 * @typedef {{
 *      id: string,
 *      label?: string,
 *      onExecute: (elements: Entity[]) => void
 * }} BatchOperation
 */

/**
 * @template Entity
 * @param {{
 *      elements: Entity[],
 *      operations: BatchOperation<Entity>[]
 * }} param0 
 */
export default function BatchOperationsMenu({operations, elements}) {

    const [menu, setMenu] = useState(false);
    const btnRef = useRef(null);

    const menuOpts = useMemo(
        () => operations && operations.map(({id, label, onExecute}) => ({
            id,
            label,
            onSelect: () => onExecute(elements)
        })), 
        [operations, elements]
    );

    return <Fragment>
        {elements && Boolean(elements.length) && <Button 
            ref={r => btnRef.current = r}
            variant="outlined"
            color="primary"
            onClick={() => setMenu(true)}
            endIcon={<KeyboardArrowDown />}
        >
            Effettua operazione su {elements.length} elementi
        </Button>}
        <SimpleMenu 
            options={menuOpts} 
            onClose={() => setMenu(false)} 
            anchorEl={menu && btnRef.current} 
        />
    </Fragment>
}