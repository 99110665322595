import React, {
  memo,
  useCallback,
  useEffect,
  useState,
  CSSProperties,
} from "react";
import { makeStyles } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import MainContentPanel, {
  MainContentPanelHeaderProps,
} from "../../../../MainContentPanel";
import Renderer from "../Renderer";
import { Product } from "@italwebcom/anema-react-components/Products";
import { EditorProps } from "@italwebcom/react-entity-manager";
import useRuntimeContext from "../../../../../contexts/Runtime/useRuntimeContext";

const s = makeStyles((t) => ({
  closeIconButton: {
    color: "white",
    fontSize: t.spacing(3),
  },
}));

/**
 * @typedef {{contentStyle?: CSSProperties, headerProps: MainContentPanelHeaderProps, resetTimeout: number}} AdditionalProps
 *
 * @returns
 * @param {EditorProps<Product> & AdditionalProps} param0
 */
function DrawerRenderer({
  entity,
  onResetSelectedEntity,
  contentStyle,
  headerProps,
  resetTimeout,
}) {
  const [open, setOpen] = useState(false);
  const { stylings } = useRuntimeContext();

  const onClose = useCallback(() => {
    setOpen(false);
    setTimeout(onResetSelectedEntity, resetTimeout);
  }, [setOpen, onResetSelectedEntity, resetTimeout]);

  useEffect(() => {
    setOpen(Boolean(entity));
  }, [entity]);

  const closeTrigger = (
    <Button variant="text" color="secondary" onClick={onClose}>
      Chiudi
    </Button>
  );

  return (
    <Drawer
      anchor="bottom"
      open={open}
      keepMounted
      onClose={(e, r) => (r === "escapeKeyDown" ? onClose() : null)}
    >
      {entity && (
        <Box style={contentStyle}>
          <MainContentPanel
            title={entity.name}
            headerProps={{
              ...(headerProps || stylings.home.productDetailPanelHeaderProps),
              sideContent: closeTrigger,
            }}
          >
            <Renderer
              product={entity}
              descriptionTypographyProps={{ style: { textAlign: "center" } }}
            />
          </MainContentPanel>
        </Box>
      )}
    </Drawer>
  );
}

export default memo(DrawerRenderer);
