import { useMemo } from "react";
import { Cart } from "../../../../../__domain/model";
import { fieldNames } from "../../CartCustomerEditor/fields";

export function isCartEmptyReservation(c) {
  return c.type === "in_place" && !c.productsNum;
}

function isCartReadOnly(c) {
  return (
    c.status === "PAID" || c.status === "CONFIRMED" || c.status === "ABORTED"
  );
}

function isCartInPlace(cart) {
  return cart[fieldNames.type] === "in_place";
}

/**
 * @returns
 * @param {Cart} cart
 */
export function isCartPayable(cart) {
  if (isCartInPlace(cart)) {
    return false;
  }
  return (
    ((cart.status === "OPEN" ||
      cart.status === "ACTIVE" ||
      (cart.status === "CLOSED" && cart.cartPaymentType === "paypal")) &&
      cart.productsNum) ||
    isCartEmptyReservation(cart)
  );
}

function isCartCashPayment(cart) {
  return cart.cartPaymentType === "direct";
}

/**
 * @returns
 * @param {Cart} cart
 */
export function isCartEditable(cart) {
  return (
    cart && cart.status && (cart.status === "OPEN" || cart.status === "ACTIVE")
  );
}

function isCartPast(c) {
  return (c && c.status && c.status === "CONFIRMED") || c.status === "ABORTED";
}

function isCartStatus(c, s) {
  return c && c.status && c.status.toLowerCase() === s;
}

/**
 * @returns
 * @param {{cart: Cart}} cart
 */
export default function useCartDerivedAttributes(cart) {
  return useMemo(
    () =>
      !cart
        ? {}
        : {
            payable: isCartPayable(cart),
            editable: isCartEditable(cart),
            cashPayment: isCartCashPayment(cart),
            past: isCartPast(cart),
            readOnly: isCartReadOnly(cart),
            emptyReservation: isCartEmptyReservation(cart),
            paymentSelectable:
              isCartEditable(cart) && !isCartEmptyReservation(cart),
            awaitingPayment:
              isCartStatus(cart, "closed") && isCartPayable(cart),
            awatingConfirmation:
              isCartStatus(cart, "closed") || isCartStatus(cart, "paid"),
            confirmed: isCartStatus(cart, "confirmed"),
            refused: isCartStatus(cart, "aborted"),
            customerExpected: cart.type !== "delivery",
          },
    [cart]
  );
}
