/* istanbul ignore file */

import { CrudCollectionAction } from "@italwebcom/react-entity-manager";
import messages from "./messages";

/**
 * @type {CrudCollectionAction<any>[]}
 */
const defaultActions = [
    {
      id: "delete",
      onExecute: (e, callbacks) => {
        const { onDelete } = callbacks;
        onDelete(e[0]);
      },
      label: "Cancella",
      requireConfirmation: true,
      confirmationMessage: messages.confirmElementDeletion
    }
];

export default defaultActions;