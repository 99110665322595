import { Paper, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { useContext, useMemo } from "react";
import Context from "../context";

const useStyles = makeStyles((theme) => ({
  noShadow: {
    boxShadow: "none",
  },
}));

/**
 * @param {{padding?: string}} param0
 * @returns
 */
export default function Content({ children, padding, noShadow }) {
  const { boxShadow: boxShadowStyle } = useStyles();
  const { contentPadding: defaultPadding } = useContext(Context);
  const actualPadding = useMemo(
    () => padding || defaultPadding,
    [padding, defaultPadding]
  );
  return (
    <Paper
      component="main"
      style={{ padding: actualPadding }}
      className={clsx([noShadow && boxShadowStyle])}
    >
      {children}
    </Paper>
  );
}
