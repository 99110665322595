import React, { memo, useCallback, useEffect, useMemo, useRef } from "react";
import useProductWrapper from "../../../../../hooks/wrappers/useProductWrapper";
import { Filter } from "@italwebcom/custom-react-hooks";
import { EntityManager, CollectionRendererProps } from "@italwebcom/react-entity-manager";
import { Product, ProductIndices } from "../../../../../__domain/model";
import { useDefaultComponents } from "../../../../misc/DefaultComponentsProvider";
import useGenericFetch from "../../../../../hooks/generic/useGenericFetch";
import { useAlertFunctions } from "../../../../../components/misc/AlertProvider";
import Context from "../context";
import useCartProvider from "../../../Carts/CartProvider/hooks/useCartProvider";

function getActualFilters(filters, cart, addCart) {
  let out = [];
  if (filters) {
    out = [...filters];
  }
  if (addCart && cart) {
    out.push({ attribute: "cart", value: cart.id });
  }
  return out;
}

/**
 * @returns
 * @param {CollectionRendererProps<Product>} param0
 */
function CollectionRenderer({ elements, children, onSelect }) {
  return (
    <Context.Provider value={{ products: elements, elements, onSelect }}>
      {children}
    </Context.Provider>
  );
}

/**
 * @returns
 * @param {{
 *    filters: Filter<ProductIndices>[],
 *    lazyCountRecompute?: boolean,
 *    addCart?: boolean
 * }} param0
 */
function ProductProvider({
  filters,
  children,
  lazyCountRecompute,
  addCart,
  noAutoFetch,
  initialItemsPerPage,
}) {
  const { defaultCountGetter, fetchArgsProcessor } = useDefaultComponents();
  const { onAdd: onMessageAdd } = useAlertFunctions();
  const wrapper = useProductWrapper();
  const { cart } = useCartProvider();

  const onFetch = useCallback(
    (args) => {
      if (wrapper && (!addCart || cart)) {
        return wrapper.fetch(args).json();
      }
      return Promise.resolve({ data: [] });
    },
    [wrapper, addCart, cart]
  );

  const onActualError = useCallback(
    (err) => onMessageAdd({ type: "error", content: err.message || err }),
    [onMessageAdd]
  );

  const actualInitialFilters = useMemo(
    () => getActualFilters(filters, cart, addCart),
    [filters, addCart, cart]
  );

  const entityManagerRef = useRef();

  useEffect(() => {
    entityManagerRef &&
      cart &&
      addCart &&
      entityManagerRef.current.replaceFilter(
        { attribute: "cart" },
        { attribute: "cart", value: cart.id }
      );
  }, [entityManagerRef, addCart, cart]);

  return (
    <EntityManager.ReadOnly
      fetchArgsProcessor={fetchArgsProcessor}
      countGetter={defaultCountGetter}
      initialFilters={actualInitialFilters}
      initialItemsPerPage={initialItemsPerPage || 35}
      initialSorter={{ attribute: "name", direction: "asc" }}
      searchAttribute="name"
      onFetch={onFetch}
      onError={onActualError}
      manageEntitySelection
      lazyCountRecompute={lazyCountRecompute}
      ref={entityManagerRef}
      noAutoFetch={noAutoFetch}
    >
      <EntityManager.Components.CollectionRenderer
        Component={CollectionRenderer}
        ComponentProps={{ children }}
        renderOnError
      />
    </EntityManager.ReadOnly>
  );
}

export default memo(ProductProvider);
