import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  makeStyles,
  Typography,
  CircularProgress,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import clsx from "clsx";

const handlers = {
  defaultDragEventHandler: (evt) => evt.preventDefault(),
};

const states = {
  default: "default",
  loading: "loading",
  dragging: "dragging",
};

function isDragging(state) {
  return state === states.dragging;
}

const dStyle = {
  backgroundColor: "rgb(250, 250, 250)",
  "& .file-uploader-message": {
    fontWeight: "bold",
    fontSize: "1.75em",
  },
};
const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderColor: "rgb(200, 200, 200)",
    borderWidth: 3,
    borderStyle: "dashed",
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
    "& .file-uploader-message": {
      transitionProperty: "font-size",
      transitionDuration: "250ms",
    },
    "&:not(.dragging):not(:hover)": {
      "& .file-uploader-message": {
        fontSize: "1.25em",
      },
    },
    "&.dragging": dStyle,
    "&:hover": dStyle,
  },
  message: {
    color: "rgb(111, 111, 111)",
  },
  input: {
    display: "none",
  },
  centered: {
    textAlign: "center",
  },
}));

/**
 * @typedef {{
 *      message: string, event: any
 * }} FileError
 */

/**
 * @returns
 * @param {{
 *    message: string,
 *    onUpload: (data: string) => void,
 *    onSubmit: (data: string) => void,
 *    style?: CSSProperties,
 *    onError: (error: FileError) => void
 * }} param0
 */
export default function FileUploader({
  message,
  onUpload,
  onSubmit,
  onError,
  style,
}) {
  const [state, setState] = useState(states.default);
  const [file, setFile] = useState(null);
  const {
    wrapper,
    message: messageStyle,
    input: inputStyle,
    centered,
  } = useStyles();
  const fileReader = useRef(null);
  const inputRef = useRef(null);
  let content;
  useEffect(() => {
    fileReader.current = new FileReader();
  }, []);
  useEffect(() => {
    if (state === states.loading) {
      fileReader.current.onloadend = () => {
        setTimeout(() => {
          setState(states.default);
          //onUpload(fileReader.current.result);
        }, 500);
      };
      fileReader.current.onerror = (evt) => {
        setState(states.default);
        onError({ message: "upload_error", event: evt });
      };
      fileReader.current.readAsDataURL(file);
    }
  }, [state, file]);
  const onSetFile = useCallback(
    (v) => {
      onUpload(v);
      setFile(v);
    },
    [onUpload, setFile]
  );
  const onDrop = useCallback(
    (evt) => {
      evt.preventDefault();
      const daFile = evt.dataTransfer.files[0];
      if (daFile) {
        onSetFile(daFile);
        setState(states.loading);
      } else {
        setState(states.default);
        onError({ message: "missing_file", event: {} });
      }
    },
    [onUpload, onError, setState, onSetFile]
  );
  const onInputChange = useCallback(
    (evt) => {
      if (evt.target.files[0]) {
        onSetFile(evt.target.files[0]);
        setState(states.loading);
      }
    },
    [setState, onSetFile]
  );
  const onDragOver = useCallback(
    (evt) => {
      evt.preventDefault();
      setState(states.dragging);
    },
    [setState]
  );
  const onDelete = useCallback(
    (evt) => {
      evt.stopPropagation();
      onSetFile(null);
    },
    [onSetFile]
  );
  const onConfirm = useCallback(
    (evt) => {
      evt.stopPropagation();
      fileReader.current.result && onSubmit(fileReader.current.result);
      setFile(null);
    },
    [onSubmit, fileReader, setFile]
  );
  if (state === states.loading) {
    content = <CircularProgress />;
  } else {
    if (file) {
      content = (
        <Box>
          <Typography
            component="p"
            variant="body1"
            className={clsx([messageStyle, centered])}
            gutterBottom
          >
            File attuale {file.name}
          </Typography>
          <ButtonGroup fullWidth>
            <Button
              fullWidth
              type="button"
              onClick={onConfirm}
              variant="outlined"
              color="primary"
            >
              Conferma
            </Button>
            <Button
              fullWidth
              type="button"
              onClick={onDelete}
              variant="outlined"
              color="secondary"
            >
              Cancella
            </Button>
          </ButtonGroup>
        </Box>
      );
    } else {
      content = (
        <Typography
          component="p"
          variant="body1"
          className={clsx(["file-uploader-message", messageStyle])}
        >
          {message}
        </Typography>
      );
    }
  }
  return (
    <Box
      onDragEnd={handlers.defaultDragEventHandler}
      onDragOver={onDragOver}
      onDragLeave={() => setState(states.default)}
      onDrop={onDrop}
      className={clsx([isDragging(state) && "dragging", wrapper])}
      style={style}
      onClick={() => !file && inputRef.current && inputRef.current.click()}
      component="article"
    >
      <input
        type="file"
        id="file-uploader-file-input"
        ref={(r) => (inputRef.current = r)}
        className={inputStyle}
        onChange={onInputChange}
      />
      {content}
    </Box>
  );
}
