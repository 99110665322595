import React, { ComponentType, memo, useCallback, useContext, useMemo } from "react";
import MenuContext from "../context";
import useApplicable from "../hooks/useApplicable";

/**
 * @typedef {{open?: boolean, in?: boolean}} TransitionComponentProps
 * @typedef {ComponentType<TransitionComponentProps>} TransitionComponent
 * @param {{label: string, TransitionComponent: TransitionComponent}} param0
 * @returns
 */
function Trigger({ children, TransitionComponent, label }) {
  const applicable = useApplicable(label);
  return <TransitionComponent in={applicable} open={applicable}>
      {children}
  </TransitionComponent>;
}

export default memo(Trigger);