import React, { memo } from "react";
import useLoadingInit from "./hooks/useLoadingInit";
import FullPageLoader from "../../components/FullPageLoader";
import ErrorPrinter from "../../components/ErrorPrinter";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";

const s = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
  },
}));

/**
 * @param {{
 *    logoSrc: string,
 *    progressColor: string
 * }} param0
 * @returns
 */
function LoadingPage(props) {
  const { done, error } = useLoadingInit();
  const { title: titleStyle } = s();
  const { children } = props;
  return (
    <Box>
      {!done && (
        <FullPageLoader {...props} active={!error}>
          {error && (
            <Container maxWidth="sm">
              <ErrorPrinter
                title="Si è verificato un errore."
                subtitle="Non è stato possibile caricare la webapp. Ti invitiamo a ricaricare la pagina e, se il problema persiste, a contattarci direttamente."
                titleTypographyProps={{
                  component: "h5",
                  variant: "h5",
                  className: titleStyle,
                }}
                color={props.progressColor}
              />
            </Container>
          )}
        </FullPageLoader>
      )}
      {done && children}
    </Box>
  );
}

export default memo(LoadingPage);
