import React, { memo, ReactChildren, CSSProperties } from "react";
import {
  Box,
  Fade,
  CircularProgress,
  CircularProgressProps,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    zIndex: 3,
  },
  absolute: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
  },
  loadingWrapper: {
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loadingBackground: {
    zIndex: 1,
    backgroundColor: "white",
    opacity: "0.5",
  },
}));

/**
 * @param {{
 *      in: boolean,
 *      CircularProgressProps: CircularProgressProps,
 *      children?: ReactChildren,
 *      progressWrapperStyle?: CSSProperties
 * }} param0
 */
function BlockingLoader({
  in: loading,
  CircularProgressProps,
  progressWrapperStyle,
  children,
}) {
  const { loadingWrapper, wrapper, absolute, loadingBackground } = useStyles();
  return (
    <Fade in={loading}>
      <Box className={clsx([absolute, wrapper])}>
        <Box className={clsx([absolute, loadingBackground])} />
        <Box className={clsx([absolute, loadingWrapper])}>
          <Box style={progressWrapperStyle}>
            <CircularProgress {...CircularProgressProps} />
            {children}
          </Box>
        </Box>
      </Box>
    </Fade>
  );
}

export default memo(BlockingLoader);
