import {
  Avatar,
  Box,
  Grid,
  Typography,
  makeStyles,
  Divider,
  Container,
  Paper,
} from "@material-ui/core";
import clsx from "clsx";
import React, { Fragment, ReactNode } from "react";
import HulkButton from "../../Inputs/HulkButton";
import HulkContent from "../HulkContent";

const useStyles = makeStyles((theme) => ({
  leftBarHeaderWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  avatar: {
    height: theme.spacing(15),
    width: theme.spacing(15),
  },
  bold: {
    fontWeight: "bold",
  },
  grey: {
    color: "rgb(113, 114, 117)",
  },
  black: {
    color: "#000",
  },
  centeredText: {
    textAlign: "center",
  },
}));

function MainBox({ children, padding, component, className }) {
  return (
    <Box
      paddingTop={padding || "1rem"}
      paddingBottom={padding || "1rem"}
      component={component || "article"}
      className={className}
    >
      {children}
    </Box>
  );
}

function LeftBarSection({ title, elements }) {
  const { grey, black } = useStyles();
  return (
    <Box component="article">
      <Box component="header">
        <Typography component="h5" variant="h6">
          {title}
        </Typography>
      </Box>
      {elements.map((e) => (
        <Box component="section" marginTop="1em">
          <Typography component="h6" variant="subtitle2" className={grey}>
            {e.name}
          </Typography>
          <Typography component="p" variant="subtitle2" className={black}>
            {e.value}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}

function LeftBarStats({ stats }) {
  const { grey, black, centeredText } = useStyles();
  return (
    <MainBox>
      <Box display="flex" justifyContent="space-between">
        {stats.map((s) => (
          <Box component="section">
            <Typography
              component="header"
              variant="subtitle2"
              className={clsx([grey, centeredText])}
            >
              {s.name}
            </Typography>
            <Typography
              component="p"
              variant="body2"
              className={clsx([black, centeredText])}
            >
              {s.value}
            </Typography>
          </Box>
        ))}
      </Box>
    </MainBox>
  );
}

function LeftBarRenderer({
  avatar,
  name,
  role,
  data,
  stats,
  footer,
  onEditData,
}) {
  const { avatar: avatarClass, leftBarHeaderWrapper, bold, grey } = useStyles();
  return (
    <Box component="article">
      <MainBox className={leftBarHeaderWrapper} component="header">
        <Box marginBottom="1em" component="aside">
          <Avatar src={avatar} variant="circular" className={avatarClass} />
        </Box>
        <Typography component="h5" variant="h5" gutterBottom className={bold}>
          {name}
        </Typography>
        <Typography component="p" variant="subtitle1" className={grey}>
          {role}
        </Typography>
      </MainBox>
      {data.map((d) => (
        <Fragment>
          <Divider />
          <MainBox>
            <LeftBarSection title={d.title} elements={d.elements} />
          </MainBox>
        </Fragment>
      ))}
      {Boolean(stats && stats.length) && (
        <Fragment>
          <Divider />
          <LeftBarStats stats={stats} />
        </Fragment>
      )}
      <MainBox>
        <HulkButton
          label="Modifica dati"
          onClick={onEditData}
          variant="light"
          fullWidth
        />
      </MainBox>
      {footer && (
        <Fragment>
          <Divider />
          <MainBox>{footer}</MainBox>
        </Fragment>
      )}
    </Box>
  );
}

function ContentRenderer({ content }) {
  /*const { bold } = useStyles();
  return content.map((c) => (
    <Box marginBottom="2rem" component="article">
      <Paper>
        <Box padding="1rem">
          <Typography
            component="header"
            variant="h6"
            gutterBottom
            className={bold}
          >
            {c.title}
          </Typography>
          <Divider />
          <MainBox>{c.content}</MainBox>
        </Box>
      </Paper>
    </Box>
  ));*/
  return (
    <HulkContent.Base contentPadding="1rem" sectionMargin="2rem">
      {content.map(({ content, title }, index) => (
        <HulkContent.Section title={title} marginTop={(index === 0) ? 0 : undefined}>
          <HulkContent.Content>{content}</HulkContent.Content>
        </HulkContent.Section>
      ))}
    </HulkContent.Base>
  );
}

/**
 * @typedef {{
 *      name: string,
 *      value: string
 * }} ProfileDataElement
 *
 * @typedef {{
 *      title: string,
 *      elements: ProfileDataElement[]
 * }} ProfileDataSection
 *
 * @typedef {{
 *      title: string,
 *      content: ReactNode
 * }} ProfileContent
 *
 * @typedef {{
 *      avatar: string,
 *      name: string,
 *      role: string,
 *      profileData: ProfileDataSection[]
 *      profileStats: ProfileDataElement[],
 *      profileFooter: ReactNode,
 *      mainContent: ProfileContent[],
 *      sideContent: ProfileContent[],
 *      onEditData: Function,
 *      stickySideContent?: boolean
 * }} HulkUserProfileProps
 * @param {HulkUserProfileProps} param0
 */
export default function HulkUserProfile({
  avatar,
  name,
  role,
  profileData,
  profileStats,
  profileFooter,
  mainContent,
  sideContent,
  onEditData,
  stickySideContent,
}) {
  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <Box position="sticky" top="20px">
            <LeftBarRenderer
              avatar={avatar}
              name={name}
              role={role}
              data={profileData}
              stats={profileStats}
              footer={profileFooter}
              onEditData={onEditData}
            />
          </Box>
        </Grid>
        <Grid item xs>
          <ContentRenderer content={mainContent} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box top="20px" position={stickySideContent ? "sticky" : "static"}>
            <ContentRenderer content={sideContent} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
