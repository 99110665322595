import { Carts } from "@italwebcom/anema-react-components";
import { useCallback } from "react";
import { Cart } from "@italwebcom/anema-react-components/Carts";

const { useCartDerivedAttributes } = Carts.hooks;

/**
 * @param {Cart} cart
 * @param {(cart: Cart) => void} onRedirectToEdit
 * @param {(cart: Cart) => void} onRedirectToOverview
 * @returns
 */
export default function useCartSelect(
  cart,
  onRedirectToEdit,
  onRedirectToOverview
) {
  const { editable } = useCartDerivedAttributes(cart);
  return useCallback(
    () => {
      if (editable) {
        onRedirectToEdit(cart);
      } else {
        onRedirectToOverview(cart);
      }
    },
    [cart, editable, onRedirectToEdit, onRedirectToOverview]
  );
}
