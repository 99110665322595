import { useEntityManagerValues } from "@italwebcom/react-entity-manager";
import { useMemo } from "react";
import useTagGroup from "../hooks/useTagGroup";

/**
 * @returns
 * @param {string[]} targetNames
 */
export default function useFilteredTags(targetNames) {
  const { tagGroupMatcher } = useTagGroup();
  const { elements: tags } = useEntityManagerValues();
  return useMemo(() => {
    if (tags && tags.length) {
      return tagGroupMatcher(tags, targetNames);
    }
    return [];
  }, [tags, tagGroupMatcher, targetNames]);
}
