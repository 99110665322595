import {Stat} from "../../model";
import products from "./products";
import customers from "./customers";

/**
 * @type {Stat[]}
 */
const data = [];

for(let i=0; i<10; i++) {
    data.push(({
        label: products[i].name,
        value: Math.random() * 2000,
        name: "top_selling_products",
        date: "all"
    }));
    data.push(({
        label: customers[i].fullName,
        value: 10000*Math.random(),
        name: "top_customers",
        date: "all"
    }))
    for(let j=1; j<=5; j++) {
        data.push(({
            label: products[i].name,
            value: Math.random() * 200,
            name: "top_selling_products",
            date: `2022-0${j}`
        }));
        data.push(({
            label: customers[i].fullName,
            value: 1000*Math.random(),
            name: "top_customers",
            date: `2022-0${j}`
        }))
    }
}
for(let j=1; j<=5; j++) {
    data.push({
        label: `2022-0${j}`,
        date: `all`,
        name: "month_sales",
        value: Math.random() * 500
    })
}
export default data;