import {
  OpenIDConnectTokenManagerConfiguration,
  OpenIDConnectAuthorizationServerFacade,
  DiscoveryDocument,
  TokenResponse,
  TokenResponseData,
} from "./defines";

function isErrored(data) {
  return data && (data.error || (data.errorType === "aborted"));
}

/**
 * Authorization code grant with PKCE verification, using an instance of OpenIDConnectAuthorizationServerFacade.
 *
 * @return {Promise<{code: string, codeVerifier: string}>}
 *
 * @param {OpenIDConnectTokenManagerConfiguration} configuration
 * @param {DiscoveryDocument} discoveryDocument
 * @param {OpenIDConnectAuthorizationServerFacade} authFacade
 */
async function requestAuthCode(configuration, discoveryDocument, authFacade, data) {
  const { clientId, clientSecret, redirectUri, onCodePrompt } = configuration;
  console.log(data);
  const req = await authFacade.loadAsync(
    {
      clientId,
      clientSecret,
      redirectUri,
      responseType: "code",
      extraParams: data
    },
    discoveryDocument
  );
  try {
    /* istanbul ignore next */
    if (!discoveryDocument || !discoveryDocument.authorizationEndpoint) {
      throw { errorType: "invalid_discovery_document" };
    }
    onCodePrompt && onCodePrompt();
    const resp = await req.promptAsync(discoveryDocument);
    const { type, error, errorCode, params } = resp;
    console.log(resp);
    if (type === "success" && params.code) {
      return {
        code: params.code,
        codeVerifier: params.codeVerifier,
      };
    } else {
      if (type === "error") {
        throw { errorType: type, errorCode, error };
      } else {
        throw { errorType: type };
      }
    }
  } catch (err) {
    throw err;
  }
}

/**
 * Fetches DiscoveryDocument for given authorization server.
 *
 * @return {Promise<DiscoveryDocument>}
 * @param {OpenIDConnectTokenManagerConfiguration} configuration
 * @param {OpenIDConnectAuthorizationServerFacade} authFacade
 * @param {object} cache
 */
async function fetchDiscovery(configuration, cache, authFacade) {
  if (!cache.discoveryDocument) {
    const dDoc = await authFacade.fetchDiscoveryAsync(configuration.issuerUri);
    cache.discoveryDocument = dDoc;
  }
  return Promise.resolve(cache.discoveryDocument);
}

/**
 * Requests access token using a valid authorization code, with PKCE (provides codeVerifier from previous call to auth. endpoint).
 *
 * @return {Promise<TokenResponse>}
 * @param {OpenIDConnectTokenManagerConfiguration} configuration
 * @param {string} code
 * @param {string} codeVerifier
 * @param {DiscoveryDocument} discovery
 * @param {OpenIDConnectAuthorizationServerFacade} authFacade
 */
async function requestToken(
  configuration,
  code,
  codeVerifier,
  discovery,
  authFacade
) {
  const { clientId, clientSecret, redirectUri } = configuration;
  return authFacade.exchangeCodeAsync(
    {
      clientId,
      clientSecret,
      redirectUri,
      code,
      extraParams: {
        code_verifier: codeVerifier,
      },
    },
    discovery
  );
}

/**
 * Token manager for authorization server using OpenIDConnect auth protocol.
 * Uses PKCE verification by default.
 *
 * Requires an instance of OpenIDConnectAuthorizationServerFacade to actually execute the logic
 * needed to:
 *
 * - fetch the discovery document from the auth. server
 * - fetch an authorization code via corresponding grant (and returning the codeVerifier used)
 * - fetch the access token using the code (and providing the PKCE codeVerifier)
 *
 * The manager caches the token and, if necessary, automatically requests a new token using
 * the refresh token grant.
 *
 */
export class OpenIDConnectTokenManager {
  /**
   * @type {OpenIDConnectTokenManagerConfiguration}
   */
  _configuration;

  /**
   * @type {{
   *      tokenResponse: TokenResponse,
   *      discoveryDocument: DiscoveryDocument
   * }}
   */
  _cache;

  /**
   * @type {OpenIDConnectAuthorizationServerFacade}
   */
  _authFacade;

  /**
   * @param {OpenIDConnectTokenManagerConfiguration} configuration
   * @param {OpenIDConnectAuthorizationServerFacade} authFacade
   */
  constructor(configuration, authFacade) {
    this._configuration = configuration;
    this._authFacade = authFacade;
    this._cache = {};
  }

  /**
   * @returns
   * @param {(config: OpenIDConnectTokenManagerConfiguration) => OpenIDConnectTokenManagerConfiguration} mapper
   */
  configuration(mapper) {
    if (this._configuration) {
      this._configuration = mapper(this._configuration);
    }
    return this;
  }

  async loadDiscovery() {
    const discovery = await fetchDiscovery(
      this._configuration,
      this._cache,
      this._authFacade
    );
    return discovery;
  }

  /**
   * @return {Promise<TokenResponse>}
   */
  async fetchToken(data) {
    console.log("fetching discovery document");
    const discovery = await fetchDiscovery(
      this._configuration,
      this._cache,
      this._authFacade
    );
    let tokenResponse;
    console.log(data);
    if (data && data.grantType === "password") {
      tokenResponse = await this._authFacade.fetchTokenPasswordGrantAsync(
        data,
        this._configuration,
        discovery
      );
    } else {
      console.log("requesting authcode");
      const { code, codeVerifier } = await requestAuthCode(
        this._configuration,
        discovery,
        this._authFacade,
        data
      );
      console.log("fetching token");
      tokenResponse = await requestToken(
        this._configuration,
        code,
        codeVerifier,
        discovery,
        this._authFacade
      );
    }
    this._cache.tokenResponse = tokenResponse;
    return tokenResponse;
  }

  isCachedTokenFresh() {
    let { tokenResponse } = this._cache;
    if (tokenResponse) {
      return this._authFacade.isTokenFresh(tokenResponse, 0);
    }
    return false;
  }

  /**
   * @return {Promise<TokenResponse>}
   */
  async getCachedToken() {
    let { tokenResponse } = this._cache;
    if (tokenResponse) {
      const fresh = this._authFacade.isTokenFresh(tokenResponse, 0);
      if (!fresh) {
        const { clientId, clientSecret } = this._configuration;
        const discovery = await fetchDiscovery(
          this._configuration,
          this._cache,
          this._authFacade
        );
        try {
          tokenResponse = await tokenResponse.refreshAsync(
            { clientId, clientSecret },
            discovery
          );
          this._cache.tokenResponse = tokenResponse;
        } catch (e) {
          tokenResponse = await this.fetchToken();
        }
      }
    }
    return tokenResponse;
  }

  /**
   * @returns
   * @param {TokenResponse | TokenResponseData} data
   */
  setCachedToken(data) {
    if (data && !data.refreshAsync) {
      data = this._authFacade.makeToken(data, this._configuration);
    }
    this._cache.tokenResponse = data;
    return this;
  }

  /**
   * @return {Promise<TokenResponse>}
   */
  async getToken(data) {
    let tokenResponse = await this.getCachedToken();
    if (!tokenResponse) {
      tokenResponse = await this.fetchToken(data);
    }
    return tokenResponse;
  }

  /**
   * @return {Promise<{
   *      token: TokenResponse,
   *      userInfo: Record<string, any>
   * }>}
   */
  async getTokenAndUserInfo(data, secondAttempt) {
    let tokenResponse = await this.getToken(data);
    const discovery = await fetchDiscovery(
      this._configuration,
      this._cache,
      this._authFacade
    );
    let userInfo = await this._authFacade.fetchUserInfoAsync(
      tokenResponse,
      discovery
    );
    if (isErrored(userInfo)) {
      this.reset();
      if (!secondAttempt) {
        return await this.getTokenAndUserInfo(data, true);
      } else {
        throw { errorType: "userinfo_error" };
      }
    }
    return { tokenResponse, userInfo, token: tokenResponse };
  }

  /**
   * @return {Promise<Record<string, any>>}
   * @param {TokenResponse} tokenResponse
   */
  async fetchUserInfo(tokenResponse) {
    const discovery = await fetchDiscovery(
      this._configuration,
      this._cache,
      this._authFacade
    );
    return this._authFacade.fetchUserInfoAsync(tokenResponse, discovery);
  }

  /**
   * @returns
   */
  reset() {
    this._cache = {};
    return this;
  }
}

/**
 * @return {OpenIDConnectTokenManager}
 * @param {OpenIDConnectTokenManagerConfiguration} configuration
 * @param {OpenIDConnectAuthorizationServerFacade} authFacade
 */
export default function build(configuration, authFacade) {
  return new OpenIDConnectTokenManager(configuration, authFacade);
}
