import { createContext } from "react";
import { OpeningTime } from "../../../../__domain/model";

/**
 * @typedef {{hour: number, minutes: number}} ProcessedOpeningTime
 * @typedef {{openingTimes: ProcessedOpeningTime[], onDateChange: (date: string) => void, loading: boolean}} OpeningTimeContextArgs
 */

/**
 * @type {OpeningTimeContextArgs}
 */
const contextArgs = {};

export default createContext(contextArgs);