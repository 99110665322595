import { ProvidersWrapper, webStorage } from "@italwebcom/anema-shared-web-components";
import {
  HulkAlertContainer,
  useAlertContainerFunctions,
} from "@italwebcom/misc-ui-components";
import { useMemo } from "react";
import { memo } from "react";
import { runtimeContextArgs, pageStylingContextArgs } from "../../data";
import {
  FetcherRequestBuilder,
  OpenIDConnectTokenManager,
} from "@italwebcom/augmented-fetch";
import { OperationWrappers } from "@italwebcom/anema-react-components/Wrappers";
import { Storage } from "@italwebcom/anema-react-components/Misc";
import { ClosingDays, OpeningTimes, Customers } from "@italwebcom/anema-react-components";
import { MuiThemeProvider } from "@material-ui/core";
import muiTheme from "../../data/muiTheme";

const { Provider: ClosingDaysProvider } = ClosingDays.Components;
const { Provider: OpeningTimesProvider } = OpeningTimes.Components;
const { AuthProvider } = Customers.Components;

function AlertWrapper({ children }) {
  return (
    <HulkAlertContainer.Provider
      initialMessages={[]}
      keyGetter={(e) => e.content}
    >
      <HulkAlertContainer.Container
        position={{ vertical: "top", horizontal: "right" }}
        dismissalTime={2000}
        width={350}
      />

      {children}
    </HulkAlertContainer.Provider>
  );
}

/**
 * @returns
 * @param {{
 *      fetcher: FetcherRequestBuilder,
 *      manager: OpenIDConnectTokenManager,
 *      wrappers: OperationWrappers,
 *      storage: Storage<any, any> | "cookie" | "local"
 * }} param0
 */
const InnerWrapper = memo(
  ({
    children,
    fetcher,
    manager,
    storage,
    wrappers,
    initialCredentials,
    noCustomerAutoFetch,
  }) => {
    const actualStorage = useMemo(() => {
      let actualStorage;
      if (typeof storage === "string") {
        actualStorage = webStorage({ type: storage });
      } else {
        actualStorage = storage;
      }
      return actualStorage;
    }, [storage]);
    const { onAdd } = useAlertContainerFunctions();
    return (
      <MuiThemeProvider theme={muiTheme}>
        <ProvidersWrapper
          fetcher={fetcher}
          manager={manager}
          storage={actualStorage}
          wrappers={wrappers}
          runtimeContextArgs={runtimeContextArgs}
          pageStylingContextArgs={pageStylingContextArgs}
          onAlertAdd={onAdd}
        >
          <ClosingDaysProvider initialMonth="2022-10">
            <OpeningTimesProvider initialWeekDay={1} processOpeningTimes>
              <AuthProvider noAutoFetch={noCustomerAutoFetch}>
                {children}
              </AuthProvider>
            </OpeningTimesProvider>
          </ClosingDaysProvider>
        </ProvidersWrapper>
      </MuiThemeProvider>
    );
  }
);

function Wrapper(props) {
  return (
    <AlertWrapper>
      <InnerWrapper {...props}>{props.children}</InnerWrapper>
    </AlertWrapper>
  );
}

export default memo(Wrapper);
