import { withDefaultHateoas } from "../auxiliary/getDefaultHateoasData";
import links from "../../links";
import { ValidityDiscriminatorAttributes, ValidityDiscriminator } from "../../../model";

/**
 * @param {ValidityDiscriminatorAttributes} data 
 * @return {ValidityDiscriminator} 
 */
export default function validityDiscriminatorHateoasWrapper(data) {
    let vd = withDefaultHateoas("validity-discriminator", data);
    vd._links.matchers = {
        href: links.validityDiscriminators.matchers(data.id),
        templated: true
    };
    vd._templates.addMatcher = {
        method: "POST",
        properties: [],
        target: links.validityDiscriminators.addMatcher(data.id),
    };
    return vd;
}