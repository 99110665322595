import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  head: {
    textTransform: "uppercase",
    color: "rgb(150, 150, 150)",
    fontWeight: "bold",
  },
  bodyCell: {
    color: "rgb(123, 128, 154)",
    fontWeight: "bold",
  },
  arrow: {
    color: "rgb(150, 150, 150)",
  },
  selectedArrow: {
    color: "rgb(25, 25, 25)",
  },
  arrowsWrapper: {
    cursor: "pointer",
  },
}));
