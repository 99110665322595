import { InMemoryCollectionFetcher } from "@italwebcom/in-memory-collection";
import { makeRequestBuilder } from "../auxiliary/misc";
import { DataCollections } from "../collections";

import categories from "../data/categories";
import images from "../data/images";
import wrappers from "../hateoas";
import products from "../data/products";

const {
  productCategory: productCategoriesHateoasWrapper,
  productImage: productImagesHateoasWrapper,
} = wrappers;

function r(s, d) {
  return {
    status: s,
    data: d,
  };
}

/**
 * @param {InMemoryCollectionFetcher} fetcher
 * @param {RegExp} matcher
 * @param {string} attribute
 * @param {string} targetCollection
 */
function makeOneToManyFetchHandler(
  fetcher,
  matcher,
  attribute,
  targetCollection
) {
  fetcher.withHandler({
    method: "GET",
    urlProcessor: "search",
    matcher,
    processor: ({ urlPars, url }, collection) => {
      const id = parseInt(url.split("/")[2]);
      return r(
        200,
        collection.fetch({
          ...urlPars,
          filters: [{ attribute, value: id }],
        })
      );
    },
    targetCollection,
  });
}

/**
 * @param {InMemoryCollectionFetcher} fetcher
 * @param {RegExp} matcher
 * @param {string} attribute
 * @param {string} targetCollection
 */
function makeOneToManySaveHandler(
  fetcher,
  matcher,
  attribute,
  targetCollection
) {
  fetcher.withHandler({
    method: "POST",
    urlProcessor: "element",
    matcher,
    processor: ({ urlPars, body }, collection) => {
      let stuff = JSON.parse(body);
      stuff[attribute] = urlPars.id;
      if (stuff.error) {
        return r(400, { message: "Errore nel salvataggio dei dati." });
      } else {
        return r(201, collection.save(stuff));
      }
    },
    targetCollection,
  });
}

/**
 * @param {{attribute: string, value: string}[]} errorTriggers
 */
function registerHandlers(fetcher, matchers, targetCollection, errorTriggers) {
  errorTriggers = errorTriggers || [];
  fetcher
    .withHandler({
      method: "GET",
      urlProcessor: "search",
      processor: "fetch",
      matcher: matchers.fetch,
      targetCollection,
    })
    .withHandler({
      method: "GET",
      urlProcessor: "element",
      processor: "fetch",
      matcher: matchers.element,
      targetCollection,
    })
    .withHandler({
      method: "PUT",
      urlProcessor: "element",
      processor: ({ body, urlPars }, collection) => {
        let b = JSON.parse(body);
        if (b.error) {
          return r(400, { message: "error" });
        } else {
          return r(
            200,
            collection.replace((e) => e.id === urlPars.id, JSON.parse(body))
          );
        }
      },
      matcher: matchers.element,
      targetCollection,
    })
    .withHandler({
      method: "DELETE",
      urlProcessor: "element",
      processor: "delete",
      matcher: matchers.element,
      targetCollection,
    })
    .withHandler({
      method: "POST",
      urlProcessor: "element",
      processor: ({ body }, collection) => {
        let b = JSON.parse(body);
        let err = "";
        b.id = collection._data.length;
        if (b.error) {
          return r(400, { message: "error" });
        }
        for (let errorTrigger of errorTriggers) {
          const { attribute, value } = errorTrigger;
          if (b[attribute] === value) {
            err = `existing_${attribute}`;
          }
        }
        if (err) {
          return { status: 400, data: { message: err } };
        } else {
          return { status: 201, data: collection.save(b) };
        }
      },
      matcher: matchers.base,
      targetCollection,
    });
}

const matchers = {
  images: {
    fetch: /^(\/images)[?]*[a-zA-Z\\&\\=0-9\\_\\-]*$/,
    element: /^(\/image\/)[0-9]+$/,
    base: /^(\/images)$/,
  },
  tags: {
    fetch: /^(\/tags)[?]*[a-zA-Z\\&\\=0-9\\_\\-]*$/,
    element: /^(\/tag\/)[0-9]+$/,
    base: /^(\/tags)$/,
  },
  categories: {
    fetch: /^(\/categories)[?]*[a-zA-Z\\&\\=0-9\\_\\-]*$/,
    element: /^(\/category\/)[0-9]+$/,
    base: /^(\/categories)$/,
    children: /^(\/category\/)[0-9]+(\/children)[?]*[a-zA-Z\\&\\=0-9\\_\\-]*$/,
    topCategories: /^(\/categories\/top)[?]*[a-zA-Z\\&\\=0-9\\_\\-]*$/,
  },
  conversations: {
    fetch: /^(\/conversations)[?]*[a-zA-Z\\&\\=0-9\\_\\-]*$/,
    element: /^(\/conversation\/)[0-9]+$/,
    base: /^(\/conversations)$/,
    messages:
      /^(\/conversation\/)[0-9]+(\/messages)[?]*[a-zA-Z\\&\\=0-9\\_\\-]*$/,
    postMessage: /^(\/conversation\/)[0-9]+(\/message)$/,
  },
  features: {
    fetch: /^(\/product-features)[?]*[a-zA-Z\\&\\=0-9\\_\\-]*$/,
    element: /^(\/product-feature\/)[0-9]+$/,
    base: /^(\/product-features)$/,
    addValue: /^(\/product-feature\/)[0-9]+(\/value)$/,
    value: /^(\/product-feature\/)[0-9]+(\/value\/)[0-9]+$/,
    values:
      /^(\/product-feature\/)[0-9]+(\/values)[?]*[a-zA-Z\\&\\=0-9\\_\\-]*$/,
  },
  costLists: {
    fetch: /^(\/cost-lists)[?]*[a-zA-Z\\&\\=0-9\\_\\-]*$/,
    element: /^(\/cost-list\/)[0-9]+$/,
    base: /^(\/cost-lists)$/,
    discriminators: /^(\/cost-list\/)[0-9]+(\/discriminators)$/,
    addDiscriminator: /^(\/cost-list\/)[0-9]+(\/discriminator)$/,
  },
  deliveryZones: {
    fetch: /^(\/delivery-zones)[?]*[a-zA-Z\\&\\=0-9\\_\\-]*$/,
    element: /^(\/delivery-zone\/)[0-9]+$/,
    base: /^(\/delivery-zones)$/,
    costs: /^(\/delivery-zone\/)[0-9]+(\/costs)[?]*[a-zA-Z\\&\\=0-9\\_\\-]*$/,
    addCost: /^(\/delivery-zone\/)[0-9]+(\/cost)$/,
  },
  deliveryCosts: {
    fetch: /^(\/delivery-costs)[?]*[a-zA-Z\\&\\=0-9\\_\\-]*$/,
    element: /^(\/delivery-cost\/)[0-9]+$/,
    base: /^(\/delivery-costs)$/,
    discriminators: /^(\/delivery-cost\/)[0-9]+(\/discriminators)$/,
    addDiscriminator: /^(\/delivery-cost\/)[0-9]+(\/discriminator)$/,
  },
  discriminators: {
    element: /^(\/validity-discriminator\/)[0-9]+$/,
    matchers:
      /^(\/validity-discriminator\/)[0-9]+(\/matchers)[?]*[a-zA-Z\\&\\=0-9\\_\\-]*$/,
    addMatcher: /^(\/validity-discriminator\/)[0-9]+(\/matcher)$/,
    matcher: /^(\/validity-discriminator\/)[0-9]+(\/matcher\/)[0-9]+$/,
  },
  products: {
    fetch: /^(\/products)[?]*[a-zA-Z\\&\\=0-9\\_\\-\\,]*$/,
    element: /^(\/product\/)[0-9]+$/,
    base: /^(\/products)$/,
    categories: /^(\/product\/)[0-9]+(\/category\/)[a-zA-Z\\&\\=0-9\\_\\-]*$/,
    images: /^(\/product\/)[0-9]+(\/image\/)[a-zA-Z\\&\\=0-9\\_\\-]*$/,
    costs: /^(\/product\/)[0-9]+(\/cost\/)[a-zA-Z\\&\\=0-9\\_\\-]*$/,
  },
  productCategories: {
    fetch: /^(\/product\/)[0-9]+(\/category)[?]*[a-zA-Z\\&\\=0-9\\_\\-]*$/,
    delete: /^(\/product\/)[0-9]+(\/category\/)[0-9]+$/,
    add: /^(\/product\/)[0-9]+(\/category)$/,
  },
  productImages: {
    fetch: /^(\/product\/)[0-9]+(\/image)[?]*[a-zA-Z\\&\\=0-9\\_\\-]*$/,
    delete: /^(\/product\/)[0-9]+(\/image\/)[0-9]+$/,
    add: /^(\/product\/)[0-9]+(\/image)$/,
  },
  productCosts: {
    fetch: /^(\/product\/)[0-9]+(\/cost)[?]*[a-zA-Z\\&\\=0-9\\_\\-]*$/,
    delete: /^(\/product\/)[0-9]+(\/cost\/)[0-9]+$/,
  },
  customers: {
    fetch: /^(\/customers)[?]*[a-zA-Z\\&\\=0-9\\_\\-\\ ]*$/,
    activeCart: /^(\/customer\/)[0-9]+(\/activeCart)$/,
    element: /^(\/customer\/)[0-9]+$/,
    base: /^(\/customers)$/,
    notifications:
      /^(\/customer\/)[0-9]+(\/notifications)[?]*[a-zA-Z\\&\\=0-9\\_\\-]*$/,
    carts: /^(\/customer\/)[0-9]+(\/carts)[?]*[a-zA-Z\\&\\=0-9\\_\\-]*$/,
    addNotification: /^(\/customer\/)[0-9]+(\/notification)$/,
    setNotificationToken: /^(\/customer\/)[0-9]+(\/token)$/
  },
  carts: {
    fetch: /^(\/carts)[?]*[a-zA-Z\\&\\=0-9\\_\\-\\ ]*$/,
    activeCart: /^(\/activeCart)[?]*[a-zA-Z\\&\\=0-9\\_\\-\\ ]*$/,
    deliveryZoneCost: /^(\/cart\/)[0-9]+(\/delivery_zone_cost)[?]*[a-zA-Z0-9\\&\\=\\_\\-]*$/,
    element: /^(\/cart\/)[0-9]+$/,
    base: /^(\/carts)$/,
    products: /^(\/cart\/)[0-9]+(\/products)[?]*[a-zA-Z\\&\\=0-9\\_\\-]*$/,
    cartProduct: /^(\/cart\/)[0-9]+(\/product\/)[0-9]+$/,
    addProduct: /^(\/cart\/)[0-9]+(\/product)$/,
    acquire: /^(\/cart\/acquire)$/,
  },
  newsItems: {
    fetch: /^(\/news-items)[?]*[a-zA-Z\\&\\=0-9\\_\\-\\ ]*$/,
    element: /^(\/news-item\/)[0-9]+$/,
    base: /^(\/news-items)$/,
  },
  user: {
    create: /^(\/user)$/,
    data: /^(\/user)$/,
    delete: /^(\/user)$/
  },
  stats: {
    fetch: /^(\/stats)[?]*[a-zA-Z&=0-9_-]*$/,
    base: /^(\/stats)$/,
  },
  search: {
    global: /^(\/search)[?]*[a-zA-Z&=0-9_-]+$/,
  },
  closingDay: {
    by_month: /^(\/closing-days)[?]*[a-zA-Z\\&\\=0-9\\_\\-]+$/,
  },
  openingTime: {
    by_weekday: /^(\/opening-times)[?]*[a-zA-Z\\&\\=0-9\\_\\-]+$/,
  },
};

/**
 * @returns
 * @param {DataCollections} collections
 */
export default function makeFetcher(collections) {
  const fetcher = new InMemoryCollectionFetcher(
    collections,
    makeRequestBuilder(150),
    {
      defaultMethod: "GET",
    }
  );

  registerHandlers(fetcher, matchers.tags, "tags");
  registerHandlers(fetcher, matchers.images, "images", [
    { attribute: "name", value: "error" },
  ]);
  registerHandlers(fetcher, matchers.categories, "tags");
  registerHandlers(fetcher, matchers.conversations, "conversations");
  registerHandlers(fetcher, matchers.features, "features");
  registerHandlers(fetcher, matchers.costLists, "costLists");
  registerHandlers(fetcher, matchers.deliveryZones, "deliveryZones");
  registerHandlers(fetcher, matchers.deliveryCosts, "deliveryCosts");
  registerHandlers(fetcher, matchers.products, "products");
  registerHandlers(fetcher, matchers.customers, "customers");
  registerHandlers(fetcher, matchers.carts, "carts");
  registerHandlers(fetcher, matchers.newsItems, "newsItems");

  fetcher.withHandler({
    method: "PATCH",
    urlProcessor: "element",
    processor: ({ body, urlPars }, collection) => {
      let b = JSON.parse(body);
      let c = collection.fetch({filters: [{attribute: "id", value: urlPars.id}]});
      return r(
        200,
        collection.replace((e) => e.id === urlPars.id, {...c[0], ...b})
      );
    },
    matcher: matchers.carts.element,
    targetCollection: "carts",
  })

  fetcher.withHandler({
    method: "PUT",
    urlProcessor: "element",
    matcher: matchers.customers.setNotificationToken,
    targetCollection: "customers",
    processor: ({body, urlPars, init}, collection) => {
      let b = JSON.parse(body);
      let {data} = collection.fetch({filters: [{attribute: "id", value: urlPars.id}]});
      if(init && init.headers && init.headers.Authorization) {
        if(data) {
          data[0].token = b.token;
          return r(201, data);
        } else {
          return r(404, {error: "not_found"});
        }
      }
      return r(401, {error: "unauthorized"});
    }
  })

  makeOneToManyFetchHandler(
    fetcher,
    matchers.customers.notifications,
    "customer",
    "notifications"
  );
  makeOneToManyFetchHandler(
    fetcher,
    matchers.customers.carts,
    "customer",
    "carts"
  );
  makeOneToManyFetchHandler(
    fetcher,
    matchers.carts.products,
    "cart",
    "cartProducts"
  );
  makeOneToManyFetchHandler(
    fetcher,
    matchers.conversations.messages,
    "conversation",
    "messages"
  );
  makeOneToManyFetchHandler(
    fetcher,
    matchers.features.values,
    "productFeature",
    "featureValues"
  );
  makeOneToManyFetchHandler(
    fetcher,
    matchers.categories.children,
    "parent",
    "tags"
  );
  makeOneToManyFetchHandler(
    fetcher,
    matchers.costLists.discriminators,
    "relatedElement",
    "validityDiscriminators"
  );
  makeOneToManyFetchHandler(
    fetcher,
    matchers.deliveryCosts.discriminators,
    "relatedElement",
    "validityDiscriminators"
  );
  makeOneToManyFetchHandler(
    fetcher,
    matchers.discriminators.matchers,
    "validityDiscriminator",
    "attributeMatchers"
  );
  makeOneToManyFetchHandler(
    fetcher,
    matchers.deliveryZones.costs,
    "deliveryZone",
    "deliveryCosts"
  );

  makeOneToManySaveHandler(
    fetcher,
    matchers.customers.addNotification,
    "customer",
    "notifications"
  );
  makeOneToManySaveHandler(
    fetcher,
    matchers.costLists.addDiscriminator,
    "relatedElement",
    "validityDiscriminators"
  );
  makeOneToManySaveHandler(
    fetcher,
    matchers.deliveryCosts.addDiscriminator,
    "relatedElement",
    "validityDiscriminators"
  );
  makeOneToManySaveHandler(
    fetcher,
    matchers.conversations.postMessage,
    "conversation",
    "messages"
  );
  makeOneToManySaveHandler(
    fetcher,
    matchers.features.addValue,
    "productFeature",
    "featureValues"
  );
  makeOneToManySaveHandler(
    fetcher,
    matchers.discriminators.addMatcher,
    "validityDiscriminator",
    "attributeMatchers"
  );
  makeOneToManySaveHandler(
    fetcher,
    matchers.deliveryZones.addCost,
    "deliveryZone",
    "deliveryCosts"
  );

  fetcher.withHandler({
    method: "POST",
    urlProcessor: "element",
    matcher: matchers.carts.addProduct,
    processor: ({ url, body }, collection) => {
      let cp = JSON.parse(body);
      let sUrl = url.split("/");
      let out = { ...cp, cart: sUrl[2], product: products[cp.product] };
      return cp.error
        ? r(400, { message: "Errore nel salvataggio." })
        : r(201, collection.save(out));
    },
    targetCollection: "cartProducts",
  });

  fetcher.withHandler({
    method: "PUT",
    urlProcessor: "element",
    matcher: matchers.carts.cartProduct,
    processor: ({ url, body }, collection) => {
      let cp = JSON.parse(body);
      let sUrl = url.split("/");
      let pId = sUrl[4];
      let cId = sUrl[2];
      let out = { ...cp, cart: cId, product: products[pId] };
      if (cp.error) {
        return r(400, { message: "Errore nel salvataggio dei dati." });
      } else {
        return r(
          200,
          collection.replace(
            (e) => e.cart === parseInt(cId) && e.product.id === parseInt(pId),
            out
          )
        );
      }
    },
    targetCollection: "cartProducts",
  });

  fetcher.withHandler({
    method: "DELETE",
    urlProcessor: "element",
    matcher: matchers.carts.cartProduct,
    processor: ({ url }, collection) => {
      let sUrl = url.split("/");
      let pId = sUrl[4];
      let cId = sUrl[2];
      return r(
        200,
        collection.remove(
          (e) => e.cart === parseInt(cId) && e.product.id === parseInt(pId)
        )
      );
    },
    targetCollection: "cartProducts",
  });

  fetcher.withHandler({
    method: "DELETE",
    urlProcessor: "element",
    matcher: matchers.deliveryCosts.element,
    processor: "delete",
    targetCollection: "deliveryCosts",
  });

  fetcher.withHandler({
    method: "PUT",
    urlProcessor: "element",
    matcher: matchers.deliveryCosts.element,
    processor: "update",
    targetCollection: "deliveryCosts",
  });

  fetcher.withHandler({
    method: "DELETE",
    urlProcessor: "element",
    matcher: matchers.discriminators.element,
    processor: "delete",
    targetCollection: "validityDiscriminators",
  });

  fetcher.withHandler({
    method: "PUT",
    urlProcessor: "element",
    matcher: matchers.discriminators.element,
    processor: "update",
    targetCollection: "validityDiscriminators",
  });

  fetcher.withHandler({
    method: "GET",
    urlProcessor: "search",
    matcher: matchers.categories.topCategories,
    processor: ({ urlPars }, collection) => {
      let filters = [{ attribute: "type", value: "top_category" }];
      if (urlPars.filters && urlPars.filters.length) {
        filters.push(urlPars.filters[0]);
      }
      return r(
        200,
        collection.fetch({
          ...urlPars,
          filters,
        })
      );
    },
    targetCollection: "tags",
  });

  fetcher.withHandler({
    method: "GET",
    urlProcessor: "base",
    matcher: matchers.carts.deliveryZoneCost,
    processor: ({ urlPars }, collection) => {
      return r(
        200,
        {cost: 5}
      );
    },
    targetCollection: "carts",
  });

  fetcher.withHandler({
    method: "GET",
    urlProcessor: "base",
    matcher: matchers.customers.activeCart,
    processor: ({ urlPars }, collection) => {
      return r(
        200,
        collection.fetch({
          start: 0,
          end: 1,
        })
      );
    },
    targetCollection: "carts",
  });

  fetcher.withHandler({
    method: "DELETE",
    urlProcessor: "element",
    matcher: matchers.features.value,
    processor: ({ urlPars, url }, collection) => {
      let valueId = parseInt(url.split("/")[4]);
      let id = parseInt(urlPars.id);
      return r(
        200,
        collection.remove((e) => e.order === valueId && e.productFeature === id)
      );
    },
    targetCollection: "featureValues",
  });

  fetcher.withHandler({
    method: "DELETE",
    urlProcessor: "element",
    matcher: matchers.discriminators.matcher,
    processor: ({ urlPars, url }, collection) => {
      let valueId = parseInt(url.split("/")[4]);
      let id = parseInt(urlPars.id);
      return r(
        200,
        collection.remove(
          (e) => e.order === valueId && e.validityDiscriminator === id
        )
      );
    },
    targetCollection: "attributeMatchers",
  });

  fetcher.withHandler({
    method: "PUT",
    urlProcessor: "element",
    matcher: matchers.features.value,
    processor: ({ urlPars, url, body }, collection) => {
      let valueId = parseInt(url.split("/")[4]);
      let id = parseInt(urlPars.id);
      let data = JSON.parse(body);
      return r(
        200,
        collection.replace(
          (e) => e.order === valueId && e.productFeature === id,
          data
        )
      );
    },
    targetCollection: "featureValues",
  });

  fetcher.withHandler({
    method: "PUT",
    urlProcessor: "element",
    matcher: matchers.discriminators.matcher,
    processor: ({ urlPars, url, body }, collection) => {
      let valueId = parseInt(url.split("/")[4]);
      let id = parseInt(urlPars.id);
      let data = JSON.parse(body);
      return r(
        200,
        collection.replace(
          (e) => e.order === valueId && e.validityDiscriminator === id,
          data
        )
      );
    },
    targetCollection: "attributeMatchers",
  });

  /* product related entities */

  const getRelatedProdSearchProcessor =
    (v) =>
    ({ url, urlPars }, collection) => {
      const splitUrl = url.split("/");
      let product = parseInt(splitUrl[2]);
      let filters = [{ attribute: v, value: product }];
      if (urlPars.filters && urlPars.filters.length) {
        filters = [...filters, urlPars.filters];
      }
      return r(
        200,
        collection.fetch({
          ...urlPars,
          filters,
        })
      );
    };

  const relatedProdSearchProcessor = getRelatedProdSearchProcessor("product");
  const getRelatedProdDeleteProcessor =
    (a, upd) =>
    ({ url, body }, collection) => {
      const splitUrl = url.split("/");
      let product = parseInt(splitUrl[2]);
      let filterVal = parseInt(splitUrl[4]);
      const matcher = (e) => {
        let v = e[a];
        v = typeof v.id !== "undefined" ? v.id : v;
        return (
          (e.productId === product || e.product === product) && v === filterVal
        );
      };
      let out;
      if (upd) {
        let data = JSON.parse(body);
        out = collection.replace(matcher, data);
      } else {
        out = collection.remove(matcher);
      }
      return r(200, out);
    };

  /* product categories */

  fetcher.withHandler({
    method: "POST",
    urlProcessor: "element",
    matcher: matchers.productCategories.add,
    processor: ({ url, body }, collection) => {
      const splitUrl = url.split("/");
      let product = parseInt(splitUrl[2]);
      let category = JSON.parse(body).category;
      let e = productCategoriesHateoasWrapper({
        category,
        product,
        ...categories[category],
      });
      collection.save(e);
      return r(201, e);
    },
    targetCollection: "productCategories",
  });

  fetcher.withHandler({
    method: "GET",
    urlProcessor: "search",
    matcher: matchers.productCategories.fetch,
    processor: relatedProdSearchProcessor,
    targetCollection: "productCategories",
  });

  fetcher.withHandler({
    method: "DELETE",
    urlProcessor: "element",
    matcher: matchers.productCategories.delete,
    processor: getRelatedProdDeleteProcessor("category"),
    targetCollection: "productCategories",
  });

  /* product costs */

  fetcher.withHandler({
    method: "GET",
    urlProcessor: "search",
    matcher: matchers.productCosts.fetch,
    processor: getRelatedProdSearchProcessor("productId"),
    targetCollection: "productCosts",
  });

  fetcher.withHandler({
    method: "DELETE",
    urlProcessor: "search",
    matcher: matchers.productCosts.delete,
    processor: getRelatedProdDeleteProcessor("costList"),
    targetCollection: "productCosts",
  });

  fetcher.withHandler({
    method: "PUT",
    urlProcessor: "search",
    matcher: matchers.productCosts.delete,
    processor: getRelatedProdDeleteProcessor("costList", true),
    targetCollection: "productCosts",
  });

  /* product images */

  fetcher.withHandler({
    method: "POST",
    urlProcessor: "element",
    matcher: matchers.productImages.add,
    processor: ({ url, body }, collection) => {
      const splitUrl = url.split("/");
      let product = parseInt(splitUrl[2]);
      let image = JSON.parse(body).image;
      let e = productImagesHateoasWrapper({
        image,
        product,
        ...images[image],
      });
      collection.save(e);
      return r(201, e);
    },
    targetCollection: "productImages",
  });

  fetcher.withHandler({
    method: "GET",
    urlProcessor: "search",
    matcher: matchers.productImages.fetch,
    processor: relatedProdSearchProcessor,
    targetCollection: "productImages",
  });

  fetcher.withHandler({
    method: "DELETE",
    urlProcessor: "element",
    matcher: matchers.productImages.delete,
    processor: getRelatedProdDeleteProcessor("image"),
    targetCollection: "productImages",
  });

  /* */
  fetcher.withHandler({
    method: "POST",
    urlProcessor: "base",
    matcher: matchers.carts.acquire,
    processor: ({ init }, collection) => {
      if (init.headers && init.headers["x-cart-code"]) {
        let code = init.headers["x-cart-code"];
        let { data } = collection.fetch({
          filters: [{ attribute: "code", value: code }],
        });
        if (data.length !== 0) {
          return r(200, data[0]);
        }
      }
      return r(401, { error: "no_cart_code" });
    },
    targetCollection: "carts",
  });

  fetcher.withHandler({
    method: "POST",
    urlProcessor: "base",
    matcher: matchers.carts.acquire,
    processor: ({ init }, collection) => {
      if (init.headers && init.headers["x-cart-code"]) {
        let code = init.headers["x-cart-code"];
        let { data } = collection.fetch({
          filters: [{ attribute: "code", value: code }],
        });
        if (data.length !== 0) {
          return r(200, data[0]);
        }
      }
      return r(401, { error: "no_cart_code" });
    },
    targetCollection: "carts",
  });

  fetcher.withHandler({
    method: "PUT",
    urlProcessor: "base",
    matcher: matchers.user.create,
    processor: ({ init }) => {
      let b = JSON.parse(init.body);
      if (b.error) {
        return r(400, { message: "Errore." });
      }
      if (init.headers && init.headers["Authorization"]) {
        return r(201, { message: "ok" });
      }
      return r(401, { error: "unauthorized" });
    },
    targetCollection: "customers",
  });

  fetcher.withHandler({
    method: "DELETE",
    urlProcessor: "base",
    matcher: matchers.user.delete,
    processor: ({init}) => {
      if(init.headers && init.headers["Authorization"]) {
        return r(200, { message: "Utente cancellato." });
      }
      return r(401, { error: "unauthorized" });
    },
    targetCollection: "customers"
  })

  /* the shitty hacks */

  fetcher.withHandler({
    method: "GET",
    matcher: matchers.stats.fetch,
    urlProcessor: "search",
    processor: "fetch",
    targetCollection: "stats",
  });

  fetcher.withHandler({
    method: "GET",
    matcher: matchers.search.global,
    urlProcessor: "search",
    processor: "fetch",
    targetCollection: "globalSearchResults",
  });

  fetcher.withHandler({
    method: "POST",
    urlProcessor: "base",
    matcher: /^(\/temp)$/,
    processor: () => r(204, { "x-cart-id": 0, "x-cart-code": 0 }),
    targetCollection: "carts",
  });

  fetcher.withHandler({
    method: "GET",
    matcher: matchers.closingDay.by_month,
    urlProcessor: "search",
    processor: "fetch",
    targetCollection: "closingDays",
  });

  fetcher.withHandler({
    method: "GET",
    matcher: matchers.openingTime.by_weekday,
    urlProcessor: "search",
    processor: "fetch",
    targetCollection: "openingTimes",
  });

  return fetcher.fetcher();
}
