import { Observable } from "@italwebcom/observer-utils";
import { useState } from "react";
import useObservers from "../use-observers/use-observers-hook";

const defaultConfig = { onEvent: "on", offEvent: "off", id: "" };

/**
 * @template {string} Events
 * @param {Observable<Events>} pool
 * @param {boolean} defaultLoading
 * @param {{onEvent: string, offEvent: string}} config
 */
export function useLoader(obs, defaultLoading, config) {
  /* istanbul ignore next */
  config = config || defaultConfig;
  const [loading, setLoading] = useState(defaultLoading);
  const { onEvent, offEvent } = config;
  const onSetLoading = () => setLoading(true);
  const onRemoveLoading = () => setLoading(false);
  useObservers(obs, [
    {
      event: onEvent,
      handler: onSetLoading,
    },
    {
      event: offEvent,
      handler: onRemoveLoading,
    },
  ]);
  return loading;
}

/**
 * @param {{onEvent: string, offEvent: string}} config} config
 */
export default function makeHook(config) {
  return (p, dl) => useLoader(p, dl, config);
}
