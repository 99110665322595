import React, { ComponentType, memo, useContext } from "react";
import GenericInput from "../GenericInput";
import { DeliveryZone, cartType } from "../../../../../../__domain/model";
import { fieldNames } from "../../fields";
import context from "../../context";

/**
 * @typedef {{
 *      loading: boolean,
 *      value: number,
 *      onChange: (zone: number) => void,
 *      deliveryZones: DeliveryZone[]
 * }} DeliveryZoneInputComponentProps
 */

/**
 * @returns
 * @param {{InputComponent: ComponentType<DeliveryZoneInputComponentProps>}} param0
 */
function DeliveryZoneInput({ InputComponent }) {
  const { deliveryZonesLoading, deliveryZones } = useContext(context);
  return (
    <GenericInput
      field={fieldNames.deliveryZone}
      InputComponent={InputComponent}
      InputProps={{ deliveryZones, loading: deliveryZonesLoading }}
      shouldRender={v => v.type === cartType.delivery }
    />
  );
}

export default memo(DeliveryZoneInput);