/* executes fetch request */
function onFetch(info, init) {
  if (!info) {
    throw Error("missing_info");
  }
  return window
    .fetch(info, init)
    .then((r) => {
      if (r.status === 204) {
        return Promise.resolve({
          status: r.status,
          statusText: r.statusText,
          data: [],
        });
      } else {
        return r
          .json()
          .then((d) => ({
            status: r.status,
            statusText: r.statusText,
            data: d,
          }));
      }
    })
    .then(({ status, statusText, data }) => {
      if (data._embedded) {
        let out = [];
        const names = Object.getOwnPropertyNames(data._embedded);
        for (let n of names) {
          out = out.concat(data._embedded[n]);
        }
        data.data = out;
        delete data._embedded;
      } else {
        if (data.page) {
          data.data = [];
        }
        if (data.id) {
          data = { data: [data] };
        }
        if (data["x-cart-code"] || data.confirmationUrl || data.cost) {
          data = { data };
        }
      }
      return {
        json: () => Promise.resolve(data),
        status,
        statusText,
      };
    });
}

/* glob. handler for fetch errors */
function onError(status, response) {
  throw { status, message: "Si è verificato un errore." };
}

/* glob. handler for redirect response */
function onRedirect(to) {
  console.log("redirect");
  console.log(to);
}

/* pkce generator used by oauth2 client */
function pkceGenerator() {
  return {};
}

/* used to get current date to check token freshness (by auth facade) */
function dateGetter() {
  return new Date();
}

export { onError, onFetch, onRedirect, pkceGenerator, dateGetter };
