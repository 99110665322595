import ReadOnly from "./ReadOnly";
import Crud from "./Crud";
import LoaderWrapper from "./Wrappers/LoaderWrapper";
import PaginatorWrapper from "./Wrappers/PaginatorWrapper";
import SearchBarWrapper from "./Wrappers/SearchBarWrapper";
import FiltererWrapper from "./Wrappers/FiltererWrapper";
import SorterWrapper from "./Wrappers/SorterWrapper";
import EditorWrapper from "./Wrappers/EditorWrapper";
import ErrorWrapper from "./Wrappers/ErrorWrapper";
import AdderWrapper from "./Wrappers/AdderWrapper";
import CollectionRendererWrapper from "./Wrappers/CollectionRendererWrapper";
import {EntityManager as EntityManagerType} from "./defines";
import useEntityManagerValues from "./hooks/useEntityManagerValues";

/**
 * @type {EntityManagerType}
 */
const EntityManager = {
    ReadOnly,
    Crud,
    Components: {
        Loader: LoaderWrapper,
        Paginator: PaginatorWrapper,
        SearchBar: SearchBarWrapper,
        Filterer: FiltererWrapper,
        Sorter: SorterWrapper,
        Editor: EditorWrapper,
        Adder: AdderWrapper,
        CollectionRenderer: CollectionRendererWrapper,
        Error: ErrorWrapper
    }
}

export {EntityManager, useEntityManagerValues};