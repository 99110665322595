import React, { useCallback } from "react";
import { memo } from "react";
import { DetailedList as CustomerCartDetailedList } from "../../components/_entities/carts/CartCustomerList";
import CustomerActiveCartRenderer from "../../components/_entities/carts/CustomerActiveCartRenderer";
import UserAreaGate from "../__auxiliary__/UserAreaGate";
import { Customers } from "@italwebcom/anema-react-components";
import { useRuntimeContext } from "../../contexts";
import CustomerCredentialsSetter from "../../components/CustomerCredentialsSetter";
import Box from "@material-ui/core/Box";

const { useCustomer } = Customers.hooks;

/**
 *
 * @param {{title: string}} param0
 * @returns
 */
function UserAreaReservations({ title, noHeader }) {
  const { customer } = useCustomer();
  const { viewNames, Navigation, smallScreen } = useRuntimeContext();
  const onActualRedirect = useCallback(
    (c) => {
      Navigation.navigate(viewNames.user.carts, { id: c.id, cart: c.id });
    },
    [Navigation, viewNames]
  );
  return (
    <UserAreaGate
      Setter={CustomerCredentialsSetter}
      title={title || "Le tue prenotazioni"}
      viewName="carts"
      PageWrapperProps={{
        noContentGutters: smallScreen,
        noTopPadding: smallScreen,
        noHeader
      }}
    >
      <CustomerCartDetailedList
        customer={customer}
        onRedirectToEdit={onActualRedirect}
        onRedirectToOverview={onActualRedirect}
      />
      <Box position="fixed" bottom={smallScreen ? "5rem" : "1rem"} right="2rem">
        <CustomerActiveCartRenderer
          onSelect={onActualRedirect}
          fabSize="large"
          noLabel
        />
      </Box>
    </UserAreaGate>
  );
}

export default memo(UserAreaReservations);
