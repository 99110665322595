import React, { useMemo } from "react";
import {CollectionAction} from "../../defines";
import {SimpleMenuOption} from "../../../Menus/SimpleMenu";

/**
 * @template Entity
 * @param {{actions: CollectionAction<Entity>[], element: Entity, setActionRef: Function}} param0 
 * @return {SimpleMenuOption[]}
 */
export default function useProcessedActions(actions, element, setActionRef) {
    return useMemo(() => {
        return actions && actions.map(({id, label, onExecute, isApplicable}) => {
            return ({
                id,
                label,
                onSelect: () => {
                    onExecute([element]);
                    setActionRef(null);
                },
                disabled: isApplicable && !isApplicable(element)
            })
        })
    }, [actions, element, setActionRef]);
}