import React, { memo, useCallback } from "react";
import TextField from "@material-ui/core/TextField";

/**
 * @param {{value: string, onChange: (value: string) => void}} param0
 * @returns
 */
function MessageInput({ value, onChange }) {
  return (
    <TextField
      defaultValue={value}
      multiline
      minRows={3}
      maxRows={6}
      onChange={onChange}
      placeholder="Inserisci il tuo messaggio..."
      //autoFocus
      fullWidth
      variant="outlined"
      type="text"
    />
  );
}

export default memo(MessageInput);
