import { UserCredentials } from "../defines";

/**
 * @param {RequestInit} stuff 
 * @return {HeaderManager}
 */
export default function manage(stuff) {
    return new HeaderManager(stuff);
}

class HeaderManager {

    /**
     * @type {RequestInit}
     */
    _requestInit;

    /**
     * @param {RequestInit} requestInit 
     */
    constructor(requestInit) {
        this._requestInit = {...requestInit};
    }

    /**
     * @param {string} name 
     * @param {string} value 
     * @return {HeaderManager}
     */
    set(name, value) {
        if(!this._requestInit.headers) {
            this._requestInit.headers = {};
        }
        this._requestInit.headers[name] = value;
        return this;
    }

    /**
     * @param {string} token 
     * @return {HeaderManager}
     */
    bearer(token) {
        if(token) {
            return this.set("Authorization", `Bearer ${token}`);
        } else {
            return this;
        }
    }

    /**
     * @param {UserCredentials} credentials 
     * @return {HeaderManager}
     */
    basic(credentials) {
        const {username, password} = credentials;
        let enc = Buffer.from(`${username}:${password}`).toString("base64");
        return this.set("Authorization", `Basic ${enc}`);
    }

    /**
     * @return {RequestInit}
     */
    get() {
        return this._requestInit;
    }
}