import { useTheme } from "@material-ui/core";
import { templates } from "@italwebcom/anema-shared-web-components";
import { memo } from "react";
import { Outlet } from "react-router";

const { loading: LoadingPageTemplate } = templates;

function LoadingPage() {
  const theme = useTheme();
  return (
    <LoadingPageTemplate
      backgroundColor="white"
      progressColor={theme.palette.grey["400"]}
      logoSrc="webapp/img/anema.png"
    >
      <Outlet />
    </LoadingPageTemplate>
  );
}

export default memo(LoadingPage);
