import React, { memo } from "react";
import BigScreenRenderer from "./BigScreen";
import { SelectedElementHandler } from "../_Auxiliary/SelectedElementHandler";

function Renderer(props) {
  const { smallScreen } = props;
  if (smallScreen) {
    return null;
  } else {
    return (
      <SelectedElementHandler variant="popover">
        <BigScreenRenderer {...props} />
      </SelectedElementHandler>
    );
  }
}

export default memo(Renderer);
