import React, {
  memo,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  useEntityManagerValues,
  EntityManager,
  CollectionRendererProps,
} from "@italwebcom/react-entity-manager";
import attributes from "../../attributes";
import CartProductContext from "../../context";
import { useDefaultComponents } from "../../../../../misc/DefaultComponentsProvider";

const initialSorter = { attribute: "productName", direction: "asc" };

const CollectionRenderer = memo(
  ({ elements, children, onDelete, onSave, cart, onSelect }) => {
    const { loading } = useEntityManagerValues();

    /*useEffect(() => {
      console.log(
        `CartProductProvider.CollectionRenderer: got ${
          elements ? elements.length : 0
        } elements.`
      );
    }, [elements]);*/

    return (
      <CartProductContext.Provider
        value={{
          cartProducts: elements,
          onDelete,
          onSave,
          cart,
          loading,
          onSelect,
        }}
      >
        {children}
      </CartProductContext.Provider>
    );
  }
);

/**
 * @returns
 */
function CartProductCrud(
  { cart, children, onFetch, onDelete, onSave, onSuccess, onError },
  ref
) {
  const { fetchArgsProcessor, defaultCountGetter: countGetter } =
    useDefaultComponents();

  const childRef = useRef();

  useImperativeHandle(
    ref,
    () => ({
      refresh: () => {
        if (cart && childRef.current) {
          console.log(
            `CartProductCrud.<useEffect callback>: refreshing products for cart ${cart.id}.`
          );
          childRef.current.refresh();
        } else {
          if (!cart) {
            console.log(
              `CartProductCrud.<useEffect callback>: refreshing products called, but cart is missing!.`
            );
          } else {
            console.log(
              `CartProductCrud.<useEffect callback>: refreshing products called, but childRef.current is missing!.`
            );
          }
        }
      },
    }),
    [cart, childRef]
  );

  return (
    <EntityManager.Crud
      fetchArgsProcessor={fetchArgsProcessor}
      countGetter={countGetter}
      attributes={attributes}
      onFetch={onFetch}
      onDelete={onDelete}
      onSave={onSave}
      initialSorter={initialSorter}
      initialItemsPerPage={9999}
      initialFilters={[]}
      manageEntitySelection
      onSuccess={onSuccess}
      onError={onError}
      ref={childRef}
      noAutoRefetch
      testID="CartProductProvider"
      lazyCountRecompute
    >
      <EntityManager.Components.CollectionRenderer
        Component={CollectionRenderer}
        ComponentProps={{ children, cart }}
        renderOnError
      />
    </EntityManager.Crud>
  );
}

export default memo(React.forwardRef(CartProductCrud));
