import React, { memo, ReactNode } from "react";
import Context from "../context";
import { NavigationFunction } from "../../RuntimeProvider/defines";

/**
 * @param {{
 *      navigate: NavigationFunction, 
 *      children: ReactNode[]
 * }} param0
 */
function Provider({ navigate, children }) {
  return <Context.Provider value={{ navigate }}>{children}</Context.Provider>;
}

export default memo(Provider);
