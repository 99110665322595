import { useCallback } from "react";
import useTokenDecorator from "../useTokenDecorator";
import { FetchRequest } from "@italwebcom/augmented-fetch";

/**
 * @param {string} name 
 * @returns 
 */
function useRequestAuthenticationSetter(name) {
  name = name || "auth_setter";
  const onSetToken = useTokenDecorator();
  return useCallback(
    /**
     * @returns
     * @param {FetchRequest} r
     */
    (r) =>
      r.withDecorator({
        name,
        process: onSetToken,
      }),
    [onSetToken]
  );
}

export default useRequestAuthenticationSetter;
