import React, { memo } from "react";
import useStyles from "../styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import SearchBar from "../../SearchBar";
import { EntityManager } from "@italwebcom/react-entity-manager";
import ProductTabsFilterer from "../../Filterers/ProductTabsFilterer";

function SmallScreenComposer({ CollectionRenderer, CollectionRendererProps }) {
  const { panelWrapper } = useStyles();
  return (
    <Box>
      <Paper className={panelWrapper} style={{top: "0px"}}>
        <EntityManager.Components.Filterer Component={ProductTabsFilterer} />
      </Paper>
      <Box paddingTop="1rem" paddingBottom="1rem" display="flex">
        <Box flexGrow={1}>
          <EntityManager.Components.SearchBar Component={SearchBar} />
        </Box>
      </Box>
      <EntityManager.Components.CollectionRenderer
        Component={CollectionRenderer}
        ComponentProps={CollectionRendererProps}
      />
    </Box>
  );
}

export default memo(SmallScreenComposer);
