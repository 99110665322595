import React, { Fragment, memo, useCallback } from "react";
import { makeStyles } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import Box from "@material-ui/core/Box";
import NativeSelect from "@material-ui/core/NativeSelect";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

const s = makeStyles((theme) => ({
  hiddenOnSmall: {
    [theme.breakpoints.down(768)]: {
      display: "none",
    },
  },
  hiddenOnLarge: {
    [theme.breakpoints.up(768)]: {
      display: "none",
    },
  },
  nativeSelect: {
    border: "1px solid rgb(222, 222, 222)",
    padding: "0.75rem"
  }
}));

function renderOption({ value, label }) {
  return (
    <option key={value} value={value}>
      {label}
    </option>
  );
}

function renderMenuItem({ value, label }) {
  return (
    <MenuItem key={value} value={value} label={label}>
      {label}
    </MenuItem>
  );
}

/**
 * @typedef {number | string} Value
 * @typedef {{label: string, value: Value}} Option
 * @param {{defaultValue?: Value, onChange: (value: string) => void, options: Option[]}} param0
 * @returns
 */
function CustomDropdownMenu({ defaultValue, onChange, options, label, value }) {
  const { hiddenOnSmall, hiddenOnLarge, nativeSelect } = s();
  const onActualChange = useCallback(
    (evt) => {
      let v = evt.target ? evt.target.value : evt.value;
      let o = options.filter((o) => o.value.toString() === v.toString());
      onChange(o[0].value);
    },
    [onChange]
  );
  return (
    <Fragment>
      <Box className={hiddenOnSmall}>
        <Select
          variant="outlined"
          value={value}
          defaultValue={defaultValue}
          onChange={onActualChange}
          label={label}
          fullWidth
        >
          {options.map(renderMenuItem)}
        </Select>
      </Box>
      <Box className={hiddenOnLarge}>
        <NativeSelect
          value={value}
          defaultValue={defaultValue}
          onChange={onActualChange}
          fullWidth
          variant="outlined"
          disableUnderline
          inputProps={{
            className: nativeSelect
          }}
        >
          {options.map(renderOption)}
        </NativeSelect>
      </Box>
    </Fragment>
  );
}

export default memo(CustomDropdownMenu);
