import React, { memo, ReactNode } from "react";
import { useCredentials } from "@italwebcom/react-auth-provider";
import Provider from "../Provider";

/**
 * Provides customer for currently authenticated user, if any. Assumes user
 * credentials -always- include the username.
 *
 * !REQUIRES!: an Auth.Provider (react-auth-provider) ancestor.
 *
 * @param {{children: ReactNode[]}} param0
 * @returns
 */
function AuthProvider({ children, noAutoFetch }) {
  const creds = useCredentials();
  const u = creds && creds.username;
  return (
    <Provider
      username={u}
      asCustomer
      noAutoFetch={noAutoFetch}
    >
      {children}
    </Provider>
  );
}

export default memo(AuthProvider);
