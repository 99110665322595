import { createContext } from "react";
import { ClosingDay } from "../../../../__domain/model";

/**
 * @typedef {{closingDays: ClosingDay[], onMonthChange: (m: string) => void, loading: boolean}} ClosingDaysContextArgs
 */

/**
 * @type {ClosingDaysContextArgs}
 */
const contextArgs = {closingDays: [], onMonthChange: () => null};

export default createContext(contextArgs);