import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    fullHeight: {
        minHeight: "calc(100vh - 82px - 1rem)"
    },
    appBarWrapper: {
        backgroundColor: "white"
    },
    mainContainer: {
        padding: theme.spacing(3),
        borderRadius: theme.spacing(2),
        display: "flex",
        flexDirection: "column"
    },
    mainContainerFullHeight: {
        height: "100%"
    },
    mainContainerBg: {
        backgroundColor: theme.palette.primary.light
    },
    listMenuWrapper: {
        "&.shown": {
            width: "250px"
        },
        "&:not(.shown)": {
            width: 0,
        },
        overflow: "hidden",
        transitionProperty: "width",
        transitionDuration: "350ms"
    },
    stickyBar: {
        position: "sticky",
        top: "70px",
        height: "calc(100vh - 70px)"
    }
}));

export default useStyles;