import React, { ComponentType, memo, useMemo } from "react";
import GenericInput from "../GenericInput";
import { CartPaymentType } from "../../../../../../__domain/model";
import { fieldNames } from "../../fields";
import { GenericForm } from "@italwebcom/react-generic-form";

const { useValues } = GenericForm;

/**
 * @typedef {{value: CartPaymentType, onChange: (value: CartPaymentType) => void}} PaymentTypeInputComponentProps
 * @typedef {ComponentType<PaymentTypeInputComponentProps>} PaymentTypeInputComponent
 */

/**
 * @returns
 * @param {{InputComponent: PaymentTypeInputComponent}} param0
 */
function PaymentTypeInput({ InputComponent, testID }) {
  const values = useValues();
  const disabled = useMemo(
    () => values && values[fieldNames.type] === "in_place",
    [values]
  );
  return (
    <GenericInput
      field={fieldNames.paymentType}
      InputComponent={InputComponent}
      InputProps={{ "data-testid": testID || fieldNames.paymentType, disabled }}
      key={disabled}
      controlled
    />
  );
}

export default memo(PaymentTypeInput);
