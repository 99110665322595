import {colors, makeStyles} from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  product: {
    height: "2rem",
    width: "2rem",
    borderRadius: "50%",
    display: "block",
  },
  statusText: {
    textAlign: "left",
  },
  awaitingPaymentText: {
    color: "red",
    fontWeight: "bold",
  },
  costWrapper: {
    color: "white",
    backgroundColor: "green",
    padding: theme.spacing(1),
    borderRadius: "50%",
  },
  dateTimeText: {
    color: "rgb(111, 111, 111)",
  },
  wrapper: {
    cursor: "pointer",
    "&:hover,.past": {
      opacity: 0.45,
    },
  },
  pastReservation: {
    opacity: 0.5,
  },
  title: {
    fontWeight: "bold",
    color: theme.palette.grey["800"]
  }
}));

export default useStyles;
