import React from "react";
import { memo } from "react";
import CustomerEditor from "../../components/_entities/customers/CustomerEditor";
import { Customers } from "@italwebcom/anema-react-components";
import UserAreaGate from "../__auxiliary__/UserAreaGate";
import CustomerCredentialsSetter from "../../components/CustomerCredentialsSetter";
import { useRuntimeContext } from "../../contexts";
import { NonWrappedDefaultErrorPrinter } from "../../components/_auxiliary/DefaultErrorPrinter";
import MainContentPanel from "../../components/MainContentPanel";

const { useCustomer } = Customers.hooks;

/**
 * @param {{title: string}} param0
 * @returns
 */
function UserAreaDataEdit({ title, noHeader }) {
  const { customer, error, onRefresh } = useCustomer();
  const { stylings, smallScreen } = useRuntimeContext();
  return (
    <UserAreaGate
      Setter={CustomerCredentialsSetter}
      title={title || "Modifica dati utente"}
      minHeight={!smallScreen && "calc(100vh - 144px)"}
      PageWrapperProps={{
        noContentGutters: smallScreen,
        noTopPadding: smallScreen,
        noHeader
      }}
    >
      {error ? (
        <MainContentPanel
          //title="Inserimento nuovi dati"
          header={<span />}
          //headerProps={stylings.general.contentPanelHeader}
        >
          <NonWrappedDefaultErrorPrinter onRefreshAll={onRefresh} />
        </MainContentPanel>
      ) : (
          <CustomerEditor
            customer={customer}
            headerProps={stylings.general.contentPanelHeader}
          />
      )}
    </UserAreaGate>
  );
}

export default memo(UserAreaDataEdit);
