import React, {
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { BaseProps } from "../defines";
import Context from "../context";
import {makeStyles} from "@material-ui/core"
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import useContainerScrolling from "../hooks/useContainerScrolling";

const useStyles = makeStyles((theme) => ({
  itemsWrapper: {
    display: "flex",
    flexWrap: "nowrap",
    overflow: "auto",
    maxWidth: "100%",
    alignItems: "center",
    flex: 1,
  },
  wrapper: {
    display: "flex",
  },
}));

const defaultStyling = {
  colors: {
    default: "rgb(32, 132, 232)",
    selected: "red",
  },
  backgroundColor: "white",
};

function Arrow({ direction, onClick }) {
  return (
    <IconButton size="small" onClick={onClick}>
      {direction === "left" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
    </IconButton>
  );
}

/**
 * @returns
 * @param {BaseProps} param0
 */
function Base({
  onChange,
  defaultValue,
  children,
  styling,
  arrows,
  containerStyle,
}) {
  const { wrapper, itemsWrapper } = useStyles();
  const [value, setValue] = useState(defaultValue);
  const containerRef = useRef();
  const { scrollLeft, scrollRight } = useContainerScrolling(containerRef, 100);

  const actualStyling = useMemo(
    () => (styling ? { ...defaultStyling, ...styling } : defaultStyling),
    [styling]
  );

  const onActualSetValue = useCallback(
    (v) => {
      setValue(v);
      onChange(v);
    },
    [setValue, onChange]
  );

  return (
    <Context.Provider
      value={{
        selected: value,
        onChange: onActualSetValue,
        styling: actualStyling,
      }}
    >
      <Box
        className={wrapper}
        style={{
          ...(containerStyle || {}),
          backgroundColor: actualStyling.backgroundColor,
        }}
      >
        {arrows && <Arrow direction="left" onClick={scrollLeft} />}
        <div className={itemsWrapper} ref={(r) => (containerRef.current = r)}>
          {children}
        </div>
        {arrows && <Arrow direction="right" onClick={scrollRight} />}
      </Box>
    </Context.Provider>
  );
}

export default memo(Base);
