import { useState, useEffect, useCallback, useMemo } from "react";
import usePaginator from "../use-paginator/use-paginator.hook";
import useCollectionState from "../use-collection-state/use-collection-state.hook";
import { Filter, Sorter, FetchResult } from "../../defines";

/* istanbul ignore next */
const errMex = (reason) => {
  if (reason && reason.message) {
    return reason.message;
  }
  if (typeof reason === "object") {
    return JSON.stringify(reason);
  }
  return reason;
};
const str = (v) => JSON.stringify(v);

const defaultConfig = {
  filterMatcher: (a, b) => a.attribute === b.attribute,
};

const errors = {
  delete: "delete_error",
  save: "save_error",
  fetch: "fetch_error",
};

const defaultOnErrorHandler = (e) => console.log(e);

/**
 * @typedef {"fetch_error" | "save_error" | "delete_error"} EntityManagementErrorType
 * @typedef {{
 *      type: EntityManagementErrorType,
 *      message: string,
 *      reason?: object
 * }} EntityManagementError
 * @typedef {"asc" | "desc"} SortDirection
 */

/**
 * @template {string} Indices
 * @typedef {{
 *      filterMatcher: (a: Filter<Indices>, b: Filter<Indices>) => boolean,
 *      noAutoRefetch?: boolean,
 *      noAutoFetch?: boolean,
 *      id?: string,
 *      lazyCountRecompute?: boolean
 * }} UserEntityManagementConfig
 */

/**
 * @template T
 * @template {string} Indices
 * @template {string} Sorters
 * @typedef {{
 *      onFetch: (args: {filters?: Filter<Indices>[], sorter?: Sorter<Sorters>, start?: number, end?: number}) => Promise<FetchResult<T>>,
 *      onSave: (element: T) => Promise<FetchResult<T>>,
 *      onDelete: (element: T) => Promise<FetchResult<T>>,
 *      onError: (error: EntityManagementError) => void,
 *      onSuccess: (operation: "save" | "fetch" | "delete") => void,
 *      countGetter?: (result: FetchResult<T>) => number,
 *      initialCount?: number,
 *      initialItemsPerPage?: number,
 *      initialFilters?: Filter<Indices>[],
 *      initialElements?: T[],
 *      initialSorter?: Sorter<Sorters>,
 *      config?: UserEntityManagementConfig<Indices>,
 *      onCountChange?: (oldCount: number, newCount: number) => void
 * }} UseEntityManagementArgs
 */

/**
 * @template T
 * @template {string} Indices
 * @template {string} Sorters
 * @typedef {{
 *      count: number,
 *      page: number,
 *      error: EntityManagementError,
 *      elements: T[],
 *      filters: Filter<Indices>[],
 *      sorter: Sorter<Sorters>,
 *      onRefreshAll: () => Promise<FetchResult<T>>,
 *      onSave: (element: T) => Promise<any>,
 *      onDelete: (element: T) => Promise<any>,
 *      onFilterAdd: (filter: Filter<Indices>) => void,
 *      onFilterRemove: (filter: Filter<Indices>) => void,
 *      onFilterReplace: (target: Filter<Indices>, filter: Filter<Indices>) => void,
 *      onFiltersSetAll: (elements: Filter<Indices>[]) => void,
 *      onFiltersClear: Function,
 *      onSorterChange: (sorter: Sorter<Sorters>) => void,
 *      onSorterClear: Function,
 *      onItemsPerPageChange: (itemsPerPage: number) => void,
 *      onNextPage: Function,
 *      onPrevPage: Function,
 *      onPageChange: (page: number) => void
 * }} UseEntityManagementReturn
 */

function onInitOp(setState) {
  setState((s) => ({ ...s, loading: true, error: null }));
}

function onSetErr(setState, error) {
  setState((s) => ({ ...s, loading: false, error }));
}

function onSetData(setState, result) {
  console.log("onSetData");
  setState((s) => ({ ...s, elements: result.data, loading: false }));
}

/**
 * @template T
 * @template {string} Indices
 * @template {string} Sorters
 * @param {UseEntityManagementArgs<T, Indices, Sorters>} param0
 */
function useEntityManagement({
  initialCount,
  initialItemsPerPage,
  initialFilters,
  initialElements,
  initialSorter,
  onFetch,
  countGetter,
  onError,
  onSuccess,
  onSave,
  onDelete,
  config,
  onCountChange: inputOnCountChange
}) {
  config = config || defaultConfig;
  onError = onError || defaultOnErrorHandler;

  console.log(inputOnCountChange);

  const { noAutoRefetch, noAutoFetch, id, lazyCountRecompute } = config;
  const {
    count,
    page,
    start,
    end,
    onNextPage,
    onPageChange,
    onCountChange,
    onPrevPage,
    onItemsPerPageChange,
    itemsPerPage,
  } = usePaginator({
    count: initialCount,
    itemsPerPage: initialItemsPerPage,
    lazyCountRecompute,
    onCountChange: inputOnCountChange
  });

  const {
    collection: filters,
    onAdd: onFilterAdd,
    onRemove: onFilterRemove,
    onReplace: onFilterReplace,
    onSetAll: onFiltersSetAll,
  } = useCollectionState({
    initial: initialFilters,
    config: { matcher: config.filterMatcher },
  });

  /* istanbul ignore next */
  const [sorter, setSorter] = useState(initialSorter);

  const [state, setState] = useState({
    loading: false,
    error: null,
    elements: initialElements || [],
  });

  const fetchCallback = useCallback(
    async ({ filters, sorter, start, end, itemsPerPage }) => {
      onInitOp(setState);
      try {
        let result = await onFetch({
          filters,
          sorter,
          start,
          end,
          itemsPerPage,
        });
        if (!result.data) {
          result = { data: result };
        }
        onSetData(setState, result);
        if (countGetter) {
          let count = countGetter(result);
          if (count) {
            onCountChange(count);
          }
        }
        onSuccess && onSuccess("fetch");
      } catch (reason) {
        const err = {
          type: errors.fetch,
          message: errMex(reason),
          reason,
        };
        onSetErr(setState, err);
        onError(err);
      }
    },
    [onFetch, onError, countGetter, setState, onCountChange]
  );

  /* elements fetch according to current values */
  useEffect(() => {
    /*console.log(
      `useEntityManagement ${
        id || ""
      }.<useEffect>: changing values, calling fetchCallback [start = ${start}, end = ${end}, filters = ${str(
        filters
      )}, sorter = ${str(sorter)}`
    );*/
    !noAutoFetch &&
      fetchCallback({ filters, sorter, start, end, itemsPerPage });
  }, [start, end, filters, sorter, itemsPerPage, noAutoFetch]);

  const onElementSave = useCallback(
    async (element) => {
      try {
        onInitOp(setState);
        let v = await onSave(element);
        if (v !== false && !noAutoRefetch) {
          await fetchCallback({
            filters,
            sorter,
            start,
            end,
            itemsPerPage,
          });
        }
        setState((s) => ({ ...s, loading: false }));
        onSuccess && onSuccess("save");
      } catch (reason) {
        const err = {
          type: errors.save,
          message: errMex(reason),
          reason,
        };
        onSetErr(setState, err);
        await onError(err);
      }
    },
    [start, end, itemsPerPage, filters, sorter, noAutoRefetch]
  );

  const onElementDelete = useCallback(
    async (element) => {
      try {
        onInitOp(setState);
        let v = await onDelete(element);
        if (v !== false && !noAutoRefetch) {
          return fetchCallback({
            filters,
            sorter,
            start,
            end,
            itemsPerPage,
          });
        }
        setState((s) => ({ ...s, loading: false }));
        onSuccess && onSuccess("delete");
      } catch (reason) {
        const err = {
          type: errors.delete,
          message: errMex(reason),
          reason,
        };
        onSetErr(setState, err);
        onError(err);
      }
    },
    [start, end, itemsPerPage, filters, sorter, noAutoRefetch]
  );

  const onRefreshAll = useCallback(
    () => fetchCallback({ filters, sorter, start, end, itemsPerPage }),
    [filters, sorter, start, end, itemsPerPage, fetchCallback]
  );

  const { onSorterChange, onSorterClear } = useMemo(
    () => ({
      onSorterChange: (v) => setSorter((s) => ({ ...s, ...v })),
      onSorterClear: () => setSorter({}),
    }),
    [setSorter]
  );

  const onItemsPerPageIncrease = useCallback(
    (v) => onItemsPerPageChange((p) => p + v),
    [onItemsPerPageChange]
  );
  return {
    count,
    page,
    itemsPerPage,
    elements: state.elements,
    error: state.error,
    filters,
    sorter,
    loading: state.loading,
    onSave: onElementSave,
    onDelete: onElementDelete,
    onFilterAdd,
    onFilterRemove,
    onFilterReplace,
    onFiltersSetAll,
    onNextPage,
    onCountChange,
    onPrevPage,
    onItemsPerPageChange,
    onPageChange,
    onRefreshAll,
    onSorterChange,
    onSorterClear,
    onItemsPerPageIncrease,
  };
}

export default useEntityManagement;
