import React, { memo } from "react";
import { Carts } from "@italwebcom/anema-react-components";
import ListItem from "../ListItem";
import { makeStyles } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItemRenderer from "../../../../products/CustomerProductSelectionView/ItemRenderers/ListItemRenderer";
import {
  SelectedElementHandler,
  useSelectedElement,
} from "../../../../products/CustomerProductSelectionView/_Auxiliary/SelectedElementHandler";
import { useRuntimeContext } from "../../../../../../contexts";
import MissingElementRenderer from "../../../../../MissingElementRenderer";
import ShoppingCart from "@material-ui/icons/ShoppingCart";

const s = makeStyles((theme) => ({
  listItem: {
    "&:not(:first-of-type)": {
      paddingTop: theme.spacing(2),
    },
    "&:not(:last-of-type)": {
      paddingBottom: theme.spacing(2),
    },
  },
}));

const { useCartAndProducts, useCartDerivedAttributes } = Carts.hooks;
const placeholderEls = [
  { product: { name: "aaa", description: "aaaa" } },
  { product: { name: "aaa", description: "aaaa" } },
  { product: { name: "aaa", description: "aaaa" } },
];

function CartProductList() {
  const {
    cartProducts,
    cartLoading,
    productsLoading,
    cart,
    onCartProductSave,
    onCartProductDelete,
  } = useCartAndProducts();
  const { listItem } = s();
  const { editable } = useCartDerivedAttributes(cart);
  const isPlaceholder =
    (cartLoading || productsLoading) && (!cartProducts || !cartProducts.length);
  const actualElements = isPlaceholder ? placeholderEls : cartProducts;
  const { onSetSelected } = useSelectedElement();
  if (isPlaceholder || cartProducts.length) {
    return (
      <List>
        {actualElements.map((cp) => (
          <ListItemRenderer
            inSel={cp}
            element={cp.product}
            placeholder={isPlaceholder}
            onSelect={onSetSelected}
          />
        ))}
      </List>
    );
  } else {
    return (
      <MissingElementRenderer
        Icon={ShoppingCart}
        title="Nessun prodotto presente."
        content="Non hai selezionato prodotti per questa prenotazione."
      />
    );
  }
}

function Outer() {
  const { smallScreen } = useRuntimeContext();
  return (
    <SelectedElementHandler variant={smallScreen ? "drawer" : "popover"}>
      <CartProductList />
    </SelectedElementHandler>
  );
}

export default memo(Outer);
