import React, { ReactNode } from "react";
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  ButtonGroup,
} from "@material-ui/core";
import HulkButton, { HulkButtonVariant } from "../../Inputs/HulkButton";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    listStyleType: "none",
  },
  image: {
    display: "block",
    maxWidth: "100%",
  },
  centeredItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  primary: {
    fontWeight: "bold",
  },
  rightContentWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  rightContentSpaceBetween: {
    justifyContent: "space-between",
  },
  buttonGroup: {
    "& .button-group-secondary + .button-group-primary": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& .button-group-primary + .button-group-secondary": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
}));

/**
 * @typedef {{
 *      primary: string,
 *      secondary?: string,
 *      image?: string,
 *      icon?: ReactNode,
 *      onAction?: Function,
 *      actionLabel?: string,
 *      actionVariant?: HulkButtonVariant,
 *      additionalContent?: ReactNode,
 *      centeredContent?: boolean
 * }} HulkListItemProps
 */

/**
 * @param {HulkListItemProps} param0
 */
export default function HulkListItem({
  icon,
  image,
  primary,
  secondary,
  onAction,
  actionLabel,
  actionVariant,
  additionalContent,
  additionalTitleContent,
  centeredContent,
  component,
  primaryTypographyProps,
  secondaryTypographyProps,
  footer
}) {
  const {
    wrapper,
    image: imageStyle,
    primary: primaryStyle,
    rightContentWrapper,
    buttonGroup,
    centeredItem,
    rightContentSpaceBetween,
  } = useStyles();

  return (
    <Box component={component || "li"} className={wrapper}>
      <Grid
        container
        spacing={2}
        component="article"
        alignItems={centeredContent && "center"}
      >
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography
              component="h6"
              variant="h6"
              gutterBottom={Boolean(secondary)}
              className={primaryStyle}
              {...(primaryTypographyProps || {})}
            >
              {primary}
            </Typography>
            {additionalTitleContent}
          </Box>
        </Grid>
        {icon ? (
          <Grid item className={centeredItem}>
            {icon}
          </Grid>
        ) : (
          <Grid item xs={3} component="aside">
            <img src={image} className={imageStyle} alt={primary} />
          </Grid>
        )}
        {secondary && (
          <Grid item xs component="section">
            <Typography
              component="p"
              variant="body1"
              {...(secondaryTypographyProps || {})}
            >
              {secondary}
            </Typography>
          </Grid>
        )}
        <Grid item>
          <Box
            className={clsx([
              rightContentWrapper,
              additionalContent && rightContentSpaceBetween,
              !additionalContent && centeredItem,
            ])}
          >
            {additionalContent}
            <ButtonGroup className={buttonGroup}>
              {onAction && (
                <HulkButton
                  className="button-group-primary"
                  label={actionLabel || "Seleziona"}
                  onClick={onAction}
                  variant={actionVariant || "dark"}
                />
              )}
            </ButtonGroup>
          </Box>
        </Grid>
        {footer && <Grid item xs={12}>
            {footer}
        </Grid>}
      </Grid>
    </Box>
  );
}
