import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
    sectionTitle: {
        color: "rgb(75, 75, 75)",
        fontWeight: "bold"
    },
    sectionSubtitle: {
        color: "rgb(156, 156, 156)"
    }
}));