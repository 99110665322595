export default function fetchArgsProcessor(args) {
    args = args || {};
    let out = {
        size: args.itemsPerPage,
        page: Math.ceil(args.start / args.itemsPerPage)
    };
    let filters = args.filters;
    let sorter = args.sorter;
    if (filters) {
      for (let filter of filters) {
        if(out[filter.attribute]) {
          out[filter.attribute] += `,${filter.value}`;
        } else {
          out[filter.attribute] = filter.value;
        }
      }
    }
    if (sorter) {
      out.sortAttribute = sorter.attribute;
      out.sortDirection = sorter.direction || "asc";
    }
    return out;
  }
  