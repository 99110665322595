import React, { ReactNode, memo, useState } from "react";
import Context from "../context";

/**
 *
 * @param {{
 *    readOnly?: boolean,
 *    children: ReactNode[],
 *    defaultSelected?: string
 * }} param0
 */
function Provider({ readOnly, children, defaultSelected, cart, placeholder }) {
  const [selected, setSelected] = useState(defaultSelected);
  return (
    <Context.Provider
      value={{ readOnly, selected, setSelected, cart, placeholder }}
    >
      {children}
    </Context.Provider>
  );
}

export default memo(Provider);
