import React, { memo } from "react";
import Grid from "@material-ui/core/Grid";
import LocalDining from "@material-ui/icons/LocalDining";
import LocalMall from "@material-ui/icons/LocalMall";
import LocalShipping from "@material-ui/icons/LocalShipping";
import LoginButton from "../LoginButton";
import MakeReservationButton from "./MakeReservationButton";
import OtherwiseSeparator from "./OtherwiseSeparator";

function ReserveLoginPanel({
  onRedirectToDelivery,
  onRedirectToInPlace,
  onRedirectToDefault,
  onRef,
}) {
  return (
    <div ref={onRef}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MakeReservationButton
            onClick={onRedirectToDelivery}
            label="Consegna"
            icon={<LocalShipping fontSize="inherit" />}
            id="make-reservation-delivery"
          />
        </Grid>
        <Grid item xs={12}>
          <MakeReservationButton
            onClick={onRedirectToInPlace}
            label="Tavolo"
            icon={<LocalDining fontSize="inherit" />}
            id="make-reservation-inplace"
          />
        </Grid>
        <Grid item xs={12}>
          <MakeReservationButton
            onClick={onRedirectToDefault}
            label="Asporto"
            icon={<LocalMall fontSize="inherit" />}
            id="make-reservation-default"
          />
        </Grid>
            <Grid item xs={12}>
              <OtherwiseSeparator />
            </Grid>
            <Grid item xs={12}>
              <LoginButton id="make-login-reservation-panel" />
            </Grid>
      </Grid>
    </div>
  );
}

export default memo(ReserveLoginPanel);