import { Conversation } from "../../model";
import wrappers from "../hateoas";
import settings from "../settings";

const n = settings.nConvos;

function s(v) {
  return (v < 10) ? `0${v}` : v;
}

/**
 * @type {Conversation[]}
 */
const conversations = [];

for (let i = 0; i < n; i++) {
  conversations.push(
    wrappers.conversation({
      id: i,
      name: `conversation${i}`,
      lastMessageDate: `2022-11-11T11:${s((20 + i) % 60)}:00.000Z`,
    })
  );
}

export default conversations;
