function lel(n) {
    if(n === "categories") {
      return "category";
    } else {
      return n.substring(0, n.length - 1);
    }
  }
  
  function undef(a) {
    return typeof(a) === "undefined";
  }
  
  const getDefaultOperations = (n, additionalTemplates) => ({
    _links: {
      fetch: {
        href: `/${n}{?sortAttribute,status,sortDirection,page,size,name,type,parent,categories,code,username,fullName,filterType,date,query,month,weekday}`,
        templated: true
      },
      read: {
        href: `/${lel(n)}/{id}`,
        templated: true
      }
    },
    _templates: {
      create: {
        method: "POST",
        properties: []
      },
      ...(additionalTemplates || {})
    }
  })
  
  const getResponse = (status, data, latency) => ({
    status,
    json: () => {
      if (latency) {
        return new Promise((res, rej) => {
          setTimeout(() => res(data), latency);
        });
      } else {
        return Promise.resolve(data);
      }
    },
  });
  
  const makeRequestBuilder =
    (l) =>
    ({ status, data }) => {
      if (data && !undef(data.count) && !undef(data.data)) {
        const { data: actualData, count } = data;
        return getResponse(status, { data: actualData, page: { count } }, l);
      } else {
        return getResponse(status, { data }, l);
      }
    };
  
  export {makeRequestBuilder, getDefaultOperations};
  