import React, { memo, useCallback } from "react";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";

function AvatarEditor({ value, onChange, disabled, loading }) {
  const onActualChange = useCallback(
    (evt) => {
      const fr = new FileReader();
      fr.onloadend = () => {
        onChange({ target: { value: fr.result } });
      };
      fr.readAsDataURL(evt.target.files[0]);
    },
    [onChange]
  );
  return (
    <Box display="flex" width="100%" alignItems="center">
      <img
        style={{
          width: "3rem",
          height: "3rem",
          borderRadius: "50%",
          marginRight: "1rem",
        }}
        src={value}
        alt="Avatar"
      />
      <TextField
        type="file"
        onChange={onActualChange}
        fullWidth
        disabled={disabled || loading}
        inputProps={{
          accept: "image/png, image/jpeg",
        }}
      />
    </Box>
  );
}

export default memo(AvatarEditor);