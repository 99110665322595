import React, { memo, useContext, ComponentType } from "react";
import Context from "../Context";
import {SelectionRendererProps} from "../defines";

/**
 * @param {{Renderer: ComponentType<SelectionRendererProps>}} param0 
 * @returns 
 */
function Selection({Renderer}) {
    const {selected, onRemove} = useContext(Context);
    return <Renderer selected={selected} onRemove={onRemove} />
}

export default memo(Selection);