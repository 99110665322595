import { Box } from "@material-ui/core";
import React, { memo, useCallback, useState, ElementType, CSSProperties } from "react";
import MenuContext, { ApplicabilityFunction } from "../context";

const defaultIsApplicable = (a, b) => a === b;

/**
 * @returns
 * @param {{isApplicable?: ApplicabilityFunction, variant: "light" | "dark", component?: ElementType, itemStyle?: CSSProperties}} param0
 */
function Menu({ children, isApplicable, variant, component, itemStyle }) {
  const [selected, setSelected] = useState(null);
  isApplicable = isApplicable || defaultIsApplicable;
  const onActualSetSelected = useCallback(
    (v) =>
      setSelected((s) => {
        if (s === v) {
          return null;
        } else {
          return v;
        }
      }),
    [setSelected]
  );
  return (
    <Box component={component}>
      <MenuContext.Provider
        value={{
          selected,
          setSelected: onActualSetSelected,
          isApplicable,
          variant,
          itemStyle
        }}
      >
        {children}
      </MenuContext.Provider>
    </Box>
  );
}

export default memo(Menu);
