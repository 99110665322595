import React, { useMemo } from "react";
import { useRuntimeContext } from "../../../contexts";
import { Carts } from "@italwebcom/anema-react-components";
import { memo } from "react";
import HomepageView from "../../../components/_auxiliary/HomepageView";
import Box from "@material-ui/core/Box";

const { useCartTypeSet } = Carts.hooks;

function PanelRenderer({
  variant,
  smallScreen,
  headersTypographyProps,
  panelDetails,
  panelDescription,
  panelBackgroundImage,
  panelButtonImage,
  title,
  className,
  cart,
  logo,
}) {
  const { viewNames, Navigation, deliveryDisabled } = useRuntimeContext();
  const { onUserArea, onAfterSet } = useMemo(
    () => ({
      onUserArea: () => Navigation.navigate(viewNames.user.dashboard, { cart }),
      onAfterSet: (t) =>
        Navigation.navigate(
          t !== "in_place" ? viewNames.menuSelection : viewNames.cartOverview,
          { cart }
        ),
    }),
    [viewNames, Navigation, cart]
  );

  const { loading, onSet, editable, awaitingPayment } =
    useCartTypeSet(onAfterSet);

  if (variant === "panel") {
    return (
      <Box className={className}>
        <HomepageView.Panel
          onUserArea={onUserArea}
          onReservation={onSet}
          image={panelBackgroundImage}
          smallScreen={smallScreen}
          details={panelDetails}
          description={panelDescription}
          buttonGroupDisabled={loading}
          logo={logo}
          noDelivery={deliveryDisabled}
        />
      </Box>
    );
  } else {
    return (
      <HomepageView.FullPage
        title={title}
        onUserArea={onUserArea}
        onReservation={onSet}
        image={panelBackgroundImage}
        smallScreen={smallScreen}
        details={panelDetails}
        description={panelDescription}
        buttonGroupImage={panelButtonImage}
        buttonGroupTypographyProps={{
          variant: smallScreen ? undefined : "h5",
          style: {
            ...headersTypographyProps.style,
            color: "white",
            textAlign: "center",
            position: "relative",
            //bottom: "3px",
          },
        }}
        buttonGroupImageSize={smallScreen ? 80 : 100}
        titleTypographyProps={{ style: { color: "white" } }}
        descriptionTypographyProps={{ style: { color: "white" } }}
        buttonGroupDisabled={loading || !editable}
        buttonGroupLoading={loading}
        logo={logo}
        noDelivery={deliveryDisabled}
      />
    );
  }
}

export default memo(PanelRenderer);
