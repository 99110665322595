import { CostList } from "../../model";
import wrappers from "../hateoas";

const n = 10;

/**
 * @type {CostList[]}
 */
let elements = [];

for (let i = 0; i < n; i++) {
  elements.push(
    wrappers.costList({
      id: i,
      name: `costList${i}`,
      priority: i % 2,
    })
  );
}

export default elements;
