import { useCallback, useEffect, useRef, useState } from "react";

/**
 * @param {{
 *    loading?: boolean,
 *    disabled?: boolean,
 *    onClick: Function
 * }} param0
 * @returns
 */
export default function useButtonAutoLoading({ loading, onClick, disabled }) {
  const [load, setLoad] = useState(false);
  const disabledRef = useRef();

  useEffect(() => {
    console.log(loading);
    setLoad(loading);
  }, [loading, setLoad]);
  useEffect(() => {
    if (disabledRef) {
      disabledRef.current = disabled;
    }
  }, [disabledRef, disabled]);

  const onActualClick = useCallback(
    (evt) => {
      console.log("CLICK");
      if (!disabledRef.current) {
        setLoad(true);
        onClick && onClick(evt);
      }
    },
    [setLoad, onClick, disabledRef]
  );

  return { load, onActualClick };
}
