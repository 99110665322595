import React, { ReactNode, useContext } from "react";
import useStyles from "../styles";
import MiscUiStylesContext from "../../../contexts/styles";
import clsx from "clsx";
import {AppBar, Box, Grid, Typography} from "@material-ui/core";

/**
 * @typedef {{
 *      title?: string,
 *      subtitle?: string,
 *      logo: string,
 *      leftContent: ReactNode,
 *      rightContent: ReactNode,
 *      additionalContent?: ReactNode
 * }} SimpleAppBarProps
 *
 * @param {SimpleAppBarProps} param0
 */

export default function AltAppBar({
  title,
  subtitle,
  logo,
  additionalContent,
  height,
  leftContent,
  rightContent,
}) {
  const classes = useStyles();
  const { AppBar: AppBarStyles } = useContext(MiscUiStylesContext);
  return (
    <AppBar
      className={classes.bar}
      style={{ height }}
      variant="outlined"
      color="transparent"
      position="sticky"
    >
      <Box className={classes.externalWrapper}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>{leftContent}</Grid>
          <Grid
            item
            className={clsx([!AppBarStyles.onlyLogo && classes.logo])}
            xs={AppBarStyles.onlyLogo}
          >
            <img
              className={clsx([
                classes.image,
                AppBarStyles.onlyLogo && classes.logoOnSmall,
              ])}
              src={logo}
            />
          </Grid>
          {Boolean(!AppBarStyles.onlyLogo && title) && (
            <Grid item>
              <Typography
                component="h2"
                variant="h2"
                gutterBottom={subtitle}
                className={classes.title}
              >
                {title}
              </Typography>
              {subtitle && (
                <Typography
                  component="h2"
                  variant="body1"
                  className={classes.subtitle}
                >
                  {subtitle}
                </Typography>
              )}
            </Grid>
          )}
          {Boolean(!AppBarStyles.onlyLogo) && (
            <Grid item xs>
              {additionalContent}
            </Grid>
          )}
          <Grid item>{rightContent}</Grid>
        </Grid>
      </Box>
    </AppBar>
  );
}
