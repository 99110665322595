import React, { memo } from "react";
import ItemRenderer from "../../../ItemRenderers/AlternateListItemRenderer";
import Grid from "@material-ui/core/Grid";

function SimpleRenderer({ elements, placeholder, onSelect }) {
  return (
    <Grid
      container
      spacing={3}
      component="ul"
      style={{ paddingLeft: 0, listStyleType: "none" }}
    >
      {elements.map(
        (e) =>
          (
            <Grid item xs={12} component="li" key={e.id}>
              <ItemRenderer
                element={e}
                placeholder={placeholder}
                onClick={(evt) => onSelect(e, evt.currentTarget)}
              />
            </Grid>
          )
      )}
    </Grid>
  );
}

export default memo(SimpleRenderer);
