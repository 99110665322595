export const tagNames = [
  "Cotone",
  "Alpaca",
  "Lana",
  "Silke",
  "Borgo pazzi",
  "In sconto",
  "Roba rimasta",
  "Roba inutile",
  "Saldi estivi",
  "Saldi invernali",
];

export function getFilterType(i) {
  return `tag_type_${i}`;
}
