import GlobalSearchQueryProvider from "./QueryProvider";
import GlobalSearchManager from "./Manager";
import useGlobalSearch from "./hooks/useGlobalSearch";

const components = {
  Manager: GlobalSearchManager,
  QueryProvider: GlobalSearchQueryProvider,
};

const hooks = {
  useGlobalSearch,
};

export { components, hooks };
