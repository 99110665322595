import { useMemo } from "react";
import {Field, FieldValidationRule, CompleteFieldValidationRule} from "../defines";

/**
 * @returns {CompleteFieldValidationRule}
 * @param {FieldValidationRule} rule 
 */
function processValidationRule(rule) {
    if(rule instanceof RegExp) {
        return {validator: rule, invalidMessage: "Valore non valido.", priority: 0};
    } else {
        return {validator: rule.validator, invalidMessage: rule.invalidMessage || "Valore non valido.", priority: rule.priority || 0};
    }
}

/**
 * @returns {Field}
 * @param {Field} field 
 */
function processField(field) {
    let processedField = {id: field.id};
    if(field.validationRules) {
        let validationRules = field.validationRules.map(processValidationRule);
        processedField.validationRules = validationRules;
    }
    processedField.type = field.type || "text";
    return processedField;
}

/**
 * @param {Field[]} fields
 * @returns {Field[]}
 */
export default function useProcessedFields(fields) {
    return useMemo(() => fields.map(processField), [fields]);
}