import {useEffect, useState} from "react";

const defaultComparer = (a, b) => a === b;

/**
 * @template T
 * @typedef {(current: T, previous: T) => boolean} ValueComparer
 */

/**
 * @template T
 * @param {{defaultValue: T, comparer?: ValueComparer<T>}} param0 
 * @returns
 */
export default function useStateWithDefault({defaultValue, comparer}) {
    comparer = comparer || defaultComparer;
    const [value, setValue] = useState(defaultValue);
    useEffect(() => {
        setValue(v => {
            if(!comparer(v, defaultValue)) {
                //console.log(`v[${v}]!==defaultValue[${defaultValue}], updating.`);
                return defaultValue;
            } else {
                //console.log(`v[${v}]===defaultValue[${defaultValue}], no update.`);
            }
            return v;
        })
    }, [defaultValue]);
    return [value, setValue];
}