import { useCallback, useMemo } from "react";

function getHours(openingTimes) {
    if(openingTimes) {
        return Array.from(new Set(openingTimes.map(ot => ot.hour)));
    }
    return [];
}

function getMinutes(openingTimes, hour) {
    const h = parseInt(hour);
    if(openingTimes) {
        return openingTimes.filter(ot => ot.hour === h).map(ot => ot.minutes);
    }
    return [];
}

export default function useOpeningTimesData({openingTimes}) {
    const hours = useMemo(() => getHours(openingTimes), [openingTimes]);
    const minutesGetter = useCallback(h => getMinutes(openingTimes, h), [openingTimes]);
    return {hours, minutesGetter};
}