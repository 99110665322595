import React, { memo, useCallback, useMemo, useRef } from "react";
import Context from "../context";
import { useCollectionState } from "@italwebcom/custom-react-hooks";
import {
  HulkAlertContainerKeyGetter,
  HulkAlertContainerMessage,
  HulkAlertContainerInput,
} from "../defines";
import useAlertContainerKey from "../hooks/useAlertContainerKey";

const messageMatcher = (a, b) => a.key === b.key;

/**
 * @param {{
 *    initialMessages?: HulkAlertContainerInput[]
 * }} param0
 * @returns
 */
function Provider({ children, initialMessages }) {
  const useKey = useAlertContainerKey();

  /**
   * @type {HulkAlertContainerMessage[]}
   */
  const initialData = useMemo(() => {
    if (initialMessages && initialMessages.length) {
      return initialMessages.map((m) => useKey((k) => ({ ...m, key: k })));
    } else {
      return [];
    }
  }, [initialMessages, useKey]);

  const { collection, onAdd, onRemove } = useCollectionState({
    initial: initialData,
    config: { matcher: messageMatcher },
  });

  const onActualAdd = useCallback(
    (v) => useKey((k) => onAdd({ ...v, key: k })),
    [onAdd, useKey]
  );

  const onActualRemove = useCallback(
    (v) => {
      //console.log(`Provider.<onActualRemove callback>: removing ${JSON.stringify(v)}`);
      onRemove(v);
    },
    [onRemove]
  );
  return (
    <Context.Provider
      value={{
        messages: collection,
        onAdd: onActualAdd,
        onRemove: onActualRemove,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default memo(Provider);
