import { useCallback } from "react";
import { CredentialsSetter } from "@italwebcom/react-auth-provider";
import useSetCredentials from "../useSetCredentials";
import useUserWrapper from "../../../../hooks/wrappers/useUserWrapper";
import useTempCartOperation from "../../../../components/entities/Carts/hooks/useTempCartOperation";
import useTempCartStorageClear from "../../../../components/entities/Carts/hooks/useTempCartStorageClear";
import useAuthenticatedRequest from "../useAuthenticatedRequest";
import { headerManager } from "@italwebcom/augmented-fetch";
import {
  useTestCallback,
  callbacks,
} from "../../../../__test-auxiliary/TestCallbacks";

function cartOperator(c, w) {
  if (c) {
    return w.wrap(c).execute("acquire").json();
  } else {
    return Promise.resolve();
  }
}

/**
 * Adds additional functionalities to the useSetCredentials hook for a
 * temporary customer. When the returned callback is invoked to set
 * the credentials, it also:
 *
 * - registers the user (i.e. sending the user's userInfo to the server) with an appropriate request
 * - acquires the temporary cart, if it exists
 *
 * @param {{setCredentials: CredentialsSetter, onError: function, data: any}}
 * @returns
 */
export default function useTempCustomerSetCredentials({
  setCredentials,
  onError
}) {
  const op = useTempCartOperation(cartOperator);
  const actualOp = useAuthenticatedRequest({ operator: op });
  const testCallback = useTestCallback(
    callbacks.Authentication.setTempCustomerCredentials
  );
  const userWrapper = useUserWrapper();
  const onStorageClear = useTempCartStorageClear();
  const onBeforeSetCredentials = useCallback(
    (token) => {
      let r = userWrapper.execute("register");
      r.request().withDecorator({
        name: "token_adder",
        process: (init) => headerManager(init).bearer(token.accessToken).get(),
      });
      return r.json();
    },
    [actualOp, userWrapper, testCallback, onError]
  );

  const onAfterSetCredentials = useCallback(async () => {
    await actualOp();
    onStorageClear();
    testCallback && testCallback();
  }, [onStorageClear, actualOp]);

  return useSetCredentials({
    setCredentials,
    onError,
    onBeforeSetCredentials,
    onAfterSetCredentials,
  });
}
