import { Customer } from "../../model";
import wrappers from "../hateoas";
import settings from "../settings";
import conversations from "./conversations";

const n = settings.nCustomers;

/**
 * @type {Customer[]}
 */
const customers = [];

for (let i = 0; i < n; i++) {
  customers.push(
    wrappers.customer({
      id: i,
      username: `customer${i}`,
      code: `cust_${i}`,
      fullName: `Cliente ${i}`,
      email: `customer${i}@something.com`,
      address: `Customer${i} address`,
      phone: `012345678${i}`,
      notificationToken: `token${i}`,
      type: (i % 2) ? "customer" : "user",
      avatar: (i % 2) ? undefined : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9upf-m7C14gFlLwqPW8shudhU5u-CWfFxng&usqp=CAU",
      mainConversation: conversations[(i % conversations.length)]
    })
  );
}

export default customers;
