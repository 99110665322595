import { useStorage } from "../../../misc/StorageProvider";
import useAuthContext from "../useAuthContext";
import { useCallback } from "react";
import { headerManager } from "@italwebcom/augmented-fetch";
import { setStorageToken } from "../../../../misc/setStorageToken";

/**
 * Returns a process function for a RequestDecorator which fetches, refreshes (if needed) and adds the
 * auth token to a given request,
 *
 * @param {string} tokenStorageName
 * @returns
 */
function useTokenDecorator(tokenStorageName) {
  tokenStorageName = tokenStorageName || "token";
  const { manager } = useAuthContext();
  const { onGet, onSet } = useStorage();

  return useCallback(
    async (init) => {
      if (!init || !init.headers || !init.headers["x-cart-code"]) {
        /* fetches token from persistent storage */
        const tokenValue = onGet(tokenStorageName);
        let t = {};

        /* used to verify if token is still fresh and to refresh it if needed (or request a new one) */
        manager.setCachedToken(tokenValue);
        try {
          t = await manager.getCachedToken();

          /* sets new token, if applicable, in persistent storage*/
          if (t && t.accessToken) {
            setStorageToken({
              onStorageSet: onSet,
              currentToken: t,
              oldTokenValues: tokenValue,
            });
          }
        } catch (e) {
          console.log(
            "useAuthenticatedRequest.onSetToken: errored occured during token fetching, aborting and executing request."
          );
        }

        /* applies auth header to request */
        if (t && t.accessToken) {
          return headerManager(init).bearer(t.accessToken).get();
        }
      }
      return init;
    },
    [onGet, onSet, manager]
  );
}

export default useTokenDecorator;
