import { useCallback, useMemo } from "react";

export default function useCategoryFiltering({ filters, onFiltersSetAll }) {
  const onActualFiltersSetAll = useCallback(
    (selectedCategories) => {
      const out = selectedCategories.map((c) => ({
        attribute: "categories",
        value: c.id,
        label: c.name,
      }));
      onFiltersSetAll(out);
    },
    [onFiltersSetAll]
  );
  const selectedCategories = useMemo(
    () =>
      filters
        ? filters
            .filter((f) => f.attribute === "categories")
            .map((f) => ({ id: f.id }))
        : [],
    [filters]
  );
  return { onSetAll: onActualFiltersSetAll, categories: selectedCategories };
}
