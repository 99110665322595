import React, { memo, useCallback, useMemo } from "react";
import { Alert } from "@material-ui/lab";
import { Carts } from "@italwebcom/anema-react-components";
import useRuntimeContext from "../../../../contexts/Runtime/useRuntimeContext";
import { Gate, Switch } from "@italwebcom/react-auth-provider";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const { useCartDerivedAttributes, useCartAndProducts } = Carts.hooks;
const s = makeStyles((theme) => ({
  wrapper: {
    marginBottom: theme.spacing(2),
  },
  alert: {
    "& .MuiAlert-icon": {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.up("sm")]: {
        marginRight: theme.spacing(3),
      },
    },
    cursor: "pointer",
  },
  alertTitle: {
    color: theme.palette.error.dark,
    fontWeight: "bold",
  },
}));

const InnerTempCartAlert = memo(({ cart, onPress }) => {

  const { readOnly, awaitingPayment } = useCartDerivedAttributes(cart);
  const onActualPress = useCallback(() => onPress(cart), [cart, onPress]);
  const { alert: alertStyle, alertTitle, wrapper } = s();

  const daStuff = useMemo(() => {
    if (cart && (awaitingPayment || readOnly)) {
      /* not awaiting payment -> simply print the acquisition text */
      if (readOnly) {
        return {
          title: "Hai una prenotazione in attesa",
          content:
            "Premi qui per autenticarti e non perdere l'accesso alla tua prenotazione.",
        };
      } else {
        /* print awaiting payment text */
        return {
          title: "Hai una prenotazione in attesa di pagamento",
          content:
            "Premi qui per effettuare il pagamento e non perdere l'accesso alla tua prenotazione.",
        };
      }
    }
    /* cart missing or condition on status doesn't apply -> don't render anything */
    return null;
  }, [cart, readOnly, awaitingPayment]);

  if (daStuff) {
    const { title, content } = daStuff;
    return (
      <Box className={wrapper}>
        <Alert
          severity="error"
          role="button"
          onClick={onActualPress}
          component="article"
          className={alertStyle}
        >
          <Box component="header">
            <Typography
              component="h6"
              variant="h6"
              gutterBottom
              className={alertTitle}
            >
              {title}
            </Typography>
            <Typography component="p" variant="body2">
              {content}
            </Typography>
          </Box>
        </Alert>
      </Box>
    );
  }

  return null;
});

function TempCartAlert({ children }) {
  const { authentication, Navigation } = useRuntimeContext();
  const { cart } = useCartAndProducts();

  const onActualPress = useCallback(
    () => Navigation.navigate("cart"),
    [Navigation]
  );
  return (
    <Switch>
      <Gate role={authentication.defaultRole}>
        <InnerTempCartAlert cart={cart} onPress={onActualPress} />
      </Gate>
      <Gate role={authentication.authenticatedRole}>{children || null}</Gate>
    </Switch>
  );
}

export { InnerTempCartAlert };
export default memo(TempCartAlert);
