import { useCallback, useState } from "react";
import useCartWrapper from "../wrappers/useCartWrapper";
import { Cart } from "../../__domain/model";
import useRequestAuthenticationSetter from "../../components/authentication/hooks/useRequestAuthenticationSetter";
import useTempCartAuthenticationSetter from "../../components/entities/Carts/hooks/useTempCartAuthenticationSetter";

/**
 * @param {{onError: (error: any) => void}} param0
 * @returns
 */
export default function usePaymentApproval({ onError }) {
  const authSetter = useRequestAuthenticationSetter();
  const tempAuthSetter = useTempCartAuthenticationSetter();
  const cartWrapper = useCartWrapper();

  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState(null);

  const reset = useCallback(() => setUrl(null), [setUrl]);
  const cb = useCallback(
    /**
     * @returns
     * @param {Cart} cart
     */
    (cart) => {
      const e = cartWrapper.wrap(cart).execute("default", {}, false);
      const r = e.request();
      tempAuthSetter(r);
      authSetter(r);
      setLoading(true);
      e.json()
        .then(({ data }) => {
          if (data && data.confirmationUrl) {
            setUrl(data.confirmationUrl);
          }
          setLoading(false);
        })
        .catch((e) => {
          onError && onError(e);
          setLoading(false);
        });
    },
    [cartWrapper, authSetter]
  );

  return { onExecute: cb, loading, url, reset };
}
