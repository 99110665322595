import React, { memo, ReactNode, useCallback } from "react";
import { Misc, Authentication } from "@italwebcom/anema-react-components";
import { GenericForm } from "@italwebcom/react-generic-form";
import {
  FormComponent,
  SubmitButton,
  InputComponent,
} from "./DefaultFormComponents";
import countGetter from "../../functions/defaultCountGetter";
import fetchArgsProcessor from "../../functions/fetchArgsProcessor";
import { ActualFetcher } from "@italwebcom/augmented-fetch";
import Loader from "./DefaultLoader";
import ListRenderer from "./DefaultListRenderer";
import timeoutService from "./_auxiliary/timeoutService";
import { makeObservable } from "@italwebcom/observer-utils";
import WebRuntime, { RuntimeContextArgs } from "../../contexts/Runtime";

const {
  RuntimeProvider,
  StorageProvider,
  AlertProvider,
  DefaultComponentsProvider,
} = Misc.Components;

const { AuthenticationProvider } = Authentication.Components;
const { ConfigurationContext } = GenericForm;

const obs = makeObservable({});

/**
 * @param {{
 *      fetcher: ActualFetcher,
 *      wrappers: OperationWrappers,
 *      children: ReactNode[],
 *      storage: Storage,
 *      manager: OpenIDConnectTokenManager,
 *      initialCredentials?: Record<string, any>,
 *      runtimeContextArgs: RuntimeContextArgs
 * }} param0
 * @returns
 */
function ProvidersWrapper({
  children,
  fetcher,
  wrappers,
  storage,
  manager,
  initialCredentials,
  runtimeContextArgs,
  onAlertAdd,
}) {
  const navigate = useCallback(
    ({ to, args }) => {
      if (runtimeContextArgs.Navigation) {
        runtimeContextArgs.Navigation.navigate(to, args);
      }
    },
    [runtimeContextArgs]
  );
  return (
    <WebRuntime.Provider value={runtimeContextArgs}>
      <ConfigurationContext.Provider
        value={{ FormComponent, SubmitButton, InputComponent }}
      >
        <RuntimeProvider
          storage={storage}
          smallScreen
          navigate={navigate}
          cartCodeName="x-cart-code"
          cartIdName="x-cart-id"
          defaultRole="unregistered"
          fetcher={fetcher}
          timeoutService={timeoutService}
          wrappers={wrappers}
        >
          <StorageProvider storage={storage}>
            <AlertProvider onAdd={onAlertAdd}>
              <DefaultComponentsProvider
                fetchArgsProcessor={fetchArgsProcessor}
                defaultCountGetter={countGetter}
                ListCollectionRenderer={ListRenderer}
                Loader={Loader}
              >
                <AuthenticationProvider
                  errorObservable={obs}
                  manager={manager}
                  initialCredentials={initialCredentials}
                  defaultRole="unregistered"
                >
                  {children}
                </AuthenticationProvider>
              </DefaultComponentsProvider>
            </AlertProvider>
          </StorageProvider>
        </RuntimeProvider>
      </ConfigurationContext.Provider>
    </WebRuntime.Provider>
  );
}

export default memo(ProvidersWrapper);
