import context from "../context";
import { useContext, useMemo } from "react";

/**
 * @param {number} elementSpan 
 * @returns 
 */
export default function useImageHeight(elementSpan) {
  const { elementHeight } = useContext(context);
  return useMemo(
    () => `${elementHeight * elementSpan}px`,
    [elementHeight, elementSpan]
  );
}
