import { WindowProcessor } from "@italwebcom/oauth2-client-web";

/**
 * @type {WindowProcessor}
 */
const processor = {
  open(location, options) {
    return window.open(location, "auth", options);
  },
  addEventListener(evt, handler) {
    window.addEventListener(evt, handler);
    return this;
  },
};

export default processor;
