const selectionView = {
  titleOffset: "64px",
  sliderOffset: "110px",
};

const args = {
  appBar: {
    logo: "webapp/img/anema.png",
    backgroundColor: "white",
  },
  contentPanelHeader: {
    fontFamily: "Anema",
    backgroundColor: "rgb(0, 0, 0, 0.75)",
    centeredTitle: true,
  },
  headersTypographyProps: {
    style: {
      fontFamily: "Anema",
    },
  },
  pageHeader: {
    backgroundColor: "#143279",
    maxWidth: "lg",
    titleTypographyProps: {
      style: {
        fontFamily: "Anema",
        color: "white",
      },
    },
  },
  backgroundColor: "#F0F2F5",
  selectionView,
  smallScreen: window.innerWidth <= 512
};

export default args;
