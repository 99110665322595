const links = {
    category: {
        base: "category",
        childrenElements: i => `/category/${i}/children{?sortAttribute,sortDirection,page,size}`
    },
    cart: {
        base: "cart",
        products: i => `/cart/${i}/products{?sortAttribute,sortDirection,page,size}`,
        deliveryZoneCost: i => `/cart/${i}/delivery_zone_cost{?deliveryZone}`,
        addProduct: i => `/cart/${i}/product`,
        self: i => `/cart/${i}`,
        related: {
            product: (c, p) => `/cart/${c}/product/${typeof(p.id) === "number" ? p.id : p}`
        },
        acquire: "/cart/acquire"
    },
    customer: {
        base: "customer",
        self: i => `/customer/${i}`,
        carts: i => `/customer/${i}/carts{?sortAttribute,sortDirection,page,size,status}`,
        notifications: i => `/customer/${i}/notifications{?sortAttribute,sortDirection,page,size}`,
        addNotification: i => `/customer/${i}/notification`,
        token: i => `/customer/${i}/token`,
        activeCart: i => `/customer/${i}/activeCart`,
        notification: {
            base: "notification",
            self: i => `/notification/${i}`
        }
    },
    conversation: {
        base: "conversation",
        messages: i => `/conversation/${i}/messages{?page,size,sortAttribute,sortDirection}`,
        postMessage: i => `/conversation/${i}/message`
    },
    deliveryZone: {
        base: "delivery-zone",
        costs: i => `/delivery-zone/${i}/costs`,
        addCost: i => `/delivery-zone/${i}/cost`
    },
    deliveryCost: {
        base: "delivery-cost",
        discriminators: i => `/delivery-cost/${i}/discriminators`,
        discriminator: i => `/delivery-cost/${i}/discriminator`
    },
    tag: "tag",
    newsItem: "news-item",
    productFeature: {
        base: "product-feature",
        values: i => `/product-feature/${i}/values`,
        addValue: i => `/product-feature/${i}/value`
    },
    product: {
        base: "product",
        categories: i => `/product/${i}/category{?page,size,sortAttribute,sortDirection}`,
        images: i => `/product/${i}/image{?page,size,sortAttribute,sortDirection}`,
        costs: i => `/product/${i}/cost{?page,size,sortAttribute,sortDirection}`,
        addCategory: i => `/product/${i}/category`,
        addImage: i => `/product/${i}/image`,
        related: {
            category: (i, c) => `/product/${i}/category/${c}`,
            cost: (i, c) => `/product/${i}/cost/${c}`,
            image: (i, c) => `/product/${i}/image/${c}`
        }
    },
    message: "message",
    image: "image",
    closingDay: "closing-day",
    openingTime: "opening-time",
    costList: {
        base: "cost-list",
        discriminators: i => `/cost-list/${i}/discriminators`,
        discriminator: i => `/cost-list/${i}/discriminator`
    },
    validityDiscriminators: {
        matchers: i => `/validity-discriminator/${i}/matchers`,
        addMatcher: i => `/validity-discriminator/${i}/matcher`
    },
    self: (entityName, id) => `/${entityName}/${id}`
};

export default links;