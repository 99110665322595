import React, { memo, useContext } from "react";
import context from "../context";
import useStyles from "../styles";
import useRowWidth from "../hooks/useRowWidth";

function ListElement({ children }) {
  const { listElement: listElementStyle } = useStyles();
  const { elementHeight } = useContext(context);
  const width = useRowWidth();
  return (
    <li
      className={listElementStyle}
      style={{ height: `${elementHeight}px`, width }}
    >
      {children}
    </li>
  );
}

export default memo(ListElement);
