import React, { memo } from "react";
import { Tags } from "@italwebcom/anema-react-components";
import { Tag } from "@italwebcom/anema-react-components/Tags";
import { SelectionList } from "./GroupRenderer";
import DefaultErrorPrinter from "../../../_auxiliary/DefaultErrorPrinter";

const { Selector } = Tags.Components;

/**
 * @returns
 * @param {{filterType: string, legend: string, defaultSelected?: Tag[], onChange: (tags: Tag[]) => void}} param0
 */
function TagPanelSelector({ filterType, legend, onChange, defaultSelected }) {
  return (
    <Selector.Provider onChange={onChange} defaultSelected={defaultSelected}>
      <Selector.Group
        filterType={filterType}
        Renderer={SelectionList}
        lazyCountRecompute
        initialItemsPerPage={9999}
      />
    </Selector.Provider>
  );
}

export default memo(TagPanelSelector);
