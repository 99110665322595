import React, { memo } from "react";
import GenericInput from "../GenericInput";
import { fieldNames } from "../../fields";

/**
 * @returns
 */
function NotesInput({InputComponent}) {
  return (
    <GenericInput
      field={fieldNames.notes}
      InputProps={{ "data-testid": "notes" }}
      InputComponent={InputComponent}
    />
  );
}

export default memo(NotesInput);