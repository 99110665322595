import { Grid } from "@material-ui/core";
import React, { memo, useCallback } from "react";
import Image from "../Image";
import {GalleryImage} from "../../defines";

/**
 * @param {{image: GalleryImage, selected?: GalleryImage, onSelect: (img: GalleryImage) => void}} param0 
 * @returns 
 */
function ImageRenderer({ image, selected, onSelect }) {
  const { name, src } = image;
  const onActualSelect = useCallback(() => onSelect(image), [image, onSelect]);
  return (
    <Grid key={name} item md={3} sm={4} xs={6}>
      <Image
        src={src}
        alt={name}
        onSelect={onActualSelect}
        selected={selected && selected.name === name}
      />
    </Grid>
  );
}

export default memo(ImageRenderer);
