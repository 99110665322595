import { useDeliveryZoneCost } from "../../../DeliveryZones/DeliveryZoneCostProvider/hooks";
import React, { memo, ComponentType } from "react";

/**
 * @typedef {{loading: boolean, cost: number | string}} PrinterProps
 * @typedef {ComponentType<PrinterProps>} Printer
 * @param {{Component: Printer, ComponentProps?: Record<string, any>}} param0
 * @returns
 */
function DeliveryZoneCostPrinter({ Component, ComponentProps }) {
  const { cost, loading } = useDeliveryZoneCost();
  if (typeof cost !== "undefined") {
    return (
      <Component loading={loading} cost={cost} {...(ComponentProps || {})} />
    );
  }
  return null;
}

export default memo(DeliveryZoneCostPrinter);
