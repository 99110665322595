import { useMemo } from "react";


/**
 * @typedef {{
 *      valid: boolean,
 *      invalidRule: string,
 *      invalidMessage: string
 * }} ValidationResult
 */

/**
 * @template T
 * @template {string} Attributes
 * @param {T} element
 * @param {ValidationRule<Attributes>[]} rules
 * @return {ValidationResult}
 */
const onValidate = (element, rules) => {
  rules = rules.map((rule) => ({ priority: 0, ...rule }));
  const sortedRules = rules.sort((a, b) => a.priority - b.priority);
  let out = { valid: true, invalidRule: "", invalidMessage: "" };
  let i = 0;
  while (i < sortedRules.length && out.valid) {
    const cRule = sortedRules[i];
    const { validator, attribute, type, label, invalidMessage } = cRule;
    let valid;
    if (validator instanceof RegExp) {
      valid = validator.test(element[attribute]);
    } else {
      if (type === "global") {
        valid = validator(element);
      } else {
        valid = validator(element[attribute], element);
      }
    }
    if (!valid) {
      out = {
        valid: false,
        invalidRule: label,
        invalidMessage,
        invalidAttribute: attribute,
      };
    }
    i++;
  }
  return out;
};

/**
 * @template {string} Attributes
 * @typedef {{
 *      validator: Function|RegExp
 *      type: "global" | "attribute",
 *      attribute?: Attributes,
 *      invalidMessage: string,
 *      label: string,
 *      priority?: number
 * }} ValidationRule
 */

/**
 * @template T
 * @template {string} Attributes
 * @param {T} element
 * @param {ValidationRule<Attributes>[]} rules
 * @return {ValidationResult}
 */
export function useValidator(element, rules) {
  return useMemo(() => onValidate(element, rules), [element, rules]);
}

/**
 * @param {ValidationRule[]} rules
 * @returns
 */
export default function makeHook(rules) {
  return (element) => useValidator(element, rules);
}
