import { CustomerEditor, CustomerDashboard } from "./customers";
import {
  CartCustomerEditor,
  CartCustomerPayer,
  CartPaymentMethodSetter,
  CartCustomerRenderer,
  CustomerActiveCartRenderer,
  DetailedCustomerCartList
} from "./carts";
import { DeliveryZoneSelector } from "./deliveryzones";
import { TagChipCollection, TagTabsSelector, TagPanelSelector } from "./tags";
import { CustomerConversationManager } from "./conversations";
import { CustomerNotificationList } from "./notifications";
import { CustomerNewsItemsRenderer } from "./newsitems";
import { CustomerProductSelectionView, CustomerProductSectionedSelectionView } from "./products";

export const carts = {
  CartCustomerEditor,
  CartCustomerPayer,
  CartPaymentMethodSetter,
  CustomerActiveCartRenderer,
  CartCustomerRenderer,
  DetailedCustomerCartList
};
export const customers = { CustomerEditor, CustomerDashboard };
export const deliveryZones = { DeliveryZoneSelector };
export const tags = { TagChipCollection, TagTabsSelector, TagPanelSelector };
export const conversations = { CustomerConversationManager };
export const notifications = { CustomerNotificationList };
export const newsItems = { CustomerNewsItemsRenderer };
export const products = { CustomerProductSelectionView, CustomerProductSectionedSelectionView };
