import React, { useCallback } from "react";
import { useEntityManagement, FetchResult, FetcherInputs } from "@italwebcom/custom-react-hooks";
import { BaseEntityManagerProps } from "../defines";
import { CrudOperationsWrapper, CrudOperation } from "@italwebcom/crud-operations-wrapper";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import useAdditionalFunctions from "../../hooks/useAdditionalFunctions";
import BlockingLoader from "../../Misc/BlockingLoader";
import useConfirmationDialog from "../../hooks/useDialogConfirm";

const useStyles = makeStyles(theme => ({
    wrapper: {
        position: "relative"
    }
}));

const getFirst = e => (e instanceof Array) ? e[0] : e;

/**
 * @template Entity
 * @template Indices
 * @template Sorters
 * @typedef {{
 *      wrapper: CrudOperationsWrapper<Entity, CrudOperation>,
 *      countGetter: (result: FetchResult<Entity>) => number,
 *      onFetch?: (args: FetcherInputs<Indices, Sorters>) => Promise<FetchResult<Entity>>,
 *      onUpdate?: (element: Entity) => Promise<FetchResult<Entity>>|void,
 *      onDelete?: (element: Entity) => Promise<FetchResult<Entity>>,
 *      fetchArgsProcessor?: (args: Record<string, any>) => Record<string, any>,
 *      noPagination: boolean
 * }} CrudEntityManagerAdditionalProps
 */

/**
 * @template Entity
 * @template Indices
 * @template Sorters
 * @typedef {BaseEntityManagerProps<Entity, Indices, Sorters> & CrudEntityManagerAdditionalProps<Entity, Indices, Sorters>} CrudEntityManagerProps
 */

/**
 * @template Entity
 * @template Indices
 * @template Sorters
 * @param {CrudEntityManagerProps<Entity, Indices, Sorters>} param0 
 */
export function CrudEntityManager({
    wrapper,
    onFetch,
    onSelect,
    onUpdate,
    onDelete,
    onError,
    Renderer,
    RendererProps,
    initialFilters,
    initialSorter,
    searchAttribute,
    attributes,
    keyExtractor,
    countGetter,
    initialItemsPerPage,
    fetchArgsProcessor,
    noPagination
}) {

    const {wrapper: wrapperStyle} = useStyles();
    const {rendered, onTrigger} = useConfirmationDialog({
        title: "Conferma cancellazione", 
        message: "Confermare la cancellazione dell'elemento?",
        abortLabel: "Annulla",
        confirmLabel: "Conferma"
    });

    const onActualFetch = useCallback(args => {
        if(fetchArgsProcessor) {
            args = fetchArgsProcessor(args);
        }
        if(onFetch) {
            return onFetch(args);
        } else {
            return wrapper.fetch(args).json();
        }
    }, [wrapper, fetchArgsProcessor, onFetch]);

    const onActualDelete = useCallback(async element => {
        const trigStatus = await onTrigger();
        if(trigStatus === "confirmed") {
            if(onDelete) {
                return onDelete(getFirst(element));
            } else {
                return wrapper.wrap(getFirst(element)).delete().json();
            }
        }
        return false;
    }, [wrapper, onTrigger, onDelete]);

    const onActualUpdate = useCallback(async element => {
        if(onUpdate) {
            return onUpdate(element);
        } else {
            return wrapper.update().json();
        }
    }, [onUpdate, wrapper]);

    const {
        count,
        loading,
        elements,
        sorter,
        onDelete: onDeleteCallback,
        onSave,
        onFilterReplace,
        onFilterAdd,
        onFilterRemove,
        onPageChange,
        onSorterChange
    } = useEntityManagement({
        onError,
        onFetch: onActualFetch,
        onDelete: onActualDelete,
        onSave: onActualUpdate,
        initialCount: 0,
        initialItemsPerPage,
        initialFilters,
        initialSorter,
        countGetter,
        config: {
            filterMatcher: (a, b) => a.attribute === b.attribute
        }
    });

    const {pages, pageChangeCallback, onSearch} = useAdditionalFunctions({ initialItemsPerPage, count, onFilterReplace, onPageChange, searchAttribute });
    //console.log(`pages = ${pages}`);
    const renderedCollection = <Renderer
        {...RendererProps}
        elements={elements}
        onSearch={onSearch}
        onSortChange={(attribute, direction) => onSorterChange({attribute, direction})}
        sortAttribute={sorter && sorter.attribute}
        sortDirection={sorter && sorter.direction}
        onFilterAdd={onFilterAdd}
        onFilterRemove={onFilterRemove}
        onFilterReplace={onFilterReplace}
        attributes={attributes}
        onSelect={onSelect}
        onSave={onSave}
        keyExtractor={keyExtractor}
        actions={[
            {
                id: "delete",
                label: "Cancella",
                onExecute: onDeleteCallback,
                batch: false
            },
            {
                id: "update",
                label: "Modifica",
                onExecute: onSave,
                batch: false
            }
        ]}
    />;
    return <Box className={wrapperStyle}>
        {rendered}
        <BlockingLoader in={loading} />
        {noPagination ? renderedCollection : <Grid container spacing={2}>
            <Grid item xs={12}>
                {renderedCollection}
            </Grid>
            <Grid item xs={12} container justifyContent="center">
                <Grid item>
                    <Pagination
                        count={pages} 
                        size="medium"
                        onChange={pageChangeCallback} 
                        showLastButton 
                        showFirstButton 
                    />
                </Grid>
            </Grid>
        </Grid>}
    </Box>
}