import {useRef, useCallback} from "react";

/**
 * @param {number} initialValue 
 * @returns 
 */
export default function useAlertContainerKey(initialValue) {
    const keyRef = useRef(initialValue || 0);
    const useKey = useCallback(
        /**
         * @param {(key: number) => void} cback 
         */
        (cback) => {
            let v = cback(keyRef.current);
            keyRef.current++;
            return v;
        }, 
        [keyRef]
    );
    return useKey;
}