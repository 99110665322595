import React, { memo } from "react";
import { ItemRendererActionContent as ActionContent, getImageUrl } from "../_Auxiliary";
import { Carts } from "@italwebcom/anema-react-components";
import { Product } from "@italwebcom/anema-react-components/Products";
import { HulkCard } from "@italwebcom/misc-ui-components";

const {
  useCartAndProducts,
  useCartDerivedAttributes,
  useCartProductSelection,
} = Carts.hooks;

/**
 * @param {{
 *      element: Product,
 *      actionLabel?: string,
 *      onSelect: (product: Product) => void
 * }} param0
 * @returns
 */
const CardItemRenderer = ({ element, actionLabel, onSelect }) => {
  const { cart } = useCartAndProducts();

  actionLabel = actionLabel || `Seleziona`;

  const {
    selectedElement,
    onAddToCart: onActualSelect,
    onDelete: onActualDelete,
    onQuantityChange: onActualChange,
  } = useCartProductSelection(element);
  const isSelected = selectedElement && selectedElement.quantity;
  const { editable } = useCartDerivedAttributes(cart);

  return (
    <HulkCard
        onAction={onSelect || (!isSelected && editable && onActualSelect)}
        avatar={getImageUrl(element)}
        title={element.name}
        subtitle={element.description}
        actionLabel={actionLabel}
        actionVariant={isSelected ? "dark" : "light"}
        avatarHeight="150"
        avatarWidth="150"
        avatarStyle={{ margin: "auto" }}
        titleTypographyProps={{ variant: "body1" }}
        subtitleTypographyProps={{ variant: "body2" }}
        /*wrapperStyle={{
          boxShadow: "unset",
          border: "1px solid rgb(244, 244, 244)",
          paddingTop: "1rem",
        }}*/
        actionRightContent={
          <ActionContent
            selectedElement={selectedElement}
            element={element}
            onDelete={onActualDelete}
            onChange={onActualChange}
            isSelected={isSelected}
            costs
          />
        }
      />
  );
};

export default memo(CardItemRenderer);
