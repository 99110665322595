import React from "react";
import { Outlet, Route } from "react-router";
import {
  Homepage,
  CartOverview,
  ProductSelection,
  UserAreaDashboard,
  UserAreaChat,
  UserAreaEdit,
  UserAreaReservations,
  LoadingPage,
} from "../pages";

function routes() {
  return (
    <Route path="/" element={<LoadingPage />}>
      <Route path="" element={<Homepage />} />
      <Route path="home" element={<Homepage />} />
      <Route path="cart" element={<Outlet />}>
        <Route path="" element={<CartOverview />} />
        <Route path=":id" element={<Outlet />}>
          <Route path="" element={<CartOverview />} />
        </Route>
      </Route>
      <Route path="menu" element={<ProductSelection />} />
      <Route path="user" element={<Outlet />}>
        <Route path="" element={<UserAreaDashboard />} />
        <Route path="dash" element={<Outlet />}>
          <Route path="" element={<UserAreaDashboard />} />
          <Route path="*" element={<UserAreaDashboard />} />
        </Route>
        <Route path="chat" element={<UserAreaChat />} />
        <Route path="edit" element={<UserAreaEdit />} />
        <Route path="carts" element={<Outlet />}>
          <Route path="" element={<UserAreaReservations />} />
          <Route path=":id" element={<Outlet />}>
            <Route path="" element={<CartOverview />} />
          </Route>
        </Route>
      </Route>
    </Route>
  );
}

export default routes;
