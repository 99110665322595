import { ProductImage } from "../../model";
import images from "./images";
import products from "./products";
import wrappers from "../hateoas";

const nImgs = 9;

/**
 * @type {ProductImage[]}
 */
const productImages = [];

for (let i = 0; i < products.length; i++) {
  for(let v = 0; v < nImgs; v++) {
    productImages.push(
      wrappers.productImage({
        image: 10 + v,
        product: i,
        ...images[10 + v],
      })
    );
  }
}

export default productImages;
