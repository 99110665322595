import React, { memo, ReactNode, CSSProperties, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import clsx from "clsx";
import Check from "@material-ui/icons/Check";
import useInputWrapperGroup from "../hooks/useInputWrapperGroup";
import { colors } from "../../../../../../../themes/anema";
import PlaceholderWrapper from "../../../../../../PlaceholderWrapper";

const s = makeStyles((theme) => ({
  wrapper: {
    transitionProperty: "opacity",
    transitionDuration: "500ms",
  },
  opaque: {
    opacity: 0.25,
  },
  label: {
    color: colors.grey.primary,
    fontWeight: "bold",
  },
  titleGroup: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
  valueStyle: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  contentWrapper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

function renderButton({ onClick, label, color, fullWidth, variant, disabled }) {
  return (
    <Button
      color={color}
      variant={variant || "outlined"}
      onClick={onClick}
      size="small"
      fullWidth={fullWidth}
      disabled={disabled}
    >
      {label}
    </Button>
  );
}

function renderSideButton({ value, onEdit, onAbort, open, readOnly }) {
  if (!readOnly) {
    if (open) {
      if (!value) {
        return renderButton({
          onClick: onAbort,
          label: "Annulla",
          color: "secondary",
        });
      }
    } else {
      if (value) {
        return renderButton({
          onClick: onEdit,
          label: "Modifica",
          color: "primary",
          variant: "text",
        });
      }
    }
  }
  return null;
}

function renderMainButtonOrValue({ onEdit, open, value, readOnly, disabled }) {
  const { valueStyle } = s();
  if (!open) {
    if (readOnly || value) {
      return (
        <Typography
          variant="body1"
          component="p"
          fullWidth
          className={valueStyle}
        >
          {value || "-"}
        </Typography>
      );
    } else {
      if(!readOnly) {
        return renderButton({
          fullWidth: true,
          onClick: onEdit,
          label: "Seleziona",
          color: "primary",
          disabled,
        });
      }
    }
  }
  return null;
}

/**
 *
 * @param {{
 *      label: string,
 *      value: any,
 *      children: ReactNode[],
 *      style: (open: boolean) => CSSProperties
 * }} param0
 */
function InputWrapper({
  label,
  value,
  style,
  children,
  id,
  onConfirm,
  disabled,
}) {
  const {
    label: labelStyle,
    titleGroup,
    divider,
    contentWrapper,
    opaque,
    wrapper,
  } = s();
  const { selected, setSelected, readOnly, placeholder } =
    useInputWrapperGroup();
  const { open, onEdit, onAbort } = useMemo(
    () => ({
      open: id === selected,
      onEdit: () => setSelected(id),
      onAbort: () => {
        onConfirm && onConfirm();
        setSelected(null);
      },
    }),
    [selected, setSelected, id, onConfirm]
  );
  return (
    <PlaceholderWrapper active={placeholder}>
      <Box
        className={clsx([open && "open", selected && !open && opaque, wrapper])}
        style={style && style(open)}
      >
        <Box className={titleGroup}>
          <Typography component="h5" variant="body1" className={labelStyle}>
            {label}
          </Typography>
          <Box marginLeft="0.5rem">
            {renderSideButton({ open, onEdit, onAbort, value, readOnly })}
          </Box>
        </Box>
        <Collapse in={open}>
          {open && <Divider className={divider} />}
          <Box className={contentWrapper}>{children}</Box>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            fullWidth
            startIcon={<Check />}
            onClick={onAbort}
            disabled={!value || disabled}
          >
            Conferma
          </Button>
        </Collapse>
        <Collapse in={!open}>
          {renderMainButtonOrValue({
            open,
            onEdit,
            value,
            children,
            readOnly,
            disabled,
          })}
        </Collapse>
      </Box>
    </PlaceholderWrapper>
  );
}

export default memo(InputWrapper);
