import React, { memo, useCallback } from "react";
import CartOverviewEditor from "../../components/_entities/carts/CartOverviewEditor";
import PageWrapper from "../__auxiliary__/PageWrapper";
import { Carts, DeliveryZones } from "@italwebcom/anema-react-components";
import { useRuntimeContext } from "../../contexts";

const { DynamicCartAndProductsProvider } = Carts.Components;
const { Provider: DeliveryZoneProvider } = DeliveryZones.Components;

/**
 * @param {{title: string, cart?: number}} param0
 * @returns
 */
function CartOverview({ title, cart, noHeader, alertOnTypeChange }) {
  const { Navigation, viewNames, smallScreen, stylings } = useRuntimeContext();
  const { contentPanelHeader } = stylings.general;
  const onActualCartProductAdd = useCallback(
    () => Navigation.navigate(viewNames.menuSelection, { cart }),
    [cart, Navigation, viewNames]
  );
  return (
    <DynamicCartAndProductsProvider id={cart} redirectOnSave>
      <DeliveryZoneProvider>
        <PageWrapper
          title={title || "La tua prenotazione"}
          cart={cart}
          viewName="cartOverview"
          noHeader={noHeader}
        >
          <CartOverviewEditor
            onCartProductAdd={onActualCartProductAdd}
            headerProps={contentPanelHeader}
            smallScreen={smallScreen}
            alertOnTypeChange={alertOnTypeChange}
          />
        </PageWrapper>
      </DeliveryZoneProvider>
    </DynamicCartAndProductsProvider>
  );
}

export default memo(CartOverview);
