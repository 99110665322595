import {
  OperationDescriptor,
  OperationLinksGetter,
  OperationLinksSetter,
  HttpMethod,
  OperationParameter,
} from "../defines";

let parsRegex = new RegExp(/({\?)[a-zA-Z0-9\,\_]+(})/g);
let pathVarRegex = new RegExp(/({)[^\?][a-zA-Z0-9]+(})/g);

/**
 * @returns
 * @param {string} link
 */
export function getCleanLink(link) {
  return link.replace(parsRegex, "").replace("{?", "");
}

/**
 * @returns
 * @param {string} link
 */
export function getParameters(link) {
  /**
   * @type {OperationParameter[]}
   */
  let out = [];
  let pathPars = link.matchAll(pathVarRegex);
  let queryPars = link.matchAll(parsRegex);
  for (let pathPar of pathPars) {
    out.push({
      name: pathPar[0].replace("{", "").replace("}", ""),
      type: "text",
      scope: "path",
    });
  }
  for (let queryPar of queryPars) {
    let q = queryPar[0].replace("{?", "").replace("}", "").split(",");
    for (let v of q) {
      out.push({
        name: v,
        type: "text",
        scope: "query",
      });
    }
  }
  return out;
}

/**
 * @typedef {{
 *      name: string,
 *      type: string,
 *      readOnly?: boolean
 * }} HateoasOperationProperty
 *
 * @typedef {{
 *      method: HttpMethod,
 *      target?: string,
 *      properties?: HateoasOperationProperty[]
 * }} HateoasOperationTemplate
 *
 * @typedef {{
 *      href: string,
 *      templated?: boolean
 * }} HateoasOperationLink
 *
 * @typedef {{
 *      _links: Record<string, HateoasOperationLink>,
 *      _templates: Record<string, HateoasOperationTemplate>
 * }} HateoasData
 */

/**
 * @template {HateoasData} T
 */
export class HateoasLinksManager {
  /**
   * @type {string}
   */
  _defaultUrlName;

  /**
   * @param {string} defaultUrlName
   */
  constructor(defaultUrlName) {
    this._defaultUrlName = defaultUrlName;
    this._getter = this.getter.bind(this);
    this._setter = this.setter.bind(this);
  }

  /**
   * @return {OperationDescriptor[]}
   * @param {T} data
   */
  getter(data) {
    /**
     * @type {OperationDescriptor[]}
     */
    let out = [];
    const links = data._links || {};
    const templates = data._templates;

    /* base url used for operations without target */
    const d = links[this._defaultUrlName];
    const baseUrl = d ? getCleanLink(d.href) : "";

    Object.getOwnPropertyNames(links).forEach((n) => {
      const link = links[n];
      if (link.href) {
        out.push({
          method: "GET",
          parameters: link.templated ? getParameters(link.href) : [],
          id: n,
          url: getCleanLink(link.href),
        });
      }
    });

    if(templates) {
      Object.getOwnPropertyNames(templates).forEach((n) => {
        const template = templates[n];
        out.push({
          url: template.target || baseUrl,
          method: template.method,
          parameters: template.properties,
          id: n,
        });
      });
    }

    return out;
  }

  setter(data, links) {}
}

/**
 * @template T
 * @returns {{
 *      manager: HateoasLinksManager<T>,
 *      getter: OperationLinksGetter<T>,
 *      setter: OperationLinksSetter<T>,
 * }}
 * @param {string} defaultUrlName
 */
export default function make(defaultUrlName) {
  /* istanbul ignore next */
  defaultUrlName = defaultUrlName || "self";
  const manager = new HateoasLinksManager(defaultUrlName);
  return {
    manager,
    setter: manager._setter,
    getter: manager._getter,
  };
}
