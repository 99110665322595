import { memo, useContext } from "react";
import EntityManagerContext from "../context";
import { ifNotUndef } from "../auxiliary";
import { SearchBar, EntityManagerWrapperProps } from "../defines";

/**
 * @param {EntityManagerWrapperProps<SearchBar>} param0
 */
function SearchBarWrapper({ Component }) {
  const { onSearch } = useContext(EntityManagerContext);
  return ifNotUndef(Component, { onSearch });
}

export default memo(SearchBarWrapper);