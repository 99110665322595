import React, { memo } from "react";
import { useCartProvider, TempCartProvider } from "../../../CartProvider";
import { TempCartProductProvider } from "../../../CartProductProvider";

const InnerCartProductProvider = memo(
  /**
   * @param {{children: ReactChildren}} param0
   * @returns
   */
  ({ children }) => {
    const { cart } = useCartProvider();
    return <TempCartProductProvider cart={cart}>{children}</TempCartProductProvider>;
  }
);

/**
 * @returns
 * @param {{cart: number, children: ReactChildren}} param0
 */
function TempCartAndProductsProvider({ cart, children }) {
  return (
    <TempCartProvider cart={cart} asCustomer>
      <InnerCartProductProvider>{children}</InnerCartProductProvider>
    </TempCartProvider>
  );
}

export {InnerCartProductProvider};
export default memo(TempCartAndProductsProvider);
