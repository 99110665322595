import { Carts } from "@italwebcom/anema-react-components";
import React, { memo } from "react";
import { NonWrappedDefaultErrorPrinter } from "../../../../_auxiliary/DefaultErrorPrinter";

const { useCartAndProducts } = Carts.hooks;

function CartErrorPrinter() {
  const { cartErrored, onCartRefresh } = useCartAndProducts();
  if (cartErrored) {
    return <NonWrappedDefaultErrorPrinter onRefreshAll={onCartRefresh} />;
  }
  return null;
}

export default memo(CartErrorPrinter);
