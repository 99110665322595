import React, { memo, useCallback } from "react";
import AlternateListRenderer from "../../../../../AlternateListRenderer";
import {
  getImageUrl,
  ItemRendererActionContent as ActionContent,
  QuantityChip,
  CostChip,
} from "../_Auxiliary";
import { Carts } from "@italwebcom/anema-react-components";
import { Product } from "@italwebcom/anema-react-components/Products";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import trim from "../../../../../../functions/trim";

const {
  useCartAndProducts,
  useCartDerivedAttributes,
  useCartProductSelection,
} = Carts.hooks;

const s = makeStyles((theme) => ({
  opaqueElement: {
    opacity: 0.35,
  },
}));

/**
 * @param {{
 *      element: Product,
 *      actionLabel?: string,
 *      onSelect: (product: Product) => void,
 *      placeholder?: boolean
 * }} param0
 * @returns
 */
const ListItemRenderer = ({
  element,
  actionLabel,
  onSelect,
  style,
  placeholder,
  inSel
}) => {
  actionLabel = actionLabel || `Seleziona`;

  const { selectedElement } = useCartProductSelection(element);
  inSel = inSel || selectedElement;

  const isSelected = inSel && inSel.quantity;
  const onActualSelect = useCallback(
    (evt) => !placeholder && onSelect(element, evt.currentTarget),
    [onSelect, element, placeholder]
  );
  const { opaqueElement } = s();

  return (
    <AlternateListRenderer
      image={getImageUrl(element)}
      primary={element.name}
      secondary={trim(element.description, 50)}
      style={style}
      onClick={onActualSelect}
      placeholder={placeholder}
      className={clsx([element && element.unavailable && opaqueElement])}
      additionalContent={
        isSelected ? (
          <QuantityChip selectedElement={inSel} />
        ) : (
          <CostChip element={element} />
        )
      }
    />
  );
};

export default memo(ListItemRenderer);
