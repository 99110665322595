import { useImperativeHandle, useRef } from "react";

export default function useRefreshHandler(outerRef) {
  const innerRef = useRef();
  useImperativeHandle(
    outerRef,
    () => ({
      refresh: () => {
        if(innerRef.current) {
          innerRef.current.refresh();
        }
      },
    }),
    [innerRef]
  );
  return innerRef;
}
