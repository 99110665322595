import { ComponentType } from "react";
import { UnaryOperator, Filter, FetchResult, FetcherInputs, EntityManagementError } from "@italwebcom/custom-react-hooks";

/**
 * @template Roles
 * @template Entity
 * @typedef {{
 *      id: string,
 *      type: Roles,
 *      label?: string,
 *      renderer?: (element: Entity) => ReactNode,
 *      sortable?: boolean
 * }} CollectionAttributeRenderer<Roles, Entity>
 */

/**
 * @template Entity
 * @typedef {{
 *      onSave: (entity: Entity) => Promise<any>,
 *      onDelete: (entity: Entity) => Promise<any>,
 *      onRefreshAll: Function,
 *      onSetSelectedEntity: (entity: Entity) => void
 * }} CrudCollectionCallbacks
 */

/**
 * @template Entity
 * @typedef {{
 *      id: string,
 *      label?: string,
 *      batch?: boolean,
 *      onExecute: (elements: Entity[], callbacks: CrudCollectionCallbacks<Entity>) => any,
 *      requireConfirmation?: boolean,
 *      confirmationMessage?: string | (elements: Entity[]) => string
 * }} CrudCollectionAction
 **/

/**
 * @template {string} Indices
 * @typedef {{
 *      onFilterRemove: UnaryOperator<Filter<Indices>>,
 *      onFilterAdd: UnaryOperator<Filter<Indices>>,
 *      onFiltersSetAll: UnaryOperator<Filter<Indices>[]>,
 *      onFiltersClear: Function,
 *      onFilterReplace: (target: Filter<Indices>, filter: Filter<Indices>) => void,
 *      filters: Filter<Indices>[]
 * }} FiltererProps
 */

/**
 * @template {string} Sorters
 * @typedef {{
 *      sorter: Sorter2,
 *      onSorterClear: Function,
 *      onSorterChange: (target: Sorter) => void
 * }} SorterProps
 */

/**
 * @typedef {{
 *      loading: boolean
 * }} LoaderProps
 */

/**
 * @typedef {{
 *      pages: number,
 *      page: number,
 *      onPageChange: (page: number) => void
 * }} PaginatorProps
 */

/**
 * @typedef {{
 *      onSearch: (query: string) => void
 * }} SearchBarProps
 */

/**
 * @typedef {{
 *      onAdd: (args: any) => void
 * }} AdderProps
 */

/**
 * @template Entity
 * @typedef {{
 *      elements: Entity[],
 *      attributes?: CollectionAttributeRenderer<any, Entity>[],
 *      onDelete?: (entity: Entity) => Promise<any>,
 *      errored: boolean
 * }} CollectionRendererProps
 */

/**
 * @template Entity
 * @typedef {{
 *      entity: Entity,
 *      onSubmit: (element: Entity) => void
 * }} EditorProps
 */

/**
 * @template Entity
 * @typedef {{
 *      defaultFeedbackMessage?: string,
 *      errorHandler?: (error: EntityManagementError) => string,
 *      actions?: CrudCollectionAction<any>[],
 *      selectedEntity?: Entity,
 *      onSave: (entity: Entity) => void,
 *      manageEntitySelection: boolean,
 *      error?: EntityManagementError
 * }} AdditionalEntityManagerProps
 */

/**
 * @template Entity
 * @template {string} Indices
 * @template {string} Sorters
 * @typedef {LoaderProps | PaginatorProps | SorterProps<Sorters> | FiltererProps<Indices> | SearchBarProps | CollectionRendererProps<Entity> | AdditionalEntityManagerProps<Entity>} EntityManagerContextArgs
 */

/**
 * @template Entity
 * @template Indices
 * @template Sorters
 * @typedef {{
 *      onFetch: (args: FetcherInputs<Indices, Sorters>) => Promise<FetchResult<Entity>>,
 *      onError: (error: EntityManagementError) => void
 *      initialFilters?: Filter<Indices>[],
 *      initialSorter?: Sorter<Sorters>,
 *      initialItemsPerPage?: number,
 *      attributes: CollectionAttributeRenderer<any, Entity>[]
 * }} BaseEntityManagerProps
 */

/**
 * @template Entity
 * @template Indices
 * @template Sorters
 * @typedef {{
 *      onSave: (entity: Entity) => Promise<any>,
 *      onDelete: (entity: Entity) => Promise<any>,
 *      defaultFeedbackMessage?: string,
 *      errorHandler?: (error: EntityManagementError) => string,
 *      actions?: CrudCollectionAction<Entity>[],
 *      manageEntitySelection?: boolean,
 *      entityComparer: (a: Entity, b: Entity) => boolean
 * }} AdditionalCrudEntityManagerProps
 **/

/**
 * @template Entity
 * @template Indices
 * @template Sorters
 * @typedef {BaseEntityManagerProps<Entity, Indices, Sorters> & AdditionalCrudEntityManagerProps<Entity, Indices, Sorters>} CrudEntityManagerProps
 */

/**
 * @typedef {ComponentType<LoaderProps>} Loader
 * @typedef {ComponentType<PaginatorProps>} Paginator
 * @typedef {ComponentType<SorterProps<any>>} Sorter
 * @typedef {ComponentType<FiltererProps<any>>} Filterer
 * @typedef {ComponentType<SearchBarProps>} SearchBar
 * @typedef {ComponentType<EditorProps<any>>} Editor
 * @typedef {ComponentType<AdderProps>} Adder
 * @typedef {ComponentType<CollectionRendererProps<any>>} CollectionRenderer
 * @typedef {ComponentType<BaseEntityManagerProps<any, any, any>>} ReadOnlyEntityManager
 * @typedef {ComponentType<CrudEntityManagerProps<any, any, any>>} CrudEntityManager
 */

/**
 * @template T
 * @template {ComponentType<T>} U 
 * @typedef {{Component: U, ComponentProps?: T}} EntityManagerWrapperProps
 */

/**
 * @typedef {{
 *      Loader: ComponentType<EntityManagerWrapperProps<LoaderProps, Loader>>,
 *      Paginator: ComponentType<EntityManagerWrapperProps<PaginatorProps, Paginator>>,
 *      Sorter: ComponentType<EntityManagerWrapperProps<SorterProps<any>, Sorter>>,
 *      Filterer: ComponentType<EntityManagerWrapperProps<FiltererProps<any>, Filterer>>,
 *      SearchBar: ComponentType<EntityManagerWrapperProps<SearchBarProps, SearchBar>>,
 *      CollectionRenderer: ComponentType<EntityManagerWrapperProps<CollectionRendererProps<any>, CollectionRenderer>>,
 *      Editor: ComponentType<EntityManagerWrapperProps<EditorProps<any>, Editor>>,
 *      Adder: ComponentType<EntityManagerWrapperProps<AdderProps, Adder>>
 * }} EntityManagerComponents
 * 
 * @typedef {{
 *      ReadOnly: ReadOnlyEntityManager,
 *      Crud: CrudEntityManager,
 *      Components: EntityManagerComponents
 * }} EntityManager
 */

const out = {};
export default out;