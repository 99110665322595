import React from "react";
import { Customer } from "../../../../model";
import { HulkUserProfile } from "@italwebcom/misc-ui-components";
import { DashboardList } from "../../carts/CartCustomerList";
import CustomerNotificationList from "../../notifications/CustomerNotificationList";
import CustomerConversationManager from "../../conversations/CustomerConversationManager";
import "./animation.css";
import ActiveCartRenderer from "../../carts/CustomerActiveCartRenderer";

export function ActualActiveCartRenderer() {
  const { cart } = useCartProvider();
  return cart && <ActiveCartRenderer onSelect={() => null} fab />;
}

/**
 * @param {{customer: Customer}} param0
 * @returns
 */
export default function HulkCustomerRenderer({
  customer,
  onEdit,
  additionalContent,
}) {
  return (
    <HulkUserProfile
      avatar={customer.avatar}
      name={customer.username || customer.name}
      role="Utente standard"
      profileData={[
        {
          title: "Dati",
          elements: [
            {
              name: "Nome",
              value: customer.fullName || customer.full_name,
            },
            {
              name: "Email",
              value: customer.email || "---",
            },
            {
              name: "Telefono",
              value: customer.phone,
            },
            {
              name: "Indirizzo",
              value: customer.address,
            },
          ],
        },
      ]}
      mainContent={[
        {
          title: "Ultime prenotazioni",
          content: (
            <DashboardList
              customer={customer}
              onRedirectToEdit={() => null}
              onRedirectToOverview={() => null}
            />
          ),
        },
        {
          title: "Chat",
          content: (
            <CustomerConversationManager
              id={customer.mainConversation && customer.mainConversation.id}
              readOnly
            />
          ),
        },
      ].concat(additionalContent || [])}
      profileStats={[]}
      sideContent={[
        {
          title: "Notifiche",
          content: <CustomerNotificationList customer={customer} />,
        },
      ]}
      onEditData={onEdit}
      stickySideContent
    />
  );
}
