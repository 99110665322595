import clsx from "clsx";
import React, { memo, useCallback, useMemo } from "react";
import {makeStyles} from "@material-ui/core"
import Box from "@material-ui/core/Box";

function r(n) {
  let o = [];
  for (let i = 0; i < n; i++) {
    o.push(i);
  }
  return o;
}

const useStyles = makeStyles((theme) => ({
  dot: {
    height: "16px",
    width: "16px",
    borderRadius: "50%",
    cursor: "pointer",
    boxSizing: "border-box",
    "&:not(.filled)": {
      borderWidth: 1,
      borderStyle: "solid",
    },
  },
  dotWrapper: {
    display: "flex",
    margin: "auto"
  },
  dotWrapperContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

function Dot({ onChange, selected, value, dotSize, color }) {
  const { dot: dotStyle } = useStyles();
  const onActualChange = useCallback(() => onChange(value), [value, onChange]);
  const isSelected = useMemo(() => selected === value, [selected, value]);
  return (
    <Box
      className={clsx([dotStyle, isSelected && "filled"])}
      role="button"
      onClick={onActualChange}
      style={{
        height: `${dotSize}px`,
        width: `${dotSize}px`,
        color,
        backgroundColor: isSelected ? color : undefined,
      }}
    />
  );
}

function CarouselDots({ selected, number, onChange, dotSize, color }) {
  const { dotWrapper, dotWrapperContainer } = useStyles();
  return (
    <Box className={dotWrapper}>
      {r(number).map((n) => (
        <Box key={n} className={dotWrapperContainer}>
          <Dot
            selected={selected}
            value={n}
            onChange={onChange}
            dotSize={dotSize}
            color={color}
          />
        </Box>
      ))}
    </Box>
  );
}

export default memo(CarouselDots);
