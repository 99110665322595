import { useCallback } from "react";
import useRuntimeContext from "../../../../contexts/Runtime/useRuntimeContext";

/**
 * @param {{cart: number}} param0
 * @returns
 */
export default function useOnElementSelect({ cart }) {
  const { Navigation, viewNames } = useRuntimeContext();
  const onActualSelect = useCallback(
    (e) => {
      Navigation.navigate(e.user ? viewNames.user[e.id] : viewNames[e.id], {
        id: cart,
        cart,
      });
    },
    [Navigation, viewNames, cart]
  );
  return onActualSelect;
}
