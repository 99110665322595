import { InnerCartProductProvider } from "../Main";
import { ActiveCartProvider } from "../../../CartProvider";
import React, { memo } from "react";

/**
 * @returns
 * @param {{
 *    children: ReactChildren,
 * }} param0
 */
function ActiveCartAndProductsProvider({ children }) {
  return (
    <ActiveCartProvider>
      <InnerCartProductProvider>{children}</InnerCartProductProvider>
    </ActiveCartProvider>
  );
}

export default memo(ActiveCartAndProductsProvider);
