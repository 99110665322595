import React from "react";
import Homepage from "../Homepage";

/**
 * @param {{
 *    title: string,
 *    productSelectionViewSectionFilters: string[],
 *    cart?: number,
 *      productSelectionViewInitialCategoryFilter?: number
 * }} param0
 * @returns
 */
function ProductSelectionPage({
  productSelectionViewSectionFilters,
  title,
  cart,
  productSelectionViewInitialCategoryFilter,
  noHeader
}) {
  return (
    <Homepage
      variant="noPanel"
      productSelectionViewSectionFilters={productSelectionViewSectionFilters}
      title={title || "I nostri piatti"}
      cart={cart}
      productSelectionViewInitialCategoryFilter={
        productSelectionViewInitialCategoryFilter
      }
      viewName="menuSelection"
      noContentGutters
      noTopPadding
      noHeader={noHeader}
    />
  );
}

export default ProductSelectionPage;
