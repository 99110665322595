/* istanbul ignore file */

import { CollectionAttributeRenderer } from "@italwebcom/react-entity-manager";
import { CartProduct } from "../../../../__domain/model";

/**
 * @type {CollectionAttributeRenderer<any, CartProduct>[]}
 */
const attributes = [
    {
        id: "product",
        label: "Prodotto",
        type: "title",
        renderer: e => e.product.name
    },
    {
        id: "description",
        label: "Descrizione",
        type: "subtitle",
        renderer: e => e.product.description
    },
    {
        id: "image",
        label: "Immagine",
        type: "avatar",
        renderer: e => (e.image && e.image.url) || e.productImage || e.product.image || e.product.mainImage
    }
];

export default attributes;