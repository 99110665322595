import React, { memo, useCallback, useMemo } from "react";
import { Products } from "@italwebcom/anema-react-components";
import { EntityManager, FiltererProps } from "@italwebcom/react-entity-manager";
import ListCollectionRenderer from "../CustomerProductSelectionView/CollectionRenderers/ListCollectionRenderer";
import TagTabsSelector from "../../tags/TagTabsSelector";
import { makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import ProductDetailView from "../ProductDetailView";
import DefaultErrorPrinter from "../../../_auxiliary/DefaultErrorPrinter";

const { Provider: ProductsProvider } = Products.Components;
const s = makeStyles((theme) => ({
  filtererWrapper: {
    marginTop: -theme.spacing(1),
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
    zIndex: 4,
  },
}));

/**
 * @param {FiltererProps<any>} param0
 */
function FiltererComponent({ filters, onFilterReplace }) {
  const onActualChange = useCallback(
    (c) => {
      c &&
        c.length &&
        onFilterReplace(
          { attribute: "categories" },
          { attribute: "categories", value: c[0].id }
        );
    },
    [onFilterReplace]
  );
  const defaultSelected = useMemo(() => {
    if (filters && filters[0]) {
      return [{ id: filters[0].value }];
    }
  }, [filters]);
  return (
    <TagTabsSelector
      filterType="tag_type_1"
      onChange={onActualChange}
      defaultSelected={defaultSelected}
    />
  );
}

function Component({
  initialCategoryFilter,
  filtererStyle,
  initialItemsPerPage,
}) {
  const { filtererWrapper } = s();
  const initialFilters = useMemo(
    () => [{ attribute: "categories", value: initialCategoryFilter }],
    [initialCategoryFilter]
  );
  return (
    <ProductsProvider
      filters={initialFilters}
      addCart
      initialItemsPerPage={initialItemsPerPage || 9999}
      lazyCountRecompute
    >
      <Box className={filtererWrapper} style={filtererStyle}>
        <EntityManager.Components.Filterer Component={FiltererComponent} />
        <Divider />
      </Box>
      <EntityManager.Components.CollectionRenderer
        Component={ListCollectionRenderer}
        ComponentProps={{ sendOnSelect: true }}
      />
      <DefaultErrorPrinter />
      <EntityManager.Components.Editor Component={ProductDetailView.Drawer} />
    </ProductsProvider>
  );
}

export default memo(Component);
