import React, { ComponentType, ReactNode, useContext } from "react";
import Menu from "@material-ui/icons/Menu";
import { UserDetails } from "../../../defines";
import CustomIconButton from "../../../Inputs/CustomIconButton";
import SimpleSearchBar from "../../../Inputs/SimpleSearchBar";
import UserManagementButton from "../../../Inputs/UserManagementButton";
import useStyles from "../styles";
import {AppBar, Box, Typography, Container, Grid} from "@material-ui/core";

const ToggleMenuButton = ({ onToggleMenu, Icon }) => (
  <CustomIconButton Icon={Icon || Menu} onPress={onToggleMenu} />
);

/**
 * @typedef {{
 *      userDetails: UserDetails,
 *      title?: string,
 *      subtitle?: string,
 *      logo: string,
 *      onToggleMenu: Function,
 *      Icon?: ComponentType<any>,
 *      onSearch: (query: string) => void,
 *      additionalContent?: ReactNode
 * }} SimpleAppBarProps
 *
 * @param {SimpleAppBarProps} param0
 */

export default function SimpleAppBar({
  userDetails,
  title,
  subtitle,
  logo,
  onToggleMenu,
  Icon,
  onSearch,
  additionalContent,
  height,
}) {
  const classes = useStyles();
  return (
    <AppBar
      className={classes.bar}
      style={{ height: height || "82px" }}
      variant="outlined"
      color="transparent"
      position="sticky"
    >
      <Box className={classes.externalWrapper}>
        <Container maxWidth={false}>
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <ToggleMenuButton onToggleMenu={onToggleMenu} Icon={Icon} />
            </Grid>
            <Grid item className={classes.logo}>
              <img className={classes.image} src={logo} />
            </Grid>
            {title && (
              <Grid item>
                <Typography
                  component="h2"
                  variant="h2"
                  gutterBottom={subtitle}
                  className={classes.title}
                >
                  {title}
                </Typography>
                {subtitle && (
                  <Typography
                    component="h2"
                    variant="body1"
                    className={classes.subtitle}
                  >
                    {subtitle}
                  </Typography>
                )}
              </Grid>
            )}
            <Grid item xs>
              {additionalContent}
            </Grid>
            {onSearch && (
              <Grid item>
                <SimpleSearchBar placeholder="Ricerca" />
              </Grid>
            )}
            {userDetails && (
              <Grid item>
                <UserManagementButton userDetails={userDetails} />
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </AppBar>
  );
}
