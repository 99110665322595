/* istanbul ignore file */

import { createContext, Context } from "react";
import { AuthProviderContextArgs } from "./defines";

/**
 * @type {Context<AuthProviderContextArgs>}
 */
const AuthContext = createContext({
    credentials: null,
    isAuthorized: () => false,
    setCredentials: () => null
});

export default AuthContext;
