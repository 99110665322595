import links from "../../links";
import { ProductImage, ProductImageAttributes } from "../../../model";

/**
 * @param {ProductImageAttributes} data 
 * @return {ProductImage}
 */
export default function wrapper(data) {
  let i = data.product;
  let c = data.image;
  return {
    ...data,
    _links: {
      self: {
        href: links.product.related.image(i, c)
      },
      fetch: {
        href: links.product.images(i),
        templated: true
      }
    },
    _templates: {
      delete: {
        method: "DELETE",
        target: links.product.related.image(i, c),
        properties: []
      },
    },
  };
}
