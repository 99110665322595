import React, { memo } from "react";
import Generic from "../Generic";
import Person from "@material-ui/icons/Person";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Message from "@material-ui/icons/Message";

const data = [
  {
    id: "dashboard",
    icon: <Person />,
    label: "Area utente",
    user: true,
  },
  {
    id: "carts",
    icon: <ShoppingCart />,
    label: "Prenotazioni",
    user: true,
  },
  {
    id: "chat",
    icon: <Message />,
    label: "Chat",
    user: true,
  }
];

/**
 * @param {{cart: number, colors: any, selected: string}} param0
 * @returns
 */
function User({ cart, colors, selected, backgroundColor }) {
  return (
    <Generic
      elements={data}
      colors={colors}
      cart={cart}
      selected={selected}
      backgroundColor={backgroundColor}
    />
  );
}

export default memo(User);
