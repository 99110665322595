import Provider from "./Provider";
import Group from "./Group";

/**
 * Component used to render groups of elements associated to a subset of the available tags
 * 
 * i.e.
 * 
 * <Provider>
 *  <Group tags={["tag_11", "tag_12"]} Renderer={...} />
 *  ...
 *  <Group tags={["tag_n1", "tag_n2"]} Renderer={...} />
 * </Provider>
 * 
 * - must provide a prop to the Provider used to match the tags by name
 * - must provide a Renderer to render the groups (optionally to each Group)
 */

const TagGroupView = {Provider, Group};
export default TagGroupView;