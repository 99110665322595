import React, { forwardRef, memo, ReactNode, useImperativeHandle } from "react";
import TagProvider from "../../../Tags/TagProvider";
import { Tag } from "../../../../../__domain/model";
import Context from "../contexts/base";
import useRefreshHandler from "../../../../../hooks/useRefreshHandler";

function defaultIsTagAvailable() {
  return true;
}

/**
 * @typedef {(tag: Tag, filterValue: any) => boolean} TagFilterer
 * @param {{
 *    filterType: string,
 *    children: ReactNode[],
 *    tagFilterer: TagFilterer,
 *    isTagAvailable?: (tag: Tag) => boolean
 * }} param0
 * @returns
 */
function Base({ filterType, children, tagFilterer, isTagAvailable }, ref) {
  isTagAvailable = isTagAvailable || defaultIsTagAvailable;

  const innerRef = useRefreshHandler(ref);

  return (
    <Context.Provider value={{ tagFilterer, isTagAvailable }}>
      <TagProvider
        filterType={filterType}
        addCart
        ref={innerRef}
        initialItemsPerPage={9999}
      >
        {children}
      </TagProvider>
    </Context.Provider>
  );
}

export default memo(forwardRef(Base));
