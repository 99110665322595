import { withDefaultHateoas } from "../auxiliary/getDefaultHateoasData";
import links from "../../links";
import {Product, ProductAttributes} from "../../../model";

/**
 * @return {Product}
 * @param {ProductAttributes} data 
 */
export default function productWrapper(data) {
    let w = withDefaultHateoas("product", data);
    w._links.categories = {
        href: links.product.categories(w.id),
        templated: true
    };
    w._links.costs = {
        href: links.product.costs(w.id),
        templated: true
    };
    w._links.images = {
        href: links.product.images(w.id),
        templated: true
    };
    w._templates.addCategory = {
        method: "POST",
        target: links.product.addCategory(w.id)
    };
    w._templates.addImage = {
        method: "POST",
        target: links.product.addImage(w.id)
    };
    return w;
}