import React, { useCallback, ReactNode, useRef, memo, forwardRef } from "react";
import {
  EntityManager,
  CollectionRendererProps,
  useEntityManagerValues,
} from "@italwebcom/react-entity-manager";
import useCustomerWrapper from "../../../../hooks/wrappers/useCustomerWrapper";
import { useDefaultComponents } from "../../../misc/DefaultComponentsProvider";
import attributes from "../attributes";
import { Notification } from "../../../../__domain/model";
import Context from "../context";
import useRequestAuthenticationSetter from "../../../authentication/hooks/useRequestAuthenticationSetter";
import useRefreshHandler from "../../../../hooks/useRefreshHandler";

/**
 * @returns
 * @param {CollectionRendererProps<Notification>} param0
 */
const ProviderCollectionRenderer = memo(({ elements, children }) => {
  const { loading, error, onRefreshAll } = useEntityManagerValues();
  return (
    <Context.Provider
      value={{ notifications: elements, loading, error, onRefreshAll }}
    >
      {children}
    </Context.Provider>
  );
});

/**
 * Manages fetching operations with an EntityManager for the Notification entity (filtered by customer)
 * & instantiates a corresponding context provider with:
 *
 * - the fetched notifications
 * - a loading boolean flag
 *
 * Values may be obtained with the useNotifications hook.
 *
 * Requires a Customer entity whose notifications are fetched.
 *
 * @returns
 * @param {{customer: Customer, children: ReactNode[], initialItemsPerPage: number}} param0
 */
function CustomerNotificationProvider(
  { customer, children, initialItemsPerPage },
  ref
) {
  const authSetter = useRequestAuthenticationSetter();
  const cWrapper = useCustomerWrapper();
  const { fetchArgsProcessor, defaultCountGetter } = useDefaultComponents();

  const onFetch = useCallback(
    (args) => {
      let r = cWrapper.wrap(customer).execute("notifications", args);
      authSetter(r.request());
      return r.json();
    },
    [cWrapper, customer, authSetter]
  );
  const innerRef = useRefreshHandler(ref);

  return (
    <EntityManager.ReadOnly
      fetchArgsProcessor={fetchArgsProcessor}
      countGetter={defaultCountGetter}
      initialItemsPerPage={initialItemsPerPage || 5}
      initialFilters={[]}
      initialSorter={{ attribute: "date", direction: "desc" }}
      onFetch={onFetch}
      attributes={attributes}
      ref={innerRef}
    >
      <EntityManager.Components.CollectionRenderer
        Component={ProviderCollectionRenderer}
        ComponentProps={{ children }}
        renderOnError
      />
    </EntityManager.ReadOnly>
  );
}

export default memo(forwardRef(CustomerNotificationProvider));
