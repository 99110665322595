import TextField from "@material-ui/core/TextField";
import React, { memo } from "react";
import {InputComponentProps} from "@italwebcom/react-generic-form";

/**
 * @param {InputComponentProps} param0 
 * @returns 
 */
function InputComponent({onChange, defaultValue, value, type, disabled}) {
    return <TextField
        variant="outlined"
        defaultValue={defaultValue}
        value={value}
        type={type}
        disabled={disabled}
        onChange={onChange}
    />;
}

export default memo(InputComponent);