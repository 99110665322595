import {
  StorageProvider,
  useStorage,
  useStorageValue,
} from "./StorageProvider";

import { Provider as AlertProvider, useAlertFunctions } from "./AlertProvider";
import { Provider as RuntimeProvider, useRuntimeContext } from "./RuntimeProvider";
import { Provider as DefaultComponentsProvider, useDefaultComponents } from "./DefaultComponentsProvider";
import { Provider as NavigationProvider } from "./NavigationProvider";

const components = { StorageProvider, AlertProvider, RuntimeProvider, DefaultComponentsProvider, NavigationProvider };
const hooks = { useStorage, useStorageValue, useAlertFunctions, useRuntimeContext, useDefaultComponents };

export { components, hooks };