import React, { memo } from "react";
import Grid from "@material-ui/core/Grid";
import { Product } from "../../../../../../model";
import { useEntityManagerValues } from "@italwebcom/react-entity-manager";
import { CardItemRenderer } from "../../ItemRenderers";
import MissingProducts from "../auxiliary/MissingProducts";

/**
 * @param {{elements: Product[]}} param0
 */
function GridCollectionRenderer({ elements, actionLabel, onSelect, sendOnSelect }) {
  const { loading } = useEntityManagerValues();
  if (!elements || !elements.length || loading) {
    return (
      <MissingProducts loading={loading} />
    );
  } else {
    return (
      <Grid container spacing={2}>
        {elements.map(e => (
          <Grid item xs={12} sm={4}>
            <CardItemRenderer
              element={e}
              key={e.id}
              onSelect={sendOnSelect && onSelect}
              actionLabel={actionLabel}
            />
          </Grid>
        ))}
      </Grid>
    );
  }
}

export default memo(GridCollectionRenderer);