import { useEffect, useRef } from "react";

function useCartProductUpdate({ cartProduct, innerRef }) {
  const cartProductRef = useRef();
  useEffect(() => {
    if (cartProduct && innerRef.current) {
      console.log("SelectedProductFeaturesProvider.useCartProductUpdate: cartProduct changed, refreshing selections.");
      cartProductRef.current = cartProduct;
      innerRef.current.refresh();
    }
  }, [cartProduct, cartProductRef, innerRef]);
  return cartProductRef;
}

export default useCartProductUpdate;
