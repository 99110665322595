import {makeStyles} from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  wrapperStyle: {
    height: "100%"
  },
  messageDate: {
    marginTop: theme.spacing(1),
    color: theme.palette.grey["500"],
  },
  windowStyle: {
    /*borderWidth: 1,
    borderColor: theme.palette.grey["300"],
    borderRadius: 5,
    borderStyle: "solid",*/
    //marginBottom: theme.spacing(3),
    overflow: "auto",
    /*paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),*/
    flex: 1,
  },
  messageWrapper: {
    "&.not-authored": {
      justifyContent: "flex-end",
      "& .message-container": {
        order: 1,
        backgroundColor: "#9F9F9F",
        boxShadow:
          "0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%)"
      },
      "& .avatar-container": {
        order: 2,
        marginLeft: theme.spacing(2),
      },
      "& .message-subtitle": {
        //color: theme.palette.grey["600"],
        color: "white"
      },
      "& .message-body": {
        color: "white",
      },
    },
    "&.authored": {
      "& .message-container": {
        backgroundColor: "rgb(107, 147, 245)",
        boxShadow:
          "0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%)",
      },
      "& .avatar-container": {
        marginRight: theme.spacing(2),
      },
      "& .message-title": {
        color: "white",
      },
      "& .message-subtitle": {
        color: "white",
      },
      "& .message-body": {
        color: "white",
      },
    },
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  messageContainer: {
    padding: theme.spacing(2),
    minWidth: "125px",
    maxWidth: "300px"
  },
  messageTitleWrapper: {
    marginBottom: theme.spacing(1),
  },
  formWrapper: {
    position: "sticky",
    bottom: 0,
    backgroundColor: "white",
    zIndex: 4
  },
  messageSubmitButton: {
    marginTop: theme.spacing(1)
  }
}));

export default useStyles;
