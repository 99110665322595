import { useContext, useMemo } from "react";
import { useEntityManagerValues } from "@italwebcom/react-entity-manager";
import context from "../contexts/base";

export default function useFilteredTag(filterValue) {
  const { elements, loading } = useEntityManagerValues();
  const { tagFilterer, isTagAvailable } = useContext(context);
  const tag = useMemo(
    () => {
        if(elements && elements.length) {
            let v = elements.filter((t) => tagFilterer(t, filterValue));
            if(v.length && isTagAvailable(v[0])) {
                return v[0];
            }
        }
        return null;
    },
    [filterValue, elements, tagFilterer]
  );
  return {tag, loading};
}
