import {makeStyles} from "@material-ui/core"
import React, { memo } from "react";
import { EntityManager } from "@italwebcom/react-entity-manager";
import ErrorPrinter from "../../ErrorPrinter";

const s = makeStyles((theme) => ({
  title: {
    color: theme.palette.grey["700"],
    textAlign: "center",
    fontWeight: "bold",
  },
}));

/**
 * @param {{
 *    title?: string,
 *    subtitle?: string,
 *    iconSize?: undefined | "small" | "big"
 * }} param0
 * @returns
 */
function DefaultErrorPrinter({ title, subtitle, iconSize }) {
  const { title: titleStyle } = s();
  return (
    <EntityManager.Components.Error
      errorType="fetch_error"
      Component={ErrorPrinter}
      ComponentProps={{
        title: title || "Errore di caricamento",
        titleTypographyProps: {
          className: titleStyle,
          component: "h5",
          variant: "h5",
        },
        subtitle:
          subtitle ||
          "Si è verificato un errore nel caricamento dei dati. Prova a controllare lo stato della connessione.",
        icon: iconSize || "big",
        color: "grey",
      }}
    />
  );
}

const NonWrappedDefaultErrorPrinter = memo(({ onRefreshAll }) => {
  const { title: titleStyle } = s();
  return (
    <ErrorPrinter
      title="Errore di caricamento"
      titleTypographyProps={{
        className: titleStyle,
        component: "h5",
        variant: "h5",
      }}
      subtitle="Si è verificato un errore nel caricamento dei dati. Prova a controllare lo stato della connessione."
      icon="big"
      color="grey"
      onRefreshAll={onRefreshAll}
    />
  );
});

export { NonWrappedDefaultErrorPrinter };
export default memo(DefaultErrorPrinter);
