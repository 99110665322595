import React, { memo, useCallback, useContext, CSSProperties } from "react";
import { Products } from "@italwebcom/anema-react-components";
import { EntityManager } from "@italwebcom/react-entity-manager";
import AlternateListCollectionRenderer from "../../CollectionRenderers/AlternateListCollectionRenderer";
import SectionSlider from "../../../../../SectionSlider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import TagPanelSelector from "../../../../tags/TagPanelSelector";
import { C, actualOnScrollTo, tagFilterer } from "../auxiliary";
import MainContentPanel, {
  MainContentPanelHeaderProps,
} from "../../../../../MainContentPanel";
import DefaultErrorPrinter from "../../../../../_auxiliary/DefaultErrorPrinter";

const { SectionedProductProvider } = Products.Components;

function ActualFilterer({ onChange, filtererStyle }) {
  const onActualChange = useCallback(
    (els) => els && els.length && onChange(els[0].name),
    [onChange]
  );
  return (
    <Paper style={filtererStyle}>
      <TagPanelSelector
        defaultSelected={[]}
        onChange={onActualChange}
        filterType="tag_type_1"
        legend="Tipo piatto"
      />
    </Paper>
  );
}

function SectionRenderer({ tag, loading }) {
  const { variant, headerProps } = useContext(C);
  if (tag && tag.unavailable) {
    return null;
  } else {
    return (
      <Box marginBottom="2rem">
        <MainContentPanel title={tag && tag.name} headerProps={headerProps}>
          <Box
            minHeight="350px"
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <EntityManager.Components.CollectionRenderer
              Component={AlternateListCollectionRenderer}
              ComponentProps={{
                image:
                  tag && ((tag.image && tag.image.url) || tag.mainImageUrl),
                variant,
                placeholder: loading,
              }}
            />
            <DefaultErrorPrinter />
          </Box>
        </MainContentPanel>
      </Box>
    );
  }
}

const CustomerProductSelectionView = memo(
  /**
   * @typedef {(content: ReactNode, tag: any) => ReactNode} InnerSectionRenderer
   * @param {{
   *        sectionFilters: string[],
   *        variant: "simple" | "panel",
   *        filtererStyle?: CSSProperties,
   *        headerProps: MainContentPanelHeaderProps
   * }} param0
   */
  ({ sectionFilters, variant, headerProps, filtererStyle }) => {
    return (
      <SectionSlider.Base onScrollTo={actualOnScrollTo}>
        <C.Provider value={{ variant, headerProps }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <SectionSlider.Slider
                Renderer={ActualFilterer}
                RendererProps={{ filtererStyle }}
              />
            </Grid>
            <Grid item xs>
              <SectionedProductProvider.Base
                filterType="tag_type_1"
                tagFilterer={tagFilterer}
              >
                {sectionFilters.map((sectionFilter) => (
                  <SectionedProductProvider.SectionProvider
                    filterValue={sectionFilter}
                  >
                    <SectionSlider.Section value={sectionFilter}>
                      <SectionedProductProvider.SectionRenderer
                        Component={SectionRenderer}
                      />
                    </SectionSlider.Section>
                  </SectionedProductProvider.SectionProvider>
                ))}
              </SectionedProductProvider.Base>
            </Grid>
          </Grid>
        </C.Provider>
      </SectionSlider.Base>
    );
  }
);

export default CustomerProductSelectionView;
