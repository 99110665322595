/**
 * @type {{
 *      confirmCartConfirmation: string,
 *      paidCartConfirmation: string,
 *      abortCartConfirmation: string,
 *      confirmElementDeletion: (element: any) => string
 * }}
 */
 const messages = {
    confirmCartConfirmation: "Vuoi confermare il carrello?",
    abortCartConfirmation: "Vuoi annullare il carrello?",
    paidCartConfirmation: "Vuoi impostare il carrello come pagato?",
    confirmElementDeletion: e => `Confermi la cancellazione dell'elemento ${e[0].name || e[0].value || e[0].id}?`
}

export default messages;