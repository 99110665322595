import { memo, useCallback, useContext } from "react";
import EntityManagerContext from "../context";
import { Adder } from "../defines";
import { ifNotUndef } from "../auxiliary";

/**
 * @param {{Component: Adder}} param0
 */
function AdderWrapper({ Component }) {
  const { onSelect, manageEntitySelection } = useContext(EntityManagerContext);
  if (manageEntitySelection) {
    const onAdd = useCallback((args) => onSelect(args || {}), [onSelect]);
    return ifNotUndef(Component, { onAdd });
  }
  return null;
}

export default memo(AdderWrapper);