import { useValidator, ValidationRule } from "@italwebcom/custom-react-hooks";
import { useMemo } from "react";
import { InvalidValue, Field } from "../../defines";

/**
 * @param {Field[]} fields
 * @returns {ValidationRule[]}
 */
function useValidationRules(fields) {
  return useMemo(() => {
    /**
     * @type {ValidationRule[]}
     */
    let outRules = [];
    for (let f of fields) {
      if (f.validationRules) {
        for (let r of f.validationRules) {
          outRules.push({
            ...r,
            attribute: f.id,
            type: "attribute",
          });
        }
      }
    }
    return outRules;
  }, [fields]);
}

/**
 * @param {{values: Record<string, any>, fields: Field[]}}
 * @returns {{valid: boolean, invalidValue: InvalidValue}}
 */
export default function useFormValidation({ values, fields }) {
  const validationRules = useValidationRules(fields);
  const result = useValidator(values, validationRules);
  const invalidValue = useMemo(() => {
    if (result.invalidAttribute && result.invalidMessage) {
      return {
        invalidMessage: result.invalidMessage,
        id: result.invalidAttribute,
      };
    }
    return null;
  }, [result]);
  return {
    valid: result.valid,
    invalidValue,
  };
}
