import { FetchResult, FetcherInputs } from "@italwebcom/custom-react-hooks";

const sortElements = (elements, attribute, sortDirection) => {
    if(attribute && sortDirection) {
        let sorter;
        let auxEls = [...elements];
        if(sortDirection === "asc") {
            sorter = (a, b) => a[attribute] < b[attribute] ? -1 : 1;
        } else {
            sorter = (a, b) => a[attribute] < b[attribute] ? 1 : -1;
        }
        return auxEls.sort(sorter);
    }
    return elements;
};

const filterElements = (elements, attribute, value) => {
    if(attribute && value) {
        return elements.filter(element => element[attribute].toLowerCase().substring(0, value.length) === value.toString().toLowerCase());
    }
    return elements;
};

const p = (d, l) => {
    if(l) {
        return new Promise((res, rej) => {
            setTimeout(() => res(d), l);
        });
    } else {
        return Promise.resolve(d);
    }
};

/**
 * @template T
 * @template Indices
 * @template Sorters
 */
export default class InMemoryCollection {

    /**
     * @type {T[]}
     */
    _data;

    /**
     * @type {number}
     */
    _latency;

    /**
     * @param {T[]} data 
     */
    constructor(data) {
        this._data = data;
    }

    /**
     * @param {number} l 
     * @returns 
     */
    withLatency(l) {
        this._latency = l;
        return this;
    }

    /**
     * @param {FetcherInputs<Indices, Sorters>} param0 
     * @return {Promise<FetchResult<T>>}
     */
    fetch(args) {
        console.log(args);
        const { filters, sorter, start, end } = args;
        let aux = [...this._data];
        if(filters) {
            for(let filter of filters) {
                aux = filterElements(aux, filter.attribute, filter.value);
            }
        }
        if(sorter) {
            aux = sortElements(aux, sorter.attribute, sorter.direction);
        }
        let c = aux.length;
        if((start !== undefined) && (end !== undefined)) {
            aux = aux.slice(start, end+1);
        }
        const out = { data: aux, count: c };
        console.log(out);
        return p(out, this._latency);
    }

    /**
     * @returns
     * @param {(entity: T) => boolean} matcher 
     */
    remove(matcher) {
        let match = this._data.filter(matcher);
        if(match.length) {
            match = match[0];
            this._data.splice(this._data.indexOf(match), 1);
            return p(match, this._latency);
        }
        return p(null, this._latency);
    }
}