import context from "../context";
import { useContext, useMemo } from "react";

/**
 * @param {number} rowSpan
 * @returns 
 */
export default function useImageWidth(rowSpan) {
  const { rows } = useContext(context);
  return useMemo(
    () => `${100 * (rowSpan/rows)}%`,
    [rowSpan, rows]
  );
}
