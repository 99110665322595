import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  overviewWrapper: {
    boxSizing: "border-box",
    height: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: "white",
    position: "sticky",
    top: 0
  },
  overviewWrapperContent: {
    position: "sticky",
    top: theme.spacing(2)
  },
  additionalDataWrapper: {
    backgroundColor: "white",
    padding: theme.spacing(2)
  },
  itemsWrapper: {
    backgroundColor: "#f3f3f3",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  bold: {
    fontWeight: "bold",
  },
  innerPar: {
    fontWeight: "inherit",
  },
  textCoupleWrapper: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(2),
    },
    "&:last-child": {
      marginBottom: 0
    },
    marginTop: 0
  },
  titleWrapper: {
    marginBottom: theme.spacing(2)
  },
  value: {
    textAlign: "right",
    maxWidth: "calc(min(40vw, 300px))",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  centered: {
    textAlign: "center"
  }
}));

export default useStyles;
