import React, { memo } from "react";
import Generic from "../Generic";
import Person from "@material-ui/icons/Person";
import Message from "@material-ui/icons/Message";
import ShoppingCart from "@material-ui/icons/ShoppingCart";

const altData = [
  {
    id: "dashboard",
    icon: <Person />,
    user: true,
  },
  {
    id: "carts",
    icon: <ShoppingCart />,
    user: true,
  },
  {
    id: "chat",
    icon: <Message />,
    user: true,
  },
];

const data = [
  {
    id: "dashboard",
    icon: <Person />,
    user: true,
    label: "Area utente",
    variant: "button"
  },
  {
    id: "carts",
    icon: <ShoppingCart />,
    user: true,
    label: "Le tue prenotazioni",
    variant: "button"
  },
  {
    id: "chat",
    icon: <Message />,
    label: "Chat",
    user: true,
    variant: "button"
  },
];

/**
 * @param {{cart: number, color: string}} param0
 * @returns
 */
function User({ cart, color, smallScreen }) {
  return (
    <Generic
      data={smallScreen ? altData : data}
      color={color}
      cart={cart}
      user
    />
  );
}

export default memo(User);
