import React, { memo } from "react";
import { Conversations } from "@italwebcom/anema-react-components";
import ConversationRenderer from "./ConversationRenderer";

const { ConversationAndMessagesProvider } = Conversations.Components;

function undef(id) {
  return typeof(id) !== "number";
}

/**
 * @param {{id: number, readOnly: boolean}} param0
 * @returns
 */
function CustomerConversationManager({ id, readOnly, initialMessagesPerPage }) {
  if(!undef(id)) {
    return (
      <ConversationAndMessagesProvider
        id={id}
        initialMessagesNumber={initialMessagesPerPage}
      >
        <ConversationRenderer readOnly={readOnly} />
      </ConversationAndMessagesProvider>
    );
  } else {
    return <ConversationRenderer readOnly placeholder />;
  }
}

export default memo(CustomerConversationManager);
