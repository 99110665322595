import React from "react";
import { MainContentCard } from "@italwebcom/misc-ui-components";
import {makeStyles} from "@material-ui/core"
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import Container from "@material-ui/core/Container";
import Close from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  externalWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function ModalEntityCard({
  entity,
  onResetSelectedEntity,
  subtitleGetter,
  children,
  maxWidth,
  title
}) {
  const { externalWrapper } = useStyles();
  return (
    <Modal open={Boolean(entity)} onClose={onResetSelectedEntity}>
      <Box className={externalWrapper}>
        <Container maxWidth={maxWidth || "sm"}>
          <MainContentCard
            title={title || `Modifica elemento`}
            subtitle={entity && subtitleGetter(entity)}
            buttons={[
              {
                id: "close",
                onPress: onResetSelectedEntity,
                label: "Annulla",
                color: "secondary",
                icon: <Close />,
              },
            ]}
          >
            {children}
          </MainContentCard>
        </Container>
      </Box>
    </Modal>
  );
}
