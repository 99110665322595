import React, { memo, useMemo } from "react";
import useCartProviderOperations from "../../hooks/useCartProviderOperations";
import useAuthenticatedRequest from "../../../../../authentication/hooks/useAuthenticatedRequest";
import useRequestAuthenticationSetter from "../../../../../authentication/hooks/useRequestAuthenticationSetter";

import Crud from "../Crud";
import { Filter } from "@italwebcom/custom-react-hooks";
import { CustomerIndices } from "../../../../../../__domain/model";

/**
 * @param {{cart: number, children: ReactChildren, asCustomer?: boolean, filters: Filter<CustomerIndices>}} param0
 * @returns
 */
function CartProvider({
  cart,
  filters,
  children,
  asCustomer,
  onActualFetch,
  onAfterSave,
}) {

  const authSetter = useRequestAuthenticationSetter();
  const { onSuccess, onError, onSave, onFetch } = useCartProviderOperations({
    asCustomer,
    onAfterSave,
    requestProcessor: authSetter
  });

  const actualFilters = useMemo(
    () => filters || [{ attribute: "id", value: cart }],
    [cart, filters]
  );

  /*const onActualSave = useAuthenticatedRequest({ operator: onSave });
  const onReallyActualFetch = useAuthenticatedRequest({
    operator: onActualFetch || onFetch,
  });*/

  return (
    <Crud
      onFetch={onActualFetch || onFetch}
      onSave={onSave}
      onSuccess={onSuccess}
      onError={onError}
      filters={actualFilters}
    >
      {children}
    </Crud>
  );
}

export default memo(CartProvider);
