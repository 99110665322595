import React from "react";
import { render } from "react-dom";
import reportWebVitals from "./reportWebVitals";
import mockApp from "./apps/mock";
import realApp from "./apps/real";
import "react-datepicker/dist/react-datepicker.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

/**
 * @param {{mock: boolean, storage: "cookie" | "local"}} param0
 * @returns
 */
function renderApp({ mock, storage }) {
  if (mock) {
    return mockApp();
  } else {
    return realApp({ storage });
  }
}

render(
  <React.StrictMode>
    {renderApp({ mock: false, storage: "local" })}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
