import { GlobalSearchResult } from "../../model";
import products from "./products";
import tags from "./tags";

/**
 * @type {GlobalSearchResult[]}
 */
const results = [];
const totResults = products.length + tags.length;

for(let i=0; i<totResults; i++) {
    if(i >= products.length) {
        let c = tags[i - products.length];
        results.push({
            ...c,
            resultType: "Category",
            query: c.name
        });
    } else {
        let p = products[i];
        results.push({
            ...p,
            resultType: "Product",
            query: p.name
        });
    }
}

export default results;