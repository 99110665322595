export default function productFilterer(elements, attribute, value, defaultFilterer) {
    if (attribute === "categories") {
      value = value.split(",").map(e => parseInt(e));
      return elements.filter((e) =>
          value
            .map((v) => e.categories.indexOf(v) !== -1)
            .reduce((a, b) => a && b)
      );
    }
    return defaultFilterer(elements, attribute, value);
  }