import { useMemo } from "react";
import useFormData from "./useFormData";

export default function useShouldDisable({ field }) {
  const { shouldDisable, values } = useFormData();
  return useMemo(
    () => shouldDisable && shouldDisable(field, values),
    [field, values, shouldDisable]
  );
}
