import React, { memo, ReactNode, createContext } from "react";
import {
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Typography,
  IconButton,
  Divider,
  makeStyles,
  Breadcrumbs,
  Link,
  Grid,
  Chip,
} from "@material-ui/core";
import CustomButton from "../CustomButton";

const TheContext = createContext({ disabled: false });
const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: theme.spacing(1),
    flex: 1,
  },
  avatar: {
    height: 72,
    width: 72,
  },
  chipStyle: {
    marginLeft: theme.spacing(1),
  },
  titleStyle: {
    color: theme.palette.grey["700"],
    fontWeight: "bold"
  },
  subtitleStyle: {
    color: theme.palette.grey["500"],},
}));

/**
 * @param {{breadcrumbs: MainContentCardBreadcrumb[]}} param0
 * @returns
 */
const MainContentCardBreadcrumbs = ({ breadcrumbs }) =>
  breadcrumbs ? (
    <Breadcrumbs aria-label="breadcrumbs">
      {breadcrumbs.map((breadcrumb) => {
        if (breadcrumb) {
          const { to, label, current } = breadcrumb;
          return (
            <Link
              key={label}
              href={to}
              color={current ? "textPrimary" : "textSecondary"}
            >
              {label}
            </Link>
          );
        }
        return null;
      })}
    </Breadcrumbs>
  ) : null;

/**
 * @param {{buttons: MainContentCardButton[]}} param0
 * @returns
 */
const CardButtonsRenderer = ({ buttons }) =>
  buttons ? (
    <Grid container spacing={1} alignItems="center">
      {buttons.map((button) => {
        if (button) {
          const { icon, label, onPress, id, color } = button;
          return (
            <Grid item key={id || label}>
              {!label ? (
                <IconButton onClick={onPress} id={id}>
                  {icon}
                </IconButton>
              ) : (
                <TheContext.Consumer>
                  {({ disabled }) => (
                    <CustomButton
                      disabled={disabled}
                      className="main-content-card-button"
                      label={label}
                      icon={icon}
                      onClick={onPress}
                      color={color}
                      id={id}
                    />
                  )}
                </TheContext.Consumer>
              )}
            </Grid>
          );
        }
        return null;
      })}
    </Grid>
  ) : null;

function imgSrc(i) {
  return typeof i === "string" ? i : undefined;
}

const Title = memo(
  ({ breadcrumbs, title, subtitle, chip, buttons, action, avatar }) => {
    const {
      avatar: avatarStyle,
      chip: chipStyle,
      titleStyle,
      subtitleStyle,
    } = useStyles();
    const bcrumb =
      breadcrumbs && breadcrumbs.length ? (
        <MainContentCardBreadcrumbs breadcrumbs={breadcrumbs} />
      ) : null;
    return (
      <Grid container spacing={2} alignItems="center">
        {avatar && (
          <Grid item xs={12}>
            {bcrumb}
          </Grid>
        )}
        {avatar && (
          <Grid item>
            <Avatar
              variant="circle"
              src={imgSrc(avatar)}
              className={avatarStyle}
            >
              {avatar}
            </Avatar>
          </Grid>
        )}
        <Grid item xs>
          {title && (
            <Typography
              variant="h5"
              gutterBottom={subtitle || bcrumb}
              className={titleStyle}
            >
              {title}
            </Typography>
          )}
          {!subtitle ? (
            bcrumb
          ) : (
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Typography
                  variant="body1"
                  component="span"
                  className={subtitleStyle}
                >
                  {subtitle}
                </Typography>
              </Grid>
              {chip && (
                <Grid item>
                  <Chip label={chip} className={chipStyle} />
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
        <Grid item>{action || <CardButtonsRenderer buttons={buttons} />}</Grid>
      </Grid>
    );
  }
);

/**
 * @typedef {{
 *      icon: ReactNode,
 *      onPress: Function,
 *      label?: string,
 *      color?: string,
 *      id: string
 * }} MainContentCardButton
 * @typedef {{
 *      label: string,
 *      to: string,
 *      current?: boolean
 * }} MainContentCardBreadcrumb
 * @typedef {{
 *      title?: string,
 *      breadcrumbs?: MainContentCardBreadcrumb[],
 *      buttons?: MainContentCardButton[],
 *      action?: ReactNode,
 *      avatar?: string,
 *      chip?: string,
 *      color?: string,
 *      disabled?: boolean
 * }} MainContentCardProps
 */

/**
 * @param {MainContentCardProps} param0
 */
function MainContentCard({
  title,
  subtitle,
  avatar,
  breadcrumbs,
  buttons,
  action,
  children,
  chip,
  color,
  disabled,
}) {
  const { card } = useStyles();
  return (
    <TheContext.Provider value={{ disabled }}>
      <Card variant="outlined" className={card} style={{ borderColor: color }}>
        <CardHeader
          title={
            <Title
              breadcrumbs={breadcrumbs}
              title={title}
              subtitle={subtitle}
              buttons={buttons}
              action={action}
              avatar={avatar}
              chip={chip}
            />
          }
          disableTypography
        />
        <Divider style={{ backgroundColor: color }} />
        <CardContent>{children}</CardContent>
      </Card>
    </TheContext.Provider>
  );
}

export default memo(MainContentCard);
