import Delete from "@material-ui/icons/Delete";
import { Carts } from "@italwebcom/anema-react-components";
import PlusButton from "./PlusButton";
import MinusButton from "./MinusButton";
import { Product } from "@italwebcom/anema-react-components/Products";
import { CartProduct } from "@italwebcom/anema-react-components/Carts";
import React, { memo, useCallback } from "react";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";

const { useCartAndProducts } = Carts.hooks;

/**
 * @param {{
 *      element: Product,
 *      selectedElement: CartProduct,
 *      onChange: (q: number) => void,
 *      onDelete: Function,
 *      buttons?: boolean
 * }} param0
 * @returns
 */
function ProductQuantitySelectionGroup({
  element,
  selectedElement,
  onChange,
  onDelete,
  buttons,
}) {
  const { productsLoading: loading } = useCartAndProducts();
  const onActualChange = useCallback(
    (evt) => evt.target.value && onChange(parseInt(evt.target.value)),
    [onChange]
  );
  return (
    <Box alignItems="center" display="flex">
      <TextField
        variant="outlined"
        defaultValue={selectedElement.quantity}
        type="number"
        disabled={loading}
        inputProps={{
          min: 0,
          step: 1,
          max: element.stock,
          style: { padding: "8px", maxWidth: "40px", textAlign: "center" },
        }}
        InputProps={{
          startAdornment: buttons && (
            <MinusButton
              selectedElement={selectedElement}
              onChange={onChange}
              disabled={loading}
            />
          ),
          endAdornment: buttons && (
            <PlusButton
              selectedElement={selectedElement}
              onChange={onChange}
              stock={element.stock}
              disabled={loading}
            />
          ),
        }}
        onChange={onActualChange}
      />
      <IconButton size="small" onClick={onDelete} disabled={loading}>
        <Delete />
      </IconButton>
    </Box>
  );
}

export default memo(ProductQuantitySelectionGroup);
