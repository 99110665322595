import { memo, useContext } from "react";
import EntityManagerContext from "../context";
import { ifNotUndef, shouldRender } from "../auxiliary";
import { Sorter, EntityManagerWrapperProps } from "../defines";

/**
 * @param {EntityManagerWrapperProps<Sorter>} param0
 */
function SorterWrapper({ Component, renderOnError }) {
  const { sorter, onSorterChange, attributes, error } =
    useContext(EntityManagerContext);
  if (shouldRender(error, renderOnError)) {
    return ifNotUndef(Component, { sorter, onSorterChange, attributes });
  }
  return null;
}

export default memo(SorterWrapper);
