import React, { memo, useCallback } from "react";
import TagTabsSelector from "../../../../tags/TagTabsSelector";
import { FiltererProps } from "@italwebcom/misc-ui-components";

/**
 * @returns
 * @param {FiltererProps} param0
 */
function ProductTabsFilterer({ onFilterReplace }) {
  const onActualChange = useCallback(
    (e) => {
      e && e.length &&
        onFilterReplace(
          { attribute: "categories" },
          { attribute: "categories", value: e[0].id, label: e[0].name }
        );
    },
    [onFilterReplace]
  );
  return <TagTabsSelector filterType="tag_type_1" onChange={onActualChange} />;
}

export default memo(ProductTabsFilterer);