import { memo, useContext } from "react";
import { Editor } from "../defines";
import EntityManagerContext from "../context";
import { ifNotUndef, auxProps as props } from "../auxiliary";

/**
 * @param {{Component: Editor}} param0
 */
function EditorWrapper({ Component, ComponentProps }) {
  const {
    selectedEntity,
    onSave,
    loading,
    onResetSelectedEntity,
    onDelete,
    actions,
  } = useContext(EntityManagerContext);
  return ifNotUndef(
    Component,
    props(ComponentProps, {
      entity: selectedEntity,
      onSubmit: onSave,
      onDelete,
      loading,
      actions,
      onResetSelectedEntity,
    })
  );
}

export default memo(EditorWrapper);
