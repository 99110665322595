import React, { memo, useCallback } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { cartType } from "../../../../../../model";
import { printType } from "../../../attributes";
import InputWrapperGroup from "../InputWrapperGroup";
import useInputWrapperGroup from "../InputWrapperGroup/hooks/useInputWrapperGroup";
import { useRuntimeContext } from "../../../../../../contexts";

const RadioInputGroup = memo(({ value, onChange, disabled, noDelivery }) => {
  const onActualChange = useCallback(
    (evt, value) => onChange({ target: { value } }),
    [onChange]
  );
  return (
    <RadioGroup onChange={onActualChange} row>
      <FormControlLabel
        disabled={disabled || noDelivery}
        label="Consegna"
        value={cartType.delivery}
        control={<Radio size="small" color="primary" />}
        id="cart-type-selector-delivery"
        checked={value === cartType.delivery}
      />
      <FormControlLabel
        disabled={disabled}
        label="Asporto"
        value={cartType.takeaway}
        control={<Radio size="small" color="primary" />}
        id="cart-type-selector-takeaway"
        checked={value === cartType.takeaway}
      />
      <FormControlLabel
        disabled={disabled}
        label="Tavolo"
        value={cartType.in_place}
        control={<Radio size="small" color="primary" />}
        id="cart-type-selector-inplace"
        checked={value === cartType.in_place}
      />
    </RadioGroup>
  );
});

function CartTypeSelector({ value, onChange, disabled }) {
  const { cart } = useInputWrapperGroup();
  const { deliveryDisabled } = useRuntimeContext();
  return (
    <InputWrapperGroup.InputWrapper
      id="type"
      label="Tipo prenotazione"
      value={value && printType(value, cart)}
    >
      <RadioInputGroup
        value={value}
        onChange={onChange}
        disabled={disabled}
        noDelivery={deliveryDisabled}
      />
    </InputWrapperGroup.InputWrapper>
  );
}

export default memo(CartTypeSelector);
