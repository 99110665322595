import React, { memo } from "react";
import { CollectionRendererProps } from "@italwebcom/misc-ui-components/v2";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import Context from "./context";
import RowRenderer from "./RowRenderer";
import HeadCell from "./HeadCellRenderer";
import {TableRendererProps} from "./defines";

const HeadRenderer = memo(({attributes}) => {
  return (
    <TableHead>
      {attributes.map(a => <HeadCell {...a} key={a.id} />)}
    </TableHead>
  );
});

/**
 * @template Entity
 * @returns
 * @param {TableRendererProps<Entity>} param0
 */
export default function TableRenderer({
  elements,
  onSelect,
  sorter,
  onSorterChange,
  footer,
  selectedEntity,
  attributes
}) {
  return (
    <Context.Provider
      value={{ selected: selectedEntity, onSelect, sorter, onSorterChange, attributes }}
    >
      <Table>
        <HeadRenderer attributes={attributes} />
        <TableBody>
          {elements.map((e) => (
            <RowRenderer element={e} />
          ))}
        </TableBody>
        {footer && <TableFooter>{footer}</TableFooter>}
      </Table>
    </Context.Provider>
  );
}
