import React, { memo, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Typography, { TypographyProps } from "@material-ui/core/Typography";
import useButtonAutoLoading from "../AutoLoadingButton/useButtonAutoLoading";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    transitionProperty: "opacity",
    transitionDuration: "500ms",
    opacity: 1,
    "&:hover": {
      opacity: 0.5,
    },
    margin: "auto",
    position: "relative",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  imageWrapper: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 1,
  },
  image: {
    display: "block",
    maxWidth: "100%",
  },
  title: {
    zIndex: 2,
  },
}));

/**
 *
 * @param {{
 *    src: string,
 *    alt?: string,
 *    onClick: Function,
 *    title?: string,
 *    titleTypographyProps?: TypographyProps,
 *    size: number,
 *    disabled?: boolean
 * }} param0
 */
function ImageButton({
  src,
  alt,
  onClick,
  title,
  titleTypographyProps,
  size,
  disabled,
  loading,
}) {
  const { wrapper, imageWrapper, image, title: titleStyle } = useStyles();
  const { load, onActualClick } = useButtonAutoLoading({
    loading,
    disabled,
    onClick,
  });
  console.log(load);
  const dim = useMemo(() => `${size || 48}px`, [size]);
  return (
    <Box
      className={wrapper}
      onClick={onActualClick}
      component="article"
      role="button"
      style={{ height: dim, width: dim, opacity: disabled || load ? 0.5 : 1 }}
    >
      <Box className={imageWrapper} component="aside">
        <picture>
          <img src={src} alt={alt} className={image} />
        </picture>
      </Box>
      <Box component="header" className={titleStyle}>
        {load ? (
          <Box style={{ color: "white" }}>
            <CircularProgress size={16} color="inherit" />
          </Box>
        ) : (
          <Typography
            variant="body1"
            component="h6"
            {...(titleTypographyProps || {})}
          >
            {title}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default memo(ImageButton);
