import React, { memo, useCallback, useMemo, useRef } from "react";
import { useCartProductWrapper } from "../../../../../hooks/wrappers";
import {
  CartProduct,
  SelectedProductFeature,
} from "../../../../../__domain/model";
import { EntityManager, useEntityManagerValues } from "@italwebcom/react-entity-manager";
import useCartProductUpdate from "../hooks/useCartProductUpdate";
import useCrudOperations from "../hooks/useCrudOperations";
import { useDefaultComponents } from "../../../../misc/DefaultComponentsProvider";
import { useAlertFunctions } from "../../../../misc/AlertProvider";
import Context from "../context";

const initialSorter = { attribute: "selectionIndex", direction: "asc" };

const ContextCollectionRenderer = memo(({ children }) => {
  const { loading, elements, onSave, onRefresh } = useEntityManagerValues();

  const onActualSave = useCallback(
    (feature, selected) => onSave({ ...feature, selected }),
    [onSave]
  );

  const groupedElements = useMemo(() => {
    let out = [];
    if (elements.length) {
      let sortedEls = elements.sort(
        (a, b) => a.selectionIndex - b.selectionIndex
      );
      let cGroup = { selectionIndex: 0, features: [] };
      for (let s of sortedEls) {
        if (s.selectionIndex > cGroup.selectionIndex) {
          out.push(cGroup);
          cGroup = { selectionIndex: s.selectionIndex, features: [] };
        }
        cGroup.features.push(s);
      }
      out.push(cGroup);
    }
    return out;
  }, [elements]);

  return (
    <Context.Provider
      value={{
        loading,
        elements,
        groupedElements,
        onSave: onActualSave,
        onRefresh,
      }}
    >
      {children}
    </Context.Provider>
  );
});

/**
 *
 * @param {{cartProduct: CartProduct, onRefresh?: Function}} param0
 */
function SelectedProductFeaturesProvider({ cartProduct, children, onRefresh }) {
  const innerRef = useRef();
  const cartProductWrapper = useCartProductWrapper();
  const cProd = useCartProductUpdate({ cartProduct, innerRef });
  const { fetchArgsProcessor, defaultCountGetter } = useDefaultComponents();

  const { onAdd: onAlertAdd } = useAlertFunctions();
  const { onFetch, onSave } = useCrudOperations({ cProd, cartProductWrapper, onRefresh });
  const { onSuccess, onError } = useMemo(
    () => ({
      onSuccess: (op) => {
        if (op !== "fetch") {
          onAlertAdd({ type: "success", content: "Aggiornamento effettuato." });
        }
      },
      onError: (e) => {
        console.log(e);
        if (op !== "fetch") {
          onAlertAdd({ type: "error", content: "Si è verificato un errore." });
        }
      },
    }),
    [onAlertAdd]
  );

  return (
    <EntityManager.Crud
      fetchArgsProcessor={fetchArgsProcessor}
      countGetter={defaultCountGetter}
      onFetch={onFetch}
      onSave={onSave}
      initialItemsPerPage={9999}
      onSuccess={onSuccess}
      onError={onError}
      initialSorter={initialSorter}
      ref={innerRef}
    >
      <EntityManager.Components.CollectionRenderer
        Component={ContextCollectionRenderer}
        ComponentProps={{ children }}
      />
    </EntityManager.Crud>
  );
}

export default memo(SelectedProductFeaturesProvider);
