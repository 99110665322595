import React, {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  memo
} from "react";
import {makeStyles} from "@material-ui/core"
import Box from "@material-ui/core/Box";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    "&.pulse": {
      backgroundColor: theme.palette.grey["400"],
    },
    transitionProperty: "background-color",
    transitionDuration: 1500,
    width: "100%",
    backgroundColor: theme.palette.grey["300"],
    position: "absolute",
    height: "100%",
    zIndex: 2,
  },
  wrapper: {
    position: "relative",
  },
}));

const defaultPulsationTime = 3000;

/**
 * @returns
 * @typedef {{width?: number | string, height: number | string}} Dims
 * @param {{
 *      active: boolean,
 *      children: ReactNode[],
 *      containerStyle?: CSSProperties,
 *      dimensions?: Dims,
 *      pulsationTime?: number
 * }} param0
 */
function PlaceholderWrapper({
  active,
  color,
  children,
  containerStyle,
  dimensions,
  pulsationTime,
}) {
  pulsationTime = pulsationTime || defaultPulsationTime;
  const [pulse, setPulse] = useState(false);
  const onTogglePulse = useCallback(() => setPulse((s) => !s), [setPulse]);

  const { backdrop, wrapper } = useStyles();
  const r = useRef();

  useEffect(() => {
    function callback() {
      onTogglePulse();
      setTimeout(callback, pulsationTime);
    }

    if (pulsationTime) {
      if (active) {
        r.current = setTimeout(callback, pulsationTime);
      } else {
        r.current && clearTimeout(r.current);
      }
    }

    return () => r.current && clearTimeout(r.current);
  }, [r, active, pulsationTime, onTogglePulse]);

  const actualStyle = useMemo(() => {
    let out = {};
    if (dimensions) {
      out = { ...dimensions };
    }
    return out;
  }, [dimensions]);

  return (
    <Box className={wrapper} style={containerStyle}>
      {active && (
        <Box
          className={clsx([backdrop, pulse && "pulse"])}
          style={actualStyle}
        />
      )}
      {children}
    </Box>
  );
}

export default memo(PlaceholderWrapper);
