import { createContext } from "react";
import { Product } from "@italwebcom/anema-react-components/Products";

/**
 * @type {{element: Product, anchorEl: HTMLElement, onSetSelected: (element: Product, anchorEl: HTMLElement) => void, onClearSelected: Function}}
 */
const args = {
  element: null,
  anchorEl: null,
  onSetSelected: null,
  onClearSelected: null,
};
export default createContext(args);
