import React from "react";
import { HulkContentBaseProps } from "../defines";
import Context from "../context";
import { Container } from "@material-ui/core";

/**
 * @returns
 * @param {HulkContentBaseProps} param0
 */
export default function Base({ children, contentPadding, sectionMargin, maxWidth }) {
  let content;
  if(maxWidth) {
    content = <Container maxWidth={maxWidth}>{children}</Container>;
  } else {
    content = children;
  }
  return (
    <Context.Provider
      value={{
        contentPadding: contentPadding || "1rem",
        sectionMargin: sectionMargin || "1rem",
      }}
    >
      {content}
    </Context.Provider>
  );
}
